import { styled } from '@mui/material'
import { Box } from '@mui/system'

const StyledLinksList = styled(Box)(({ theme }) => ({
	display: 'flex',
	marginTop: theme.spacing(3.75),
	'&  a': {
		borderRadius: 10,
		width: 200,
		display: 'flex',
		flexDirection: 'column',
		justifyContent: 'center',
		alignItems: 'center',
		boxShadow: '0px 3px 15px #00000029',
		padding: `${theme.spacing(5)} ${theme.spacing(2)}`,
		textDecoration: 'none',
		color: theme.colors.black[500],
		marginRight: theme.spacing(3.75),
		...theme.typography.body1,
		fontWeight: theme.typography.fontWeightMedium,
	},
}))

export default StyledLinksList
