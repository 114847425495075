import classNames from 'classnames'
import React from 'react'

import { Typography } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'

const useStyles = makeStyles((theme) => ({
	messageText: {
		color: theme.colors.red[500],
		margin: '10px 0',
	},
}))

interface FieldErrorMessageProps {
	hasError?: boolean
	message?: string
	children: React.ReactNode
	className?: string
	messageClassName?: string
}

const FieldErrorMessage = ({ hasError = false, message = '', children, className = '', messageClassName = '' }: FieldErrorMessageProps) => {
	const classes = useStyles()

	return (
		<div className={className}>
			{children}
			{hasError && <Typography className={classNames(messageClassName, classes.messageText)}>{message}</Typography>}
		</div>
	)
}

export default FieldErrorMessage
