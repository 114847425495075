import { Table as MaterialTable, TableBody, TableCell, TableContainer, TableRow, TableHead } from '@mui/material'
import { Theme } from '@mui/material/styles'
import makeStyles from '@mui/styles/makeStyles'
import CollapsibleTableRow from './Row'

const useStyles = makeStyles((theme: Theme) => ({
	cell: {
		...theme.typography.body1,
		paddingLeft: 0,
		borderBottomColor: theme.colors.grey[200],
	},
	headerCell: {
		fontWeight: theme.typography.fontWeightBold,
		paddingLeft: 0,
		borderBottomColor: theme.colors.grey[200],
		fontSize: 20,
		lineHeight: '27px',
	},
}))

export interface ColumnInfo {
	name: string
	dataKey: string
	align?: 'left' | 'right' | 'inherit' | 'center' | 'justify'
	showMetrics?: boolean
	dataTransform?: (value: any, row: any) => JSX.Element | string
	size?: number
}

export interface CollapsibleTableProps {
	columns: ColumnInfo[]
	rows: {
		groupName: string
		metrics: (number | null)[]
		rows: any[]
	}[]
	onClickItem?: (item: any) => void
}

const CollapsibleTable = ({ columns, rows, onClickItem }: CollapsibleTableProps) => {
	const classes = useStyles()

	return (
		<TableContainer>
			<MaterialTable>
				<TableHead>
					<TableRow>
						{columns.map(({ name, dataKey, size, ...rest }, index) => (
							<TableCell key={`table-head-${index}`} className={classes.headerCell} style={{ width: size ? `${size}%` : 'auto' }} {...rest}>
								{name}
							</TableCell>
						))}
					</TableRow>
				</TableHead>
				<TableBody>
					{rows.map((row, index) => (
						<CollapsibleTableRow
							key={`${row.groupName}-collapsible-table-content-row-${index}`}
							name={row.groupName}
							rows={row.rows}
							metrics={row.metrics}
							columns={columns}
							onClickItem={onClickItem}
						/>
					))}
				</TableBody>
			</MaterialTable>
		</TableContainer>
	)
}

export default CollapsibleTable
