import { baseStyles as _baseStyles } from '@/components/layouts/dashboard/baseStyles'

export const baseStyles = {
	..._baseStyles,
	sectionHeader: {
		..._baseStyles.sectionHeader,
		marginBottom: 36,
	},
	sectionHeaderSubtitle: {
		..._baseStyles.sectionHeaderSubtitle,
		color: '#0D0C22',
		font: 'normal normal normal 18px/24px Open Sans',
	},
	questionLabel: {
		font: 'normal normal 600 18px/24px Open Sans',
		letterSpacing: 0,
		color: '#0D0E10',
		marginBottom: 10,
	},
	questionRequiredIndicator: {
		color: '#FA1564',
		marginLeft: 5,
	},
	questionDescription: {
		font: 'normal normal normal 18px/24px Open Sans',
		color: '#0D0E10',
		letterSpacing: 0,
	},
	genericQuestion: {
		marginBottom: 57,
	},
}
