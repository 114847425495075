import AppRoute from '@/interfaces/route'
import { getAllAuthorizedRoles } from '@/utils/authHandlers'
import { createPageTitle } from '@/utils/page'

import { EVENT_PATHS } from './constants'
import { CreateEventPage } from './pages/CreateEventPage'
import EditEventPage from './pages/EditEventPage'
import List from './pages/lists'
import { ViewEvent } from './pages/ViewEvent'

const allAuthorizedRolesAllowed = getAllAuthorizedRoles()

const EVENTS_TITLE = createPageTitle('Events')
const CREATE_EVENTS_TITLE = createPageTitle('Create Event')
const VIEW_EVENT_TITLE = createPageTitle('View Event')
const EDIT_EVENT_TITLE = createPageTitle('Edit Event')

const EVENT_ROUTES: AppRoute[] = [
	{
		component: List,
		title: EVENTS_TITLE,
		name: EVENTS_TITLE,
		path: EVENT_PATHS.LIST,
		exact: false,
		isPrivate: true,
		allowedRoles: allAuthorizedRolesAllowed,
	},
	{
		component: CreateEventPage,
		title: CREATE_EVENTS_TITLE,
		name: CREATE_EVENTS_TITLE,
		path: EVENT_PATHS.EVENTS_CREATE,
		exact: true,
		isPrivate: true,
		allowedRoles: allAuthorizedRolesAllowed,
	},
	{
		component: ViewEvent,
		title: VIEW_EVENT_TITLE,
		name: VIEW_EVENT_TITLE,
		path: EVENT_PATHS.VIEW_EVENT(),
		exact: false,
		isPrivate: true,
		allowedRoles: allAuthorizedRolesAllowed,
	},
	{
		component: EditEventPage,
		title: EDIT_EVENT_TITLE,
		name: EDIT_EVENT_TITLE,
		path: EVENT_PATHS.EDIT_EVENT(),
		exact: false,
		isPrivate: true,
		allowedRoles: allAuthorizedRolesAllowed,
	},
]

export default EVENT_ROUTES
