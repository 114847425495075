import React from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { MiddlePaneLayout } from '@/components/layouts/dashboard/MiddleLayout'
import { getUserRole } from '@/store/auth'
import { isAdmin } from '@/utils/authHandlers'

import EventForm from '../components/EventForm'
import { EVENT_PATHS } from '../constants'
import { createEventRequest } from '../eventsSlice'
import { useCurrentUserOrganizations } from '@/features/organizations/hooks/useUserOrganizations'
import MiddlePaneLoader from '@/components/MiddlePaneLoader'

const CreateEventPage = () => {
	const dispatch = useDispatch()
	const currentUserRole = useSelector(getUserRole)
	const { loading, admin: adminOrganizations } = useCurrentUserOrganizations()

	const onSubmit = (data: any) => {
		dispatch(createEventRequest(data))
	}
	return (
		<MiddlePaneLayout>
			{loading ? (
				<MiddlePaneLoader />
			) : (
				<EventForm
					onSubmit={onSubmit}
					data={{}}
					organizationsAsOptions={adminOrganizations}
					cancelRedirectPath={isAdmin(currentUserRole) ? EVENT_PATHS.NEW_LIST : EVENT_PATHS.LIST}
				/>
			)}
		</MiddlePaneLayout>
	)
}

export { CreateEventPage }
