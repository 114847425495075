import classNames from 'classnames'
import { DateTime } from 'luxon'
import React, { useCallback, useState } from 'react'

import useLuxonDateFromJsDate from '@/hooks/useLuxonDateFromJsDate'
import styled from '@emotion/styled'
import { ExpandLess, ExpandMore } from '@mui/icons-material'
import { Box } from '@mui/material'
import { makeStyles } from '@mui/styles'
import { DatePicker } from '@mui/x-date-pickers'

import { SelectButton } from './SelectButton'

const useStyles = makeStyles((theme) => ({
	wrapper: {
		display: 'flex',
		margin: '0 -25px',
	},

	datePickerWrapper: {
		minWidth: '320px',
	},

	icon: {
		width: '24px',
		height: '24px',
	},
	timePickerInput: {
		fontSize: theme.typography.pxToRem(18),
		lineHeight: theme.typography.pxToRem(24),
	},
}))

export const HiddenInput = styled('input')(() => ({
	visibility: 'hidden',
	border: 'none',
	padding: '0',
}))

export const PickerContainer = styled(Box)((theme) => ({
	position: 'relative',
	minWidth: 300,
	'& > input': {
		zIndex: -1,
		position: 'absolute',
		left: 0,
		top: 0,
		width: '100%',
		height: '100%',
	},
}))
export type IDatePickerSelectProps = {
	disablePast: boolean
	onChange: (date: DateTime) => void
	value: Date
	placeholder: string
	disabled: boolean
	minDate?: Date
	maxDate?: Date
	wrapperClass?: string
}

const DatePickerSelect: React.FC<IDatePickerSelectProps> = ({
	disablePast,
	onChange,
	value,
	placeholder,
	disabled,
	minDate,
	maxDate,
	wrapperClass,
}) => {
	const classes = useStyles()
	const [isOpened, setIsOpened] = useState(false)
	const getLabel = useCallback(() => {
		if (value) {
			return DateTime.fromJSDate(new Date(value)).toLocaleString(DateTime.DATE_FULL)
		} else {
			return placeholder
		}
	}, [placeholder, value])

	const { date: minDateTime } = useLuxonDateFromJsDate(minDate)
	const { date: maxDateTime } = useLuxonDateFromJsDate(maxDate)

	const handleChange = (date: DateTime) => {
		if ((minDateTime && date < minDateTime) || (maxDateTime && date > maxDateTime)) {
			return
		}
		onChange(date)
	}

	const disabledDates = useCallback(
		(day: DateTime) => {
			if (minDateTime && minDateTime > day) {
				return true
			}
			if (maxDateTime && maxDateTime < day) {
				return true
			}
			return false
		},
		[minDateTime, maxDateTime],
	)

	const toggleDatePicker = useCallback(() => setIsOpened((isOpened) => !isOpened), [])

	return (
		<DatePicker
			disablePast={disablePast}
			shouldDisableDate={disabledDates}
			open={isOpened}
			onOpen={() => setIsOpened(true)}
			onClose={() => setIsOpened(false)}
			value={value}
			onChange={handleChange}
			renderInput={({ inputRef, inputProps }) => (
				<PickerContainer className={classNames(classes.datePickerWrapper, wrapperClass)}>
					<HiddenInput ref={inputRef} {...inputProps} />
					<SelectButton
						disabled={disabled}
						filled={value ? true : false}
						onClick={toggleDatePicker}
						icon={isOpened ? <ExpandLess /> : <ExpandMore />}
					>
						{getLabel()}
					</SelectButton>
				</PickerContainer>
			)}
		/>
	)
}

export { DatePickerSelect }
