import { FormControlLabel, styled } from '@mui/material'

export const StyledFormControlLabelInline = styled(FormControlLabel)(({ theme }) => ({
	marginLeft: 0,
	marginRight: 0,
	width: '100%',
	marginBottom: theme.spacing(2),
	justifyContent: 'space-between',
	'& .MuiTextField-root': {
		width: 'auto',
	},
	'& .MuiOutlinedInput-root': {
		maxWidth: 60,
	},
	'& .MuiInputBase-input[type=number]': {
		padding: 8,
	},
}))
