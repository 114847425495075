import '@/css/index.css'

import { ClickToComponent } from 'click-to-react-component'
import ReactDOM from 'react-dom'
import * as Sentry from '@sentry/react'
import { CaptureConsole as CaptureConsoleIntegration } from '@sentry/integrations'

import App from '@/App'
import Providers from '@/Providers'
import reportWebVitals from '@/reportWebVitals'

if (process.env.REACT_APP_ENV !== 'local') {
	Sentry.init({
		environment: process.env.REACT_APP_ENV,
		dsn: 'https://78203172ecca4a1786348c91d7f0a4b8@o569813.ingest.sentry.io/6592192',
		// @TODO this needed for upload source maps
		// further reading https://docs.sentry.io/platforms/javascript/guides/react/sourcemaps/uploading/cli/
		// release: process.env.APP_VERSION | 0.1.0
		integrations: [
			new CaptureConsoleIntegration({
				// array of methods that should be captured
				// defaults to ['log', 'info', 'warn', 'error', 'debug', 'assert']
				levels: ['error', 'warn'],
			}),
		],
		//debug: process.env.REACT_APP_ENV !== 'prod',
		normalizeDepth: 10,
		// Set tracesSampleRate to 1.0 to capture 100%
		// of transactions for performance monitoring.
		// We recommend adjusting this value in production
		tracesSampleRate: 1.0,
	})
}

ReactDOM.render(
	<Providers>
		<ClickToComponent />
		<App />
	</Providers>,
	document.getElementById('root'),
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
