import { ErrorData } from '@/interfaces/common'

export enum SnackbarType {
	uploading = 'uploading',
	success = 'success',
	info = 'info',
	error = 'error',
}

export interface NotificationSuccessCallback {
	label: string
	onClick: () => void
}

export interface NotificationMessage {
	type: SnackbarType
	message: string
	callback?: NotificationSuccessCallback
}

export interface Notification {
	key?: any
	message: NotificationMessage
	options?: any
	dismissed?: boolean
}
export interface ExitConfirmationModal {
	isOpen: boolean
	description?: string
	redirectPath?: string
	onConfirm?: () => void
}

export interface AppState {
	isLoading: boolean
	notifications: Notification[]
	error?: ErrorData | null
	exitConfirmationModal: ExitConfirmationModal
}
