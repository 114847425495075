import { ChangeEvent } from 'react'
import makeStyles from '@mui/styles/makeStyles'
import { styles, TextField } from '@/components/shared'
import { IconButton, Typography } from '@mui/material'
import { AnswerDropdownOption } from '../../types'
import CloseIcon from '@mui/icons-material/Close'

const useStyles = makeStyles((theme) => ({
	root: {
		width: styles.FILL_AVAILABLE_WIDTH,
		margin: 10,
		display: 'flex',
		alignItems: 'center',
	},
	text: {
		width: styles.FILL_AVAILABLE_WIDTH,
		margin: '0 5px',
	},
	deleteButton: {
		padding: 5,
		width: 30,
		height: 30,
	},
}))

interface AnswerVariantWithOtherOptionProps {
	index: number
	data: AnswerDropdownOption
	isEdit?: boolean
	canDelete?: boolean
	onChange: (v: string) => void
	onDelete: () => void
}

const AnswerWithOtherOptionCard = ({ index, data, onChange, onDelete, isEdit, canDelete = true }: AnswerVariantWithOtherOptionProps) => {
	const classes = useStyles()

	const handelChangeQuestion = ({ target: { value } }: ChangeEvent<HTMLInputElement>) => onChange(value)

	return (
		<div className={classes.root}>
			<Typography>{index + 1}.</Typography>
			<div>
				{isEdit ? (
					<TextField placeholder="Option" className={classes.text} value={data.label} onChange={handelChangeQuestion} />
				) : (
					<Typography>{data.label || 'Option'}</Typography>
				)}
			</div>
			{isEdit && canDelete && (
				<IconButton className={classes.deleteButton} onClick={onDelete} size="large">
					<CloseIcon fontSize="small" />
				</IconButton>
			)}
		</div>
	)
}

export default AnswerWithOtherOptionCard
