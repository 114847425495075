import { DateTime } from 'luxon'
import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Link } from 'react-router-dom'

import { DataTable } from '@/components/EntityManager/DataTable'
import { EVENT_PATHS } from '@/features/events/constants'
import {
	getOrganizationsEventsListRequest,
	selectCurrentOrganization,
	selectOrganizationEventsList,
	setCurrentOrganizationId,
} from '@/features/organizations/organizationSlice'
import { getFullDateFormatted } from '@/utils/dateTime'
import { GridColumns } from '@mui/x-data-grid'

export type IEventsListProps = {
	orgId: string
}
const columns: GridColumns = [
	{
		field: 'name',
		headerName: 'Event Name',
		flex: 1,
		sortable: true,
		renderCell: (params) => {
			return <Link to={EVENT_PATHS.VIEW_EVENT(params.row.id)}>{params.row.name}</Link>
		},
	},
	{
		field: 'submittedBy',
		headerName: 'Submitted By',
		flex: 1,
	},
	{
		field: 'createdAt',
		headerName: 'Date Submitted',
		flex: 1,
		sortable: true,
		valueGetter: (params: any) => getFullDateFormatted(params.row.createdAt),
	},
	{
		field: 'startDate',
		headerName: 'Event Date',
		flex: 1,
		sortable: true,
		valueGetter: (params: any) => DateTime.fromISO(params.row.startDate).toLocaleString(DateTime.DATE_SHORT),
	},
]

const EventsList: React.FC<IEventsListProps> = ({ orgId }) => {
	const eventsList = useSelector(selectOrganizationEventsList)
	const currentOrganization = useSelector(selectCurrentOrganization)
	const dispatch = useDispatch()

	useEffect(() => {
		dispatch(setCurrentOrganizationId(orgId))
	}, [dispatch, orgId])
	return currentOrganization && currentOrganization.id ? (
		<DataTable data={eventsList} request={getOrganizationsEventsListRequest} columns={columns} />
	) : null
}

export { EventsList }
