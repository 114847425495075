export const ENGAGEMENT_APP_BASE_URL = 'https://engagementapp.psu.edu/app'
export enum LINK_SCOPES {
	EVENT = 'event',
	ORGANIZATION = 'organization',
}

export const engagementAppLink = (scope: LINK_SCOPES, id?: string) => {
	const scopeBase = `${ENGAGEMENT_APP_BASE_URL}/${scope}s`
	return id ? `${scopeBase}/${scope}/${id}` : `${scopeBase}`
}

export const getNetworkUrl = (id?: number | string) => {
	const networkBase = `${ENGAGEMENT_APP_BASE_URL}/network`
	return id ? `${networkBase}/user/${id}` : `${networkBase}/my`
}

export const redirectToSignup = (token: string) => {
	window.location.href = `${process.env.REACT_APP_ENGAGEMENT_APP_URL}?signupToken=${token}&redirect_uri=${encodeURIComponent(
		process.env.REACT_APP_COGNITO_REDIRECT_URL,
	)}`
}
