import { forwardRef } from 'react'
import { useSnackbar } from 'notistack'
import makeStyles from '@mui/styles/makeStyles'
import { ButtonColors, OutlinedButton } from '@/components/shared'
import { CircularProgress } from '@mui/material'
import { SnackbarType } from '@/store/app/types'
import CheckCircleIcon from '@/components/icons/CheckCircleIcon'
import HighlightOffIcon from '@mui/icons-material/HighlightOff'
import appTheme from '@/theme'

const useStyles = makeStyles((theme) => ({
	snackbarRoot: ({ variant }: any) => ({
		backgroundColor: variant,
		color: theme.colors.white[500],
		width: '100vw',
		height: '12vh',
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
	}),
	contentContainer: {
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
		justifyContent: 'center',
	},
	progress: {
		color: theme.colors.white[500],
		marginBottom: '2vh',
	},
	checkIcon: {
		fontSize: '2em',
		marginRight: '1vw',
	},
	callbackButton: {
		marginLeft: '2.5vw',
	},
}))

const getSnackbarColor = (type: SnackbarType) => {
	const colors = {
		[SnackbarType.uploading]: appTheme.colors.Primary[700],
		[SnackbarType.info]: appTheme.colors.Primary[700],
		[SnackbarType.success]: appTheme.colors.green[500],
		[SnackbarType.error]: appTheme.colors.red[500],
	}

	return colors[type]
}

const Snackbar = forwardRef<any, any>(({ id, message, type, callback, ...rest }, ref) => {
	const classes = useStyles({ variant: getSnackbarColor(type) })
	const { closeSnackbar } = useSnackbar()

	let content = null

	const handleClose = () => type !== SnackbarType.uploading && closeSnackbar(id)

	switch (type) {
		case SnackbarType.uploading:
			content = (
				<div className={classes.contentContainer}>
					<CircularProgress size={20} className={classes.progress} />
					{message}
				</div>
			)
			break

		case SnackbarType.success:
			content = (
				<>
					<CheckCircleIcon className={classes.checkIcon} />
					{message}
					{callback && (
						<OutlinedButton className={classes.callbackButton} color={ButtonColors.WHITE} onClick={callback.onClick}>
							{callback.label}
						</OutlinedButton>
					)}
				</>
			)
			break

		case SnackbarType.error:
			content = (
				<>
					<HighlightOffIcon className={classes.checkIcon} />
					{message}
					{callback && (
						<OutlinedButton className={classes.callbackButton} color={ButtonColors.WHITE} onClick={callback.onClick}>
							{callback.label}
						</OutlinedButton>
					)}
				</>
			)
			break

		case SnackbarType.info:
		default:
			content = <>{message}</>
			break
	}

	return (
		<div ref={ref} className={classes.snackbarRoot} onClick={handleClose}>
			{content}
		</div>
	)
})

export default Snackbar
