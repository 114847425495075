import http from '@/api/http/requestHttp'
import { FieldDataType, FormBuilderData } from '@/components/Form/FormBuilder/types'
import * as config from '@/constants/configuration'

interface PatchFormBody {
	isDraft: boolean
	data: Omit<FormBuilderData<FieldDataType>, 'id'>
}

const patchForm = async (id: string, body: PatchFormBody) => {
	const url = `${config.PSU_SCHOOL_ID}/oms/forms/${id}`

	return await http.patch(url, body)
}

export default patchForm
