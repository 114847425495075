import React, { ChangeEvent, useMemo, useState } from 'react'
import { v4 as uuidv4 } from 'uuid'

import AddOutlinedIcon from '@mui/icons-material/AddOutlined'
import AddPhotoAlternateOutlinedIcon from '@mui/icons-material/AddPhotoAlternateOutlined'
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank'
import RadioButtonUncheckedIcon from '@mui/icons-material/RadioButtonUnchecked'
import { IconButton, Typography } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'

import { ButtonColors, ColorButton, ImageUploadField, OutlinedSelect, SelectValue, styles, Toggle } from '@/components/shared'
import { FileTypes } from '@/features/documents/constants'
import { AutocompleteStorageKeys } from '@/utils/autoComplete'
import { componentOptions, dropdownGeneratorMap, TDropdownEntitiesKeys } from './config'
import EditDescriptionField from './EditDescriptionField'
import EditQuestionField from './EditQuestionField'
import AnswerWithOtherOptionCard from './QuestionTypeCards/AnswerWithOtherOptionCard'
import { AutocompleteCard } from './QuestionTypeCards/AutocompleteCard'
import { DatePickerCard } from './QuestionTypeCards/DatePickerCard'
import DropdownCard from './QuestionTypeCards/DropdownCard'
import { GenerateButton } from './QuestionTypeCards/DropdownCard/GenerateButton'
import FileUploadCard from './QuestionTypeCards/FileUploadCard'
import TextAnswerCard from './QuestionTypeCards/TextAnswerCard'
import { AnswerOptionType, CardProps, FieldData, FieldDataType, FieldDataWithOtherAnswer, FormBuilderQuestion, QuestionType } from './types'

const useStyles = makeStyles((theme) => ({
	root: {
		width: styles.FILL_AVAILABLE_WIDTH,
	},
	cardContent: {
		padding: 15,
		display: 'flex',
		flexDirection: 'row',
		alignItems: 'flex-start',
		justifyContent: 'space-between',
	},
	cardFooter: {
		width: styles.FILL_AVAILABLE_WIDTH,
		display: 'flex',
		flexDirection: 'row',
		alignItems: 'center',
		justifyContent: 'space-between',
		backgroundColor: theme.colors.grey[200],
		padding: 15,
	},
	questionInfo: {
		width: '60%',
		padding: '0 10px',
	},
	questionTypeSelectContainer: {
		width: '40%',
		padding: '0 10px',
	},
	questionTypeSelect: {
		width: styles.FILL_AVAILABLE_WIDTH,
		minHeight: 50,
	},
	label: {
		marginBottom: 7,
	},
	itemIcon: {
		color: theme.colors.grey[500],
	},
	addDescriptionButton: {
		color: theme.colors.grey[500],
		...styles.BOX_SHADOW_DOWN,
		marginRight: 15,
		'&:hover': {
			...styles.BOX_SHADOW_DOWN,
		},
		'&:disabled': {
			...styles.BOX_SHADOW_DOWN,
		},
	},
	generatePopper: {
		zIndex: 1300,
	},
	generateDropdown: {
		padding: `${theme.spacing(1)} 0`,
		marginTop: theme.spacing(2),
		width: 200,
	},
	addImageButton: {
		padding: 10,
		width: 42,
		height: 42,
		color: theme.colors.grey[500],
		backgroundColor: theme.colors.white[500],
		...styles.BOX_SHADOW_DOWN,
	},
	imageUpload: {
		margin: '10px 0',
	},
	generationText: {
		width: '100%',
		textAlign: 'center',
		marginTop: 100,
		marginBottom: 100,
		color: theme.colors.grey[300],
	},
}))

interface QuestionCardProps {
	index: number
	data: FormBuilderQuestion<FieldDataType>
	selected?: boolean
	onChange: (data: FormBuilderQuestion<FieldDataType>) => void
}

const QuestionCard = ({ index, data, selected, onChange }: QuestionCardProps) => {
	const classes = useStyles()

	const [isGenerating, setIsGenerating] = useState<boolean>(false)

	const [showImageComponent, setShowImageComponent] = useState(!!data.image)
	const [showDescriptionField, setShowDescriptionField] = useState(!!data.description)

	const components = useMemo(
		() =>
			[
				{
					type: QuestionType.TextAnswer,
					component: TextAnswerCard,
				},
				{
					type: QuestionType.Checkboxes,
					component: (props: CardProps<FieldDataWithOtherAnswer>) => (
						<AnswerWithOtherOptionCard optionTypeElement={<CheckBoxOutlineBlankIcon className={classes.itemIcon} />} {...props} />
					),
				},
				{
					type: QuestionType.Radio,
					component: (props: CardProps<FieldDataWithOtherAnswer>) => (
						<AnswerWithOtherOptionCard optionTypeElement={<RadioButtonUncheckedIcon className={classes.itemIcon} />} {...props} />
					),
				},
				{
					type: QuestionType.Dropdown,
					component: DropdownCard,
				},
				{
					type: QuestionType.FileUpload,
					component: FileUploadCard,
				},
				{
					type: QuestionType.DatePicker,
					component: DatePickerCard,
				},
				{
					type: QuestionType.Autocomplete,
					component: AutocompleteCard,
				},
			] as {
				type: QuestionType
				component: React.FC<CardProps<FieldDataType>>
			}[],
		[classes.itemIcon],
	)

	const handleChange = (newFieldData: FieldData<FieldDataType>) =>
		onChange({
			...data,
			fieldData: {
				...data.fieldData,
				...newFieldData,
			},
		})

	const handleChangeQuestion = (value: string) =>
		onChange({
			...data,
			questionText: value,
		})

	const handleShowDescriptionField = () => setShowDescriptionField(true)

	const handleChangeImage = (imageUrl?: string) =>
		onChange({
			...data,
			image: imageUrl,
		})
	const handleClearUploadImageField = () => {
		handleChangeImage(undefined)
	}
	const handleHideUploadImageField = () => {
		handleChangeImage(undefined)
		setShowImageComponent(false)
	}
	const handleShowUploadImageField = () => setShowImageComponent(true)

	const handleChangeRequiredToggle = ({ target: { checked } }: ChangeEvent<HTMLInputElement>) =>
		onChange({
			...data,
			required: checked,
		})
	const handleDropdownGeneration = () => async (key: TDropdownEntitiesKeys) => {
		setIsGenerating(true)

		const answersList = await dropdownGeneratorMap[key](data.questionType)

		setIsGenerating(false)
		onChange({
			...data,
			fieldData: {
				...data.fieldData,
				answers: answersList,
				generated: key,
			},
		})
	}

	const handleChangeFiledType = (value: SelectValue | SelectValue[]) => {
		const newType = value as QuestionType
		let newData: FieldData<FieldDataType> = {}

		switch (newType) {
			case QuestionType.Checkboxes:
			case QuestionType.Radio:
				newData = {
					...newData,
					answers: [
						{
							id: uuidv4(),
							type: AnswerOptionType.text,
							answer: '',
						},
					],
				}
				break

			case QuestionType.Dropdown:
				newData = {
					...newData,
					multiple: false,
					answers: [
						{
							value: uuidv4(),
							label: '',
						},
					],
				}
				break

			case QuestionType.FileUpload:
				newData = {
					...newData,
					allowedFileTypes: FileTypes.pdf,
					maxFileSize: 15,
					multiple: false,
					fileCount: 1,
				}
				break
			case QuestionType.DatePicker:
				newData = {
					...newData,
					timePicker: false,
				}
				break
			case QuestionType.Autocomplete:
				newData = {
					...newData,
					entity: AutocompleteStorageKeys.Users,
				}
				break
			case QuestionType.TextAnswer:
			default:
				break
		}

		onChange({
			...data,
			fieldData: newData,
			questionType: newType,
		})
	}

	const Component = useMemo(() => {
		const component = components.find((c) => c.type === data.questionType)

		return component ? component.component : null
	}, [components, data.questionType])

	const isEdit = !!selected

	return (
		<div id={`question-${data.id}`} className={classes.root}>
			<div className={classes.cardContent}>
				<div className={classes.questionInfo}>
					<Typography className={classes.label} variant="subtitle1">
						Question {index + 1}
					</Typography>
					<EditQuestionField value={data.questionText} onChange={handleChangeQuestion} isEdit={isEdit} />
					<EditDescriptionField data={data} disabled={!isEdit} onChange={onChange} showDescriptionField={showDescriptionField} />
					{showImageComponent ? (
						<ImageUploadField
							className={classes.imageUpload}
							maxWidth={300}
							value={data.image}
							disabled={!selected}
							onClose={handleHideUploadImageField}
							onClear={handleClearUploadImageField}
							onChange={handleChangeImage}
						/>
					) : null}
					{isGenerating ? (
						<Typography className={classes.generationText}>Please wait, generating data...</Typography>
					) : Component ? (
						<Component data={data.fieldData} onChange={handleChange} isEdit={isEdit} />
					) : null}
				</div>
				{selected && (
					<div className={classes.questionTypeSelectContainer}>
						<Typography className={classes.label} variant="subtitle1">
							Question Type
						</Typography>
						<OutlinedSelect
							classnames={{
								input: classes.questionTypeSelect,
							}}
							value={data.questionType}
							options={componentOptions}
							onChange={handleChangeFiledType}
						/>
					</div>
				)}
			</div>
			{selected && (
				<div className={classes.cardFooter}>
					<div>
						<ColorButton
							color={ButtonColors.WHITE}
							className={classes.addDescriptionButton}
							onClick={handleShowDescriptionField}
							startIcon={<AddOutlinedIcon />}
							disabled={showDescriptionField}
						>
							Add description
						</ColorButton>
						{(data.questionType === QuestionType.Dropdown ||
							data.questionType === QuestionType.Radio ||
							data.questionType === QuestionType.Checkboxes) && (
							<GenerateButton
								classes={{ button: classes.addDescriptionButton, popper: classes.generatePopper, dropdown: classes.generateDropdown }}
								onChange={handleDropdownGeneration()}
							/>
						)}
						<IconButton className={classes.addImageButton} onClick={handleShowUploadImageField} disabled={showImageComponent} size="large">
							<AddPhotoAlternateOutlinedIcon fontSize="small" />
						</IconButton>
					</div>

					<Toggle label={'Required'} labelPosition="start" value={!!data.required} onChange={handleChangeRequiredToggle} />
				</div>
			)}
		</div>
	)
}

export default QuestionCard
