import { useCallback, useMemo } from 'react'
import { useSelector } from 'react-redux'
import { Route, Switch, useHistory } from 'react-router-dom'

import ContentContainer from '@/components/ContentContainer'
import { Header } from '@/components/EntityManager/Header'
import RouterTabs from '@/components/RouterTabs'
import { styles } from '@/components/shared'
import { PATHS, ROLES } from '@/constants'
import { FORM_PATHS } from '@/features/formSubmissions/constants'
import { getSubmissionsListRequest } from '@/features/formSubmissions/formSubmissionsSlice'
import useSubHeader from '@/hooks/useSubHeader'
import { getUserRole } from '@/store/auth'
import { SubHeaderList } from '@/types'
import { isAdmin } from '@/utils/authHandlers'
import { Theme } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'

import MyForms from './Tabs/MyForms'
import SubmissionsTab from './Tabs/Submissions'
import TemplatesTab from './Tabs/Templates'

const useStyles = makeStyles((theme: Theme) => ({
	tabs: {
		minWidth: '60%',
	},
	contentContainer: {
		width: styles.FILL_AVAILABLE_WIDTH,
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'space-between',
	},
}))

const SUB_HEADERS: SubHeaderList = [
	{
		text: 'See which forms are available for you to fill out and submit',
		roles: [ROLES.STUDENT_ADMIN, ROLES.STUDENT],
		path: FORM_PATHS.FORMS,
	},
	{
		text: 'See which forms you’ve submitted. This list excludes event submissions.',
		roles: [ROLES.STUDENT_ADMIN, ROLES.STUDENT],
		path: FORM_PATHS.MY_SUBMISSIONS,
	},
	{
		text: 'See all forms submitted by students',
		roles: [ROLES.CAMPUS_ADMIN],
		path: FORM_PATHS.SUBMISSIONS,
	},
	{
		text: 'See the custom forms you’ve created',
		roles: [ROLES.CAMPUS_ADMIN],
		path: FORM_PATHS.TEMPLATES,
	},
]
const TABS = [
	{
		label: 'Submissions',
		value: FORM_PATHS.SUBMISSIONS,
		roles: [ROLES.CAMPUS_ADMIN],
		component: SubmissionsTab,
	},
	{
		label: 'Templates',
		value: FORM_PATHS.TEMPLATES,
		roles: [ROLES.CAMPUS_ADMIN],
		component: TemplatesTab,
	},
	{
		label: 'Forms',
		value: FORM_PATHS.FORMS,
		roles: [ROLES.STUDENT_ADMIN, ROLES.STUDENT],
		component: MyForms,
	},
	{
		label: 'My Submissions',
		value: FORM_PATHS.MY_SUBMISSIONS,
		roles: [ROLES.STUDENT_ADMIN, ROLES.STUDENT],
		component: SubmissionsTab,
	},
]

const FormsList = () => {
	const classes = useStyles()
	const { push } = useHistory()
	const currentUserRole = useSelector(getUserRole)

	const handleOpenCreateFormPage = useCallback(() => {
		push(PATHS.APP.FORMS_CREATE)
	}, [push])

	const subHeader = useSubHeader(SUB_HEADERS)
	const currentUserTabs = useMemo(() => {
		return TABS.filter((t) => (t.roles && t.roles.includes(currentUserRole)) || !t.roles)
	}, [currentUserRole])
	return (
		<ContentContainer>
			<Header
				title={'Forms'}
				handlePrimaryBtn={isAdmin(currentUserRole) ? handleOpenCreateFormPage : null}
				primaryBtnText={'Create Form'}
				searchPlaceholder={'Search form submissions'}
				searchRequest={getSubmissionsListRequest}
				subHeader={subHeader?.text}
			/>
			<div className={classes.contentContainer}>
				<div className={classes.tabs}>
					<RouterTabs tabs={currentUserTabs} />
				</div>
			</div>
			<Switch>
				{currentUserTabs.map((t) => (
					<Route exact key={t.label} path={t.value} component={t.component} />
				))}
			</Switch>
		</ContentContainer>
	)
}

export default FormsList
