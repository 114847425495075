import { mapFlatToOption } from '@/utils/common'
import { OrganizationStatuses } from './types'

export const ORGANIZATION_ROOT_PATH = `/app/organizations`

export const ORGANIZATION_PATHS = {
	ROOT: ORGANIZATION_ROOT_PATH,
	LIST: `${ORGANIZATION_ROOT_PATH}/list`,
	ACTIVE_LIST: `${ORGANIZATION_ROOT_PATH}/list/active`,
	PENDING_LIST: `${ORGANIZATION_ROOT_PATH}/list/pending`,
	MY: `${ORGANIZATION_ROOT_PATH}/my`,
	CREATE: `${ORGANIZATION_ROOT_PATH}/create`,
	SINGLE: (id?: string) => `${ORGANIZATION_ROOT_PATH}/organization/${id !== undefined ? id : ':id'}`,
	SETTINGS: (id?: string) => `${ORGANIZATION_ROOT_PATH}/organization/${id !== undefined ? id : ':id'}/settings`,
	EVENTS: (id?: string) => `${ORGANIZATION_ROOT_PATH}/organization/${id !== undefined ? id : ':id'}/events`,
	DOCUMENTS: (id?: string) => `${ORGANIZATION_ROOT_PATH}/organization/${id !== undefined ? id : ':id'}/documents`,
	NEW_DOCUMENT: (id?: string) => `${ORGANIZATION_ROOT_PATH}/organization/${id !== undefined ? id : ':id'}/documents/new`,
}

export const OrganizationDescriptionMapper = {
	[OrganizationStatuses.Active]: `This organization is Active. Active Organizations are
			visible on the Engagement App and have all management capabilities. They are fully functional
			within the community.`,
	[OrganizationStatuses.Pending]: `This organization is Pending. Pending Organizations are
		submitted through forms and are not visible to the public on the Engagement App. Pending Organizations can still be accessed by existing admins.`,

	[OrganizationStatuses.Inactive]: `This organization is Inactive. Inactive Organizations are
			not active and cannot be accessed on the Engagement App. They do not have memberships. Only
			Campus Admins can reactivate these organizations.`,

	[OrganizationStatuses.Frozen]: `This organization is Frozen. Frozen Organizations are not visible to the public on the
			Engagement App, but can still be accessed by existing admins and members. Student Admins can
			still edit their organizations.`,

	[OrganizationStatuses.Locked]: `This organization is Locked. Locked Organizations are not visible to the public on the
			Engagement App, but can still be accessed by existing admins and members. Only Campus Admins
			can edit these organizations. Members cannot leave.`,

	[OrganizationStatuses.Dormant]: `This organization is Dormant. Dormant Organizations are active organizations that have not
			done anything besides submitting a re-registration form in two years.`,
}

export enum ORGANIZATION_ROLE {
	MEMBER = 'Member',
	ADMIN = 'Admin',
	SUPER_ADMIN = 'Super Admin',
}

export const ORGANIZATION_PRIMARY_ROLES = {
	rolePrimaryLeader: {
		label: 'Primary Leader',
		description:
			'This person is the current primary leader of an organization. They typically hold the title of President or Executive Director.',
	},
	rolePrimaryContactAffairs: {
		label: 'Primary Contact for Student Affairs',
		description: 'This person is responsible for receiving and acting upon all information sent from Student Affairs.',
	},
	rolePrimaryContactFinance: {
		label: 'Primary Contact for Finance',
		description: 'This person is responsible for receiving and acting upon all information regarding their organization’s finances.',
	},
	rolePrimaryContactPublic: {
		label: 'Primary Contact for the Public',
		description:
			'This person is listed as the Primary Contact of the organization on the Engagement App, and is therefore responsible for receiving and acting upon all information sent from the public.',
	},
} as const

export const organizationStatusOptions = Object.keys(OrganizationStatuses).map(mapFlatToOption)
