import makeStyles from '@mui/styles/makeStyles'
import { styles } from '@/components/shared'
import ItemOrdinalScaleContainer from '../../ItemOrdinalScaleContainer'
import { AnswerDropdownOption, DropdownQuestion } from '../../types'
import AddOption from './AddOption'
import Option from './Option'

const useStyles = makeStyles((theme) => ({
	root: {
		width: styles.FILL_AVAILABLE_WIDTH,
		margin: '10px 0',
	},
}))

interface OptionsCardProps {
	data: DropdownQuestion
	isEdit?: boolean
	onChange: (v: AnswerDropdownOption[]) => void
}

const OptionsCard = ({ data, onChange, isEdit }: OptionsCardProps) => {
	const classes = useStyles()

	const handelChangeItem = (id: number) => (value: string) =>
		onChange(data.answers.map((a, idx) => (idx === id ? { ...a, label: value } : a)))
	const handelDeleteItem = (id: number) => () => onChange(data.answers.filter((a, idx) => idx !== id))
	const handelAddItem = (answer: AnswerDropdownOption) => onChange(data.answers.concat(answer))

	return (
		<div className={classes.root}>
			{data.answers.map((a, idx) => (
				<ItemOrdinalScaleContainer
					key={a.value}
					uniqId={a.value}
					currentIndex={idx}
					itemsArray={data.answers}
					isEdit={isEdit}
					onChange={onChange}
				>
					<Option
						index={idx}
						key={a.value}
						data={a}
						isEdit={isEdit}
						onChange={handelChangeItem(idx)}
						onDelete={handelDeleteItem(idx)}
						canDelete={data.answers.length > 1}
					/>
				</ItemOrdinalScaleContainer>
			))}
			{isEdit && <AddOption onAddNewAnswer={handelAddItem} />}
		</div>
	)
}

export default OptionsCard
