import { v4 as uuidv4 } from 'uuid'

import { FieldDataType, FormBuilderQuestion, QuestionType } from '@/components/Form/FormBuilder/types'
import { TemplatesTabs } from '@/pages/FormsPage/FormsList/Tabs/Templates/tabsData'
import { MODULE_NAME } from '@/store/forms/constants'
import { FormCategoryDto, FormMandatoryQuestions, FormsState } from '@/store/forms/types'
import { createSelector } from '@reduxjs/toolkit'
import orderBy from 'lodash/orderBy'
import { RootState } from '..'

const selectState = (state: { [MODULE_NAME]: FormsState }) => state[MODULE_NAME]

export const getCategories = createSelector(selectState, (state) =>
	state.categories.map((category) => ({
		label: category.name,
		value: category.id,
	})),
)
export const getTemplates = (tab: TemplatesTabs) => createSelector(selectState, (state) => state.templates[tab])
export const getIsLoadingTemplates = createSelector(selectState, (state) => state.isLoading.templates)
export const getIsLoadingUploadFormData = createSelector(selectState, (state) => state.isLoading.uploadingFormData)
export const getIsLoadingIndividualForm = createSelector(selectState, (state) => state.isLoading.individualForm)
export const getIndividualForm = createSelector(selectState, (state) => state.selectedForm)
export const getFormList = createSelector(selectState, (state) => state.formList)

export const selectFormCategorySchemaById = createSelector(
	[(state: RootState): FormCategoryDto[] => state[MODULE_NAME].categories, (state: RootState, id: number): number => id],
	(categories, id): FormBuilderQuestion<FieldDataType>[] => {
		const category = categories.find((category) => category.id === id)

		if (category && category.schema && category.schema.questions) {
			return orderBy<FormMandatoryQuestions>(category.schema.questions, 'indexNumber', 'asc').map((q) => ({
				id: uuidv4(),
				fieldData: q.data,
				questionText: q.question,
				questionType: q.questionType,
				image: q.imageUrl ?? undefined,
				description: q.description,
				mandatory: q.mandatory,
				required: q.required,
			}))
		} else {
			return [
				{
					id: uuidv4(),
					questionText: '',
					questionType: QuestionType.TextAnswer,
					fieldData: {},
				},
			]
		}
	},
)
