import { CloseOutlined } from '@mui/icons-material'
import { IconButton, Modal } from '@mui/material'
import { makeStyles } from '@mui/styles'
import { specialColors } from '@navengage/sen-shared-assets'

const useStyles = makeStyles((theme) => ({
	modal: {
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
		justifyContent: 'center',
	},
	image: {
		width: '100%',
		height: '100%',
		objectFit: 'contain',
		position: 'absolute',
		inset: 'calc(0% + 0px) calc(100% + 0px) calc(0% + 0px) calc(0% + 0px)',
	},
	container: {
		width: '70%',
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
		paddingTop: 'calc(50%)',
		position: 'relative',
		outline: 'none',
	},
	closeButton: {
		position: 'fixed',
		top: 0,
		right: 0,
		width: 40,
		height: 40,
		color: theme.colors.white[500],
		margin: 25,
		backgroundColor: specialColors.blacktransparent60,
		'&:hover': {
			backgroundColor: specialColors.blacktransparent80,
		},
	},
}))

interface ImageViewModalProps {
	imageSrc: string
	onClose: (e: any) => void
}

const ImageViewModal = ({ imageSrc, onClose }: ImageViewModalProps) => {
	const classes = useStyles()

	const open = !!imageSrc

	const handleCloseModal = (e: any) => {
		e.stopPropagation()
		onClose(e)
	}

	return (
		<Modal open={open} onClose={handleCloseModal} className={classes.modal}>
			<div className={classes.container} onClick={handleCloseModal}>
				<IconButton className={classes.closeButton} onClick={handleCloseModal}>
					<CloseOutlined fontSize="medium" />
				</IconButton>
				<img className={classes.image} src={imageSrc} alt={''} />
			</div>
		</Modal>
	)
}

export default ImageViewModal
