import { rootStyles } from '@/styles'

export const baseStyles = {
	...rootStyles,
	section: {
		marginBottom: '46px',
	},
	sectionHeader: {
		marginBottom: 26,
	},
	sectionHeaderTitle: {
		font: 'normal normal bold 25px/34px Open Sans',
		letterSpacing: 0,
		color: '#0D0C22',
		margin: 0,
	},
	sectionHeaderSubtitle: {
		font: 'normal normal 600 15px/20px Open Sans',
		letterSpacing: '0px',
		color: '#616b75', // Accessibility change, fix me @Coll
		marginTop: 8,
	},
}
