import searchOrganizations from './searchOrganizations'
import getListCategories from './getListCategories'
import getFilters from './getFilters'
import createOrganization from './createOrganization'

const organizations = {
	searchOrganizations,
	getListCategories,
	getFilters,
	createOrganization,
}

export default organizations
