import { AvailableGeneratorFileTypes } from './fileExporter/constants'
import { FileExporter } from './fileExporter/FileExporter'
import { TFieldMapper } from './fileExporter/TFieldMapper'

/**
 *
 * @TODO: this should be moved to webworker, pause, cancel needs to be implemented later on
 */

type TApiPaginationParams = {
	limit?: number
	page?: number
}

type TGeneratorConfig = {
	type: AvailableGeneratorFileTypes
	exportId: string
	cols: TFieldMapper
	fileName: string
	apiCall: any
	headers?: string[]
	apiCallArgs?: any[]
	progressTracker?: (exportId: string, progress: number) => void
} & TApiPaginationParams

export const generateExcel = async ({ type, exportId, cols, fileName, apiCall, apiCallArgs, progressTracker }: TGeneratorConfig) => {
	const fileExporter = new FileExporter(type, fileName, exportId, cols, apiCall, apiCallArgs, progressTracker)
	await fileExporter.start()
}
