import { makeStyles } from '@mui/styles'
import { STYLES } from '@/constants'
import { getTimeAgo } from '@/utils/dateTime'
import { Typography } from '@mui/material'
import { DateTime } from 'luxon'

const useStyles = makeStyles((theme) => ({
	contentContainer: {
		width: '100%',
		display: 'flex',
		flexDirection: 'row',
		alignItems: 'center',
		justifyContent: 'center',
		marginRight: 15,
		marginBottom: 10,
	},
	rootContainer: ({ compactView }: any) => ({
		width: '100%',
		margin: '10px 0',
		display: 'flex',
		flexDirection: compactView ? 'column' : 'row',
		alignItems: 'center',
		justifyContent: 'center',
	}),
	contentText: ({ compactView }: any) => ({
		width: compactView ? 300 : STYLES.FILL_AVAILABLE_WIDTH,
	}),
	timeText: {
		color: theme.colors.grey[500],
		display: 'contents',
		whiteSpace: 'nowrap',
	},
	buttonsContainer: ({ compactView }: any) => ({
		marginLeft: 47,
		width: STYLES.FILL_AVAILABLE_WIDTH,
		display: 'flex',
		flexDirection: 'row',
		alignItems: 'center',
		justifyContent: compactView ? 'space-between' : 'flex-end',
	}),
}))

interface NotificationSkeletonProps {
	content: string
	createdAt: string
	compactView?: boolean
	iconComponent: React.ReactNode
	actionButtons: React.ReactNode
}

const getTimeAgoDisplay = (createdAt) => {
	const dateTime = DateTime.fromISO(createdAt)
	const timeAgo = getTimeAgo(createdAt)

	if (timeAgo.days > 20) {
		return dateTime.toFormat('h:mm A MM/DD/YY')
	}

	if (timeAgo.days >= 1) {
		return dateTime.toRelativeCalendar()
	}

	return dateTime.toRelative()
}

const NotificationSkeleton = ({ content, createdAt, compactView = false, iconComponent, actionButtons }: NotificationSkeletonProps) => {
	const classes = useStyles({ compactView })

	return (
		<div className={classes.rootContainer}>
			<div className={classes.contentContainer}>
				{iconComponent}
				<Typography className={classes.contentText}>
					{content}
					<Typography component="span" className={classes.timeText}>
						{' '}
						{getTimeAgoDisplay(createdAt)}
					</Typography>
				</Typography>
			</div>
			<div className={classes.buttonsContainer}>{actionButtons}</div>
		</div>
	)
}

export default NotificationSkeleton
