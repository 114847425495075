import http from '@/api/http/requestHttp'
import { FieldDataType, FormBuilderData } from '@/components/Form/FormBuilder/types'
import * as config from '@/constants/configuration'

interface CreateFormBody {
	isDraft: boolean
	data: Omit<FormBuilderData<FieldDataType>, 'id'>
}

const postForm = async (body: CreateFormBody) => {
	const url = `${config.PSU_SCHOOL_ID}/oms/forms`

	return await http.post<{ id: string }>(url, body)
}

export default postForm
