import classNames from 'classnames'

import { Tab as TabComponent, Tabs as NavTabs } from '@/components/shared'
import makeStyles from '@mui/styles/makeStyles'

const getBottomLinePosition = (props: TabsProps): number => {
	const indicatorHeight = props.indicatorWidth ?? 3
	const lineHeight = props.borderWidth ?? 1
	return (indicatorHeight - lineHeight) / 2
}

const useStyles = makeStyles((theme) => ({
	tab: {
		padding: '0 2px',
		marginRight: '2.5vw',
		fontSize: '18px',
		lineHeight: '24px',
		fontWeight: 600,
		display: 'flex',
		flexDirection: 'row-reverse',
	},
	tabsContainer: {
		position: 'relative',
		'&:after': {
			content: '""',
			display: 'block',
			position: 'absolute',
			width: '100%',
			bottom: (props: TabsProps) => getBottomLinePosition(props),
			left: 0,
			height: (props: TabsProps) => props.borderWidth ?? 1,
			background: (props: TabsProps) => (props.borderColor ? props.borderColor : '#f2f2f2'),
		},
	},
	tabWrapper: {
		marginTop: 0,
		display: 'flex',
		flexDirection: 'row-reverse',
		alignItems: 'center',
		'& > *:first-child': {
			marginBottom: '0 !important',
		},
	},
	selectedTab: {
		color: theme.colors.Primary[700],
	},
	unselectedTab: {
		color: theme.colors.grey[500],
	},
	indicator: {
		height: (props: TabsProps) => props.indicatorWidth ?? 3,
		backgroundColor: theme.colors.Primary[700],
		zIndex: 2,
		borderRadius: 99,
	},
}))

export interface Tab {
	label: string
	icon?: React.ReactElement<any, string | React.JSXElementConstructor<any>>
	disabled?: boolean
}

interface TabsProps {
	activeTabIndex: number
	centered?: boolean
	hideIndicator?: boolean
	tabs: Tab[]
	handleChangeTab: (index: number, e: any) => void
	tabsContainerClassName?: string
	unselectedTabClass?: string
	borderColor?: string
	borderWidth?: number
	indicatorWidth?: number
}

const Tabs = (props: TabsProps) => {
	const {
		activeTabIndex,
		hideIndicator = false,
		centered = false,
		tabs,
		handleChangeTab,
		tabsContainerClassName = '',
		unselectedTabClass = '',
	} = props
	const classes = useStyles(props)

	return (
		<NavTabs
			classnames={{
				indicator: classes.indicator,
				root: classNames(tabsContainerClassName, classes.tabsContainer),
			}}
			activeTabIndex={activeTabIndex}
			centered={centered}
			hideIndicator={hideIndicator}
			tabsRenderer={(props) =>
				tabs.map((tab, index) => (
					<TabComponent
						classnames={{
							tab: classes.tab,
							tabWrapper: classes.tabWrapper,
							selectedTab: classes.selectedTab,
							unselectedTab: unselectedTabClass === '' ? classes.unselectedTab : unselectedTabClass,
						}}
						key={`${tab}-${index}`}
						index={index}
						label={tab.label}
						onClick={handleChangeTab}
						icon={tab.icon}
						disabled={tab.disabled}
						{...props}
					/>
				))
			}
		/>
	)
}

export default Tabs
