import { createPalette } from '@navengage/sen-shared-assets'

const palette = createPalette('psu')

// --->>>>>>>>> old palette <<<<<<<<---
//
// const palette = {
// specialColors.transparent  transparent: '#ffffff00',
// 500  black: '#0d0e10',
// specialColors.blacktransparent60  blackSemiTranspanent: 'rgba(13, 14, 16, 0.55)',
// specialColors.blacktransparent80  blackSemiTranspanentHover: 'rgba(13, 14, 16, 0.75)',
// 500  grey1: '#7b8794',
// 300  grey2: '#d7d9de',
// 200  grey3: '#f2f2f2',
//   grey3hover: 'rgb(224 224 224)',
// 100  grey4: '#fafafa',
// 500  white: '#ffffff',
//   whiteHover: 'rgba(0, 0, 0, 0.04)',
// specialColors.whitetransparent10  whiteSemiTranspanent: '#EAF7FD1a',
// 500  blue1: '#009cde',
// 550  blue1hover: '#0797D5',
// 100  blue2: '#eaf7fd',
//   blue2hover: '#009cde10',
//   blue2disabled: '#00c4ff38',
// 600  blue3: '#1da6fc',
// 700  blue4: '#1071ea',
//   blue4hover: '#0c56b2',
//   blue4disabled: '#0b5fc7b5',
// 800  blue5: '#1e407c',
// 500094  gradientBlue: 'linear-gradient(355deg, rgba(0,156,222,1) 0%, rgba(0,188,222,1) 94%)',
// 1000  darkblue: '#021a43',
// 1500  violet: '#0D0C22',
// 500  green1: '#3dd8ba',
//   green1disabled: '#5abdaa8f',
// 100  green2: '#ebfbf8',
//   green2hover: '#ebfbf8b5',
//   green2disabled: '#d6eae76e',
// 200  green3: '#13bc9b',
// 500094  gradientGreen: 'linear-gradient(355deg, rgba(61,216,186,1) 0%, rgba(61,216,172,1) 61%, rgba(61,216,150,1) 94%)',
// 500  red1: '#f2665e',
//   red1hover: '#f7564d',
//   red1disabled: '#ffaca7',
// 200  red2: '#fef0ef',
//   red2hover: '#ffdedb73',
// 500094  gradientRed: 'linear-gradient(355deg, rgba(250,21,100,1) 0%, rgba(242,102,94,1) 94%)',
// 500  yellow1: '#ffea3e',
//   yellow1hover: '#bbab2c',
//   yellow1disabled: '#9d923a',
// 500094  gradientYellow: 'linear-gradient(355deg, rgba(255,203,62,1) 0%, rgba(255,233,62,1) 94%)',
// 500  orange: '#fd962f',
// 500094  gradientOrange: 'linear-gradient(355deg, rgba(253,150,47,1) 0%, rgba(253,117,47,1) 94%)',
// };

export default palette
