import { useEffect, useState } from 'react'

import { CheckBox } from '@/components/shared'
import Close from '@mui/icons-material/Close'
import { IconButton, Typography } from '@mui/material'
import { makeStyles } from '@mui/styles'

const useStyles = makeStyles((theme) => ({
	toolRoot: {
		width: '100%',
		display: 'flex',
		flexDirection: 'column',
		border: `solid 1px ${theme.colors.grey[500]}`,
		borderLeft: 'none',
		borderRight: 'none',
		padding: '2vh 0',
		margin: '4vh 0',
	},
	closeIcon: {
		fontSize: '0.55em',
	},
	closeButton: {
		height: '40%',
		padding: 4,
		marginLeft: '1vw',
		backgroundColor: '#927f7f42',
		borderRadius: '100%',
		boxShadow: '-1px 3px 6px -1px rgba(181,181,181,0.93)',
	},
	toolHeader: {
		color: theme.colors.black[500],
		display: 'flex',
		flexDirection: 'row',
		alignItems: 'center',
		margin: '1vh 0 2vh 0',
	},
	title: {
		fontWeight: 700,
	},
}))

const checkBoxes = [
	{
		disabled: false,
		value: false,
		label: 'Set following shifts into 1-hr increments',
		step: 4,
	},
	{
		disabled: false,
		value: false,
		label: 'Set following shifts into 30-m increments',
		step: 2,
	},
]

const getTimeOptionIndex = (start: number, current: number, step: number, max: number) => {
	const calculatedOptionId = start + current * step
	const optionId = calculatedOptionId < max ? calculatedOptionId : calculatedOptionId - max

	return optionId
}

const TimeSaverTool = ({ onChange, values, options = [] }: any) => {
	const classes = useStyles()

	const [isShowTool, setIsShowTool] = useState(true)
	const [toolCheckBoxes, setToolCheckBoxes] = useState(checkBoxes)

	const handleCloseTool = () => setIsShowTool(false)

	const handleChangeCheckBox = (index: number) => (newValue: any) => {
		const checked = newValue.currentTarget.checked

		setToolCheckBoxes(
			toolCheckBoxes.map((checkBox: any, idx: number) => {
				if (idx === index) {
					return { ...checkBox, value: checked }
				}
				return { ...checkBox, value: false }
			}),
		)
	}

	useEffect(() => {
		const selectedCheckBox = toolCheckBoxes.filter((c) => c.value)
		if (selectedCheckBox && selectedCheckBox.length) {
			const startFromIndex = options.findIndex((opt: any) => opt.value === values[0].end)
			onChange(
				values.reduce((acc: any, val: any, idx: number) => {
					const endTimeId = getTimeOptionIndex(startFromIndex, idx, selectedCheckBox[0].step, options.length)

					if (idx) {
						return [...acc, { start: acc[idx - 1].end, end: options[endTimeId].value }]
					}
					return [val]
				}, []),
			)
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [toolCheckBoxes])

	return isShowTool ? (
		<div className={classes.toolRoot}>
			<div className={classes.toolHeader}>
				<Typography component="span" className={classes.title} variant="h2">
					Time Saver Tool
				</Typography>
				<IconButton className={classes.closeButton} onClick={handleCloseTool}>
					<Close className={classes.closeIcon} fontSize="small" />
				</IconButton>
			</div>
			{toolCheckBoxes.map((c, i) => (
				<CheckBox {...c} onChange={handleChangeCheckBox(i)} key={c.step} />
			))}
		</div>
	) : null
}

export default TimeSaverTool
