import { Box, styled } from '@mui/material'

const TextFieldAnswer = styled(Box)(({ theme }) => ({
	maxWidth: '70%',
	minWidth: 300,
	margin: `${theme.spacing(1.875)} 0`,
	padding: `${theme.spacing(2)} ${theme.spacing(1.75)}`,
	border: `1px solid ${theme.colors.grey[200]}`,
	color: theme.colors.grey[400],
	...theme.shape,
}))

export default TextFieldAnswer
