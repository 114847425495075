import { push } from 'connected-react-router'
import { isString } from 'lodash'
import React from 'react'
import { useDispatch } from 'react-redux'

import { OptionButton } from '@/components/shared'
import usePopper from '@/hooks/usePopper'
import AddIcon from '@mui/icons-material/Add'
import { Button } from '@mui/material'
import { makeStyles } from '@mui/styles'

import DropdownContainer from './DropdownContainer'
import HeaderPopper from './HeaderPopper'

const useStyles = makeStyles((theme) => ({
	root: {
		marginRight: theme.spacing(6),
	},
	createButton: {
		...theme.typography.body1,
		color: theme.colors.black[500],
		fontWeight: theme.typography.fontWeightMedium,
		padding: `${theme.spacing(1.5)} ${theme.spacing(5)}`,
		backgroundColor: `${theme.colors.Neutral[100]}`,
		textTransform: 'none',
		boxShadow: '0px 3px 6px #0000000F',
	},
	optionsContainer: {
		minWidth: 390,
		maxHeight: 'auto',
		marginTop: 34,
		padding: `${theme.spacing(2)} 0`,
	},
	optionButton: {
		justifyContent: 'flex-start',
		padding: `${theme.spacing(2.125)} ${theme.spacing(3.625)}`,
		borderRadius: 0,
		fontWeight: theme.typography.fontWeightMedium,
	},
}))

type ICreateButtonProps = {
	actionList: any
}

const CreateButton: React.FC<ICreateButtonProps> = ({ actionList }) => {
	const dispatch = useDispatch()
	const classes = useStyles()
	const { handleToggle, anchorRef, popperRef, open } = usePopper()

	const clickHandler = (action: any) => () => {
		if (isString(action.path)) {
			dispatch(push(action.path))
		}
		if (isString(action.url)) {
			window.open(action.url, '_blank')
		}
		handleToggle()
	}
	return (
		<div className={classes.root}>
			<Button ref={anchorRef} onClick={handleToggle} className={classes.createButton} startIcon={<AddIcon />}>
				Create
			</Button>
			<HeaderPopper placement="bottom-end" ref={popperRef} open={open} anchorEl={anchorRef.current} role={undefined}>
				<DropdownContainer className={classes.optionsContainer}>
					{Object.keys(actionList).map((key) => (
						<OptionButton className={classes.optionButton} key={key} onClick={clickHandler(actionList[key])}>
							{actionList[key].label}
						</OptionButton>
					))}
				</DropdownContainer>
			</HeaderPopper>
		</div>
	)
}

export default CreateButton
