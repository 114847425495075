import { Typography } from '@mui/material'
import { makeStyles } from '@mui/styles'

import { baseStyles } from '../baseStyles'
import { basicInfoQuestions } from './questions'

const useStyles = makeStyles(() => ({
	...baseStyles,
}))

export const BasicInformationSection = (props: { stateControl }) => {
	const classes = useStyles()

	return (
		<section className={classes.section}>
			<div className={classes.sectionHeader}>
				<Typography className={classes.sectionHeaderTitle} variant="h2">
					Basic Information
				</Typography>
			</div>
			{basicInfoQuestions.map(({ label, description, isRequired, Input }) => {
				return (
					<div key={label} className={classes.genericQuestion}>
						<h3 className={classes.questionLabel}>
							{label}
							{isRequired && <span className={classes.questionRequiredIndicator}>*</span>}
						</h3>
						{description && <p className={classes.questionDescription}>{description}</p>}
						<Input {...props} />
					</div>
				)
			})}
		</section>
	)
}
