import { AnswerDropdownOption, CardProps, DropdownQuestion } from '../../types'
import OptionsCard from './OptionsCard'
import { Settings } from './Settings'

type TSettingKey = keyof DropdownQuestion

const DropdownCard = ({ data, onChange, isEdit }: CardProps<DropdownQuestion>) => {
	const handelChangeAnswers = (value: AnswerDropdownOption[]) => onChange({ ...data, answers: value })
	const onSettingChange = (key: TSettingKey, value) => {
		onChange({
			...data,
			[key]: value,
		})
	}
	return (
		<>
			{isEdit && <Settings onChange={onSettingChange} {...data} />}

			<OptionsCard data={data as DropdownQuestion} onChange={handelChangeAnswers} isEdit={isEdit} />
		</>
	)
}

export default DropdownCard
