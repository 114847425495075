import React from 'react'
import { useSelector } from 'react-redux'
import { Link } from 'react-router-dom'

import { DataTable } from '@/components/EntityManager/DataTable'
import { GridColumns } from '@mui/x-data-grid'

import { USERS_PATHS } from '../../constants'
import { getUsersListRequest, selectUsersList } from '../../usersSlice'

const columns: GridColumns = [
	{
		field: 'userName',
		headerName: 'Name',
		flex: 1,
		renderCell: (params) => {
			if (params.row.userId) {
				return <Link to={USERS_PATHS.VIEW_USER(params.row.userId)}>{params.value}</Link>
			}
			return params.value
		},
	},
	{
		field: 'email',
		headerName: 'Email',
		flex: 1,
	},
	{
		field: 'organizations',
		headerName: 'Org Involvement',
		flex: 1,
	},
]

const Students = () => {
	const usersList = useSelector(selectUsersList)

	return (
		<>
			<DataTable data={usersList} request={getUsersListRequest} columns={columns} />
		</>
	)
}

export default Students
