import { useState } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { useDispatch } from 'react-redux'
import * as yup from 'yup'

import FieldErrorMessage from '@/components/FieldErrorMessage'
import { ColorButton, Label } from '@/components/shared'
import { yupResolver } from '@hookform/resolvers/yup'
import { makeStyles } from '@mui/styles'

import { EntityAutocomplete } from '@/components/EntityAutocomplete'
import { AutocompleteStorageKeys } from '@/utils/autoComplete'
import { addCampusAdminRequest, getCampusAdminsListRequest } from '../usersSlice'

const useStyles = makeStyles((theme) => ({
	label: {
		marginBottom: theme.spacing(3),
	},
	formActions: {
		textAlign: 'center',
	},
	formAction: {
		marginTop: theme.spacing(3),
		minWidth: 227,
		padding: theme.spacing(1.5),
		textTransform: 'none',
	},
}))
const schema = yup.object().shape({
	userId: yup.number().required(),
})

interface CampusAdminParams {
	userId: number
}
const CampusAdminForm = ({ onSubmit }) => {
	const dispatch = useDispatch()
	const [loading, setLoading] = useState(false)
	const classes = useStyles()
	const {
		control,
		handleSubmit,
		formState: { errors, isValid },
	} = useForm({
		resolver: yupResolver(schema),
		mode: 'onChange',
		reValidateMode: 'onChange',
		criteriaMode: 'firstError',
		shouldFocusError: true,
	})

	const submitHandler = async (data: CampusAdminParams) => {
		try {
			setLoading(true)
			await dispatch(addCampusAdminRequest(data))
			await dispatch(getCampusAdminsListRequest({ page: 1 }))
			setLoading(false)
			if (onSubmit) onSubmit()
		} catch (error: any) {
			// TODO: show error
		}
	}
	return (
		<div>
			<Controller
				name="userId"
				control={control}
				render={({ field }) => {
					const { name, onChange } = field
					return (
						<Label classnames={{ root: classes.label }} title="User" required>
							<div>Campus Admin Autocomplete</div>
							<EntityAutocomplete onChange={(item) => onChange(item.id)} entity={AutocompleteStorageKeys.CampusAdminCandidates} />
							<FieldErrorMessage hasError={!!errors[name]} message={errors[name] ? errors[name].message : ''} />
						</Label>
					)
				}}
			/>
			<div className={classes.formActions}>
				<ColorButton disabled={!isValid || loading} onClick={handleSubmit(submitHandler)} className={classes.formAction}>
					Add
				</ColorButton>
			</div>
		</div>
	)
}

export default CampusAdminForm
