import ExcelFileGenerator from './fileGenerators/ExcelFileGenerator'
import JsonFileGenerator from './fileGenerators/JsonFileGenerator'

export enum AvailableGeneratorFileTypes {
	JSON = 'json',
	EXCEL = 'excel',
}

export const GeneratorConfigMap = {
	[AvailableGeneratorFileTypes.JSON]: JsonFileGenerator,
	[AvailableGeneratorFileTypes.EXCEL]: ExcelFileGenerator,
}
