import React from 'react'
import { Theme } from '@mui/material/styles'
import makeStyles from '@mui/styles/makeStyles'
import { styles } from '../../../constants'

interface StyleProps {
	height?: number
}

const useStyles = makeStyles<Theme, StyleProps>((theme) => ({
	root: ({ height }) => ({
		position: 'fixed',
		zIndex: 2,
		top: 0,
		left: 0,
		width: '100%',
		backgroundColor: theme.colors.white[500],
		height: `${height ?? styles.HEADER_HEIGHT}vh`,
		display: 'flex',
		flexDirection: 'row',
		justifyContent: 'space-between',
		boxShadow: '0px 7px 15px -1px rgba(181,181,181,0.33)',
	}),
	left: {
		display: 'flex',
		flexDirection: 'row',
	},
	content: {
		width: styles.FILL_AVAILABLE_WIDTH,
		display: 'flex',
		flexDirection: 'row',
		justifyContent: 'flex-end',
		alignItems: 'center',
	},
	logo: {
		marginLeft: '0.3vw',
		height: '9vh',
		cursor: 'pointer',
	},
}))

export interface HeaderProps extends StyleProps {
	logoSrc: string
	onClickLogo?: (e: React.SyntheticEvent<HTMLDivElement>) => void
	children?: React.ReactNode
}

const Header = ({ height, onClickLogo, children, logoSrc }: HeaderProps) => {
	const classes = useStyles({ height })

	return (
		<header className={classes.root}>
			<div className={classes.left}>
				<div onClick={onClickLogo}>
					<img className={classes.logo} src={logoSrc} alt="PennState logo" />
				</div>
			</div>
			<div className={classes.content}>{children}</div>
		</header>
	)
}

export default Header
