import classNames from 'classnames'
import { ReactNode } from 'react'

import { Button } from '@mui/material'
import { makeStyles } from '@mui/styles'
import { getHoverColor, specialColors } from '@navengage/sen-shared-assets'

import { MuiButtonColors } from './shared/theme/colors/buttonColors'

export interface ButtonProps {
	variant?: 'primary' | 'secondary'
	color?: MuiButtonColors
	children?: React.ReactNode
	onClick?: (e?: any) => void
	disabled?: boolean
	className?: string
	type?: 'button' | 'reset' | 'submit' | undefined
	onMouseEnter?: (e?: any) => void
	onMouseLeave?: (e?: any) => void
	component?: any
}

const useStyles = makeStyles((theme) => ({
	root: ({ variant, color }: any) => ({
		borderRadius: 7,
		fontWeight: 600,
		backgroundColor: variant === 'primary' ? specialColors.transparent : theme.colors.Primary[700],
		color: theme.colors.white[500],
		textTransform: 'none',
		boxShadow: 'none',
		[theme.breakpoints.up('lg')]: {
			fontSize: '1.1em',
		},
		[theme.breakpoints.up('xl')]: {
			fontSize: '1.125em',
		},
		[theme.breakpoints.down('lg')]: {
			fontSize: '0.96em',
		},
		[theme.breakpoints.down('xs')]: {
			fontSize: '0.7em',
			minWidth: 100,
			minHeight: 36,
		},
		'&:hover': {
			backgroundColor: variant === 'primary' ? getHoverColor(theme.colors.white) : getHoverColor(theme.colors.Primary),
		},
	}),
}))

const IconButton = ({
	variant = 'primary',
	children = '',
	onClick,
	className,
	disabled,
	startIcon,
	endIcon,
	component,
	color,
}: ButtonProps & { startIcon?: ReactNode; endIcon?: ReactNode; component?: any }) => {
	const classes = useStyles({ variant, color })

	return (
		<Button
			onClick={onClick}
			disabled={disabled}
			color={color}
			className={classNames(classes.root, className)}
			startIcon={startIcon}
			component={component}
			endIcon={endIcon}
		>
			{children}
		</Button>
	)
}

export default IconButton
