import { Badge, BadgeProps } from '@mui/material'

const BADGE_MAX_COUNT = 99

export type ICounterBadgeProps = BadgeProps

const CounterBadge: React.FC<ICounterBadgeProps> = ({ children, ...rest }) => {
	return (
		<Badge color={rest.color || 'error'} max={rest.max || BADGE_MAX_COUNT} {...rest}>
			{children}
		</Badge>
	)
}

export default CounterBadge
