import http from '@/api/http/requestHttp'

import * as config from '@/constants/configuration'

const deleteFormById = async (id: string) => {
	const url = `${config.PSU_SCHOOL_ID}/oms/forms/${id}`

	return await http.delete(url)
}

export default deleteFormById
