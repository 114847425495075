import { EVENT_PATHS } from '@/features/events/constants'
import { ORGANIZATION_PATHS } from '@/features/organizations/constants'
import { OrganizationStatuses } from '@/features/organizations/types'
import { USERS_PATHS } from '@/features/users/constants'
import { createQueryFilterFunction } from '@/utils/gridHelper'
import { MetricsGroup } from './types'

const createStatusFilterUrl = createQueryFilterFunction(ORGANIZATION_PATHS.ACTIVE_LIST, {
	columnField: 'status',
	operatorValue: 'is',
})

export const metricsConfig: { metrics: { [key: string]: MetricsGroup } } = {
	metrics: {
		organizations: {
			title: 'Organizations',
			metrics: [
				{
					title: 'Active',
					backgroundColor: '#EBFBF8',
					getCount: (stats) => stats.active,
					url: createStatusFilterUrl(OrganizationStatuses.Active),
				},
				{
					title: 'Frozen',
					getCount: (stats) => stats.frozen,
					backgroundColor: '#EAF7FD',
					url: createStatusFilterUrl(OrganizationStatuses.Frozen),
				},
				{
					title: 'Locked',
					getCount: (stats) => stats.locked,
					backgroundColor: '#FBE6D0',
					url: createStatusFilterUrl(OrganizationStatuses.Locked),
				},
				{
					title: 'Inactive',
					getCount: (stats) => stats.inactive,
					backgroundColor: '#FBEFD8',
					url: createStatusFilterUrl(OrganizationStatuses.Inactive),
				},
				{
					title: 'Dormant',
					getCount: (stats) => stats.dormant,
					backgroundColor: '#FEF0EF',
					url: createStatusFilterUrl(OrganizationStatuses.Dormant),
				},
			],
		},
		users: {
			metrics: [
				// @TODO: correct urls when filters are created for users list
				{ title: 'Students', getCount: (stats) => stats.students, url: USERS_PATHS.LIST('list') },
				{ title: 'Student Admins', getCount: (stats) => stats.studentAdmins, url: USERS_PATHS.LIST('list') },
				{ title: 'Campus Admins', getCount: (stats) => stats.campusAdmins, url: USERS_PATHS.LIST('campus-admins') },
			],
		},
		events: {
			title: 'Events',
			metrics: [
				{
					title: 'Upcoming Events',
					date: 30,
					getCount: (stats) => stats.upcomingEvents,
					// @TODO: correct url when filter is created for upcoming event in the backend
					url: EVENT_PATHS.NEW_LIST,
				},
				{ title: 'Past Events', getCount: (stats) => stats.pastEvents },
			],
		},
		serviceHours: {
			metrics: [{ title: 'Approved Service Hours', getCount: (stats) => stats.approvedHours }],
		},
	},
}
