import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useRouteMatch } from 'react-router-dom'

import { BackButton } from '@/components/BackButton'
import { MiddlePaneLayout } from '@/components/layouts/dashboard/MiddleLayout'
import SinglePageTop from '@/components/SinglePageTop'
import { styled } from '@mui/material'
import { Box } from '@mui/system'

import Details from '../components/details/Details'
import { EventSidebar } from '../components/EventSidebar'
import { getEventByIdRequest, selectCurrentEvent } from '../eventsSlice'
import { EventStatuses } from '../types'

const ContentWrapper = styled(Box, {
	shouldForwardProp: (prop) => prop !== 'active',
})<{ active: boolean }>(({ active }) => ({
	display: 'flex',
	justifyContent: active ? 'space-between' : 'center',
}))

const ViewEvent = React.memo(() => {
	const {
		params: { id },
	} = useRouteMatch<{ id: string }>()
	const dispatch = useDispatch()
	const event = useSelector(selectCurrentEvent)

	useEffect(() => {
		dispatch(getEventByIdRequest(id))
	}, [dispatch, id])

	return (
		<MiddlePaneLayout>
			<SinglePageTop>
				<BackButton />
			</SinglePageTop>

			{event && event.id === id && (
				<ContentWrapper active={event.status === EventStatuses.Approved}>
					<Details data={event} />
					{event.status === EventStatuses.Approved && <EventSidebar id={event.id} />}
				</ContentWrapper>
			)}
		</MiddlePaneLayout>
	)
})

export { ViewEvent }
