import { Box, CircularProgress, styled } from '@mui/material'

const LoaderContainer = styled(Box, { shouldForwardProp: (prop) => prop !== 'minHeight' })<{
	minHeight?: number
}>(({ theme, minHeight }) => ({
	display: 'flex',
	minHeight: minHeight,
	justifyContent: 'center',
	alignItems: 'center',
}))

const MiddlePaneLoader = ({ minHeight = 400 }) => {
	return (
		<LoaderContainer minHeight={minHeight}>
			<CircularProgress />
		</LoaderContainer>
	)
}

export default MiddlePaneLoader
