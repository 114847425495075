import { FieldDataType, FormBuilderData } from '@/components/Form/FormBuilder/types'
import { FormDataDto, FormQuestionDto } from '@/store/forms/types'
import { EntityListing } from '@/types'

export type TForm = {
	formData: FormDataDto
	formQuestions: FormQuestionDto[]
}
export type TFormData = TForm & {
	builder: FormBuilderData<FieldDataType>
}

export type TFormListItem = {
	id: string
	title: string
	description: string
	category: string
}

export enum SubmissionStatuses {
	Pending = 'Pending',
	Approved = 'Approved',
	Denied = 'Denied',
	InReview = 'In Review',
}

export type TSubmission = {
	id: string
	formId: string
	title: string
	category: string
	user: string
	status: `${SubmissionStatuses}`
	createdAt: string
}

export type SubmissionsDto = EntityListing<TSubmission>

export type FormsListDto = EntityListing<TFormListItem>
