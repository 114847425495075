import http from '@/api/http/requestHttp'
import * as config from '@/constants/configuration'

const getUserProfileImage = async (externalId?: string) => {
	const url = `${config.PSU_SCHOOL_ID}/photos/profilePhoto/${externalId}`

	return await http.get(url)
}

export default getUserProfileImage
