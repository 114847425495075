import * as config from '@/constants/configuration'
import { get, post, errorHandler } from '@/api/Cognito/methods'

export const signIn = (authCodeParse: any) => {
	const url = 'oauth2/token'
	const rawBody = {
		code: authCodeParse,
		redirect_uri: config.APP_URL,
		grant_type: 'authorization_code',
		client_id: config.COGNITO_POOL_CLIENT_ID,
	}
	const body = new URLSearchParams(rawBody)

	return errorHandler(post, url, body)
}

export const getUserInfo = (token: any) => {
	const url = 'oauth2/userInfo'
	const additionalConfiguration = { headers: { token } }

	return errorHandler(get, url, {}, additionalConfiguration)
}

export const refreshToken = (token: any) => {
	const url = 'oauth2/token'
	const rawBody = {
		refresh_token: token,
		grant_type: 'refresh_token',
		redirect_uri: config.APP_URL,
		client_id: config.COGNITO_POOL_CLIENT_ID,
	}

	const body = new URLSearchParams(rawBody)

	return errorHandler(post, url, body)
}

export const signOut = () => {
	window.open(config.COGNITO_LOGOUT_CALL, '_self')
}
