import React, { useMemo } from 'react'
import { useSelector } from 'react-redux'
import { Redirect, Route, Switch, useParams } from 'react-router-dom'

import { BackButton } from '@/components/BackButton'
import { MiddlePaneLayout } from '@/components/layouts/dashboard/MiddleLayout'
import RouterTabs from '@/components/RouterTabs'
import SinglePageTop from '@/components/SinglePageTop'
import DocumentList from '@/features/documents/pages/DocumentList'
import { engagementAppLink, LINK_SCOPES } from '@/utils/linksHelper'
import { Chip, Link, styled, Typography } from '@mui/material'
import { makeStyles } from '@mui/styles'

import { ORGANIZATION_PATHS } from '../../constants'
import { selectCurrentOrganization } from '../../organizationSlice'
import { EventsList } from './tabs/EventsList'
import { Settings } from './tabs/Settings'

const RoleLabel = styled(Chip)(({ theme }) => ({
	height: 'auto',
	...theme.typography.body1,
	borderRadius: 5,
	padding: `${theme.spacing(1)} ${theme.spacing(1.25)}`,
	backgroundColor: theme.colors.Neutral[200],
	color: theme.colors.Neutral[500],
	marginLeft: theme.spacing(2),
}))

const useStyles = makeStyles((theme) => ({
	header: {
		marginBottom: theme.spacing(4.5),
	},
	pageDescription: {},
	orgNameContainer: {
		display: 'flex',
		alignItems: 'center',
		marginBottom: theme.spacing(2),
	},
	orgName: {
		fontSize: 35,
		lineHeight: '47px',
		fontWeight: theme.typography.fontWeightBold,
	},
}))

const ViewOrganizationPage = () => {
	const classes = useStyles()
	const { id } = useParams<{ id?: string }>()
	const currentOrganizationData = useSelector(selectCurrentOrganization)
	const appLink = engagementAppLink(LINK_SCOPES.ORGANIZATION, id)

	const TABS = useMemo(
		() => [
			{
				label: 'Settings',
				value: ORGANIZATION_PATHS.SETTINGS(id),
			},
			{
				label: 'Events',
				value: ORGANIZATION_PATHS.EVENTS(id),
			},
			{
				label: 'Documents',
				value: ORGANIZATION_PATHS.DOCUMENTS(id),
			},
		],
		[id],
	)

	return (
		<MiddlePaneLayout>
			<SinglePageTop>
				<BackButton />
			</SinglePageTop>
			{currentOrganizationData && (
				<div className={classes.header}>
					<div className={classes.orgNameContainer}>
						<Typography className={classes.orgName} variant="h1">
							{currentOrganizationData.name}
						</Typography>
						{<RoleLabel label={'Super Admin'} />}
					</div>

					<Typography>
						You can edit and manage this organization’s information and membership on the Engagement App.{' '}
						<Link underline="none" href={appLink}>
							Go to Engagement App
						</Link>
					</Typography>
				</div>
			)}
			<RouterTabs tabs={TABS} />
			<Switch>
				<Route exact path={ORGANIZATION_PATHS.SETTINGS(id)} children={<Settings orgId={id} />} />
				<Route exact path={ORGANIZATION_PATHS.EVENTS(id)} children={<EventsList orgId={id} />} />
				<Route path={ORGANIZATION_PATHS.DOCUMENTS(id)} children={<DocumentList subHeader="" organizationId={id} />} />
				<Redirect from="/" to={ORGANIZATION_PATHS.SETTINGS(id)} />
			</Switch>
		</MiddlePaneLayout>
	)
}

export default ViewOrganizationPage
