const USERS_ROOT_PATH = `/app/users`

export const USERS_PATHS = {
	ROOT: USERS_ROOT_PATH,
	LIST: (tab?: string) => `${USERS_ROOT_PATH}/list/${tab !== undefined ? tab : ':tab'}`,
	NEW_ADMIN: (tab?: string) => `${USERS_ROOT_PATH}/list/${tab !== undefined ? tab : ':tab'}/add`,
	VIEW_USER: (id?: string) => `${USERS_ROOT_PATH}/view/${id !== undefined ? id : ':id'}`,
}

export const UserOrganizationRolesMap = {
	isRolePrimaryContactAffairs: 'Primary Contact for Student Affairs',
	isRolePrimaryContactFinance: 'Primary Contact for Finance',
	isRolePrimaryContactPublic: 'Primary Contact for the Public',
	isRolePrimaryLeader: 'Primary Leader',
}
