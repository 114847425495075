import { Route, Redirect } from 'react-router-dom'
import { useSelector } from 'react-redux'
import PATHS from '@/constants/paths'
import { getUserRole } from '@/store/auth'
import AppRoute from '@/interfaces/route'

const PrivateRoute = ({ component: Component, allowedRoles, isPrivate, ...rest }: AppRoute) => {
	const userRole = useSelector(getUserRole)

	return (
		<Route
			{...rest}
			render={(props) =>
				!isPrivate || allowedRoles.includes(userRole) ? (
					<Component {...props} />
				) : (
					<Redirect
						to={{
							pathname: PATHS.ROOT,
						}}
					/>
				)
			}
		/>
	)
}

export default PrivateRoute
