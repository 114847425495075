import React, { useState } from 'react'
import { Table, TableBody, TableCell, TableRow } from '@mui/material'
import { Theme } from '@mui/material/styles'
import makeStyles from '@mui/styles/makeStyles'
import Box from '@mui/material/Box'
import Collapse from '@mui/material/Collapse'
import { ColumnInfo } from '.'
import ExpandFilledIcon from '../icons/ExpandFilledIcon'
import classNames from 'classnames'
import { getHoverColor } from '@navengage/sen-shared-assets'

const useStyles = makeStyles((theme: Theme) => ({
	cell: {
		...theme.typography.body2,
		paddingLeft: 0,
		border: 'none',
		paddingTop: theme.spacing(2.25),
		paddingBottom: theme.spacing(2.25),
	},
	cursorPointer: {
		cursor: 'pointer',
	},
	groupCell: {
		fontWeight: theme.typography.fontWeightMedium,
		fontSize: '18px',
		lineHeight: '24px',
		color: theme.colors.black[100],
		paddingLeft: 0,
		borderBottomColor: theme.colors.grey[200],
		display: 'flex',
		alignItems: 'center',
		paddingTop: theme.spacing(4),
		paddingBottom: theme.spacing(4),
	},

	expandedCell: {
		...theme.typography.body2,
		paddingLeft: 0,
		borderBottomColor: theme.colors.grey[200],
	},
	box: {
		border: 'none',
		padding: '10px 0',
		borderBottom: `solid 1px ${theme.colors.grey[200]}`,
	},
	tableBody: {
		padding: 0,
	},
	expandIcon: {
		color: theme.colors.grey[500],
	},
	open: {
		transform: 'scale(-1)',
	},
	itemRow: {
		cursor: 'pointer',
		'&:hover': {
			backgroundColor: getHoverColor(theme.colors.grey, 200),
			'& > td:first-child': {
				textDecoration: 'underline',
			},
		},
	},
}))

interface CollapsibleTableRowProps {
	name: string
	metrics: (number | null)[]
	columns: ColumnInfo[]
	rows: any[]
	onClickItem?: (item: any) => void
}

const CollapsibleTableRow = ({ name, metrics, columns, rows, onClickItem }: CollapsibleTableRowProps) => {
	const classes = useStyles()
	const [open, setOpen] = useState(false)

	const hasRows = !!rows.length
	const canSelectRow = !!onClickItem

	const handleClick = (item: any) => () => canSelectRow ? onClickItem(item) : null

	const handleToggle = () => {
		if (!hasRows) return

		setOpen((prev) => !prev)
	}

	return (
		<React.Fragment>
			<TableRow className={hasRows ? classes.cursorPointer : undefined} onClick={handleToggle}>
				<TableCell className={classes.groupCell} component="th" scope="row">
					{name}
					{hasRows && <ExpandFilledIcon className={classNames(classes.expandIcon, !open ? classes.open : '')} />}
				</TableCell>
				{metrics.map((metric, index) => {
					const { size, dataTransform, dataKey, showMetrics, ...rest } = columns[index + 1]

					return (
						<TableCell
							key={`${name}-table-head-${index}`}
							{...rest}
							style={{ width: size ? `${size}%` : 'auto' }}
							className={classes.expandedCell}
						>
							{metric}
						</TableCell>
					)
				})}
			</TableRow>
			<TableRow>
				<TableCell style={{ padding: 0, border: 'none' }} colSpan={6}>
					<Collapse in={open} timeout="auto" unmountOnExit>
						<Box className={classes.box}>
							<Table size="small">
								<TableBody className={classes.tableBody}>
									{rows.map((row, index) => (
										<TableRow
											key={`${name}-table-content-row-${index}`}
											className={canSelectRow ? classes.itemRow : undefined}
											onClick={handleClick(row)}
										>
											{columns.map(({ name, dataKey, dataTransform, size, showMetrics, ...rest }, idx) => {
												const cellData = dataTransform ? dataTransform(row[dataKey], row) : row[dataKey]

												return (
													<TableCell
														key={`${name}-table-content-cell-${index}-${idx}`}
														{...rest}
														style={{ width: size ? `${size}%` : 'auto' }}
														className={classes.cell}
													>
														{cellData}
													</TableCell>
												)
											})}
										</TableRow>
									))}
								</TableBody>
							</Table>
						</Box>
					</Collapse>
				</TableCell>
			</TableRow>
		</React.Fragment>
	)
}

export default CollapsibleTableRow
