import { GridFilterItem } from '@mui/x-data-grid'

export const applyFilterQueryToUrl = (url: string, filters: Omit<GridFilterItem, 'id'>[]) => {
	const filterJsonQueryParam = JSON.stringify({
		items: filters,
		linkOperator: 'and',
	})
	return `${url}?filters=${filterJsonQueryParam}`
}

export const createQueryFilterFunction = (url: string, options: Omit<GridFilterItem, 'id' | 'value'>) => (value: string) => {
	const filterJsonQueryParam = JSON.stringify({
		items: [
			{
				...options,
				value,
			},
		],
		linkOperator: 'and',
	})
	return `${url}?filters=${filterJsonQueryParam}`
}
