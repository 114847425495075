import { ChangeEvent, useCallback } from 'react'

export const useSettingsChangeHandler = <T,>(onChange: (key: T, value: any) => void) => {
	const onChangeHandler = useCallback(
		(key: T, val: string = 'value') => {
			return (e: ChangeEvent<HTMLInputElement>) => {
				onChange(key, e.target[val])
			}
		},

		[onChange],
	)
	return onChangeHandler
}
