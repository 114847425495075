import { SvgIcon } from '@mui/material'

const ExpandFilledIcon = (props: any) => {
	return (
		<SvgIcon xmlns="http://www.w3.org/2000/svg" viewBox="-6 -9 25 25" {...props}>
			<g data-name="\u041C\u043D\u043E\u0433\u043E\u0443\u0433\u043E\u043B\u044C\u043D\u0438\u043A 34" fill="currentColor">
				<path d="M9.971 6.5H1.03L5.5.81 9.971 6.5Z" />
				<path d="M5.5 1.619 2.057 6h6.886L5.5 1.619m0-1.31c.147 0 .293.064.393.191l4.471 5.691A.5.5 0 0 1 9.971 7H1.03a.5.5 0 0 1-.393-.809l4.47-5.69A.497.497 0 0 1 5.5.308Z" />
			</g>
		</SvgIcon>
	)
}

export default ExpandFilledIcon
