export const APP_URL = process.env.REACT_APP_COGNITO_REDIRECT_URL || 'https://sen.navengage.com/'

// Cognito Related Config

export const COGNITO_BASE = 'https://prod-sen.auth.us-east-1.amazoncognito.com/'
export const COGNITO_AUTH_CALL = `${COGNITO_BASE}oauth2/authorize?client_id=6llhh3s1nn6pku481iev6devsp&response_type=code&scope=openid&redirect_uri=${APP_URL}` // Now that we switched to /oath2/authorization we could potentially change this to respond with token and skip the step of exchanging the code for a token
export const COGNITO_POOL_CLIENT_ID = '6llhh3s1nn6pku481iev6devsp'
export const COGNITO_AUTHORIZATION_BEARER =
	'Basic NmxsaGgzczFubjZwa3U0ODFpZXY2ZGV2c3A6MTdma2g1cmVmY2NudGY4YzNpOWk1amJjNWM5ZzlvdjdwZXJoZWxrZTVzOTlzNzYyNTRwbA=='
export const EXTERNAL_ID_PREFIX = 'psu_' // I know. We'll change it to schoolID
export const REFRESH_TOKEN_EXPIRATION = 365
export const TOKEN_REFRESH_INTERVAL = 59000 * 60 // 59 seconds * 60 = 59 minutes
export const COGNITO_LOGOUT_CALL = `${COGNITO_BASE}logout?client_id=6llhh3s1nn6pku481iev6devsp&logout_uri=${APP_URL}`
export const PSU_SCHOOL_ID = 1
export const FEEDBACK_URL = 'https://forms.gle/KrKvP8KsJMJ7zRyi7'
export const REQUEST_ADD_ORG_URL = 'https://docs.google.com/forms/d/1aP0jLJpPSNyKxtanDLb9KvSa5Kt_mA1Nfwkq-1dDmAk/edit '
export const REQUEST_ADD_RESEARCH_URL = 'https://urfm.psu.edu/research/undergraduate-research-opportunities-database'
export const REQUEST_ADD_EDUCATION_ABROAD_URL = 'https://global.psu.edu/category/developing-and-proposing-program'
export const FILE_URL_PREFIX = 'https://psu-engage-prod-photo.s3.amazonaws.com/'
export const GOOGLE_ANALYTICS_KEY = 'G-2V96DXHZ1P'
export const PSU_PHOTO_REGISTRY = 'https://www.absecom.psu.edu/ONLINE_CARD_OFFICE/USER_PAGES/PSU_USER_MENU_WIN.cfm'
export const PSU_LIONPATH_LINK = 'https://www.lionpath.psu.edu/'
export const PHOTO_UPLOAD_URL = 'https://psu-engage-prod-photo.s3.amazonaws.com'
export const ADD_INTERNSHIP_LINK = 'https://pennstate-csm.symplicity.com/employers/?signin_tab=0&signin_tab=0'
export const ABOUT_APP_URL = 'https://www.engage.psu.edu/get-started/'
export const ACTIVATE_ORGANIZATION_URL = 'https://forms.gle/2Kk7E6QxiiUumS2p9'
export const ORG_CENTRAL_URL = 'https://orgcentral.psu.edu/'
export const TWENTY_FIVE_LIVE_URL = 'https://25live.collegenet.com/pro/psu'
