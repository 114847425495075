import { Controller } from 'react-hook-form'

import FieldErrorMessage from '@/components/FieldErrorMessage'
import { baseStyles } from '@/components/layouts/dashboard/baseStyles'
import { TextField } from '@/components/shared'
import { URL_TYPES } from '@/constants/validations'
import { makeStyles } from '@mui/styles'

import { ISocialMediaQuestion } from '../../types'

const useStyles = makeStyles((theme) => ({
	...baseStyles,
	grid: {
		display: 'flex',
		width: '100%',
		marginBottom: theme.spacing(2),
	},
	icon: ({ isSocialMedia, type }: { isSocialMedia?: boolean; type: URL_TYPES }) => {
		const isFacebook = type === URL_TYPES.FACEBOOK

		const padding = isSocialMedia ? 7 : 0
		const height = isSocialMedia ? 20 : 34
		return {
			...baseStyles.circle,
			background: isSocialMedia ? '#000000' : 'transparent',
			width: isSocialMedia ? 20 : 34,
			height: isFacebook ? 25 : height,
			padding,
			paddingBottom: isFacebook ? 0 : padding,
		}
	},
	socialInput: {
		flex: 1,
	},
	iconContainer: {
		width: 52,
	},
	websiteLinkIcon: {
		...baseStyles.circle,
		background: 'transparent',
		width: 28,
		height: 28,
		padding: 0,
	},
}))

export const SocialMediaInput = ({
	Icon,
	inputPlaceholder,
	isSocialMedia,
	type,
	stateControl,
}: { stateControl: Record<string, any> } & ISocialMediaQuestion) => {
	const classes = useStyles({ isSocialMedia, type })
	const { control, errors } = stateControl

	return (
		<div className={classes.grid}>
			<div className={classes.iconContainer}>
				<Icon className={classes.icon} />
			</div>
			<Controller
				name={`${type}`}
				defaultValue={''}
				control={control}
				render={({ field: { value, onChange, name } }) => (
					<div className={classes.socialInput}>
						<TextField error={!!errors[name]} placeholder={inputPlaceholder} variant="standard" {...{ value, onChange }} />
						<FieldErrorMessage hasError={!!errors[name]} message={errors[name] ? errors[name].message : ''} />
					</div>
				)}
			/>
		</div>
	)
}
