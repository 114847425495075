import { useCallback, useEffect, useMemo, useState } from 'react'
import { useForm } from 'react-hook-form'
import { useSelector } from 'react-redux'
import { v4 as uuidv4 } from 'uuid'
import * as yup from 'yup'

import ContentContainer from '@/components/ContentContainer'
import { FieldDataType, FormBuilderData, FormBuilderQuestion, FormSettings, QuestionType } from '@/components/Form/FormBuilder/types'
import { styles } from '@/components/shared'
import Tabs, { Tab } from '@/components/Tabs'
import { getCategories, selectFormCategorySchemaById } from '@/store/forms'
import appTheme from '@/theme'
import { yupResolver } from '@hookform/resolvers/yup'
import { Check } from '@mui/icons-material'
import { Chip, Tooltip, Typography } from '@mui/material'
import { Theme } from '@mui/material/styles'
import makeStyles from '@mui/styles/makeStyles'

import { RootState } from '@/store'
import { DESCRIPTION_MAX_LENGTH, TEXT_INPUT_MAX_LENGTH } from '@/utils/common'
import FieldsTab from './Tabs/Fields'
import SettingsTab from './Tabs/Settings'

const useStyles = makeStyles((theme: Theme) => ({
	root: {
		width: styles.FILL_AVAILABLE_WIDTH,
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
		paddingTop: 80,
	},
	container: {
		width: '55%',
		[theme.breakpoints.down('xl')]: {
			width: '100%',
		},
		zIndex: 2,
	},
	header: {
		fontWeight: theme.typography.fontWeightBold,
		whiteSpace: 'nowrap',
		flex: '1 1 auto',
		[theme.breakpoints.down('md')]: {
			width: '100%',
			marginBottom: theme.spacing(1),
		},
	},
	headerRight: {
		display: 'flex',
		[theme.breakpoints.down('md')]: {
			flexDirection: 'column',
			width: '100%',
			'& > button': {
				margin: `0 0 ${theme.spacing(1)}`,
			},
		},
	},
	headerLeft: {
		display: 'flex',
		alignItems: 'center',
		maxWidth: '60%',
	},
	contentContainer: {
		width: styles.FILL_AVAILABLE_WIDTH,
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'space-between',
	},
	builderRoot: {
		width: '100%',
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
	},
	pageHeader: {
		padding: `${theme.spacing(3.5)} 0`,
		zIndex: 3,
		width: styles.FILL_AVAILABLE_WIDTH,
		backgroundColor: theme.colors.white[500],
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'space-between',
		[theme.breakpoints.down('xl')]: {
			padding: `${theme.spacing(2.5)} 0`,
		},
		[theme.breakpoints.down('md')]: {
			flexDirection: 'column',
			padding: `${theme.spacing(2.5)} ${theme.spacing(2)}`,
		},
	},
	tabsContainer: {
		display: 'inline-flex',
	},
	checkIcon: {
		color: theme.colors.green[500],
		margin: '0 5px',
	},
	formMeta: {
		display: 'flex',
		alignItems: 'center',
		maxWidth: '100%',
	},
	formTitle: {
		overflow: 'hidden',
		textOverflow: 'ellipsis',
		whiteSpace: 'nowrap',
		[theme.breakpoints.up('xl')]: {
			maxWidth: '600px',
		},
		[theme.breakpoints.up('md')]: {
			maxWidth: '500px',
		},
	},
	categoryName: {
		borderRadius: 5,
		marginLeft: theme.spacing(2.25),
	},
}))

enum FormsTabs {
	SETTINGS = 'SETTINGS',
	FIELDS = 'FIELDS',
}

const tabIndex = {
	[FormsTabs.SETTINGS]: 0,
	[FormsTabs.FIELDS]: 1,
}

const schema = yup.object().shape({
	title: yup.string().max(TEXT_INPUT_MAX_LENGTH).required(),
	description: yup.string().max(DESCRIPTION_MAX_LENGTH).required(),
	startDate: yup.date().nullable().when('isActive', {
		is: false,
		then: yup.date().required(),
	}),
	endDate: yup.date().nullable().when('isActive', {
		is: false,
		then: yup.date().required(),
	}),
	category: yup.number().required(),
	allowMultipleSubmissions: yup.boolean(),
	isActive: yup.boolean(),
})

interface FormControls {
	getFormData: () => FormBuilderData<FieldDataType>
	allActionsDisabled: boolean
	draftSavingDisabled: boolean
	publishingDisabled: boolean
	isDirty: boolean
}

interface FormBuilderFormProps {
	title: string
	data?: FormBuilderData<FieldDataType>
	children: (controls: FormControls) => React.ReactNode
	disabled?: boolean
	canEditSettings?: boolean
	canEditQuestions?: boolean
	canSaveWithErrors?: boolean
	questionsTemplate?: FormBuilderQuestion<FieldDataType>[]
}

const FormBuilderForm = ({
	title,
	data,
	children,
	disabled,
	canEditSettings = true,
	canEditQuestions = true,
	canSaveWithErrors = false,
	questionsTemplate,
}: FormBuilderFormProps) => {
	const classes = useStyles()

	const {
		control,
		handleSubmit,
		getValues,
		watch,
		formState: { errors, isValid, isDirty },
	} = useForm<FormSettings>({
		resolver: yupResolver(schema),
		mode: 'onChange',
		reValidateMode: 'onChange',
		criteriaMode: 'firstError',
		shouldFocusError: true,
		defaultValues: data?.settings,
	})

	const unlockTabs = (tabs: Tab[], filledTabs: number[]) =>
		tabs.reduce(
			(acc, tab, i) => [
				...acc,
				{
					...tab,
					icon: filledTabs.includes(i) ? <Check className={classes.checkIcon} fontSize="small" /> : null,
					disabled: i > 0 && !filledTabs.includes(i) && !acc[i - 1]?.icon,
				} as Tab,
			],
			[] as Tab[],
		)

	const [filledTabs, setFilledTabs] = useState<number[]>(isValid ? [0] : [])
	const [builderIsTouched, setBuilderIsTouched] = useState(false)
	const [tabs, setTabs] = useState(
		unlockTabs(
			Object.values(FormsTabs).map((label) => ({ label })),
			filledTabs,
		),
	)

	const [activeTab, setActiveTab] = useState(0)

	const [formBuilderData, setFormBuilderData] = useState<FormBuilderData<FieldDataType>>(
		data ?? {
			id: uuidv4(),
			questions: questionsTemplate ?? [
				{
					id: uuidv4(),
					questionText: '',
					questionType: QuestionType.TextAnswer,
					fieldData: {},
				},
			],
		},
	)

	const selectedCategory = watch('category')
	const formTitle = watch('title')
	const makeActive = watch('isActive')

	const formSchema = useSelector((state: RootState) => selectFormCategorySchemaById(state, selectedCategory))

	const categoriesOptions = useSelector(getCategories)
	const getCategoryLabel = useCallback(
		(selectedCategory: number) => {
			const currentCategory = categoriesOptions.find((category) => {
				return category.value === selectedCategory
			})

			return currentCategory?.label
		},
		[categoriesOptions],
	)
	const handleTouchBuilder = useCallback(() => setBuilderIsTouched(true), [])

	const handleSubmitSettings = useCallback(
		(data: FormSettings) => {
			setFilledTabs((prev) => [...prev, activeTab])
			setActiveTab((prev) => prev + 1)
			setFormBuilderData((prev) => ({ ...prev, settings: data }))
		},
		[activeTab],
	)

	const allActionsDisabled = !!disabled

	const draftSavingDisabled =
		!(isDirty || builderIsTouched) ||
		!selectedCategory ||
		allActionsDisabled ||
		(!canSaveWithErrors && !isValid) ||
		!formBuilderData.questions.length ||
		formBuilderData.questions.some((q) => !q.questionText)

	const hasErrors = !!Object.keys(errors).length

	const publishingDisabled =
		!!Object.keys(errors).length ||
		!isValid ||
		!formBuilderData.questions.length ||
		formBuilderData.questions.some((q) => !q.questionText) ||
		!Object.keys(formBuilderData.settings ?? {}).length ||
		allActionsDisabled

	const getFormData = (): FormBuilderData<FieldDataType> => ({
		...formBuilderData,
		settings: getValues(),
	})

	const tabComponentsByIndex = useMemo(
		() => ({
			[tabIndex[FormsTabs.SETTINGS]]: (
				<SettingsTab
					control={control}
					errors={errors}
					onSubmit={handleSubmit(handleSubmitSettings)}
					isDirty={isDirty}
					hasErrors={hasErrors}
					disabled={allActionsDisabled || !canEditSettings}
					isValid={isValid}
					watch={watch}
					makeActive={makeActive}
				/>
			),
			[tabIndex[FormsTabs.FIELDS]]: (
				<FieldsTab
					data={formBuilderData}
					setData={setFormBuilderData}
					disabled={allActionsDisabled || !canEditQuestions}
					setIsTouched={handleTouchBuilder}
				/>
			),
		}),
		[
			control,
			errors,
			isValid,
			watch,
			handleSubmit,
			handleSubmitSettings,
			handleTouchBuilder,
			isDirty,
			hasErrors,
			allActionsDisabled,
			canEditSettings,
			formBuilderData,
			canEditQuestions,
			makeActive,
		],
	)

	const activeTabComponent = useMemo(() => tabComponentsByIndex[activeTab], [activeTab, tabComponentsByIndex])

	useEffect(() => {
		setTabs(unlockTabs(tabs, filledTabs))
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [filledTabs])

	useEffect(() => {
		let completeTabs: number[] = []

		if (data) {
			if (isValid) {
				completeTabs = [...completeTabs, 0]
			}
			if (!canEditQuestions) {
				completeTabs = [...completeTabs, 1]
			}
			setFilledTabs(completeTabs)
		} else if (!isValid) {
			setFilledTabs((prev) => prev.filter((v) => v !== 0))
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [isValid])

	useEffect(() => {
		setFormBuilderData({
			id: uuidv4(),
			questions: formSchema,
		})
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [selectedCategory])

	return (
		<div className={classes.builderRoot}>
			<div className={classes.pageHeader}>
				<div className={classes.headerLeft}>
					<Typography className={classes.header} variant="h4">
						{title}
					</Typography>

					<div className={classes.formMeta}>
						{formTitle && (
							<Tooltip arrow title={formTitle}>
								<Typography className={classes.formTitle} variant="h4">
									|{formTitle}
								</Typography>
							</Tooltip>
						)}
						{selectedCategory && <Chip className={classes.categoryName} label={getCategoryLabel(selectedCategory)} />}
					</div>
				</div>
				<div className={classes.headerRight}>
					{children({
						allActionsDisabled,
						publishingDisabled,
						draftSavingDisabled,
						getFormData,
						isDirty,
					})}
				</div>
			</div>
			<ContentContainer backgroundColor={appTheme.colors.grey[100]}>
				<div className={classes.root}>
					<div className={classes.container}>
						<div className={classes.contentContainer}>
							<Tabs tabsContainerClassName={classes.tabsContainer} activeTabIndex={activeTab} handleChangeTab={setActiveTab} tabs={tabs} />
						</div>
						{activeTabComponent}
					</div>
				</div>
			</ContentContainer>
		</div>
	)
}

export default FormBuilderForm
