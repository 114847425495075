import React, { forwardRef } from 'react'
import classNames from 'classnames'
import { Button, Theme } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import { ButtonColors, pickButtonColor, createButtonColors } from '../../../theme/colors/buttonColors'

interface StylesProps {
	color?: ButtonColors
}

const useStyles = makeStyles<Theme, StylesProps>((theme) => ({
	root: ({ color }) => {
		const buttonColors = createButtonColors(theme)
		const buttonColor = pickButtonColor(color ?? ButtonColors.PRIMARY, buttonColors)

		return {
			borderRadius: 7,
			backgroundColor: buttonColor.main,
			color: buttonColor.textColor,
			textTransform: 'none',
			boxShadow: 'none',
			'&:hover': {
				backgroundColor: buttonColor.hover,
				boxShadow: 'none',
			},
			'&:disabled': {
				backgroundColor: buttonColor.disabled,
				color: buttonColor.textColor,
				'&>span': {
					opacity: 0.7,
				},
			},
			...theme.typography.body1,
			[theme.breakpoints.down('sm')]: {
				...theme.typography.subtitle1,
				padding: 0,
				minWidth: 100,
				minHeight: 36,
			},
			'&.Mui-disabled': {
				pointerEvents: 'auto',
				backgroundColor: buttonColor.disabled,
				color: buttonColor.textColor,
				'&:hover': {
					backgroundColor: buttonColor.disabled,
					color: buttonColor.textColor,
					'&>span': {
						opacity: 0.7,
					},
				},
			},
		}
	},
}))

export interface ButtonProps extends StylesProps {
	children?: React.ReactNode
	onClick?: (e: React.SyntheticEvent<HTMLButtonElement>) => void
	disabled?: boolean
	className?: string
	type?: 'button' | 'reset' | 'submit'
	onMouseEnter?: (e: React.SyntheticEvent<HTMLButtonElement>) => void
	onMouseLeave?: (e: React.SyntheticEvent<HTMLButtonElement>) => void
	component?: any
	startIcon?: React.ReactNode
	endIcon?: React.ReactNode
}

const ColorButton = forwardRef<HTMLButtonElement, ButtonProps>(
	(
		{
			color = ButtonColors.PRIMARY,
			children = null,
			onClick,
			disabled,
			className,
			type,
			onMouseEnter,
			onMouseLeave,
			component,
			startIcon,
			endIcon,
		},
		ref,
	) => {
		const classes = useStyles({ color })

		return (
			<Button
				ref={ref}
				type={type}
				onClick={onClick}
				disabled={disabled}
				className={classNames(className, classes.root)}
				variant="contained"
				onMouseEnter={onMouseEnter}
				onMouseLeave={onMouseLeave}
				component={component}
				startIcon={startIcon}
				endIcon={endIcon}
			>
				{children}
			</Button>
		)
	},
)

export default ColorButton
