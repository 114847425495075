import * as http from '@/api/http'
import { FormReaderData } from '@/components/Form/FormReader/types'
import { LIST_INITIAL_STATE } from '@/constants/app'
import { RootState } from '@/store'
import { enqueueSnackbar } from '@/store/app'
import { createFormBuilderData } from '@/store/forms'
import { EntityList, EntityListParams, ListRequestParams } from '@/types'
import { prepareListParams } from '@/utils/common'
import { createFieldUpdateToast, creationSuccessToast } from '@/utils/notificationHelpers'
import { createAsyncThunk, createSelector, createSlice, isAnyOf } from '@reduxjs/toolkit'
import { push } from 'connected-react-router'
import { isArray } from 'lodash'
import { createFile } from '../documents/api'
import { IDocument } from '../documents/types'

import { getFormsList, getFormSubmissionsById, getSubmissionById, getSubmissions, submitForm, updateSubmissionStatus } from './api'
import { FORM_PATHS } from './constants'
import { FormsListDto, SubmissionsDto, SubmissionStatuses, TForm, TFormData, TFormListItem, TSubmission } from './types'

export const FEATURE_NAME = 'FORM_SUBMISSIONS'

interface SubmitFormState {
	isLoading: boolean
	formData: TFormData | null
	submissionsList: EntityList<TSubmission>
	formsList: EntityList<TFormListItem>
	formSubmissionsList: EntityList<any>
}

const initialState: SubmitFormState = {
	isLoading: false,
	formData: null,
	submissionsList: { ...LIST_INITIAL_STATE },
	formsList: { ...LIST_INITIAL_STATE },
	formSubmissionsList: { ...LIST_INITIAL_STATE },
}

export const readFormRequest = createAsyncThunk<
	TForm,
	string,
	{
		state: RootState
	}
>(`${FEATURE_NAME}/READ_FORM_REQUEST`, async (id, { dispatch, getState, rejectWithValue }) => {
	try {
		const response = await http.forms.getForm(id)

		return response.data
	} catch (e: any) {
		return rejectWithValue(e)
	}
})

export const submitFormRequest = createAsyncThunk<
	any,
	{
		formId: string
		data: FormReaderData
	},
	{
		state: RootState
	}
>(`${FEATURE_NAME}/SUBMIT_FORM_REQUEST`, async ({ formId, data }, { dispatch, getState, rejectWithValue }) => {
	try {
		const filesToQuestions = {}
		const fileUploadPromises = []

		Object.keys(data).forEach((questionId) => {
			if (isArray(data[questionId])) {
				const questionData = data[questionId] as Array<any>
				if (questionData.length > 0 && questionData[0].file) {
					filesToQuestions[questionId] = []

					questionData.forEach((a: IDocument) => {
						fileUploadPromises.push(
							createFile({ file: a.file }).then(({ data }) => {
								filesToQuestions[questionId].push(data)
							}),
						)
					})
				}
			}
		})

		await Promise.all(fileUploadPromises)

		const answers = Object.keys(data).map((questionId) => ({
			questionId,
			data: JSON.stringify(filesToQuestions[questionId] ? filesToQuestions[questionId] : data[questionId]),
		}))

		const response = await submitForm(formId, answers)
		dispatch(enqueueSnackbar(creationSuccessToast(null, 'Form submitted successfully')))
		dispatch(push(FORM_PATHS.MY_SUBMISSIONS))
		return response.data
	} catch (e: any) {
		return rejectWithValue(e)
	}
})

export const getSubmissionsListRequest = createAsyncThunk<
	SubmissionsDto,
	ListRequestParams,
	{
		state: RootState
	}
>(`${FEATURE_NAME}/GET_SUBMISSIONS_REQUEST`, async (requestParams, { dispatch, getState, rejectWithValue }) => {
	try {
		const {
			FORM_SUBMISSIONS: {
				submissionsList: { params },
			},
		} = getState()

		const queryParams = prepareListParams({ ...params }, requestParams, dispatch, setListParams) as EntityListParams

		const response = await getSubmissions(queryParams)

		return response.data
	} catch (e: any) {
		return rejectWithValue(e)
	}
})

export const getFormSubmissionsListRequest = createAsyncThunk<
	SubmissionsDto,
	ListRequestParams & { id: string },
	{
		state: RootState
	}
>(`${FEATURE_NAME}/GET_FORM_SUBMISSIONS_REQUEST`, async (requestParams, { dispatch, getState, rejectWithValue }) => {
	try {
		const {
			FORM_SUBMISSIONS: {
				formSubmissionsList: { params },
			},
		} = getState()

		const queryParams = prepareListParams({ ...params }, requestParams, dispatch, setListParams) as EntityListParams

		const response = await getFormSubmissionsById(requestParams.id, queryParams)

		return response.data
	} catch (e: any) {
		return rejectWithValue(e)
	}
})

export const getFormsListRequest = createAsyncThunk<
	FormsListDto,
	ListRequestParams,
	{
		state: RootState
	}
>(`${FEATURE_NAME}/GET_FORMS_LIST_REQUEST`, async (requestParams, { dispatch, getState, rejectWithValue }) => {
	try {
		const {
			FORM_SUBMISSIONS: {
				formsList: { params },
			},
		} = getState()

		const queryParams = prepareListParams({ ...params }, requestParams, dispatch, setListParams) as EntityListParams

		const response = await getFormsList(queryParams)

		return response.data
	} catch (e: any) {
		return rejectWithValue(e)
	}
})

export const updateSubmissionStatusRequest = createAsyncThunk<
	any,
	{
		status: `${SubmissionStatuses}`
		id: string
	},
	{
		state: RootState
	}
>(`${FEATURE_NAME}/UPDATE_SUBMISSION_STATUS_REQUEST`, async ({ id, status }, { dispatch, getState, rejectWithValue }) => {
	try {
		const response = await updateSubmissionStatus(id, status)
		dispatch(enqueueSnackbar(createFieldUpdateToast('Status')))
		return response.data
	} catch (e: any) {
		return rejectWithValue(e)
	}
})

export const getSubmissionRequest = createAsyncThunk<
	any,
	string,
	{
		state: RootState
	}
>(`${FEATURE_NAME}/GET_SUBMISSION_REQUEST`, async (id, { rejectWithValue }) => {
	try {
		const response = await getSubmissionById(id)
		const { submission: formData, answers: formQuestions } = response.data

		return {
			formData,
			formQuestions,
			builder: createFormBuilderData(formData, formQuestions),
		}
	} catch (e: any) {
		return rejectWithValue(e)
	}
})

export const submitFormSlice = createSlice({
	name: FEATURE_NAME,
	initialState,
	reducers: {
		setPage: (state, { payload }) => {
			state.submissionsList.params.page = payload
		},
		setPageSize: (state, { payload }) => {
			state.submissionsList.params.limit = payload
		},
		setListParams: (state, { payload }) => {
			state.submissionsList.params = payload
		},
		resetList: (state) => {
			state.formSubmissionsList = { ...LIST_INITIAL_STATE }
		},
	},
	extraReducers: (builder) => {
		builder
			.addCase(readFormRequest.fulfilled, (state, { payload: { formData, formQuestions } }) => {
				state.formData = {
					formData,
					formQuestions,
					builder: createFormBuilderData(formData, formQuestions),
				}
			})
			.addCase(getSubmissionsListRequest.pending, (state) => {
				state.submissionsList.isLoading = true
			})
			.addCase(getSubmissionsListRequest.fulfilled, (state, { payload }) => {
				state.submissionsList.list = payload
				state.submissionsList.isLoading = false
			})
			.addCase(getSubmissionsListRequest.rejected, (state) => {
				state.submissionsList.isLoading = false
			})
			.addCase(getFormsListRequest.pending, (state) => {
				state.formsList.isLoading = true
			})
			.addCase(getFormsListRequest.rejected, (state) => {
				state.formsList.isLoading = false
			})
			.addCase(getFormsListRequest.fulfilled, (state, { payload }) => {
				state.formsList.isLoading = false
				state.formsList.list = payload
			})
			.addCase(getFormSubmissionsListRequest.pending, (state) => {
				state.formSubmissionsList.isLoading = true
			})
			.addCase(getFormSubmissionsListRequest.fulfilled, (state, { payload }) => {
				state.formSubmissionsList.isLoading = false

				state.formSubmissionsList.list = payload
			})
			.addCase(getFormSubmissionsListRequest.rejected, (state) => {
				state.formSubmissionsList.isLoading = false
			})

			.addMatcher(isAnyOf(readFormRequest.pending, submitFormRequest.pending), (state, action) => {
				state.isLoading = true
			})
			.addMatcher(
				isAnyOf(readFormRequest.fulfilled, readFormRequest.rejected, submitFormRequest.fulfilled, submitFormRequest.rejected),
				(state) => {
					state.isLoading = false
				},
			)
	},
})

export const { setPage, setPageSize, setListParams, resetList } = submitFormSlice.actions

// Selectors
const selectState = (state: { [FEATURE_NAME]: SubmitFormState }) => state[FEATURE_NAME]

export const selectFormData = createSelector(selectState, (state) => state.formData)
export const selectSubmitFromIsLoading = createSelector(selectState, (state) => state.isLoading)
export const selectSubmissionsList = createSelector(selectState, (state) => state.submissionsList)
export const selectFormsList = createSelector(selectState, (state) => state.formsList)
export const selectFormSubmissionsList = createSelector(selectState, (state) => state.formSubmissionsList)
