import events from '@/features/events/routes'
import users from '@/features/users/routes'
import AppRoute from '@/interfaces/route'
import app from '@/routes/app'
import forms from '@/routes/forms'
import root from '@/routes/root'
import notifications from '@/features/notifications/routes'
export const rootRoutes: AppRoute[] = [...root]

export const mainRoutes: AppRoute[] = [...app, ...notifications]
export const formRoutes: AppRoute[] = [...forms]

const ROUTES = {
	root,
	app,
	forms,
	events,
	users,
}

export default ROUTES
