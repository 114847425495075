import { push } from 'connected-react-router'
import { v4 as uuidv4 } from 'uuid'

import { Action, isRejectedWithValue, Middleware, MiddlewareAPI } from '@reduxjs/toolkit'

import { PATHS } from '../constants'
import { isDevEnv } from '../utils/authHandlers'
import { prepareError } from '../utils/common'
import { enqueueSnackbar, setError } from './app'
import { SnackbarType } from './app/types'
import { MODULE_NAME as AUTH_MODULE_NAME } from './auth'

const authActionTypes = [`${AUTH_MODULE_NAME}/SIGN_IN`, `${AUTH_MODULE_NAME}/USER_INFO_REQUEST`, `${AUTH_MODULE_NAME}/AUTO_SIGN_IN`]

const errorPages = [PATHS.AUTH_ERROR, PATHS.ERROR, PATHS.NOT_FOUND_ERROR]

const errorMiddleware: Middleware = (api: MiddlewareAPI) => (next) => (action: Action) => {
	if (isRejectedWithValue(action)) {
		const err = prepareError(action.payload)
		if (err.message && err.status <= 400) {
			api.dispatch(
				enqueueSnackbar({
					key: uuidv4(),
					notification: {
						message: {
							type: SnackbarType.error,
							message: err.message,
						},
					},
				}),
			)
		} else {
			api.dispatch(setError(err))

			if (err.status === 404) {
				api.dispatch(push(PATHS.NOT_FOUND_ERROR))
			} else if (err.status === 403 && authActionTypes.some((type) => action.type.includes(type))) {
				api.dispatch(push(PATHS.AUTH_ERROR))
			} else if (!errorPages.includes(window.location.pathname)) {
				//api.dispatch(push(PATHS.ERROR))
			}
		}

		if (isDevEnv()) {
			console.error({ action, err })
		}
	}

	return next(action)
}

export default errorMiddleware
