import { DropzonePlaceholder } from '@/components/Form/FormBuilder/QuestionTypeCards/DropzonePlaceholder'
import { FileUploadQuestion } from '@/components/Form/FormBuilder/types'
import { DocumentDropzone } from '@/features/documents/components/DocumentDropzone'
import { FilePreview } from '@/features/documents/components/FilePreview'
import { AVAILABLE_FILE_TYPES } from '@/features/documents/constants'
import { Link } from '@mui/material'
import { Theme } from '@mui/material/styles'
import makeStyles from '@mui/styles/makeStyles'
import { isArray } from 'lodash'

const useStyles = makeStyles<Theme>((theme) => ({
	root: {
		marginTop: theme.spacing(2),
	},
	fileLink: {
		textDecoration: 'none',
		color: theme.colors.black[500],
	},
}))

interface UploadFieldProps {
	submission?: boolean
	value?: any
	onChange?: (url: string) => void
	className?: string
	validateFileSize?: (fileUrl: string) => Promise<boolean>
	data: FileUploadQuestion
	disabled?: boolean
}

const UploadField = ({
	onChange = (files: string) => null,
	submission,
	value,
	data: { allowedFileTypes, fileCount, maxFileSize, multiple, placeholder },
	disabled,
}: UploadFieldProps) => {
	const classes = useStyles()
	return (
		<div className={classes.root}>
			{!submission ? (
				<DocumentDropzone
					disabled={disabled}
					maxSize={maxFileSize * 1024 * 1024}
					accept={AVAILABLE_FILE_TYPES[allowedFileTypes].accept}
					multiple={multiple}
					maxFiles={fileCount}
					onChange={onChange}
					placeholder={<DropzonePlaceholder label={placeholder} icon={AVAILABLE_FILE_TYPES[allowedFileTypes].icon} />}
				/>
			) : (
				isArray(value) &&
				value.map((file) => {
					return (
						<Link className={classes.fileLink} href={file.location}>
							<FilePreview key={file.id} fileName={file.originalname} />
						</Link>
					)
				})
			)}
		</div>
	)
}

export default UploadField
