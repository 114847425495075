import { AnswerWithOtherOption, CardProps, FieldDataWithOtherAnswer } from '../../types'
import OptionsCard from './OptionsCard'

interface AnswerWithOtherOptionCardProps extends CardProps<FieldDataWithOtherAnswer> {
	optionTypeElement?: React.ReactNode
}

const AnswerWithOtherOptionCard = ({ data, onChange, isEdit, optionTypeElement = null }: AnswerWithOtherOptionCardProps) => {
	const handelChangeAnswers = (value: AnswerWithOtherOption[]) => onChange({ ...data, answers: value })

	return (
		<OptionsCard
			data={data as FieldDataWithOtherAnswer}
			onChange={handelChangeAnswers}
			isEdit={isEdit}
			optionTypeElement={optionTypeElement}
		/>
	)
}

export default AnswerWithOtherOptionCard
