import classNames from 'classnames'
import { useEffect, useState } from 'react'
import makeStyles from '@mui/styles/makeStyles'
import * as http from '@/api/http'
import Person from '@mui/icons-material/Person'
import { cacheUserPhoto, getCachedUserPhoto } from '@/utils/userImageCache'

const useStyles = makeStyles((theme) => ({
	root: ({ borderRadius }: any) => ({
		position: 'relative',
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
		borderRadius: `${borderRadius}%`,
		backgroundColor: theme.colors.grey[100],
		[theme.breakpoints.down('sm')]: {
			width: '4.5vw',
			height: '4.5vw',
			minWidth: 55,
			minHeight: 55,
		},
	}),
	image: ({ borderRadius }: any) => ({
		width: '100%',
		height: '100%',
		objectFit: 'cover',
		borderRadius: `${borderRadius}%`,
	}),
	personIcon: {
		width: '80%',
		height: '80%',
		color: theme.colors.grey[500],
	},
	notificationDot: ({ notificationDot }: any) => ({
		width: notificationDot.width,
		height: notificationDot.height,
		backgroundColor: theme.colors.red[500],
		border: `solid 1px ${theme.colors.white[500]}`,
		borderRadius: '50%',
		position: 'absolute',
		top: notificationDot.top,
		right: notificationDot.right,
	}),
}))

interface LazyLoadProfileImageProps {
	className?: string
	externalUserId: string
	photoUrl?: string
	borderRadius?: number
	notificationDot?: {
		width: number
		height: number
		top: number
		right: number
	}
}

const LazyLoadProfileImage = ({
	className = '',
	externalUserId,
	photoUrl,
	borderRadius = 50,
	notificationDot = {
		width: 0,
		height: 0,
		top: 0,
		right: 0,
	},
}: LazyLoadProfileImageProps) => {
	const classes = useStyles({ borderRadius, notificationDot })
	const [photo, setPhoto] = useState(
		getCachedUserPhoto({
			externalUid: externalUserId,
			photoUrl,
		}),
	)

	const showDot = !!notificationDot.width && !!notificationDot.height

	useEffect(() => {
		const fetchImage = async () => {
			try {
				const cachedPhoto = getCachedUserPhoto({
					externalUid: externalUserId,
					photoUrl,
				})

				if (cachedPhoto) return

				const {
					data: { image },
				} = await http.user.getUserProfileImage(externalUserId)

				cacheUserPhoto({
					externalUid: externalUserId,
					photoUrl,
					data: image,
				})
				setPhoto(image)
			} catch (e: any) {}
		}
		fetchImage()
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [externalUserId, photoUrl])

	return (
		<div className={classNames(classes.root, className)}>
			{showDot && <div className={classes.notificationDot} />}
			{photo ? (
				<img className={classes.image} src={`data:image/jpeg;base64, ${photo}`} alt="profile" />
			) : (
				<Person className={classes.personIcon} />
			)}
		</div>
	)
}

export default LazyLoadProfileImage
