import saveAs from 'file-saver'
import { TFieldMapper } from '../TFieldMapper'

export type TJsonGeneratorConfigs = {
	defaultHeaders: TFieldMapper
}

export default abstract class FileGenerator {
	/**
	 *  Info about results columns and their headers
	 *
	 * @protected
	 * @type {TFieldMapper}
	 * @memberof FileGenerator
	 */
	protected _defaultHeaders: TFieldMapper
	/**
	 * Generated file type must be defined in the child classes
	 *	@property {string}
	 */
	abstract readonly type: string

	abstract readonly extension: string
	/**
	 * Creates an instance of FileGenerator
	 *
	 * @param {string} fileName
	 * @memberof FileGenerator
	 */
	constructor(protected fileName: string, { defaultHeaders }: TJsonGeneratorConfigs) {
		this._defaultHeaders = defaultHeaders
	}
	/**
	 * Downloads buffer as a file
	 */
	/**
	 * @TODO: need to be changes to streamsaverjs
	 */
	async downloadFile() {
		const buffer = await this.getBuffer()
		saveAs(new Blob([buffer], { type: this.type }), `${this.fileName}.${this.extension}`)
	}
	/**
	 * Returns buffer of the current file, must be implemented in the child classes
	 *
	 * @returns {ArrayBuffer}
	 */
	abstract getBuffer(): Promise<ArrayBuffer> | string
	abstract addData(data: any[]): void
}
