import { push } from 'connected-react-router'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { RootState } from '@/store'
import { getUserRole } from '@/store/auth'
import { isStudent } from '@/utils/authHandlers'
import { GridColumns } from '@mui/x-data-grid'
import { AsyncThunk } from '@reduxjs/toolkit'

import { EVENT_LIST_COLUMNS, EVENT_PATHS } from './constants'
import { deleteEventRequest } from './eventsSlice'
import { getGridAction } from './helpers'

export const useEventColumns = (cols: GridColumns, cb?: () => void) => {
	const dispatch = useDispatch()
	const currentUserRole = useSelector(getUserRole)

	const { confirmationRequired, setConfirmationRequired, renderButtons, handleClose, setCurrentEntityId } = useDeleteConfirmation(
		deleteEventRequest,
		'Event',
		undefined,
		cb,
	)
	const handleEditClick = useCallback(
		(id) => () => {
			dispatch(push(EVENT_PATHS.EDIT_EVENT(id)))
		},
		[dispatch],
	)
	// const handleViewClick = useCallback(
	// 	(id) => () => {
	// 		dispatch(push(EVENT_PATHS.VIEW_EVENT(id)))
	// 	},
	// 	[dispatch],
	// )
	const handleDeleteClick = useCallback(
		(id) => () => {
			setCurrentEntityId(id)
			setConfirmationRequired(true)
		},
		[setConfirmationRequired, setCurrentEntityId],
	)
	const columns: GridColumns = useMemo(() => {
		const columnsList = [...EVENT_LIST_COLUMNS, ...cols]
		const adminColumns = [
			{
				field: 'actions',
				type: 'actions',
				headerName: 'Actions',
				width: 100,
				cellClassName: 'actions',
				getActions: getGridAction({ handleEditClick, handleDeleteClick }),
			},
		]

		return !isStudent(currentUserRole) ? [...columnsList, ...adminColumns] : [...columnsList]
	}, [currentUserRole, handleEditClick, handleDeleteClick, cols])

	return { columns, confirmationRequired, renderButtons, handleClose }
}

export const useDeleteConfirmation = (
	request: AsyncThunk<
		any,
		{
			id: string
			cb?: () => void
		},
		{
			state: RootState
		}
	>,
	entity: string,
	id?: string,
	cb?: () => void,
) => {
	const [confirmationRequired, setConfirmationRequired] = useState(false)
	const [currentEntityId, setCurrentEntityId] = useState(id)
	const dispatch = useDispatch()
	const handleConfirm = useCallback(
		(id) => {
			dispatch(request({ id: currentEntityId, cb }))
			setConfirmationRequired(false)
		},
		[dispatch, currentEntityId, cb, request],
	)
	const handleClose = useCallback(() => {
		setConfirmationRequired(false)
	}, [])

	useEffect(() => {
		setCurrentEntityId(id)
	}, [id])

	const renderButtons = useMemo(
		() => [
			{
				label: `Delete ${entity}`,
				onClick: handleConfirm,
				color: '#FA1564',
			},
			{
				label: `Cancel`,
				onClick: handleClose,
				color: 'inherit',
			},
		],
		[handleConfirm, handleClose, entity],
	)

	return {
		confirmationRequired,
		setConfirmationRequired,
		renderButtons,
		handleClose,
		handleConfirm,
		setCurrentEntityId,
	}
}
