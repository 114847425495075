import { STYLES } from '@/constants'

import { Theme, Typography } from '@mui/material'
import { makeStyles } from '@mui/styles'

import React from 'react'

import placeholderImage from './images/placeholder_image.svg'

const useStyles = makeStyles<Theme>((theme) => ({
	root: {
		position: 'relative',
		width: '100%',
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
		minHeight: `calc(100vh - ${STYLES.MAIN_HEADER_HEIGHT}vh - ${STYLES.LANDING_FOOTER_HEIGHT}px - ${theme.spacing(2)})`,
	},
	content: {
		width: '70%',
		position: 'relative',
		textAlign: 'center',
	},
	messageBox: {
		textAlign: 'center',
		marginTop: '30px',
	},
	messageTitle: {
		...theme.typography.h3,
		marginBottom: theme.spacing(1),
	},
	message: {
		...theme.typography.body2,
	},
}))

const ErrorCard: React.FC = () => {
	const classes = useStyles()
	return (
		<div className={classes.root}>
			<div className={classes.content}>
				<img alt="Page is temporary unavailable" src={placeholderImage} />
				<div className={classes.messageBox}>
					<Typography className={classes.messageTitle}>Sorry, something went wrong</Typography>
					<Typography className={classes.message}>We are working hard to fix the problem</Typography>
				</div>
			</div>
		</div>
	)
}

export { ErrorCard }
