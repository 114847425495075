import { useCallback, useEffect, useRef } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'

import { STYLES } from '@/constants'

import { Link, Typography } from '@mui/material'
import { makeStyles } from '@mui/styles'
import SettingsOutlinedIcon from '@mui/icons-material/SettingsOutlined'
import { Link as RouterLink } from 'react-router-dom'

import NotificationsBlock from '../components/NotificationsBlock'
import { NotificationPages, UserNotification } from '../types'
import { loadUserNotificationsRequest, selectNotifications, setViewedNotificationsRequest } from '../notificationsSlice'
import { NotificationViewStatuses } from '../constants'
import upperFirst from 'lodash/upperFirst'
import { SETTINGS_PATHS } from '@/features/settings/constants'

const useStyles = makeStyles((theme) => ({
	root: {
		width: '100vw',
		display: 'flex',
		flexDirection: 'row',
	},
	card: {
		backgroundColor: theme.colors.white[500],
		width: STYLES.FILL_AVAILABLE_WIDTH,
		height: '100%',
		padding: '3.5vh 2.5vw',
		[theme.breakpoints.down('sm')]: {
			width: '100vw',
		},
	},
	notificationsPopper: {
		marginTop: 5,
		padding: '10px 20px',
		minWidth: '20vw',
		boxShadow: 'none',
		border: `solid 1px ${theme.colors.grey[200]}`,
		borderRadius: 8,
		maxHeight: 'none',
	},
	notificationsPopperTitle: {
		fontWeight: 700,
	},
	titleContainer: {
		display: 'flex',
		flexDirection: 'row',
		alignItems: 'center',
		justifyContent: 'space-between',
		marginBottom: 10,
	},
	rootContainer: {
		width: '100%',
	},
	leftColumn: {
		width: '25%',
		backgroundColor: theme.colors.white[500],
		[theme.breakpoints.down('md')]: {
			width: '35%',
		},
	},
	rightColumn: {
		width: '25%',
	},
	middleColumn: {
		width: '50%',
		display: 'flex',
		flexDirection: 'column',
		maxWidth: 940,
		border: `solid 1.5px ${theme.colors.grey[300]}`,
		borderTop: 'none',
		borderBottom: 'none',
		[theme.breakpoints.down('md')]: {
			width: '65%',
			maxWidth: 'none',
		},
		[theme.breakpoints.down('sm')]: {
			width: '100%',
			maxWidth: 'none',
		},
	},
}))

const NotificationsPage = () => {
	const classes = useStyles()
	const dispatch = useDispatch()

	const { unviewed, viewed } = useSelector(selectNotifications)

	const { list: unviewedList, loading: newLoading } = unviewed
	const { list: viewedList, loading: pastLoading } = viewed

	const canLoadMore = (isNew: boolean) => {
		return isNew ? unviewed.list.length < unviewed.total : viewed.list.length < viewed.total
	}

	const viewedNotifications = useRef<UserNotification[]>([])
	const onNotificationView = useCallback((notification: UserNotification) => {
		viewedNotifications.current = [notification, ...viewedNotifications.current]
	}, [])

	const params = useParams<{ type: NotificationPages }>()

	const hasNewNotifications = !!unviewedList.length
	const hasPastNotifications = !!viewedList!.length

	const showPlaceholder = !hasNewNotifications && !hasPastNotifications

	const handleSetViewedNotificationsRequest = useCallback(() => {
		dispatch(setViewedNotificationsRequest(viewedNotifications.current))
	}, [dispatch])

	const handleLoadNotifications = useCallback(
		(status: NotificationViewStatuses) => () => {
			dispatch(loadUserNotificationsRequest(status))
		},
		[dispatch],
	)

	useEffect(
		() => () => {
			handleSetViewedNotificationsRequest()
		},

		[handleSetViewedNotificationsRequest],
	)

	return (
		<div className={classes.root}>
			<div className={classes.middleColumn}>
				<div className={classes.card}>
					<div className={classes.rootContainer}>
						<div className={classes.titleContainer}>
							<Typography className={classes.notificationsPopperTitle} variant="subtitle1">
								{`${upperFirst(params.type!)}`} Notifications
							</Typography>
							<Link to={SETTINGS_PATHS.NOTIFICATIONS} component={RouterLink}>
								<SettingsOutlinedIcon />
							</Link>
						</div>
						{showPlaceholder ? (
							<Typography>No notifications yet!</Typography>
						) : (
							<>
								{params.type !== 'past' && (
									<NotificationsBlock
										hideTitle={params.type === 'new'}
										onNotificationView={onNotificationView}
										notifications={unviewedList}
										isNew
										showDivider={hasPastNotifications}
										handleLoadMoreItems={handleLoadNotifications(NotificationViewStatuses.UNVIEWED)}
										isLoading={newLoading}
										canLoadMore={canLoadMore(true)}
									/>
								)}
								{hasPastNotifications && params.type !== 'new' && (
									<NotificationsBlock
										hideTitle={params.type === 'past'}
										notifications={viewedList}
										showDivider={false}
										handleLoadMoreItems={handleLoadNotifications(NotificationViewStatuses.VIEWED)}
										isLoading={pastLoading}
										canLoadMore={canLoadMore(false)}
									/>
								)}
							</>
						)}
					</div>
				</div>
			</div>
		</div>
	)
}

export default NotificationsPage
