export enum PhotoUploadDirectories {
	EVENT = 'event_uploads',
	POST = 'post_photos',
	MESSAGE = 'message_photos',
	ORGANIZATION = 'organization_photos',
	FORM = 'form_photos',
}

export const maxSizeMB = 2
export const maxImageWidth = 1920
export const maxAllowedImageSize = 8192 // https://caniuse.com/offscreencanvas

export enum UploadErrors {
	EVENT = 'event',
	POST = 'post',
	MESSAGE = 'message',
	ORGANIZATION = 'organization',
	FORM = 'form',
}

export enum UploadErrorMessage {
	POST = 'Something went wrong. Try posting again.',
	EVENT = 'Something went wrong. Try updating event again.',
}
