import React, { useCallback, useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'

import { URL_TYPES } from '@/constants/validations'
import {
	createAnnouncementRequest,
	getAnnouncementByIdRequest,
	updateAnnouncementRequest,
} from '@/features/announcements/announcementsSlice'
import { createResourceRequest, getResourceByIdRequest, updateResourceRequest } from '@/features/resources/resourcesSlice'
import { AppDispatch } from '@/store'
import { PostDto, PostParams, TPostData, TPostType } from '@/types'
import { useMediaQuery, useTheme } from '@mui/material'
import Dialog from '@mui/material/Dialog'
import { makeStyles } from '@mui/styles'

import MiddlePaneLoader from '../MiddlePaneLoader'
import { PostForm } from '../post/PostForm'
import { ModalHeader } from './ModalHeader'

const useStyles = makeStyles((theme) => ({
	content: {
		padding: `${theme.spacing(4.5)} ${theme.spacing(6.75)} ${theme.spacing(7)}`,
	},
}))

export type IPostModalProps = {
	type: TPostType
	id?: string
	open?: boolean
	handleClose?: () => void
}

const PostModal: React.FC<IPostModalProps> = ({ type, id, open = true, handleClose }) => {
	const theme = useTheme()
	const classes = useStyles()
	const dispatch = useDispatch<AppDispatch>()
	const fullScreen = useMediaQuery(theme.breakpoints.down('sm'))
	const [data, setData] = useState<PostDto | null>(null)
	const [dataLoading, setDataLoading] = useState<boolean>(id ? true : false)

	const title = id ? `Edit ${type}` : `Add ${type}`
	const subTitle = `Add important ${type.toLowerCase()}s to Student and Student Admin dashboards`

	const handleFormSubmit = useCallback(
		(data: TPostData) => {
			const transformedData = {
				title: data.title,
				description: data.description,
			}
			Object.keys(URL_TYPES).forEach((k) => {
				const fieldName = URL_TYPES[k]
				if (fieldName !== URL_TYPES.LINKEDIN) {
					transformedData[`${fieldName}Url`] = !data[fieldName] ? null : data[fieldName]
				}
			})
			const requestParams = { data: transformedData as PostParams, reloadList: true }
			if (!id) {
				if (type === 'Resource') {
					dispatch(createResourceRequest(requestParams))
				} else {
					dispatch(createAnnouncementRequest(requestParams))
				}
			} else {
				if (type === 'Resource') {
					dispatch(updateResourceRequest({ id, data: transformedData as PostParams }))
				} else {
					dispatch(updateAnnouncementRequest({ id, data: transformedData as PostParams }))
				}
			}

			handleClose()
		},
		[dispatch, handleClose, type, id],
	)
	const initPost = useCallback(
		async (id) => {
			const post =
				type === 'Resource' ? await dispatch(getResourceByIdRequest(id)).unwrap() : await dispatch(getAnnouncementByIdRequest(id)).unwrap()

			setDataLoading(false)
			setData(post)
		},
		[dispatch, type],
	)
	useEffect(() => {
		if (id) {
			initPost(id)
		} else {
			setData({} as PostDto)
		}
	}, [initPost, id])

	return (
		<Dialog fullScreen={fullScreen} fullWidth maxWidth="md" onClose={handleClose} open={open}>
			<ModalHeader handleClose={handleClose} subTitle={!id ? subTitle : ''} title={title} />

			<div className={classes.content}>
				{dataLoading ? <MiddlePaneLoader /> : data && <PostForm defaultData={data} onSubmit={handleFormSubmit} isUpdating={false} />}
			</div>
		</Dialog>
	)
}

export { PostModal }
