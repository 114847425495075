import * as actions from '@/store/forms/actions'
import { FormsState } from '@/store/forms/types'
import { createReducer } from '@reduxjs/toolkit'

const initialState: FormsState = {
	categories: [],
	templates: {
		Active: [],
		Drafts: [],
		Inactive: [],
	},
	isLoading: {
		templates: false,
		uploadingFormData: false,
		individualForm: false,
	},
	formList: {
		pageTabIndex: 0,
		tableTabIndex: 0,
	},
}

const formsReducer = createReducer(initialState, (builder) => {
	builder
		.addCase(actions.setCategories, (state, { payload }) => ({
			...state,
			categories: payload,
		}))
		.addCase(actions.saveFormRequest.pending, (state) => ({
			...state,
			isLoading: {
				...state.isLoading,
				uploadingFormData: true,
			},
		}))
		.addCase(actions.saveFormRequest.fulfilled, (state) => ({
			...state,
			isLoading: {
				...state.isLoading,
				uploadingFormData: false,
			},
		}))
		.addCase(actions.saveFormRequest.rejected, (state) => ({
			...state,
			isLoading: {
				...state.isLoading,
				uploadingFormData: false,
			},
		}))
		.addCase(actions.readTemplatesRequest.pending, (state) => ({
			...state,
			isLoading: {
				...state.isLoading,
				templates: true,
			},
		}))
		.addCase(actions.readTemplatesRequest.fulfilled, (state) => ({
			...state,
			isLoading: {
				...state.isLoading,
				templates: false,
			},
		}))
		.addCase(actions.readTemplatesRequest.rejected, (state) => ({
			...state,
			isLoading: {
				...state.isLoading,
				templates: false,
			},
		}))
		.addCase(actions.readFormRequest.pending, (state) => ({
			...state,
			isLoading: {
				...state.isLoading,
				individualForm: true,
			},
		}))
		.addCase(actions.readFormRequest.fulfilled, (state) => ({
			...state,
			isLoading: {
				...state.isLoading,
				individualForm: false,
			},
		}))
		.addCase(actions.readFormRequest.rejected, (state) => ({
			...state,
			isLoading: {
				...state.isLoading,
				individualForm: false,
			},
		}))
		.addCase(actions.setTemplates, (state, { payload }) => ({
			...state,
			templates: { ...state.templates, ...payload },
		}))
		.addCase(actions.setForm, (state, { payload }) => ({
			...state,
			selectedForm: payload,
		}))
		.addCase(actions.setFormListTab, (state, { payload }) => ({
			...state,
			formList: {
				...state.formList,
				...(payload as {
					pageTabIndex?: number
					tableTabIndex?: number
				}),
			},
		}))
		.addCase(actions.deleteFormFromTemplates, (state, { payload: { tab, formId } }) => {
			state.templates[tab] = state.templates[tab].map((category) => {
				return {
					...category,
					rows: category.rows.filter((row) => row.id !== formId),
				}
			})
		})
})

export { formsReducer }
