import { useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { Button } from '@mui/material'
import { makeStyles } from '@mui/styles'

import { loadUserNotificationsRequest, selectNewNotificationsCount } from '@/features/notifications/notificationsSlice'
import { NotificationViewStatuses } from '@/features/notifications/constants'

import { styles } from '@/components/shared'
import BellOutlinedIcon from '@/components/shared/icons/BellOutlinedIcon'
import NotificationsPopper from './NotificationPopper'
import CounterBadge from '@/components/badges/CounterBadge'

const useStyles = makeStyles((theme) => ({
	root: {
		display: 'inline-flex',
		flexDirection: 'column',
		marginRight: theme.spacing(7),
	},
	optionsContainer: ({ anchorOffsetWidth }: any) => ({
		margin: 10,
		maxHeight: '40vh',
		minWidth: anchorOffsetWidth ? `calc(${anchorOffsetWidth}px - 20px)` : 0,
		width: styles.FILL_AVAILABLE_WIDTH,
		overflowY: 'auto',
		backgroundColor: theme.colors.white[500],
		display: 'flex',
		flexDirection: 'column',
	}),
	paper: {
		marginTop: 10,
		zIndex: 1300,
		maxHeight: '40vh',
		...styles.BOX_SHADOW,
		backgroundColor: theme.colors.white[500],
		borderRadius: 10,
	},
	selectButton: {
		width: styles.FILL_AVAILABLE_WIDTH,
		color: theme.colors.black[500],
		border: 'none',
		padding: 0,
		minWidth: 0,
		'& > .MuiSvgIcon-root': {
			width: '34px',
			height: '34px',
		},
	},
	profileTabIcon: {
		width: '3.5vw',
		height: '3.5vw',
		minWidth: 30,
		minHeight: 30,
		maxWidth: 46,
		maxHeight: 46,
		[theme.breakpoints.down('sm')]: {
			width: 30,
			height: 30,
		},
	},
	userName: {
		margin: '0 2vw',
		fontWeight: 450,
	},
	expandIcon: {
		color: theme.colors.grey[500],
		marginRight: 10,
		width: 26,
		height: 26,
	},
	optionButton: {
		width: styles.FILL_AVAILABLE_WIDTH,
		fontWeight: 450,
		height: 50,
		'&>span': {
			justifyContent: 'flex-start',
		},
	},
}))

export const NavigationBarNotificationsButton = () => {
	const [open, setOpen] = useState(false)
	const anchorRef = useRef(null)
	const handleCloseNotificationPopover = () => setOpen(false)
	const dispatch = useDispatch()
	const newNotificationsCount = useSelector(selectNewNotificationsCount)

	const classes = useStyles({
		anchorOffsetWidth: anchorRef.current?.offsetWidth,
	})

	useEffect(() => {
		dispatch(loadUserNotificationsRequest(NotificationViewStatuses.UNVIEWED))
		dispatch(loadUserNotificationsRequest(NotificationViewStatuses.VIEWED))
	}, [dispatch])

	return (
		<div ref={anchorRef} className={classes.root}>
			<CounterBadge badgeContent={newNotificationsCount}>
				<Button aria-label="Notification Bell" disableRipple={true} className={classes.selectButton} onClick={() => setOpen((v) => !v)}>
					<BellOutlinedIcon />
				</Button>
			</CounterBadge>
			{open && <NotificationsPopper anchorEl={anchorRef.current} handleClose={handleCloseNotificationPopover} />}
		</div>
	)
}
