import { Controller } from 'react-hook-form'
import { useSelector } from 'react-redux'

import { CommonSelect } from '@/components/shared'
import { getOrgTypes } from '@/features/organizations/organizationSlice'
import { FormControl } from '@mui/material'

export const OrgTypeInput = ({ stateControl }) => {
	const orgTypeOptions = useSelector(getOrgTypes) || []

	const { control, errors } = stateControl

	return (
		<Controller
			name="type"
			control={control}
			render={({ field: { value, onChange } }) => (
				<FormControl variant="filled" className="u-minWidth470">
					<CommonSelect
						value={value}
						onChange={onChange}
						options={orgTypeOptions}
						placeholder="Select Type"
						error={!!errors.type}
						materialSelectOptions={{ fullWidth: true, native: false }}
					/>
				</FormControl>
			)}
		/>
	)
}
