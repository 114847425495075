import React, { forwardRef } from 'react'
import { Theme } from '@mui/material/styles'
import makeStyles from '@mui/styles/makeStyles'
import { FormControlLabel, Radio as MaterialRadio, RadioProps as MaterialRadioProps } from '@mui/material'

interface StyleProps {
	error?: boolean
	disabled?: boolean
	textColor?: string
}

const useStyles = makeStyles<Theme, StyleProps>((theme) => ({
	checkBoxIcon: {
		fontSize: '1em',
		[theme.breakpoints.up('xl')]: {
			width: '1.25em',
			height: '1.25em',
		},
	},
	checkBox: ({ error, textColor }) => ({
		color: error ? theme.colors.red[500] : textColor ? textColor : theme.colors.grey[500],
		'&.MuiCheckbox-colorPrimary.Mui-checked': {
			color: theme.colors.Primary[1500],
		},
	}),
	checkBoxLabel: ({ error, textColor }) => ({
		color: error ? theme.colors.red[500] : textColor ? textColor : theme.colors.grey[500],
	}),
}))

interface RadioProps extends MaterialRadioProps {
	label?: React.ReactNode
}

const Radio = forwardRef<any, RadioProps & StyleProps>(
	({ value = false, disabled = false, onChange, label = '', error, textColor }, ref) => {
		const classes = useStyles({ error, textColor, disabled })

		return (
			<FormControlLabel
				ref={ref}
				value="end"
				className={classes.checkBoxLabel}
				control={
					<MaterialRadio
						color="primary"
						classes={{
							root: classes.checkBox,
						}}
						checked={!!value}
						onChange={onChange}
						disabled={disabled}
					/>
				}
				label={label}
				labelPlacement="end"
			/>
		)
	},
)

export default Radio
