import last from 'lodash/last'

/**
 * Define base server url
 * @return Server base url
 */
const DEFAULT_BASE_URL = 'http://localhost:9000'

export const getBaseUrl = (): string => {
	const baseUrl = process.env.REACT_APP_BASE_API_URL || DEFAULT_BASE_URL
	return last(baseUrl) !== '/' ? `${baseUrl}/` : baseUrl
}

export const getQueryParamByName = (name: string) => {
	const urlParams = new URLSearchParams(window.location.search)
	const myParam = urlParams.get(name)
	return myParam || null
}

export const includeUrl = (pathNames: string[]): boolean => {
	const { pathname } = window.location
	const splitPathName = pathname.split('/')

	return pathNames.some((path) => {
		if (pathname === path) {
			return true
		}

		const splitPath = path.split('/')

		if (splitPathName.length < splitPath.length) {
			return false
		}

		if (splitPath.includes(':id')) {
			const restoredPathName = splitPath.map((p, index) => (p === ':id' ? ':id' : splitPathName[index])).join('/')

			return restoredPathName === path
		}

		return false
	})
}

export const getFormattedCurrentPath = (pathname: string) => {
	return pathname.endsWith('/') ? pathname.substring(0, pathname.length - 1) : pathname
}
