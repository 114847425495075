import { DateTime } from 'luxon'
import { Link } from 'react-router-dom'

import { getFullDateFormatted } from '@/utils/dateTime'
import { GridColumns, GridValueGetterParams } from '@mui/x-data-grid'
import { getFullName } from '@/utils/common'
import { EventDto } from './types'

export const EVENTS_ROOT_PATH = `/app/events`

export const EVENT_PATHS = {
	ROOT: EVENTS_ROOT_PATH,
	LIST: `${EVENTS_ROOT_PATH}/list`,
	NEW_LIST: `${EVENTS_ROOT_PATH}/list/new`,
	APPROVED_LIST: `${EVENTS_ROOT_PATH}/list/approved`,
	DENIED_LIST: `${EVENTS_ROOT_PATH}/list/denied`,
	EVENTS_CREATE: `${EVENTS_ROOT_PATH}/create`,
	EDIT_EVENT: (id?: string) => `${EVENTS_ROOT_PATH}/edit/${id !== undefined ? id : ':id'}`,
	VIEW_EVENT: (id?: string) => `${EVENTS_ROOT_PATH}/view/${id !== undefined ? id : ':id'}`,
}

export const MAX_VOLUNTEERS_PER_SHIFT = 1001
export const MAX_SHIFTS = 51

export const FORM = {
	eventName: 'eventName',
	startDate: 'startDate',
	startTime: 'startTime',
	endDate: 'endDate',
	endTime: 'endTime',
	repeating: 'repeating',
	repeatCount: 'repeatCount',
	description: 'description',
	campus: 'campus',
	locationType: 'locationType',
	locationName: 'locationName',
	streetAddress: 'streetAddress',
	virtualLink: 'virtualLink',
	hostOrganization: 'hostOrganization',
	categories: 'categories',
	contactName: 'contactName',
	contactEmail: 'contactEmail',
	photoUrl: 'photoUrl',
	addingShifts: 'addingShifts',
	shiftsNumber: 'shiftsNumber',
	volunteersPerShift: 'volunteersPerShift',
	shifts: 'shifts',
	externalUrl: 'externalUrl',
	exclude: 'exclude',
}

export type CreateEventFormKeys = keyof typeof FORM
export type CreateEventFormType = {
	[key in CreateEventFormKeys]?: any
}

export const getEventSubmitter = (event: EventDto) => {
	const { submittedBy, sourceSystem } = event

	return submittedBy ? getFullName(submittedBy?.firstName, submittedBy?.lastName) : sourceSystem || ' System'
}

export const EVENT_LIST_COLUMNS: GridColumns = [
	{
		field: 'name',
		headerName: 'Event Name',
		flex: 1,
		sortable: true,
		renderCell: (params) => {
			return <Link to={EVENT_PATHS.VIEW_EVENT(params.row.id)}>{params.row.name}</Link>
		},
	},
	{
		field: 'organizationName',
		headerName: 'Organization',
		flex: 1,
		sortable: false,
		valueGetter: (params: GridValueGetterParams<any, EventDto>) => {
			if (params.row.organization && params.row.organization.length > 0) {
				return params.row.organization.map((org) => org.name).join(' • ')
			} else {
				return ''
			}
		},
	},
	{
		field: 'submittedBy',
		headerName: 'Submitted By',
		flex: 1,
		sortable: false,
		valueGetter: (params: GridValueGetterParams<any, EventDto>) => getEventSubmitter(params.row),
	},
	{
		field: 'createdAt',
		headerName: 'Date Submitted',
		flex: 1,
		sortable: true,
		valueGetter: (params: GridValueGetterParams<any, EventDto>) =>
			params.row.createdAt ? getFullDateFormatted(params.row.createdAt) : 'N/A',
	},
	{
		field: 'startDate',
		headerName: 'Event Date',
		flex: 1,
		sortable: true,
		valueGetter: (params: GridValueGetterParams<any, EventDto>) =>
			params.row.startDate ? DateTime.fromISO(params.row.startDate).toLocaleString(DateTime.DATE_SHORT) : 'N/A',
	},
	{
		field: 'exclude',
		headerName: 'Exclude',
		flex: 1,
		type: 'boolean',
		editable: true,
		sortable: false,
	},
]

export enum EventLocationTypes {
	IN_PERSON = 'In-Person',
	VIRTUAL = 'Virtual',
}

export enum EventRepeatingOptions {
	DISABLED = 'Disabled',
	DAILY = 'Daily',
	WEEKLY = 'Weekly',
	MONTHLY = 'Monthly',
}
