import React, { useMemo } from 'react'

import { formatEventDate, getTimeFormatted, isSameDate } from '@/utils/dateTime'
import AccessTimeIcon from '@mui/icons-material/AccessTime'
import InsertLinkIcon from '@mui/icons-material/InsertLink'
import LocationOnOutlinedIcon from '@mui/icons-material/LocationOnOutlined'
import LoopIcon from '@mui/icons-material/Loop'
import PersonOutlineOutlinedIcon from '@mui/icons-material/PersonOutlineOutlined'
import { Chip, Link, Typography } from '@mui/material'
import { Link as RouterLink } from 'react-router-dom'
import { makeStyles } from '@mui/styles'
import { Box } from '@mui/system'

import { EventRepeatingOptions } from '../../constants'
import Card from './Card'
import { EventOrganizations } from '../../types'
import { ORGANIZATION_PATHS } from '@/features/organizations/constants'

const useStyles = makeStyles((theme) => ({
	iconContainer: {
		display: 'inline-flex',
		marginRight: theme.spacing(2.5),
		width: theme.spacing(4),
		height: theme.spacing(4),
		alignItems: 'center',
		justifyContent: 'center',
	},
	eventName: {
		fontWeight: theme.typography.fontWeightBold,
		fontSize: 25,
		lineHeight: '34px',
	},
	detailContainer: {
		display: 'flex',
		alignItems: 'center',
		marginBottom: theme.spacing(2.25),
	},
	dateInfo: {
		fontWeight: theme.typography.fontWeightMedium,
	},
	timeInfo: {
		fontWeight: theme.typography.fontWeightMedium,
		marginTop: theme.spacing(0.25),
		color: theme.colors.Neutral[500],
	},
	eventNameContainer: {
		display: 'flex',
		alignItems: 'center',
		marginBottom: theme.spacing(4),
		justifyContent: 'space-between',
	},
}))

export type IMainInfoProps = {
	startDate: string
	endDate: string
	name: string
	locationName: string
	organizations: EventOrganizations
	repeating: EventRepeatingOptions
	virtualLink: string
}

const MainInfo: React.FC<IMainInfoProps> = ({ startDate, endDate, name, locationName, organizations, repeating, virtualLink }) => {
	const isStartEndSame = isSameDate(startDate, endDate)
	const dataDateFormatted = formatEventDate(startDate)
	const startDateFormatted = formatEventDate(startDate, true)
	const endDateFormatted = formatEventDate(endDate, true)
	const startTime = getTimeFormatted(startDate)
	const endTime = getTimeFormatted(endDate)
	const classes = useStyles()

	// TODO: students has no access to organizations
	const organizationList = useMemo(() => {
		if (!organizations || organizations.length === 0) {
			return [{ id: 'no_host_organization', name: 'No Host Organization' }]
		}
		return organizations
	}, [organizations])

	return (
		<Card>
			<Box className={classes.eventNameContainer}>
				<Typography className={classes.eventName}>{name}</Typography>
				{repeating !== EventRepeatingOptions.DISABLED && <Chip icon={<LoopIcon />} label={repeating} variant="outlined" />}
			</Box>
			<Box marginBottom={2} alignItems={isStartEndSame ? 'flex-start' : 'center'} display="flex">
				<div className={classes.iconContainer}>
					<AccessTimeIcon color="info" />
				</div>
				<Box>
					<Typography className={classes.dateInfo}>
						{isStartEndSame ? dataDateFormatted : `${startDateFormatted} - ${endDateFormatted}`}
					</Typography>
					{isStartEndSame && <Typography className={classes.timeInfo}>{`${startTime} - ${endTime}`}</Typography>}
				</Box>
			</Box>
			<div className={classes.detailContainer}>
				<div className={classes.iconContainer}>
					<LocationOnOutlinedIcon color="info" />
				</div>
				<Typography fontWeight={'medium'}>{locationName}</Typography>
			</div>
			{virtualLink && (
				<div className={classes.detailContainer}>
					<div className={classes.iconContainer}>
						<InsertLinkIcon color="info" />
					</div>
					<Typography fontWeight={'medium'}>
						<Link target="_blank" href={virtualLink}>
							{virtualLink}
						</Link>
					</Typography>
				</div>
			)}
			{organizationList.map((org) => (
				<div className={classes.detailContainer}>
					<div className={classes.iconContainer}>
						<PersonOutlineOutlinedIcon color="info" />
					</div>
					<Typography fontWeight={'medium'}>{org.name}</Typography>
				</div>
			))}
		</Card>
	)
}

export { MainInfo }
