import { RootState } from '@/store'
import { enqueueSnackbar } from '@/store/app'
import { createDeletionThunk } from '@/utils/common'
import { creationSuccessToast } from '@/utils/notificationHelpers'
import { createAsyncThunk, createSelector, createSlice, isAnyOf } from '@reduxjs/toolkit'

import { createAnnouncement, deleteAnnouncement, getAnnouncementById, getAnnouncements, updateAnnouncementById } from './api'
import { AnnouncementDto, AnnouncementListParams, AnnouncementParams, AnnouncementResultsDto, AnnouncementsState } from './types'

const FEATURE_NAME = 'ANNOUNCEMENTS'

const initialState: AnnouncementsState = {
	list: {
		page: 1,
		isLoading: false,
		// Default value 20 come from backend
		limit: 20,
		total: 0,
		result: [],
		loadMore: true,
	},
	reloading: false,
	selected: {} as AnnouncementDto,
	updating: false,
}

export const createAnnouncementRequest = createAsyncThunk<
	AnnouncementDto,
	{
		data: AnnouncementParams
		reloadList?: boolean
	},
	{
		state: RootState
	}
>(`${FEATURE_NAME}/CREATE_Announcement_REQUESTS`, async ({ data, reloadList = false }, { rejectWithValue, dispatch }) => {
	try {
		const response = await createAnnouncement(data)
		dispatch(enqueueSnackbar(creationSuccessToast('Announcement')))

		if (reloadList) {
			dispatch(getAnnouncementsRequest({ page: 1 }))
		}
		return response.data
	} catch (e) {
		return rejectWithValue(e)
	}
})
export const updateAnnouncementRequest = createAsyncThunk<
	AnnouncementDto,
	{ id: string; data: AnnouncementParams; reloadList?: boolean },
	{
		state: RootState
	}
>(`${FEATURE_NAME}/UPDATE_Announcement_REQUESTS`, async ({ id, data, reloadList = true }, { rejectWithValue, dispatch }) => {
	try {
		const response = await updateAnnouncementById(id, data)
		if (reloadList) {
			dispatch(getAnnouncementsRequest({ page: 1 }))
		}
		return response.data
	} catch (e) {
		return rejectWithValue(e)
	}
})
export const getAnnouncementsRequest = createAsyncThunk<AnnouncementResultsDto, AnnouncementListParams, { state: RootState }>(
	`${FEATURE_NAME}/GET_AnnouncementS_REQUESTS`,
	async (params, { rejectWithValue, getState, dispatch }) => {
		try {
			const response = await getAnnouncements(params)

			return response.data
		} catch (e) {
			return rejectWithValue(e)
		}
	},
)
export const loadMoreAnnouncementsRequest = createAsyncThunk<any, undefined, { state: RootState }>(
	`${FEATURE_NAME}/LOAD_MORE_AnnouncementS_REQUESTS`,
	async (__, { rejectWithValue, getState, dispatch }) => {
		try {
			const {
				ANNOUNCEMENTS: {
					list: { page, limit, total, result },
				},
			} = getState()
			if (result.length < total) {
				const currentPage = page + 1
				dispatch(setListLoading(true))
				const response = await getAnnouncements({
					page: currentPage,
					limit,
				})
				dispatch(addListResults(response.data))
				dispatch(setListLoading(false))
				dispatch(setPage(currentPage))
			}
		} catch (e) {
			return rejectWithValue(e)
		}
	},
)

export const deleteAnnouncementRequest = createDeletionThunk(FEATURE_NAME, deleteAnnouncement)

export const getAnnouncementByIdRequest = createAsyncThunk<AnnouncementDto, string, { state: RootState }>(
	`${FEATURE_NAME}/GET_Announcement_BY_ID_REQUESTS`,
	async (id, { rejectWithValue, getState, dispatch }) => {
		try {
			const response = await getAnnouncementById(id)

			return response.data
		} catch (e) {
			return rejectWithValue(e)
		}
	},
)

export const announcementSlice = createSlice({
	name: FEATURE_NAME,
	initialState,
	reducers: {
		setPage: (state, { payload }) => {
			state.list.page = payload
		},
		setListLoading: (state, { payload }) => {
			state.list.isLoading = payload
		},
		addListResults: (state, { payload }) => {
			state.list.result = [...state.list.result, ...payload.result]
			if (state.list.result.length >= payload.total) {
				state.list.loadMore = false
			}
		},
	},
	extraReducers: (builder) => {
		builder
			.addCase(createAnnouncementRequest.pending, (state) => {
				state.updating = true
			})
			.addCase(getAnnouncementsRequest.pending, (state) => {
				state.reloading = true
			})
			.addCase(getAnnouncementsRequest.fulfilled, (state, { payload }) => {
				state.list.result = payload.result
				state.reloading = false
				state.list.total = payload.total
				state.list.loadMore = true
			})
			.addCase(getAnnouncementsRequest.rejected, (state) => {
				state.reloading = false
			})
			.addMatcher(isAnyOf(createAnnouncementRequest.fulfilled, createAnnouncementRequest.rejected), (state) => {
				state.updating = false
			})
	},
})

export const { setPage, setListLoading, addListResults } = announcementSlice.actions

const selectedState = (state: { [FEATURE_NAME]: AnnouncementsState }) => state[FEATURE_NAME]

export const getAnnouncementList = createSelector(selectedState, (state) => state.list)
export const getReloading = createSelector(selectedState, (state) => state.reloading)
