import MoreVertIcon from '@mui/icons-material/MoreVert'
import { alpha, IconButton, Menu, MenuItem, MenuProps, styled } from '@mui/material'
import React, { ReactNode, useState } from 'react'

const StyledMenu = styled((props: MenuProps) => (
	<Menu
		elevation={0}
		anchorOrigin={{
			vertical: 'bottom',
			horizontal: 'right',
		}}
		transformOrigin={{
			vertical: 'top',
			horizontal: 'right',
		}}
		{...props}
	/>
))(({ theme }) => ({
	'& .MuiPaper-root': {
		borderRadius: theme.shape.borderRadius,
		marginTop: theme.spacing(1),
		minWidth: 180,
		color: theme.palette.mode === 'light' ? 'rgb(55, 65, 81)' : theme.palette.grey[300],
		boxShadow: theme.shadows[3],
		'& .MuiMenu-list': {
			padding: '4px 0',
		},
		'& .MuiMenuItem-root': {
			display: 'flex',
			justifyContent: 'stretch',
			padding: 0,
			'& .MuiSvgIcon-root': {
				fontSize: 18,
				color: theme.palette.text.secondary,
				marginRight: theme.spacing(1.5),
			},
			'&:active': {
				backgroundColor: alpha(theme.palette.primary.main, theme.palette.action.selectedOpacity),
			},
		},
	},
}))

type TActionMenuItem = {
	title: ReactNode
	onClick?: () => void
	icon?: ReactNode
}

export type IActionMenuProps = {
	actions: TActionMenuItem[]
}

const ActionMenu: React.FC<IActionMenuProps> = ({ actions }) => {
	const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
	const open = Boolean(anchorEl)
	const handleClick = (event: any) => {
		setAnchorEl(event.currentTarget)
	}
	const handleClose = () => {
		setAnchorEl(null)
	}

	const handleMenuItemClick = (cb) => () => {
		handleClose()
		if (cb) cb()
	}

	return (
		<>
			<IconButton onClick={handleClick}>
				<MoreVertIcon />
			</IconButton>
			<StyledMenu anchorEl={anchorEl} open={open} onClose={handleClose}>
				{actions.map(({ icon, onClick, title }, index) => (
					<MenuItem key={index} onClick={handleMenuItemClick(onClick)} disableRipple>
						{icon}
						{title}
					</MenuItem>
				))}
			</StyledMenu>
		</>
	)
}

export { ActionMenu }
