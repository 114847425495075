import { faFile, faFilePdf, faFileWord, faImage, faFileExcel, faFilePowerpoint } from '@fortawesome/free-solid-svg-icons'

export const DOCUMENTS_ROOT_PATH = `/app/documents`
export const DOCUMENTS_PATH = {
	ROOT: DOCUMENTS_ROOT_PATH,
	NEW: `${DOCUMENTS_ROOT_PATH}/new`,
	EDIT: (id?: string) => `${DOCUMENTS_ROOT_PATH}/edit/${id !== undefined ? id : ':id'}`,
}

// Max allowed upload size determined by backend
export const MAX_UPLOAD_FILE_SIZE = 50 * 1024 * 1024 // 50MB

export const FILE_ICONS = {
	pdf: faFilePdf,
	doc: faFileWord,
	docx: faFileWord,
	defaultIcon: faFile,
	jpg: faImage,
	png: faImage,
	jpeg: faImage,
	ppt: faFilePowerpoint,
	pptx: faFilePowerpoint,
	xlsx: faFileExcel,
	xls: faFileExcel,
}

export enum FileTypes {
	pdf = 'pdf',
	doc = 'doc',
	image = 'image',
	ppt = 'ppt',
	excel = 'excel',
}

export const AVAILABLE_FILE_TYPES: Record<FileTypes, { accept: any; label: string; icon: any }> = {
	[FileTypes.pdf]: {
		accept: {
			'application/pdf': ['.pdf'],
		},
		label: 'Pdf',
		icon: FILE_ICONS['pdf'],
	},
	[FileTypes.doc]: {
		accept: {
			'application/msword': ['.doc'],
			'application/vnd.openxmlformats-officedocument.wordprocessingml.document': ['.docx'],
		},
		label: 'Word Document',
		icon: FILE_ICONS['doc'],
	},
	[FileTypes.image]: {
		accept: {
			'image/*': ['.png', '.jpeg'],
		},
		label: 'Image',
		icon: FILE_ICONS['image'],
	},
	[FileTypes.ppt]: {
		accept: {
			'application/vnd.openxmlformats-officedocument.presentationml.presentation': ['.pptx'],
			'application/vnd.ms-powerpoint': ['.ppt'],
		},
		label: 'PowerPoint',
		icon: FILE_ICONS['ppt'],
	},
	[FileTypes.excel]: {
		accept: {
			'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': ['.xlsx'],
			'application/vnd.ms-excel': ['.xls'],
		},
		label: 'Excel',
		icon: FILE_ICONS['xls'],
	},
}
