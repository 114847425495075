import { Fragment } from 'react'

import { ButtonColors, OutlinedButton, styles } from '@/components/shared'
import { submitFeedback } from '@/utils/services'
import { Link, Theme, Typography } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'

const useStyles = makeStyles<Theme, IFooterProps>((theme) => ({
	root: ({ inner }) => ({
		zIndex: 2,
		width: styles.FILL_AVAILABLE_WIDTH,
		backgroundColor: theme.colors.Primary[1000],
		minHeight: inner ? 80 : 172,
		color: theme.colors.white[500],
		display: 'flex',
		justifyContent: 'space-between',
		alignItems: 'center',
		padding: '0 3%',
		[theme.breakpoints.down('md')]: {
			minHeight: 100,
		},
		[theme.breakpoints.down('sm')]: {
			flexDirection: 'column',
			justifyContent: 'center',
		},
	}),
	link: {
		textDecoration: 'underline',
	},
	feedback: {
		margin: '0 10px',
		[theme.breakpoints.down('sm')]: {
			margin: '0 10px',
		},
	},
	feedbackText: {
		margin: '0 5px',
	},
	feedbackButton: {
		color: '#ffffff',
		marginLeft: 15,
		padding: '8px 12px',
		[theme.breakpoints.up('lg')]: {
			fontSize: '0.9em',
		},
		[theme.breakpoints.up('xl')]: {
			fontSize: '1em',
		},
		[theme.breakpoints.down('xl')]: {
			fontSize: '0.875em',
		},
		[theme.breakpoints.down('sm')]: {
			fontSize: '0.7em',
			minHeight: 24,
			width: '100%',
			marginLeft: 0,
			marginTop: theme.spacing(2),
			marginBottom: theme.spacing(2),
		},
	},
}))

const links = [
	{
		href: 'https://www.psu.edu/web-privacy-statement',
		label: 'Privacy',
	},
	{
		href: 'https://www.psu.edu/accessibilitystatement',
		label: 'Accessibility',
	},
]

type IFooterProps = {
	inner?: boolean
}

const Footer: React.FC<IFooterProps> = (props) => {
	const classes = useStyles(props)

	return (
		<footer className={classes.root}>
			<Typography className={classes.feedback}>
				👋 <b className={classes.feedbackText}> Need help? Have feedback? Let us know!</b>{' '}
				<OutlinedButton className={classes.feedbackButton} onClick={submitFeedback} color={ButtonColors.WHITE}>
					Submit Feedback
				</OutlinedButton>
			</Typography>
			<Typography className={classes.feedback}>
				Copyright 2022 © Navengage Education Technologies LLC |
				{links.map(({ href, label }, index) => (
					<Fragment key={href}>
						<Link href={href} target="_blank" color="inherit" className={classes.link} underline="hover">
							{label}
						</Link>{' '}
						{index < links.length - 1 ? '|' : ''}
					</Fragment>
				))}
			</Typography>
		</footer>
	)
}

export default Footer
