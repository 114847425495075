import React from 'react'

import { faImage } from '@fortawesome/free-regular-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Theme } from '@mui/material'
import { makeStyles } from '@mui/styles'

export enum ImagePlaceholderSizes {
	SMALL = 'small',
	BIG = 'big',
	LARGE = 'large',
}

const SIZES = {
	[ImagePlaceholderSizes.SMALL]: {
		width: 4,
		height: 4,
	},
	[ImagePlaceholderSizes.BIG]: {
		width: 8,
		height: 8,
	},
	[ImagePlaceholderSizes.LARGE]: {
		width: 32,
		height: 32,
	},
}

const useStyles = makeStyles<Theme, IImagePlaceholderProps>((theme) => ({
	root: ({ width, height }) => ({
		...theme.shape,
		width: width ?? '100%',
		height: height ?? '100%',
		backgroundColor: theme.colors.Neutral[200],
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
	}),
	placeholder: ({ placeholderSize }) => ({
		maxWidth: '80%',
		maxHeight: '80%',
		width: theme.spacing(SIZES[placeholderSize].width),
		height: theme.spacing(SIZES[placeholderSize].height),
		color: theme.colors.grey[400],
	}),
}))

export type IImagePlaceholderProps = {
	placeholderSize?: ImagePlaceholderSizes
	width?: number | string
	height?: number | string
}

const ImagePlaceholder: React.FC<IImagePlaceholderProps> = ({ placeholderSize = ImagePlaceholderSizes.SMALL, ...rest }) => {
	const classes = useStyles({ placeholderSize, ...rest })
	return (
		<div className={classes.root}>
			<FontAwesomeIcon className={classes.placeholder} icon={faImage} />
		</div>
	)
}

export { ImagePlaceholder }
