import { v4 as uuidv4 } from 'uuid'

import { LIST_INITIAL_STATE } from '@/constants/app'
import { RootState } from '@/store'
import { AutocompleteDto, IAutocompleteItemDto, ListRequestParams } from '@/types'
import { prepareListParams } from '@/utils/common'
import { createAsyncThunk, createSelector, createSlice } from '@reduxjs/toolkit'

import {
	getUserById,
	getUsersList,
	getCampusAdminsList,
	addCampusAdmin,
	deleteCampusAdmin,
	searchUsers,
	searchCampusAdminCandidates,
} from './api'
import { AutocompleteRequestParams, UserInfoDto, UserListQueryParams, UsersListDto, CampusAdminsListDto, UsersState } from './types'

const FEATURE_NAME = 'USERS'

const initialState: UsersState = {
	isLoading: false,
	selectedUserData: null,
	usersList: {
		...LIST_INITIAL_STATE,
	},
	campusAdminsList: {
		...LIST_INITIAL_STATE,
	},
}

export const addCampusAdminRequest = createAsyncThunk<
	any,
	any,
	{
		state: RootState
	}
>(`${FEATURE_NAME}/ADD_CAMPUS_ADMIN`, async (requestParams, { getState, dispatch, rejectWithValue }) => {
	try {
		const result = await addCampusAdmin(requestParams.userId)
		return result
	} catch (e: any) {
		rejectWithValue(e)
	}
})

export const getUsersListRequest = createAsyncThunk<
	UsersListDto,
	ListRequestParams,
	{
		state: RootState
	}
>(`${FEATURE_NAME}/GET_USERS_LIST_REQUEST`, async (requestParams, { getState, dispatch, rejectWithValue }) => {
	try {
		const {
			USERS: {
				usersList: { params },
			},
		} = getState()

		const queryParams = prepareListParams(
			{
				...params,
			},
			requestParams,
			dispatch,
			setListParams,
		) as UserListQueryParams

		const response = await getUsersList(queryParams)
		return response.data
	} catch (e: any) {
		return rejectWithValue(e)
	}
})

export const getCampusAdminsListRequest = createAsyncThunk<
	CampusAdminsListDto,
	ListRequestParams,
	{
		state: RootState
	}
>(`${FEATURE_NAME}/GET_CAMPUS_ADMINS_LIST_REQUEST`, async (requestParams, { getState, dispatch, rejectWithValue }) => {
	try {
		const {
			[FEATURE_NAME]: {
				campusAdminsList: { params },
			},
		} = getState()

		const queryParams = prepareListParams(
			{
				...params,
			},
			requestParams,
			dispatch,
			setListParams,
		) as UserListQueryParams

		const response = await getCampusAdminsList(queryParams)
		return response.data
	} catch (e: any) {
		return rejectWithValue(e)
	}
})

export const getSelectedUserInfoRequest = createAsyncThunk<
	UserInfoDto,
	number,
	{
		state: RootState
	}
>(`${FEATURE_NAME}/GET_USER_INFO_REQUEST`, async (userId, { getState, dispatch, rejectWithValue }) => {
	try {
		const response = await getUserById(userId)

		return response.data
	} catch (e: any) {
		return rejectWithValue(e)
	}
})

export const usersAutocompleteRequest = createAsyncThunk<
	AutocompleteDto<IAutocompleteItemDto>,
	AutocompleteRequestParams,
	{
		state: RootState
	}
>(`${FEATURE_NAME}/USERS_AUTOCOMPLETE_REQUEST`, async (params, { getState, dispatch, rejectWithValue }) => {
	try {
		const {
			userInfo: { campusId },
		} = getState().AUTH

		const response = await searchUsers(params, campusId)

		return response.data
	} catch (e: any) {
		return rejectWithValue(e)
	}
})

export const campusAdminCandidatesAutocompleteRequest = createAsyncThunk<
	AutocompleteDto<IAutocompleteItemDto>,
	AutocompleteRequestParams,
	{
		state: RootState
	}
>(`${FEATURE_NAME}/USERS_AUTOCOMPLETE_REQUEST`, async (params, { getState, dispatch, rejectWithValue }) => {
	try {
		const {
			userInfo: { campusId },
		} = getState().AUTH

		const response = await searchCampusAdminCandidates(params, campusId)

		return response.data
	} catch (e: any) {
		return rejectWithValue(e)
	}
})

export const deleteCampusAdminRequest = createAsyncThunk<
	any,
	{
		id: string
		cb?: () => void
	},
	{
		state: RootState
	}
>(`${FEATURE_NAME}/DELETE_CAMPUS_ADMIN_REQUEST`, async ({ id, cb }, { dispatch, getState, rejectWithValue }) => {
	try {
		await deleteCampusAdmin(id)

		if (cb) cb()
	} catch (e: any) {
		return rejectWithValue(e)
	}
})

export const usersSlice = createSlice({
	name: FEATURE_NAME,
	initialState,
	reducers: {
		setListParams: (state, { payload }) => {
			state.usersList.params = payload
		},
	},
	extraReducers: (builder) => {
		builder
			.addCase(getUsersListRequest.fulfilled, (state, { payload }) => {
				state.usersList.list = {
					...payload,
					results: payload.results.map((user) => ({
						id: uuidv4(),
						...user,
					})),
				}
				state.usersList.isLoading = false
			})
			.addCase(getCampusAdminsListRequest.fulfilled, (state, { payload }) => {
				state.campusAdminsList.list = payload
				state.usersList.isLoading = false
			})
			.addCase(getUsersListRequest.pending, (state) => {
				state.usersList.isLoading = false
			})
			.addCase(getUsersListRequest.rejected, (state) => {
				state.usersList.isLoading = false
			})
			.addCase(getSelectedUserInfoRequest.pending, (state) => {
				state.isLoading = true
			})
			.addCase(getSelectedUserInfoRequest.fulfilled, (state, { payload }) => {
				state.isLoading = false
				state.selectedUserData = payload
			})
			.addCase(getSelectedUserInfoRequest.rejected, (state) => {
				state.isLoading = false
			})
	},
})

const selectedState = (state: { [FEATURE_NAME]: UsersState }) => state[FEATURE_NAME]

export const selectUsersList = createSelector(selectedState, (state) => state.usersList)
export const selectAdminsList = createSelector(selectedState, (state) => state.campusAdminsList)
export const selectCurrentUserData = createSelector(selectedState, (state) => state.selectedUserData)
export const selectUsersIsLoading = createSelector(selectedState, (state) => state.isLoading)

export const { setListParams } = usersSlice.actions
