import { Box, styled } from '@mui/material'

const DropdownContainer = styled(Box)(({ theme }) => ({
	maxHeight: '60vh',
	overflowY: 'auto',
	backgroundColor: theme.colors.white[500],
	display: 'flex',
	flexDirection: 'column',
	boxShadow: '0px 3px 6px #00000029',
	border: `1px solid ${theme.colors.Neutral[300]}`,
	borderRadius: 10,
	marginTop: theme.spacing(3),
}))

export default DropdownContainer
