import { push } from 'connected-react-router'
import { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { Route, Router, Switch } from 'react-router-dom'

import PrivateRoute from '@/components/PrivateRoute'
import { PATHS } from '@/constants'
import * as ROUTES from '@/routes'
import { history } from '@/store'
import { autoSignInRequest, signInRequest } from '@/store/auth/actions'
import { getQueryParamByName } from '@/utils/urlHandlers'
import { makeStyles } from '@mui/styles'

const useStyles = makeStyles((theme) => ({
	routerModal: {
		position: 'absolute',
		left: 0,
		top: 0,
	},
}))

//import useWatchPageTitle from './hooks/useWatchPageTitle'

const App = () => {
	const dispatch = useDispatch()
	const redirect = `${window.location.pathname}${window.location.search}`

	const classes = useStyles()

	useEffect(() => {
		const code = getQueryParamByName('code')

		if (code) {
			dispatch(signInRequest(code)) // signin after redirect from OAuth
			dispatch(push(PATHS.AUTH_REDIRECT))
		} else {
			dispatch(autoSignInRequest(redirect))
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])

	return (
		<Router history={history}>
			<div id="router-modal" className={classes.routerModal}></div>
			<Switch>
				{ROUTES.rootRoutes.map((route) => {
					const { component: Component, ...rest } = route
					return !route.isPrivate ? (
						<Route key={route.name} {...rest} render={(props) => <Component {...props} />} />
					) : (
						<PrivateRoute key={route.name} {...route} />
					)
				})}
				<Route path="*">
					{/* FIXME - use src/pages/NoMatch.tsx */}
					<div>404</div>
				</Route>
			</Switch>
		</Router>
	)
}

export default App
