import EVENTS from '@/constants/paths/events'
import FORMS from '@/constants/paths/forms'
import HOME from '@/constants/paths/home'
import ORGANIZATIONS from '@/constants/paths/organizations'
import SETTINGS from '@/constants/paths/settings'

export const ROOT = '/app'

const mainPaths = {
	ROOT,
	ORGANIZATIONS: `${ROOT}/${ORGANIZATIONS.ROOT}`,
	ORGANIZATIONS_MY: `${ROOT}/${ORGANIZATIONS.MY}`,
	ORGANIZATIONS_CREATE: `${ROOT}/${ORGANIZATIONS.CREATE}`,
	ORGANIZATIONS_SINGLE: (id?: string) => `${ROOT}/${ORGANIZATIONS.SINGLE(id)}`,
	EVENT_SINGLE: (id?: string) => `${ROOT}/${EVENTS.SINGLE(id)}`,
	PROFILE: `${ROOT}/profile`,
	HOME: `${ROOT}/${HOME.ROOT}`,
	SETTINGS: `${ROOT}/${SETTINGS.ROOT}`,
	SETTINGS_BLOCKED_ACCOUNTS: `${ROOT}/${SETTINGS.BLOCKED_ACCOUNTS}`,
	SETTINGS_MUTED_ACCOUNTS: `${ROOT}/${SETTINGS.MUTED_ACCOUNTS}`,
	SETTINGS_PROFILE: `${ROOT}/${SETTINGS.PROFILE}`,
	SETTINGS_NOTIFICATIONS: `${ROOT}/${SETTINGS.NOTIFICATIONS}`,
	FORMS: `${ROOT}/${FORMS.ROOT}`,
	FORMS_LIST: `${ROOT}/${FORMS.ROOT}/list`,
	FORMS_SUBMISSION_LIST: `${ROOT}/${FORMS.ROOT}/list/submissions`,
	FORMS_TEMPLATE_LISTS: `${ROOT}/${FORMS.ROOT}/list/templates`,
	FORMS_CREATE: `${ROOT}/${FORMS.CREATE}`,
	FORMS_EDIT: (id?: string) => `${ROOT}/${FORMS.EDIT(id)}`,
	SUBMIT_FORM: (id?: string) => `${ROOT}/${FORMS.SUBMIT(id)}`,
	VIEW_SUBMISSION: (id?: string) => `${ROOT}/${FORMS.VIEW_SUBMISSION(id)}`,
	VIEW_FORM_SUBMISSIONS: (id?: string) => `${ROOT}/${FORMS.VIEW_FORM_SUBMISSIONS(id)}`,
}

export default mainPaths
