import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { initCurrentUserOrganizations, selectUserAdminOrganizations, selectUserOrganizations } from '../organizationSlice'

// @TODO: need to be refactored and switched to rtk query when we start moving
// probably better use autocomplete instead and use oms dedicated route
export const useCurrentUserOrganizations = () => {
	const userOrganizations = useSelector(selectUserOrganizations)
	const userAdminOrgs = useSelector(selectUserAdminOrganizations)
	const dispatch = useDispatch()
	useEffect(() => {
		if (!userOrganizations.list) {
			dispatch(initCurrentUserOrganizations())
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])
	return {
		all: userOrganizations.list,
		admin: userAdminOrgs,
		loading: userOrganizations.loading,
		loaded: userOrganizations.list && !userOrganizations.loading,
	}
}
