import { isNumber } from 'lodash'

import * as Cognito from '@/api/Cognito'
import { ROLES } from '@/constants'
import * as config from '@/constants/configuration'
import localStorageKeys from '@/constants/localStorage'
import { UserInfo } from '@/interfaces/user'
import LocalStorage from '@/utils/storage'

import { redirectToSignup } from './linksHelper'

const USER_TYPES: { [type: string]: number } = {
	[ROLES.STUDENT]: 1,
	[ROLES.STUDENT_ADMIN]: 2,
	[ROLES.CAMPUS_ADMIN]: 3,
}

export const USER_ROLE_NAMES = {
	[ROLES.STUDENT]: 'Student',
	[ROLES.STUDENT_ADMIN]: 'Student Admin',
	[ROLES.CAMPUS_ADMIN]: 'Campus Admin',
}

export const getUserType = (type: string): number => {
	const userType = type.toLowerCase()
	if (userType.includes(ROLES.STUDENT)) return 1

	if (userType.includes(ROLES.STUDENT_ADMIN)) return 2

	if (userType.includes(ROLES.CAMPUS_ADMIN)) return 3

	return 4
}

export const getUserRole = (appUserTypeId: number) => {
	const roleById = Object.keys(USER_TYPES).reduce((acc, key) => {
		return {
			...acc,
			[USER_TYPES[key]]: key,
		}
	}, {}) as { [key: string]: ROLES }

	if (appUserTypeId === 4) return ROLES.STUDENT //mock "other" role

	return roleById[`${appUserTypeId}`] || ROLES.GUEST
}

export const isStudent = (role: ROLES) => role === ROLES.STUDENT

export const isAdmin = (role: ROLES) => role === ROLES.CAMPUS_ADMIN

export const getAllRoles = () => Object.values(ROLES)
export const getAllAuthorizedRoles = () => Object.values(ROLES).filter((role) => role !== ROLES.GUEST)

export const getAllAuthorizedNonStudentRoles = () => getAllAuthorizedRoles().filter((role) => role !== ROLES.STUDENT)

export const handleSignIn = () => window.open(config.COGNITO_AUTH_CALL, '_self')

export const getTokenExpirationDate = (expiresIn: number) => {
	let expiration = new Date() // note - this is all in UTC
	expiration.setSeconds(expiration.getSeconds() + expiresIn)

	return expiration
}

// Function to get (and save) a new access token using a refresh token
export async function handleRefreshToken() {
	const refresh = LocalStorage.get(localStorageKeys.REFRESH_TOKEN_PATH)

	const response = await Cognito.refreshToken(refresh)

	const newAccessToken = response.data.access_token
	const tokenExpiration = getTokenExpirationDate(response.data.expires_in)

	LocalStorage.set(localStorageKeys.ACCESS_TOKEN_PATH, newAccessToken)
	LocalStorage.set(localStorageKeys.ACCESS_TOKEN_EXPIRATION_PATH, JSON.stringify(tokenExpiration))

	return newAccessToken
}

export const isDevEnv = () => 'local' === process.env.REACT_APP_ENV
export const getMockAccessToken = (userId: number) => `mock-access-token-${userId}`

export const devSignupHandler = (role: ROLES | null) => {
	const roleToTestUseId = {
		[ROLES.STUDENT]: 1, // John Doe
		[ROLES.STUDENT_ADMIN]: 2, // Maryne Eva
		[ROLES.STUDENT]: 3, // @TODO: Needs to be created in db
		[ROLES.UNKNOWN]: 4, // @TODO: Needs to be created in db
	}

	const token = getMockAccessToken(role ? roleToTestUseId[role] : roleToTestUseId[ROLES.UNKNOWN])

	redirectToSignup(token)
}

export function checkTestingData() {
	if (!isDevEnv()) return false

	if (!isDevEnv) return false

	const token = LocalStorage.get(localStorageKeys.ACCESS_TOKEN_PATH)

	return JSON.stringify(token).includes('mock')
}

export const userHasSignUpData = (role: ROLES, userInfo?: UserInfo) =>
	role === ROLES.STUDENT
		? Object.keys(userInfo?.signupResponses || {}).length && userInfo?.intendedGraduationDate
		: userInfo?.positionTitle && userInfo?.department

export const isCurrentUser = (currentUserId?: number, id?: number) => isNumber(currentUserId) && isNumber(id) && currentUserId === id

export const getToken = () => LocalStorage.get(localStorageKeys.ACCESS_TOKEN_PATH) || ''
