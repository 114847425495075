import { EntityList, EntityListing, EntityListParams } from '@/types'

import { EventStatuses } from '../events/types'
import { ORGANIZATION_PRIMARY_ROLES } from './constants'

// @TODO: #refactor IOrganization should be created and other interfaces should extend it
export interface Organization {
	name: string
	externalId: string
	categories: {
		id: number
		category: string
	}[]
	campus: {
		id: number
		campusName: string
	}[]
	schoolId: number
	createdAt: string
	updatedAt: string
	photoUrl: string
	contactEmail: string
	facebookUrl?: string
	instagramUrl?: string
	twitterUrl?: string
	linkedinUrl?: string
	campusId?: number
	contactName: string
	status: string
	id: string
	dataLake: boolean
	websiteUrl: string
	sourceSystem: null
	organizationTypeId: number
	description: string
	exclude: boolean
	isActivated: boolean
	followed: boolean
}

export interface IOrganizationListItem {
	id: string
	name: string
	status: string
	type: string
	category: string
}
export interface UserOrganizations {
	followed: Organization[]
	member: Organization[]
	admin: Organization[]
	superAdmin: Organization[]
}
export interface OrganizationsState {
	categories: {
		label: string
		value: number
	}[]
	types: {
		label: string
		value: number
	}[]
	statuses: {
		label: string
		value: string
	}[]
	constantsInitialized: boolean
	isLoading: boolean
	organizationList: EntityList<IOrganizationListItem>
	organizationRegistrationList: EntityList<IOrganizationRegistration>
	userOrganizations: {
		list?: UserOrganizations
		loading: boolean
	}
	selectedOrganization: OrganizationDto
	organizationEventList: EntityList<IOrganizationEventListItem>
	initialized: boolean
}

export type OrganizationsDto = EntityListing<IOrganizationListItem>

export type OrganizationsListParams = EntityListParams & { type?: string }

export enum OrganizationStatuses {
	Active = 'Active',
	Frozen = 'Frozen',
	Inactive = 'Inactive',
	Locked = 'Locked',
	Dormant = 'Dormant',
	Pending = 'Pending',
}

export type OrganizationAdmin = {
	email: string
	userName: string
}

export type TOrganizationPrimaryRoleKeys = keyof typeof ORGANIZATION_PRIMARY_ROLES

export type TOrganizationPrimaryUser = { id: number; email: string; fullName: string }

export type OrganizationDto = {
	id: string
	name: string
	status: OrganizationStatuses
	rolePrimaryLeaderUserId: string | null
	rolePrimaryLeaderUserEmail: string | null
	rolePrimaryLeaderUserName: string | null
	rolePrimaryContactAffairsUserId: string | null
	rolePrimaryContactAffairsUserEmail: string | null
	rolePrimaryContactAffairsUserName: string | null
	rolePrimaryContactFinanceUserId: string | null
	rolePrimaryContactFinanceUserEmail: string | null
	rolePrimaryContactFinanceUserName: string | null
	rolePrimaryContactPublicUserId: string | null
	rolePrimaryContactPublicUserEmail: string | null
	rolePrimaryContactPublicUserName: string | null
	memberCount: number
	superAdmins: Array<OrganizationAdmin>
	admins: Array<OrganizationAdmin>
}

export type OrganizationEventListParams = Omit<EntityListParams, 'status' | 'category' | 'sort'>

export interface IOrganizationEventListItem {
	id: string
	name: string
	submittedBy: string
	createdAt: string
	startDate: string
	status: EventStatuses
}

export type OrganizationEventsDto = {
	organization: {
		id: string
		name: string
	}
	list: EntityListing<IOrganizationEventListItem>
}

export interface IOrganizationRegistration {
	id: string
	name: string
	userName: string
	createdAt: string
	status: OrganizationStatuses
}

export type OrganizationRegistrationDto = EntityListing<IOrganizationRegistration>

export type OrganizationCategoryDto = {
	id: number
	category: string
	createdAt: string
	externalUpdatedAt: string
	updatedAt: string
}
