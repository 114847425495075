import http from '@/api/http/requestHttp'
import * as config from '@/constants/configuration'

const getFilters = async () => {
	const url = `${config.PSU_SCHOOL_ID}/organizations/org/filters`

	return await http.get(url)
}

export default getFilters
