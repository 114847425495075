import React from 'react'

import Router from '@/components/Router'

import { USERS_PATHS } from '../constants'
import USERS_ROUTES from '../routes'

const UsersPage = React.memo(() => {
	return (
		<Router
			routes={USERS_ROUTES}
			redirectPaths={[
				{
					from: USERS_PATHS.ROOT,
					to: USERS_PATHS.LIST('students'),
				},
			]}
		/>
	)
})

export default UsersPage
