import React from 'react'
import { useSelector } from 'react-redux'
import { Link } from 'react-router-dom'

import { DataTable } from '@/components/EntityManager/DataTable'
import { PATHS } from '@/constants'
import { getFormsListRequest, selectFormsList } from '@/features/formSubmissions/formSubmissionsSlice'
import { GridColumns } from '@mui/x-data-grid'

const columns: GridColumns = [
	{
		field: 'title',
		headerName: 'Form Name',
		flex: 1,
		sortable: false,
		filterable: false,
		renderCell: (params) => {
			return <Link to={PATHS.APP.SUBMIT_FORM(params.row.id)}>{params.row.title}</Link>
		},
	},
	{
		field: 'category',
		headerName: 'Type',
		flex: 1,
		sortable: false,
		filterable: false,
	},
	{
		field: 'description',
		headerName: 'Description',
		flex: 1,
		sortable: false,
		filterable: false,
	},
]

const MyForms = () => {
	const formsList = useSelector(selectFormsList)
	return <DataTable data={formsList} request={getFormsListRequest} columns={columns} />
}

export default MyForms
