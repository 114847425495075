import classNames from 'classnames'
import React, { useCallback, useEffect, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Redirect, Route, Switch, useHistory } from 'react-router-dom'

import RouterTabs from '@/components/RouterTabs'
import { getFormattedCurrentPath } from '@/utils/urlHandlers'
import CachedIcon from '@mui/icons-material/Cached'
import { Button, styled, Typography } from '@mui/material'
import { makeStyles } from '@mui/styles'

import { ShiftList } from '../components/ShiftList'
import { EVENT_PATHS } from '../constants'
import {
	getEventAttendeesRequest,
	getEventVolunteersDataRequest,
	hasVolunteerShift,
	refreshEventDetailsRequest,
	selectAttendeeLists,
	selectAttendeesListIsLoading,
	selectEventDetailsIsRefreshing,
	selectVolunteersData,
} from '../eventsSlice'
import { getShiftsInfo } from '../helpers'
import { EventUsersList } from './EventUsersList'
import { EventActions } from './sidebar/EventActions'

const REFRESH_TIME = 10000

const RefreshButton = styled(Button, {
	shouldForwardProp: (prop) => prop !== 'active',
})<{ active?: boolean }>(({ theme, active }) => ({
	...theme.typography.body1,
	color: theme.colors.Primary[700],
	textTransform: 'none',
	fontWeight: theme.typography.fontWeightMedium,
	'& .MuiSvgIcon-root': {
		animation: active ? 'spin 1s linear infinite' : 'none',
	},
	'@keyframes spin': {
		'0%': {
			transform: 'rotate(360deg)',
		},
		'100%': {
			transform: 'rotate(0deg)',
		},
	},
}))

const useStyles = makeStyles((theme) => ({
	sidebar: {
		maxWidth: 580,
		flex: 1,
		display: 'flex',
		flexDirection: 'column',
	},
	infoBox: {
		padding: `${theme.spacing(5.25)} ${theme.spacing(10)} ${theme.spacing(5.25)} ${theme.spacing(5.5)}`,
		border: `1px solid ${theme.colors.Neutral[300]}`,
		borderRadius: 7,
		flex: 1,
	},

	userLists: {
		marginTop: theme.spacing(5),
	},
	greyText: {
		color: theme.colors.Neutral[500],
	},
	blackText: {
		color: theme.colors.black[500],
	},
	emptyListHeading: {
		fontWeight: theme.typography.fontWeightBold,
		marginBottom: theme.spacing(2),
	},
	infoBoxTop: {
		display: 'flex',
		justifyContent: 'space-between',
		alignItems: 'center',
	},
	actionsDropdown: {
		zIndex: 1300,
	},
}))

export type IEventSidebarProps = {
	id: string
}

const EventSidebar: React.FC<IEventSidebarProps> = ({ id }) => {
	const history = useHistory()
	const classes = useStyles()
	const dispatch = useDispatch()

	const attendeeLists = useSelector(selectAttendeeLists)
	const isAttendeesLoading = useSelector(selectAttendeesListIsLoading)
	const volunteersData = useSelector(selectVolunteersData)
	const isRefreshing = useSelector(selectEventDetailsIsRefreshing)
	const volunteerShiftEnabled = useSelector(hasVolunteerShift)

	const currentPath = getFormattedCurrentPath(history.location.pathname)

	const TABS = useMemo(() => {
		const tabs = [
			{
				label: 'Going',
				value: `${EVENT_PATHS.VIEW_EVENT(id)}/going`,
			},
			{
				label: 'Interested',
				value: `${EVENT_PATHS.VIEW_EVENT(id)}/interested`,
			},
			{
				label: 'Attendees',
				value: `${EVENT_PATHS.VIEW_EVENT(id)}/attendees`,
			},
		]
		if (volunteerShiftEnabled) {
			tabs.push({
				label: 'Volunteers',
				value: `${EVENT_PATHS.VIEW_EVENT(id)}/volunteers`,
			})
		}
		return tabs
	}, [id, volunteerShiftEnabled])

	const currentList = useMemo(() => {
		return TABS.find((tab) => tab.value === currentPath)
	}, [currentPath, TABS])

	useEffect(() => {
		dispatch(getEventAttendeesRequest(id))
		dispatch(getEventVolunteersDataRequest(id))
		const dataPoller = setInterval(() => {
			dispatch(refreshEventDetailsRequest(id))
		}, REFRESH_TIME)
		return () => clearInterval(dataPoller)
	}, [dispatch, id])
	const getUsersCount = (label) => {
		return attendeeLists[label.toLocaleLowerCase()].length
	}
	const { totalSpots, bookedSpots } = getShiftsInfo(volunteersData.volunteerShifts || [])
	const handleRefresh = useCallback(() => {
		dispatch(refreshEventDetailsRequest(id))
	}, [dispatch, id])

	const listUsersCountInfo =
		currentList && currentList.label !== 'Volunteers' ? getUsersCount(currentList.label) : `${bookedSpots}/${totalSpots}`

	return (
		<div className={classes.sidebar}>
			<EventActions id={id} />
			<div className={classes.infoBox}>
				<div className={classes.infoBoxTop}>
					<Typography>
						<strong>Attendance</strong> | {listUsersCountInfo} {currentList?.label}
					</Typography>
					<RefreshButton onClick={handleRefresh} variant="outlined" active={isRefreshing} startIcon={<CachedIcon />}>
						Refresh
					</RefreshButton>
				</div>
				<div className={classes.userLists}>
					<RouterTabs tabs={TABS} fullWidth={true} />
					<Switch>
						<Route exact path={`${EVENT_PATHS.VIEW_EVENT(id)}/going`}>
							<EventUsersList
								isLoading={isAttendeesLoading}
								data={attendeeLists.going}
								emptyPlaceholder={<Typography className={classes.greyText}>No one has signed up to go to this event yet.</Typography>}
							/>
						</Route>
						<Route exact path={`${EVENT_PATHS.VIEW_EVENT(id)}/interested`}>
							<EventUsersList
								isLoading={isAttendeesLoading}
								data={attendeeLists.interested}
								emptyPlaceholder={<Typography className={classes.greyText}>No one is interested in this event yet.</Typography>}
							/>
						</Route>
						<Route exact path={`${EVENT_PATHS.VIEW_EVENT(id)}/attendees`}>
							<EventUsersList
								isLoading={isAttendeesLoading}
								data={attendeeLists.attendees}
								emptyPlaceholder={
									<>
										<Typography className={classNames(classes.emptyListHeading, classes.blackText)}>Want to track attendance?</Typography>
										<Typography className={classes.blackText}>
											Create a QR code on the Engagement App, then ask attendees to scan it to populate this list. Go to this event on your
											mobile app to get started.
										</Typography>
									</>
								}
							/>
						</Route>
						{volunteerShiftEnabled && (
							<Route exact path={`${EVENT_PATHS.VIEW_EVENT(id)}/volunteers`}>
								<ShiftList
									volunteers={volunteersData.volunteers}
									shifts={volunteersData.volunteerShifts}
									emptyPlaceholder={<Typography className={classes.greyText}>No volunteers.</Typography>}
								/>
							</Route>
						)}
						<Redirect from="/" to={`${EVENT_PATHS.VIEW_EVENT(id)}/going`} />
					</Switch>
				</div>
			</div>
		</div>
	)
}

export { EventSidebar }
