import React from 'react'
import { useSelector } from 'react-redux'
import { Link } from 'react-router-dom'

import { DataTable } from '@/components/EntityManager/DataTable'
import { getFullDateFormatted } from '@/utils/dateTime'
import { GridColumns } from '@mui/x-data-grid'

import { ORGANIZATION_PATHS } from '../../constants'
import { getOrganizationsRegistrationRequest, selectOrganizationRegistrations } from '../../organizationSlice'
import { OrganizationStatuses } from '../../types'

const columns: GridColumns = [
	{
		field: 'name',
		headerName: 'Name',
		filterable: false,
		flex: 1,
		sortable: false,
		renderCell: (params) => {
			return <Link to={ORGANIZATION_PATHS.SINGLE(params.row.id)}>{params.row.name}</Link>
		},
	},
	{
		field: 'userName',
		headerName: 'Submitted By',
		filterable: false,
		sortable: false,
		flex: 1,
	},
	{
		field: 'createdAt',
		headerName: 'Date Submitted',
		filterable: false,
		sortable: false,
		valueGetter: (params: any) => getFullDateFormatted(params.row.createdAt),
		flex: 1,
	},
	{
		field: 'status',
		headerName: 'Status',
		filterable: false,
		sortable: false,
		flex: 1,
		editable: true,
		type: 'singleSelect',
		valueOptions: Object.values(OrganizationStatuses),
	},
]

const Pending = () => {
	const organizationRegistrations = useSelector(selectOrganizationRegistrations)
	return <DataTable data={organizationRegistrations} request={getOrganizationsRegistrationRequest} columns={columns} />
}

export default Pending
