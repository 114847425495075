import { MODULE_NAME } from '@/store/organizations/constants'
import { OrganizationsState } from '@/store/organizations/types'
import { createSelector } from '@reduxjs/toolkit'

const selectState = (state: { [MODULE_NAME]: OrganizationsState }) => state[MODULE_NAME]

export const getIsLoading = createSelector(selectState, (state) => state.list.isLoading)
export const getCategories = createSelector(selectState, (state) => state.categories)
export const getOrgTypes = createSelector(selectState, (state) => state.types)
export const getOrgStatuses = createSelector(selectState, (state) => state.statuses)
export const getOrganizationsList = createSelector(selectState, (state) => state.list)
export const getUserOrganizations = createSelector(selectState, (state) => state.userOrganizations)
