import AddOutlinedIcon from '@mui/icons-material/AddOutlined'
import React from 'react'

import DropdownContainer from '@/components/header/DropdownContainer'
import { ButtonColors, ColorButton, OptionButton } from '@/components/shared'
import usePopper from '@/hooks/usePopper'
import { Popper } from '@mui/material'
import { AVAILABLE_DROPDOWN_ENTITIES, TDropdownEntitiesKeys } from '../../config'

export type IGenerateButtonProps = {
	disabled?: boolean
	onChange: (key: TDropdownEntitiesKeys) => void
	classes?: {
		button?: string
		popper?: string
		dropdown?: string
	}
}

const GenerateButton: React.FC<IGenerateButtonProps> = ({ disabled, onChange, classes }) => {
	const { handleToggle, anchorRef, popperRef, open } = usePopper()
	const clickHandler = (key: TDropdownEntitiesKeys) => () => {
		onChange(key)
	}
	return (
		<>
			<ColorButton
				className={classes?.button}
				color={ButtonColors.WHITE}
				onClick={handleToggle}
				startIcon={<AddOutlinedIcon />}
				disabled={disabled}
				ref={anchorRef}
			>
				Generate from
			</ColorButton>
			<Popper className={classes?.popper} placement="bottom-end" ref={popperRef} open={open} anchorEl={anchorRef.current} role={undefined}>
				<DropdownContainer className={classes.dropdown}>
					{(Object.keys(AVAILABLE_DROPDOWN_ENTITIES) as TDropdownEntitiesKeys[]).map((key) => (
						<OptionButton key={key} onClick={clickHandler(key)}>
							{AVAILABLE_DROPDOWN_ENTITIES[key]}
						</OptionButton>
					))}
				</DropdownContainer>
			</Popper>
		</>
	)
}

export { GenerateButton }
