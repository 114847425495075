import { v4 as uuidv4 } from 'uuid'

import { Notification, SnackbarType } from '@/store/app/types'

const AUTO_HIDE_DURATION = 5000

const DEFAULT_OPTIONS = {
	autoHideDuration: AUTO_HIDE_DURATION,
}

interface IToastBody {
	key: string
	notification: Notification
}

export const createFieldUpdateToast = (fieldName: string): IToastBody => {
	return {
		key: uuidv4(),
		notification: {
			message: {
				message: `${fieldName} has been updated successfully`,
				type: SnackbarType.success,
			},
			options: {
				autoHideDuration: AUTO_HIDE_DURATION,
			},
		},
	}
}

export const creationSuccessToast = (entity?: string, message?: string, onClick?: (messageId: string) => void): IToastBody => {
	const messageId = uuidv4()
	let dynamicMessage = 'Success!'
	if (entity) {
		dynamicMessage = `${entity} has been created successfully`
	} else if (message) {
		dynamicMessage = message
	}
	const messageData = {
		key: messageId,
		notification: {
			message: {
				message: dynamicMessage,
				type: SnackbarType.success,
			},
			options: DEFAULT_OPTIONS,
		},
	}
	if (onClick) {
		messageData.notification.message['callback'] = {
			label: `See ${entity}`,
			onClick: () => onClick(messageId),
		}
	}
	return messageData
}
