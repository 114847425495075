import { Controller } from 'react-hook-form'
import { useSelector } from 'react-redux'

import { CommonSelect } from '@/components/shared'
import { getCategories } from '@/features/organizations/organizationSlice'
import { FormControl } from '@mui/material'

export const CategoriesInput = ({ stateControl }) => {
	const categoriesOptions = useSelector(getCategories) || []
	const { control, errors, getValues } = stateControl
	const values = getValues()

	return (
		<Controller
			name="categories"
			defaultValue={values.categories}
			control={control}
			render={({ field: { value, onChange } }) => (
				<FormControl variant="filled" className="u-minWidth470">
					<CommonSelect
						value={value}
						onChange={onChange}
						options={categoriesOptions}
						placeholder="Select Categories"
						error={!!errors.category}
						multiple
						materialSelectOptions={{ fullWidth: true, native: false }}
					/>
				</FormControl>
			)}
		/>
	)
}
