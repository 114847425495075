import React from 'react'
import { useForm } from 'react-hook-form'
import * as yup from 'yup'

import { YUP_URLS_SCHEMA } from '@/constants/validations'
import { socialMediaInputs } from '@/pages/createOrganization/section/questions'
import { SocialMediaInput } from '@/pages/createOrganization/section/socialMediaSection/SocialMediaInput'
import { PostDto, TPostData } from '@/types'
import { yupResolver } from '@hookform/resolvers/yup'
import { makeStyles } from '@mui/styles'

import { ControlledInput } from '../inputs/ControlledInput'
import { ColorButton } from '../shared'

const useStyles = makeStyles((theme) => ({
	links: {
		marginBottom: theme.spacing(6),
	},
	label: {
		marginBottom: theme.spacing(4.25),
	},
	formActions: {
		textAlign: 'center',
	},
	formAction: {
		minWidth: 227,
		padding: theme.spacing(1.5),
		textTransform: 'none',
	},
}))

const schema = yup.object().shape({
	title: yup.string().required(),
	description: yup.string().required(),
	...YUP_URLS_SCHEMA,
})

export type IPostFormProps = {
	defaultData?: PostDto
	isUpdating: boolean
	onSubmit?: (data: TPostData) => void
}

const PostForm: React.FC<IPostFormProps> = ({
	defaultData,
	isUpdating = false,

	onSubmit,
}) => {
	const classes = useStyles()
	let defaultValues = {}
	if (defaultData && defaultData.id) {
		const {
			title,
			description,
			websiteUrl: website,
			facebookUrl: facebook,
			twitterUrl: twitter,
			instagramUrl: instagram,
			youtubeUrl: youtube,
		} = defaultData
		defaultValues = {
			title,
			description,
			youtube,
			twitter,
			instagram,
			website,
			facebook,
		}
	}
	const isNew = defaultData && defaultData.id ? false : true

	const {
		control,
		handleSubmit,
		getValues,
		formState: { errors, isDirty, isValid },
		register,
	} = useForm<TPostData>({
		resolver: yupResolver(schema),
		mode: 'onChange',
		reValidateMode: 'onChange',
		criteriaMode: 'firstError',
		shouldFocusError: true,
		defaultValues: defaultValues,
	})

	const stateControl = { control, isDirty, isValid, errors, getValues }
	return (
		<form onSubmit={handleSubmit(onSubmit)}>
			<ControlledInput
				control={control}
				name="title"
				required={true}
				label="Title"
				disabled={isUpdating}
				errors={errors}
				labelClass={classes.label}
				register={register}
			/>
			<ControlledInput
				control={control}
				name="description"
				label="Description"
				disabled={isUpdating}
				required={true}
				errors={errors}
				labelClass={classes.label}
				register={register}
				multiline={true}
			/>
			<div className={classes.links}>
				{socialMediaInputs.map((data) => (
					<SocialMediaInput key={data.type} {...{ ...data, stateControl }} />
				))}
			</div>
			<div className={classes.formActions}>
				<ColorButton disabled={!isValid} onClick={handleSubmit(onSubmit)} className={classes.formAction}>
					{!isNew ? 'Update' : 'Add'}
				</ColorButton>
			</div>
		</form>
	)
}

export { PostForm }
