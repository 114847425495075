import { useCallback, useEffect, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'

import Footer from '@/components/Footer'
import CreateButton from '@/components/header/CreateButton'
import PennStateLogoImage from '@/components/icons/png/PennStateLogo.png'
import { Header, StaticSideBar, styles } from '@/components/shared'
import { PATHS, ROLES, STYLES } from '@/constants'
import { ANNOUNCEMENTS_PATH } from '@/features/announcements/constants'
import { DOCUMENTS_PATH } from '@/features/documents/constants'
import { EVENT_PATHS } from '@/features/events/constants'
import { FORM_PATHS } from '@/features/formSubmissions/constants'
import { ORGANIZATION_PATHS } from '@/features/organizations/constants'
import { RESOURCES_PATH } from '@/features/resources/constants'
import { USERS_PATHS } from '@/features/users/constants'
import { getUserRole, selectCurrentUserCampus, signOutRequest } from '@/store/auth'
import { rootStyles } from '@/styles'
import { getAllAuthorizedRoles, isAdmin, USER_ROLE_NAMES } from '@/utils/authHandlers'
import { CREATE_ACTION_PATHS } from '@/utils/quickActions'
import { submitFeedback } from '@/utils/services'
import { getFormattedCurrentPath } from '@/utils/urlHandlers'
import {
	DateRangeOutlined,
	DescriptionOutlined,
	EmojiObjectsOutlined,
	FileCopyOutlined,
	NotificationImportantOutlined,
	PeopleOutlineOutlined,
	PersonOutlined,
} from '@mui/icons-material'
import { Theme, Typography } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import { specialColors } from '@navengage/sen-shared-assets'

import ProfileExpandButton from './header/ProfileExpandButton'
import HomeFilledIcon from './icons/HomeFilledIcon'

import { ExportListTracker } from '@/features/exportList/ExportListTracker'
import { NavigationBarNotificationsButton } from '@/features/notifications/components/NavigationBarNotificationsButton'
import { SETTINGS_PATHS } from '@/features/settings/constants'
import { SIDEBAR_SIZES } from '@/styles/sidebar'
import Notifier from './Notifier'
import ErrorBoundary from './error/ErrorBoundary'

type StyleProps = {
	isGreyColor: boolean
}

const useStyles = makeStyles<Theme, StyleProps>((theme) => ({
	...rootStyles,
	root: ({ isGreyColor }: StyleProps) => ({
		marginTop: `${STYLES.MAIN_HEADER_HEIGHT}vh`,
		width: '100%',
		height: 'max-content',
		display: 'flex',
		flexDirection: 'column',
		justifyContent: 'space-between',
		backgroundColor: isGreyColor ? theme.colors.grey[100] : theme.colors.white[500],
	}),
	container: {
		minHeight: `calc(100vh - ${STYLES.MAIN_HEADER_HEIGHT}vh - ${STYLES.LANDING_FOOTER_HEIGHT}px - ${theme.spacing(2)})`,
		backgroundColor: 'inherit',

		display: 'flex',
		flexDirection: 'row',
		padding: `0 ${theme.spacing(2)} ${theme.spacing(2)} ${theme.spacing(2)}`,
		zIndex: 1,
	},
	profileTabIcon: {
		width: 30,
		height: 30,
	},
	notificationsPopper: {
		marginTop: 5,
		padding: '10px 20px',
		minWidth: '10vw',
		boxShadow: 'none',
		border: `solid 1px ${theme.colors.grey[200]}`,
		borderRadius: 8,
	},
	notificationsPopperTitle: {
		fontWeight: 700,
	},
	messagesIcon: {
		position: 'relative',
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
	},
	messageNotificationDot: {
		width: 10,
		height: 10,
		backgroundColor: theme.colors.red[500],
		border: `solid 1px ${theme.colors.white[500]}`,
		borderRadius: '50%',
		position: 'absolute',
		top: 4,
		right: -8,
	},
	icon: {
		height: 30,
		width: 30,
		color: theme.colors.Primary[700],
	},
	headerContent: {
		width: styles.FILL_AVAILABLE_WIDTH,
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'space-between',
		paddingRight: 10,
		minHeight: 80,
	},
	headerInfo: {
		borderLeft: `solid 1px ${theme.colors.grey[500]}`,
		paddingLeft: 10,
	},
	headerInfoTitle: {
		fontWeight: 900,
		color: theme.colors.Primary[800],
	},
	headerInfoSubTitle: {
		fontWeight: 800,
		color: theme.colors.Primary[700],
		textTransform: 'uppercase',
	},
	sideBarContainer: {
		minWidth: 226,
		width: `${SIDEBAR_SIZES.regular}%`,
		[theme.breakpoints.down('md')]: {
			width: `${SIDEBAR_SIZES.md}%`,
		},
	},
	sideBar: {
		backgroundColor: specialColors.transparent,
		margin: 0,
	},
	studentDashboard: {
		backgroundColor: theme.colors.grey[100],
	},
}))

const hideSidebar = [SETTINGS_PATHS.ROOT]

const AUTHORIZED_ROLES = getAllAuthorizedRoles()

const MainLayout = ({ children }: any) => {
	const history = useHistory()
	const { push } = history
	const dispatch = useDispatch()
	const currentUserRole = useSelector(getUserRole)
	const campus = useSelector(selectCurrentUserCampus)
	const currentPathname = getFormattedCurrentPath(history.location.pathname)
	const [hide, setHide] = useState(false)

	const isStudentDashboard = !isAdmin(currentUserRole) && currentPathname === PATHS.APP.HOME
	const classes = useStyles({ isGreyColor: isStudentDashboard } as StyleProps)

	const handleClickLogo = useCallback(() => {
		push(PATHS.APP.HOME)
	}, [push])

	const handleClickNavigationButton = useCallback((newPath: string) => push(newPath), [push])

	const navigation = useMemo(
		() =>
			[
				{
					label: 'Home',
					icon: <HomeFilledIcon className={classes.icon} />,
					path: PATHS.APP.HOME,
					roles: AUTHORIZED_ROLES,
				},
				{
					label: 'Organizations',
					icon: <PeopleOutlineOutlined className={classes.icon} />,
					path: ORGANIZATION_PATHS.ROOT,
					roles: [ROLES.CAMPUS_ADMIN, ROLES.STUDENT_ADMIN],
				},
				{
					label: 'Events',
					icon: <DateRangeOutlined className={classes.icon} />,
					path: EVENT_PATHS.ROOT,
					roles: AUTHORIZED_ROLES,
				},
				{
					label: 'Users',
					icon: <PersonOutlined className={classes.icon} />,
					path: USERS_PATHS.ROOT,
					roles: [ROLES.CAMPUS_ADMIN],
				},
				{
					label: 'Forms',
					icon: <DescriptionOutlined className={classes.icon} />,
					path: FORM_PATHS.ROOT,
					roles: AUTHORIZED_ROLES,
				},
				{
					label: 'Documents',
					icon: <FileCopyOutlined className={classes.icon} />,
					path: DOCUMENTS_PATH.ROOT,
					roles: [ROLES.CAMPUS_ADMIN, ROLES.STUDENT_ADMIN],
				},
				{
					label: 'Announcements',
					icon: <NotificationImportantOutlined className={classes.icon} />,
					path: ANNOUNCEMENTS_PATH.ROOT,
					roles: [ROLES.CAMPUS_ADMIN, ROLES.STUDENT_ADMIN],
				},
				{
					label: 'Resources',
					icon: <EmojiObjectsOutlined className={classes.icon} />,
					path: RESOURCES_PATH.ROOT,
					roles: [ROLES.CAMPUS_ADMIN, ROLES.STUDENT_ADMIN],
				},
			].filter((nav) => (nav.roles && nav.roles.includes(currentUserRole)) || !nav.roles),
		[classes.icon, currentUserRole],
	)

	const profileOptions = useMemo(
		() => [
			// {
			// 	label: 'Switch to Student View',
			// 	onClick: switchToStudentView,
			// },
			{
				label: 'Help & Feedback',
				onClick: submitFeedback,
			},
			{
				label: 'Settings',
				onClick: () => {
					push(SETTINGS_PATHS.NOTIFICATIONS)
				},
			},
			{
				label: 'Sign Out',
				onClick: () => dispatch(signOutRequest()),
			},
		],
		[dispatch, push],
	)

	useEffect(() => {
		if (hideSidebar.some((path) => currentPathname.includes(path))) {
			setHide(true)
		} else {
			setHide(false)
		}
	}, [currentPathname])

	return (
		<>
			<main className={classes.root}>
				<Notifier />
				<Header logoSrc={PennStateLogoImage} onClickLogo={handleClickLogo}>
					<div className={classes.headerContent}>
						<div className={classes.headerInfo}>
							<Typography className={classes.headerInfoTitle}>ORG MANAGEMENT</Typography>
							<Typography className={classes.headerInfoSubTitle} variant="subtitle2">
								{USER_ROLE_NAMES[currentUserRole]} {campus ? ` | ${campus.name}` : ''}
							</Typography>
						</div>
						<div className={classes.flexCenteredItems}>
							<NavigationBarNotificationsButton />
							{isAdmin(currentUserRole) && <CreateButton actionList={CREATE_ACTION_PATHS} />}
							<ProfileExpandButton options={profileOptions} />
						</div>
					</div>
				</Header>

				<div className={classes.container}>
					{!hide && (
						<div className={classes.sideBarContainer}>
							<StaticSideBar
								classnames={{
									root: classes.sideBar,
								}}
								onClickNavigation={handleClickNavigationButton}
								navigation={navigation}
							/>
						</div>
					)}
					<ErrorBoundary key={currentPathname}>{children}</ErrorBoundary>
				</div>

				<ExportListTracker />
				<Footer inner />
			</main>
		</>
	)
}

export default MainLayout
