import React from 'react'
import { Link } from 'react-router-dom'

import { ORGANIZATION_PATHS } from '@/features/organizations/constants'
import { Typography } from '@mui/material'
import { makeStyles } from '@mui/styles'

import StyledLinksList from './StyledLinksList'
import { isArray } from 'lodash'

const useStyles = makeStyles((theme) => ({
	section: {
		backgroundColor: theme.colors.white[500],
		padding: `${theme.spacing(5)} ${theme.spacing(7)} ${theme.spacing(6.5)}`,
		borderRadius: 7,
	},
	sectionDescription: {
		marginTop: theme.spacing(1.75),
		color: theme.colors.grey[500],
	},
	sectionName: {
		fontSize: 25,
		lineHeight: '34px',
		fontWeight: theme.typography.fontWeightBold,
	},
	thumbnail: {
		height: theme.spacing(11.25),
		borderRadius: 10,
		marginBottom: theme.spacing(4.25),
	},
	orgName: {
		fontWeight: theme.typography.fontWeightMedium,
		marginBottom: theme.spacing(5.75),
		textAlign: 'center',
	},
	memberType: {
		fontSize: 15,
		lineHeight: '20px',
		color: theme.colors.grey[500],
	},
}))

export type IMyOrganizationsProps = {
	orgList: any
}

const MyOrganizations: React.FC<IMyOrganizationsProps> = ({ orgList }) => {
	const classes = useStyles()
	if (!isArray(orgList)) {
		return null
	}
	return (
		<div className={classes.section}>
			<Typography className={classes.sectionName}>My Organizations</Typography>
			<Typography className={classes.sectionDescription}>Organizations that you’re a Super Admin or Admin of will appear here.</Typography>
			{/* {orgList ?
				(<StyledLinksList>
					{(orgList || []).map((org) => (
						<Link key={org.id} to={ORGANIZATION_PATHS.SINGLE(org.id)}>
							<img className={classes.thumbnail} src={org.photoUrl} loading="lazy" alt={org.name} />
							<Typography className={classes.orgName}>{org.name}</Typography>
							<Typography className={classes.memberType}>{org.memberType}</Typography>
						</Link>
					))}
				</StyledLinksList>) : null} */}
		</div>
	)
}

export default MyOrganizations
