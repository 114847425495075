import React from 'react'
import makeStyles from '@mui/styles/makeStyles'
import { Tabs as MaterialTabs } from '@mui/material'
import classNames from 'classnames'

const useStyles = makeStyles((theme) => ({
	tabs: {
		height: '100%',
	},
	hideIndicator: {
		backgroundColor: theme.colors.white[500],
	},
}))

interface TabsProps {
	classnames?: {
		root?: string
		flexContainer?: string
		indicator?: string
	}
	tabsRenderer: (props: { hideIndicator: boolean; centered: boolean; activeTabIndex: number }) => React.ReactNode
	hideIndicator?: boolean
	activeTabIndex?: number
	centered?: boolean
}

const Tabs = ({
	classnames = {
		root: '',
		flexContainer: '',
		indicator: '',
	},
	tabsRenderer,
	hideIndicator = false,
	activeTabIndex = 0,
	centered = true,
}: TabsProps) => {
	const classes = useStyles({ centered })

	return (
		<MaterialTabs
			classes={{
				root: classNames(classnames.root, classes.tabs),
				flexContainer: classNames(classnames.flexContainer, classes.tabs),
				indicator: classNames(classnames.indicator, `${hideIndicator ? classes.hideIndicator : ''}`),
			}}
			indicatorColor="primary"
			centered={centered}
			value={activeTabIndex}
		>
			{tabsRenderer({
				hideIndicator,
				centered,
				activeTabIndex,
			})}
		</MaterialTabs>
	)
}

export default Tabs
