import { Typography } from '@mui/material'
import { makeStyles } from '@mui/styles'

import { baseStyles } from '../baseStyles'
import { roleQuestions } from './questions'

const useStyles = makeStyles(() => ({
	...baseStyles,
}))

export const RolesSection = (props: { stateControl }) => {
	const classes = useStyles()

	return (
		<section className={classes.section}>
			<div className={classes.sectionHeader}>
				<Typography className={classes.sectionHeaderTitle} variant="h2">
					Roles
				</Typography>
				<p className={classes.sectionHeaderSubtitle}>
					Roles let Campus Admins know which individuals in an organization should receive specific information, regardless of the title
					they hold. Roles are not public on the Engagement App, except for the Primary Contact for the Public. Roles can be edited by
					Student Admins and Campus Admins. An individual can hold multiple roles if applicable.
				</p>
			</div>
			{roleQuestions.map(({ label, description, isRequired, Input }) => {
				return (
					<div key={label} className={classes.genericQuestion}>
						<h3 className={classes.questionLabel}>
							{label}
							{isRequired && <span className={classes.questionRequiredIndicator}>*</span>}
						</h3>
						{description && <p className={classes.questionDescription}>{description}</p>}

						<Input {...props} />
					</div>
				)
			})}
		</section>
	)
}
