import { useCallback, useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'

import { getAnnouncementsRequest } from '@/features/announcements/announcementsSlice'
import { ANNOUNCEMENTS_PATH } from '@/features/announcements/constants'
import { AppDispatch } from '@/store'
import { PostDto } from '@/types'
import { makeStyles } from '@mui/styles'

import Posts from './Posts'

export type IAnnouncementsProps = {}

const useStyles = makeStyles((theme) => ({
	root: {
		padding: `${theme.spacing(3)} ${theme.spacing(2.5)} ${theme.spacing(5)} ${theme.spacing(5)}`,
		borderRadius: 7,
		backgroundColor: theme.colors.white[500],
		marginBottom: 46,
		maxHeight: 450,
		display: 'flex',
		flexDirection: 'column',
	},
	post: {
		border: `1px solid ${theme.colors.Neutral[200]}`,
		borderRadius: 5,
		padding: `${theme.spacing(2.5)} ${theme.spacing(3.375)} ${theme.spacing(2)}`,
		marginBottom: theme.spacing(1),
	},
}))

const Announcements = () => {
	const classes = useStyles()
	const dispatch = useDispatch<AppDispatch>()
	const [isLoading, setIsLoading] = useState<boolean>(true)
	const [total, setTotal] = useState<number>(0)
	const [announcements, setAnnouncements] = useState<Array<PostDto>>([])
	const initResources = useCallback(async () => {
		setIsLoading(true)
		const data = await dispatch(getAnnouncementsRequest({ page: 1 })).unwrap()
		setAnnouncements(data.result)
		setTotal(data.total)
		setIsLoading(false)
	}, [dispatch])
	useEffect(() => {
		initResources()
	}, [initResources])
	return (
		<div className={classes.root}>
			<Posts
				postClass={classes.post}
				data={announcements}
				loading={isLoading}
				title="Announcements"
				seeAllLink={total > announcements.length ? ANNOUNCEMENTS_PATH.ROOT : ''}
			/>
		</div>
	)
}

export default Announcements
