import React from 'react'

import { ActivitySection } from './admin/activitySection'
import { GreetingSection } from './admin/greetingSection'
import { MetricsSection } from './admin/metricsSection'

// @TODO: correct any type, forced to put any here because it was put any elsewhere
export type IAdminDashletsProps = {
	stats: any
}

const AdminDashlets: React.FC<IAdminDashletsProps> = ({ stats }) => {
	return (
		<>
			<GreetingSection />
			<ActivitySection {...{ stats: stats.activity }} />
			<MetricsSection {...{ stats: stats.metrics }} />
		</>
	)
}

export default AdminDashlets
