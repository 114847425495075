import classNames from 'classnames'
import React, { useCallback, useEffect, useState } from 'react'
import Cropper from 'react-easy-crop'
import { Point } from 'react-easy-crop/types'

import { Modal, Slider } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'

import getCroppedImg from '../../utils/cropImage'
import { ColorButton, OutlinedButton } from '../Buttons'

const useStyles = makeStyles((theme) => ({
	modal: {
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
	},
	modalCard: {
		width: '43vw',
		padding: '3vh 2vw',
		backgroundColor: theme.colors.white[500],
		borderRadius: 5,
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
		justifyContent: 'center',
		[theme.breakpoints.down('md')]: {
			width: '93vw',
		},
	},
	modalButton: {
		padding: '10px 20px',
		width: '15vw',
		marginBottom: '1vh',
		minWidth: 130,
	},
	outlinedButton: {
		color: theme.colors.Primary[700],
	},
	cropContainer: {
		position: 'relative',
		width: '100%',
		height: 300,
		[theme.breakpoints.up('sm')]: {
			height: 500,
		},
	},
	slider: {
		padding: '22px 0px',
		marginLeft: 32,
		[theme.breakpoints.up('sm')]: {
			flexDirection: 'row',
			alignItems: 'center',
			margin: '0 16px',
		},
	},
	controls: {
		margin: '1vh 0',
		width: '70%',
	},
	buttonsContainer: {
		width: '90%',
		display: 'flex',
		flexDirection: 'row',
		justifyContent: 'space-evenly',
	},
	progress: {
		width: '20px !important',
		height: '20px !important',
		color: theme.colors.white[500],
	},
}))

interface ImageCropperModalProps {
	isOpen: boolean
	onClose: () => void
	imageUrl?: string
	onChange: (imageSrc: string) => void
	aspect?: number
	onLoading?: (isLoading: boolean) => void
	classnames?: {
		cropArea?: string
	}
}

const ImageCropperModal = ({
	isOpen,
	onClose,
	imageUrl,
	onChange,
	aspect = 4 / 3,
	onLoading = () => {},
	classnames = {
		cropArea: '',
	},
}: ImageCropperModalProps) => {
	const [imageToCrop, setImageToCrop] = useState('')
	const [crop, setCrop] = useState<Point>({ x: 0, y: 0 })
	const [zoom, setZoom] = useState(1)
	const [croppedAreaPixels, setCroppedAreaPixels] = useState(null)

	const classes = useStyles()

	const handleCloseModal = useCallback(() => {
		setZoom(1)
		setImageToCrop('')
		setCroppedAreaPixels(null)
		onLoading(false)
		onClose()
	}, [onClose, onLoading])

	const onCropComplete = useCallback((croppedArea, croppedAreaPixels) => {
		setCroppedAreaPixels(croppedAreaPixels)
	}, [])

	const acceptCropperChanges = useCallback(async () => {
		try {
			onLoading(true)
			onClose()

			const croppedImage = await getCroppedImg(imageToCrop, croppedAreaPixels, 0)
			onChange(croppedImage)
			handleCloseModal()
		} catch (e: any) {
			console.error(e)
		}
	}, [croppedAreaPixels, handleCloseModal, imageToCrop, onChange, onClose, onLoading])

	useEffect(() => {
		if (isOpen && imageUrl) {
			setImageToCrop(imageUrl)
			onLoading(false)
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [isOpen])

	return (
		<Modal open={isOpen} onClose={handleCloseModal} className={classes.modal}>
			<div className={classes.modalCard}>
				<div className={classes.cropContainer}>
					<Cropper
						classes={{
							cropAreaClassName: classnames.cropArea,
						}}
						image={imageToCrop}
						crop={crop}
						zoom={zoom}
						aspect={aspect}
						onCropChange={setCrop}
						onCropComplete={onCropComplete}
						onZoomChange={setZoom}
					/>
				</div>
				<div className={classes.controls}>
					<Slider
						value={zoom}
						min={1}
						max={3}
						step={0.1}
						aria-labelledby="Zoom"
						onChange={(e, zoom) => setZoom(Number(zoom))}
						classes={{ root: classes.slider }}
					/>
				</div>
				<div className={classes.buttonsContainer}>
					<ColorButton className={classes.modalButton} onClick={acceptCropperChanges}>
						Ok
					</ColorButton>
					<OutlinedButton className={classNames(classes.outlinedButton, classes.modalButton)} onClick={handleCloseModal}>
						Cancel
					</OutlinedButton>
				</div>
			</div>
		</Modal>
	)
}

export default ImageCropperModal
