import { DateTime } from 'luxon'

export function formatDate(date: string | Date, format = DateTime.DATE_SHORT) {
	return DateTime.fromJSDate(new Date(date)).toLocaleString(format)
}

export const getTimeOptionsArray = (date: DateTime | null, minDate?: DateTime, maxDate?: DateTime, step: number = 15) => {
	const timesArray = []
	let startOfTheDay = date ? date.startOf('day') : DateTime.local().startOf('day')
	const endOfTheDay = date ? date.endOf('day') : DateTime.local().endOf('day')
	while (startOfTheDay <= endOfTheDay) {
		const time = startOfTheDay.toFormat('hh:mm a').toUpperCase()
		timesArray.push({
			label: time,
			value: time,
			disabled: isUpcomingDate(startOfTheDay, minDate) || isUpcomingDate(maxDate, startOfTheDay),
		})
		startOfTheDay = startOfTheDay.plus({ minutes: step })
	}
	return timesArray
}
export const getTimeOptionsList = (step = 15) => {
	const timesArray = []
	let startOfTheDay = DateTime.local().startOf('day')
	const endOfTheDay = DateTime.local().endOf('day')
	while (startOfTheDay <= endOfTheDay) {
		const time = startOfTheDay.toFormat('hh:mm a').toUpperCase()
		timesArray.push({
			label: time,
			value: time,
		})
		startOfTheDay = startOfTheDay.plus({ minutes: step })
	}
	return timesArray
}
export const isUpcomingDate = (compare?: DateTime, date?: DateTime) => {
	if (compare && date) {
		return date > compare
	}
	return false
}
export const mergeDateTime = (date: Date, time: string) => {
	const formattedDate = DateTime.fromJSDate(date).toFormat('yyyy-MM-dd')
	const mergedDate = DateTime.fromFormat(`${formattedDate} ${time.toLocaleLowerCase()}`, 'yyyy-MM-dd hh:mm a')

	return mergedDate.toJSDate()
}

export const getFullDateFormatted = (dateStr: string) => {
	const date = DateTime.fromISO(dateStr).toLocal()
	return date.toFormat(`M/d/yyyy 'at' h:mm a`)
}

export const isSameDate = (dateStr1: string, dateStr2: string) => {
	const date1 = DateTime.fromISO(dateStr1).startOf('day')
	const date2 = DateTime.fromISO(dateStr2).startOf('day')
	return date1.toMillis() === date2.toMillis()
}
export const formatEventDate = (dateStr: string, showHours: boolean = false) => {
	const date = DateTime.fromISO(dateStr).toLocal()
	const format = showHours ? `ccc, LLL d 'at' h:mm a` : `ccc, LLL d`
	return date.toFormat(format)
}
export const getTimeFormatted = (dateStr: string) => {
	const date = DateTime.fromISO(dateStr)
	return date.toFormat('h:mm a')
}

export const getFormatMediumDate = (dateStr: string) => {
	const date = DateTime.fromISO(dateStr).toLocal()
	return date.toLocaleString(DateTime.DATE_MED)
}

export const formatTime = (dateStr: string) => {
	const date = DateTime.fromISO(dateStr).toLocal()
	return date.toFormat('h:mm a')
}

export const getTimeAgo = (
	date,
): {
	seconds: number
	minutes: number
	hours: number
	days: number
	weeks: number
	months: number
	years: number
} => {
	const now = DateTime.local()
	const past = DateTime.fromJSDate(date)
	return now.diff(past, ['seconds', 'minutes', 'hours', 'days', 'weeks', 'months', 'years'])
}

export const getRoundedTime = (date: string, roundMinutes = 15) => {
	const start = DateTime.fromISO(date).toLocal()
	const round = roundMinutes - (start.minute % roundMinutes)

	return start.plus({ minutes: round }).toFormat('hh:mm a')
}
