import classNames from 'classnames'
import { push } from 'connected-react-router'
import { useEffect, useMemo, useState } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { useDispatch, useSelector } from 'react-redux'
import * as yup from 'yup'

import CreateFormHeader from '@/components/CreateFormHeader'
import { DatePickerSelect } from '@/components/DateTimePicker/DatePickerSelect'
import FieldErrorMessage from '@/components/FieldErrorMessage'
import { ColorButton, CommonSelect, ImageUploadField, Label, styles, TextField, Toggle } from '@/components/shared'
import { ERRORS, STYLES } from '@/constants'
import useImageUploadError from '@/hooks/useImageUploadError'
import { showExitConfirmationModal } from '@/store/app'
import { getCampuses } from '@/store/auth'
import { getTimeOptionsList } from '@/utils/dateTime'
import { yupResolver } from '@hookform/resolvers/yup'
import { DateRangeOutlined } from '@mui/icons-material'
import { FormControlLabel, Radio, RadioGroup, Typography } from '@mui/material'
import { makeStyles } from '@mui/styles'
import { getHoverColor } from '@navengage/sen-shared-assets'

import {
	CreateEventFormType,
	EventLocationTypes,
	EventRepeatingOptions,
	EVENT_PATHS,
	FORM,
	MAX_SHIFTS,
	MAX_VOLUNTEERS_PER_SHIFT,
} from '../constants'
import { getCategories } from '../eventsSlice'
import Card from './Card'
import ShiftsCreationForm from './ShiftsCreationForm'

const MAX_EVENT_REPEAT_COUNT = 5

const useStyles = makeStyles((theme) => ({
	pageRoot: {
		width: '100vw',
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
		marginBottom: `${STYLES.LANDING_FOOTER_HEIGHT}vh`,
	},
	card: {
		width: '100%',
	},
	title: {
		fontWeight: theme.typography.fontWeightBold,
	},
	closeButton: {
		backgroundColor: theme.colors.red[200],
		color: theme.colors.red[500],
		'& >span': {
			margin: '3px 10px',
		},
		'&:hover': {
			backgroundColor: getHoverColor(theme.colors.red, 200),
			color: theme.colors.red[500],
		},
	},
	calendarIcon: {
		fontSize: 43,
	},
	headerBlock: {
		display: 'flex',
		flexDirection: 'row',
		width: '100%',
		alignItems: 'center',
		justifyContent: 'space-between',
		marginBottom: '2vh',
	},
	headerWithIcon: {
		display: 'flex',
		flexDirection: 'row',
		alignItems: 'center',
	},

	formInput: {
		display: 'flex',
		width: '60%',
		[theme.breakpoints.down('md')]: {
			width: '90%',
		},
	},
	formDatePickers: {
		display: 'flex',
		flexDirection: 'row',
		alignItems: 'flex-end',
	},
	formSelect: {
		width: '40%',

		[theme.breakpoints.down('md')]: {
			width: '70%',
		},
	},
	timeSelect: {
		width: styles.FILL_AVAILABLE_WIDTH,
		[theme.breakpoints.down('md')]: {
			width: '70%',
		},
	},
	dateTimeLabel: {
		width: '40%',
		marginRight: '5%',
	},
	selectWithTags: {
		flexDirection: 'column',
	},
	formFooter: {
		width: '100%',
		display: 'flex',
		flexDirection: 'column',
		justifyContent: 'center',
		alignItems: 'center',
		margin: '4vh 0 1vh 0',
	},
	submitEventButton: {
		padding: '10px 20px',
		width: '15vw',
		minWidth: 200,
	},
	toggleLabel: {
		margin: '10px 0',
		color: theme.colors.black[500],
		'&>span:last-child': {
			...theme.typography.body1,
		},
	},
	label: {
		margin: `${theme.spacing(4.5)} 0 ${theme.spacing(1.25)} 0`,
	},
	radioGroup: {
		display: 'flex',
		flexDirection: 'row',
	},
}))

const schema = yup.object().shape({
	[FORM.eventName]: yup.string().required(),
	[FORM.startDate]: yup.date().max(yup.ref(FORM.endDate), "Start date can't be after end date").required(),
	[FORM.startTime]: yup.string().required(),
	[FORM.endDate]: yup.date().min(yup.ref(FORM.startDate), "End date can't be before start date").required(),
	[FORM.repeatCount]: yup.number().when(FORM.repeating, {
		is: EventRepeatingOptions.DISABLED,
		then: yup.number().nullable(),
		otherwise: yup.number().min(1).max(MAX_EVENT_REPEAT_COUNT).required(),
	}),
	[FORM.endTime]: yup.string().required(),
	[FORM.description]: yup.string().required(),
	[FORM.campus]: yup.array().min(1).required(),
	[FORM.locationName]: yup.string().required(),
	[FORM.repeating]: yup.string().oneOf(Object.keys(EventRepeatingOptions).map((key) => EventRepeatingOptions[key])),
	[FORM.locationType]: yup.string().oneOf(Object.keys(EventLocationTypes).map((key) => EventLocationTypes[key])),
	[FORM.virtualLink]: yup.string().nullable().when(FORM.locationType, {
		is: EventLocationTypes.VIRTUAL,
		then: yup.string().required(),
	}),
	[FORM.streetAddress]: yup.string().nullable(),
	[FORM.hostOrganization]: yup.array(),
	[FORM.externalUrl]: yup.string().nullable(),
	[FORM.categories]: yup.array().min(1).required(),
	[FORM.contactName]: yup.string().required(),
	[FORM.contactEmail]: yup.string().email().required(),
	[FORM.photoUrl]: yup.string().required(),
	[FORM.addingShifts]: yup.boolean().nullable(),
	[FORM.shiftsNumber]: yup.number().when(FORM.addingShifts, {
		is: true,
		then: yup
			.number()
			.typeError(ERRORS.requiredField)
			.moreThan(0, ERRORS.moreThan(0))
			.lessThan(MAX_SHIFTS, ERRORS.lessThan(MAX_SHIFTS))
			.required(ERRORS.requiredField),
	}),
	[FORM.exclude]: yup.boolean().default(false),
	[FORM.volunteersPerShift]: yup.number().when(FORM.addingShifts, {
		is: true,
		then: yup
			.number()
			.typeError(ERRORS.requiredField)
			.moreThan(0, ERRORS.moreThan(0))
			.lessThan(MAX_VOLUNTEERS_PER_SHIFT, ERRORS.lessThan(MAX_VOLUNTEERS_PER_SHIFT))
			.required(ERRORS.requiredField),
	}),
	[FORM.shifts]: yup
		.array()
		.of(
			yup.object().shape({
				start: yup.string(),
				end: yup.string(),
			}),
		)
		.when(FORM.addingShifts, {
			is: true,
			then: yup
				.array()
				.of(
					yup.object().shape({
						start: yup.string(),
						end: yup.string(),
					}),
				)
				.required(),
		}),
})

interface EventFormProps {
	onSubmit: (data: any) => void
	data: CreateEventFormType
	isEdit?: boolean
	cancelRedirectPath?: string
	organizationsAsOptions?: Array<{ label: string; value: string }>
}

const EventForm = ({
	onSubmit,
	data,
	isEdit = false,
	cancelRedirectPath = EVENT_PATHS.LIST,
	organizationsAsOptions = [],
}: EventFormProps) => {
	const classes = useStyles()
	const dispatch = useDispatch()

	const {
		control,
		handleSubmit,
		watch,
		setValue,
		formState: { errors, isDirty },
	} = useForm<any>({
		resolver: yupResolver(schema),
		mode: 'onSubmit',
		reValidateMode: 'onChange',
		criteriaMode: 'firstError',
		shouldFocusError: true,
		defaultValues: data,
	})

	const [isUpdating, setIsUpdating] = useState(false)

	const validateImageSize = useImageUploadError()

	const categories = useSelector(getCategories)

	const campuses = useSelector(getCampuses)

	const handleOpenModal = () => {
		if (isDirty) {
			dispatch(
				showExitConfirmationModal({
					isOpen: true,
					redirectPath: cancelRedirectPath,
				}),
			)
		} else {
			dispatch(push(cancelRedirectPath))
		}
	}

	const selectedCategories = watch(FORM.categories)
	const locationType = watch(FORM.locationType)
	const repeating = watch(FORM.repeating)
	const isVirtual = locationType === EventLocationTypes.VIRTUAL
	// @TODO: handle date validation for min and max dates
	const selectedStartDate = watch(FORM.startDate)
	const selectedEndDate = watch(FORM.endDate)
	const shiftsNumber = watch(FORM.shiftsNumber)
	const volunteersPerShift = watch(FORM.volunteersPerShift)
	const addingShifts = watch(FORM.addingShifts)

	const volunteerCategory = useMemo(() => categories.find((c) => c.label.toLowerCase().includes('volunteer')), [categories])
	const timeOptions = useMemo(() => getTimeOptionsList(), [])
	const canAddShifts = useMemo(
		() => Array.isArray(selectedCategories) && selectedCategories.some((c: any) => c === volunteerCategory?.value),
		[selectedCategories, volunteerCategory?.value],
	)

	const formHasErrors = !!Object.keys(errors).length

	const disableEditShifts = isEdit && data.categories?.includes(volunteerCategory?.value)

	const availableCategories = useMemo(() => {
		const shouldDisableVolunteerCategory = isEdit && data.categories?.includes(volunteerCategory?.value)

		if (!shouldDisableVolunteerCategory) return categories

		return categories.map((category) => (category.value === volunteerCategory?.value ? { ...category, disabled: true } : category))
	}, [categories, data.categories, isEdit, volunteerCategory?.value])
	const repeatingOptions = useMemo(
		() => [
			{
				label: 'Does not repeat',
				value: EventRepeatingOptions.DISABLED,
			},
			{
				label: 'Daily',
				value: EventRepeatingOptions.DAILY,
			},
			{
				label: 'Weekly',
				value: EventRepeatingOptions.WEEKLY,
			},
			{
				label: 'Monthly',
				value: EventRepeatingOptions.MONTHLY,
			},
		],
		[],
	)
	const submitAndSetUpdating = (data: any) => {
		setIsUpdating(true)
		onSubmit(data)
	}

	useEffect(() => {
		if (!canAddShifts) setValue(FORM.addingShifts, canAddShifts)
	}, [canAddShifts, setValue])

	useEffect(() => {
		if (!addingShifts) {
			setValue(FORM.shiftsNumber, 0)
			setValue(FORM.volunteersPerShift, 0)
			setValue(FORM.shifts, [])
		}
	}, [addingShifts, setValue])

	const submitButtonLabel = isEdit ? 'Save' : 'Create'
	const title = isEdit ? 'Edit Event' : 'Create Event'
	const disableSubmitButton = isUpdating || (isEdit && !isDirty)
	const allowedRepeatCounts = useMemo(
		() =>
			[...Array(MAX_EVENT_REPEAT_COUNT)].map((_, index) => ({
				label: `Repeats ${index + 1} times`,
				value: index + 1,
			})),
		[],
	)

	return (
		<form onSubmit={handleSubmit(submitAndSetUpdating)}>
			<Card className={classes.card}>
				<CreateFormHeader
					title={title}
					icon={<DateRangeOutlined />}
					onCancel={handleOpenModal}
					subHeader={'Once your event is submitted, it will post on the Engagement App.'}
				/>

				<Controller
					name={FORM.eventName}
					control={control}
					render={({ field: { onChange, value } }) => {
						return (
							<Label classnames={{ label: classes.label }} title="Name of Event" required>
								<TextField
									className={classes.formInput}
									placeholder="Event Name"
									error={!!errors[FORM.eventName]}
									disabled={isUpdating}
									variant="standard"
									value={value}
									onChange={onChange}
								/>
								<FieldErrorMessage hasError={!!errors[FORM.eventName]} message={ERRORS.requiredField} />
							</Label>
						)
					}}
				/>
				<div className={classes.formInput}>
					<Controller
						name={FORM.startDate}
						control={control}
						render={({ field: { value, onChange } }) => (
							<Label classnames={{ root: classes.dateTimeLabel, label: classes.label }} title="Start Date" required>
								{
									<DatePickerSelect
										maxDate={selectedEndDate}
										value={value}
										onChange={onChange}
										disablePast
										placeholder="Select Date"
										disabled={isUpdating}
									/>
								}
								<FieldErrorMessage
									hasError={!!errors[FORM.startDate]}
									message={errors[FORM.startDate] ? errors[FORM.startDate].message : ERRORS.requiredField}
								/>
							</Label>
						)}
					/>
					<Controller
						name={FORM.startTime}
						control={control}
						defaultValue=""
						render={({ field: { value, onChange } }) => (
							<Label classnames={{ root: classes.dateTimeLabel, label: classes.label }} title="Start Time" required>
								<CommonSelect
									classnames={{
										input: classNames(classes.timeSelect, classes.formSelect),
									}}
									value={value}
									onChange={onChange}
									options={timeOptions}
									placeholder="Start Time"
									disabled={isUpdating}
								/>
								<FieldErrorMessage hasError={!!errors[FORM.startTime]} message={ERRORS.requiredField} />
							</Label>
						)}
					/>
				</div>
				<div className={classes.formInput}>
					<Controller
						name={FORM.endDate}
						control={control}
						render={({ field: { value, onChange } }) => (
							<Label classnames={{ root: classes.dateTimeLabel, label: classes.label }} title="End Date" required>
								<DatePickerSelect
									minDate={selectedStartDate}
									value={value}
									onChange={onChange}
									disablePast
									placeholder="Select Date"
									disabled={isUpdating}
								/>
								<FieldErrorMessage
									hasError={!!errors[FORM.endDate]}
									message={errors[FORM.endDate] ? errors[FORM.endDate].message : ERRORS.requiredField}
								/>
							</Label>
						)}
					/>
					<Controller
						name={FORM.endTime}
						control={control}
						defaultValue=""
						render={({ field: { value, onChange } }) => (
							<Label classnames={{ root: classes.dateTimeLabel, label: classes.label }} title="End Time" required>
								<CommonSelect
									classnames={{
										input: classNames(classes.timeSelect, classes.formSelect),
									}}
									value={value}
									onChange={onChange}
									options={timeOptions}
									placeholder="End Time"
									disabled={isUpdating}
								/>
								<FieldErrorMessage hasError={!!errors[FORM.endTime]} message={ERRORS.requiredField} />
							</Label>
						)}
					/>
				</div>
				<Controller
					name={FORM.repeating}
					control={control}
					defaultValue={repeatingOptions[0].value}
					render={({ field: { value, onChange } }) => (
						<Label classnames={{ label: classes.label }} title="Is this a repeating event?">
							<CommonSelect
								value={value}
								onChange={onChange}
								classnames={{
									input: classes.formSelect,
								}}
								options={repeatingOptions}
								placeholder="Select repeating option"
								disabled={isUpdating}
							/>
						</Label>
					)}
				/>
				{repeating !== EventRepeatingOptions.DISABLED && (
					<div className={classes.formInput}>
						<Controller
							name={FORM.repeatCount}
							control={control}
							render={({ field: { value, onChange } }) => (
								<Label classnames={{ label: classes.label }} title="Repeat count?" required>
									<CommonSelect
										value={value}
										onChange={onChange}
										options={allowedRepeatCounts}
										placeholder="Select repeat count"
										disabled={isUpdating}
									/>
								</Label>
							)}
						/>
					</div>
				)}
				<Controller
					name={FORM.description}
					control={control}
					defaultValue=""
					render={({ field }) => (
						<Label classnames={{ label: classes.label }} title="Description" required>
							<TextField
								variant="standard"
								error={!!errors[FORM.description]}
								className={classes.formInput}
								placeholder="Tell the Penn State community about your event"
								multiline
								disabled={isUpdating}
								{...field}
							/>
							<FieldErrorMessage hasError={!!errors[FORM.description]} message={ERRORS.requiredField} />
						</Label>
					)}
				/>
				<Controller
					name={FORM.campus}
					control={control}
					defaultValue={[]}
					render={({ field: { value, onChange } }) => (
						<Label classnames={{ label: classes.label }} title="Campus" required>
							<CommonSelect
								value={value}
								onChange={onChange}
								classnames={{
									input: classes.formSelect,
								}}
								renderTags
								multiple={true}
								options={campuses}
								placeholder="Select campus"
								disabled={isUpdating}
							/>
							<FieldErrorMessage hasError={!!errors[FORM.campus]} message={ERRORS.requiredField} />
						</Label>
					)}
				/>
				<Controller
					name={FORM.locationType}
					control={control}
					defaultValue={data.locationType ?? EventLocationTypes.IN_PERSON}
					render={({ field: { value, onChange, name } }) => (
						<Label classnames={{ label: classes.label }} title="Location Name">
							<RadioGroup aria-labelledby="Location Type" name={name} value={value} onChange={onChange} className={classes.radioGroup}>
								<FormControlLabel value={EventLocationTypes.IN_PERSON} control={<Radio />} label={EventLocationTypes.IN_PERSON} />
								<FormControlLabel value={EventLocationTypes.VIRTUAL} control={<Radio />} label={EventLocationTypes.VIRTUAL} />
							</RadioGroup>
						</Label>
					)}
				/>
				<Controller
					name={FORM.locationName}
					control={control}
					defaultValue=""
					render={({ field }) => (
						<>
							<TextField
								variant="standard"
								error={!!errors[FORM.locationName]}
								{...field}
								className={classes.formInput}
								placeholder={isVirtual ? 'Ex. Zoom, Microsoft Teams, etc.' : 'Ex. Nittany Lion Building, Room. 30'}
								disabled={isUpdating}
							/>
							<FieldErrorMessage hasError={!!errors[FORM.locationName]} message={ERRORS.requiredField} />
						</>
					)}
				/>
				{!isVirtual && (
					<Controller
						name={FORM.streetAddress}
						control={control}
						render={({ field }) => (
							<Label classnames={{ label: classes.label }} title="Location Address" optional>
								<TextField
									variant="standard"
									error={!!errors[FORM.streetAddress]}
									className={classes.formInput}
									placeholder={'Ex. 123 Nittany Lion Road, State College, PA, 16801'}
									{...field}
									disabled={isUpdating}
								/>
								<FieldErrorMessage hasError={!!errors[FORM.streetAddress]} message={ERRORS.requiredField} />
							</Label>
						)}
					/>
				)}

				{isVirtual && (
					<Controller
						name={FORM.virtualLink}
						control={control}
						render={({ field }) => (
							<Label classnames={{ label: classes.label }} title="Virtual Link" required>
								<TextField
									variant="standard"
									error={!!errors[FORM.virtualLink]}
									className={classes.formInput}
									placeholder="Add link"
									{...field}
									disabled={isUpdating}
								/>
								<FieldErrorMessage hasError={!!errors[FORM.virtualLink]} message={ERRORS.requiredField} />
							</Label>
						)}
					/>
				)}
				<Controller
					name={FORM.externalUrl}
					control={control}
					defaultValue=""
					render={({ field }) => (
						<Label classnames={{ label: classes.label }} title="External Url" optional>
							<TextField
								variant="standard"
								error={!!errors[FORM.externalUrl]}
								{...field}
								className={classes.formInput}
								placeholder="Add external url"
								disabled={isUpdating}
							/>
							<FieldErrorMessage hasError={!!errors[FORM.externalUrl]} message={ERRORS.requiredField} />
						</Label>
					)}
				/>
				<Controller
					name={FORM.hostOrganization}
					defaultValue={[]}
					control={control}
					render={({ field: { value, onChange } }) => (
						<Label
							classnames={{ label: classes.label }}
							title="Host Organization"
							optional
							description={
								!!organizationsAsOptions.length
									? 'You must be an admin or super admin of an organization to create an event on behalf of that organization.'
									: 'If you would like to add an event on behalf of a student organization, you must be an admin or super admin of that organization.'
							}
						>
							{!!organizationsAsOptions.length && (
								<>
									<CommonSelect
										value={value}
										renderTags
										multiple
										onChange={onChange}
										classnames={{
											input: classes.formSelect,
										}}
										options={organizationsAsOptions}
										placeholder="Select Organization"
										disabled={isUpdating}
									/>
									<FieldErrorMessage hasError={!!errors[FORM.hostOrganization]} message={ERRORS.requiredField} />
								</>
							)}
						</Label>
					)}
				/>
				<Controller
					name={FORM.categories}
					control={control}
					defaultValue={[]}
					render={({ field: { value, onChange } }) => (
						<Label classnames={{ label: classes.label }} title="Categories" required description="You can select multiple categories.">
							<CommonSelect
								value={value}
								onChange={onChange}
								classnames={{
									input: classes.formSelect,
								}}
								options={availableCategories}
								placeholder="Select Categories"
								multiple
								renderTags
								disabled={isUpdating}
							/>
							<FieldErrorMessage hasError={!!errors[FORM.categories]} message={ERRORS.requiredField} />
						</Label>
					)}
				/>
				<Controller
					name={FORM.contactName}
					control={control}
					defaultValue=""
					render={({ field }) => (
						<Label classnames={{ label: classes.label }} title="Contact Name" required>
							<TextField
								variant="standard"
								error={!!errors[FORM.contactName]}
								{...field}
								className={classes.formInput}
								placeholder="First & Last Name"
								disabled={isUpdating}
							/>
							<FieldErrorMessage hasError={!!errors[FORM.contactName]} message={ERRORS.requiredField} />
						</Label>
					)}
				/>
				<Controller
					name={FORM.contactEmail}
					control={control}
					defaultValue=""
					render={({ field }) => (
						<Label classnames={{ label: classes.label }} title="Contact Email" required>
							<TextField
								variant="standard"
								error={!!errors[FORM.contactEmail]}
								{...field}
								className={classes.formInput}
								placeholder="Ex. ehp1855@psu.edu"
								disabled={isUpdating}
							/>
							<FieldErrorMessage hasError={!!errors[FORM.contactEmail]} message={ERRORS.requiredField} />
						</Label>
					)}
				/>
				<Controller
					name={FORM.photoUrl}
					control={control}
					defaultValue=""
					render={({ field: { onChange, value } }) => (
						<Label
							classnames={{ label: classes.label }}
							title="Upload a Photo"
							required
							description="Please be aware that your photo will be public."
						>
							<ImageUploadField
								maxWidth={400}
								onChange={onChange}
								value={value}
								disabled={isUpdating}
								validateImageSize={validateImageSize}
							/>
							<FieldErrorMessage hasError={!!errors[FORM.photoUrl]} message={ERRORS.requiredField} />
						</Label>
					)}
				/>
				<Controller
					name={FORM.exclude}
					control={control}
					defaultValue={false}
					render={({ field: { value, onChange } }) => {
						return (
							<Label
								classnames={{ label: classes.label }}
								title="Exclude from engagement app? "
								description={`This event will only be available in the Org Management System`}
							>
								<Toggle className={classes.toggleLabel} value={value} onChange={onChange} label="Exclude event" labelPosition="end" />
							</Label>
						)
					}}
				/>
				<Controller
					name={FORM.addingShifts}
					control={control}
					defaultValue={false}
					render={({ field: { value, onChange } }) => (
						<Label
							classnames={{ label: classes.label }}
							title="Adding Shifts? "
							description={`Shifts can be added to volunteer events.
          To make this a volunteer event, add “volunteer” as a category.
          You can't edit shifts after the event has been submitted.`}
						>
							<Toggle
								className={classes.toggleLabel}
								value={value}
								onChange={onChange}
								disabled={!canAddShifts || disableEditShifts || isUpdating}
								label="Add shifts to this volunteer event"
								labelPosition="end"
							/>
						</Label>
					)}
				/>
				{!addingShifts && (
					<div className={classes.formFooter}>
						<ColorButton className={classes.submitEventButton} type="submit" disabled={disableSubmitButton}>
							{submitButtonLabel}
						</ColorButton>
						<FieldErrorMessage hasError={formHasErrors} message={ERRORS.fieldsMissing} />
					</div>
				)}
			</Card>
			{addingShifts && (
				<Card className={classes.card}>
					<div className={classes.headerBlock}>
						<div className={classes.headerWithIcon}>
							<Typography component="span" className={classes.title} variant="h2">
								Volunteer Shifts
							</Typography>
						</div>
					</div>
					<Controller
						name={FORM.shiftsNumber}
						control={control}
						defaultValue={0}
						render={({ field }) => (
							<Label classnames={{ label: classes.label }} title="How many shifts do you have?" required>
								<TextField
									variant="standard"
									error={!!errors[FORM.shiftsNumber]}
									className={classes.formInput}
									type="number"
									placeholder="Set Number"
									disabled={disableEditShifts || isUpdating}
									{...field}
								/>
								<FieldErrorMessage hasError={!!errors[FORM.shiftsNumber]} message={errors[FORM.shiftsNumber]?.message} />
							</Label>
						)}
					/>
					<Controller
						name={FORM.volunteersPerShift}
						control={control}
						defaultValue={0}
						render={({ field }) => (
							<Label classnames={{ label: classes.label }} title="How many volunteers per shift?" required>
								<TextField
									variant="standard"
									error={!!errors[FORM.volunteersPerShift]}
									className={classes.formInput}
									type="number"
									placeholder="Set Number"
									disabled={disableEditShifts || isUpdating}
									{...field}
								/>
								<FieldErrorMessage hasError={!!errors[FORM.volunteersPerShift]} message={errors[FORM.volunteersPerShift]?.message} />
							</Label>
						)}
					/>
					{!!(
						shiftsNumber &&
						volunteersPerShift &&
						!errors[FORM.shiftsNumber] &&
						!errors[FORM.volunteersPerShift] &&
						volunteersPerShift > 0 &&
						shiftsNumber > 0 &&
						volunteersPerShift < MAX_VOLUNTEERS_PER_SHIFT &&
						shiftsNumber < MAX_SHIFTS
					) && (
						<Controller
							name={FORM.shifts}
							control={control}
							defaultValue={[]}
							render={({ field: { value, onChange } }) => (
								<Label classnames={{ label: classes.label }} title="Set shift times" required>
									<ShiftsCreationForm
										value={value}
										onChange={onChange}
										shiftsNumber={shiftsNumber}
										volunteersPerShift={volunteersPerShift}
										options={timeOptions}
										disabled={disableEditShifts || isUpdating}
									/>
									<FieldErrorMessage hasError={!!errors[FORM.shifts]} message={ERRORS.requiredField} />
								</Label>
							)}
						/>
					)}
					<div className={classes.formFooter}>
						<ColorButton className={classes.submitEventButton} type="submit" disabled={disableSubmitButton}>
							{submitButtonLabel}
						</ColorButton>
						<FieldErrorMessage hasError={formHasErrors} message={ERRORS.fieldsMissing} />
					</div>
				</Card>
			)}
		</form>
	)
}

export default EventForm
