import { DeprecatedThemeOptions } from '@mui/material'

import defaultPalette from './colors/palette'

// XD to em conversion:
// Calling 16 px = 1em

const themeConfig: DeprecatedThemeOptions = {
	palette: {
		...defaultPalette,
		primary: {
			main: defaultPalette.Primary[700],
		},
		info: {
			main: defaultPalette.Info[500],
		},
		warning: {
			main: defaultPalette.Warning[500],
		},
		error: {
			main: defaultPalette.Error[500],
		},
	},
	typography: {
		fontFamily: ['Open Sans'].join(','),
		fontWeightMedium: 600,

		body1: {
			'@media (max-width:1600px)': {
				fontSize: '16px',
				lineHeight: '20px',
			},
			'@media (min-width:1920px)': {
				fontSize: '18px',
				lineHeight: '24px',
			},
		},

		// Secondary (Small) (15)
		subtitle1: {
			'@media (max-width:1919px)': {
				fontSize: '0.9375em', // 15px
			},
			'@media (min-width:1920px)': {
				fontSize: '1.0625em', //17px
			},
		},

		// Sub-Text (XS) (12)
		subtitle2: {
			'@media (max-width:1919px)': {
				fontSize: '0.75em', // 12px
			},
			'@media (min-width:1920px)': {
				fontSize: '0.875em', //14px
			},
		},

		// XXL - 30
		h1: {
			'@media (max-width:1919px)': {
				fontSize: '1.875em', //30px
			},
			'@media (min-width:1920px)': {
				fontSize: '2em', //32px
			},
		},

		// XL - 25
		h2: {
			'@media (max-width:1919px)': {
				fontSize: '1.5625em', // 25px
			},
			'@media (min-width:1920px)': {
				fontSize: '1.6875em', // 27px
			},
		},

		// Large (20px)
		h3: {
			'@media (max-width:1919px)': {
				fontSize: '1.25em', // 20px
			},
			'@media (min-width:1920px)': {
				fontSize: '1.375em', // 22px
			},
		},
	},
}

export default themeConfig
