import React from 'react'
import { useSelector } from 'react-redux'

import { STYLES } from '@/constants'
import { getUserRole } from '@/store/auth'
import { makeStyles } from '@mui/styles'

import { GreetingSection } from './admin/greetingSection'
import Announcements from './dashlets/Announcements'
import MyOrganizations from './dashlets/MyOrganizations'
import { QuickActions } from './dashlets/QuickActions'
import Resources from './dashlets/Resources'

const useStyles = makeStyles((theme) => ({
	root: {
		display: 'flex',
	},
	leftSide: {
		width: '70%',
	},
	rightSide: {
		width: '30%',
		marginLeft: 20,
		position: 'relative',
	},
	resourceRoot: {
		position: 'sticky',
		top: `calc(${STYLES.MAIN_HEADER_HEIGHT}vh + 21px)`,
		maxHeight: `calc(90vh - ${STYLES.MAIN_HEADER_HEIGHT}vh - 21px)`,
	},
}))

// @TODO: any need to be fixed #bad_typing
export type IDashletsProps = {
	stats: any
}

const Dashlets: React.FC<IDashletsProps> = ({ stats }) => {
	const classes = useStyles()
	const currentUserRole = useSelector(getUserRole)

	return (
		<div className={classes.root}>
			<div className={classes.leftSide}>
				<GreetingSection />
				<Announcements />
				<QuickActions role={currentUserRole} />
				<MyOrganizations orgList={stats} />
			</div>
			<div className={classes.rightSide}>
				<Resources rootClass={classes.resourceRoot} />
			</div>
		</div>
	)
}

export default Dashlets
