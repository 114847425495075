import { Theme } from '@mui/material'
import { AllColors, getDisabledColor, getHoverColor, SemanticColors, ThemeColors } from '@navengage/sen-shared-assets'

declare module '@mui/material/styles/createTheme' {
	interface Theme {
		colors: ThemeColors & SemanticColors & AllColors
	}
	interface DeprecatedThemeOptions {
		colors: ThemeColors & SemanticColors & AllColors
	}
}

export enum ButtonColors {
	PRIMARY = 'PRIMARY',
	PRIMARY_LIGHT = 'PRIMARY_LIGHT',
	BLUE = 'BLUE',
	GREEN = 'GREEN',
	LIGHT_GREEN = 'LIGHT_GREEN',
	LIGHT_BLUE = 'LIGHT_BLUE',
	BLUE4 = 'BLUE4',
	WHITE = 'WHITE',
	YELLOW = 'YELLOW',
	GREY = 'GREY',
	LIGHT_RED = 'LIGHT_RED',
	RED = 'RED',
}

export interface ButtonColor {
	main: string
	hover: string
	disabled: string
	textColor: string
}

type ButtonColorKey = keyof typeof ButtonColors

type AllButtonColors = { [k in ButtonColorKey]: ButtonColor }

export const createButtonColors = (theme: Theme): AllButtonColors => ({
	[ButtonColors.BLUE]: {
		main: theme.colors.blue[500],
		hover: getHoverColor(theme.colors.blue),
		disabled: getDisabledColor(theme.colors.blue),
		textColor: theme.colors.white[500],
	},
	[ButtonColors.GREEN]: {
		main: theme.colors.green[500],
		hover: getHoverColor(theme.colors.green),
		disabled: getDisabledColor(theme.colors.green),
		textColor: theme.colors.white[500],
	},
	[ButtonColors.LIGHT_GREEN]: {
		main: theme.colors.green[100],
		hover: getHoverColor(theme.colors.green, 100),
		disabled: getDisabledColor(theme.colors.green, 100),
		textColor: theme.colors.green[200],
	},
	[ButtonColors.WHITE]: {
		main: theme.colors.white[500],
		hover: getHoverColor(theme.colors.white),
		disabled: getDisabledColor(theme.colors.white),
		textColor: theme.colors.grey[500],
	},
	[ButtonColors.GREY]: {
		main: theme.colors.grey[200],
		hover: theme.colors.grey[100],
		disabled: getDisabledColor(theme.colors.grey, 200),
		textColor: theme.colors.black[500],
	},
	[ButtonColors.LIGHT_RED]: {
		main: theme.colors.red[200],
		hover: getHoverColor(theme.colors.red, 200),
		disabled: getDisabledColor(theme.colors.red, 200),
		textColor: theme.colors.red[500],
	},
	[ButtonColors.LIGHT_BLUE]: {
		main: theme.colors.blue[100],
		hover: getHoverColor(theme.colors.blue, 100),
		disabled: getDisabledColor(theme.colors.blue, 100),
		textColor: theme.colors.blue[500],
	},
	[ButtonColors.RED]: {
		main: theme.colors.red[500],
		hover: getHoverColor(theme.colors.red),
		disabled: getDisabledColor(theme.colors.red),
		textColor: theme.colors.white[500],
	},
	[ButtonColors.YELLOW]: {
		main: theme.colors.yellow[500],
		hover: getHoverColor(theme.colors.yellow),
		disabled: getDisabledColor(theme.colors.yellow),
		textColor: theme.colors.black[500],
	},
	[ButtonColors.BLUE4]: {
		main: theme.colors.blue[700],
		hover: getHoverColor(theme.colors.blue, 700),
		disabled: getDisabledColor(theme.colors.blue, 700),
		textColor: theme.colors.white[500],
	},
	[ButtonColors.PRIMARY]: {
		main: theme.colors.Primary[700],
		hover: getHoverColor(theme.colors.Primary),
		disabled: getDisabledColor(theme.colors.Primary),
		textColor: theme.colors.white[500],
	},
	[ButtonColors.PRIMARY_LIGHT]: {
		main: theme.colors.Primary[100],
		hover: getHoverColor(theme.colors.Primary, 100),
		disabled: getDisabledColor(theme.colors.Primary, 100),
		textColor: theme.colors.Primary[700],
	},
})

export const pickButtonColor = (color: ButtonColors, buttonColors: AllButtonColors) => {
	const colors = buttonColors[color]
	return colors
}

export type MuiButtonColors = 'primary' | 'secondary' | 'inherit' | 'success' | 'error' | 'info' | 'warning'
