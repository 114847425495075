import classNames from 'classnames'
import { useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router'

import Popover from '@/components/Popover'
import { TextButton } from '@/components/shared'
import { STYLES } from '@/constants'

import { Typography } from '@mui/material'
import { makeStyles } from '@mui/styles'
import { NotificationViewStatuses, NOTIFICATION_PATHS } from '../constants'
import { selectHasMoreNotifications, selectPopperNotifications, setViewedNotificationsRequest } from '../notificationsSlice'
import NotificationsBlock from './NotificationsBlock'
import { NotificationPages } from '../types'

const useStyles = makeStyles((theme) => ({
	notificationsPopper: {
		marginTop: 40,
		padding: '10px 20px',
		minWidth: '20vw',

		boxShadow: '0px 3px 6px #00000029',
		border: `1px solid ${theme.colors.Neutral[300]}`,
		borderRadius: 8,
		maxHeight: `calc(100vh - ${STYLES.MAIN_HEADER_HEIGHT}vh - 40px)`,
		overflowY: 'hidden',
	},
	popperContainer: {
		maxHeight: `calc(100vh - ${STYLES.MAIN_HEADER_HEIGHT}vh - 60px)`,
	},
	notificationsPopperTitle: {
		fontWeight: 700,
	},
	titleContainer: {
		display: 'flex',
		flexDirection: 'row',
		alignItems: 'center',
		justifyContent: 'space-between',
		marginBottom: 10,
	},
	rootContainer: {
		width: '100%',
	},
	seeAll: {
		fontWeight: 600,
	},
	content: {
		overflowY: 'auto',
		height: 'calc(100% - 30px)',
	},
}))

interface NotificationsPopperProps {
	anchorEl: any
	handleClose: () => void
}

const NotificationsPopper = ({ anchorEl, handleClose = () => {} }: NotificationsPopperProps) => {
	const classes = useStyles()
	const dispatch = useDispatch()

	const { unviewedList: newNotifications, viewedList: pastNotifications } = useSelector(selectPopperNotifications)

	const hasMoreNewNotifications = useSelector(selectHasMoreNotifications(NotificationViewStatuses.UNVIEWED))
	const hasMorePastNotifications = useSelector(selectHasMoreNotifications(NotificationViewStatuses.VIEWED))

	const { push } = useHistory()

	const navigateToPage = useCallback(
		(type: NotificationPages) => () => {
			push(NOTIFICATION_PATHS.ROOT(type))
			handleClose()
		},
		[handleClose, push],
	)

	const closeAndSetViewed = useCallback(() => {
		dispatch(setViewedNotificationsRequest(newNotifications))
		handleClose()
	}, [dispatch, handleClose, newNotifications])

	const hasNewNotifications = !!newNotifications.length
	const hasPastNotifications = !!pastNotifications.length
	const showPlaceholder = !hasNewNotifications && !hasPastNotifications

	return (
		<Popover
			anchorEl={anchorEl}
			handleClose={closeAndSetViewed}
			boxShadow={false}
			classnames={{
				paper: classes.notificationsPopper,
				container: classes.popperContainer,
			}}
		>
			<div className={classes.rootContainer}>
				<div className={classes.titleContainer}>
					<Typography className={classes.notificationsPopperTitle} variant="h3">
						Notifications
					</Typography>
					{!showPlaceholder && (
						<TextButton className={classes.seeAll} onClick={navigateToPage('all')}>
							See All
						</TextButton>
					)}
				</div>
				{showPlaceholder ? (
					<Typography>No notifications yet!</Typography>
				) : (
					<div className={classNames(classes.content, 'cm-scroller')}>
						<NotificationsBlock
							notifications={newNotifications}
							seeMoreLink
							isNew
							compactView
							showDivider={hasPastNotifications}
							handleClosePopup={closeAndSetViewed}
							canLoadMore={hasMoreNewNotifications}
							handleLoadMoreItems={navigateToPage('new')}
						/>
						{hasPastNotifications && (
							<NotificationsBlock
								canLoadMore={hasMorePastNotifications}
								handleLoadMoreItems={navigateToPage('past')}
								seeMoreLink
								notifications={pastNotifications}
								compactView
								showDivider={false}
								handleClosePopup={closeAndSetViewed}
							/>
						)}
					</div>
				)}
			</div>
		</Popover>
	)
}

export default NotificationsPopper
