import './css/index.css'

import React from 'react'
import { Provider } from 'react-redux'
import { Route, Router } from 'react-router-dom'
import { ExtendedStringifyOptions, QueryParamProvider, transformSearchStringJsonSafe } from 'use-query-params'

import SnackbarProvider from '@/components/Snackbar/SnackbarProvider'
import appTheme from '@/theme'
import { StyledEngineProvider, Theme, ThemeProvider } from '@mui/material'
import { LocalizationProvider } from '@mui/x-date-pickers'
import { AdapterLuxon } from '@mui/x-date-pickers/AdapterLuxon'

import { history, store } from './store'
// import { SplitioProvider } from './components/splitio/SplitioProvider'
// import { CircularProgress } from '@mui/material'

declare module '@mui/styles/defaultTheme' {
	// eslint-disable-next-line @typescript-eslint/no-empty-interface
	interface DefaultTheme extends Theme {}
}

const queryStringifyOptions: ExtendedStringifyOptions = {
	transformSearchString: transformSearchStringJsonSafe,
}

interface ProvidersProps {
	children: React.ReactNode
}

const Providers = ({ children }: ProvidersProps) => {
	return (
		// <SplitioProvider>
		// 	{({ isReady }) => {
		// 		if (!isReady) {
		// 			return (
		// 				<div className="u-sizeFullViewport u-flexCenteredItems">
		// 					<CircularProgress color="primary" />
		// 				</div>
		// 			)
		// 		}

		// 		return (
		<Provider store={store}>
			<Router history={history}>
				<QueryParamProvider ReactRouterRoute={Route} stringifyOptions={queryStringifyOptions}>
					<StyledEngineProvider injectFirst>
						<ThemeProvider theme={appTheme}>
							<LocalizationProvider dateAdapter={AdapterLuxon}>
								<SnackbarProvider>{children}</SnackbarProvider>
							</LocalizationProvider>
						</ThemeProvider>
					</StyledEngineProvider>
				</QueryParamProvider>
			</Router>
		</Provider>
		// 		)
		// 	}}
		// </SplitioProvider>
	)
}

export default Providers
