import classNames from 'classnames'

import { InputBaseProps, InputProps, TextField } from '@mui/material'
import { Theme } from '@mui/material/styles'
import makeStyles from '@mui/styles/makeStyles'

interface StyleProps {
	error?: boolean
	disabled?: boolean
}

const useStyles = makeStyles<Theme, StyleProps>((theme) => ({
	root: {
		position: 'relative',
		display: 'flex',
	},
	inputRoot: {
		width: '100%',
		backgroundColor: theme.colors.grey[200],
		'&.MuiOutlinedInput-root': {
			border: 'none',
		},
		'& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline': {
			border: 'none',
		},
		'&:hover .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline': {
			border: 'none',
		},
		'& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
			border: 'none',
		},
		borderRadius: 5,
	},
	input: {
		padding: `${theme.typography.pxToRem(13)} ${theme.typography.pxToRem(28)}`,
		fontSize: theme.spacing(2.25),
		lineHeight: theme.spacing(3),
		height: 'auto',
		'&.MuiInputBase-inputMultiline': {
			padding: 0,
		},
	},

	inputIcon: {
		color: theme.colors.Primary[700],
	},
	inputFilled: ({ error }) => {
		const borderColor = error ? theme.colors.red[500] : theme.colors.grey[200]

		return {
			backgroundColor: theme.colors.white[500],
			'&.MuiOutlinedInput-root': {
				border: `solid 1px ${borderColor}`,
			},
			'& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline': {
				border: `solid 1px ${borderColor}`,
			},
			'&:hover .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline': {
				border: `solid 1px ${borderColor}`,
			},
			'& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
				border: `solid 1px ${borderColor}`,
			},
		}
	},
}))

interface FilledTextFieldProps extends StyleProps {
	className?: string
	value?: string
	placeholder?: string
	onChange: (e: any) => void
	onKeyPress?: (e: any) => void
	onFocus?: (e: any) => void
	onBlur?: (e: any) => void
	variant?: 'primary' | 'secondary'
	inputProps?: InputProps
	multiline?: boolean
	muiInputProps?: InputBaseProps['inputProps']
	helperText?: string
}

const FilledTextField = ({
	className = '',
	value = '',
	placeholder,
	onChange,
	onKeyPress,
	onFocus,
	onBlur,
	variant = 'primary',
	inputProps,
	error,
	multiline,
	muiInputProps,
	helperText,
}: FilledTextFieldProps) => {
	const classes = useStyles({ error })

	const changeFilledColor = variant === 'secondary' && value

	return (
		<TextField
			helperText={helperText}
			multiline={multiline}
			variant="outlined"
			classes={{
				root: classNames(className, changeFilledColor ? classes.inputFilled : '', classes.inputRoot),
			}}
			placeholder={placeholder}
			value={value}
			onChange={onChange}
			onKeyPress={onKeyPress}
			onFocus={onFocus}
			onBlur={onBlur}
			inputProps={muiInputProps}
			InputProps={{
				...inputProps,
				classes: {
					input: classes.input,
				},
			}}
		/>
	)
}

export default FilledTextField
