import { Grid, styled } from '@mui/material'

const ContainerGrid = styled(Grid)(({ theme }) => ({
	maxWidth: 800,
	marginBottom: theme.spacing(2.5),
}))

export type IUserRow = {
	email: React.ReactElement | string
	userName?: React.ReactElement | string
}

export const UserRow: React.FC<IUserRow> = ({ email, userName }) => {
	return (
		<ContainerGrid container spacing={2}>
			<Grid item sm={6}>
				{email}
			</Grid>
			<Grid item sm={6}>
				{userName}
			</Grid>
		</ContainerGrid>
	)
}
