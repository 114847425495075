import { Controller } from 'react-hook-form'

import FieldErrorMessage from '@/components/FieldErrorMessage'
import { TextField } from '@/components/shared'

export const UserInput = ({ inputName, stateControl, placeholder }) => {
	const { control, errors } = stateControl

	return (
		<Controller
			name={inputName}
			defaultValue=""
			control={control}
			render={({ field: { value, onChange, name } }) => {
				return (
					<>
						<TextField placeholder={placeholder} error={!!errors[name]} variant="standard" {...{ value, onChange }} />
						<FieldErrorMessage hasError={!!errors[name]} message={errors[name] ? errors[name].message : ''} />
					</>
				)
			}}
		/>
	)
}
