import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'

import { BackButton } from '@/components/BackButton'
import { MiddlePaneLayout } from '@/components/layouts/dashboard/MiddleLayout'
import MiddlePaneLoader from '@/components/MiddlePaneLoader'
import { Typography } from '@mui/material'
import { makeStyles } from '@mui/styles'

import { UserOrganizationsTable } from '../components/UserOrganizationsTable'
import { getSelectedUserInfoRequest, selectCurrentUserData, selectUsersIsLoading } from '../usersSlice'

const useStyles = makeStyles((theme) => ({
	pageTop: {
		marginBottom: theme.spacing(4.75), // @TODO distance between back button and entire page content changes from page to page
	},
	userName: {
		fontWeight: theme.typography.fontWeightBold,
		fontSize: '25px',
		lineHeight: '34px',
		marginBottom: theme.spacing(1.5),
	},
	emailLink: {
		textDecoration: 'none',
		color: theme.colors.Primary[700],
		fontWeight: theme.typography.fontWeightMedium,
	},
}))

const ViewUser = () => {
	const classes = useStyles()
	const { id } = useParams<{ id?: string }>()
	const isLoading = useSelector(selectUsersIsLoading)
	const selectedUser = useSelector(selectCurrentUserData)
	const dispatch = useDispatch()
	useEffect(() => {
		if (id) {
			dispatch(getSelectedUserInfoRequest(Number(id)))
		}
	}, [dispatch, id])

	return (
		<MiddlePaneLayout>
			<div className={classes.pageTop}>
				<BackButton />
			</div>
			{isLoading ? (
				<MiddlePaneLoader />
			) : selectedUser ? (
				<>
					<Typography className={classes.userName}>{selectedUser.userInfo.userName}</Typography>
					<a className={classes.emailLink} href={`mailto:${selectedUser.userInfo.email}`}>
						{selectedUser.userInfo.email}
					</a>
					<UserOrganizationsTable data={selectedUser.organizations} />
				</>
			) : null}
		</MiddlePaneLayout>
	)
}

export default ViewUser
