import List from './pages/lists'
import { ROLES } from '@/constants'
import AppRoute from '@/interfaces/route'
import { createPageTitle } from '@/utils/page'

import { USERS_PATHS } from './constants'
import ViewUser from './pages/ViewUser'

const USERS_TITLE = createPageTitle('Users List')
const VIEW_USER_TITLE = createPageTitle('View User')

const USERS_ROUTES: AppRoute[] = [
	{
		component: List,
		title: USERS_TITLE,
		name: USERS_TITLE,
		path: USERS_PATHS.LIST('students'),
		exact: false,
		isPrivate: true,
		allowedRoles: [ROLES.CAMPUS_ADMIN],
	},
	{
		component: List,
		title: USERS_TITLE,
		name: USERS_TITLE,
		path: USERS_PATHS.LIST('campus-admins'),
		exact: false,
		isPrivate: true,
		allowedRoles: [ROLES.CAMPUS_ADMIN],
	},
	{
		component: ViewUser,
		title: VIEW_USER_TITLE,
		name: VIEW_USER_TITLE,
		path: USERS_PATHS.VIEW_USER(),
		exact: true,
		isPrivate: true,
		allowedRoles: [ROLES.CAMPUS_ADMIN],
	},
]

export default USERS_ROUTES
