import http from '@/api/http/requestHttp'
import * as config from '@/constants/configuration'
import { TForm } from '@/features/formSubmissions/types'

const getForm = async (id: string) => {
	const url = `${config.PSU_SCHOOL_ID}/oms/forms/${id}`

	return await http.get<TForm>(url)
}

export default getForm
