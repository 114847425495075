import { FilledTextField, styles } from '@/components/shared'
import { APP_MESSAGES } from '@/constants'
import { DESCRIPTION_MAX_LENGTH } from '@/utils/common'
import { Typography } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import { ChangeEvent } from 'react'

const useStyles = makeStyles((theme) => ({
	root: {
		width: styles.FILL_AVAILABLE_WIDTH,
	},
}))

interface EditQuestionFieldProps {
	value: string
	onChange: (value: string) => void
	isEdit: boolean
}

const EditQuestionField = ({ value, onChange, isEdit }: EditQuestionFieldProps) => {
	const classes = useStyles()

	const handelChangeQuestion = ({ target: { value } }: ChangeEvent<HTMLInputElement>) => onChange(value)

	if (!isEdit) return <Typography className={classes.root}>{value || 'Question'}</Typography>

	return (
		<FilledTextField
			helperText={APP_MESSAGES.GENERALS.getMaxLengthMessage(DESCRIPTION_MAX_LENGTH)}
			muiInputProps={{ maxLength: DESCRIPTION_MAX_LENGTH }}
			multiline
			value={value}
			placeholder="Question"
			onChange={handelChangeQuestion}
		/>
	)
}

export default EditQuestionField
