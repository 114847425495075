import React from 'react'
import { v4 as uuidv4 } from 'uuid'
import makeStyles from '@mui/styles/makeStyles'
import { styles, TextButton } from '@/components/shared'
import { AnswerOptionType, AnswerWithOtherOption } from '../../types'
import { Typography } from '@mui/material'

const useStyles = makeStyles((theme) => ({
	root: {
		width: styles.FILL_AVAILABLE_WIDTH,
		margin: 10,
		display: 'flex',
		alignItems: 'center',
	},
	text: {
		marginLeft: 5,
	},
}))

interface AddOptionProps {
	canAddOtherOption?: boolean
	onAddNewAnswer: (answer: AnswerWithOtherOption) => void
	optionTypeElement?: React.ReactNode
}

const AddOption = ({ canAddOtherOption, onAddNewAnswer, optionTypeElement = null }: AddOptionProps) => {
	const classes = useStyles()

	const handelAddNewOption = (answer: AnswerWithOtherOption) => () => onAddNewAnswer(answer)

	return (
		<div className={classes.root}>
			{optionTypeElement}
			<TextButton
				className={classes.text}
				onClick={handelAddNewOption({
					id: uuidv4(),
					type: AnswerOptionType.text,
					answer: '',
				})}
			>
				Add option
			</TextButton>
			{canAddOtherOption && (
				<>
					<Typography className={classes.text}>or</Typography>
					<TextButton
						className={classes.text}
						onClick={handelAddNewOption({
							id: uuidv4(),
							type: AnswerOptionType.other,
							answer: 'Other',
						})}
					>
						add other
					</TextButton>
				</>
			)}
		</div>
	)
}

export default AddOption
