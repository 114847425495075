import React from 'react'
import { Link, useHistory } from 'react-router-dom'

import { styled, Tab, Tabs } from '@mui/material'

export type IStyledTabsProps = {
	fullWidth?: boolean
}

export const StyledTabs = styled(Tabs, {
	shouldForwardProp: (prop) => prop !== 'fullWidth',
})<IStyledTabsProps>(({ theme, fullWidth }) => ({
	display: fullWidth ? 'flex' : 'inline-flex',
	position: 'relative',
	'&:after': {
		content: '""',
		display: 'block',
		position: 'absolute',
		width: '100%',
		bottom: 2,
		left: 0,
		height: 1,
		background: theme.colors.Neutral[300],
	},
	'& .MuiTabs-flexContainer': {
		justifyContent: fullWidth ? 'space-between' : 'flex-start',
	},
	'& .MuiTabs-indicator': {
		height: 4,
		borderRadius: '4px',
		zIndex: 2,
	},
	'& .MuiTab-root': {
		marginRight: fullWidth ? 0 : theme.spacing(8),
		padding: `0 ${theme.spacing(0.5)} ${theme.spacing(2)}`,
		minWidth: 0,
		...theme.typography.body1,
		textTransform: 'none',
		fontWeight: theme.typography.fontWeightMedium,
	},
	'& .MuiTab-root:not(.Mui-selected)': {
		color: theme.colors.black[500],
	},
	'& .MuiTab-root:last-child': {
		marginRight: 0,
	},
}))

export type IRouterTabsProps = {
	tabs: Array<{ value: string; label: string }>
} & IStyledTabsProps

const RouterTabs: React.FC<IRouterTabsProps> = ({ tabs, ...tabProps }) => {
	const history = useHistory()
	const currentTab = tabs.find((tab) => history.location.pathname.startsWith(tab.value))
	const tabsValue = currentTab?.value || history.location.pathname
	return (
		<StyledTabs {...tabProps} indicatorColor="primary" value={tabsValue}>
			{tabs.map((tab) => (
				<Tab key={tab.value} label={tab.label} component={Link} value={tab.value} to={tab.value} />
			))}
		</StyledTabs>
	)
}

export default RouterTabs
