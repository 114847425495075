import { FC, useCallback } from 'react'
import { useHistory } from 'react-router-dom'

import { Button, ButtonProps, styled } from '@mui/material'

const StyledButton = styled(Button)(({ theme }) => ({
	...theme.typography.body1,
	color: theme.colors.black[500],
	fontWeight: theme.typography.fontWeightMedium,
	textTransform: 'none',
}))

type BackButtonProps = ButtonProps

export const BackButton: FC<BackButtonProps> = (props) => {
	const history = useHistory()
	const handleGoBack = useCallback(() => {
		history.goBack()
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])
	return (
		<StyledButton {...props} onClick={handleGoBack}>
			&#60; Back
		</StyledButton>
	)
}
