import { useRef, useState } from 'react'
import { Control, Controller, FieldErrors, UseFormWatch } from 'react-hook-form'
import { useSelector } from 'react-redux'

import { CustomDateTimePicker } from '@/components/DateTimePicker/CustomDateTimePicker'
import { FormSettings, TimePickers } from '@/components/Form/FormBuilder/types'
import { Card, CheckBox, ColorButton, CommonSelect, ErrorField, Label, styles, TextField } from '@/components/shared'
import { APP_MESSAGES, ERRORS } from '@/constants'
import { getCategories } from '@/store/forms'
import appTheme from '@/theme'

import { Popover, Typography } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import { specialColors } from '@navengage/sen-shared-assets'
import { DESCRIPTION_MAX_LENGTH, TEXT_INPUT_MAX_LENGTH } from '@/utils/common'

const useStyles = makeStyles((theme) => ({
	root: {
		width: styles.FILL_AVAILABLE_WIDTH,
		padding: '30px 0',
	},
	header: {
		fontWeight: 700,
	},
	input: {
		height: 56,
		margin: '20px 0 10px 0',
		width: '35vw',
		minWidth: 300,
		'&>div.MuiOutlinedInput-adornedEnd': {
			paddingRight: 11,
		},
		'&>.MuiInputBase-root': {
			height: 'inherit',
		},
	},
	searchButton: {
		padding: 5,
		minWidth: 38,
		minHeight: 38,
		borderRadius: 3,
	},
	selectContainer: {
		display: 'flex',
		alignItems: 'center',
		flexWrap: 'wrap',
	},
	select: {
		width: '40%',
		height: 48,
		marginRight: 15,
	},
	selectInput: {
		margin: '10px 0 30px 10px',
		marginRight: 10,
	},
	placeholder: {
		width: styles.FILL_AVAILABLE_WIDTH,
	},
	placeholderText: {
		fontWeight: 500,
		textAlign: 'center',
	},
	label: {
		margin: `${theme.spacing(2)} 0`,
		fontSize: theme.typography.pxToRem(18),
		lineHeight: theme.typography.pxToRem(24),
	},
	labelBig: {
		fontSize: '25px',
		lineHeight: '34px',
		fontWeight: theme.typography.fontWeightBold,
		marginBottom: 44,
	},
	checkbox: {
		padding: 0,
		marginRight: theme.spacing(2.25),
	},
	checkboxLabel: {
		marginLeft: 0,
		marginTop: theme.spacing(1.25),
		marginBottom: theme.spacing(1.25),
		fontSize: theme.typography.pxToRem(18),
		lineHeight: theme.typography.pxToRem(24),
	},
	form: {
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
	},
	nextButton: {
		margin: '10px 0',
		minWidth: 220,
		height: 45,
	},
	fromErrorContainer: {
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
		margin: 35,
	},
	tooltip: {
		color: theme.colors.white[500],
		backgroundColor: theme.colors.grey[500],
		borderRadius: 3,
		padding: '5px 10px',
	},
	popover: {
		pointerEvents: 'none',
	},
	paper: {
		backgroundColor: specialColors.transparent,
		paddingTop: '1vh',
		boxShadow: 'none',
	},
}))

interface SettingsTabProps {
	onSubmit: (e?: React.BaseSyntheticEvent<object, any, any> | undefined) => Promise<void>
	control: Control<FormSettings, any>
	errors: FieldErrors<FormSettings>
	isDirty: boolean
	hasErrors: boolean
	disabled?: boolean
	isValid: boolean
	watch: UseFormWatch<FormSettings>
	makeActive?: boolean
}

const SettingsTab = ({ onSubmit, control, errors, isDirty, hasErrors, disabled, isValid, watch, makeActive }: SettingsTabProps) => {
	const classes = useStyles()

	const anchorRef = useRef<any>(null)

	const [openTooltip, setOpenTooltip] = useState(false)

	const categoriesOptions = useSelector(getCategories)

	const disableSubmitButton = !isValid || !!hasErrors || !!disabled

	const shouldShowTooltip = disableSubmitButton && openTooltip

	const handleOpenTooltip = () => {
		setOpenTooltip(true)
	}
	const handleCloseTooltip = () => {
		setOpenTooltip(false)
	}

	return (
		<div className={classes.root}>
			<form className={classes.form} onSubmit={onSubmit}>
				<Card>
					<Controller
						name="category"
						control={control}
						render={({ field: { value, onChange } }) => (
							<Label
								classnames={{
									label: classes.label,
								}}
								title="Category"
								semiBold
								required
							>
								<CommonSelect
									classnames={{
										input: classes.select,
									}}
									value={value}
									onChange={onChange}
									options={categoriesOptions}
									placeholder="Select Category"
									error={!!errors.category}
									disabled={disabled}
								/>
							</Label>
						)}
					/>
				</Card>
				<Card>
					<Controller
						name="title"
						defaultValue=""
						control={control}
						render={({ field }) => (
							<Label
								classnames={{
									label: classes.labelBig,
								}}
								title="Title"
								semiBold
								required
							>
								<TextField
									inputProps={{ maxLength: TEXT_INPUT_MAX_LENGTH }}
									helperText={APP_MESSAGES.GENERALS.getMaxLengthMessage(TEXT_INPUT_MAX_LENGTH)}
									variant="standard"
									placeholder="Title"
									error={!!errors.title}
									{...field}
									disabled={disabled}
								/>
							</Label>
						)}
					/>
				</Card>
				<Card>
					<Controller
						name="description"
						defaultValue=""
						control={control}
						render={({ field }) => (
							<Label
								classnames={{
									label: classes.labelBig,
								}}
								title="Description"
								semiBold
								required
							>
								<TextField
									multiline
									inputProps={{ maxLength: DESCRIPTION_MAX_LENGTH }}
									helperText={APP_MESSAGES.GENERALS.getMaxLengthMessage(DESCRIPTION_MAX_LENGTH)}
									variant="standard"
									placeholder="Description"
									error={!!errors.description}
									{...field}
									disabled={disabled}
								/>
							</Label>
						)}
					/>
				</Card>
				<Card>
					<Controller
						name="isActive"
						defaultValue={false}
						control={control}
						render={({ field }) => (
							<Label
								classnames={{
									label: classes.label,
								}}
								title="Do you want this form to be active?"
								semiBold
							>
								<CheckBox
									checkboxClassName={classes.checkbox}
									checkboxLabelClass={classes.checkboxLabel}
									size={22}
									{...field}
									label="Make Active"
									disabled={disabled}
									variant="secondary"
									textColor={appTheme.colors.black[500]}
								/>
								<Typography color="GrayText" variant="body2">
									Once published, this form will be active until you make it inactive or delete it. If you would like to set a date range
									instead, keep this box unchecked and fill out the date range fields.
								</Typography>
							</Label>
						)}
					/>
				</Card>
				{!makeActive && (
					<Card>
						<Label
							classnames={{
								label: classes.label,
							}}
							title="Start Date"
							semiBold
							required
						>
							<Controller
								name="startDate"
								control={control}
								render={({ field: { value, onChange } }) => {
									const endDate = watch('endDate')

									return (
										<CustomDateTimePicker
											timeSelection={TimePickers.dropdown}
											timePickerOptions={{
												placeholder: 'Start Time',
											}}
											maxDate={endDate}
											disablePast
											value={value}
											onChange={onChange}
											placeholder="Start Date"
											error={!!errors.startDate}
											disabled={disabled}
										/>
									)
								}}
							/>
						</Label>
						<Label
							classnames={{
								label: classes.label,
							}}
							title="End Date"
							semiBold
							required
						>
							<Controller
								name="endDate"
								control={control}
								render={({ field: { value, onChange } }) => {
									const startDate = watch('startDate')
									return (
										<CustomDateTimePicker
											timeSelection={TimePickers.dropdown}
											timePickerOptions={{
												placeholder: 'End Time',
											}}
											minDate={startDate}
											value={value}
											onChange={onChange}
											disablePast
											placeholder="End Date"
											error={!!errors.endDate}
											disabled={disabled}
										/>
									)
								}}
							/>
						</Label>
					</Card>
				)}
				<Card>
					<Controller
						name="allowMultipleSubmissions"
						control={control}
						render={({ field }) => (
							<Label
								classnames={{
									label: classes.label,
								}}
								title="Do you want to allow users to submit more than once?"
								semiBold
							>
								<CheckBox
									checkboxClassName={classes.checkbox}
									checkboxLabelClass={classes.checkboxLabel}
									size={22}
									{...field}
									label="Allow multiple submissions"
									disabled={disabled}
									variant="secondary"
									textColor={appTheme.colors.black[500]}
								/>
							</Label>
						)}
					/>
				</Card>
				<ErrorField hasError={hasErrors} message={ERRORS.fieldsMissing} className={classes.fromErrorContainer}>
					{anchorRef?.current && (
						<Popover
							className={classes.popover}
							classes={{
								paper: classes.paper,
							}}
							open={shouldShowTooltip}
							anchorEl={anchorRef?.current}
							anchorOrigin={{
								vertical: 'bottom',
								horizontal: 'center',
							}}
							transformOrigin={{
								vertical: 'top',
								horizontal: 'center',
							}}
							disableRestoreFocus
						>
							<Typography className={classes.tooltip}>Complete required fields</Typography>
						</Popover>
					)}
					<ColorButton
						className={classes.nextButton}
						type={disableSubmitButton ? 'button' : 'submit'}
						disabled={disableSubmitButton}
						ref={anchorRef}
						onMouseEnter={handleOpenTooltip}
						onMouseLeave={handleCloseTooltip}
						component={disableSubmitButton ? 'div' : undefined}
					>
						Next
					</ColorButton>
				</ErrorField>
			</form>
		</div>
	)
}

export default SettingsTab
