import http from '@/api/http/requestHttp'
import * as config from '@/constants/configuration'

const createOrganization = async (body) => {
	const url = `${config.PSU_SCHOOL_ID}/oms/organizations`

	return await http.post<{ id: string }>(url, body)
}

export default createOrganization
