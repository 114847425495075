import { useEffect, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useRouteMatch } from 'react-router-dom'

import { MiddlePaneLayout } from '@/components/layouts/dashboard/MiddleLayout'
import { getUserInfo, getUserRole } from '@/store/auth'
import { isAdmin } from '@/utils/authHandlers'

import EventForm from '../components/EventForm'
import { EVENT_PATHS } from '../constants'
import { editEventRequest, getEventByIdRequest, selectCurrentEvent, selectCurrentEventForm } from '../eventsSlice'
import MiddlePaneLoader from '@/components/MiddlePaneLoader'
import { useCurrentUserOrganizations } from '@/features/organizations/hooks/useUserOrganizations'

const mapOrganizationToOption = (org) => ({ label: org.name, value: org.id })

const EditEventPage = () => {
	const dispatch = useDispatch()
	const currentUserRole = useSelector(getUserRole)
	const currentUser = useSelector(getUserInfo)
	const { admin, loading: orgsListLoading } = useCurrentUserOrganizations()
	const {
		params: { id },
	} = useRouteMatch<{ id: string }>()

	const event = useSelector(selectCurrentEvent)
	const organizationOptions = useMemo(() => {
		if (!event) {
			return []
		}
		return currentUser.id === event.submittedBy.id ? admin.map(mapOrganizationToOption) : event.organization.map(mapOrganizationToOption)
	}, [currentUser, event, admin])
	const eventData = useSelector(selectCurrentEventForm)
	useEffect(() => {
		if (!event || id !== event.id) {
			dispatch(getEventByIdRequest(id))
		}
	}, [dispatch, id, event])

	const onSubmit = (data: any) => {
		dispatch(editEventRequest(data))
	}

	return (
		<MiddlePaneLayout>
			{eventData.id && !orgsListLoading ? (
				<EventForm
					organizationsAsOptions={organizationOptions}
					isEdit={true}
					onSubmit={onSubmit}
					data={eventData}
					cancelRedirectPath={isAdmin(currentUserRole) ? EVENT_PATHS.NEW_LIST : EVENT_PATHS.LIST}
				/>
			) : (
				<MiddlePaneLoader />
			)}
		</MiddlePaneLayout>
	)
}

export default EditEventPage
