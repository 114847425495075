import { TFieldMapper } from '../TFieldMapper'
import FileGenerator from './FileGenerator'

export type TJsonGeneratorConfigs = {
	defaultHeaders: TFieldMapper
}

export default class JsonFileGenerator extends FileGenerator {
	protected _jsonData: any[] = []

	readonly type: string = 'text/json;charset=utf-8'
	readonly extension: string = 'json'

	public constructor(protected fileName: string, { defaultHeaders }: TJsonGeneratorConfigs) {
		super(fileName, { defaultHeaders })
	}
	getBuffer() {
		return JSON.stringify(Object.assign({}, this._jsonData))
	}
	addData(data: any[]) {
		const fieldKeys = Object.keys(this._defaultHeaders)
		data.forEach((r) => {
			const row = fieldKeys.reduce((acc, field) => {
				const header = this._defaultHeaders[field].header
				acc[header] = r[field]
				return acc
			}, {})
			this._jsonData.push(row)
		})
	}
}
