import { makeStyles } from '@mui/styles'
import { useSelector } from 'react-redux'
import { ExportItem } from './ExportItem'
import { selectExportListState } from './exportListSlice'

const useStyles = makeStyles((theme) => ({
	root: {
		boxSizing: 'border-box',
		padding: '10px 20px',
		position: 'fixed',
		height: 80,
		bottom: 0,
		width: '100%',
		zIndex: 1000,
		background: theme.colors.white[500],
		display: 'flex',
		overflowX: 'auto',
		justifyContent: 'flex-end',
	},
	item: {
		marginLeft: `${theme.spacing(2)}`,
	},
}))

export type IExportListTrackerProps = {}

const ExportListTracker = () => {
	const exportList = useSelector(selectExportListState)
	const classes = useStyles()
	const exportKeys = Object.keys(exportList)
	return exportKeys.length > 0 ? (
		<div className={classes.root}>
			{Object.keys(exportList).map((exportId) => {
				return (
					<ExportItem
						className={classes.item}
						title={exportList[exportId].fileName}
						key={exportId}
						variant="determinate"
						value={exportList[exportId].progress}
					/>
				)
			})}
		</div>
	) : (
		<></>
	)
}

export { ExportListTracker }
