import { PATHS } from '@/constants'
import AppRoute from '@/interfaces/route'
import CreateForm from '@/pages/FormsPage/CreateFormPage'
import EditForm from '@/pages/FormsPage/EditFormPage'
import FormsList from '@/pages/FormsPage/FormsList'
import SubmitForm from '@/pages/FormsPage/SubmitFormAnswersPage'
import { ViewFormSubmissions } from '@/pages/FormsPage/ViewFormSubmissions'
import ViewSubmission from '@/pages/FormsPage/ViewSubmission'
import { getAllAuthorizedRoles } from '@/utils/authHandlers'
import { createPageTitle } from '@/utils/page'

const allAuthorizedRolesAllowed = getAllAuthorizedRoles()

const FORMS_TITLE = createPageTitle('Forms')
const CREATE_FORMS_TITLE = createPageTitle('Create Form')
const EDIT_FORMS_TITLE = createPageTitle('Edit Form')
const VIEW_SUBMISSION_TITLE = createPageTitle('View Submission')

const routes: AppRoute[] = [
	{
		component: FormsList,
		title: FORMS_TITLE,
		name: FORMS_TITLE,
		path: PATHS.APP.FORMS_LIST,
		exact: false,
		isPrivate: true,
		allowedRoles: allAuthorizedRolesAllowed,
	},
	{
		component: CreateForm,
		title: CREATE_FORMS_TITLE,
		name: CREATE_FORMS_TITLE,
		path: PATHS.APP.FORMS_CREATE,
		exact: true,
		isPrivate: true,
		allowedRoles: allAuthorizedRolesAllowed,
	},
	{
		component: EditForm,
		title: EDIT_FORMS_TITLE,
		name: EDIT_FORMS_TITLE,
		path: PATHS.APP.FORMS_EDIT(),
		exact: true,
		isPrivate: true,
		allowedRoles: allAuthorizedRolesAllowed,
	},
	{
		component: SubmitForm,
		title: 'Submit form',
		name: 'submit_form',
		path: PATHS.APP.SUBMIT_FORM(),
		exact: true,
		isPrivate: true,
		allowedRoles: allAuthorizedRolesAllowed,
	},
	{
		component: ViewSubmission,
		title: VIEW_SUBMISSION_TITLE,
		name: VIEW_SUBMISSION_TITLE,
		path: PATHS.APP.VIEW_SUBMISSION(),
		exact: true,
		isPrivate: true,
		allowedRoles: allAuthorizedRolesAllowed,
	},
	{
		component: ViewFormSubmissions,
		title: VIEW_SUBMISSION_TITLE,
		name: VIEW_SUBMISSION_TITLE,
		path: PATHS.APP.VIEW_FORM_SUBMISSIONS(),
		exact: true,
		isPrivate: true,
		allowedRoles: allAuthorizedRolesAllowed,
	},
]

export default routes
