import classNames from 'classnames'
import { Popover as MaterialPopover } from '@mui/material'
import { makeStyles } from '@mui/styles'

const useStyles = makeStyles((theme) => ({
	boldText: {
		fontWeight: 700,
	},
	popperContent: {
		padding: '1vh 1vw',
		borderRadius: 5,
		backgroundColor: theme.colors.white[500],
		display: 'flex',
		flexDirection: 'row',
	},
	popperPaper: ({ boxShadow }: any) => ({
		zIndex: 1300,
		maxHeight: '40vh',
		borderRadius: 5,
		boxShadow: boxShadow ? '0px 0px 8px 4px rgba(0,0,0,0.13)' : 'none',
		backgroundColor: theme.colors.white[500],
	}),
}))

interface Position {
	horizontal: 'center' | 'left' | 'right' | number
	vertical: 'bottom' | 'center' | 'top' | number
}
interface PopperProps {
	classnames?: {
		root?: string
		paper?: string
		container?: string
	}
	boxShadow?: boolean
	anchorEl: any
	handleClose: () => void
	children: React.ReactNode
	anchorOrigin?: Position
	transformOrigin?: Position
}

const Popover = ({
	classnames = {
		root: '',
		paper: '',
		container: '',
	},
	boxShadow = true,
	anchorEl,
	children,
	handleClose = () => {},
	anchorOrigin = {
		vertical: 'bottom',
		horizontal: 'right',
	},
	transformOrigin = {
		vertical: 'top',
		horizontal: 'right',
	},
}: PopperProps) => {
	const classes = useStyles({ boxShadow })

	const isOpen = Boolean(anchorEl)

	return (
		<MaterialPopover
			open={isOpen}
			anchorEl={anchorEl}
			role={undefined}
			anchorOrigin={anchorOrigin}
			transformOrigin={transformOrigin}
			classes={{
				root: classNames(classnames.root),
				paper: classNames(classnames.paper, classes.popperPaper),
			}}
			onClose={handleClose}
		>
			<div className={classNames(classnames.container, classes.popperContent)}>{children}</div>
		</MaterialPopover>
	)
}

export default Popover
