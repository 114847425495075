export const SETTINGS_ROOT_PATH = '/app/settings'
export const SETTINGS_PATHS = {
	ROOT: SETTINGS_ROOT_PATH,
	NOTIFICATIONS: `${SETTINGS_ROOT_PATH}/notifications`,
	PROFILE_SETTINGS: `${SETTINGS_ROOT_PATH}/profile`,
	BLOCKED_ACCOUNTS: `${SETTINGS_ROOT_PATH}/blocked-accounts`,
}

export const AVAILABLE_NOTIFICATION_OPTIONS = {
	web: 'Web Notifications',
	email: 'Email Notifications',
}
