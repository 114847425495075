import { baseStyles as _baseStyles } from '../../../../components/layouts/dashboard/baseStyles'

export const baseStyles = {
	..._baseStyles,
	gridAreaHeader: {
		..._baseStyles.flexCenteredItemsY,
		marginBottom: 37,
	},
	gridAreaTitle: {
		font: 'normal normal bold 18px/24px Open Sans',
		letterSpacing: '0px',
		color: '#0D0C22',
	},
	metricsGrid: {
		display: 'grid',
		gap: '21px',
		gridTemplateColumns: 'repeat(auto-fill, minmax(135px, 1fr)) ',
	},
}
