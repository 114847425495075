import { useCallback, useRef, useState } from 'react'
import { useForm } from 'react-hook-form'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import * as yup from 'yup'

import createOrganization from '@/api/http/organizations/createOrganization'
import { MiddlePaneLayout } from '@/components/layouts/dashboard/MiddleLayout'
import { ButtonColors, ColorButton } from '@/components/shared'
import { MaterialUiColor } from '@/constants/materialUi'
import mainPaths from '@/constants/paths/main'
import { URL_TYPES, YUP_URLS_SCHEMA } from '@/constants/validations'
import { ORGANIZATION_PATHS } from '@/features/organizations/constants'
import { closeSnackbar, enqueueSnackbar, showExitConfirmationModal } from '@/store/app'
import { getUserRole } from '@/store/auth'
import { isAdmin } from '@/utils/authHandlers'
import { yupResolver } from '@hookform/resolvers/yup'
import { Close, GroupTwoTone } from '@mui/icons-material'
import { Popover, Typography } from '@mui/material'
import { makeStyles } from '@mui/styles'
import { specialColors } from '@navengage/sen-shared-assets'

import { creationSuccessToast } from '@/utils/notificationHelpers'
import { baseStyles } from './baseStyles'
import { BasicInformationSection } from './section/BasicInformationSection'
import { OrganizationPhotoSection } from './section/OrganizationPhotoSection'
import { RolesSection } from './section/RolesSection'
import { SocialMediaSection } from './section/socialMediaSection'

const useStyles = makeStyles((theme) => ({
	...baseStyles,
	header: {
		marginBottom: 34,
	},
	headerTitle: {
		...baseStyles.flex1,
		letterSpacing: 0,
		color: '#0d0c22',
		textAlign: 'left',
		font: 'normal normal bold 40px/55px Open Sans',
		opacity: 1,
	},
	headerIcon: {
		...baseStyles.flex0,
		...baseStyles.flexCenteredItems,
		...baseStyles.circle,
		background: '#EAF7FD 0% 0% no-repeat padding-box',
		width: 43,
		height: 43,
		marginRight: 23,
	},
	headerDescription: {
		font: 'normal normal normal 20px/27px Open Sans',
		letterSpacing: 0,
		color: '#7B8794',
	},
	tabsSection: {
		...baseStyles.flex,
	},
	tabs: {
		minWidth: '60%',
		...baseStyles.flex1,
	},
	cancelButton: {
		padding: '8px 30px',
		borderRadius: 4,
		...baseStyles.flex0,
	},
	submitButton: {
		font: 'normal normal 600 18px/24px Open Sans',
		padding: '8px 30px',
		borderRadius: 4,
	},
	popover: {
		pointerEvents: 'none',
	},
	tooltip: {
		color: theme.colors.white[500],
		backgroundColor: theme.colors.grey[500],
		borderRadius: 3,
		padding: '5px 10px',
	},
	paper: {
		backgroundColor: specialColors.transparent,
		paddingTop: '1vh',
		boxShadow: 'none',
	},
}))

const formSchema = yup.object().shape({
	name: yup.string().required(),
	description: yup.string().required(),
	photoUrl: yup.string().required(),
	type: yup.string().required(),
	categories: yup.array(yup.number()).required(),
	superAdminUserId: yup.number().label('Super admin email').required(),
	rolePrimaryLeaderUserId: yup.number().label('Primary leader email').required(),
	rolePrimaryContactAffairsUserId: yup.number().label('Primary contact student affairs email').required(),
	rolePrimaryContactFinanceUserId: yup.number().label('Primary contact finance email').required(),
	rolePrimaryContactPublicUserId: yup.number().nullable().label('Primary contact public email').required(),
	...YUP_URLS_SCHEMA,
})

const CreateOrganizationPage = () => {
	const classes = useStyles()
	const { push } = useHistory()
	const [isTooltipOpen, setIsTooltipOpen] = useState(false)
	const [submitting, setSubmitting] = useState(false)
	const currentUserRole = useSelector(getUserRole)

	const {
		control,
		getValues,
		formState: { errors, isValid, isDirty },
	} = useForm({
		resolver: yupResolver(formSchema),
		mode: 'onChange',
		reValidateMode: 'onChange',
		criteriaMode: 'firstError',
		shouldFocusError: true,
		defaultValues: {
			name: '',
			description: '',
			photoUrl: '',
			categories: [],
			type: '',
			superAdminUserId: '',
			rolePrimaryLeaderUserId: '',
			rolePrimaryContactAffairsUserId: '',
			rolePrimaryContactFinanceUserId: '',
			rolePrimaryContactPublicUserId: '',
			[URL_TYPES.WEBSITE]: '',
			[URL_TYPES.FACEBOOK]: '',
			[URL_TYPES.INSTAGRAM]: '',
			[URL_TYPES.TWITTER]: '',
			[URL_TYPES.LINKEDIN]: '',
			[URL_TYPES.YOUTUBE]: '',
		},
	})

	const dispatch = useDispatch()
	const handleCancel = () => {
		if (isDirty) {
			dispatch(
				showExitConfirmationModal({
					isOpen: true,
					redirectPath: ORGANIZATION_PATHS.ROOT,
				}),
			)
		} else {
			dispatch(push(ORGANIZATION_PATHS.ROOT))
		}
	}

	const submitButtonRef = useRef<HTMLButtonElement>(null)

	const stateControl = { control, isDirty, isValid, errors, getValues }

	const disableSubmitButton = !isValid || !isDirty || submitting
	const shouldShowTooltip = disableSubmitButton && isTooltipOpen

	const handleOpenTooltip = () => {
		setIsTooltipOpen(true)
	}
	const handleCloseTooltip = () => {
		setIsTooltipOpen(false)
	}
	// @TODO: #inconsistency #handle_errors
	const submitAndSetUpdating = useCallback(async () => {
		if (disableSubmitButton) {
			return
		}

		const values = getValues()

		setSubmitting(true)
		// TODO - change rolerole on backend, so that we can remove rolerole as a word
		// on cspell. Should be camelCase
		try {
			const orgName = values.name
			const {
				data: { id },
			} = await createOrganization({
				name: orgName,
				description: values.description,
				categories: values.categories,
				type: values.type,
				superAdminUserId: values.superAdminUserId,
				rolerolePrimaryLeaderUserId: values.rolePrimaryLeaderUserId,
				rolePrimaryContactAffairsEmail: values.rolePrimaryContactAffairsUserId,
				rolerolePrimaryContactFinanceUserId: values.rolePrimaryContactFinanceUserId,
				rolerolePrimaryContactPublicUserId: values.rolePrimaryContactPublicUserId,
				websiteUrl: values[URL_TYPES.WEBSITE],
				linkedinUrl: values[URL_TYPES.LINKEDIN],
				instagramUrl: values[URL_TYPES.INSTAGRAM],
				twitterUrl: values[URL_TYPES.TWITTER],
				facebookUrl: values[URL_TYPES.FACEBOOK],
				youtubeUrl: values[URL_TYPES.YOUTUBE],
				photoUrl: values.photoUrl,
			})

			push(mainPaths.ORGANIZATIONS)

			dispatch(
				enqueueSnackbar(
					creationSuccessToast('Organization', 'Your organization has successfully been submitted!', (messageId) => {
						dispatch(closeSnackbar({ key: messageId }))
						dispatch(push(ORGANIZATION_PATHS.SINGLE(id)))
					}),
				),
			)
		} catch (err) {
			// @TODO: ask again for moving this to thunk
			console.log(err)
		} finally {
			setSubmitting(false)
		}
	}, [getValues, disableSubmitButton, push, dispatch])

	return (
		<MiddlePaneLayout>
			<div className={classes.header}>
				<div className={classes.flexCenteredItemsY}>
					<div className={classes.headerIcon}>
						<GroupTwoTone color={MaterialUiColor.PRIMARY} />
					</div>
					<Typography className={classes.headerTitle} variant="h1">
						Add an Organization
					</Typography>
					<ColorButton color={ButtonColors.LIGHT_RED} className={classes.cancelButton} startIcon={<Close />} onClick={handleCancel}>
						Cancel
					</ColorButton>
				</div>

				<p className={classes.headerDescription}>
					{isAdmin(currentUserRole)
						? `Once you submit this organization, it will be immediately added to your campus’ database
					and posted on the Engagement App.`
						: `Once you submit your registration, it will go to your Campus Admin for approval. Once
					approved, your organization will appear on the Engagement App.`}
				</p>
			</div>

			<BasicInformationSection
				{...{
					stateControl,
				}}
			/>
			<RolesSection
				{...{
					stateControl,
				}}
			/>
			<SocialMediaSection
				{...{
					stateControl,
				}}
			/>
			<OrganizationPhotoSection {...{ stateControl }} />

			<div className={classes.flexCenteredItems}>
				{submitButtonRef?.current && (
					<Popover
						className={classes.popover}
						classes={{
							paper: classes.paper,
						}}
						open={shouldShowTooltip}
						anchorEl={submitButtonRef?.current}
						anchorOrigin={{
							vertical: 'bottom',
							horizontal: 'center',
						}}
						transformOrigin={{
							vertical: 'top',
							horizontal: 'center',
						}}
						disableRestoreFocus
					>
						<Typography className={classes.tooltip}>Complete required fields</Typography>
					</Popover>
				)}
				<ColorButton
					className={classes.submitButton}
					type={'button'}
					disabled={disableSubmitButton}
					ref={submitButtonRef}
					onMouseEnter={handleOpenTooltip}
					onMouseLeave={handleCloseTooltip}
					component={disableSubmitButton ? 'div' : undefined}
					onClick={submitAndSetUpdating}
				>
					{submitting ? 'Submitting' : 'Submit'}
				</ColorButton>
			</div>
		</MiddlePaneLayout>
	)
}

export default CreateOrganizationPage
