import { useCallback, useEffect, useMemo, useState } from 'react'
import { useDispatch } from 'react-redux'

import { RootState } from '@/store'
import { AsyncThunk } from '@reduxjs/toolkit'

interface UseDeleteConfirmation {
	request: AsyncThunk<
		any,
		{
			id: string
			cb?: () => void
		},
		{
			state: RootState
		}
	>
	entity: string
	deleteBtnLabelPrefix?: string
	id?: string
	cb?: () => void
}

export const useDeleteConfirmation = ({ request, entity, deleteBtnLabelPrefix = 'Delete', id, cb }: UseDeleteConfirmation) => {
	const [confirmationRequired, setConfirmationRequired] = useState(false)
	const [currentEntityId, setCurrentEntityId] = useState(id)
	const dispatch = useDispatch()
	const handleConfirm = useCallback(
		(id) => {
			dispatch(request({ id: currentEntityId, cb }))
			setConfirmationRequired(false)
		},
		[dispatch, currentEntityId, cb, request],
	)
	const handleClose = useCallback(() => {
		setConfirmationRequired(false)
	}, [])

	useEffect(() => {
		setCurrentEntityId(id)
	}, [id])

	const renderButtons = useMemo(
		() => [
			{
				label: `${deleteBtnLabelPrefix} ${entity}`,
				onClick: handleConfirm,
				color: '#FA1564',
			},
			{
				label: `Cancel`,
				onClick: handleClose,
				color: 'inherit',
			},
		],
		[deleteBtnLabelPrefix, entity, handleConfirm, handleClose],
	)

	return {
		confirmationRequired,
		setConfirmationRequired,
		renderButtons,
		handleClose,
		handleConfirm,
		setCurrentEntityId,
	}
}
