import http from '@/api/http/requestHttp'
import { PSU_SCHOOL_ID } from '@/constants/configuration'

import { AnnouncementDto, AnnouncementListParams, AnnouncementParams, AnnouncementResultsDto } from './types'

const BASE_URL = `${PSU_SCHOOL_ID}/oms/announcements`

export const createAnnouncement = async (data: AnnouncementParams) => {
	return await http.post<AnnouncementDto>(BASE_URL, data)
}

export const getAnnouncements = async (params: AnnouncementListParams) => {
	return await http.get<AnnouncementResultsDto>(BASE_URL, { params })
}

export const getAnnouncementById = async (id: string) => {
	const url = `${BASE_URL}/${id}`
	return await http.get<AnnouncementDto>(url)
}

export const updateAnnouncementById = async (id: string, data: AnnouncementParams) => {
	const url = `${BASE_URL}/${id}`
	return await http.patch<AnnouncementDto>(url, data)
}

export const deleteAnnouncement = async (id: string) => {
	const url = `${BASE_URL}/${id}`
	return await http.delete(url)
}
