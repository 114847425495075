import classNames from 'classnames'

import { URL_ICONS_MAP, URL_TYPES } from '@/constants/validations'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Link } from '@mui/material'
import { makeStyles } from '@mui/styles'

const useStyles = makeStyles((theme) => ({
	urlLink: {
		display: 'inline-flex',
		alignItems: 'center',
		justifyContent: 'center',
		width: 30,
		height: 30,
		borderRadius: '50%',
		backgroundColor: theme.colors.black[500],
		marginRight: theme.spacing(1),
	},
}))

export type IPostLinksProps = {
	linksData: any
	linkClass?: string
}

const PostLinks: React.FC<IPostLinksProps> = ({ linksData, linkClass }) => {
	const classes = useStyles()
	const links = Object.keys(URL_TYPES)
		.filter((key) => {
			return linksData[`${URL_TYPES[key]}Url`] ? true : false
		})
		.map((key) => {
			const fieldName = `${URL_TYPES[key]}Url`
			return (
				<Link target="_blank" className={classNames(classes.urlLink, linkClass)} href={linksData[fieldName]} key={key}>
					<FontAwesomeIcon icon={URL_ICONS_MAP[URL_TYPES[key]]} color="#ffffff" size="sm" />
				</Link>
			)
		})
	return <>{links}</>
}

export { PostLinks }
