import http from '@/api/http/requestHttp'
import * as config from '@/constants/configuration'
import { EntityListParams } from '@/types'

import { EventCategoryDto, EventDto, EventInfo, EventsDto, EventStatuses } from './types'

export const getListCategories = async () => {
	const url = `${config.PSU_SCHOOL_ID}/events/listCategories`

	return http.get<EventCategoryDto>(url)
}

export const postNewEvent = async (eventInfo: EventInfo) => {
	const url = `${config.PSU_SCHOOL_ID}/oms/events`

	return http.post(url, eventInfo)
}
export const patchEvent = async (userId: number, eventInfo: EventInfo) => {
	const url = `${config.PSU_SCHOOL_ID}/oms/events/${eventInfo.id}`

	return http.patch(url, eventInfo)
}
export const deleteEvent = async (userId: number, eventId: string) => {
	const url = `${config.PSU_SCHOOL_ID}/oms/events/${eventId}`

	return http.delete(url)
}

export const getEvents = async (params: EntityListParams) => {
	const url = `${config.PSU_SCHOOL_ID}/oms/events`
	return http.get<EventsDto>(url, { params })
}

export const getEventById = async (id: string) => {
	const url = `${config.PSU_SCHOOL_ID}/oms/events/${id}`

	return http.get<EventDto>(url)
}

export const updateEventStatus = async (id: string, status: EventStatuses) => {
	const url = `${config.PSU_SCHOOL_ID}/oms/events/${id}/status`
	return http.patch(url, {
		status,
	})
}
export const getEventAttendees = async (id: string) => {
	const url = `${config.PSU_SCHOOL_ID}/events/${id}/attendees`

	return http.get(url)
}

export const getEventVolunteerShifts = async (id: string) => {
	const url = `${config.PSU_SCHOOL_ID}/events/${id}/volunteerShifts`

	return http.get(url)
}

export const getEventVolunteers = async (id: string) => {
	const url = `${config.PSU_SCHOOL_ID}/events/${id}/volunteers`

	return http.get(url)
}
