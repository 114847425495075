import classNames from 'classnames'
import React from 'react'
import { useHistory } from 'react-router-dom'

import { getFormattedCurrentPath } from '@/utils/urlHandlers'
import { Button, Typography } from '@mui/material'
import { Theme } from '@mui/material/styles'
import makeStyles from '@mui/styles/makeStyles'
import { specialColors } from '@navengage/sen-shared-assets'

import { styles } from '../../constants'

interface StyleProps {
	active: boolean
}

const useStyles = makeStyles<Theme, StyleProps>((theme) => ({
	root: ({ active }) => ({
		minHeight: 55,
		width: styles.FILL_AVAILABLE_WIDTH,
		margin: 5,
		padding: '0 15px',
		backgroundColor: active ? theme.colors.Primary[100] : specialColors.transparent,
		borderRadius: 5,
		border: 'none',
		color: active ? theme.colors.Primary[700] : theme.colors.black[500],
		display: 'flex',
		justifyContent: 'flex-start',
		fontSize: theme.typography.body1.fontSize,
		'&:hover': {
			color: theme.colors.Primary[700],
			backgroundColor: active ? theme.colors.Primary[100] : specialColors.transparent,
		},
		'& p': {
			fontWeight: active ? theme.typography.fontWeightMedium : theme.typography.fontWeightRegular,
			//@TODO: move hard coded color to the theme
			//
			color: active ? theme.colors.Primary[700] : '#616b75', // Accessibility change, fix me @Coll
		},
	}),
	contentContainer: {
		display: 'flex',
		flexDirection: 'row',
		textTransform: 'none',
		textAlign: 'left',
		width: styles.FILL_AVAILABLE_WIDTH,
		alignItems: 'center',
	},
	icon: {
		alignItems: 'center',
		justifyContent: 'center',
		display: 'flex',
		height: 'fit-content',
		width: 66,
		minWidth: 66,
	},
	textContainer: {
		alignSelf: 'center',
	},
}))

export interface SideBarNavigation {
	label: string
	icon: React.ReactNode | null
	path: string
}

export interface NavigationButtonClassNames {
	root?: string
	contentContainer?: string
	icon?: string
	label?: string
}

export interface NavigationButtonProps {
	classnames?: NavigationButtonClassNames
	navigation: SideBarNavigation
	onClick?: (path: string) => void
	hideLabel?: boolean
}

const NavigationButton = ({
	classnames = {
		root: '',
		contentContainer: '',
		icon: '',
		label: '',
	},
	navigation: { icon, label, path },
	onClick = () => {},
	hideLabel = false,
}: NavigationButtonProps) => {
	const history = useHistory()
	const currentPathname = getFormattedCurrentPath(history.location.pathname)
	const active = currentPathname.includes(path)
	const classes = useStyles({ active })

	const handleOnClick = () => onClick(path)

	return (
		<Button className={classNames(classnames.root, classes.root)} onClick={handleOnClick}>
			<div className={classNames(classnames.contentContainer, classes.contentContainer)}>
				<div className={classNames(classnames.icon, classes.icon)}>{icon}</div>
				{!hideLabel && (
					<div className={classes.textContainer}>
						<Typography className={classnames.label}>{label}</Typography>
					</div>
				)}
			</div>
		</Button>
	)
}

export default NavigationButton
