import React from 'react'
import { useSelector } from 'react-redux'
import { Route, Switch } from 'react-router-dom'

import ContentContainer from '@/components/ContentContainer'
import { Header } from '@/components/EntityManager/Header'
import RouterTabs from '@/components/RouterTabs'
import { ROLES } from '@/constants'
import useSubHeader from '@/hooks/useSubHeader'
import { getUserRole } from '@/store/auth'
import { SubHeaderList } from '@/types'
import { isAdmin } from '@/utils/authHandlers'
import { engagementAppLink, LINK_SCOPES } from '@/utils/linksHelper'
import { Link, Typography } from '@mui/material'

import { useHistory } from 'react-router-dom'

// import { CreateEventDialog } from '../../components/CreateEventDialog'
import { EVENT_PATHS } from '../../constants'
import { getEventsListRequest } from '../../eventsSlice'
import AllEvents from './AllEvents'
import Approved from './Approved'
import Denied from './Denied'
import New from './New'

const TABS = [
	{
		label: 'New',
		value: EVENT_PATHS.NEW_LIST,
		component: New,
	},
	{
		label: 'Approved',
		value: EVENT_PATHS.APPROVED_LIST,
		component: Approved,
	},
	{
		label: 'Denied',
		value: EVENT_PATHS.DENIED_LIST,
		component: Denied,
	},
]

const SUB_HEADERS: SubHeaderList = [
	{
		text: 'See events that you’ve submitted',
		roles: [ROLES.STUDENT, ROLES.STUDENT_ADMIN],
	},
	{
		text: 'See events submitted by students that are awaiting approval',
		roles: [ROLES.CAMPUS_ADMIN],
		path: EVENT_PATHS.NEW_LIST,
	},
	{
		text: (
			<Typography>
				Approved events are public on the Engagement App.{' '}
				<Link underline="none" href={engagementAppLink(LINK_SCOPES.EVENT)}>
					Go to Engagement App
				</Link>
			</Typography>
		),
		roles: [ROLES.CAMPUS_ADMIN],
		path: EVENT_PATHS.APPROVED_LIST,
	},
	{
		text: 'These event requests have been denied by a Campus Admin',
		roles: [ROLES.CAMPUS_ADMIN],
		path: EVENT_PATHS.DENIED_LIST,
	},
]

const List = React.memo(() => {
	// const [dialogOpened, setDialogOpened] = useState<boolean>(false)
	const currentUserRole = useSelector(getUserRole)
	const subHeader = useSubHeader(SUB_HEADERS)

	// const closeDialog = useCallback(() => {
	// 	setDialogOpened(false)
	// }, [])
	// const handleOpenCreateFormPage = useCallback(() => {
	// 	setDialogOpened(true)
	// }, []) //temporary removal of popup

	const history = useHistory()
	const handleClickEvent = () => {
		history.push(EVENT_PATHS.EVENTS_CREATE)
	}

	return (
		<ContentContainer>
			<Header
				title={'Events'}
				handlePrimaryBtn={handleClickEvent}
				primaryBtnText={'Create Event'}
				searchPlaceholder={'Search events'}
				searchRequest={getEventsListRequest}
				subHeader={subHeader.text}
			/>
			{isAdmin(currentUserRole) && <RouterTabs tabs={TABS} />}
			{isAdmin(currentUserRole) && (
				<Switch>
					{TABS.map((route) => (
						<Route key={route.label} exact path={route.value} component={route.component} />
					))}
				</Switch>
			)}
			{!isAdmin(currentUserRole) && <AllEvents />}
			{/* <CreateEventDialog opened={dialogOpened} onClose={closeDialog} /> */}
		</ContentContainer>
	)
})

export default List
