import classNames from 'classnames'

import LogoImage from '@/components/icons/png/PennStateLogo.png'
import { specialColors } from '@navengage/sen-shared-assets'
import { makeStyles } from '@mui/styles'
import { Card as MaterialCard, CardContent } from '@mui/material'

const useStyles = makeStyles((theme) => ({
	card: ({ variant }: any) => ({
		borderRadius: 15,
		background: variant === 'primary' ? theme.colors.white[500] : specialColors.transparent,
		border: variant === 'primary' ? 'none' : `solid 1px ${theme.colors.white[500]}`,
		color: variant === 'primary' ? theme.colors.black[500] : theme.colors.white[500],
	}),
	cardContent: {
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
		margin: '0 2vw',
		[theme.breakpoints.down('xs')]: {
			margin: 0,
		},
	},
	img: {
		height: '7vh',
		paddingBottom: '2vh',
	},
}))

interface CardProps {
	children: React.ReactNode
	className?: string
	cardContentClass?: string
	variant?: 'primary' | 'secondary'
	showIcon?: boolean
}

const Card = ({ children, className = '', cardContentClass = '', variant = 'primary', showIcon = true }: CardProps) => {
	const classes = useStyles({ variant })

	return (
		<MaterialCard className={classNames(classes.card, className)}>
			<CardContent className={classNames(classes.cardContent, cardContentClass)}>
				{variant === 'primary' && showIcon && <img className={classes.img} src={LogoImage} alt="pennstate logo" />}
				{children}
			</CardContent>
		</MaterialCard>
	)
}

export default Card
