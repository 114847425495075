import classNames from 'classnames'
import React, { forwardRef, useCallback, useMemo } from 'react'

import { Add, Check, ExpandMore } from '@mui/icons-material'
import { MenuItem, Select as MaterialSelect } from '@mui/material'
import { Theme } from '@mui/material/styles'
import makeStyles from '@mui/styles/makeStyles'

interface StyleProps {
	hasStartIcon: boolean
}

const useStyles = makeStyles<Theme, StyleProps>((theme) => ({
	paper: {
		width: 0,
	},
	menuItem: {
		whiteSpace: 'pre-wrap',
	},
	selectLabel: ({ hasStartIcon }) => ({
		marginLeft: hasStartIcon ? 5 : 0,
		whiteSpace: 'nowrap',
		overflow: 'hidden',
		textOverflow: 'ellipsis',
		display: 'block',
	}),
	menuItemLeft: {
		display: 'flex',
		alignItems: 'center',
	},
	menuItemIcon: {
		display: 'flex',
		width: theme.spacing(4),
		alignItems: 'center',
		marginRight: theme.spacing(2),
	},
}))

const useLabelStyles = makeStyles((theme) => ({
	root: {
		display: 'flex',
		alignItems: 'center',
	},
}))

export type SelectValue = number | string | null

export interface SelectOptionType {
	label: string
	value: SelectValue
	disabled?: boolean
	icon?: any
}

export interface SelectClasses {
	input?: string
	select?: string
	selectOpenIcon?: string
	selectLabel?: string
	selectMenuPaper?: string
	selectMenuList?: string
	selectMenuListContainer?: string
	menuItem?: string
	menuItemIcon?: string
}

export interface LabelProps {
	label?: string
	startIcon?: React.ReactNode
	labelClassName?: string
}

export interface SelectProps extends LabelProps {
	classnames?: SelectClasses
	value: SelectValue | SelectValue[]
	placeholder?: string
	onChange: (v: SelectValue | SelectValue[]) => void
	options: SelectOptionType[]
	multiple?: boolean
	disabled?: boolean
	onToggleSelect?: (state: boolean) => void
	materialSelectOptions?: Record<string, any>
}

export const isSelectHasValue = (multiple: boolean, options: SelectOptionType[], value: SelectValue | SelectValue[]) =>
	!multiple ? !!value || (options.some((o) => o.value === null) && value === null) : Array.isArray(value) && !!value.length

const parseValue = (v: string) => {
	try {
		return JSON.parse(v)
	} catch (e) {
		return v
	}
}

const Label = ({ label, startIcon, labelClassName }: LabelProps) => {
	const classes = useLabelStyles()

	return (
		<div className={classes.root}>
			{startIcon}
			<div className={labelClassName}>{label}</div>
		</div>
	)
}

const Select = forwardRef<HTMLDivElement, SelectProps>(
	(
		{
			classnames = {
				input: '',
				select: '',
				selectOpenIcon: '',
				selectLabel: '',
				selectMenuPaper: '',
				selectMenuList: '',
				selectMenuListContainer: '',
				menuItem: '',
				menuItemIcon: '',
			},
			label,
			value,
			placeholder = '',
			onChange,
			options = [],
			multiple = false,
			disabled = false,
			onToggleSelect,
			startIcon,
			materialSelectOptions,
		},
		ref,
	) => {
		const classes = useStyles({ hasStartIcon: !!startIcon })

		const hasValue = isSelectHasValue(multiple, options, value)

		const onOpenSelect = useCallback(() => (onToggleSelect ? onToggleSelect(true) : null), [onToggleSelect])
		const onCloseSelect = useCallback(() => (onToggleSelect ? onToggleSelect(false) : null), [onToggleSelect])

		const handleChange = useCallback(
			({ target: { value } }: any) => {
				if (!multiple) {
					onChange(parseValue(value))
				} else if (Array.isArray(value)) {
					onChange(value.filter((v) => v !== placeholder).map((v) => parseValue(v)))
				}
			},
			[multiple, onChange, placeholder],
		)

		const stringValue = useMemo(
			() =>
				multiple && Array.isArray(value)
					? hasValue
						? value.map((v) => JSON.stringify(v))
						: [placeholder]
					: hasValue
					? JSON.stringify(value)
					: placeholder,
			[hasValue, multiple, placeholder, value],
		)

		return (
			<MaterialSelect
				ref={ref}
				className={classnames.input}
				classes={{
					select: classnames.select,
				}}
				onOpen={onOpenSelect}
				onClose={onCloseSelect}
				disabled={disabled}
				value={stringValue}
				onChange={handleChange}
				IconComponent={(props) => <ExpandMore {...props} className={classNames(classnames.selectOpenIcon, props.className)} />}
				variant="outlined"
				MenuProps={{
					classes: {
						paper: classNames(classnames.selectMenuPaper, classes.paper),
						list: classnames.selectMenuList,
					},
					anchorOrigin: {
						vertical: 'bottom',
						horizontal: 'left',
					},
					transformOrigin: {
						vertical: 'top',
						horizontal: 'left',
					},
					className: classnames.selectMenuListContainer,
				}}
				renderValue={(v: any) => {
					if (multiple && Array.isArray(v)) {
						const values = v.filter((v) => v !== placeholder)

						return (
							<Label
								labelClassName={classNames(classnames.selectLabel, classes.selectLabel)}
								startIcon={startIcon}
								label={label ? `${label}: ${placeholder} (${values.length})` : `${placeholder} (${values.length})`}
							/>
						)
					}

					const option = hasValue ? options.find(({ value }) => value === parseValue(v)) : null

					if (option)
						return (
							<Label
								labelClassName={classNames(classnames.selectLabel, classes.selectLabel)}
								startIcon={startIcon}
								label={label ? `${label}: ${option.label}` : option.label}
							/>
						)
					return (
						<Label
							labelClassName={classNames(classnames.selectLabel, classes.selectLabel)}
							startIcon={startIcon}
							label={label ? `${label}: ${placeholder}` : placeholder}
						/>
					)
				}}
				multiple={multiple}
				{...materialSelectOptions}
			>
				{options.map(({ label, value: itemValue, disabled, icon }) => {
					const selected = multiple && Array.isArray(value) ? value.includes(itemValue) : itemValue === value

					return (
						<MenuItem
							key={`${label}-${itemValue}`}
							value={JSON.stringify(itemValue)}
							className={classNames(classnames.menuItem, classes.menuItem)}
							disabled={disabled}
						>
							<div className={classes.menuItemLeft}>
								{icon && <div className={classes.menuItemIcon}>{icon}</div>}
								{label}
							</div>
							{selected && <Check className={classnames.menuItemIcon} />}
							{!selected && multiple && <Add className={classnames.menuItemIcon} />}
						</MenuItem>
					)
				})}
			</MaterialSelect>
		)
	},
)

export default Select
