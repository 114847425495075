import React from 'react'
import { useSelector } from 'react-redux'

import { OptionButton, styles } from '@/components/shared'
import usePopper from '@/hooks/usePopper'
import { getUserInfo } from '@/store/auth'
import { getFullName } from '@/utils/common'
import ExpandLessIcon from '@mui/icons-material/ExpandLess'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import { Button, Typography } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'

import LazyLoadProfileImage from '../LazyLoadProfileImage'
import DropdownContainer from './DropdownContainer'
import HeaderPopper from './HeaderPopper'
import { isFunction } from 'lodash'

const useStyles = makeStyles((theme) => ({
	root: {
		width: styles.FILL_AVAILABLE_WIDTH,
		display: 'flex',
		flexDirection: 'column',
	},
	optionsContainer: ({ anchorOffsetWidth }: any) => ({
		minWidth: anchorOffsetWidth ? `calc(${anchorOffsetWidth}px - 20px)` : 0,
		width: 376,
		marginRight: theme.spacing(1.5),
		padding: '21px 0',
		marginTop: 30,
	}),
	paper: {
		zIndex: 1300,
		maxHeight: '40vh',
	},
	selectButton: {
		width: styles.FILL_AVAILABLE_WIDTH,
		color: theme.colors.black[500],
		backgroundColor: theme.colors.grey[100],
		border: 'none',
		padding: '5px 10px',
		textTransform: 'none',
		boxShadow: 'none',
		'& >span': {
			justifyContent: 'space-between',
		},
		...theme.typography.body1,
		[theme.breakpoints.down('sm')]: {
			...theme.typography.subtitle1,
			padding: 0,
			minWidth: 100,
			minHeight: 36,
		},
	},
	profileTabIcon: {
		width: 40,
		height: 40,
		[theme.breakpoints.down('sm')]: {
			width: 30,
			height: 30,
		},
	},
	userName: {
		margin: '0 2vw',
		fontWeight: 450,
	},
	expandIcon: {
		color: theme.colors.grey[500],
		marginRight: 10,
		width: 26,
		height: 26,
	},
	optionButton: {
		fontWeight: theme.typography.fontWeightMedium,
		padding: '18px 39px',
		borderRadius: 0,
		justifyContent: 'flex-start',
		'&>span': {
			justifyContent: 'flex-start',
		},
	},
}))
export interface SelectOptionType {
	label: string
	value: number | string
	checked?: boolean
}

interface ProfileExpandButtonProps {
	options: {
		label: string
		onClick: (e?: React.SyntheticEvent<HTMLButtonElement>) => void
	}[]
}

const ProfileExpandButton = ({ options = [] }: ProfileExpandButtonProps) => {
	const { handleToggle, anchorRef, popperRef, open, handleClose } = usePopper()

	const userInfo = useSelector(getUserInfo)

	const classes = useStyles({
		anchorOffsetWidth: anchorRef.current?.offsetWidth,
	})
	const userName = getFullName(userInfo.firstName, userInfo.lastName)
	const closeAndHandleClick = (onClick: (e: unknown) => void) => (e: any) => {
		handleClose(e)

		if (onClick && isFunction(onClick)) onClick()
	}
	return (
		<div className={classes.root}>
			<Button
				ref={anchorRef}
				onClick={handleToggle}
				className={classes.selectButton}
				endIcon={open ? <ExpandLessIcon className={classes.expandIcon} /> : <ExpandMoreIcon className={classes.expandIcon} />}
			>
				<LazyLoadProfileImage
					className={classes.profileTabIcon}
					externalUserId={userInfo.externalId}
					photoUrl={userInfo.photoUrl}
					borderRadius={7}
				/>
				<Typography className={classes.userName}>{userName}</Typography>
			</Button>
			<HeaderPopper ref={popperRef} className={classes.paper} open={open} anchorEl={anchorRef.current} role={undefined}>
				<DropdownContainer className={classes.optionsContainer}>
					{options.map(({ label, onClick }) => (
						<OptionButton className={classes.optionButton} key={label} onClick={closeAndHandleClick(onClick)}>
							{label}
						</OptionButton>
					))}
				</DropdownContainer>
			</HeaderPopper>
		</div>
	)
}

export default ProfileExpandButton
