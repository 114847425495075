import { push } from 'connected-react-router'
import { useCallback, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { ActionModal } from '@/components/ActionModal'
import { DataTable } from '@/components/EntityManager/DataTable'
import { getFullDateFormatted } from '@/utils/dateTime'

import { EVENT_PATHS } from '../../constants'
import { getEventsListRequest, resetList, selectEventsList } from '../../eventsSlice'
import { useEventColumns } from '../../hooks'
import { EventStatuses } from '../../types'

const Approved = () => {
	const eventsList = useSelector(selectEventsList)
	const dispatch = useDispatch()

	const deletionCallback = useCallback(() => {
		dispatch(push(EVENT_PATHS.APPROVED_LIST))
	}, [dispatch])

	useEffect(() => {
		dispatch(resetList())
	}, [dispatch])

	const { columns, confirmationRequired, renderButtons, handleClose } = useEventColumns(
		[
			{
				field: 'approvedAt',
				headerName: 'Approved At',
				valueGetter: (params: any) => (params.row.approvedAt ? getFullDateFormatted(params.row.approvedAt) : 'N/A'),
				filterable: false,
				flex: 1,
			},
		],
		deletionCallback,
	)

	return (
		<>
			<DataTable
				defaultFilters={{
					items: [
						{
							columnField: 'status',
							operatorValue: 'is',
							value: EventStatuses.Approved,
						},
					],
				}}
				data={eventsList}
				request={getEventsListRequest}
				columns={columns}
			/>
			<ActionModal
				handleClose={handleClose}
				open={confirmationRequired}
				renderButtons={renderButtons}
				title="Delete Event"
				description="Anyone who has RSVP’d will be notified that this event has been deleted."
			/>
		</>
	)
}

export default Approved
