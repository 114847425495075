import classNames from 'classnames'
import React, { forwardRef } from 'react'

import { TextField as MaterialTextField, TextFieldProps } from '@mui/material'
import { Theme } from '@mui/material/styles'
import makeStyles from '@mui/styles/makeStyles'
import { getHoverColor } from '@navengage/sen-shared-assets'

interface StyleProps {
	error?: boolean
	disabled?: boolean
	placeholderColor?: string
}

const useStyles = makeStyles<Theme, StyleProps>((theme) => ({
	textInput: ({ error }: any) => ({
		width: '95%',
		'& .MuiInput-underline:after': {
			borderBottom: `solid 1px ${error ? theme.colors.red[500] : theme.colors.grey[200]}`,
		},
		'& .MuiInput-underline:before': {
			borderBottom: `solid 1px ${error ? theme.colors.red[500] : theme.colors.grey[200]}`,
		},
		'& .MuiInput-underline:hover:not(.Mui-disabled):before': {
			borderBottom: `solid 1px ${getHoverColor(theme.colors.Primary)}`,
		},
	}),
	inputRoot: ({ placeholderColor, error }) => ({
		caretColor: getHoverColor(theme.colors.Primary),
		caretShape: 'bar',
		'&:not(.Mui-disabled)::placeholder': {
			color: error ? theme.colors.red[400] : placeholderColor ?? theme.colors.grey[500],
			opacity: 1,
		},
		'&.Mui-disabled::placeholder': {
			color: theme.colors.grey[400],
			opacity: 0.7,
		},
		'&:focus::placeholder': {
			color: theme.colors.grey[400],
			opacity: 0.7,
		},
	}),
}))

const TextField = forwardRef<HTMLDivElement | null, TextFieldProps & StyleProps>(({ placeholderColor, ...rest }, ref) => {
	const classes = useStyles({ placeholderColor, error: !!rest.error })

	return (
		<MaterialTextField
			ref={ref}
			{...rest}
			className={classNames(classes.textInput, rest.className)}
			InputProps={{
				...rest.InputProps,
				classes: { input: classes.inputRoot },
			}}
		/>
	)
})

export default TextField
