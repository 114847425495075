import { v4 as uuidv4 } from 'uuid'
import makeStyles from '@mui/styles/makeStyles'
import { styles, TextButton } from '@/components/shared'
import { AnswerDropdownOption } from '../../types'

const useStyles = makeStyles((theme) => ({
	root: {
		width: styles.FILL_AVAILABLE_WIDTH,
		margin: 10,
		display: 'flex',
		alignItems: 'center',
	},
}))

interface AddOptionProps {
	onAddNewAnswer: (answer: AnswerDropdownOption) => void
}

const AddOption = ({ onAddNewAnswer }: AddOptionProps) => {
	const classes = useStyles()

	const handelAddNewOption = (answer: AnswerDropdownOption) => () => onAddNewAnswer(answer)

	return (
		<div className={classes.root}>
			<TextButton
				onClick={handelAddNewOption({
					value: uuidv4(),
					label: '',
				})}
			>
				Add option
			</TextButton>
		</div>
	)
}

export default AddOption
