import React, { Fragment, useCallback } from 'react'
import { createPortal } from 'react-dom'
import { useHistory } from 'react-router-dom'

export type IRouterModalProps = {
	onClose?: () => void
	backUrl?: string
	children: (handleCLose: () => void) => React.ReactNode
}

const RouterModal: React.FC<IRouterModalProps> = ({ onClose, children, backUrl }) => {
	const history = useHistory()
	const modalContainer = document.getElementById('router-modal')
	const handleClose = useCallback(() => {
		if (backUrl) {
			history.push(backUrl)
		} else {
			history.goBack()
		}

		if (onClose) {
			onClose()
		}
	}, [history, onClose, backUrl])
	if (modalContainer) {
		return createPortal(<div>{children(handleClose)}</div>, modalContainer)
	} else {
		console.warn(`Modal container doesn't exist width id 'router-modal'`)
		return <Fragment />
	}
}

export { RouterModal }
