import getUserInfo from '@/api/http/user/getUserInfo'
import getUserProfileImage from '@/api/http/user/getUserProfileImage'
import signIn from '@/api/http/user/signIn'
import storeSignUp from '@/api/http/user/storeSignUp'
import getUserOrganizationPermissions from '@/api/http/user/getUserOrganizationPermissions'

const user = {
	getUserInfo,
	getUserProfileImage,
	signIn,
	storeSignUp,
	getUserOrganizationPermissions,
}

export default user
