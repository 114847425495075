import { Fragment, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { STYLES } from '@/constants'

import { Toggle } from '@/components/shared'
import { styled, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from '@mui/material'
import { makeStyles } from '@mui/styles'
import SettingsPageHeader from '../components/SettingsPageHeader'
import {
	loadUserNotificationPreferencesRequest,
	selectAvailableTransportOptions,
	selectIsNotificationPreferencesLoading,
	selectNotificationPreferences,
	selectPreferenceMap,
	toggleNotificationPreferenceRequest,
} from '../settingsSlice'

import MiddlePaneLoader from '@/components/MiddlePaneLoader'

const NoBorderCell = styled(TableCell)(() => ({
	borderBottom: 'none',
	padding: 0,
}))

const useStyles = makeStyles((theme) => ({
	root: {
		width: STYLES.FILL_AVAILABLE_WIDTH,
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
		[theme.breakpoints.down('sm')]: {
			width: '100vw',
		},
	},
	headings: {
		fontWeight: theme.typography.fontWeightBold,
	},
	preferencesTable: {
		maxWidth: '60%',
	},
	container: {
		height: 90,
		width: STYLES.FILL_AVAILABLE_WIDTH,
	},
	contentContainer: {
		width: STYLES.FILL_AVAILABLE_WIDTH,
		padding: '1.5vw',
	},
	blockTitle: {
		margin: '40px 0 20px 0',
		fontWeight: theme.typography.fontWeightBold,
	},
	subGroup: {
		padding: '10px 0px',
	},
}))

const Notifications = () => {
	const classes = useStyles()
	const dispatch = useDispatch()

	const settings = useSelector(selectNotificationPreferences)
	const isLoading = useSelector(selectIsNotificationPreferencesLoading)
	const transportOptions = useSelector(selectAvailableTransportOptions)
	const preferenceMap = useSelector(selectPreferenceMap)
	const handleChangeToggle = (preferencesId: number) => (e: any) => {
		dispatch(toggleNotificationPreferenceRequest(preferencesId))
	}
	useEffect(() => {
		dispatch(loadUserNotificationPreferencesRequest())
	}, [dispatch])

	console.log(settings)
	return (
		<div className={classes.root}>
			<div className={classes.container}>
				<SettingsPageHeader title="Notifications" />
			</div>
			{settings?.length < 1 && !isLoading ? (
				<Typography className={classes.blockTitle} variant="h3">
					No settings to edit.
				</Typography>
			) : settings?.length && !isLoading ? (
				<TableContainer className={classes.contentContainer}>
					<Table className={classes.preferencesTable}>
						<TableHead>
							<TableRow>
								<NoBorderCell></NoBorderCell>
								{Object.keys(transportOptions).map((optionKey) => (
									<NoBorderCell>
										<Typography className={classes.headings} variant="h3">
											{transportOptions[optionKey].name}
										</Typography>
									</NoBorderCell>
								))}
							</TableRow>
						</TableHead>
						<TableBody>
							{settings.map(({ id, name, subGroups }) => (
								<Fragment key={id}>
									<TableRow>
										<NoBorderCell colSpan={Object.keys(transportOptions).length + 1}>
											<Typography className={classes.blockTitle} variant="h3">
												{name}
											</Typography>
										</NoBorderCell>
									</TableRow>

									{subGroups.map(({ id, name, preferences }) => (
										<TableRow key={id}>
											<NoBorderCell>
												<Typography variant="h3">{name}</Typography>
											</NoBorderCell>
											{preferences.map((id) => {
												const { value } = preferenceMap[id]
												return (
													<NoBorderCell className={classes.subGroup}>
														<Toggle
															value={value}
															onChange={handleChangeToggle(id)}
															labelPosition="end"
															label={{
																checked: 'Turned On',
																unchecked: 'Turned Off',
															}}
														/>
													</NoBorderCell>
												)
											})}
										</TableRow>
									))}
								</Fragment>
							))}
						</TableBody>
					</Table>
				</TableContainer>
			) : (
				<MiddlePaneLoader />
			)}
		</div>
	)
}

export default Notifications
