import { useCallback, useEffect, useMemo } from 'react'
import { useHistory } from 'react-router'

import LazyLoadProfileImage from '@/components/LazyLoadProfileImage'

import { EVENT_PATHS } from '@/features/events/constants'
import { ORGANIZATION_PATHS } from '@/features/organizations/constants'

import { makeStyles } from '@mui/styles'

import NotificationIcon from './NotificationIcon'
import NotificationSkeleton from './NotificationSkeleton'
import { OutlinedButton } from '@/components/shared'
import { NotificationTypes, UserNotification } from '../types'

const useStyles = makeStyles((theme) => ({
	image: {
		minWidth: 47,
		minHeight: 47,
		width: 47,
		height: 47,
		marginRight: 15,
	},
	button: {
		width: '48%',
		marginRight: 10,
	},
}))

interface NotificationProps {
	notification: UserNotification
	compactView?: boolean
	handleClosePopup: () => void
	onViewed?: (notification: UserNotification) => void
}

const notificationDotPosition = {
	width: 10,
	height: 10,
	top: -5,
	right: -5,
}

const Notification = ({ notification, compactView, handleClosePopup, onViewed }: NotificationProps) => {
	const classes = useStyles({ compactView })
	const { push } = useHistory()
	const {
		content,
		createdAt,
		data: { payload, type },
		viewed,
	} = notification
	const handleOpenEventPage = useCallback(
		(id?: string) => {
			push(EVENT_PATHS.VIEW_EVENT(`${id}`))
			handleClosePopup()
		},
		[handleClosePopup, push],
	)

	const handleOpenOrganizationPage = useCallback(
		(id?: string) => {
			push(ORGANIZATION_PATHS.SINGLE(`${id}`))
			handleClosePopup()
		},
		[handleClosePopup, push],
	)

	const { icon, buttons } = useMemo(() => {
		if (type === NotificationTypes.upcomingEvent) {
			const { eventId } = payload

			return {
				icon: (
					<NotificationIcon className={classes.image} borderRadius={5} notificationDot={viewed ? undefined : notificationDotPosition} />
				),
				buttons: (
					<>
						<OutlinedButton className={classes.button} onClick={() => handleOpenEventPage(eventId)}>
							See event
						</OutlinedButton>
					</>
				),
			}
		}

		if (
			type === NotificationTypes.newOrganizationMembershipRequest ||
			type === NotificationTypes.newOrganizationMembershipResponse ||
			type === NotificationTypes.changeOrganizationMembershipRoleRequest ||
			type === NotificationTypes.changeOrganizationMembershipRoleResponse
		) {
			const { organization, sourceUser } = payload

			return {
				icon:
					type === NotificationTypes.newOrganizationMembershipRequest ||
					type === NotificationTypes.changeOrganizationMembershipRoleRequest ? (
						<LazyLoadProfileImage
							externalUserId={sourceUser?.externalId || ''}
							photoUrl={sourceUser?.photoUrl}
							className={classes.image}
							borderRadius={5}
							notificationDot={viewed ? undefined : notificationDotPosition}
						/>
					) : (
						<NotificationIcon
							className={classes.image}
							borderRadius={5}
							notificationDot={viewed ? undefined : notificationDotPosition}
							imageUrl={organization?.photoUrl}
							alt="organization logo"
						/>
					),
				buttons: (
					<>
						<OutlinedButton className={classes.button} onClick={() => handleOpenOrganizationPage(organization?.id)}>
							{type === NotificationTypes.newOrganizationMembershipRequest ||
							type === NotificationTypes.changeOrganizationMembershipRoleRequest
								? 'See request'
								: 'Go to organization'}
						</OutlinedButton>
					</>
				),
			}
		}

		return {
			icon: <NotificationIcon className={classes.image} borderRadius={5} notificationDot={viewed ? undefined : notificationDotPosition} />,
			buttons: null,
		}
	}, [classes.button, classes.image, handleOpenEventPage, handleOpenOrganizationPage, payload, type, viewed])

	useEffect(() => {
		if (notification.id && !notification.viewed) {
			if (onViewed) onViewed(notification)
		}
	}, [notification, onViewed])

	return (
		<NotificationSkeleton content={content} createdAt={createdAt} compactView={compactView} iconComponent={icon} actionButtons={buttons} />
	)
}

export default Notification
