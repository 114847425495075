import { push } from 'connected-react-router'
import { useDispatch, useSelector } from 'react-redux'

import FormBuilderForm from '@/components/Form'
import { FieldDataType, FormBuilderData } from '@/components/Form/FormBuilder/types'
import { PageContentContainer } from '@/components/PageContentContainer'
import { ColorButton, OutlinedButton } from '@/components/shared'
import { PATHS } from '@/constants'
import { showExitConfirmationModal } from '@/store/app'
import { getUserRole } from '@/store/auth'
import { getIsLoadingUploadFormData, saveFormRequest } from '@/store/forms'
import { isAdmin } from '@/utils/authHandlers'
import makeStyles from '@mui/styles/makeStyles'

const useStyles = makeStyles((theme) => ({
	headerButton: {
		minWidth: 130,
		marginLeft: 5,
	},
	outlinedButton: {
		minWidth: 130,
		color: theme.colors.black[500],
		borderColor: theme.colors.grey[300],
		marginLeft: 5,
	},
}))

const CreateForm = () => {
	const classes = useStyles()
	const currentUserRole = useSelector(getUserRole)
	const dispatch = useDispatch()
	const isLoading = useSelector(getIsLoadingUploadFormData)

	const handleSaveForm = (data: FormBuilderData<FieldDataType>, isDraft: boolean, redirect?: string, disabled?: boolean) => () => {
		if (disabled) return

		dispatch(
			saveFormRequest({
				data,
				isDraft,
				redirect,
				isNew: true,
			}),
		)
	}

	const handleClickCancel = (changes: boolean) => () => {
		const redirectPath = isAdmin(currentUserRole) ? PATHS.APP.FORMS_TEMPLATE_LISTS : PATHS.APP.FORMS_LIST
		if (changes) {
			dispatch(
				showExitConfirmationModal({
					isOpen: true,
					redirectPath,
				}),
			)
		} else {
			dispatch(push(redirectPath))
		}
	}

	return (
		<PageContentContainer>
			<FormBuilderForm title="Create Form" disabled={isLoading}>
				{({ getFormData, allActionsDisabled, draftSavingDisabled, publishingDisabled, isDirty }) => {
					return (
						<>
							<OutlinedButton className={classes.outlinedButton} onClick={handleClickCancel(isDirty)} disabled={allActionsDisabled}>
								Cancel
							</OutlinedButton>
							<OutlinedButton
								className={classes.outlinedButton}
								disabled={draftSavingDisabled}
								onClick={handleSaveForm(getFormData(), true, PATHS.APP.FORMS, draftSavingDisabled)}
							>
								Save
							</OutlinedButton>
							<ColorButton
								className={classes.headerButton}
								disabled={publishingDisabled}
								onClick={handleSaveForm(getFormData(), false, PATHS.APP.FORMS, publishingDisabled)}
							>
								Publish
							</ColorButton>
						</>
					)
				}}
			</FormBuilderForm>
		</PageContentContainer>
	)
}

export default CreateForm
