import { RootState } from '@/store'
import { createAsyncThunk, createSelector, createSlice, isAnyOf, PayloadAction } from '@reduxjs/toolkit'
import { getUserNotificationPreferences, patchNotificationPreferences } from './api'
import { DtoNotificationPreferences, DtoNotificationTransport, TSettingsState } from './types'

const FEATURE_NAME = 'SETTINGS'

const initialState: TSettingsState = {
	preferences: {
		notifications: {
			isLoading: false,
		},
	},
}

export const loadUserNotificationPreferencesRequest = createAsyncThunk<
	DtoNotificationPreferences,
	undefined,
	{
		state: RootState
	}
>(`${FEATURE_NAME}/LOAD_USER_NOTIFICATION_PREFERENCES_REQUEST`, async (__, { dispatch, getState, rejectWithValue }) => {
	try {
		const { data } = await getUserNotificationPreferences()
		return data
	} catch (e) {
		return rejectWithValue(e)
	}
})

export const toggleNotificationPreferenceRequest = createAsyncThunk<
	boolean,
	number,
	{
		state: RootState
	}
>(`${FEATURE_NAME}/TOGGLE_USER_NOTIFICATION_PREFERENCES_REQUEST`, async (id, { dispatch, getState, rejectWithValue }) => {
	const preference = getState().SETTINGS.preferences.notifications.preferenceMap[id]
	if (preference) {
		try {
			// optimistic update
			dispatch(toggleNotificationPreference(id))
			const { data } = await patchNotificationPreferences(id, !preference.value)
			return data
		} catch (e) {
			dispatch(toggleNotificationPreference(id))
			return rejectWithValue(e)
		}
	}
})

export const settingsSlice = createSlice({
	name: FEATURE_NAME,
	initialState,
	reducers: {
		toggleNotificationPreference: (state, { payload }: PayloadAction<number>) => {
			const { [payload]: preference } = state.preferences.notifications.preferenceMap
			if (preference) {
				preference.value = !preference.value
			}
		},
	},
	extraReducers: (builder) => {
		builder
			.addCase(loadUserNotificationPreferencesRequest.pending, (state) => {
				state.preferences.notifications.isLoading = true
			})
			.addCase(loadUserNotificationPreferencesRequest.fulfilled, (state, { payload }) => {
				const preferenceMap = {}
				state.preferences.notifications.data = payload.map((group) => {
					return {
						...group,
						subGroups: group.subGroups.map((subGroup) => ({
							...subGroup,
							preferences: subGroup.preferences.map((preference) => {
								const { id } = preference
								if (!preferenceMap[id]) {
									preferenceMap[id] = preference
								}
								return id
							}),
						})),
					}
				})
				state.preferences.notifications.preferenceMap = preferenceMap
			})
			.addMatcher(isAnyOf(loadUserNotificationPreferencesRequest.rejected, loadUserNotificationPreferencesRequest.fulfilled), (state) => {
				state.preferences.notifications.isLoading = false
			})
	},
})

// Actions
export const { toggleNotificationPreference } = settingsSlice.actions

// Selectors

const selectNotificationSettings = (state: RootState) => state[FEATURE_NAME].preferences.notifications

export const selectNotificationPreferences = createSelector(selectNotificationSettings, (settings) => settings.data)
export const selectIsNotificationPreferencesLoading = createSelector(selectNotificationSettings, (settings) => settings.isLoading)

export const selectAvailableTransportOptions = createSelector(
	selectNotificationSettings,
	(settings): Record<string, DtoNotificationTransport> => {
		const transportsMap = {}
		if (settings.preferenceMap) {
			Object.keys(settings.preferenceMap).forEach((key) => {
				const { transport } = settings.preferenceMap[key]
				const { code } = transport
				if (!transportsMap[code]) {
					transportsMap[code] = transport
				}
			})
		}

		return transportsMap
	},
)

export const selectPreferenceMap = createSelector(selectNotificationSettings, (settings) => settings.preferenceMap)
