import { SnackbarProvider } from 'notistack'

import Snackbar from '@/components/Snackbar/Snackbar'
import { MAX_SNACK } from '@/constants/app'
import makeStyles from '@mui/styles/makeStyles'

const useStyles = makeStyles({
	root: {
		bottom: '-2px !important',
	},
})

const Provider = ({ children }: any) => {
	const classes = useStyles()

	return (
		<SnackbarProvider
			classes={{
				containerAnchorOriginBottomCenter: classes.root,
			}}
			maxSnack={MAX_SNACK}
			anchorOrigin={{
				vertical: 'bottom',
				horizontal: 'center',
			}}
			content={(key, message) => <Snackbar id={key} {...message} />}
			dense
		>
			{children}
		</SnackbarProvider>
	)
}

export default Provider
