import http from '@/api/http/requestHttp'
import { PSU_SCHOOL_ID } from '@/constants/configuration'

import { FileDto, FileListDto, FileListParams } from './types'

const BASE_URL = `${PSU_SCHOOL_ID}/oms/files`

export const createFile = async ({ organizationId, file }: { organizationId?: string; file: File }) => {
	const formData = new FormData()
	formData.append('file', file)

	return await http.post<FileDto>(BASE_URL, formData, {
		params: {
			organizationId,
		},
		headers: {
			Accept: 'application/json',
			'Content-Type': 'multipart/form-data',
		},
	})
}

export const getFiles = async (params: FileListParams) => {
	return await http.get<FileListDto>(BASE_URL, { params })
}

export const getFileById = async (id: string) => {
	const url = `${BASE_URL}/${id}`
	return await http.get<FileDto>(url)
}

export const deleteFileById = async (id: string) => {
	const url = `${BASE_URL}/${id}`
	return await http.delete(url)
}
