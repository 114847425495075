import makeStyles from '@mui/styles/makeStyles'
import { baseStyles } from '../../../../components/layouts/dashboard/baseStyles'

export enum GridAreaKey {
	A = 'a',
	B = 'b',
	C = 'c',
	D = 'd',
}

const BORDER_WIDTH = 0.5
const getGridAreaBorders = (gridArea: GridAreaKey) => {
	const borderStyles = { borderStyle: 'solid', borderColor: '#D7D9DE' }

	if (gridArea === GridAreaKey.A) {
		return {
			...borderStyles,
			borderWidth: 0,
			borderBottomWidth: BORDER_WIDTH,
			borderRightWidth: BORDER_WIDTH,
		}
	}

	if (gridArea === GridAreaKey.B) {
		return {
			...borderStyles,
			borderWidth: 0,
			borderBottomWidth: BORDER_WIDTH,
		}
	}

	if (gridArea === GridAreaKey.C) {
		return {
			...borderStyles,
			borderWidth: 0,
			borderRightWidth: BORDER_WIDTH,
		}
	}

	return { border: 'none' }
}
const useStyles = makeStyles(() => ({
	...baseStyles,
	wrapper: ({ gridArea }: any) => {
		return {
			gridArea: gridArea,
			...getGridAreaBorders(gridArea),
		}
	},
}))

export const GridArea = ({ children, gridArea }: { children: JSX.Element; gridArea: GridAreaKey }) => {
	const classes = useStyles({ gridArea })
	return <div className={classes.wrapper}>{children}</div>
}
