import { OutlinedSelect, SelectValue, styles, TextField, UploadField } from '@/components/shared'
import { Switch, Typography } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import { CardProps, fileTypeOptions, FileUploadQuestion } from '../types'

import { AVAILABLE_FILE_TYPES, FileTypes, MAX_UPLOAD_FILE_SIZE } from '@/features/documents/constants'
import { convertByteToMegaByte } from '@/utils/common'
import React, { useMemo } from 'react'
import { DropzonePlaceholder } from './DropzonePlaceholder'
import { useSettingsChangeHandler } from './hooks/useSettingsChangeHandler'
import { FieldSettingsContainer } from './styledComponents/FieldSettingsContainer'
import { StyledFormControlLabel } from './styledComponents/StyledFormControlLabel'
import { StyledFormControlLabelInline } from './styledComponents/StyledFormControlLabelInline'

const useStyles = makeStyles((theme) => ({
	typeSelectContainer: {
		width: '15vw',
		minWidth: 200,
		marginBottom: 15,
	},
	typeSelect: {
		width: styles.FILL_AVAILABLE_WIDTH,
		minHeight: 50,
	},
	label: {
		marginBottom: 7,
	},
}))

type TSettingKey = keyof FileUploadQuestion

interface IFileUploadSettingsBlockProps extends FileUploadQuestion {
	onChange: (key: TSettingKey, value: any) => void
}

const FileUploadSettingsBlock: React.FC<IFileUploadSettingsBlockProps> = ({
	allowedFileTypes,
	multiple,
	fileCount,
	maxFileSize,
	placeholder,
	onChange,
}) => {
	const label = useMemo(() => {
		if (placeholder) {
			return placeholder
		}
		const typeLabel = fileTypeOptions.find((type) => type.value === allowedFileTypes)?.label
		return fileCount > 1 ? `Add ${typeLabel} files` : `Add a ${typeLabel}`
	}, [allowedFileTypes, fileCount, placeholder])

	const icon = AVAILABLE_FILE_TYPES[allowedFileTypes].icon

	const onChangeHandler = useSettingsChangeHandler<TSettingKey>(onChange)

	return (
		<>
			<StyledFormControlLabelInline
				labelPlacement="start"
				control={<Switch checked={multiple} onChange={onChangeHandler('multiple', 'checked')} />}
				label="Allow multiple files?"
			/>
			{multiple && (
				<StyledFormControlLabel
					labelPlacement="start"
					control={<TextField value={fileCount} onChange={onChangeHandler('fileCount')} inputProps={{ min: 1, max: 5 }} type="number" />}
					label="Max allowed file count"
				/>
			)}
			<StyledFormControlLabel
				labelPlacement="top"
				control={<TextField value={placeholder} onChange={onChangeHandler('placeholder')} />}
				label="Placeholder"
			/>

			<StyledFormControlLabel
				labelPlacement="start"
				control={
					<TextField
						value={maxFileSize}
						onChange={onChangeHandler('maxFileSize')}
						inputProps={{ min: 0, max: convertByteToMegaByte(MAX_UPLOAD_FILE_SIZE) }}
						type="number"
					/>
				}
				label="Max allowed file size (MB)"
			/>
			<DropzonePlaceholder label={label} icon={icon} />
		</>
	)
}

export { FileUploadSettingsBlock }

const FileUploadCard = ({ data, onChange, isEdit }: CardProps<FileUploadQuestion>) => {
	const classes = useStyles()

	const handleChangeFiledType = (value: SelectValue | SelectValue[]) => onChange({ ...data, allowedFileTypes: value as FileTypes })

	const fileDataType = data as FileUploadQuestion
	const onSettingChange = (key: TSettingKey, value) => {
		if (key === 'multiple') {
			onChange({
				...data,
				[key]: value,
				fileCount: 1,
			})
		} else {
			onChange({
				...data,
				[key]: value,
			})
		}
	}
	return (
		<FieldSettingsContainer>
			{isEdit ? (
				<div className={classes.typeSelectContainer}>
					<Typography className={classes.label} variant="subtitle1">
						File Type
					</Typography>
					<OutlinedSelect
						classnames={{
							input: classes.typeSelect,
						}}
						value={fileDataType.allowedFileTypes}
						options={fileTypeOptions}
						onChange={handleChangeFiledType}
					/>
					<FileUploadSettingsBlock onChange={onSettingChange} {...data} />
				</div>
			) : (
				<UploadField disabled={true} data={data} />
			)}
		</FieldSettingsContainer>
	)
}

export default FileUploadCard
