import { push } from 'connected-react-router'
import React, { useCallback, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { ActionModal } from '@/components/ActionModal'
import { DataTable } from '@/components/EntityManager/DataTable'

import { EVENT_PATHS } from '../../constants'
import { getEventsListRequest, resetList, selectEventsList } from '../../eventsSlice'
import { useEventColumns } from '../../hooks'
import { EventStatuses } from '../../types'

const New = () => {
	const eventsList = useSelector(selectEventsList)
	const dispatch = useDispatch()

	const deletionCallback = useCallback(() => {
		dispatch(push(EVENT_PATHS.NEW_LIST))
	}, [dispatch])

	useEffect(() => {
		dispatch(resetList())
	}, [dispatch])

	const { columns, confirmationRequired, renderButtons, handleClose } = useEventColumns(
		[
			{
				field: 'status',
				sortable: false,
				headerName: 'Status',
				filterable: false,
			},
		],
		deletionCallback,
	)
	return (
		<>
			<DataTable
				defaultFilters={{
					items: [
						{
							columnField: 'status',
							operatorValue: 'is',
							value: EventStatuses.Pending,
						},
					],
				}}
				data={eventsList}
				request={getEventsListRequest}
				columns={columns}
			/>
			<ActionModal
				handleClose={handleClose}
				open={confirmationRequired}
				renderButtons={renderButtons}
				title="Delete Event"
				description="Anyone who has RSVP’d will be notified that this event has been deleted."
			/>
		</>
	)
}

export default New
