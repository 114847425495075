import { MODULE_NAME } from '@/store/auth/constants'
import { AuthState } from '@/store/auth/types'
import { CampusDto } from '@/types'
import { createSelector } from '@reduxjs/toolkit'

const selectState = (state: { [MODULE_NAME]: AuthState }) => state[MODULE_NAME]

export const campusToOption = (campus: CampusDto) => ({ label: campus.name, value: campus.id })

export const getUserRole = createSelector(selectState, (state) => state.role)
export const getUserInfo = createSelector(selectState, (state) => state.userInfo)

export const getIsLoading = createSelector(selectState, (state) => state.isLoading)
export const getCampuses = createSelector(selectState, (state) => state.campuses.map(campusToOption))
export const getAllUserOrganizationRoles = createSelector(selectState, (state) => state.organizationRoles)
export const getUserOrganizationRoles = (orgId: string) => createSelector(selectState, (state) => state.organizationRoles[orgId])

export const selectCurrentUserCampus = createSelector(selectState, (state) => ({
	name: state.userInfo.campusName,
	id: state.userInfo.campusId,
}))
