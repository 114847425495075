import React, { forwardRef, useCallback } from 'react'
import classNames from 'classnames'
import { Theme } from '@mui/material/styles'
import makeStyles from '@mui/styles/makeStyles'
import { Tab as MaterialTab } from '@mui/material'

interface StyleProps {
	centered: boolean
}

const useStyles = makeStyles<Theme, StyleProps>((theme) => ({
	tab: ({ centered }) => ({
		textTransform: 'none',
		minWidth: 'fit-content',
		'& >span': {
			minWidth: 'fit-content',
			width: 'fit-content !important',
		},
		width: 'fit-content',
		height: '100%',
		margin: '0 1vw',
		marginLeft: centered ? '1vw' : 0,
		...theme.typography.body2,
		'& span': {
			width: 'min-content',
		},
	}),
	selectedTab: {
		color: theme.colors.Primary[700],
		opacity: 1,
		fontWeight: 600,
	},
	unselectedTab: {
		color: theme.colors.black[500],
		opacity: 1,
	},
	tabWrapper: {
		marginTop: '1vh',
	},
}))

interface TabProps extends StyleProps {
	classnames?: {
		tabWrapper?: string
		tab?: string
		selectedTab?: string
		unselectedTab?: string
	}
	key: string
	label?: string
	hideIndicator: boolean
	activeTabIndex: number
	icon?: React.ReactElement
	activeIcon?: React.ReactElement
	onClick?: (index: number, e: any) => void
	index: number
	disabled?: boolean
}

const Tab = forwardRef<HTMLDivElement | null, TabProps>(
	(
		{
			classnames = {
				tabWrapper: '',
				tab: '',
				selectedTab: '',
				unselectedTab: '',
			},
			key,
			label,
			activeTabIndex,
			index,
			icon,
			activeIcon,
			hideIndicator,
			onClick = () => {},
			centered,
			disabled,
		},
		ref,
	) => {
		const classes = useStyles({ centered })

		const tabSelected = index === activeTabIndex && !hideIndicator
		const tabIcon = !tabSelected ? icon : activeIcon ? activeIcon : icon

		const handleClick = useCallback(
			(e: any) => {
				onClick(index, e)
			},
			[index, onClick],
		)

		return (
			<MaterialTab
				ref={ref}
				key={key}
				className={`${
					tabSelected
						? classNames(classnames.selectedTab, classes.selectedTab)
						: classNames(classnames.unselectedTab, classes.unselectedTab)
				}`}
				classes={{
					root: classNames(classnames.tab, classes.tab),
					//wrapper: classNames(classnames.tabWrapper, classes.tabWrapper),
				}}
				onClick={handleClick}
				wrapped={true}
				label={label}
				icon={tabIcon}
				disabled={disabled}
			/>
		)
	},
)

export default Tab
