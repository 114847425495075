import { GridFilterModel, GridSortModel } from '@mui/x-data-grid'

export const MAX_SNACK = 2

export const LIST_INITIAL_STATE = {
	list: {
		count: 0,
		pages: 0,
		page: 0,
		results: [],
	},
	params: {
		page: 1,
		limit: 10,
		filters: {} as GridFilterModel,
		sort: [] as GridSortModel,
		q: '',
	},
	isLoading: false,
}

export const INTENDED_GRADUATION_TERMS_LIST = <const>['Spring', 'Summer', 'Fall', 'Winter']

export type TGraduationTerm = typeof INTENDED_GRADUATION_TERMS_LIST[number]
