import { DateTime } from 'luxon'
import { useSelector } from 'react-redux'

import { useCurrentUser } from '@/hooks/userHooks'
import { getUserRole } from '@/store/auth'
import { isAdmin } from '@/utils/authHandlers'
import { Theme } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'

import { baseStyles } from '../../../../components/layouts/dashboard/baseStyles'

type StyleProps = {
	isStudent: boolean
}
const useStyles = makeStyles<Theme, StyleProps>((theme) => ({
	...baseStyles,
	wrapper: ({ isStudent }) => ({
		...baseStyles.section,
		backgroundColor: isStudent ? theme.colors.white[500] : 'transparent',
		padding: isStudent ? `${theme.spacing(2.375)} ${theme.spacing(5)} ${theme.spacing(3)}` : 0,
		borderRadius: isStudent ? 7 : 0,
	}),
}))

export const GreetingSection = () => {
	const currentUserRole = useSelector(getUserRole)
	const classes = useStyles({ isStudent: !isAdmin(currentUserRole) } as StyleProps)
	const user = useCurrentUser()

	const todayDate = DateTime.now()

	return (
		<section className={classes.wrapper}>
			<div className={classes.sectionHeader}>
				<h2 className={classes.sectionHeaderTitle}>
					<span>Hello {user.firstName}!</span>

					<span className="u-marginLeft10">👋</span>
				</h2>
				<p className={classes.sectionHeaderSubtitle}>
					{todayDate.weekdayLong}, {todayDate.toFormat('MMMM dd, yyyy')}
				</p>
			</div>
		</section>
	)
}
