import { DateTime } from 'luxon'
import React, { useCallback, useEffect, useMemo, useState } from 'react'

import useLuxonDateFromJsDate from '@/hooks/useLuxonDateFromJsDate'
import { getTimeOptionsArray } from '@/utils/dateTime'
import { makeStyles } from '@mui/styles'

import { CommonSelect } from '../shared'
import { DatePickerSelect } from './DatePickerSelect'
import { TimePickerSelect } from './TimePickerSelect'
import { isDate } from 'lodash'
import { TimePickers } from '../Form/FormBuilder/types'

const useStyles = makeStyles((theme) => ({
	wrapper: {
		display: 'flex',
		margin: '0 -25px',
	},
	datePickerWrapper: {
		margin: '0 25px',
	},
	timePickerWrapper: {
		minWidth: '320px',
		margin: '0 25px',
	},
}))

export type ICustomDateTimePickerProps = {
	maxDate?: Date
	minDate?: Date
	disablePast?: boolean
	placeholder?: string
	onChange?: (date: Date | null) => void
	value?: Date
	timePickerOptions?: {
		placeholder: string
	}
	disabled?: boolean
	error?: boolean
	timeSelection?: TimePickers
}

const CustomDateTimePicker: React.FC<ICustomDateTimePickerProps> = ({
	minDate,
	maxDate,
	disablePast,
	onChange,
	timePickerOptions,
	placeholder = 'Select Date',
	disabled,
	value,
	timeSelection,
}) => {
	const classes = useStyles()

	const [dateTime, setDateTime] = useState<DateTime | null>(null)
	const [selectedTime, setSelectedTime] = useState<string>(null)

	const { date: minDateTime } = useLuxonDateFromJsDate(minDate)
	const { date: maxDateTime } = useLuxonDateFromJsDate(maxDate)

	const timeOptions = useMemo(() => {
		return getTimeOptionsArray(dateTime, minDateTime, maxDateTime)
	}, [minDateTime, maxDateTime, dateTime])

	const handleChange = useCallback(
		(dateTime: DateTime | null, time: string) => {
			if (dateTime) {
				const dateString = dateTime.toFormat('yyyy-MM-dd')
				const timeString = time ? time.toLowerCase() : '12:00 am'
				const fullDateTime = DateTime.fromFormat(dateString + ' ' + timeString, 'yyyy-MM-dd hh:mm a')
				onChange(fullDateTime.toJSDate())
			} else {
				onChange(null)
			}
		},
		[onChange],
	)

	const onTimeChange = useCallback(
		(val) => {
			setSelectedTime(val)
			handleChange(dateTime, val)
		},
		[handleChange, dateTime],
	)

	const onDateChange = useCallback(
		(date: DateTime | null) => {
			setDateTime(date)
			handleChange(date, selectedTime)
		},
		[handleChange, selectedTime],
	)

	useEffect(() => {
		if (value) {
			const userInput = isDate(value) ? value : new Date(value)
			setDateTime(DateTime.fromJSDate(userInput))
			setSelectedTime(DateTime.fromJSDate(userInput).toFormat('hh:mm a').toUpperCase())
		}
	}, [value])

	return (
		<div className={classes.wrapper}>
			<DatePickerSelect
				minDate={minDate}
				maxDate={maxDate}
				disablePast={disablePast}
				value={value}
				onChange={onDateChange}
				placeholder={placeholder}
				disabled={disabled}
				wrapperClass={classes.datePickerWrapper}
			/>
			{timeSelection ? (
				timeSelection === TimePickers.dropdown ? (
					<CommonSelect
						classnames={{
							input: classes.timePickerWrapper,
						}}
						value={selectedTime}
						onChange={onTimeChange}
						options={timeOptions}
						placeholder={timePickerOptions?.placeholder ?? 'Time'}
						disabled={disabled}
					/>
				) : (
					<TimePickerSelect disabled={disabled} onChange={onChange} label={timePickerOptions.placeholder} value={value} />
				)
			) : null}
		</div>
	)
}

export { CustomDateTimePicker }
