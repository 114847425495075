import http from '@/api/http/requestHttp'
import * as config from '@/constants/configuration'

interface FormListParams {
	isActive?: boolean
	isDraft?: boolean
	page?: number
	perPage?: number
	category?: number
}

// @TODO: we don't have pagination in the frontend put big number
const getTemplates = async ({ isActive, isDraft, page = 1, perPage = 200, category }: FormListParams) => {
	const url = `${config.PSU_SCHOOL_ID}/oms/forms/templates`

	const params = {
		formStatus: isDraft ? 'drafts' : isActive ? 'active' : 'inactive',
		page,
		perPage,
		category,
	}

	return await http.get(url, { params })
}

export default getTemplates
