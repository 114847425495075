import React, { useMemo } from 'react'

import { CommonSelect } from '@/components/shared'
import { getFullDateFormatted } from '@/utils/dateTime'
import { Typography } from '@mui/material'
import { makeStyles } from '@mui/styles'

import { EventStatuses, EventUserData } from '../../types'
import { mapFlatToOption } from '@/utils/common'

const useStyles = makeStyles((theme) => ({
	root: {
		display: 'flex',
		justifyContent: 'space-between',
		alignItems: 'center',
		borderRadius: 7,
		padding: `${theme.spacing(3.75)} ${theme.spacing(4)} ${theme.spacing(3.25)} ${theme.spacing(3)}`,
		backgroundColor: theme.colors.Neutral[100],
	},
	actionsInfo: {
		color: theme.colors.Neutral[500],
		'& > p': {
			marginBottom: theme.spacing(1.25),
		},
		'& > p:last-child': {
			marginBottom: 0,
		},
	},
}))

export type ISubmissionInfoProps = {
	// Submitted by can't be null
	submittedBy: null | EventUserData
	approvedBy: null | EventUserData
	status: EventStatuses
	canEdit: boolean
	onStatusChanged?: (status: EventStatuses) => void
}

const SubmissionInfo: React.FC<ISubmissionInfoProps> = ({
	submittedBy,
	approvedBy,

	status,
	canEdit,
	onStatusChanged,
}) => {
	const classes = useStyles()
	const statusOptions = useMemo(() => {
		return (Object.keys(EventStatuses) as EventStatuses[]).filter((key) => key !== EventStatuses.Pending).map(mapFlatToOption)
	}, [])
	return (
		<div className={classes.root}>
			<div className={classes.actionsInfo}>
				{submittedBy && (
					<Typography fontWeight={'medium'}>
						Submitted by {submittedBy.userName} on {getFullDateFormatted(submittedBy.date)}
					</Typography>
				)}

				{approvedBy && (
					<Typography fontWeight={'medium'}>
						Approved by {approvedBy.userName} on {getFullDateFormatted(approvedBy.date)}
					</Typography>
				)}
			</div>
			{canEdit && (
				<CommonSelect
					onChange={onStatusChanged}
					placeholder="Approve/Deny"
					value={status !== EventStatuses.Pending ? status : ''}
					options={statusOptions}
				/>
			)}
		</div>
	)
}

export { SubmissionInfo }
