import http from '@/api/http/requestHttp'
import * as config from '@/constants/configuration'

const getOrganizationStats = async () => {
	const url = `${config.PSU_SCHOOL_ID}/oms/dashboards`

	return http.get(url)
}

export default getOrganizationStats
