import React from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { getUserRole } from '@/store/auth'
import { isStudent } from '@/utils/authHandlers'
import { Box, Button, Typography } from '@mui/material'
import { makeStyles } from '@mui/styles'

import { updateEventStatusRequest } from '../../eventsSlice'
import { EventDto, EventStatuses } from '../../types'
import CategoryItem from './CategoryItem'
import { MainInfo } from './MainInfo'
import { SubmissionInfo } from './SubmissionInfo'
import { Thumbnail } from './Thumbnail'

// @TODO break down to smaller components
const useStyles = makeStyles((theme) => ({
	eventDetails: {
		width: 835,
	},
	card: {
		padding: `${theme.spacing(3.75)} ${theme.spacing(5.25)} `,
		border: `1px solid ${theme.colors.Neutral[300]}`,
		marginTop: theme.spacing(2),
		borderRadius: 7,
	},
	cardHeader: {
		fontWeight: theme.typography.fontWeightBold,
		marginBottom: theme.spacing(2.5),
	},

	categoryList: {
		marginBottom: theme.spacing(3),
	},
	emailBtn: {
		textTransform: 'none',
		marginTop: theme.spacing(2.5),
	},
}))

export type IDetailsProps = {
	data: EventDto
}

const Details: React.FC<IDetailsProps> = ({ data }) => {
	const classes = useStyles()
	const dispatch = useDispatch()
	const currentUserRole = useSelector(getUserRole)
	const handleStatusChange = (status: EventStatuses) => {
		dispatch(updateEventStatusRequest({ id: data.id, status, refresh: status === EventStatuses.Approved }))
	}

	return (
		<div className={classes.eventDetails}>
			<Thumbnail photoUrl={data.photoUrl} altText={data.name} />
			{(data.approvedBy || data.submittedBy) && (
				<SubmissionInfo
					canEdit={data.status === EventStatuses.Pending && !isStudent(currentUserRole)}
					submittedBy={data.submittedBy}
					approvedBy={data.approvedBy}
					status={data.status}
					onStatusChanged={handleStatusChange}
				/>
			)}
			<MainInfo
				startDate={data.startDate}
				endDate={data.endDate}
				name={data.name}
				organizations={data.organization}
				locationName={data.locationName}
				repeating={data.repeating}
				virtualLink={data.virtualLink}
			/>
			<div className={classes.card}>
				<Typography className={classes.cardHeader}>About this data</Typography>
				{data.categories.length > 0 && (
					<Box className={classes.categoryList}>
						{data.categories.map((cat) => (
							<CategoryItem variant="outlined" key={cat.id} label={cat.category} />
						))}
					</Box>
				)}
				<Typography>{data.description}</Typography>
			</div>
			<div className={classes.card}>
				<Typography className={classes.cardHeader}>Primary Contact</Typography>
				<Typography>{data.contactName}</Typography>
				{data.contactEmail && (
					<Button href={`mailto:${data.contactEmail}`} className={classes.emailBtn} variant="outlined">
						{data.contactEmail}
					</Button>
				)}
			</div>
		</div>
	)
}

export default Details
