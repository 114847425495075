import React, { forwardRef } from 'react'
import classNames from 'classnames'
import { specialColors, getHoverColor } from '@navengage/sen-shared-assets'
import { Button } from '@mui/material'

import makeStyles from '@mui/styles/makeStyles'

const useStyles = makeStyles((theme) => ({
	root: {
		borderRadius: 7,
		backgroundColor: specialColors.transparent,
		color: theme.colors.black[500],
		textTransform: 'none',
		boxShadow: 'none',
		...theme.typography.body1,
		[theme.breakpoints.down('sm')]: {
			...theme.typography.subtitle1,
			padding: 0,
			minWidth: 100,
			minHeight: 36,
		},
		'&:hover': {
			backgroundColor: getHoverColor(theme.colors.white),
		},
	},
}))

export interface OptionButtonProps {
	children?: React.ReactNode
	onClick?: (e: React.SyntheticEvent<HTMLButtonElement>) => void
	disabled?: boolean
	className?: string
	type?: 'button' | 'reset' | 'submit'
	onMouseEnter?: (e: React.SyntheticEvent<HTMLButtonElement>) => void
	onMouseLeave?: (e: React.SyntheticEvent<HTMLButtonElement>) => void
	component?: any
	startIcon?: React.ReactNode
	endIcon?: React.ReactNode
}

const OptionButton = forwardRef<HTMLButtonElement, OptionButtonProps>(
	({ children = null, onClick, disabled, className, type, onMouseEnter, onMouseLeave, component, startIcon, endIcon }, ref) => {
		const classes = useStyles()

		return (
			<Button
				ref={ref}
				className={classNames(className, classes.root)}
				onClick={onClick}
				type={type}
				onMouseEnter={onMouseEnter}
				onMouseLeave={onMouseLeave}
				disabled={disabled}
				startIcon={startIcon}
				component={component}
				endIcon={endIcon}
			>
				{children}
			</Button>
		)
	},
)

export default OptionButton
