const ROOT = 'organizations'

const ORGANIZATIONS = {
	ROOT,
	MY: `${ROOT}/my`,
	CREATE: `${ROOT}/create`,
	SINGLE: (id?: string) => `${ROOT}/organization/${id !== undefined ? id : ':id'}`,
}

export default ORGANIZATIONS
