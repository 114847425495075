import React, { forwardRef } from 'react'
import { Theme } from '@mui/material/styles'
import makeStyles from '@mui/styles/makeStyles'
import { Checkbox, FormControlLabel } from '@mui/material'
import classNames from 'classnames'
import { getDisabledColor } from '@navengage/sen-shared-assets'

interface StyleProps {
	value: boolean
	labelPosition?: 'bottom' | 'end' | 'start'
}

const useStyles = makeStyles<Theme, StyleProps>((theme) => ({
	checkBox: ({ value }) => ({
		color: theme.colors.grey[500],
		'&.MuiCheckbox-colorPrimary.Mui-checked': {
			color: theme.colors.Primary[700],
		},
		'&.MuiCheckbox-colorPrimary.Mui-disabled': {
			color: getDisabledColor(theme.colors.Primary),
			'&>span': {
				'&>div': {
					backgroundColor: value ? getDisabledColor(theme.colors.Primary) : theme.colors.grey[200],
					border: 'solid 5px #00c4ff05',
				},
			},
		},
		padding: 0,
		fontSize: 12,
	}),
	checkBoxLabel: ({ labelPosition = 'bottom' }) => ({
		position: 'relative',
		color: theme.colors.grey[500],
		margin: 0,
		'&>span:last-child': {
			fontSize: 13,
			marginLeft: labelPosition === 'end' ? 10 : 0,
			marginRight: labelPosition === 'start' ? 10 : 0,
		},
	}),
	itemContainer: ({ value }) => ({
		backgroundColor: value ? theme.colors.Primary[700] : theme.colors.grey[300],
		width: 35,
		height: 15,
		borderRadius: 30,
		border: `solid 5px ${value ? theme.colors.Primary[700] : theme.colors.grey[300]}`,
		display: 'flex',
		alignItems: 'center',
		justifyContent: value ? 'right' : 'left',
	}),
	checkCircle: {
		backgroundColor: theme.colors.white[500],
		width: 15,
		height: 15,
		borderRadius: '50%',
	},
}))

interface ToggleCheckBoxProps extends StyleProps {
	className?: string
	disabled?: boolean
	showLabel?: boolean
	onChange?: (v: React.ChangeEvent<HTMLInputElement>) => void
	label?:
		| {
				checked: string
				unchecked: string
		  }
		| string
}

const ToggleCheckBox = forwardRef<any, ToggleCheckBoxProps>(
	({ className = '', value = false, disabled = false, showLabel = true, onChange, labelPosition = 'bottom', label }, ref) => {
		const classes = useStyles({ value, labelPosition })

		const getLabel = () => {
			if (!showLabel) return undefined
			if (typeof label === 'string') return label

			if (value) return label ? label.checked : 'On'
			else return label ? label.unchecked : 'Off'
		}

		const checkItem = (
			<div className={classes.itemContainer}>
				<div className={classes.checkCircle}></div>
			</div>
		)

		return (
			<FormControlLabel
				ref={ref}
				value={labelPosition}
				className={classNames(className, classes.checkBoxLabel)}
				control={
					<Checkbox
						color="primary"
						classes={{
							root: classes.checkBox,
						}}
						checked={value}
						onChange={onChange}
						disabled={disabled}
						inputProps={{ 'aria-label': 'primary checkbox' }}
						checkedIcon={checkItem}
						icon={checkItem}
					/>
				}
				label={getLabel()}
				labelPlacement={labelPosition}
			/>
		)
	},
)

export default ToggleCheckBox
