import { push } from 'connected-react-router'
import { v4 as uuidv4 } from 'uuid'

import * as http from '@/api/http'
import { FieldDataType, FormBuilderData, FormBuilderQuestion } from '@/components/Form/FormBuilder/types'
import { PhotoUploadDirectories, UploadErrorMessage, UploadErrors } from '@/constants/uploads'
import { templatesColumns, TemplatesTabs } from '@/pages/FormsPage/FormsList/Tabs/Templates/tabsData'
import { RootState } from '@/store'
import { MODULE_NAME } from '@/store/forms/constants'
import { createDeletionThunk, createPhotoUrl } from '@/utils/common'
import uploadPhoto from '@/utils/photoUpload'
import { createAction, createAsyncThunk } from '@reduxjs/toolkit'

import deleteFormById from '@/api/http/forms/deleteForm'
import { PATHS } from '@/constants'
import { creationSuccessToast } from '@/utils/notificationHelpers'
import { closeSnackbar, enqueueSnackbar } from '../app'
import { SnackbarType } from '../app/types'
import { FormCategoryDto, FormDataDto, FormQuestionDto, Templates } from './types'

export const createFormBuilderData = (
	{ id, title, description, startDate, endDate, allowMultipleSubmission, isActive, category }: FormDataDto,
	formQuestions: FormQuestionDto[],
): FormBuilderData<FieldDataType> => {
	let data: FormBuilderData<FieldDataType> = {
		id,
		settings: {
			title,
			description,
			startDate: startDate ? new Date(startDate) : undefined,
			endDate: endDate ? new Date(endDate) : undefined,
			allowMultipleSubmissions: allowMultipleSubmission,
			isActive: isActive,
			category,
		},
		questions: [],
	}

	data.questions = formQuestions.map(
		(q) =>
			({
				id: q.id,
				questionType: q.questionType,
				fieldData: JSON.parse(q.data),
				questionText: q.question,
				required: q.required,
				description: q.description,
				image: q.imageUrl,
			} as FormBuilderQuestion<FieldDataType>),
	)

	return data
}

export const setCategories = createAction<FormCategoryDto[]>(`${MODULE_NAME}/SET_CATEGORIES`)

export const deleteFormFromTemplates = createAction<{ tab: TemplatesTabs; formId: string }>(`${MODULE_NAME}/DELETE_FORM_FROM_LIST`)

export const setFormListTab = createAction<{
	pageTabIndex?: number
	tableTabIndex?: number
}>(`${MODULE_NAME}/SET_FORM_LIST_TAB`)

export const setForm = createAction<{
	data: {
		formData: FormDataDto
		formQuestions: FormQuestionDto[]
	}
	builder: FormBuilderData<FieldDataType>
}>(`${MODULE_NAME}/SET_FORM`)

export const setTemplates = createAction<Templates>(`${MODULE_NAME}/SET_TEMPLATES`)

export const loadFormsConstantsRequest = createAsyncThunk<
	any,
	undefined,
	{
		state: RootState
	}
>(`${MODULE_NAME}/LOAD_FORMS_CONSTANTS_REQUEST`, async (_undefined, { dispatch, getState, rejectWithValue }) => {
	try {
		const response = await http.forms.getListCategories()

		dispatch(setCategories(response.data))
	} catch (e: any) {
		return rejectWithValue(e)
	}
})

export const saveFormRequest = createAsyncThunk<
	any,
	{
		data: FormBuilderData<FieldDataType>
		isDraft: boolean
		redirect?: string
		isNew: boolean
	},
	{
		state: RootState
	}
>(`${MODULE_NAME}/SAVE_FORM_REQUEST`, async ({ data, isDraft, redirect, isNew }, { dispatch, getState, rejectWithValue }) => {
	const id = uuidv4()

	try {
		dispatch(
			enqueueSnackbar({
				key: id,
				notification: {
					message: {
						type: SnackbarType.uploading,
						message: 'Uploading Form',
					},
				},
			}),
		)

		let imagesToUpload: { localUrl: string; name: string }[] = []

		const questions = data.questions.reduce((acc: FormBuilderQuestion<FieldDataType>[], q) => {
			const { image } = q
			let tempQuestion = { ...q }

			if (image && image.startsWith('blob:')) {
				const imageName = uuidv4()

				tempQuestion.image = createPhotoUrl(imageName, PhotoUploadDirectories.FORM)
				imagesToUpload.push({
					localUrl: image,
					name: imageName,
				})
			}
			// backend doesn't accept empty string
			if (!tempQuestion.description) {
				tempQuestion.description = undefined
			}
			return [...acc, tempQuestion]
		}, [])
		const { id: dataId, ...dataWithoutId } = data

		const formData = {
			data: {
				...dataWithoutId,
				questions,
			},
			isDraft,
		}
		let formId = null
		if (isNew) {
			const { data } = await http.forms.postForm(formData)
			formId = data.id
		} else {
			await http.forms.patchForm(dataId, formData)
		}

		await Promise.all(
			imagesToUpload.map((img) =>
				uploadPhoto({
					fileUrl: img.localUrl,
					imageName: img.name,
					directory: PhotoUploadDirectories.FORM,
					options: {
						compressImage: true,
						onError: {
							uploadErrors: UploadErrors.FORM,
							uploadErrorMessage: UploadErrorMessage.POST,
						},
					},
				}),
			),
		)

		if (isNew && formId) {
			dispatch(
				enqueueSnackbar(
					creationSuccessToast('Form', `Form ${data.settings.title} has been created successfully!`, (messageId) => {
						dispatch(closeSnackbar({ key: messageId }))
						dispatch(push(PATHS.APP.FORMS_EDIT(formId)))
					}),
				),
			)
		}

		dispatch(push(redirect ?? ''))
	} catch (e: any) {
		dispatch(
			enqueueSnackbar({
				key: uuidv4(),
				notification: {
					message: {
						type: SnackbarType.error,
						message: UploadErrorMessage.POST,
					},
				},
			}),
		)
		return rejectWithValue(e)
	} finally {
		dispatch(closeSnackbar({ key: id }))
	}
})

export const readTemplatesRequest = createAsyncThunk<
	any,
	{
		tab: TemplatesTabs
		isActive?: boolean
		isDraft?: boolean
	},
	{
		state: RootState
	}
>(`${MODULE_NAME}/READ_TEMPLATES_REQUEST`, async ({ tab, isActive, isDraft }, { dispatch, getState, rejectWithValue }) => {
	try {
		const state = getState()

		const { categories } = state.FORMS

		const {
			data: { results },
		} = await http.forms.getTemplates({
			isDraft,
			isActive,
		})

		const rowsByCategory: { [k: number]: any[] } = (results as any[]).reduce((acc, item) => {
			const categoryId = item.categoryId

			return {
				...acc,
				[categoryId]: acc[categoryId] ? [...acc[categoryId], item] : [item],
			}
		}, {} as { [k: number]: any[] })

		const rows = categories.map((category) => ({
			groupName: category.name,
			metrics: templatesColumns[tab].reduce(
				(acc, col, idx) =>
					!!idx
						? [
								...acc,
								!col.showMetrics
									? null
									: rowsByCategory[category.id]?.reduce((acc, row) => (!!row[col.dataKey] ? acc + row[col.dataKey] : acc), 0),
						  ]
						: acc,
				[] as (number | null)[],
			),
			rows: rowsByCategory[category.id] ?? [],
		}))

		dispatch(setTemplates({ [tab]: rows }))
	} catch (e: any) {
		return rejectWithValue(e)
	}
})

export const readFormRequest = createAsyncThunk<
	any,
	string,
	{
		state: RootState
	}
>(`${MODULE_NAME}/READ_FORM_REQUEST`, async (id, { dispatch, getState, rejectWithValue }) => {
	try {
		const {
			data: { formData, formQuestions },
		} = await http.forms.getForm(id)

		dispatch(
			setForm({
				data: {
					formData,
					formQuestions,
				},
				builder: createFormBuilderData(formData, formQuestions),
			}),
		)
	} catch (e: any) {
		return rejectWithValue(e)
	}
})

export const deleteFormRequest = createDeletionThunk(MODULE_NAME, deleteFormById)
