import React, { useCallback, useEffect, useState } from 'react'

import { Link, OutlinedInput } from '@mui/material'
import { makeStyles } from '@mui/styles'

import { EntityAutocomplete } from '@/components/EntityAutocomplete'
import { IAutocompleteItemDto } from '@/types'
import { AutocompleteStorageKeys } from '@/utils/autoComplete'
import { ORGANIZATION_PRIMARY_ROLES } from '../constants'
import { TOrganizationPrimaryRoleKeys, TOrganizationPrimaryUser } from '../types'
import { CardContainer } from './CardContainer'
import { UserRow } from './UserRow'

const useStyles = makeStyles((theme) => ({
	textInput: {
		'& :disabled': {
			textAlign: 'center',
			color: theme.colors.black[500],
			opacity: '1',
			textOverflow: 'ellipsis',
			overflow: 'hidden',
		},
	},
	actions: {
		display: 'flex',
		alignItems: 'center',
		'& > button': {
			cursor: 'pointer',
			textDecoration: 'none',
			textTransform: 'none',
			marginRight: theme.spacing(2),
			fontWeight: theme.typography.fontWeightMedium,
			...theme.typography.body1,
			'&:disabled': {
				opacity: 0.5,
			},
		},
	},
}))

export type FnUpdateRole = (role: TOrganizationPrimaryRoleKeys, user: TOrganizationPrimaryUser) => void

export type IRoleEditorProps = {
	user?: TOrganizationPrimaryUser
	role: TOrganizationPrimaryRoleKeys
	updateRole: FnUpdateRole
}

const RoleEditor: React.FC<IRoleEditorProps> = ({ user, role, updateRole }) => {
	const classes = useStyles()

	const [isFormActive, setIsFormActive] = useState<boolean>(false)
	const [localStateUser, setLocalStateUser] = useState<TOrganizationPrimaryUser | null>(null)

	const resetForm = () => {
		setIsFormActive(false)
		setLocalStateUser(user)
	}

	const toggleForm = () => setIsFormActive((isActive) => !isActive)
	const handleUpdateButtonClick = useCallback(() => {
		updateRole(role, localStateUser)
		setIsFormActive(false)
	}, [updateRole, localStateUser, role])

	const { description, label } = ORGANIZATION_PRIMARY_ROLES[role]

	const onChange = useCallback((user: null | IAutocompleteItemDto) => {
		setLocalStateUser(user && user.id ? { email: user.email, fullName: user.fullName, id: Number(user.id) } : null)
	}, [])
	const isValid = localStateUser && localStateUser.id
	useEffect(() => {
		setLocalStateUser(user)
	}, [user])

	return (
		<CardContainer
			actions={
				<div className={classes.actions}>
					{!isFormActive && (
						<Link component="button" onClick={toggleForm}>
							Edit
						</Link>
					)}
					{isFormActive && (
						<Link disabled={!isValid} onClick={handleUpdateButtonClick} component="button">
							Update
						</Link>
					)}
					{isFormActive && (
						<Link color={'error'} component="button" onClick={resetForm}>
							Cancel
						</Link>
					)}
				</div>
			}
			label={label}
			description={description}
		>
			<UserRow
				email={
					<>
						{!isFormActive ? (
							<OutlinedInput className={classes.textInput} disabled={true} value={user.email} placeholder={`${label} Email`} fullWidth />
						) : (
							<EntityAutocomplete value={user.id ? user : undefined} entity={AutocompleteStorageKeys.Users} onChange={onChange} />
						)}
					</>
				}
				userName={
					!isFormActive ? (
						<OutlinedInput className={classes.textInput} disabled={true} value={user.fullName} placeholder={`${label} Name`} fullWidth />
					) : undefined
				}
			/>
		</CardContainer>
	)
}

export { RoleEditor }
