import { Typography } from '@mui/material'
import { makeStyles } from '@mui/styles'

import { baseStyles } from '../../baseStyles'
import { socialMediaInputs } from '../questions'
import { SocialMediaInput } from './SocialMediaInput'

const useStyles = makeStyles(() => ({
	...baseStyles,
}))

export const SocialMediaSection = (props) => {
	const classes = useStyles()

	const { stateControl } = props

	return (
		<section className={classes.section}>
			<div className={classes.sectionHeader}>
				<Typography className={classes.sectionHeaderTitle} variant="h2">
					Social Media
				</Typography>
				<p className={classes.sectionHeaderSubtitle}>These links will appear on the organization’s page on the Engagement App.</p>
			</div>
			{socialMediaInputs.map((data) => (
				<SocialMediaInput key={data.type} {...{ ...data, stateControl }} />
			))}
		</section>
	)
}
