import { themeConfig } from '@/components/shared'
import { createTheme } from '@mui/material'
import { CSSProperties } from '@mui/styled-engine'
import { AllColors, createPalette, SchoolPalette, SemanticColors, ThemeColors } from '@navengage/sen-shared-assets'

declare module '@mui/material/styles/createTheme' {
	interface Theme {
		colors: ThemeColors & SemanticColors & AllColors
		shape2: CSSProperties
	}
	interface DeprecatedThemeOptions {
		colors: ThemeColors & SemanticColors & AllColors
	}
}

const palette = createPalette((process.env.REACT_APP_COLOR_PALETTE ?? 'psu') as SchoolPalette)

const config = {
	...themeConfig,
	shape: {
		borderRadius: 5,
	},
	shape2: {
		borderRadius: 7,
	},
	props: {
		MuiButton: {
			disableElevation: true,
		},
	},
	palette: {
		primary: {
			main: palette.Primary[700],
		},
		info: {
			main: palette.Info[500],
		},
		warning: {
			main: palette.Warning[500],
		},
		error: {
			main: palette.Error[500],
		},
	},
	colors: palette,
	components: {
		MuiLink: {
			styleOverrides: {
				root: {
					fontWeight: 600,
				},
			},
		},
	},
}
const appTheme = createTheme(config)

export default appTheme
