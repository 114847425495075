import { orgTypes } from '@/utils/organizationRoles'
import { NotificationViewStatuses } from './constants'

export type TNotificationList = {
	total: number
	loading: boolean
	list: UserNotification[]
}

export type NotificationsState = Record<NotificationViewStatuses, TNotificationList>

export interface UserNotification {
	id: number
	heading: string
	content: string
	imageUrl?: string
	viewed: boolean
	targetUserId: number
	notificationDataId?: number
	createdAt: string
	updatedAt: string
	data: UserNotificationData
}
// @TODO: duplications of types correct, when start moving to sent-types
export interface UserNotificationData {
	type: string
	payload: {
		id: number
		connectionId?: number
		postId?: string
		sourceUserId: number
		messageId?: string
		eventId?: string
		organizationId?: string
		organization?: {
			id: string
			name: string
			photoUrl: string
		}
		organizationMembershipRequestId?: number
		organizationMembershipRequest?: {
			created_at: string
			id: number
			organizationId: string
			organizationMembershipTypeId: orgTypes
			requestedUserId: number
			responseUserId: number
			status: string
			updatedAt: string
			viewed: boolean
		}
		post?: {
			id: string
			ownerUserId: number
			ownerOrganizationId?: string
			campusId?: number
			usersAudience: string
			tag: string
			message: string
			photoUrl?: string
			linkPreviewUrl?: string
			sharedPostId?: string
			sharedEventId?: string
			sharedOrganizationId?: string
			sharedResearchId?: string
			sharedStudyAbroadId?: string
			createdAt: string
			updatedAt: string
			deleted: boolean
			sharedJobId?: string
		}
		message?: any
		sourceUser?: {
			id: number
			firstName: string
			lastName: string
			externalId: string
			displayName?: string
			appUserTypeId: number
			primaryTitle?: string
			campusId?: number
			photoUrl?: string
		}
		connectionRequest: {
			id: number
			sourceUserId: number
			targetUserId: number
			status: string
			created_at: string
			updated_at: string
			targetUser: {
				id: number
				externalId: string
				photoUrl?: string
			}
			sourceUser: {
				id: number
				externalId: string
				photoUrl?: string
			}
		}
	}
}

export interface DtoUserNotifications {
	total: number
	list: UserNotification[]
}

export type NotificationPages = 'all' | 'new' | 'past'

export enum NotificationTypes {
	newConnection = 'newConnection',
	postResponse = 'postResponse',
	upcomingEvent = 'upcomingEvent',
	newOrganizationMembershipRequest = 'newOrganizationMembership',
	newOrganizationMembershipResponse = 'newOrganizationMembershipResponse',
	changeOrganizationMembershipRoleRequest = 'changeOrganizationMembershipRoleRequest',
	changeOrganizationMembershipRoleResponse = 'changeOrganizationMembershipRoleResponse',
}
