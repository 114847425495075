import React, { useEffect, useState } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { useDispatch } from 'react-redux'
import * as yup from 'yup'

import FieldErrorMessage from '@/components/FieldErrorMessage'
import { ColorButton, Label } from '@/components/shared'
import { yupResolver } from '@hookform/resolvers/yup'
import { makeStyles } from '@mui/styles'

import { EntityAutocomplete } from '@/components/EntityAutocomplete'
import { AutocompleteStorageKeys } from '@/utils/autoComplete'
import { createDocumentRequest } from '../documentsSlice'
import { DocumentParams } from '../types'
import { DocumentDropzone } from './DocumentDropzone'

export type IDocumentFormProps = {
	onSubmit?: (data: any) => void
	defaultValues?: any
	isNew?: boolean
	organizationId?: string
}

const useStyles = makeStyles((theme) => ({
	label: {
		marginBottom: theme.spacing(3),
	},
	formActions: {
		textAlign: 'center',
	},
	formAction: {
		marginTop: theme.spacing(3),
		minWidth: 227,
		padding: theme.spacing(1.5),
		textTransform: 'none',
	},
}))

const schema = yup.object().shape({
	organizationId: yup.string().required(),
	files: yup.mixed().test('notEmpty', (files, { createError, path }) => {
		if (!files || files.length === 0) {
			return createError({
				path,
				message: 'Please select at least one file',
			})
		}
		return true
	}),
})

const DocumentForm: React.FC<IDocumentFormProps> = ({ onSubmit, organizationId, isNew = true }) => {
	const dispatch = useDispatch()
	const [loading, setLoading] = useState(false)
	const classes = useStyles()
	const {
		control,
		handleSubmit,
		formState: { errors, isValid },
		setValue,
	} = useForm({
		resolver: yupResolver(schema),
		mode: 'onChange',
		reValidateMode: 'onChange',
		criteriaMode: 'firstError',
		shouldFocusError: true,
	})
	useEffect(() => {
		setValue('organizationId', organizationId)
	}, [organizationId, setValue])

	const submitHandler = async (data: DocumentParams) => {
		setLoading(true)
		await dispatch(createDocumentRequest({ data, reloadList: true, reloadSameOrg: !!organizationId }))
		setLoading(false)
	}

	return (
		<div>
			{!organizationId && (
				<Controller
					name="organizationId"
					control={control}
					render={({ field }) => {
						const { name, onChange } = field

						return (
							<Label classnames={{ root: classes.label }} title="Organization" required>
								<EntityAutocomplete onChange={(item) => onChange(item.id)} entity={AutocompleteStorageKeys.Organizations} />
								<FieldErrorMessage hasError={!!errors[name]} message={errors[name] ? errors[name].message : ''} />
							</Label>
						)
					}}
				/>
			)}
			<Controller
				name="files"
				control={control}
				render={({ field: { onChange, name } }) => {
					return <DocumentDropzone maxFiles={5} multiple={true} onChange={onChange} />
				}}
			/>

			<div className={classes.formActions}>
				<ColorButton disabled={!isValid || loading} onClick={handleSubmit(submitHandler)} className={classes.formAction}>
					{!isNew ? 'Update' : 'Upload'}
				</ColorButton>
			</div>
		</div>
	)
}

export { DocumentForm }
