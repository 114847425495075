const settings = {
	SEARCH_ACTIVITY_LIST_PER_PAGE: 16,
	SEARCH_LIST_PER_PAGE: 16,
	SEARCH_FEEDS_LIST_PER_PAGE: 10,
	SEARCH_COMMENTS_LIST_PER_PAGE: 3,
	SEARCH_MESSAGES_PER_PAGE: 20,
	ORG_MEMBERS_PER_PAGE: 10,
	NOTIFICATIONS_PER_PAGE: 16,
}

export default settings
