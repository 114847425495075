import classNames from 'classnames'

import LazyLoadProfileImage from '@/components/LazyLoadProfileImage'
import { UserData } from '@/interfaces/common'
import { getNetworkUrl } from '@/utils/linksHelper'
import { Link, Typography } from '@mui/material'
import { makeStyles } from '@mui/styles'

const useStyles = makeStyles((theme) => ({
	root: {
		width: '100%',
		display: 'flex',
		flexDirection: 'row',
		alignItems: 'center',
		margin: '15px 0',
		textDecoration: 'none',
		color: theme.colors.black[500],
	},
	descriptionText: {
		color: theme.colors.grey[500],
	},
	infoContainer: {
		display: 'flex',
		flexDirection: 'column',
		marginLeft: 15,
	},
	clickable: {
		cursor: 'pointer',
	},
	profileImage: {
		width: 47,
		height: 47,
	},
}))

interface NetworkUserRowProps {
	className?: string
	user: UserData
	openProfileOnClick?: boolean
}

const NetworkUserRow = ({ className = '', user, openProfileOnClick = true }: NetworkUserRowProps) => {
	const classes = useStyles()

	const { externalId, fullName, type, campus, id, position, photoUrl } = user

	const subTitle = position ? position : type && campus ? `${type} | ${campus}` : ''
	//@TODO: navigate to user profile in engagement app or stay in orm? #Dan_needs_to_specify
	const profileUrl = getNetworkUrl(id)

	return (
		<Link href={profileUrl} className={classNames(classes.root, className)}>
			<LazyLoadProfileImage className={classes.profileImage} borderRadius={10} externalUserId={externalId || ''} photoUrl={photoUrl} />
			<div className={classes.infoContainer}>
				<Typography>{fullName}</Typography>
				<Typography className={classes.descriptionText}>{subTitle}</Typography>
			</div>
		</Link>
	)
}

export default NetworkUserRow
