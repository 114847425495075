const ROOT = 'forms'

const FORMS = {
	ROOT,
	CREATE: `${ROOT}/create`,
	EDIT: (id?: string) => `${ROOT}/edit/${id !== undefined ? id : ':id'}`,
	SUBMIT: (id?: string) => `${ROOT}/submit/${id !== undefined ? id : ':id'}`,
	VIEW_SUBMISSION: (id?: string) => `${ROOT}/submission/${id !== undefined ? id : ':id'}`,
	VIEW_FORM_SUBMISSIONS: (id?: string) => `${ROOT}/${id !== undefined ? id : ':id'}/submissions/`,
}

export default FORMS
