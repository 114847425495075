import makeStyles from '@mui/styles/makeStyles'
import { styles, Radio, TextField } from '@/components/shared'
import classNames from 'classnames'
import { ChangeEvent } from 'react'
import { AnswerOptionType, AnswerWithOtherOption, FieldDataWithOtherAnswer } from '../FormBuilder/types'
import { ReaderCardProps, ValueWithOtherAnswer } from './types'

const useStyles = makeStyles((theme) => ({
	root: {
		width: styles.FILL_AVAILABLE_WIDTH,
		display: 'flex',
		flexDirection: 'column',
	},
	option: {
		display: 'flex',
		alignItems: 'center',
	},
	otherInput: {
		width: styles.FILL_AVAILABLE_WIDTH,
	},
}))

const RadioAnswerCard = ({
	data,
	onChange,
	disabled,
	value,
	className,
	error,
}: ReaderCardProps<FieldDataWithOtherAnswer, ValueWithOtherAnswer>) => {
	const classes = useStyles()

	const handleSelectAnswer = (newValue: AnswerWithOtherOption) => () => onChange(newValue)

	const handleChangeOtherAnswer = (e: ChangeEvent<HTMLInputElement>) => onChange({ ...value, otherAnswer: e.target.value })

	return (
		<div className={classNames(className, classes.root)}>
			{data.answers.map((a) => (
				<div key={a.id} className={classes.option}>
					<Radio key={a.id} onChange={handleSelectAnswer(a)} value={a.id === value?.id} label={a.answer} disabled={disabled} />
					{value?.id === a.id && a.type === AnswerOptionType.other && (
						<TextField
							className={classes.otherInput}
							value={value?.otherAnswer ?? ''}
							onChange={handleChangeOtherAnswer}
							disabled={disabled}
							error={error}
							autoFocus
						/>
					)}
				</div>
			))}
		</div>
	)
}

export default RadioAnswerCard
