import { APP_URL } from '@/constants/configuration'
import { enqueueSnackbar } from '@/store/app'
import { SnackbarType } from '@/store/app/types'
import copy from 'copy-to-clipboard'
import { useCallback } from 'react'
import { useDispatch } from 'react-redux'
import { v4 as uuidv4 } from 'uuid'

export const useHandleCopy = (showNotification: boolean) => {
	const dispatch = useDispatch()
	const copyHandler = useCallback((text) => {
		const copied = copy(text)
		if (copied && showNotification) {
			dispatch(
				enqueueSnackbar({
					key: uuidv4(),
					notification: {
						options: {
							autoHideDuration: 500,
						},
						message: {
							message: 'Copied!',
							type: SnackbarType.success,
						},
					},
				}),
			)
		}
	}, [])
	return copyHandler
}
