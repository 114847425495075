import http from '@/api/http/requestHttp'
import { PSU_SCHOOL_ID } from '@/constants/configuration'
import { DtoNotificationPreferences } from './types'

const BASE_URL = `${PSU_SCHOOL_ID}/oms/users/preferences`

export const patchNotificationPreferences = (id: number, value: boolean) => {
	return http.patch(`${BASE_URL}/${id}`, { value })
}

export const getUserNotificationPreferences = () => {
	return http.get<DtoNotificationPreferences>(BASE_URL)
}
