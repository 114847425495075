import { DateTime } from 'luxon'
import { useEffect, useState } from 'react'

const useLuxonDateFromJsDate = (jsDate?: Date | DateTime) => {
	const [date, setDate] = useState<DateTime | undefined>(undefined)
	useEffect(() => {
		if (!(jsDate instanceof DateTime)) {
			setDate(DateTime.fromJSDate(jsDate))
		} else {
			setDate(jsDate)
		}
	}, [jsDate])
	return {
		date,
		setDate,
	}
}

export default useLuxonDateFromJsDate
