import classNames from 'classnames'
import React, { useCallback, useState } from 'react'

import { Cancel } from '@mui/icons-material'
import { Chip } from '@mui/material'
import { Theme } from '@mui/material/styles'
import makeStyles from '@mui/styles/makeStyles'
import { getHoverColor } from '@navengage/sen-shared-assets'

import { styles } from '../../../constants'
import Select, { isSelectHasValue, SelectClasses, SelectOptionType, SelectValue } from '../Select'

interface StyleProps {
	isOpen: boolean
	hasValue: boolean
	error?: boolean
	disabled?: boolean
}

const useStyles = makeStyles<Theme, StyleProps>((theme) => ({
	selectOpenIcon: {
		color: theme.colors.black[500],
		width: 24,
	},
	selectMenuListContainer: {
		maxHeight: 350,
	},
	inputRoot: ({ isOpen, hasValue, error, disabled }) => {
		const borderColor = error ? theme.colors.red[500] : theme.colors.grey[200]
		const border = isOpen || hasValue || error ? `solid 1px ${borderColor} !important` : 'none !important'

		return {
			'&.MuiOutlinedInput-root': {
				color: theme.colors.black[500],
				backgroundColor: isOpen ? theme.colors.white[500] : hasValue ? theme.colors.white[500] : theme.colors.grey[200],
				boxShadow: 'none',

				'& fieldset': {
					border,
					borderRadius: 5,
					background: 'none',
				},
				'&:hover fieldset': {
					border,
					borderRadius: 5,
					background: 'none',
				},
				'&.Mui-focused fieldset': {
					border,
					borderRadius: 5,
					background: 'none',
				},
			},
		}
	},
	selectRoot: ({ isOpen, hasValue, error }) => ({
		color: error ? theme.colors.red[500] : theme.colors.black[500],
		backgroundColor: isOpen ? theme.colors.white[500] : hasValue ? theme.colors.white[500] : theme.colors.grey[200],
		padding: '17px 7px 17px 20px',
		boxShadow: 'none',
		'&.MuiSelect-select:focus': {
			background: 'none',
		},
		...theme.typography.subtitle2,
	}),
	selectMenuPaper: {
		borderRadius: 0,
		boxShadow: '0px 0px 8px 4px rgba(0,0,0,0.13)',
		backgroundColor: theme.colors.white[500],
	},
	selectMenuList: {
		width: 'calc(100% - 2px) !important',
		boxShadow: 'none',
		backgroundColor: theme.colors.white[500],
		padding: '10px 0',
		borderRadius: 0,
	},
	menuItemIcon: {
		color: theme.colors.Primary[700],
		paddingLeft: 5,
	},
	menuItem: {
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'space-between',
		'&.Mui-selected': {
			background: 'none',
		},
		...theme.typography.subtitle2,
	},
	selectLabel: {
		width: 'auto',
		...theme.typography.body1,
		fontWeight: theme.typography.fontWeightMedium,
	},
	tagsContainer: {
		margin: '15px 0',
		width: styles.FILL_AVAILABLE_WIDTH,
	},
	tag: {
		color: theme.colors.Primary[700],
		backgroundColor: theme.colors.white[500],
		border: `solid 1px ${theme.colors.Primary[700]}`,
		borderRadius: 32,
		padding: '2vh 1vw',
		margin: '1vh 0.5vw',
		fontSize: 14,
		fontWeight: 550,
	},
	tagIcon: {
		color: theme.colors.Primary[700],
		'&:hover': {
			color: getHoverColor(theme.colors.Primary),
		},
	},
}))

type CommonSelectOptions = {
	disabled?: boolean
} & SelectOptionType
interface CommonSelectProps {
	classnames?: SelectClasses & {
		tag?: string
		tagsContainer?: string
	}
	label?: string
	value: SelectValue | SelectValue[]
	placeholder?: string
	onChange: (v: SelectValue | SelectValue[]) => void
	options: CommonSelectOptions[]
	multiple?: boolean
	disabled?: boolean
	onToggleSelect?: (state: boolean) => void
	renderTags?: boolean
	error?: boolean
	materialSelectOptions?: Record<string, any>
}

const CommonSelect = ({
	classnames = {
		input: '',
		select: '',
		selectOpenIcon: '',
		selectLabel: '',
		selectMenuPaper: '',
		selectMenuList: '',
		selectMenuListContainer: '',
		menuItem: '',
		menuItemIcon: '',
		tag: '',
		tagsContainer: '',
	},
	label,
	value,

	placeholder = '',
	onChange,
	options = [],
	multiple = false,
	disabled = false,
	renderTags = false,
	error = false,
	materialSelectOptions,
}: CommonSelectProps) => {
	const [isOpen, setIsOpen] = useState(false)
	const hasValue = isSelectHasValue(multiple, options, value)

	const classes = useStyles({ hasValue, isOpen, error, disabled })

	const onToggleSelect = useCallback((open: boolean) => setIsOpen(open), [])

	const onDeleteOption = (v: SelectValue) => {
		if (Array.isArray(value)) {
			onChange(value.filter((selectedValue) => v !== selectedValue))
		}
	}

	return (
		<>
			<Select
				classnames={{
					input: classNames(classnames.input, classes.inputRoot),
					select: classNames(classnames.select, classes.selectRoot),
					selectMenuPaper: classNames(classnames.selectMenuPaper, classes.selectMenuPaper),
					selectMenuList: classNames(classnames.selectMenuList, classes.selectMenuList),
					selectMenuListContainer: classNames(classnames.selectMenuListContainer, classes.selectMenuListContainer),
					selectLabel: classNames(classnames.selectLabel, classes.selectLabel),
					menuItem: classNames(classnames.menuItem, classes.menuItem),
					menuItemIcon: classNames(classnames.menuItemIcon, classes.menuItemIcon),
					selectOpenIcon: classNames(classnames.selectOpenIcon, classes.selectOpenIcon),
				}}
				label={label}
				placeholder={placeholder}
				options={options}
				onToggleSelect={onToggleSelect}
				disabled={disabled}
				value={value}
				onChange={onChange}
				multiple={multiple}
				materialSelectOptions={materialSelectOptions}
			/>
			{renderTags && Array.isArray(value) && (
				<div className={classNames(classnames.tagsContainer, classes.tagsContainer)}>
					{value.map((v) => {
						const option = options.find((o) => o.value === v)!
						return (
							<Chip
								className={classNames(classnames.tag, classes.tag)}
								key={`common-select-chip-${option.label.trim()}-${value}`}
								label={option.label}
								onDelete={(e: any) => {
									e.stopPropagation()
									e.preventDefault()
									onDeleteOption(v)
								}}
								deleteIcon={<Cancel className={classes.tagIcon} />}
								variant="outlined"
								color="primary"
								disabled={option.disabled}
							/>
						)
					})}
				</div>
			)}
		</>
	)
}

export default CommonSelect
