import { useCallback, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Route, useHistory } from 'react-router-dom'

import ContentContainer from '@/components/ContentContainer'
import { Header } from '@/components/EntityManager/Header'
import MiddlePaneLoader from '@/components/MiddlePaneLoader'
import { PostModal } from '@/components/Modal/PostModal'
import { RouterModal } from '@/components/Modal/RouterModal'
import { PostList } from '@/components/post/PostList'
import { getAnnouncementList, getAnnouncementsRequest } from '@/features/announcements/announcementsSlice'
import { ANNOUNCEMENTS_PATH } from '@/features/announcements/constants'
import { RESOURCES_PATH } from '@/features/resources/constants'
import { getReloading, getResourceList, getResourcesRequest } from '@/features/resources/resourcesSlice'
import { getUserRole } from '@/store/auth'
import { TPostType } from '@/types'
import { isAdmin } from '@/utils/authHandlers'
import { makeStyles } from '@mui/styles'

const useStyles = makeStyles((theme) => ({
	noResult: {
		display: 'flex',
		height: 400,
		alignItems: 'center',
		justifyContent: 'center',
		color: theme.colors.Neutral[500],
		fontWeight: theme.typography.fontWeightBold,
	},
}))

interface IPostListPageProps {
	postType: TPostType
}

const PostListPage: React.FC<IPostListPageProps> = ({ postType }) => {
	const { push } = useHistory()
	const dispatch = useDispatch()
	const classes = useStyles()

	const resourceList = useSelector(getResourceList)
	const announcementList = useSelector(getAnnouncementList)
	const postList = postType === 'Resource' ? resourceList : announcementList
	const isReloading = useSelector(getReloading)
	const currentUserRole = useSelector(getUserRole)
	const newPath = postType === 'Resource' ? RESOURCES_PATH.NEW : ANNOUNCEMENTS_PATH.NEW
	const editPath = postType === 'Resource' ? RESOURCES_PATH.EDIT() : ANNOUNCEMENTS_PATH.EDIT()

	useEffect(() => {
		if (postType === 'Resource') {
			dispatch(getResourcesRequest({ page: 1 }))
		} else {
			dispatch(getAnnouncementsRequest({ page: 1 }))
		}
	}, [dispatch, postType])

	const redirectToCreatePage = useCallback(() => {
		if (postType === 'Resource') {
			push(RESOURCES_PATH.NEW)
		} else {
			push(ANNOUNCEMENTS_PATH.NEW)
		}
	}, [push, postType])

	return (
		<>
			<ContentContainer>
				<Header
					title={postType + 's'}
					handlePrimaryBtn={isAdmin(currentUserRole) ? redirectToCreatePage : undefined}
					primaryBtnText={`Add ${postType}`}
					subHeader={isAdmin(currentUserRole) ? `Add ${postType.toLowerCase()}s for Students and Student Admins to see` : null}
				/>
				{isReloading ? (
					<MiddlePaneLoader />
				) : postList.result.length > 0 ? (
					<PostList postType={postType} editable={isAdmin(currentUserRole)} {...postList} />
				) : (
					<div className={classes.noResult}>No {postType + 's'}</div>
				)}
			</ContentContainer>

			<Route
				path={newPath}
				render={(props) => {
					return (
						<RouterModal
							children={(handleClose) => {
								return <PostModal handleClose={handleClose} type={postType} />
							}}
						/>
					)
				}}
			/>
			<Route
				path={editPath}
				render={({
					match: {
						params: { id },
					},
				}) => {
					return (
						<RouterModal
							children={(handleClose) => {
								return <PostModal id={id} handleClose={handleClose} type={postType} />
							}}
						/>
					)
				}}
			/>
		</>
	)
}

export default PostListPage
