import { RootState } from '@/store'

import { createAsyncThunk, createSelector, createSlice, isAnyOf, PayloadAction } from '@reduxjs/toolkit'
import { isArray, orderBy } from 'lodash'

import getUserNotifications, { patchUserNotifications } from './api'
import { DISPLAYED_NOTIFICATIONS_COUNT, NotificationViewStatuses } from './constants'
import { DtoUserNotifications, NotificationsState, UserNotification } from './types'

const FEATURE_NAME = 'NOTIFICATIONS'

const initialState: NotificationsState = {
	unviewed: {
		total: 0,
		list: [],
		loading: false,
	},
	viewed: {
		total: 0,
		list: [],
		loading: false,
	},
}

export const loadUserNotificationsRequest = createAsyncThunk<
	DtoUserNotifications,
	NotificationViewStatuses,
	{
		state: RootState
	}
>(`${FEATURE_NAME}/LOAD_USER_NOTIFICATIONS_REQUEST`, async (status, { dispatch, getState, rejectWithValue }) => {
	const viewed = status === NotificationViewStatuses.VIEWED
	try {
		const { data } = await getUserNotifications(viewed)
		return data
	} catch (e) {
		return rejectWithValue(e)
	}
})

export const setViewedNotificationsRequest = createAsyncThunk<
	any,
	UserNotification[],
	{
		state: RootState
	}
>(`${FEATURE_NAME}/SET_VIEWED_USER_NOTIFICATIONS_REQUEST`, async (viewedNotifications, { dispatch, getState, rejectWithValue }) => {
	if (!viewedNotifications || viewedNotifications.length === 0) {
		return
	}
	try {
		const {
			[FEATURE_NAME]: {
				unviewed: { total },
			},
			AUTH: {
				userInfo: { id },
			},
		} = getState()
		// optimistic update undo can be applied on catch
		await dispatch(markAsViewed(viewedNotifications))
		// @TODO: we should't send userId from client, backend should obtain it from session
		// Right now it is possible to patch other user notifications
		await patchUserNotifications(
			id,
			viewedNotifications.map((n) => n.id),
		)
		if (total > viewedNotifications.length) {
			await dispatch(loadUserNotificationsRequest(NotificationViewStatuses.UNVIEWED))
		}
	} catch (e: any) {
		return rejectWithValue(e)
	}
})

export const notificationsSlice = createSlice({
	name: FEATURE_NAME,
	initialState,
	reducers: {
		// TODO: we need to correct logic time for viewedAt, this should be real time when user saw the notification
		markAsViewed: (state, { payload }: PayloadAction<UserNotification[]>) => {
			state.unviewed.total -= payload.length
			state.viewed.total += payload.length

			state.viewed.list = [
				...orderBy(
					payload.map((n) => ({ ...n, viewed: true })),
					'updatedAt',
					'desc',
				),
				...state.viewed.list,
			]
			state.unviewed.list = state.unviewed.list.filter(
				(unviewed) => !payload.some((viewedNotification) => viewedNotification.id === unviewed.id),
			)
		},
		addToUnviewed: (state, { payload }: PayloadAction<UserNotification | UserNotification[]>) => {
			state.unviewed.total += 1

			if (isArray(payload)) {
				state.unviewed.list.unshift(...payload)
			} else {
				state.unviewed.list.unshift(payload)
			}
		},
		deleteFromStore: (state, { payload }: PayloadAction<UserNotification>) => {
			const listKey = payload.viewed ? NotificationViewStatuses.VIEWED : NotificationViewStatuses.UNVIEWED
			state[listKey].list = state.viewed.list.filter((n) => n.id !== payload.id)
		},
	},
	extraReducers: (builder) => {
		builder.addCase(loadUserNotificationsRequest.pending, (state, { meta: { arg } }) => {
			state[arg].loading = true
		})
		builder.addCase(loadUserNotificationsRequest.fulfilled, (state, { payload: { list, total }, meta: { arg } }) => {
			if (list) {
				state[arg] = {
					loading: false,
					total,
					list: [...state[arg].list, ...list.map((n) => ({ ...n, viewed: arg === NotificationViewStatuses.VIEWED }))],
				}
			}
		})
		builder.addMatcher(
			isAnyOf(loadUserNotificationsRequest.fulfilled, loadUserNotificationsRequest.rejected),
			(state, { meta: { arg } }) => {
				state[arg].loading = false
			},
		)
	},
})

export const { markAsViewed } = notificationsSlice.actions

// Selectors
const selectState = (state: { [FEATURE_NAME]: NotificationsState }) => state[FEATURE_NAME]

export const selectNotifications = selectState

export const selectPopperNotifications = createSelector(selectState, (state) => ({
	unviewedList: state[NotificationViewStatuses.UNVIEWED].list.slice(0, DISPLAYED_NOTIFICATIONS_COUNT),
	viewedList: state[NotificationViewStatuses.VIEWED].list.slice(0, DISPLAYED_NOTIFICATIONS_COUNT),
}))

export const selectHasMoreNotifications = (type: NotificationViewStatuses) => (state: RootState) =>
	state[FEATURE_NAME][type].total > DISPLAYED_NOTIFICATIONS_COUNT

export const selectNewNotificationsCount = (state: RootState) => state[FEATURE_NAME].unviewed.total
