import React from 'react'
import { SvgIcon } from '@mui/material'

const CheckSquareIcon = ({ checked, ...rest }: any) => {
	return (
		<SvgIcon {...rest} xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" viewBox="0 0 29 29">
			{checked ? (
				<>
					<defs>
						<linearGradient id="linear-gradient1" x1="0.5" x2="0.5" y2="1" gradientUnits="objectBoundingBox">
							<stop offset="0" stopColor="#009cde" />
							<stop offset="1" stopColor="#00bcde" />
						</linearGradient>
					</defs>
					<g id="group_4705" data-name="group 4705" transform="translate(-786 -889)">
						<rect
							id="sdsa_4705"
							data-name="sdsa_4705"
							width="29"
							height="29"
							rx="3"
							transform="translate(786 889)"
							fill="url(#linear-gradient1)"
						/>
						<g id="Сгруппировать_4174" data-name="Сгруппировать 4174" transform="translate(796.355 899.356)">
							<g id="Exit" transform="translate(-0.001 0)">
								<line
									id="line_4705"
									data-name="line 4705"
									x1="4"
									y1="4"
									transform="translate(-0.355 6.644)"
									fill="none"
									stroke="#fff"
									strokeLinecap="round"
									strokeWidth="1.5"
								/>
								<line
									id="line_4706"
									data-name="line 4706"
									y1="11"
									x2="6"
									transform="translate(3.645 -0.356)"
									fill="none"
									stroke="#fff"
									strokeLinecap="round"
									strokeWidth="1.5"
								/>
							</g>
						</g>
					</g>
				</>
			) : (
				<g id="fre_4705" data-name="fre 4705" transform="translate(-786 -889)">
					<g id="fre_2190" data-name="fre 2190" transform="translate(786 889)" fill="none" stroke="currentColor" strokeWidth="2">
						<rect width="29" height="29" rx="3" stroke="none" />
						<rect x="1" y="1" width="27" height="27" rx="2" fill="none" />
					</g>
				</g>
			)}
		</SvgIcon>
	)
}

export default CheckSquareIcon
