import React from 'react'
import { useSelector } from 'react-redux'
import { Link } from 'react-router-dom'

import { DataTable } from '@/components/EntityManager/DataTable'
import { GridColumns } from '@mui/x-data-grid'

import { ORGANIZATION_PATHS } from '../../constants'
import { getOrganizationsListRequest, selectOrganizationsList } from '../../organizationSlice'

const columns: GridColumns = [
	{
		field: 'name',
		headerName: 'Name',
		filterable: false,
		flex: 1,
		renderCell: (params) => {
			return <Link to={ORGANIZATION_PATHS.SINGLE(params.row.id)}>{params.row.name}</Link>
		},
	},
	{
		field: 'membershipType',
		headerName: 'Admin Type',
		filterable: false,
		flex: 1,
	},
	{
		field: 'roles',
		headerName: 'Roles',
		filterable: false,
		flex: 1,
	},
]

const MyOrganizations = () => {
	const organizationsList = useSelector(selectOrganizationsList)
	return <DataTable data={organizationsList} request={getOrganizationsListRequest} columns={columns} />
}

export default MyOrganizations
