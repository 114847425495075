import Router from '@/components/Router'
import { makeStyles } from '@mui/styles'
import SettingsSidebar from '../components/SettingsSidebar'
import SETTINGS_ROUTES from '../routes'

const useStyles = makeStyles((theme) => ({
	root: {
		width: '100%',
		backgroundColor: theme.colors.white[500],
		display: 'flex',
		flexDirection: 'row',
		[theme.breakpoints.down('sm')]: {
			flexDirection: 'column',
		},
	},
}))

const SettingsPage = () => {
	const classes = useStyles()

	return (
		<div className={classes.root}>
			<SettingsSidebar />
			<Router routes={SETTINGS_ROUTES} />
		</div>
	)
}

export default SettingsPage
