import { IconButton, styled } from '@mui/material'

const DialogCloseButton = styled(IconButton)(({ theme }) => ({
	position: 'absolute',
	right: theme.spacing(1),
	top: theme.spacing(1),
	color: theme.colors.black[500],
}))

export default DialogCloseButton
