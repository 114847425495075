import http, { formDataConfig } from '@/api/http/requestHttp'
import * as config from '@/constants/configuration'

const uploadPhoto = async (file: File, directory?: string, isPrivateImage?: boolean) => {
	const url = `${config.PSU_SCHOOL_ID}/s3/uploads/photoUpload`
	const privateUploadUrl = `${config.PSU_SCHOOL_ID}/s3/uploads/secure/photoUpload`

	const data = new FormData()

	data.append('file', file)
	if (directory) data.append('directory', directory)

	return await http.post(isPrivateImage ? privateUploadUrl : url, data, formDataConfig)
}

export default uploadPhoto
