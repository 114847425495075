import { Tooltip } from '@mui/material'
import { styled } from '@mui/styles'

import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown'

export type IStatusBoxProps = {
	status: string
	helperText?: string
}

const StatusSelect = styled('div')(({ theme }) => ({
	display: 'flex',
	border: `1px solid ${theme.colors.Neutral[500]}`,
	width: '100%',
	justifyContent: 'space-between',
	padding: `${theme.spacing(0.5)} ${theme.spacing(1)}`,
	...theme.shape,
}))

const StatusBox: React.FC<IStatusBoxProps> = ({ status, helperText = 'Double click to edit' }) => {
	return (
		<Tooltip arrow title={helperText}>
			<StatusSelect>
				{status}
				<ArrowDropDownIcon />
			</StatusSelect>
		</Tooltip>
	)
}

export { StatusBox }
