import classNames from 'classnames'
import { NavLink } from 'react-router-dom'

import LogoImage from '@/components/icons/svg/PsuLogo.svg'
import { PATHS } from '@/constants'
import makeStyles from '@mui/styles/makeStyles'

const useStyles = makeStyles((theme) => ({
	root: {
		width: '100%',
		backgroundColor: theme.colors.Primary[1000],
		display: 'flex',
		flexDirection: 'row',
		justifyContent: 'space-between',
		alignItems: 'center',
		background: 'linear-gradient(90deg, rgba(1,31,69,1) 16%, rgba(26,59,116,1) 87%, rgba(1,31,69,1) 100%)',
		[theme.breakpoints.up('md')]: {
			minHeight: 80,
		},
		[theme.breakpoints.up('lg')]: {
			minHeight: 120,
		},
		[theme.breakpoints.up('xl')]: {
			minHeight: 120,
		},
		[theme.breakpoints.down('md')]: {
			minHeight: 80,
		},
	},
	right: {
		width: '40%',
		display: 'flex',
		flexDirection: 'row-reverse',
		alignItems: 'flex-end',
	},
	logo: {
		marginLeft: '4.5vw',
		width: '15vw',
		minWidth: 168,
	},
	logoLink: {
		width: '100%',
		display: 'inline-flex',
		alignItems: 'center',
	},
}))

const Header = ({ children }: any) => {
	const classes = useStyles()

	return (
		<header className={classNames(classes.root, 'u-flexCenteredItemsY')}>
			<NavLink className={classes.logoLink} to={PATHS.ROOT}>
				<img className={classes.logo} src={LogoImage} alt="pennstate logo" />
			</NavLink>
		</header>
	)
}

export default Header
