import http from '@/api/http/requestHttp'
import * as config from '@/constants/configuration'
import { UserInfo } from '@/interfaces/user'

const getUserInfo = async (id: any) => {
	const url = `${config.PSU_SCHOOL_ID}/oms/users/user/${id}`

	return await http.get<UserInfo>(url)
}

export default getUserInfo
