import { v4 as uuidv4 } from 'uuid'

import { UploadErrors } from '@/constants/uploads'
import { ErrorData } from '@/interfaces/common'
import { RootState } from '@/store'
import { MODULE_NAME } from '@/store/app/constants'
import { ExitConfirmationModal, Notification, SnackbarType } from '@/store/app/types'
import { getUserInfoRequest, loadUserConstantsRequest } from '@/store/auth'
import { loadFormsConstantsRequest } from '@/store/forms'
import { loadOrganizationsConstantsRequest } from '@/store/organizations'
import { getUploadErrors } from '@/utils/uploadErrorHandlers'
import { createAction, createAsyncThunk } from '@reduxjs/toolkit'

export const setIsLoading = createAction<boolean>(`${MODULE_NAME}/SET_IS_LOADING`)
export const setError = createAction<ErrorData>(`${MODULE_NAME}/SET_ERROR`)

export const enqueueSnackbar = createAction<{
	key: any
	notification: Notification
}>(`${MODULE_NAME}/ENQUEUE_SNACKBAR`)
export const closeSnackbar = createAction<{ key: any }>(`${MODULE_NAME}/CLOSE_SNACKBAR`)
export const dismissAllSnackbar = createAction(`${MODULE_NAME}/DISMISS_ALL_SNACKBAR`)
export const removeSnackbar = createAction<{ key: any }>(`${MODULE_NAME}/REMOVE_SNACKBAR`)
export const showExitConfirmationModal = createAction<ExitConfirmationModal>(`${MODULE_NAME}/SHOW_EXIT_CONFIRMATION_MODAL`)
export const closeExitConfirmationModal = createAction(`${MODULE_NAME}/CLOSE_EXIT_CONFIRMATION_MODAL`)
export const loadConstantsRequest = createAsyncThunk<
	any,
	undefined,
	{
		state: RootState
	}
>(`${MODULE_NAME}/LOAD_CONSTANTS_REQUEST`, async (_undefined, { dispatch, getState, rejectWithValue }) => {
	try {
		dispatch(setIsLoading(true))

		await dispatch(getUserInfoRequest())

		await Promise.all([
			dispatch(loadUserConstantsRequest()),
			dispatch(loadOrganizationsConstantsRequest()),
			dispatch(loadFormsConstantsRequest()),
		])
	} catch (e: any) {
		return rejectWithValue(e)
	} finally {
		dispatch(setIsLoading(false))
	}
})

export const showUploadErrors = createAsyncThunk<
	any,
	undefined,
	{
		state: RootState
	}
>(`${MODULE_NAME}/SHOW_UPLOAD_ERRORS`, async (_undefiend, { dispatch, getState, rejectWithValue }) => {
	try {
		const uploadErrors = Object.values(UploadErrors).reduce((acc, errKey) => [...acc, ...getUploadErrors(errKey)], [] as string[])

		uploadErrors.forEach((message) => {
			dispatch(
				enqueueSnackbar({
					key: uuidv4(),
					notification: {
						message: {
							type: SnackbarType.error,
							message,
						},
					},
				}),
			)
		})
	} catch (e: any) {
		return rejectWithValue(e)
	}
})
