import React, { useMemo } from 'react'
import { Link } from 'react-router-dom'

import { ROLES } from '@/constants'
import { QUICK_ACTIONS } from '@/utils/quickActions'
import { Typography } from '@mui/material'
import { makeStyles } from '@mui/styles'

import StyledLinksList from './StyledLinksList'

const useStyles = makeStyles((theme) => ({
	actionsList: {
		display: 'flex',
		marginTop: theme.spacing(3.75),
		'& > a': {
			borderRadius: 10,
			width: 200,
			display: 'flex',
			flexDirection: 'column',
			justifyContent: 'center',
			alignItems: 'center',
			boxShadow: '0px 3px 15px #00000029',
			padding: `${theme.spacing(5)} ${theme.spacing(2)}`,
			textDecoration: 'none',
			color: theme.colors.black[500],
			marginRight: theme.spacing(3.75),
			...theme.typography.body1,
			fontWeight: theme.typography.fontWeightMedium,
		},
	},
	actionIcon: {
		color: theme.colors.Primary[700],
		width: theme.spacing(6.75),
		height: theme.spacing(6.75),
		marginBottom: theme.spacing(5.25),
	},
	section: {
		marginBottom: theme.spacing(5.75),
		backgroundColor: theme.colors.white[500],
		padding: `${theme.spacing(5)} ${theme.spacing(7)} ${theme.spacing(6.5)}`,
		borderRadius: 7,
	},
	sectionDescription: {
		color: theme.colors.grey[500],
	},
	sectionName: {
		fontSize: 25,
		lineHeight: '34px',
		fontWeight: theme.typography.fontWeightBold,
	},
}))

export type IQuickActionsProps = {
	role: ROLES
}

const QuickActions: React.FC<IQuickActionsProps> = ({ role }) => {
	const classes = useStyles()

	const availableActions = useMemo(() => {
		return QUICK_ACTIONS.filter((action) => (action.roles && action.roles.includes(role)) || !action.roles)
	}, [role])
	return (
		<div className={classes.section}>
			<Typography className={classes.sectionName}>Quick Actions</Typography>
			<StyledLinksList>
				{availableActions.map((action, index) => {
					const Icon = action.icon
					return (
						<Link key={index} to={action.path}>
							<Icon className={classes.actionIcon} />
							{action.label}
						</Link>
					)
				})}
			</StyledLinksList>
		</div>
	)
}

export { QuickActions }
