import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { Accept, useDropzone } from 'react-dropzone'
import { v4 as uuidv4 } from 'uuid'

import { styled, Theme, Typography } from '@mui/material'

import { FilePreview } from './FilePreview'
import { IDocument } from '../types'
import { AVAILABLE_FILE_TYPES, MAX_UPLOAD_FILE_SIZE } from '../constants'
import { Box } from '@mui/system'

const getColor = (props: any, theme: Theme) => {
	if (props.isDragAccept) {
		return theme.colors.Success[500]
	}
	if (props.isDragReject) {
		return theme.colors.Error[500]
	}
	if (props.isFocused) {
		return theme.colors.Primary[700]
	}
	return theme.colors.grey[300]
}

const DropBox = styled('div', {
	shouldForwardProp: (prop) => !(['isFocused', 'isDragAccept', 'isDragReject', 'disabled'] as PropertyKey[]).includes(prop),
})<{
	disabled?: boolean
	isFocused: boolean
	isDragAccept: boolean
	isDragReject: boolean
}>(({ theme, isFocused, isDragAccept, isDragReject, disabled }) => ({
	minHeight: 200,
	flex: 1,
	display: 'flex',
	justifyContent: 'center',
	alignItems: 'center',
	padding: '20px',
	borderWidth: '2px',
	borderRadius: 10,
	borderStyle: 'dashed',
	borderColor: getColor({ isFocused, isDragAccept, isDragReject }, theme),
	backgroundColor: theme.colors.Neutral[200],
	color: '#bdbdbd',
	outline: 'none',
	transition: 'border .24s ease-in-out',
}))

const defaultAccept = {
	...AVAILABLE_FILE_TYPES.doc.accept,
	...AVAILABLE_FILE_TYPES.ppt.accept,
	...AVAILABLE_FILE_TYPES.pdf.accept,
	...AVAILABLE_FILE_TYPES.excel.accept,
	...AVAILABLE_FILE_TYPES.image.accept,
}

export type IDocumentDropzoneProps = {
	accept?: Accept
	title?: string
	maxSize?: number
	minSize?: number
	multiple?: boolean
	maxFiles?: number
	onChange?: (files) => void
	placeholder?: React.ReactNode
	disabled?: boolean
}

const DocumentDropzone: React.FC<IDocumentDropzoneProps> = ({
	accept = defaultAccept,
	title = `Drag 'n' drop some documents here, or click to select document`,
	maxSize = MAX_UPLOAD_FILE_SIZE,
	minSize,
	multiple = false,
	maxFiles = 1,
	onChange,
	placeholder,
	disabled,
}) => {
	const [files, setFiles] = useState<IDocument[]>([])
	const onDrop = useCallback(
		(acceptedFiles) => {
			const newFiles = [
				...files,
				...acceptedFiles.map((f) => {
					return {
						file: f,
						id: uuidv4(),
					}
				}),
			]
			setFiles(newFiles)
		},
		[files],
	)
	const { getRootProps, getInputProps, isFocused, isDragAccept, isDragReject } = useDropzone({
		onDrop,
		accept,
		maxSize,
		minSize,
		multiple,
		maxFiles,
	})

	const onFileRemove = useCallback((id) => {
		setFiles((files) => {
			return files.filter((f) => f.id !== id)
		})
	}, [])

	const displayFiles = useMemo(
		() => files.map((f, index) => <FilePreview id={f.id} fileName={f.file.name} onRemove={onFileRemove} key={index} />),
		[files, onFileRemove],
	)
	useEffect(() => {
		if (onChange) onChange(files)
	}, [files, onChange])

	return (
		<Box sx={{ pointerEvents: disabled ? 'none' : 'initial' }}>
			{files.length === 0 && (
				<DropBox {...getRootProps({ isFocused, isDragAccept, isDragReject })}>
					<input {...getInputProps()} />
					{placeholder ? (
						placeholder
					) : (
						<>
							<Typography>{title}</Typography>
							<Typography>
								(
								{Object.values(accept)
									.map((v) => v[0])
									.join(' , ')}
								)
							</Typography>
						</>
					)}
				</DropBox>
			)}
			{displayFiles}
		</Box>
	)
}

export { DocumentDropzone }
