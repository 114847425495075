import { useCallback, useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'

import FormReader from '@/components/Form/FormReader'
import { getSubmissionRequest } from '@/features/formSubmissions/formSubmissionsSlice'
import { AppDispatch } from '@/store'

const ViewSubmission = ({
	match: {
		params: { id },
	},
}: any) => {
	const dispatch = useDispatch<AppDispatch>()

	const [submissionData, setSubmissionData] = useState(null)

	const initData = useCallback(async () => {
		const response = await dispatch(getSubmissionRequest(id)).unwrap()
		const readerData = {}
		response.formQuestions.forEach((q) => {
			readerData[q.id] = JSON.parse(q.answer)
		})

		setSubmissionData({
			builder: response.builder,
			readerData,
		})
	}, [dispatch, id])

	useEffect(() => {
		initData()
	}, [initData])

	return (
		<>{submissionData && <FormReader submission={true} disabled={true} data={submissionData.readerData} form={submissionData.builder} />}</>
	)
}

export default ViewSubmission
