import classNames from 'classnames'
import { useCallback, useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'

import { RESOURCES_PATH } from '@/features/resources/constants'
import { getResourcesRequest } from '@/features/resources/resourcesSlice'
import { AppDispatch } from '@/store'
import { PostDto } from '@/types'
import { makeStyles } from '@mui/styles'

import Posts from './Posts'

const useStyles = makeStyles((theme) => ({
	root: {
		padding: `${theme.spacing(3)} ${theme.spacing(2.5)} ${theme.spacing(5)} ${theme.spacing(5)}`,
		borderRadius: 7,
		backgroundColor: theme.colors.white[500],
		display: 'flex',
		flexDirection: 'column',
	},
	post: {
		borderBottom: `1px solid ${theme.colors.Neutral[200]}`,
		paddingBottom: theme.spacing(3.5),
		marginBottom: theme.spacing(4),
	},
}))

const Resources = ({ rootClass = '' }) => {
	const classes = useStyles()
	const dispatch = useDispatch<AppDispatch>()
	const [isLoading, setIsLoading] = useState<boolean>(true)
	const [resources, setResources] = useState<Array<PostDto>>([])
	const [total, setTotal] = useState<number>(0)

	const initResources = useCallback(async () => {
		setIsLoading(true)
		const data = await dispatch(getResourcesRequest({ page: 1 })).unwrap()
		setResources(data.result)
		setTotal(data.total)
		setIsLoading(false)
	}, [dispatch])
	useEffect(() => {
		initResources()
	}, [initResources])
	return (
		<div className={classNames(classes.root, rootClass)}>
			<Posts
				postClass={classes.post}
				data={resources}
				loading={isLoading}
				title="Resources"
				seeAllLink={total > resources.length ? RESOURCES_PATH.ROOT : ''}
			/>
		</div>
	)
}

export default Resources
