import { createReducer } from '@reduxjs/toolkit'
import ROLES from '@/constants/roles'
import { getUserType } from '@/utils/authHandlers'
import * as actions from '@/store/auth/actions'
import { AuthState } from '@/store/auth/types'

const initialState: AuthState = {
	role: ROLES.GUEST,
	firstLogin: false,
	isLoading: false,
	userInfo: {
		campusName: null,
		campusId: 0,
		id: 0,
		externalId: '',
		appUserTypeId: getUserType(ROLES.GUEST),
	},
	campuses: [],
	organizationRoles: {},
}

const authReducer = createReducer(initialState, (builder) => {
	builder
		.addCase(actions.signIn, (state, { payload: { userInfo, role } }) => ({
			...state,
			userInfo,
			role,
		}))
		.addCase(actions.signUp, (state, { payload: { userInfo, role } }) => ({
			...state,
			userInfo,
			role,
			firstLogin: true,
		}))
		.addCase(actions.setUserAfterPageRefresh, (state, { payload: { userInfo, role } }) => ({
			...state,
			userInfo,
			role,
		}))
		.addCase(actions.setIsLoading, (state, action) => ({
			...state,
			isLoading: action.payload,
		}))
		.addCase(actions.signOut, () => ({
			...initialState,
		}))
		.addCase(actions.setUserInfo, (state, action) => ({
			...state,
			userInfo: action.payload.userInfo,
		}))
		.addCase(actions.setCampuses, (state, action) => ({
			...state,
			campuses: action.payload,
		}))
		.addCase(actions.setUserOrganizationRoles, (state, { payload }) => ({
			...state,
			organizationRoles: payload,
		}))
})

export { authReducer }
