import { push } from 'connected-react-router'
import { DateTime } from 'luxon'
import { useCallback, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { DataTable } from '@/components/EntityManager/DataTable'
import { PATHS, ROLES } from '@/constants'
import { getGridAction } from '@/features/events/helpers'
import {
	getSubmissionsListRequest,
	selectSubmissionsList,
	updateSubmissionStatusRequest,
} from '@/features/formSubmissions/formSubmissionsSlice'
import { SubmissionStatuses } from '@/features/formSubmissions/types'
import { AppDispatch } from '@/store'

import { GridColumns } from '@mui/x-data-grid'
import { getUserRole } from '@/store/auth'
import { StatusBox } from '@/components/EntityManager/cellRenderers/StatusBox'
import { Link } from 'react-router-dom'
import { EVENT_PATHS } from '@/features/events/constants'
import { ORGANIZATION_PATHS } from '@/features/organizations/constants'

const Submissions = () => {
	const dispatch = useDispatch<AppDispatch>()
	const currentUserRole = useSelector(getUserRole)

	const submissionsList = useSelector(selectSubmissionsList)

	const handleViewClick = useCallback(
		(id) => () => {
			dispatch(push(PATHS.APP.VIEW_SUBMISSION(id)))
		},
		[dispatch],
	)

	const columns: GridColumns = useMemo(
		() => [
			{
				field: 'category',
				headerName: 'Type',
				flex: 1,
				sortable: true,
			},
			{
				field: 'title',
				headerName: 'Form Name',
				flex: 1,
			},
			{
				field: 'user',
				headerName: 'Submitted By',
				sortable: false,
				flex: 1,
			},
			{
				field: 'createdAt',
				headerName: 'Date Submitted',
				flex: 1,
				sortable: true,
				valueGetter: (params: any) => {
					const date = DateTime.fromISO(params.row.createdAt)
					return date.toFormat(`dd/MM/yyyy 'at' hh:mm a`)
				},
			},
			{
				field: 'entityId',
				headerName: 'Entity',
				flex: 1,
				sortable: false,
				renderCell: (params: any) => {
					if (params.value) {
						switch (params.row.category) {
							case 'Registrations':
								return <Link to={ORGANIZATION_PATHS.SINGLE(params.value)}>Organization</Link>
							case 'Events':
								return <Link to={EVENT_PATHS.VIEW_EVENT(params.value)}>Event</Link>
						}
					}
					return ''
				},
			},
			{
				field: 'status',
				headerName: 'Status',
				editable: currentUserRole === ROLES.CAMPUS_ADMIN,
				type: 'singleSelect',
				valueOptions: Object.values(SubmissionStatuses),
				width: 140,
				renderCell: (params: any) =>
					currentUserRole === ROLES.CAMPUS_ADMIN ? <StatusBox status={params.row.status} /> : params.row.status,
			},
			{
				field: 'actions',
				type: 'actions',
				headerName: 'Actions',
				width: 100,
				cellClassName: 'actions',
				getActions: getGridAction({ handleViewClick }),
			},
		],
		[handleViewClick, currentUserRole],
	)

	const onCellUpdate = useCallback(
		({ id, field, value }) => {
			switch (field) {
				case 'status':
					dispatch(
						updateSubmissionStatusRequest({
							id: id.toString(),
							status: value,
						}),
					)
					break
			}
		},
		[dispatch],
	)
	return <DataTable onCellEditCommit={onCellUpdate} data={submissionsList} request={getSubmissionsListRequest} columns={columns} />
}

export default Submissions
