import { useCallback, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'

import { EVENT_PATHS } from '@/features/events/constants'
import { closeExitConfirmationModal, getExitConfirmationModal } from '@/store/app'

import { ActionModal } from './ActionModal'

const ExitConfirmationModal = () => {
	const dispatch = useDispatch()
	const modalProps = useSelector(getExitConfirmationModal)
	const { push } = useHistory()

	const handleClose = useCallback(() => {
		dispatch(closeExitConfirmationModal())
	}, [dispatch])

	const handleConfirm = useCallback(() => {
		dispatch(closeExitConfirmationModal())
		if (modalProps.onConfirm) modalProps.onConfirm()
		setTimeout(() => push(modalProps.redirectPath || EVENT_PATHS.LIST), 200)
	}, [dispatch, modalProps, push])

	const renderButtons = useMemo(
		() => [
			{
				label: `Yes, leave`,
				onClick: handleConfirm,
				color: '#009cde',
			},
			{
				label: `No, keep working`,
				onClick: handleClose,
			},
		],
		[handleClose, handleConfirm],
	)

	return (
		<ActionModal
			open={modalProps.isOpen}
			handleClose={handleClose}
			renderButtons={renderButtons}
			title="Are you sure you want to leave?"
			subTitle={modalProps.description ?? 'Your work will not be saved.'}
		/>
	)
}

export default ExitConfirmationModal
