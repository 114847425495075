import { Link, styled } from '@mui/material'

const OutlinedPrimaryButton = styled(Link)(({ theme }) => ({
	...theme.typography.body1,
	...theme.shape2,
	height: 'auto',
	padding: `${theme.spacing(1.25)} ${theme.spacing(3.75)}`,
	fontWeight: theme.typography.fontWeightMedium,
	border: `2px solid ${theme.colors.Primary[700]}`,
	textDecoration: 'none',
	textTransform: 'none',
	cursor: 'pointer',
}))

export default OutlinedPrimaryButton
