import { getFormatMediumDate } from '@/utils/dateTime'
import { styled, Table, TableBody, TableCell, tableCellClasses, TableHead, TableRow, Typography } from '@mui/material'

import { UserOrganizationRolesMap } from '../constants'
import { IUserOrganization } from '../types'

const StyledTableCell = styled(TableCell)(({ theme }) => ({
	[`&.${tableCellClasses.head}`]: {
		height: 134,
		...theme.typography.body1,
		fontWeight: theme.typography.fontWeightMedium,
	},
	[`&.${tableCellClasses.body}`]: {
		...theme.typography.body1,
		height: 134,
	},
}))

export type IUserOrganizationsTableProps = {
	data: IUserOrganization[]
}

const tableHeadings = ['Organization', 'Member Type', 'Roles', 'Start Date', 'End Date']

const UserOrganizationsTable: React.FC<IUserOrganizationsTableProps> = ({ data }) => {
	return (
		<Table>
			<TableHead>
				<TableRow>
					{tableHeadings.map((label) => (
						<StyledTableCell key={label}>{label}</StyledTableCell>
					))}
				</TableRow>
			</TableHead>
			<TableBody>
				{data.map((organization) => {
					const startDate = organization.startDate ? getFormatMediumDate(organization.startDate) : 'Past'
					const endDate = organization.endDate ? getFormatMediumDate(organization.endDate) : 'Present'

					return (
						<TableRow key={organization.name}>
							<StyledTableCell>{organization.name}</StyledTableCell>
							<StyledTableCell>{organization.organizationMembershipName}</StyledTableCell>
							<StyledTableCell>
								{Object.keys(UserOrganizationRolesMap).map((key: string) => {
									if (organization[key]) {
										return <Typography>{UserOrganizationRolesMap[key]}</Typography>
									} else {
										return null
									}
								})}
							</StyledTableCell>
							<StyledTableCell>{startDate}</StyledTableCell>
							<StyledTableCell>{endDate}</StyledTableCell>
						</TableRow>
					)
				})}
			</TableBody>
		</Table>
	)
}

export { UserOrganizationsTable }
