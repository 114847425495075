import React from 'react'

import { Close } from '@mui/icons-material'
import { Typography } from '@mui/material'
import { makeStyles } from '@mui/styles'

import { ButtonColors, ColorButton } from './shared'

const useStyles = makeStyles((theme) => ({
	header: {
		marginBottom: theme.spacing(4.5),
	},
	headerTop: {
		display: 'flex',
		justifyContent: 'space-between',
	},
	subHeader: {
		color: theme.colors.grey[500],
		marginTop: theme.spacing(2.75),
		maxWidth: '100%',
	},
	iconContainer: {
		color: theme.colors.Primary[700],
		marginRight: theme.spacing(2.25),
		display: 'inline-flex',
		alignItems: 'center',
		'& .MuiSvgIcon-root': {
			width: 43,
			height: 43,
		},
	},
	title: {
		fontWeight: theme.typography.fontWeightBold,
		color: theme.colors.black[500],
	},
	cancelButton: {
		...theme.shape,
		padding: '8px 30px',
		flex: '0 0 auto',
	},
	leftSide: {
		display: 'flex',
		alignItems: 'center',
	},
}))
export type ICreateFormHeaderProps = {
	title: string
	onCancel?: () => void
	icon?: React.ReactElement
	subHeader?: string
}

const CreateFormHeader: React.FC<ICreateFormHeaderProps> = React.memo(({ onCancel, title, icon, subHeader }) => {
	const classes = useStyles()
	return (
		<div className={classes.header}>
			<div className={classes.headerTop}>
				<div className={classes.leftSide}>
					{icon && <div className={classes.iconContainer}>{icon}</div>}
					<Typography className={classes.title} component="span" variant="h1">
						{title}
					</Typography>
				</div>
				{onCancel && (
					<ColorButton color={ButtonColors.LIGHT_RED} className={classes.cancelButton} startIcon={<Close />} onClick={onCancel}>
						Cancel
					</ColorButton>
				)}
			</div>
			{subHeader && <Typography className={classes.subHeader}>{subHeader}</Typography>}
		</div>
	)
})

export default CreateFormHeader
