import { getFormSubmissionsById } from '@/features/formSubmissions/api'
import { getOrganizations } from '@/features/organizations/api'

export enum TABLE_NAMES {
	ORGANIZATIONS = 'Organizations',
	FORM_SUBMISSIONS = 'Form Submissions',
}

export const DATA_TABLE_MAP = {
	[TABLE_NAMES.ORGANIZATIONS]: {
		api: getOrganizations,
	},
	[TABLE_NAMES.FORM_SUBMISSIONS]: {
		api: getFormSubmissionsById,
	},
}
