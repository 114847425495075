import http from '@/api/http/requestHttp'
import { PSU_SCHOOL_ID } from '@/constants/configuration'

import { ResourceDto, ResourceListParams, ResourceParams, ResourceResultsDto } from './types'

const BASE_URL = `${PSU_SCHOOL_ID}/oms/resources`

export const createResource = async (data: ResourceParams) => {
	return await http.post<ResourceDto>(BASE_URL, data)
}

export const getResources = async (params: ResourceListParams) => {
	return await http.get<ResourceResultsDto>(BASE_URL, { params })
}

export const getResourceById = async (id: string) => {
	const url = `${BASE_URL}/${id}`
	return await http.get<ResourceDto>(url)
}

export const updateResourceById = async (id: string, data: ResourceParams) => {
	const url = `${BASE_URL}/${id}`
	return await http.patch<ResourceDto>(url, data)
}

export const deleteResource = async (id: string) => {
	const url = `${BASE_URL}/${id}`
	return await http.delete(url)
}
