import { styles } from '@/components/shared'
import makeStyles from '@mui/styles/makeStyles'

import { Activity } from '../../types'

const useStyles = makeStyles(() => ({
	card: {
		width: styles.FILL_AVAILABLE_WIDTH,
		background: '#FFFFFF 0% 0% no-repeat padding-box',
		boxShadow: '0px 3px 15px #00000019',
		padding: '14px 16px',
		borderRadius: 10,
	},
	count: {
		font: 'normal normal bold 30px/41px Open Sans',
		marginTop: 10,
	},
	title: {
		font: 'normal normal 600 15px/20px Open Sans',
		marginTop: 6,
	},
	iconWrapper: {
		width: 59,
		height: 59,
	},
}))

export const ActivityCard = ({ activity }: { activity: Activity }) => {
	const classes = useStyles()

	return (
		<div className={classes.card}>
			<div className={classes.iconWrapper}>{activity.Icon && <activity.Icon />}</div>
			<div className={classes.count}>{activity.count}</div>
			<div className={classes.title}>{activity.title}</div>
		</div>
	)
}
