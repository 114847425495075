import { PHOTO_UPLOAD_URL } from '@/constants/configuration'
import { ErrorData, UserData } from '@/interfaces/common'
import { RootState } from '@/store'
import { ListRequestParams, TEntity } from '@/types'
import { ActionCreatorWithPayload, AnyAction, createAsyncThunk, ThunkDispatch } from '@reduxjs/toolkit'
import { AxiosResponse } from 'axios'

export const prepareError = (err: any): ErrorData => {
	let message = err?.message?.toJSON ? err?.message?.toJSON().message : 'Unknown error'
	if (err?.message?.response?.data?.error) {
		message = err.message.response.data.error
	}

	return {
		status: err?.message?.response?.status,
		statusText: err?.message?.response?.statusText,
		message,
	}
}

export const createPhotoUrl = (imageName: string, directory: string) => `${PHOTO_UPLOAD_URL}/${directory}/${imageName}`

export const getFullName = (firstName?: string, lastName?: string) => `${firstName} ${lastName}`

export const scrollIntoElementView = (id: string, timer = 300) => {
	const timeoutId = setTimeout(() => {
		const element = document.getElementById(id)
		element?.scrollIntoView({
			behavior: 'smooth',
			block: 'center',
		})
	}, timer)

	return timeoutId
}

export const prepareListParams = (
	oldParams: ListRequestParams,
	newParams: ListRequestParams,
	dispatch: ThunkDispatch<RootState, unknown, AnyAction>,
	setListParams: ActionCreatorWithPayload<ListRequestParams>,
) => {
	const params = { ...oldParams, ...newParams }

	dispatch(setListParams(params))

	const queryParams = {
		page: params.page,
		limit: params.limit,
	}

	if (params.sortBy && params.sortBy.length > 0) {
		const sortOnce = params.sortBy[0]
		queryParams['sort'] = sortOnce['sort'] === 'asc' ? sortOnce['field'] : `-${sortOnce['field']}`
	}
	if (params.filters && params.filters.items) {
		params.filters.items.forEach((item: any) => {
			queryParams[item.columnField] = item.value
		})
	}
	if (params.q) {
		queryParams['q'] = params.q
	}

	return queryParams
}
export const getNormalizedUserData = (users: { appUser: string[] }[]): UserData[] => {
	return users.reduce((acc: UserData[], { appUser }) => {
		const [id, firstName, lastName, email, externalId, photoUrl] = appUser
		const userData: UserData = {
			id: +id,
			firstName,
			lastName,
			fullName: `${firstName} ${lastName}`,
			email,
			externalId,
			photoUrl,
		}

		return [...acc, userData]
	}, [])
}
export const getNormalizeShiftUser = (user: any): UserData => ({
	id: +user.appUserId,
	firstName: user.firstName,
	lastName: user.lastName,
	fullName: getFullName(user.firstName, user.lastName),
	externalId: user.externalId,
	photoUrl: user.photoUrl,
})

export const createDeletionThunk = (moduleName: string, apiRequest: (id: string) => Promise<AxiosResponse<any, any>>) => {
	return createAsyncThunk<
		any,
		{ id: string; cb?: () => void },
		{
			state: RootState
		}
	>(`${moduleName}/DELETE_BY_ID_REQUEST`, async ({ id, cb }, { rejectWithValue }) => {
		try {
			await apiRequest(id)
			if (cb) cb()
		} catch (e) {
			return rejectWithValue(e)
		}
	})
}

export const DESCRIPTION_MAX_LENGTH = 1000
export const TEXT_INPUT_MAX_LENGTH = 255

export const convertByteToMegaByte = (bytes: number) => {
	return Math.ceil(bytes / (1024 * 1024))
}

export const mapFlatToOption = <TValue>(val: TValue) => ({
	label: `${val}`,
	value: val,
})

export const mapEntityToOption = <TValue extends TEntity>(item: TValue) => ({
	label: item.name,
	value: `${item.id}`,
})
