import { NotificationPages } from './types'

export enum NotificationViewStatuses {
	UNVIEWED = 'unviewed',
	VIEWED = 'viewed',
}

export const DISPLAYED_NOTIFICATIONS_COUNT = 3

export const NOTIFICATIONS_ROOT_PATH = '/app/notifications'
export const NOTIFICATION_PATHS = {
	ROOT: (type?: NotificationPages) => `${NOTIFICATIONS_ROOT_PATH}/${type ? type : ':type?'}`,
}
