import AppRoute from '@/interfaces/route'

import { getAllAuthorizedRoles } from '@/utils/authHandlers'
import { createPageTitle } from '@/utils/page'
import { NOTIFICATION_PATHS } from './constants'
import NotificationsPage from './pages'

const allAuthorizedRolesAllowed = getAllAuthorizedRoles()

const NOTIFICATIONS_TITLE = createPageTitle('Notifications')

const NOTIFICATION_ROUTES: AppRoute[] = [
	{
		component: NotificationsPage,
		title: NOTIFICATIONS_TITLE,
		name: NOTIFICATIONS_TITLE,
		path: NOTIFICATION_PATHS.ROOT(),
		exact: false,
		isPrivate: true,
		allowedRoles: allAuthorizedRolesAllowed,
	},
]

export default NOTIFICATION_ROUTES
