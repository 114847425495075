import { useMemo } from 'react'
import { Control, Controller, FieldErrors } from 'react-hook-form'

import { Card, CommonSelect, ErrorField, ImageUploadField, Label, SelectValue, styles, TextField, UploadField } from '@/components/shared'
import { ERRORS } from '@/constants'
import { Typography } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'

import { CustomDateTimePicker } from '@/components/DateTimePicker/CustomDateTimePicker'
import { EntityAutocomplete } from '@/components/EntityAutocomplete'
import {
	AutocompleteQuestion,
	DatePickerQuestion,
	DropdownQuestion,
	FieldDataType,
	FieldDataWithOtherAnswer,
	FileUploadQuestion,
	FormBuilderQuestion,
	QuestionType,
} from '../FormBuilder/types'
import CheckBoxAnswerCard from './CheckBoxAnswerCard'
import RadioAnswerCard from './RadioAnswerCard'
import TextFieldAnswer from './TextFieldAnswer'
import { FormReaderData, FormValueType, ReaderCardProps, ValueWithOtherAnswer } from './types'
import { IAutocompleteItemDto } from '@/types'
import classNames from 'classnames'
import { getUserInfo, selectCurrentUserCampus } from '@/store/auth'
import { useSelector } from 'react-redux'

const useStyles = makeStyles((theme) => ({
	root: {
		width: styles.FILL_AVAILABLE_WIDTH,
	},
	questionInfo: {
		width: styles.FILL_AVAILABLE_WIDTH,
	},
	label: {
		marginBottom: 7,
	},
	imageUpload: {
		margin: '10px 0',
	},
	preview: {
		width: styles.FILL_AVAILABLE_WIDTH,
		margin: '5px 0',
		color: theme.colors.grey[500],
	},
	inputComponent: {
		width: '100%',
		minWidth: 300,
		margin: `${theme.spacing(1)} 0 0 0`,
	},
	answerCard: {
		marginTop: 0,
		padding: `${theme.spacing(2)} ${theme.spacing(2.5)}`,
	},
}))

interface AnswerCardProps {
	index: number
	data: FormBuilderQuestion<FieldDataType>
	control: Control<FormReaderData, any>
	errors: FieldErrors<FormReaderData>
	disabled?: boolean
	submission?: boolean
	hideDefaultText?: boolean
}

const components = [
	{
		type: QuestionType.TextAnswer,
		component: (props: ReaderCardProps<DropdownQuestion, string>) =>
			props.submission ? <TextFieldAnswer>{props.value}</TextFieldAnswer> : <TextField placeholder="Enter your answer" {...props} />,
	},
	{
		type: QuestionType.Checkboxes,
		component: (props: ReaderCardProps<FieldDataWithOtherAnswer, ValueWithOtherAnswer[]>) => <CheckBoxAnswerCard {...props} />,
	},
	{
		type: QuestionType.Radio,
		component: (props: ReaderCardProps<FieldDataWithOtherAnswer, ValueWithOtherAnswer>) => <RadioAnswerCard {...props} />,
	},
	{
		type: QuestionType.Dropdown,
		component: (props: ReaderCardProps<DropdownQuestion, SelectValue>) => (
			<CommonSelect
				multiple={props.data.multiple}
				placeholder="Select answer"
				options={props.data.answers}
				classnames={{ input: props.className }}
				renderTags={props.data.multiple}
				{...props}
			/>
		),
	},
	{
		type: QuestionType.FileUpload,
		component: (props: ReaderCardProps<FileUploadQuestion, string>) => {
			return <UploadField {...props} />
		},
	},
	{
		type: QuestionType.DatePicker,
		component: (props: ReaderCardProps<DatePickerQuestion, any>) => {
			return (
				<CustomDateTimePicker
					disabled={props.disabled}
					timeSelection={props.data.timePickerType}
					timePickerOptions={{ placeholder: props.data.timePickerPlaceholder }}
					value={props.value}
					onChange={props.onChange}
				/>
			)
		},
	},
	{
		type: QuestionType.Autocomplete,
		component: ({
			data: { entity, multiple },
			onChange,
			disabled,
			submission,
			value,
		}: ReaderCardProps<AutocompleteQuestion, IAutocompleteItemDto>) => {
			return <EntityAutocomplete multiple={multiple} disabled={disabled || submission} entity={entity} onChange={onChange} value={value} />
		},
	},
] as {
	type: QuestionType
	component: React.FC<ReaderCardProps<FieldDataType, FormValueType>>
}[]

const useDefaultValue = (questionType: QuestionType, fieldData: any) => {
	let initialValue: any = ''
	const currentUserCampus = useSelector(selectCurrentUserCampus)
	if (fieldData.generated === 'campuses') {
		initialValue = String(currentUserCampus.id)
	}
	if (fieldData.multiple || questionType === QuestionType.Checkboxes) {
		initialValue = initialValue ? [initialValue] : []
	}
	return initialValue
}

const AnswerCard = ({ index, data, control, errors, disabled = false, submission, hideDefaultText }: AnswerCardProps) => {
	const classes = useStyles()

	const Component = useMemo(() => {
		const component = components.find((c) => c.type === data.questionType)

		return component ? component.component : null
	}, [data.questionType])

	const defaultValue = useDefaultValue(data.questionType, data.fieldData)
	return (
		<Card id={`question-${data.id}`} className={classNames(classes.root, classes.answerCard)}>
			<div className={classes.questionInfo}>
				<Label
					classnames={{
						label: classes.label,
					}}
					title={hideDefaultText ? data.questionText : `Question ${index + 1}`}
					semiBold
					required={data.required}
				>
					{!hideDefaultText && <Typography className={classes.root}>{data.questionText || 'Question'}</Typography>}
					{data.description && (
						<Typography className={classes.preview} variant="subtitle1">
							{data.description}
						</Typography>
					)}
					{data.image && <ImageUploadField className={classes.imageUpload} maxWidth={300} value={data.image} disabled={disabled} />}
				</Label>
				{Component ? (
					<Controller
						name={data.id}
						defaultValue={defaultValue}
						control={control}
						render={({ field: { value, onChange } }) => {
							return (
								<ErrorField hasError={!!errors[data.id]} message={ERRORS.requiredField}>
									<Component
										submission={submission}
										className={classes.inputComponent}
										data={data.fieldData}
										onChange={onChange}
										value={value}
										disabled={disabled}
										error={!!errors[data.id]}
									/>
								</ErrorField>
							)
						}}
					/>
				) : null}
			</div>
		</Card>
	)
}

export default AnswerCard
