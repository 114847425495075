import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import makeStyles from '@mui/styles/makeStyles'
import AttachFileIcon from '@mui/icons-material/AttachFile'

export type IDropzonePlaceholderProps = {
	icon?: any
	label: string
}

const useStyles = makeStyles((theme) => ({
	dropArea: {
		width: '15vw',
		height: '15vw',
		minWidth: 200,
		minHeight: 200,
		borderRadius: 10,
		backgroundColor: theme.colors.grey[200],
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
		justifyContent: 'center',
	},
	attachIcon: {
		width: '5vw',
		height: '5vw',
		minWidth: 75,
		minHeight: 75,
		marginBottom: 15,
	},
}))

const DropzonePlaceholder: React.FC<IDropzonePlaceholderProps> = ({ icon, label }) => {
	const classes = useStyles()
	return (
		<div className={classes.dropArea}>
			{icon ? <FontAwesomeIcon className={classes.attachIcon} icon={icon} size="9x" /> : <AttachFileIcon className={classes.attachIcon} />}
			{label}
		</div>
	)
}

export { DropzonePlaceholder }
