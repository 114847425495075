import makeStyles from '@mui/styles/makeStyles'

import { MetricGroupStats, MetricsGroup } from '../../types'
import { baseStyles } from './baseStyles'
import { MetricCard } from './MetricCard'
import { getValidMetrics } from './utils'

const useStyles = makeStyles(() => ({
	...baseStyles,
	wrapper: {
		...baseStyles.section,
	},
	totalOrganizations: {
		font: 'normal normal normal 18px/24px Open Sans',
		color: '#7B8794',
		borderLeft: '1px solid #7B8794',
		paddingLeft: 10,
		marginLeft: 10,
	},
}))

export const OrganizationsMetricsGroup = ({
	metricsGroupConfig: metricsGroup,
	stats,
}: {
	metricsGroupConfig: MetricsGroup
	stats: MetricGroupStats
}) => {
	const classes = useStyles()

	const validMetrics = getValidMetrics(metricsGroup, stats)

	return (
		<div>
			<div className={classes.gridAreaHeader}>
				<h3 className={classes.gridAreaTitle}>{metricsGroup.title}</h3>
				<span className={classes.totalOrganizations}>{stats.total} total</span>
			</div>
			<div className={classes.metricsGrid}>
				{validMetrics.map((metric, index) => {
					return <MetricCard key={index} {...{ metric, isFloating: true }} />
				})}
			</div>
		</div>
	)
}
