import { FILE_ICONS } from '@/features/documents/constants'

const formats = {
	allImages: 'image/*',
	commonImages: 'image/png, image/jpeg, image/jpg',
	imageJpeg: 'image/jpeg',
	pdf: 'application/pdf',
}

export const AVAILABLE_FILE_TYPES = {
	pdf: {
		accept: {
			'application/pdf': ['.pdf'],
		},
		label: 'Pdf',
		icon: FILE_ICONS['pdf'],
	},
	doc: {
		accept: {
			'application/msword': ['.doc'],
			'application/vnd.openxmlformats-officedocument.wordprocessingml.document': ['.docx'],
		},
		label: 'Word Document',
		icon: FILE_ICONS['doc'],
	},
	image: {
		accept: {
			'image/*': ['.png', '.jpeg'],
		},
		label: 'Image',
		icon: FILE_ICONS['image'],
	},
}

export default formats
