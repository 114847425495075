/* eslint-disable no-useless-escape */

import * as yup from 'yup'

import { faFacebookF, faInstagram, faLinkedinIn, faTwitter, faYoutube } from '@fortawesome/free-brands-svg-icons'
import { faGlobe } from '@fortawesome/free-solid-svg-icons'

export enum URL_TYPES {
	WEBSITE = 'website',
	LINKEDIN = 'linkedin',
	INSTAGRAM = 'instagram',
	FACEBOOK = 'facebook',
	TWITTER = 'twitter',
	YOUTUBE = 'youtube',
}

export const URL_ICONS_MAP = {
	[URL_TYPES.FACEBOOK]: faFacebookF,
	[URL_TYPES.INSTAGRAM]: faInstagram,
	[URL_TYPES.LINKEDIN]: faLinkedinIn,
	[URL_TYPES.TWITTER]: faTwitter,
	[URL_TYPES.YOUTUBE]: faYoutube,
	[URL_TYPES.WEBSITE]: faGlobe,
}

// @TODO: backend only accepts urls with https and doesn't validate anything else, needs to be discussed
export const URL_REGEXPS = {
	[URL_TYPES.FACEBOOK]: /^(http(?:s)?:\/\/)?(?:www\.)?facebook\.com\/([a-zA-Z0-9_]+)$/,
	[URL_TYPES.TWITTER]: /^(http(?:s)?:\/\/)?(?:www\.)?twitter\.com\/([a-zA-Z0-9_]+)$/,
	[URL_TYPES.LINKEDIN]: /^(http(?:s)?:\/\/)?(?:www\.)?linkedin\.com\/(pub|in|profile)/,
	[URL_TYPES.YOUTUBE]:
		/^(?:https?:)?(?:\/\/)?(?:youtu\.be\/|(?:www\.|m\.)?youtube\.com\/(?:watch|v|embed)(?:\.php)?(?:\?.*v=|\/))([a-zA-Z0-9\_-]{7,15})(?:[\?&][a-zA-Z0-9\_-]+=[a-zA-Z0-9\_-]+)*(?:[&\/\#].*)?$/,
	[URL_TYPES.WEBSITE]: /^(http(?:s)?:\/\/)?(?:www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)$/,
	[URL_TYPES.INSTAGRAM]: /^(http(?:s)?:\/\/)?(?:www\.)?instagram\.com\/([a-zA-Z0-9_]+)$/,
}

export const YUP_URLS_SCHEMA = Object.keys(URL_REGEXPS).reduce((acc, urlKey) => {
	acc[urlKey] = yup.lazy((val) => (!val ? yup.string().nullable() : yup.string().matches(URL_REGEXPS[urlKey], `Invalid ${urlKey} url`)))
	return acc
}, {})
