import { CustomDateTimePicker } from '@/components/DateTimePicker/CustomDateTimePicker'
import { FormControlLabel, Radio, RadioGroup, Switch, TextField } from '@mui/material'
import React, { useCallback } from 'react'
import { CardProps, DatePickerQuestion, TimePickers } from '../types'
import { useSettingsChangeHandler } from './hooks/useSettingsChangeHandler'
import { FieldSettingsContainer } from './styledComponents/FieldSettingsContainer'
import { StyledFormControlLabel } from './styledComponents/StyledFormControlLabel'

import { StyledFormControlLabelInline } from './styledComponents/StyledFormControlLabelInline'

type TSettingsKey = keyof DatePickerQuestion

const DatePickerCard = ({ data, onChange, isEdit }: CardProps<DatePickerQuestion>) => {
	const { timePicker, datePickerPlaceholder, timePickerPlaceholder, timePickerType } = data

	const onSettingChange = useCallback(
		(key: TSettingsKey, value: any) => {
			switch (key) {
				case 'timePicker':
					const newData = value ? { [key]: value, timePickerType: TimePickers.dropdown } : { [key]: value, timePickerType: undefined }
					onChange({
						...data,
						...newData,
					})
					break
				default:
					onChange({
						...data,
						[key]: value,
					})
			}
		},
		[onChange, data],
	)

	const onChangeHandler = useSettingsChangeHandler<TSettingsKey>(onSettingChange)
	return (
		<FieldSettingsContainer>
			{isEdit ? (
				<>
					<StyledFormControlLabel
						labelPlacement="top"
						control={
							<TextField value={datePickerPlaceholder} onChange={onChangeHandler('datePickerPlaceholder')} placeholder="Select date" />
						}
						label="Date Picker Placeholder"
					/>
					<StyledFormControlLabelInline
						labelPlacement="start"
						control={<Switch onChange={onChangeHandler('timePicker', 'checked')} checked={timePicker} />}
						label="Allow time picking?"
					/>
					{timePicker && (
						<>
							<StyledFormControlLabel
								labelPlacement="top"
								control={
									<TextField value={timePickerPlaceholder} onChange={onChangeHandler('timePickerPlaceholder')} placeholder="Select time" />
								}
								label="Date Picker Placeholder"
							/>
							<RadioGroup name="timePickerType" value={timePickerType} onChange={onChangeHandler('timePickerType')}>
								<FormControlLabel value={TimePickers.clock} control={<Radio />} label={'Clock'} />
								<FormControlLabel value={TimePickers.dropdown} control={<Radio />} label={'Dropdown'} />
							</RadioGroup>
						</>
					)}
				</>
			) : (
				<CustomDateTimePicker
					disabled={true}
					timeSelection={data.timePickerType}
					timePickerOptions={{ placeholder: data.timePickerPlaceholder }}
					placeholder={data.datePickerPlaceholder}
				/>
			)}
		</FieldSettingsContainer>
	)
}

export { DatePickerCard }
