import isNumber from 'lodash/isNumber'

import { Metric, MetricGroupStats, MetricsGroup } from '../../types'

export const getValidMetrics = (metricsGroup: MetricsGroup, stats: MetricGroupStats): Metric[] => {
	return metricsGroup.metrics.reduce((validMetrics, { title, backgroundColor, getCount, date, url }) => {
		const count = getCount?.(stats)

		if (!isNumber(count)) {
			return validMetrics
		}

		return [...validMetrics, { title, count, backgroundColor, date, url }]
	}, [])
}
