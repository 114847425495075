import React from 'react'
import { Typography } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import classNames from 'classnames'
import { styles } from '../../constants'

const useStyles = makeStyles((theme) => ({
	card: {
		width: styles.FILL_AVAILABLE_WIDTH,
		padding: 20,
		marginTop: 15,
		backgroundColor: theme.colors.white[500],
		borderRadius: 5,
		'&:first-child': {
			marginTop: 0,
		},
	},
	title: {
		fontWeight: 700,
		marginBottom: 15,
	},
}))

interface CardProps {
	title?: string
	children: React.ReactNode
	className?: string
	id?: string
}

const Card = ({ className = '', title = '', id, children }: CardProps) => {
	const classes = useStyles()

	return (
		<div id={id} className={classNames(classes.card, className)}>
			{title && (
				<Typography className={classes.title} variant="body1">
					{title}
				</Typography>
			)}
			{children}
		</div>
	)
}

export default Card
