import http from '@/api/http/requestHttp'
import * as config from '@/constants/configuration'

const signIn = async (id: any, userInfo?: any) => {
	const url = `${config.PSU_SCHOOL_ID}/oms/users/login/${id}`
	const body = { ...userInfo }

	return await http.post<{ user: any; returning: boolean }>(url, body)
}

export default signIn
