import { useCallback, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import FormReader from '@/components/Form/FormReader'
import { FormReaderData } from '@/components/Form/FormReader/types'
import { styles } from '@/components/shared'
import {
	readFormRequest,
	selectFormData,
	selectSubmitFromIsLoading,
	submitFormRequest,
} from '@/features/formSubmissions/formSubmissionsSlice'
import { useErrorAsSnackbar } from '@/hooks/useErrorAsSnackbar'
import { AppDispatch } from '@/store'
import { CircularProgress } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import { PageContentContainer } from '@/components/PageContentContainer'

const useStyles = makeStyles((theme) => ({
	placeholder: {
		width: styles.FILL_AVAILABLE_WIDTH,
		height: '100%',
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
		justifyContent: 'center',
	},
}))

const SubmitForm = ({
	match: {
		params: { id },
	},
}: any) => {
	const classes = useStyles()
	const dispatch = useDispatch<AppDispatch>()
	const isLoading = useSelector(selectSubmitFromIsLoading)
	const data = useSelector(selectFormData)
	useErrorAsSnackbar()
	useEffect(() => {
		const promise = dispatch(readFormRequest(id))
		return () => {
			promise.abort()
		}
	}, [dispatch, id])

	const handleSubmit = useCallback(
		(data: FormReaderData) => {
			dispatch(submitFormRequest({ formId: id, data }))
		},
		[dispatch, id],
	)
	if (isLoading || !data)
		return (
			<div className={classes.placeholder}>
				<CircularProgress />
			</div>
		)

	return (
		<PageContentContainer>
			<FormReader onSubmit={handleSubmit} form={data!.builder} />
		</PageContentContainer>
	)
}

export default SubmitForm
