import makeStyles from '@mui/styles/makeStyles'

import { MetricGroupStats, MetricsGroup } from '../../types'
import { baseStyles } from './baseStyles'
import { MetricCard } from './MetricCard'
import { getValidMetrics } from './utils'

const useStyles = makeStyles(() => ({
	...baseStyles,
	wrapper: {
		...baseStyles.section,
	},
	metricsGrid: {
		...baseStyles.metricsGrid,
		gridTemplateRows: 'repeat(auto-fill, minmax(135px, 1fr)) ',
		gridTemplateColumns: 'unset',
	},
}))

export const UsersMetricsGroup = ({
	metricsGroupConfig: metricsGroup,
	stats,
}: {
	metricsGroupConfig: MetricsGroup
	stats: MetricGroupStats
}) => {
	const classes = useStyles()
	const validMetrics = getValidMetrics(metricsGroup, stats)

	return (
		<div>
			<div className={classes.metricsGrid}>
				{validMetrics.map((metric, index) => {
					return <MetricCard key={index} {...{ metric, alignLeft: true }} />
				})}
			</div>
		</div>
	)
}
