import React from 'react'
import { ModalHeader } from '@/components/Modal/ModalHeader'
import { Dialog, useMediaQuery, useTheme } from '@mui/material'
import { makeStyles } from '@mui/styles'
import CampusAdminForm from './CampusAdminForm'

type ModalProps = {
	open: boolean
	handleClose: () => void
}

const useStyles = makeStyles((theme) => ({
	content: {
		padding: `${theme.spacing(4.5)} ${theme.spacing(6.75)} ${theme.spacing(7)}`,
	},
}))

const CampusAdminModal: React.FC<ModalProps> = ({ open, handleClose }) => {
	const classes = useStyles()
	const theme = useTheme()
	const fullScreen = useMediaQuery(theme.breakpoints.down('sm'))
	return (
		<Dialog fullScreen={fullScreen} fullWidth maxWidth="md" onClose={handleClose} open={open}>
			<ModalHeader handleClose={handleClose} title="Add Campus Admin" />
			<div className={classes.content}>
				<CampusAdminForm onSubmit={handleClose} />
			</div>
		</Dialog>
	)
}

export default CampusAdminModal
