import APP from '@/constants/paths/main'

const paths = {
	ROOT: '/',
	DOWNLOAD_APP: '/download',
	AUTH_REDIRECT: '/redirect',
	ERROR: '/error',
	NOT_FOUND_ERROR: '/404',
	AUTH_ERROR: '/auth-error',
	NEW_USER: '/welcome',
	APP,
}

export default paths
