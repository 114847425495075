import ContentContainer from '@/components/ContentContainer'
import { TABLE_NAMES } from '@/components/EntityManager/config'
import { DataTable } from '@/components/EntityManager/DataTable'
import { QuestionType } from '@/components/Form/FormBuilder/types'
import MiddlePaneLoader from '@/components/MiddlePaneLoader'
import { getFormQuestions } from '@/features/formSubmissions/api'
import { getFormSubmissionsListRequest, resetList, selectFormSubmissionsList } from '@/features/formSubmissions/formSubmissionsSlice'
import { AutocompleteStorageKeys } from '@/utils/autoComplete'
import { Link } from '@mui/material'
import { camelize } from 'humps'
import { isArray } from 'lodash'
import { DateTime } from 'luxon'
import { useEffect, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

type FnGetAnswerAsString = (userAnswer: any, questionData: any, separator?: string) => string

const ARRAY_ANSWER_SEPARATOR = ' ; '

const questionAnswerValueGetterMap: Record<any, FnGetAnswerAsString> = {
	textAnswer: (userAnswer) => {
		return userAnswer
	},
	checkboxes: (userAnswer) => {
		return userAnswer.map((a) => a.answer).join(ARRAY_ANSWER_SEPARATOR)
	},
	multipleChoice: (userAnswer) => {
		if (userAnswer.answer) {
			return userAnswer.answer
		} else {
			console.error(`multipleChoice answer need to contain answer in it`)
			return ''
		}
	},
	dropdown: (userAnswer, questionData) => {
		const selectList = JSON.parse(questionData.data)

		if (selectList.answers && isArray(selectList.answers)) {
			const userSelection = selectList.answers.filter((item) => {
				return isArray(userAnswer) ? userAnswer.includes(item.value) : item.value === userAnswer
			})

			return userSelection ? userSelection.map((item) => item.label).join(', ') : ''
		} else {
			console.error('Data inconsistency in dropdown json')
			return ''
		}
	},
	fileUpload: (userAnswer) => {
		return userAnswer && isArray(userAnswer) ? userAnswer.map((file) => file.location).join(ARRAY_ANSWER_SEPARATOR) : userAnswer
	},
	datePicker: (userAnswer) => {
		if (userAnswer) {
			const date = new Date(userAnswer)
			return DateTime.fromJSDate(date).toLocaleString(DateTime.DATETIME_SHORT_WITH_SECONDS)
		}
		return ''
	},
	autocomplete: (userAnswer, questionData) => {
		const questionInfo = JSON.parse(questionData.data)
		const selector = questionInfo.entity === AutocompleteStorageKeys.Users ? 'fullName' : 'name'
		if (isArray(userAnswer)) {
			return userAnswer.map((item) => item[selector]).join(' | ')
		} else {
			return userAnswer[selector]
		}
	},
}

function displayFormAnswer(questionData, id) {
	return (params) => {
		const mapKey = camelize(questionData.questionType)
		if (questionAnswerValueGetterMap[mapKey]) {
			return questionAnswerValueGetterMap[mapKey](params.row[id], questionData)
		} else {
			console.error(`questionType:${mapKey} is not handled`)
			return ''
		}
	}
}

const defaultColumns = [
	{
		field: 'user',
		headerName: 'Submitted By',
		filterable: false,
		sortable: false,
		minWidth: 200,
	},
	{
		field: 'createdAt',
		headerName: 'Submission Date',
		filterable: false,
		sortable: false,
		minWidth: 200,
	},
	{
		field: 'status',
		headerName: 'Status',
		filterable: false,
		sortable: false,
		minWidth: 100,
	},
]

const ViewFormSubmissions = ({
	match: {
		params: { id },
	},
}: any) => {
	const [questionsList, setQuestionsList] = useState<any>(null)
	const formSubmissionsList = useSelector(selectFormSubmissionsList)
	const dispatch = useDispatch()

	const columns = useMemo(() => {
		return questionsList
			? [
					...defaultColumns,
					...questionsList.map(({ id, question, ...questionData }) => ({
						field: id,
						headerName: question,
						filterable: false,
						resizable: true,
						sortable: false,
						minWidth: 150,
						maxWidth: 400,
						valueGetter: displayFormAnswer(questionData, id),
						renderCell: (params) => {
							if (questionData.questionType === QuestionType.FileUpload && isArray(params.row[id])) {
								return params.row[id].map((file) => (
									<Link download target={'_blank'} key={file.id} href={file.location}>
										{file.originalname}
									</Link>
								))
							}
						},
					})),
			  ]
			: []
	}, [questionsList])

	useEffect(() => {
		;(async () => {
			const { data } = await getFormQuestions(id)
			setQuestionsList(data)
		})()
	}, [id])

	const valueGetters = columns.reduce((acc, item) => {
		acc[item.field] = item.valueGetter
		return acc
	}, {})
	useEffect(() => {
		return () => {
			dispatch(resetList())
		}
	}, [dispatch])
	return (
		<ContentContainer>
			{!questionsList && <MiddlePaneLoader />}
			{questionsList && (
				<DataTable
					apiCallArgs={[id]}
					defaultParams={{ id }}
					tableName={TABLE_NAMES.FORM_SUBMISSIONS}
					request={getFormSubmissionsListRequest}
					columns={columns}
					data={formSubmissionsList}
					valueGetters={valueGetters}
				/>
			)}
		</ContentContainer>
	)
}

export { ViewFormSubmissions }
