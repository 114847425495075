import { useEffect } from 'react'
import { CircularProgress } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import { useSelector } from 'react-redux'
import { PATHS } from '@/constants'
import { getError } from '@/store/app'
import { useHistory } from 'react-router-dom'
import CardLayout from '@/components/CardLayout'
import Card from '@/components/Card'

const useStyles = makeStyles((theme) => ({
	card: {
		paddingBottom: '1vh',
	},
	loader: {
		width: 20,
		height: 20,
	},
}))

const RedirectPage = () => {
	const classes = useStyles()
	const { push } = useHistory()
	const error = useSelector(getError)

	useEffect(() => {
		if (error) {
			push(PATHS.ERROR)
		}
	}, [error, push])

	return (
		<CardLayout>
			<Card className={classes.card}>
				<CircularProgress className={classes.loader} />
			</Card>
		</CardLayout>
	)
}

export default RedirectPage
