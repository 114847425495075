import React from 'react'

import LazyLoadProfileImage from '@/components/LazyLoadProfileImage'
import MiddlePaneLoader from '@/components/MiddlePaneLoader'
import { UserData } from '@/interfaces/common'
import { Typography } from '@mui/material'
import { makeStyles } from '@mui/styles'

const useStyles = makeStyles((theme) => ({
	usersList: {
		display: 'flex',
		flexDirection: 'column',
		marginTop: theme.spacing(5.5),
	},
	userRow: {
		display: 'flex',
	},
	imageContainer: {
		width: theme.spacing(5.875),
		height: theme.spacing(5.875),
		marginRight: theme.spacing(2.25),
	},
	userImage: {
		width: '100%',
		height: '100%',
	},
	userName: {
		fontWeight: theme.typography.fontWeightMedium,
	},
	emptyList: {
		textAlign: 'center',
		padding: `${theme.spacing(24.5)} ${theme.spacing(2)}`,
	},
}))

export type IEventUsersListProps = {
	data: UserData[]
	isLoading?: boolean
	emptyPlaceholder?: React.ReactNode
}

const EventUsersList: React.FC<IEventUsersListProps> = ({ data, isLoading, emptyPlaceholder }) => {
	const classes = useStyles()
	return isLoading ? (
		<MiddlePaneLoader />
	) : (
		<div>
			{data.length === 0 ? (
				<div className={classes.emptyList}>{emptyPlaceholder}</div>
			) : (
				<div className={classes.usersList}>
					{data.map((user) => (
						<div className={classes.userRow}>
							<div className={classes.imageContainer}>
								<LazyLoadProfileImage
									borderRadius={3}
									className={classes.userImage}
									externalUserId={user.externalId}
									photoUrl={user.photoUrl}
								/>
							</div>
							<Typography variant="body1" className={classes.userName}>
								{user.fullName}
							</Typography>
						</div>
					))}
				</div>
			)}
		</div>
	)
}

export { EventUsersList }
