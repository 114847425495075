import { ICustomAutocompleteProps } from '@/components/CustomAutocomplete'
import { organizationsAutocompleteRequest } from '@/features/organizations/organizationSlice'
import { usersAutocompleteRequest, campusAdminCandidatesAutocompleteRequest } from '@/features/users/usersSlice'
import { IAutocompleteItemDto } from '@/types'

const HISTORY_ITEMS_COUNT = 10

export const autocompleteHistory = (history: string | undefined) => {
	const data = localStorage.getItem(history)
	const items = data ? JSON.parse(data) : []
	return {
		setItem: (item: IAutocompleteItemDto) => {
			if (!history) {
				return
			}
			const itemIndex = items.findIndex((i) => i.id === item.id)
			if (itemIndex >= 0) {
				items.splice(itemIndex, 1)
			}
			items.unshift({
				...item,
				history: true,
			})
			if (items.length > HISTORY_ITEMS_COUNT) {
				items.pop()
			}
			localStorage.setItem(history, JSON.stringify(items))
		},
		getItems: (): IAutocompleteItemDto[] => {
			return items
		},
	}
}

export enum AutocompleteStorageKeys {
	Organizations = 'Organizations',
	Users = 'Users',
	CampusAdminCandidates = 'CampusAdminCandidates',
}

export const autocompleteEquality = (option, value) => value && option.id === value.id

export const EntityAutocompleteOptions: Record<AutocompleteStorageKeys, ICustomAutocompleteProps> = {
	[AutocompleteStorageKeys.Users]: {
		placeholder: 'Add user (must be a Penn State student)',
		autocompleteRequest: usersAutocompleteRequest,
		isOptionEqualToValue: autocompleteEquality,
		history: AutocompleteStorageKeys.Users,
		getOptionLabel: (option: any) => `${option.fullName} (${option.email})`,
	},
	[AutocompleteStorageKeys.Organizations]: {
		placeholder: 'Type to search organizations',
		autocompleteRequest: organizationsAutocompleteRequest,
		isOptionEqualToValue: autocompleteEquality,
		history: AutocompleteStorageKeys.Organizations,
		getOptionLabel: (option) => option.name,
	},
	[AutocompleteStorageKeys.CampusAdminCandidates]: {
		placeholder: 'Type to search users',
		autocompleteRequest: campusAdminCandidatesAutocompleteRequest,
		isOptionEqualToValue: autocompleteEquality,
		history: AutocompleteStorageKeys.CampusAdminCandidates,
		getOptionLabel: (option) => `${option.userName} (${option.email})`,
	},
}
