import classNames from 'classnames'
import NotificationsNoneIcon from '@mui/icons-material/NotificationsNone'
import { makeStyles } from '@mui/styles'

const useStyles = makeStyles((theme) => ({
	root: ({ borderRadius }: any) => ({
		position: 'relative',
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
		borderRadius: `${borderRadius}%`,
		backgroundColor: theme.colors.grey[100],
		[theme.breakpoints.down('xs')]: {
			width: '4.5vw',
			height: '4.5vw',
			minWidth: 55,
			minHeight: 55,
		},
	}),
	image: ({ borderRadius }: any) => ({
		width: '100%',
		height: '100%',
		objectFit: 'cover',
		borderRadius: `${borderRadius}%`,
	}),
	notificationIcon: {
		width: '80%',
		height: '80%',
		color: theme.colors.grey[500],
	},
	notificationDot: ({ notificationDot }: any) => ({
		width: notificationDot.width,
		height: notificationDot.height,
		backgroundColor: theme.colors.red[500],
		border: `solid 1px ${theme.colors.white[500]}`,
		borderRadius: '50%',
		position: 'absolute',
		top: notificationDot.top,
		right: notificationDot.right,
	}),
}))

interface NotificationIconProps {
	className?: string
	imageUrl?: string
	alt?: string
	borderRadius?: number
	notificationDot?: {
		width: number
		height: number
		top: number
		right: number
	}
}

const NotificationIcon = ({
	className = '',
	imageUrl,
	alt = 'notification logo',
	borderRadius = 50,
	notificationDot = {
		width: 0,
		height: 0,
		top: 0,
		right: 0,
	},
}: NotificationIconProps) => {
	const classes = useStyles({ borderRadius, notificationDot })

	return (
		<div className={classNames(classes.root, className)}>
			<div className={classes.notificationDot} />
			{imageUrl ? (
				<img className={classes.image} src={imageUrl} alt={alt} />
			) : (
				<NotificationsNoneIcon className={classes.notificationIcon} />
			)}
		</div>
	)
}

export default NotificationIcon
