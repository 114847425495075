import { IAutocompleteItemDto } from '@/types'
import { AutocompleteStorageKeys, EntityAutocompleteOptions } from '@/utils/autoComplete'
import React, { useMemo } from 'react'
import { CustomAutocomplete } from './CustomAutocomplete'

export type IEntityAutocompleteProps = {
	entity: AutocompleteStorageKeys
	onChange?: (item: IAutocompleteItemDto | null) => void
	placeholder?: string
	disabled?: boolean
	value?: IAutocompleteItemDto | IAutocompleteItemDto[]
	multiple?: boolean
}

const EntityAutocomplete: React.FC<IEntityAutocompleteProps> = ({ entity, onChange, placeholder, value, disabled, multiple }) => {
	const options = useMemo(
		() => (placeholder ? { ...EntityAutocompleteOptions[entity], placeholder } : EntityAutocompleteOptions[entity]),
		[entity, placeholder],
	)
	return <CustomAutocomplete multiple={multiple} disabled={disabled} defaultValue={value} onChange={onChange} {...options} />
}

export { EntityAutocomplete }
