import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'

import getOrganizationStats from '@/api/http/organizations/getOrganizationStats'
import { MiddlePaneLayout } from '@/components/layouts/dashboard/MiddleLayout'
import { getUserRole } from '@/store/auth'
import { isAdmin } from '@/utils/authHandlers'

import AdminDashlets from './AdminDashlets'
import Dashlets from './Dashlets'
import MiddlePaneLoader from '@/components/MiddlePaneLoader'

const RETRY_TIMEOUT_MILLIS = 2000

const HomePage = () => {
	const [stats, setStats] = useState(null)
	const userRole = useSelector(getUserRole)

	const loadMetrics = () => {
		return getOrganizationStats().then(({ data }) => {
			setStats(data)
		})
	}

	useEffect(() => {
		let retryTimeout = null
		loadMetrics().catch((err) => {
			retryTimeout = setTimeout(loadMetrics, RETRY_TIMEOUT_MILLIS)
		})

		return () => clearTimeout(retryTimeout)
	}, [])

	return (
		<MiddlePaneLayout>
			{stats ? isAdmin(userRole) ? <AdminDashlets stats={stats} /> : <Dashlets stats={stats} /> : <MiddlePaneLoader />}
		</MiddlePaneLayout>
	)
}

export default HomePage
