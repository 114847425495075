import React, { forwardRef } from 'react'
import classNames from 'classnames'
import { Button, Theme } from '@mui/material'

import makeStyles from '@mui/styles/makeStyles'

interface StyleProps {
	color?: string
	fontWeight?: number
}

const useStyles = makeStyles<Theme, StyleProps>((theme) => ({
	root: ({ color, fontWeight }) => ({
		minWidth: 'auto',
		background: 'none',
		whiteSpace: 'nowrap',
		color: color ?? theme.colors.Primary[700],
		textTransform: 'none',
		boxShadow: 'none',
		padding: 0,
		'&:hover': {
			background: 'none',
			boxShadow: 'none',
			textDecoration: 'underline',
		},
		'&:disabled': {
			backgroundColor: 'none',
			color: color ?? theme.colors.Primary[700],
			textDecoration: 'none',
			cursor: 'not-allowed',
			'&>span': {
				opacity: 0.7,
			},
		},
		...theme.typography.body1,
		fontWeight,
		'&.Mui-disabled': {
			pointerEvents: 'auto',
			background: 'none',
			color: color ?? theme.colors.Primary[700],
			cursor: 'not-allowed',
			'&:hover': {
				background: 'none',
				color: color ?? theme.colors.Primary[700],
				textDecoration: 'none',
				'&>span': {
					opacity: 0.7,
				},
			},
		},
	}),
}))

export interface ButtonProps extends StyleProps {
	children?: React.ReactNode
	onClick?: (e: React.SyntheticEvent<HTMLButtonElement>) => void
	disabled?: boolean
	className?: string
	type?: 'button' | 'reset' | 'submit'
	onMouseEnter?: (e: React.SyntheticEvent<HTMLButtonElement>) => void
	onMouseLeave?: (e: React.SyntheticEvent<HTMLButtonElement>) => void
}

const TextButton = forwardRef<HTMLButtonElement, ButtonProps>(
	({ color, fontWeight = 400, children = null, onClick, disabled, className, type, onMouseEnter, onMouseLeave }, ref) => {
		const classes = useStyles({ color, fontWeight })

		return (
			<Button
				ref={ref}
				type={type}
				onClick={onClick}
				disabled={disabled}
				className={classNames(className, classes.root)}
				variant="contained"
				onMouseEnter={onMouseEnter}
				onMouseLeave={onMouseLeave}
				disableRipple
			>
				{children}
			</Button>
		)
	},
)

export default TextButton
