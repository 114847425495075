import makeStyles from '@mui/styles/makeStyles'
import { styles, TextField } from '@/components/shared'

const useStyles = makeStyles((theme) => ({
	root: {
		width: styles.FILL_AVAILABLE_WIDTH,
		margin: '10px 0',
	},
	input: {
		...theme.typography.body1,
	},
}))

const TextAnswerCard = () => {
	const classes = useStyles()

	return (
		<TextField
			className={classes.root}
			inputProps={{
				className: classes.input,
			}}
			placeholder="Short answer text"
			disabled
		/>
	)
}

export default TextAnswerCard
