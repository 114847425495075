import { getNormalizedUserData, getNormalizeShiftUser } from '@/utils/common'
import DeleteIcon from '@mui/icons-material/DeleteOutlined'
import DownloadIcon from '@mui/icons-material/Download'
import EditIcon from '@mui/icons-material/Edit'
import VisibilityIcon from '@mui/icons-material/Visibility'
import { GridActionsCellItem, GridRowParams } from '@mui/x-data-grid'

export const getShiftsInfo = (shifts: any[]) => {
	let totalSpots = 0
	let bookedSpots = 0
	shifts.forEach(({ maxUsers, users }) => {
		totalSpots += maxUsers
		bookedSpots += users.length
	})
	const availableSpots = totalSpots - bookedSpots

	return {
		totalSpots,
		bookedSpots,
		availableSpots,
	}
}

export const transformVolunteersData = (data: any) => {
	return data.reduce((acc, el, i) => {
		const shiftId = Object.keys(el)[0]
		const userData = getNormalizeShiftUser(el[shiftId])

		let newAcc = {
			...acc,
			[shiftId]: [...(acc[shiftId] || [])],
		}

		if (el[shiftId].appUserId !== null) {
			newAcc = {
				...newAcc,
				[shiftId]: [...(newAcc[shiftId] || []), userData],
			}
		}

		return newAcc
	}, {})
}

export const transformAttendees = (data: any) => {
	return Object.keys(data).reduce((acc, key) => {
		return {
			...acc,
			[key]: getNormalizedUserData(data[key]),
		}
	}, {})
}

type VoidFn = (id: any, row?: any) => () => void

enum GridHandlerActions {
	handleEditClick = 'handleEditClick',
	handleDeleteClick = 'handleDeleteClick',
	handleViewClick = 'handleViewClick',
	handleDownload = 'handleDownload',
}

const ACTION_MAPPER: Record<GridHandlerActions, { icon: any; label: string }> = {
	handleEditClick: {
		icon: <EditIcon />,
		label: 'Edit',
	},
	handleDeleteClick: {
		icon: <DeleteIcon />,
		label: 'Delete',
	},
	handleViewClick: {
		icon: <VisibilityIcon />,
		label: 'View',
	},
	handleDownload: {
		icon: <DownloadIcon />,
		label: 'Download',
	},
}

export const getGridAction = (actions: Record<string, VoidFn>) => (row: GridRowParams<any>) => {
	const { id } = row
	return Object.keys(actions)
		.filter((action) => !!ACTION_MAPPER[action])
		.map((action) => {
			const handler = actions[action]
			const { icon, label } = ACTION_MAPPER[action]
			return <GridActionsCellItem icon={icon} label={label} className="textPrimary" onClick={handler(id, row)} color="inherit" />
		})
}
