import makeStyles from '@mui/styles/makeStyles'
import { Link } from 'react-router-dom'

import { Metric } from '../../types'

const useStyles = makeStyles(() => ({
	card: ({ isFloating, backgroundColor, alignLeft }: any) => ({
		background: `${backgroundColor ? backgroundColor : '#FFFFFF'} 0% 0% no-repeat padding-box`,
		boxShadow: isFloating ? '0px 3px 15px #00000019' : '',
		padding: isFloating ? '14px 16px' : '14px 16px 14px 0',
		borderRadius: 10,
		textAlign: alignLeft ? 'left' : 'center',
		'& a': {
			color: 'inherit',
			textDecoration: 'none',
		},
	}),
	count: {
		font: 'normal normal bold 30px/41px Open Sans',
		marginTop: 17,
	},
	title: {
		font: 'normal normal 600 15px/20px Open Sans',
		marginTop: 6,
		letterSpacing: 0,
		color: '#0D0C22',
	},
	subtitle: {
		font: 'normal normal 600 15px/20px Open Sans',
		marginTop: 2,
		letterSpacing: 0,
		color: '#7B8794',
	},
}))

export const MetricCard = ({
	metric: { url, date, count, title, backgroundColor },
	isFloating,
	alignLeft,
}: {
	metric: Metric
	isFloating?: boolean
	alignLeft?: boolean
}) => {
	const classes = useStyles({ isFloating, alignLeft, backgroundColor: backgroundColor })
	const info = (
		<>
			<div className={classes.count}>{count}</div>
			<div className={classes.title}>{title}</div>
			{date && <div className={classes.subtitle}>(Next {date} days)</div>}
		</>
	)
	return <div className={classes.card}>{url ? <Link to={url}>{info}</Link> : info}</div>
}
