import React, { forwardRef } from 'react'
import classNames from 'classnames'
import { specialColors } from '@navengage/sen-shared-assets'
import { Button as MaterialButton, Theme } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import { ButtonColors, pickButtonColor, createButtonColors } from '../../../theme/colors/buttonColors'

interface StylesProps {
	color?: ButtonColors
}

const useStyles = makeStyles<Theme, StylesProps>((theme) => ({
	root: ({ color }) => {
		const buttonColors = createButtonColors(theme)
		const buttonColor = pickButtonColor(color ?? ButtonColors.PRIMARY, buttonColors)

		return {
			borderRadius: 7,
			backgroundColor: specialColors.transparent,
			color: buttonColor.textColor,
			borderColor: buttonColor.main,
			textTransform: 'none',
			...theme.typography.body1,
			[theme.breakpoints.down('sm')]: {
				...theme.typography.subtitle1,
				padding: 0,
				minWidth: 100,
				minHeight: 36,
			},
		}
	},
}))

export interface ButtonProps extends StylesProps {
	children?: React.ReactNode
	onClick?: (e: React.SyntheticEvent<HTMLButtonElement>) => void
	disabled?: boolean
	className?: string
	type?: 'button' | 'reset' | 'submit'
	onMouseEnter?: (e: React.SyntheticEvent<HTMLButtonElement>) => void
	onMouseLeave?: (e: React.SyntheticEvent<HTMLButtonElement>) => void
	component?: any
	startIcon?: React.ReactNode
	endIcon?: React.ReactNode
}

const OutlinedButton = forwardRef<HTMLButtonElement, ButtonProps>(
	(
		{
			color = ButtonColors.BLUE,
			children = null,
			onClick,
			disabled,
			className,
			type,
			onMouseEnter,
			onMouseLeave,
			component,
			startIcon,
			endIcon,
		},
		ref,
	) => {
		const classes = useStyles({ color })

		return (
			<MaterialButton
				ref={ref}
				type={type}
				onClick={onClick}
				disabled={disabled}
				className={classNames(className, classes.root)}
				variant="outlined"
				onMouseEnter={onMouseEnter}
				onMouseLeave={onMouseLeave}
				component={component}
				startIcon={startIcon}
				endIcon={endIcon}
			>
				{children}
			</MaterialButton>
		)
	},
)

export default OutlinedButton
