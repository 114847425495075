import { AnswerOptionType, FileUploadQuestion, QuestionType } from './types'
import { v4 as uuidv4 } from 'uuid'

import { INTENDED_GRADUATION_TERMS_LIST, TGraduationTerm } from '@/constants/app'
// Types
import { FileTypes } from '@/features/documents/constants'
import { TGenericOption } from '@/types'
// Api calls
import { getListCampuses } from '@/api/http'
import { getListCategories as getEventCategoryList } from '@/features/events/api'
import { getListCategories as getOrganizationCategoryList, getOrganizationTypes } from '@/features/organizations/api'
// Helper functions
import { mapEntityToOption, mapFlatToOption } from '@/utils/common'

// Icons
import AttachFileOutlinedIcon from '@mui/icons-material/AttachFileOutlined'
import AutorenewOutlinedIcon from '@mui/icons-material/AutorenewOutlined'
import CheckBoxOutlinedIcon from '@mui/icons-material/CheckBoxOutlined'
import InsertInvitationIcon from '@mui/icons-material/InsertInvitation'
import ListOutlinedIcon from '@mui/icons-material/ListOutlined'
import RadioButtonCheckedOutlinedIcon from '@mui/icons-material/RadioButtonCheckedOutlined'
import TextFieldsOutlinedIcon from '@mui/icons-material/TextFieldsOutlined'

export const QuestionTypeIcons = {
	[QuestionType.TextAnswer]: <TextFieldsOutlinedIcon />,
	[QuestionType.Checkboxes]: <CheckBoxOutlinedIcon />,
	[QuestionType.Radio]: <RadioButtonCheckedOutlinedIcon />,
	[QuestionType.FileUpload]: <AttachFileOutlinedIcon />,
	[QuestionType.Dropdown]: <ListOutlinedIcon />,
	[QuestionType.Autocomplete]: <AutorenewOutlinedIcon />,
	[QuestionType.DatePicker]: <InsertInvitationIcon />,
}

export const componentOptions = Object.keys(QuestionTypeIcons).map((type) => ({
	label: type,
	value: type,
	icon: QuestionTypeIcons[type],
}))

export const fileUploadDefaultSettings: FileUploadQuestion = {
	maxFileSize: 15,
	fileCount: 1,
	multiple: false,
	allowedFileTypes: FileTypes.pdf,
}

export const INTENDED_GRADUATION_YEARS_COUNT = 5

export const AVAILABLE_DROPDOWN_ENTITIES = {
	eventCategories: 'Event Categories',
	campuses: 'Campuses',
	terms: 'Terms',
	organizationCategories: 'Organization Categories',
	organizationTypes: 'Organization Types',
}

export type TDropdownEntitiesKeys = keyof typeof AVAILABLE_DROPDOWN_ENTITIES

export type TGraduationTermYear = { year: number; term: TGraduationTerm }

export const formatGraduationTerm = (item: TGraduationTermYear) => {
	return `${item.term}, ${item.year}`
}

export const optionTypeMapper = (c: TGenericOption<any>) => ({
	id: uuidv4(),
	type: AnswerOptionType.text,
	answer: c.label,
	value: c.value,
})
// @TODO: add checks for response
export const dropdownGeneratorMap = {
	campuses: async (questionType: QuestionType) => {
		const { data } = await getListCampuses()
		const campuses = data.campuses.map(mapEntityToOption)

		if (questionType === QuestionType.Dropdown) {
			return campuses
		} else {
			return campuses.map(optionTypeMapper)
		}
	},

	eventCategories: async (questionType: QuestionType) => {
		const { data } = await getEventCategoryList()
		const eventCategories = data.categories.map((c) => ({ value: `${c.id}`, label: c.category }))

		if (questionType === QuestionType.Dropdown) {
			return eventCategories
		} else {
			return eventCategories.map(optionTypeMapper)
		}
	},
	terms: async (questionType: QuestionType) => {
		const graduationTermList = generateGraduationTermsAndYears().map(mapFlatToOption) as TGenericOption<string>[]
		if (questionType !== QuestionType.Dropdown) {
			return graduationTermList.map(optionTypeMapper)
		}
		return graduationTermList
	},
	organizationCategories: async (questionType: QuestionType) => {
		const { data } = await getOrganizationCategoryList()
		const categories = data.categories.map((c) => ({ label: c.category, value: `${c.id}` }))
		if (questionType !== QuestionType.Dropdown) {
			return categories.map(optionTypeMapper)
		}
		return categories
	},
	organizationTypes: async (questionType: QuestionType) => {
		const { data } = await getOrganizationTypes()

		const categories = data.map((c) => ({ label: c.name, value: `${c.id}` }))
		if (questionType !== QuestionType.Dropdown) {
			return categories.map(optionTypeMapper)
		}
		return categories
	},
}

export const generateGraduationTermsAndYears = (
	count: number = INTENDED_GRADUATION_YEARS_COUNT,
	includeCurrentYear = true,
	formatter: (item: TGraduationTermYear) => unknown = formatGraduationTerm,
): unknown[] => {
	const now = new Date()
	const currentYear = now.getFullYear()
	const currentMonth = now.getMonth()
	const termsList = []

	if (includeCurrentYear) {
		let termIndex = Math.floor((currentMonth + 1) / INTENDED_GRADUATION_TERMS_LIST.length)
		while (termIndex < INTENDED_GRADUATION_TERMS_LIST.length - 1) {
			termsList.push({
				term: [INTENDED_GRADUATION_TERMS_LIST[termIndex]],
				year: currentYear,
			})
			termIndex++
		}
	}
	for (let index = 1; index <= count; index++) {
		INTENDED_GRADUATION_TERMS_LIST.forEach((graduationTerm) => {
			termsList.push({
				term: graduationTerm,
				year: currentYear + index,
			})
		})
	}

	return termsList.map(formatter)
}
