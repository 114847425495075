import { ROLES } from '@/constants'
import AppRoute from '@/interfaces/route'
import CreateOrganizationPage from '@/pages/createOrganization'
import { createPageTitle } from '@/utils/page'

import { ORGANIZATION_PATHS } from './constants'
import List from './pages/lists'
import ViewOrganizationPage from './pages/view'

const TITLE = createPageTitle('Organizations List')
const CREATE_TITLE = createPageTitle('Create Organization')
const VIEW_TITLE = createPageTitle('View Organization')

const ORGANIZATION_ROUTES: AppRoute[] = [
	{
		component: List,
		title: TITLE,
		name: TITLE,
		path: ORGANIZATION_PATHS.LIST,
		exact: false,
		isPrivate: true,
		allowedRoles: [ROLES.CAMPUS_ADMIN, ROLES.STUDENT_ADMIN],
	},
	{
		component: CreateOrganizationPage,
		title: CREATE_TITLE,
		name: CREATE_TITLE,
		path: ORGANIZATION_PATHS.CREATE,
		exact: true,
		isPrivate: true,
		allowedRoles: [ROLES.CAMPUS_ADMIN, ROLES.STUDENT_ADMIN],
	},
	{
		component: ViewOrganizationPage,
		title: VIEW_TITLE,
		name: VIEW_TITLE,
		path: ORGANIZATION_PATHS.SINGLE(),
		exact: false,
		isPrivate: true,
		allowedRoles: [ROLES.CAMPUS_ADMIN, ROLES.STUDENT_ADMIN],
	},
]

export default ORGANIZATION_ROUTES
