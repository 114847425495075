import { push } from 'connected-react-router'

import * as Cognito from '@/api/Cognito'
import * as http from '@/api/http'
import { PATHS, ROLES } from '@/constants'
import * as config from '@/constants/configuration'
import localStorageKeys from '@/constants/localStorage'
import { UserInfo } from '@/interfaces/user'
import { RootState } from '@/store'
import { MODULE_NAME } from '@/store/auth/constants'
import { OrganizationRoles } from '@/store/auth/types'
import { CampusDto } from '@/types'
import { getMockAccessToken, getTokenExpirationDate, getUserType, handleRefreshToken } from '@/utils/authHandlers'
import { redirectToSignup } from '@/utils/linksHelper'
import LocalStorage from '@/utils/storage'
import { createAction, createAsyncThunk } from '@reduxjs/toolkit'

setInterval(() => {
	handleRefreshToken()
}, config.TOKEN_REFRESH_INTERVAL)

export const setIsLoading = createAction<boolean>(`${MODULE_NAME}/SET_IS_LOADING`)
export const setCampuses = createAction<CampusDto[]>(`${MODULE_NAME}/SET_CAMPUSES`)

export const signIn = createAction<{ userInfo: UserInfo; role: ROLES }>(`${MODULE_NAME}/SIGN_IN`)
export const signUp = createAction<{ userInfo: UserInfo; role: ROLES }>(`${MODULE_NAME}/SIGN_UP`)

export const setUserAfterPageRefresh = createAction<{
	userInfo: UserInfo
	role: ROLES
}>(`${MODULE_NAME}/SET_USER_AFTER_PAGE_REFRESH`)

export const setUserInfo = createAction<{ userInfo: UserInfo }>(`${MODULE_NAME}/SET_USER_INFO`)

export const signOut = createAction(`${MODULE_NAME}/SIGN_OUT`)

export const setUserOrganizationRoles = createAction<OrganizationRoles>(`${MODULE_NAME}/SET_USER_ORGANIZATION_ROLES`)

export const signInRequest = createAsyncThunk(`${MODULE_NAME}/SIGN_IN`, async (code: string, { dispatch, rejectWithValue }) => {
	try {
		dispatch(setIsLoading(true))

		const {
			data: { access_token: newAccessToken, refresh_token: newRefreshToken, expires_in },
		} = await Cognito.signIn(code)

		const tokenExpiration = getTokenExpirationDate(expires_in)

		const refreshTokenExpiration = new Date()
		refreshTokenExpiration.setDate(refreshTokenExpiration.getDate() + config.REFRESH_TOKEN_EXPIRATION)

		LocalStorage.set(localStorageKeys.ACCESS_TOKEN_PATH, newAccessToken)
		LocalStorage.set(localStorageKeys.REFRESH_TOKEN_PATH, newRefreshToken)
		LocalStorage.set(localStorageKeys.ACCESS_TOKEN_EXPIRATION_PATH, JSON.stringify(tokenExpiration))
		LocalStorage.set(localStorageKeys.REFRESH_TOKEN_EXPIRATION_PATH, JSON.stringify(refreshTokenExpiration))

		const {
			data: { username, email, given_name, family_name, profile },
		} = await Cognito.getUserInfo(newAccessToken)

		const externalID = username.slice(0, -8)

		if (externalID) {
			const userData = {
				schoolId: 1,
				email,
				lastName: family_name,
				firstName: given_name,
				externalId: externalID,
				userType: profile,
			}

			const {
				data: { user, returning },
			} = await http.user.signIn(externalID, userData)
			if (!user) {
				throw Error('Something went wrong please try later')
			}
			if (!returning) {
				redirectToSignup(newAccessToken)
				return
			}

			LocalStorage.set(localStorageKeys.CURRENT_USER_ID_PATH, user.id)
			LocalStorage.set(localStorageKeys.CURRENT_USER_EXTERNAL_ID_PATH, user.id)

			dispatch(signIn({ userInfo: user, role: user.omsSystemAccess }))
			dispatch(push(PATHS.APP.ROOT))
		} else {
			console.warn('Error at Dispatching Token')
		}
	} catch (e: any) {
		return rejectWithValue(e)
	} finally {
		dispatch(setIsLoading(false))
	}
})

export const autoSignInRequest = createAsyncThunk(
	`${MODULE_NAME}/AUTO_SIGN_IN`,
	async (redirect: string, { dispatch, rejectWithValue }) => {
		try {
			dispatch(setIsLoading(true))

			const refreshToken = LocalStorage.get(localStorageKeys.REFRESH_TOKEN_PATH)

			const userId = LocalStorage.get(localStorageKeys.CURRENT_USER_ID_PATH)

			const rawRefreshExpiration = LocalStorage.get(localStorageKeys.REFRESH_TOKEN_EXPIRATION_PATH) as string
			const refreshExpiration = rawRefreshExpiration ? JSON.parse(rawRefreshExpiration) : null

			if (refreshToken && refreshExpiration) {
				// Check to see if the refreshExpiration has passed
				const now = new Date()
				const expirationDate = new Date(refreshExpiration)

				if (now <= expirationDate && userId) {
					const { data: user } = await http.user.getUserInfo(userId)

					LocalStorage.set(localStorageKeys.CURRENT_USER_ID_PATH, user.id)
					LocalStorage.set(localStorageKeys.CURRENT_USER_EXTERNAL_ID_PATH, user.externalId)

					dispatch(
						setUserAfterPageRefresh({
							userInfo: user,
							role: user.omsSystemAccess,
						}),
					)
					if (redirect && redirect !== PATHS.ROOT) dispatch(push(redirect))
					else dispatch(push(PATHS.APP.ROOT))
				} else {
					await handleRefreshToken()
					// Couldn't find a refresh token or expiration, need to sign in
					dispatch(autoSignInRequest(redirect))
				}
			} else {
				dispatch(push(PATHS.ROOT))
			}
		} catch (e: any) {
			// return rejectWithValue(e);
		} finally {
			dispatch(setIsLoading(false))
		}
	},
)

export const signOutRequest = createAsyncThunk(`${MODULE_NAME}/SIGN_OUT_REQUEST`, async (__, { dispatch, rejectWithValue }) => {
	try {
		LocalStorage.remove(localStorageKeys.ACCESS_TOKEN_PATH)
		LocalStorage.remove(localStorageKeys.REFRESH_TOKEN_PATH)
		LocalStorage.remove(localStorageKeys.ACCESS_TOKEN_EXPIRATION_PATH)
		LocalStorage.remove(localStorageKeys.REFRESH_TOKEN_EXPIRATION_PATH)
		LocalStorage.remove(localStorageKeys.CURRENT_USER_ID_PATH)
		LocalStorage.remove(localStorageKeys.CURRENT_USER_EXTERNAL_ID_PATH)

		await Cognito.signOut()
	} catch (e: any) {
		return rejectWithValue(e)
	} finally {
		dispatch(signOut())
	}
})

export const loadUserOrganizationRolesRequest = createAsyncThunk<
	any,
	undefined,
	{
		state: RootState
	}
>(`${MODULE_NAME}/LOAD_USER_ORGANIZATION_ROLES_REQUEST`, async (_undefined, { dispatch, getState, rejectWithValue }) => {
	try {
		const { id } = getState().AUTH.userInfo

		const {
			data: { list },
		} = await http.user.getUserOrganizationPermissions(id)

		const orgRoles = list.reduce(
			(acc, { organizationId, organizationMembershipTypeId }) => ({
				...acc,
				[organizationId]: (acc[organizationId] ?? []).concat([organizationMembershipTypeId]),
			}),
			{} as OrganizationRoles,
		)

		dispatch(setUserOrganizationRoles(orgRoles))
	} catch (e: any) {
		return rejectWithValue(e)
	}
})

export const getUserInfoRequest = createAsyncThunk<
	any,
	void,
	{
		state: RootState
	}
>(`${MODULE_NAME}/USER_INFO_REQUEST`, async (__, { dispatch, getState, rejectWithValue }) => {
	dispatch(setIsLoading(true))

	try {
		const { id: userId } = getState()[MODULE_NAME].userInfo!

		const { data: user } = await http.user.getUserInfo(userId)

		dispatch(setUserInfo({ userInfo: user }))
		await dispatch(loadUserOrganizationRolesRequest())
	} catch (e: any) {
		return rejectWithValue(e)
	} finally {
		dispatch(setIsLoading(false))
	}
})

export const devSignInRequest = createAsyncThunk<
	any,
	ROLES,
	{
		state: RootState
	}
>(`${MODULE_NAME}/DEV_SIGN_IN_REQUEST`, async (role, { dispatch, rejectWithValue }) => {
	dispatch(setIsLoading(true))

	try {
		dispatch(push(PATHS.APP.HOME))

		const today = new Date()
		const tomorrow = new Date(today)
		tomorrow.setDate(tomorrow.getDate() + 1)

		let userInfo = {
			campusId: 22,
			schoolId: '1',
			appUserTypeId: getUserType(role),
			acceptedTermsConditions: true,
		} as UserInfo

		switch (role) {
			case ROLES.STUDENT_ADMIN:
				userInfo = {
					...userInfo,
					id: 3,
					firstName: 'Maryne',
					lastName: 'Eva',
					email: 'maryne.eva232131@test.com',
					externalId: 'psu_test_user_3',
				}
				break
			case ROLES.CAMPUS_ADMIN:
				userInfo = {
					...userInfo,
					id: 9,
					firstName: 'Sergio',
					lastName: 'Perez',
					email: 'sergio.perez@test.com',
					externalId: 'psu_test_user_9',
				}
				break
			case ROLES.STUDENT: {
				userInfo = {
					...userInfo,
					firstName: 'Bob',
					lastName: 'Marley',
					email: 'bob.marley@ragge.com',
					id: 10,
					externalId: 'psu_test_user_10',
				}
				break
			}
		}

		LocalStorage.set(localStorageKeys.TEST_ROLE_PATH, role)
		LocalStorage.set(localStorageKeys.ACCESS_TOKEN_PATH, getMockAccessToken(userInfo.id))
		LocalStorage.set(localStorageKeys.REFRESH_TOKEN_PATH, 'mock-refresh-token')
		LocalStorage.set(localStorageKeys.ACCESS_TOKEN_EXPIRATION_PATH, JSON.stringify(tomorrow))
		LocalStorage.set(localStorageKeys.REFRESH_TOKEN_EXPIRATION_PATH, JSON.stringify(tomorrow))
		LocalStorage.set(localStorageKeys.CURRENT_USER_ID_PATH, userInfo.id)
		LocalStorage.set(localStorageKeys.CURRENT_USER_EXTERNAL_ID_PATH, userInfo.id)

		dispatch(signIn({ userInfo, role }))
	} catch (e: any) {
		const customMessage = 'Error occurred while attempting to DEV sign in'
		console.warn(customMessage)

		return rejectWithValue(e)
	} finally {
		dispatch(setIsLoading(false))
	}
})

export const loadUserConstantsRequest = createAsyncThunk(
	`${MODULE_NAME}/LOAD_USER_CONSTANTS_REQUEST`,
	async (_undefined, { dispatch, rejectWithValue }) => {
		try {
			const {
				data: { campuses },
			} = await http.getListCampuses()

			dispatch(setCampuses(campuses))
		} catch (e: any) {
			return rejectWithValue(e)
		}
	},
)
