import { useSelector } from 'react-redux'

import { DataTable } from '@/components/EntityManager/DataTable'
import { GridColumns } from '@mui/x-data-grid'

import { EVENT_LIST_COLUMNS } from '../../constants'
import { getEventsListRequest, selectEventsList } from '../../eventsSlice'

const columns: GridColumns = [
	...EVENT_LIST_COLUMNS,
	{
		field: 'status',
		headerName: 'Status',
		filterable: false,
	},
]

const AllEvents = () => {
	const eventsList = useSelector(selectEventsList)
	return <DataTable data={eventsList} request={getEventsListRequest} columns={columns} />
}

export default AllEvents
