import makeStyles from '@mui/styles/makeStyles'
import { styles, TextField } from '@/components/shared'
import { Typography } from '@mui/material'
import { ChangeEvent } from 'react'
import { FieldDataType, FormBuilderQuestion } from './types'
import { DESCRIPTION_MAX_LENGTH } from '@/utils/common'
import { APP_MESSAGES } from '@/constants'

const useStyles = makeStyles((theme) => ({
	root: {
		width: styles.FILL_AVAILABLE_WIDTH,
		margin: '5px 0',
	},
	preview: {
		width: styles.FILL_AVAILABLE_WIDTH,
		margin: '5px 0',
		color: theme.colors.grey[500],
	},
}))

interface EditDescriptionFieldProps {
	data: FormBuilderQuestion<FieldDataType>
	onChange: (newData: FormBuilderQuestion<FieldDataType>) => void
	disabled?: boolean
	showDescriptionField?: boolean
}

const EditDescriptionField = ({ data, onChange, disabled, showDescriptionField }: EditDescriptionFieldProps) => {
	const classes = useStyles()

	const handelChangeDescription = ({ target: { value } }: ChangeEvent<HTMLInputElement>) => onChange({ ...data, description: value })

	if (!showDescriptionField) return null

	if (disabled)
		return (
			<Typography className={classes.preview} variant="subtitle1">
				{data.description ?? 'Description'}
			</Typography>
		)

	return (
		<TextField
			helperText={APP_MESSAGES.GENERALS.getMaxLengthMessage(DESCRIPTION_MAX_LENGTH)}
			inputProps={{ maxLength: DESCRIPTION_MAX_LENGTH }}
			className={classes.root}
			value={data.description}
			onChange={handelChangeDescription}
			placeholder="Description"
			multiline
		/>
	)
}

export default EditDescriptionField
