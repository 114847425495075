import { Typography } from '@mui/material'
import { makeStyles } from '@mui/styles'

const useStyles = makeStyles((theme) => ({
	messageText: {
		color: theme.colors.Error[400],
		margin: '1vh 0',
	},
}))

interface FieldErrorMessageProps {
	hasError?: boolean
	message?: string
}

const FieldErrorMessage = ({ hasError = false, message = '' }: FieldErrorMessageProps) => {
	const classes = useStyles()

	return hasError ? <Typography className={classes.messageText}>{message}</Typography> : <></>
}

export default FieldErrorMessage
