import { debounce, isArray } from 'lodash'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { useDispatch } from 'react-redux'

import { AutocompleteRequestParams } from '@/features/users/types'
import { AppDispatch, RootState } from '@/store'
import { IAutocompleteItemDto } from '@/types'
import { autocompleteHistory } from '@/utils/autoComplete'
import RestoreIcon from '@mui/icons-material/Restore'
import { Autocomplete, CircularProgress, styled, TextField } from '@mui/material'
import { AsyncThunk } from '@reduxjs/toolkit'

const AUTOCOMPLETE_DEFAULT_LIMIT = 20

const ListItem = styled('li')(({ theme }) => ({
	width: '100%',
	display: 'flex',
	justifyContent: 'space-between',
	'& > span': {
		flex: 1,
		marginRight: 16,
		maxWidth: 'calc(100% - 40px)',
		textOverflow: 'ellipsis',
		whiteSpace: 'nowrap',
		overflow: 'hidden',
	},
	'& > svg': {
		color: theme.colors.Primary[700],
	},
}))

export type ICustomAutocompleteProps = {
	placeholder?: string
	history?: string
	onChange?: (selected: IAutocompleteItemDto | IAutocompleteItemDto[]) => void
	autocompleteRequest: AsyncThunk<
		any,
		AutocompleteRequestParams,
		{
			state: RootState
		}
	>
	isOptionEqualToValue?: (option: any, value: any) => boolean
	getOptionLabel?: (option: any) => string
	disabled?: boolean
	defaultValue?: IAutocompleteItemDto | IAutocompleteItemDto[]
	multiple?: boolean
}

const CustomAutocomplete: React.FC<ICustomAutocompleteProps> = ({
	placeholder = '',
	history,
	onChange,
	autocompleteRequest,
	isOptionEqualToValue,
	getOptionLabel,
	disabled,
	defaultValue,
	multiple,
}) => {
	const historyManager = useMemo(() => autocompleteHistory(history), [history])

	const dispatch = useDispatch<AppDispatch>()
	const [value, setValue] = useState<IAutocompleteItemDto | null | IAutocompleteItemDto[]>(multiple ? [] : null)
	const [items, setItems] = useState<IAutocompleteItemDto[]>([...historyManager.getItems()])
	const [search, setSearch] = useState<string>('')
	const [loading, setLoading] = useState<boolean>(false)

	useEffect(() => {
		if (defaultValue) {
			setValue(defaultValue)
		}
	}, [defaultValue, setValue])

	const handleSearch = async () => {
		if (search) {
			setLoading(true)
			const data = await dispatch(autocompleteRequest({ search, limit: AUTOCOMPLETE_DEFAULT_LIMIT })).unwrap()
			// @TODO inconsistency in the api
			setItems(data.result ?? [...data])
			setLoading(false)
		} else {
			setItems([...historyManager.getItems()])
		}
	}

	const handleChange = (_, newValue) => {
		setValue(newValue)
		if (newValue) {
			if (isArray(newValue)) {
				newValue.forEach((item) => {
					historyManager.setItem({
						...item,
					})
				})
			} else {
				historyManager.setItem({
					...newValue,
				})
			}
		}
		if (onChange) onChange(newValue)
	}

	// eslint-disable-next-line react-hooks/exhaustive-deps
	const delayedSearch = useCallback(
		debounce(() => handleSearch(), 300),
		[search],
	)

	const handleInputChange = (_, search) => {
		setSearch(search)
	}
	useEffect(() => {
		delayedSearch()
	}, [search, delayedSearch])

	return (
		<Autocomplete
			disabled={disabled}
			multiple={multiple}
			fullWidth
			value={value}
			filterOptions={(x) => x}
			onInputChange={handleInputChange}
			onChange={handleChange}
			isOptionEqualToValue={isOptionEqualToValue}
			getOptionLabel={getOptionLabel}
			options={items}
			renderInput={(params) => (
				<TextField
					variant="standard"
					{...params}
					placeholder={multiple && isArray(value) && value.length > 0 ? '' : placeholder}
					InputProps={{
						...params.InputProps,
						endAdornment: (
							<>
								{loading ? <CircularProgress color="inherit" size={20} /> : null}
								{params.InputProps.endAdornment}
							</>
						),
					}}
				/>
			)}
			renderOption={(props, option) => {
				return (
					<ListItem {...props}>
						<span>{getOptionLabel(option)}</span>
						{option.history ? <RestoreIcon /> : null}
					</ListItem>
				)
			}}
		/>
	)
}

export { CustomAutocomplete }
