import { push } from 'connected-react-router'
import React, { useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { ActionModal } from '@/components/ActionModal'
import OutlinedPrimaryButton from '@/components/buttons/OutlinedPrimaryButton'
import { ColorButton, OptionButton } from '@/components/shared'
import usePopper from '@/hooks/usePopper'
import { getUserRole } from '@/store/auth'
import { isStudent } from '@/utils/authHandlers'
import { engagementAppLink, LINK_SCOPES } from '@/utils/linksHelper'
import { ButtonBase, Popper } from '@mui/material'
import { makeStyles } from '@mui/styles'

import { EVENT_PATHS } from '../../constants'
import { deleteEventRequest } from '../../eventsSlice'
import { useDeleteConfirmation } from '../../hooks'

const useStyles = makeStyles((theme) => ({
	actions: {
		display: 'flex',
		marginBottom: theme.spacing(5.625),
	},
	action: {
		minWidth: 205,
		marginRight: theme.spacing(1.5),
		fontWeight: theme.typography.fontWeightMedium,
		'&:disabled,&:disabled:hover': {
			backgroundColor: theme.colors.Neutral[200],
			color: theme.colors.black[500],
		},
	},
	popper: {
		zIndex: 1300,
	},
	actionsDropdown: {
		borderRadius: '10px 0 10px 10px',
		border: `1px solid ${theme.colors.Neutral[300]}`,
		marginTop: theme.spacing(1.75),
		minWidth: 254,
		backgroundColor: theme.colors.white[500],
		padding: `${theme.spacing(1.5)} 0`,
	},
	dropdownItem: {
		width: '100%',
		display: 'block',
		padding: `${theme.spacing(1.25)} ${theme.spacing(3.25)}`,
		borderRadius: 0,
		textAlign: 'left',
		fontWeight: theme.typography.fontWeightMedium,
	},
	modal: {
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
	},
	itemPopupButton: {
		width: '100%',
		textAlign: 'center',
		borderTop: `solid 1px ${theme.colors.grey[200]}`,
		borderRadius: 0,
		color: theme.colors.black[500],
		padding: '20px 0',
	},
	cardHeader: {
		borderBottom: 'none',
	},
	acceptButton: {
		color: theme.colors.Primary[700],
	},
}))

export type IEventActionsProps = {
	id: string
}

const EventActions: React.FC<IEventActionsProps> = ({ id }) => {
	const classes = useStyles()
	const dispatch = useDispatch()

	const deleteCallback = useCallback(() => {
		dispatch(push(EVENT_PATHS.ROOT))
	}, [dispatch])
	const { renderButtons, setConfirmationRequired, confirmationRequired, handleClose } = useDeleteConfirmation(
		deleteEventRequest,
		'Event',
		id,
		deleteCallback,
	)

	const currentUserRole = useSelector(getUserRole)
	const { open, handleToggle, anchorRef, popperRef } = usePopper()
	const appLink = engagementAppLink(LINK_SCOPES.EVENT, id)
	const handleEditClick = () => {
		dispatch(push(EVENT_PATHS.EDIT_EVENT(id)))
	}

	const editHandler = () => {
		if (isStudent(currentUserRole)) {
			handleEditClick()
		} else {
			handleToggle()
		}
	}
	const handleDeleteClick = () => {
		handleToggle()
		setConfirmationRequired(true)
	}
	return (
		<div className={classes.actions}>
			<ColorButton ref={anchorRef} onClick={editHandler} className={classes.action} disabled={open}>
				Edit Event
			</ColorButton>
			<Popper placement="bottom-end" className={classes.popper} anchorEl={anchorRef.current} ref={popperRef} open={open}>
				<div className={classes.actionsDropdown}>
					<OptionButton onClick={handleEditClick} className={classes.dropdownItem}>
						Edit Event
					</OptionButton>
					<OptionButton onClick={handleDeleteClick} className={classes.dropdownItem}>
						Delete Event
					</OptionButton>
				</div>
			</Popper>
			<ButtonBase>
				<OutlinedPrimaryButton target="_blank" href={appLink} className={classes.action} variant="button">
					See on Engagement App
				</OutlinedPrimaryButton>
			</ButtonBase>
			<ActionModal
				handleClose={handleClose}
				open={confirmationRequired}
				renderButtons={renderButtons}
				title="Delete Event"
				description="Anyone who has RSVP’d will be notified that this event has been deleted."
			/>
		</div>
	)
}

export { EventActions }
