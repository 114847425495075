import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import CollapsibleTable from '@/components/CollapsibleTable'
import { styles } from '@/components/shared'
import Tabs from '@/components/Tabs'
import { getFormList, getIsLoadingTemplates, getTemplates, readTemplatesRequest, setFormListTab } from '@/store/forms'
import { CircularProgress } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'

import { templatesColumns, templatesTabs, TemplatesTabs } from './tabsData'

const useStyles = makeStyles((theme) => ({
	root: {
		width: styles.FILL_AVAILABLE_WIDTH,
		padding: '30px 0',
	},
	container: {
		width: styles.FILL_AVAILABLE_WIDTH,
		backgroundColor: theme.colors.white[500],
		border: `solid 1px ${theme.colors.grey[200]}`,
		'-webkit-box-shadow': '0px 3px 4px 2px rgba(0,0,0,0.05)',
		boxShadow: '0px 3px 4px 2px rgba(0,0,0,0.05)',
		padding: '5px 20px 20px 20px',
	},
	tabs: {
		width: styles.FILL_AVAILABLE_WIDTH,
	},
	unselectedTabs: {
		color: theme.colors.black[500],
	},
	placeholder: {
		width: styles.FILL_AVAILABLE_WIDTH,
		minHeight: 300,
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
	},
}))

const TemplatesTab = () => {
	const classes = useStyles()
	const dispatch = useDispatch()

	const { tableTabIndex } = useSelector(getFormList)

	const [activeTabName, setActiveTabName] = useState(TemplatesTabs.ACTIVE)

	const rows = useSelector(getTemplates(activeTabName))

	const isLoading = useSelector(getIsLoadingTemplates)

	const handleChangeTab = (newTabIndex: number) => {
		dispatch(setFormListTab({ tableTabIndex: newTabIndex }))
		setActiveTabName(templatesTabs[newTabIndex].label)
	}

	useEffect(() => {
		dispatch(
			readTemplatesRequest({
				tab: activeTabName,
				isActive: TemplatesTabs.ACTIVE === activeTabName || undefined,
				isDraft: TemplatesTabs.DRAFTS === activeTabName || undefined,
			}),
		)
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [activeTabName])

	return (
		<div className={classes.root}>
			<div className={classes.container}>
				<div className={classes.tabs}>
					<Tabs
						activeTabIndex={tableTabIndex}
						handleChangeTab={handleChangeTab}
						tabs={templatesTabs}
						unselectedTabClass={classes.unselectedTabs}
						borderWidth={1}
						borderColor={'#D7D9DE'}
					/>
				</div>
				{isLoading ? (
					<div className={classes.placeholder}>
						<CircularProgress />
					</div>
				) : (
					<CollapsibleTable columns={templatesColumns[activeTabName]} rows={rows} />
				)}
			</div>
		</div>
	)
}

export default TemplatesTab
