import { AddWorksheetOptions, AutoFilter, Workbook, Worksheet, WorksheetView } from 'exceljs'
import { isFunction } from 'lodash'
import { TFieldMapper } from '../TFieldMapper'
import FileGenerator from './FileGenerator'

/**
 *	Worksheet headers list
 */

export type TWorksheetHeaders = string[]

type TWorksheetConfig = {
	views?: Partial<WorksheetView>[]
	autoFilter?: AutoFilter
	worksheetOptions?: Partial<AddWorksheetOptions>
}
/**
 * Extended default configuration
 */
export type TExcelJsConfigs = {
	defaultHeaders: TFieldMapper
	multipleWorksheets?: boolean
	defaultWorksheetConfig?: TWorksheetConfig
}

/**
 * Simple wrapper classes, which helps to generate excel files and worksheets
 * @export
 * @class ExcelFileGenerator
 * @extends {FileGenerator}
 */
export default class ExcelFileGenerator extends FileGenerator {
	/**
	 * @readonly
	 * @type {string}
	 * @memberof ExcelFileGenerator
	 */
	readonly type: string = 'application/octet-stream'

	/**
	 * File extension
	 *
	 * @type {string}
	 * @memberof ExcelFileGenerator
	 */
	readonly extension: string = 'xlsx'

	/**
	 * Flag for multiple worksheets, based on this new worksheets
	 * will be generated in every addData method call
	 *
	 * @private
	 * @type {boolean}
	 * @memberof ExcelFileGenerator
	 */
	private _multipleWorksheets: boolean
	/**
	 * @private
	 * @type {Workbook}
	 * @memberof ExcelFileGenerator
	 */
	private _workbook: Workbook
	/**
	 * @private
	 * @type {TWorksheetConfig}
	 * @memberof ExcelFileGenerator
	 */
	private _worksheetConfig: TWorksheetConfig

	private _columnLengths = []

	/**
	 * Creates an instance of ExcelFileGenerator.
	 * @param {string} fileName
	 * @param {TExcelJsConfigs} { defaultHeaders, defaultWorksheetConfig, multipleWorksheets }
	 * @memberof ExcelFileGenerator
	 */
	public constructor(protected fileName: string, { defaultHeaders, defaultWorksheetConfig, multipleWorksheets }: TExcelJsConfigs) {
		super(fileName, { defaultHeaders })
		this._workbook = new Workbook()
		this._multipleWorksheets = multipleWorksheets
		this._worksheetConfig = defaultWorksheetConfig
	}

	/**
	 * Adds worksheet to workbook and returns newly created worksheet data
	 * @param {string} worksheetName
	 * @param {TWorksheetHeaders} headers
	 * @param {Partial<AddWorksheetOptions>} [worksheetOptions]
	 * @return {*}  {Worksheet}
	 * @memberof ExcelFileGenerator
	 */
	public addWorksheet(worksheetName: string, headers?: TWorksheetHeaders, worksheetOptions?: Partial<AddWorksheetOptions>): Worksheet {
		const worksheetHeaders = headers ?? this.getDefaultHeaders()
		const worksheet = this._workbook.addWorksheet(worksheetName, this._worksheetConfig?.worksheetOptions)
		worksheet.addRow(worksheetHeaders)
		this._columnLengths.push(worksheetHeaders.map((header) => header.length))
		worksheet.autoFilter = this._worksheetConfig?.autoFilter ?? this.getDefaultAutoFilter(worksheetHeaders)
		worksheet.views = this._worksheetConfig?.views ?? this.getDefaultWOrksheetViews()

		return worksheet
	}

	public getDefaultHeaders() {
		return Object.keys(this._defaultHeaders).map((fieldKey) => this._defaultHeaders[fieldKey].header)
	}

	/**
	 * Adds data to given worksheet, if no worksheet is provided will add to the latest created worksheet
	 *
	 * @param {any[]} data
	 * @param {string[]} cols
	 * @memberof ExcelFileGenerator
	 */
	public addData(data: any[]): void {
		const cols = this._defaultHeaders
		const worksheets = this.getWorksheets()
		const worksheetName = !this._multipleWorksheets ? 'Main' : `Page ${worksheets.length + 1}`

		const currentWorksheet = this._multipleWorksheets || worksheets.length === 0 ? this.addWorksheet(worksheetName) : worksheets[0]
		const worksheetIndex = this.getWorksheets().length - 1
		data.forEach((r) => {
			currentWorksheet.addRow(
				Object.keys(cols).map((c, colIndex) => {
					const colValue =
						this._defaultHeaders[c].getter && isFunction(this._defaultHeaders[c].getter) ? this._defaultHeaders[c].getter({ row: r }) : r[c]
					if (this._columnLengths[worksheetIndex][colIndex] < colValue.length) {
						this._columnLengths[worksheetIndex][colIndex] = colValue.length
					}
					return colValue
				}),
			)
		})
	}
	/**
	 * Returns worksheets array in current ExcelJS workbook
	 *
	 * @return {*}  {Worksheet[]}
	 * @memberof ExcelFileGenerator
	 */
	public getWorksheets(): Worksheet[] {
		return this._workbook.worksheets
	}

	public async getBuffer() {
		return await this._workbook.xlsx.writeBuffer()
	}

	/**
	 * Returns default auto filter option, which columns should auto filter,
	 * by default all columns are filterable, from 1 to length of headers
	 *
	 * @param {TWorksheetHeaders} headers
	 * @return {*}  {AutoFilter}
	 * @memberof ExcelFileGenerator
	 */
	public getDefaultAutoFilter(headers: TWorksheetHeaders): AutoFilter {
		return {
			from: {
				row: 1,
				column: 1,
			},
			to: {
				row: 1,
				column: headers.length,
			},
		}
	}

	/**
	 * Default worksheet view options, freezes first row , which contains headers fo the worksheet
	 *
	 * @return {*}  {Partial<WorksheetView>[]}
	 * @memberof ExcelFileGenerator
	 */
	public getDefaultWOrksheetViews(): Partial<WorksheetView>[] {
		return [{ state: 'frozen', ySplit: 1 }]
	}

	public async downloadFile() {
		this.getWorksheets().forEach((worksheet, worksheetIndex) => {
			worksheet.columns.forEach((col, colIndex) => {
				col.width = 10 + this._columnLengths[worksheetIndex][colIndex]
			})
		})

		super.downloadFile()
	}
}
