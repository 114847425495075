import { FormControlLabel, styled } from '@mui/material'

export const StyledFormControlLabel = styled(FormControlLabel)(({ theme }) => ({
	alignItems: 'initial',
	marginBottom: theme.spacing(2),
	marginLeft: 0,
	marginRight: 0,
	width: '100%',
	'& .MuiFormControl-root': {
		width: '100%',
	},
	'& .MuiTypography-root': {
		marginBottom: theme.spacing(1),
	},
}))
