import { memo, useCallback } from 'react'
import { Route, Switch, useHistory } from 'react-router-dom'

import ContentContainer from '@/components/ContentContainer'
import { Header } from '@/components/EntityManager/Header'
import RouterTabs from '@/components/RouterTabs'
import useSubHeader from '@/hooks/useSubHeader'
import { SubHeaderList } from '@/types'
import { USERS_PATHS } from '../../constants'

import StudentsList from './Students'
import CampusAdminsList from './CampusAdmins'
import { getCampusAdminsListRequest, getUsersListRequest } from '../../usersSlice'

const TABS = [
	{
		label: 'Students',
		value: USERS_PATHS.LIST('students'),
		exact: true,
		component: StudentsList,
	},
	{
		label: 'Campus Admins',
		value: USERS_PATHS.LIST('campus-admins'),
		exact: false,
		component: CampusAdminsList,
	},
]
const SUB_HEADERS: SubHeaderList = [
	{
		text: 'See all students at your campus',
		path: USERS_PATHS.LIST('students'),
	},
	{
		text: 'See Campus Admins at your campus',
		path: USERS_PATHS.LIST('campus-admins'),
	},
]

const getSearchRequest = (location) => {
	if (location.pathname.startsWith(USERS_PATHS.LIST('students'))) {
		return getUsersListRequest
	}
	if (location.pathname.startsWith(USERS_PATHS.LIST('campus-admins'))) {
		return getCampusAdminsListRequest
	}

	return undefined
}

const List = memo(() => {
	const { location, push } = useHistory()
	const subHeader = useSubHeader(SUB_HEADERS)
	const handleAddAdmin = useCallback(() => {
		push(USERS_PATHS.NEW_ADMIN('campus-admins'))
	}, [push])

	const showPrimaryButton = location.pathname.startsWith(USERS_PATHS.LIST('campus-admins'))

	return (
		<ContentContainer>
			<Header
				title="Users"
				handlePrimaryBtn={showPrimaryButton ? handleAddAdmin : undefined}
				primaryBtnText={'Add Campus Admin'}
				searchPlaceholder={'Search users'}
				searchRequest={getSearchRequest(location)}
				subHeader={subHeader?.text}
			/>
			<RouterTabs tabs={TABS} />
			<Switch>
				{TABS.map((route) => (
					<Route key={route.label} exact={route.exact} path={route.value} component={route.component} />
				))}
			</Switch>
		</ContentContainer>
	)
})

export default List
