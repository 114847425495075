import React from 'react'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import Close from '@mui/icons-material/Close'
import { IconButton, Typography } from '@mui/material'
import { makeStyles } from '@mui/styles'

import { FILE_ICONS } from '../constants'

const useStyles = makeStyles((theme) => ({
	filePreview: {
		width: 200,
		height: 200,
		display: 'inline-flex',
		alignItems: 'center',
		justifyContent: 'center',
		position: 'relative',
		flexDirection: 'column',
		'& > svg': {
			color: theme.colors.Primary[700],
		},
	},
	removeFile: {
		color: theme.colors.black[500],
		position: 'absolute',
		right: 0,
		top: 0,
	},
	fileName: {
		marginTop: theme.spacing(2),
		fontWeight: theme.typography.fontWeightMedium,
	},
}))

export type IFilePreviewProps = {
	fileName: string
	onRemove?: (id: string) => void
	id?: string
}

const FilePreview: React.FC<IFilePreviewProps> = ({ fileName, onRemove, id }) => {
	const classes = useStyles()
	const fileNameParts = fileName.split('.')
	const extension = fileNameParts[fileNameParts.length - 1]
	const icon = FILE_ICONS[extension] ?? FILE_ICONS['defaultIcon']
	const handleRemoveClick = () => {
		onRemove(id)
	}
	return (
		<div className={classes.filePreview}>
			{onRemove && (
				<IconButton onClick={handleRemoveClick} className={classes.removeFile}>
					<Close />
				</IconButton>
			)}
			<FontAwesomeIcon icon={icon} size="9x" />
			<Typography className={classes.fileName}>{fileName}</Typography>
		</div>
	)
}

export { FilePreview }
