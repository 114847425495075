import React, { useCallback, useEffect, useState } from 'react'

import { ButtonColors, ColorButton, FilledTextField } from '@/components/shared'
import { SearchFn } from '@/types'
import { Search } from '@mui/icons-material'
import { makeStyles } from '@mui/styles'

// Styles
const useStyles = makeStyles((theme) => ({
	searchBoxContainer: {
		display: 'flex',
		maxWidth: 420,
	},
	searchBox: {
		paddingRight: 0,
		backgroundColor: theme.colors.grey[100],
	},
	searchBtn: {
		borderRadius: '0px 7px 7px 0px',
	},
	searchInputHandlers: {
		height: '100%',
		maxHeight: '100%',
	},
}))
// Props
export type ISearchBoxProps = {
	placeholder?: string
	value?: string
	onSearch: SearchFn
}

const SearchBox: React.FC<ISearchBoxProps> = ({ placeholder, value, onSearch }) => {
	const classes = useStyles()
	const [text, setText] = useState<string>('')
	useEffect(() => {
		setText(value ?? '')
	}, [value])
	const onSearchBtnClick = () => {
		onSearch(text)
	}
	const handleOnChange = useCallback(({ target: { value } }: React.ChangeEvent<HTMLInputElement>) => {
		setText(value)
	}, [])
	const handleSubmit = (event) => {
		event.preventDefault()
		onSearch(text)
	}
	return (
		<form onSubmit={handleSubmit} className={classes.searchBoxContainer}>
			<FilledTextField
				className={classes.searchBox}
				placeholder={placeholder}
				value={text}
				onChange={handleOnChange}
				inputProps={{
					style: {
						paddingRight: 0,
					},
				}}
			/>
			<ColorButton aria-label="Search" className={classes.searchBtn} onClick={() => onSearchBtnClick()}>
				<Search />
			</ColorButton>
		</form>
	)
}

export { SearchBox }
