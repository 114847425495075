import { CUSTOM_REPORT_URL } from '@/utils/quickActions'
import React from 'react'

import { Box, styled, TablePagination } from '@mui/material'
import { gridPaginationSelector, useGridApiContext, useGridSelector } from '@mui/x-data-grid'

import OutlinedPrimaryButton from '../buttons/OutlinedPrimaryButton'

const FooterContainer = styled(Box)(({ theme }) => ({
	display: 'flex',
	borderTop: `1px solid ${theme.colors.grey[300]}`,
	minHeight: 52,
	justifyContent: 'space-between',
	alignItems: 'center',
	paddingTop: theme.spacing(2),
}))

export type IDataTableFooterProps = {
	customReport?: boolean
}

const DataTableFooter: React.FC<IDataTableFooterProps> = ({ customReport = false }) => {
	const apiRef = useGridApiContext()

	const { page, pageSize, rowCount } = useGridSelector(apiRef, gridPaginationSelector)

	const handleChangePage = (event: React.MouseEvent<HTMLButtonElement> | null, newPage: number) => {
		apiRef.current.setPage(newPage)
	}

	const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
		apiRef.current.setPageSize(parseInt(event.target.value, 10))
		apiRef.current.setPage(0)
	}

	return (
		<FooterContainer>
			<div>
				{customReport && (
					<OutlinedPrimaryButton target="_blank" href={CUSTOM_REPORT_URL}>
						Request Custom Report
					</OutlinedPrimaryButton>
				)}
			</div>
			<div>
				<TablePagination
					component="div"
					onPageChange={handleChangePage}
					rowsPerPage={pageSize}
					onRowsPerPageChange={handleChangeRowsPerPage}
					count={rowCount}
					page={page}
				></TablePagination>
			</div>
		</FooterContainer>
	)
}

export { DataTableFooter }
