import { PATHS } from '@/constants'
import AppRoute from '@/interfaces/route'
import LandingPage from '@/pages/landing'
import MainRouter from '@/pages/MainRouter'
import RedirectPage from '@/pages/RedirectPage'
import { getAllAuthorizedRoles, getAllRoles } from '@/utils/authHandlers'
import { createPageTitle } from '@/utils/page'
import AuthErrorPage from '@/pages/NotAuthorized'

const allRolesAllowed = getAllRoles()
const allAuthorizedRolesAllowed = getAllAuthorizedRoles()

const APP_TITLE = createPageTitle('Organization Management System')

const routes: AppRoute[] = [
	{
		component: LandingPage,
		title: APP_TITLE,
		name: APP_TITLE,
		path: PATHS.ROOT,
		exact: true,
		isPrivate: false,
		allowedRoles: allRolesAllowed,
	},
	{
		component: AuthErrorPage,
		title: 'Error',
		name: 'Error',
		path: PATHS.AUTH_ERROR,
		exact: false,
		isPrivate: false,
		allowedRoles: allRolesAllowed,
	},
	{
		component: RedirectPage,
		name: 'redirect-page',
		path: PATHS.AUTH_REDIRECT,
		exact: false,
		isPrivate: false,
		allowedRoles: allRolesAllowed,
	},
	{
		component: MainRouter,
		name: 'root-router',
		path: PATHS.APP.ROOT,
		exact: false,
		isPrivate: true,
		allowedRoles: allAuthorizedRolesAllowed,
	},
]

export default routes
