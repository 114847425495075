import classNames from 'classnames'
import React, { forwardRef } from 'react'

import { CheckBoxOutlineBlank, CheckBoxOutlined } from '@mui/icons-material'
import { Checkbox, FormControlLabel } from '@mui/material'
import { Theme } from '@mui/material/styles'
import makeStyles from '@mui/styles/makeStyles'

import CheckSquareIcon from '../../../icons/CheckSquareIcon'

interface StyleProps {
	variant?: 'primary' | 'secondary'
	value: boolean
	error?: boolean
	textColor?: string
	size?: number
}

const useStyles = makeStyles<Theme, StyleProps>((theme) => ({
	checkBoxIcon: {
		[theme.breakpoints.up('xl')]: {
			width: '1em',
			height: '1em',
		},
	},
	checkBox: ({ variant, error, textColor, size }) => ({
		fontSize: size ?? 18,
		color: error ? theme.colors.red[500] : textColor ? textColor : variant === 'primary' ? theme.colors.grey[500] : theme.colors.white[500],
	}),
	checkBoxLabel: ({ value, variant, error, textColor }) => ({
		color: error
			? theme.colors.red[500]
			: textColor
			? textColor
			: variant === 'primary'
			? value
				? theme.colors.Primary[1500]
				: theme.colors.grey[500]
			: theme.colors.white[500],
	}),
}))

interface CheckBoxProps extends StyleProps {
	disabled?: boolean
	onChange: (v: any) => void
	label?: React.ReactNode
	size?: number
	checkboxClassName?: string
	checkboxLabelClass?: string
}

const CheckBox = forwardRef<any, CheckBoxProps>(
	(
		{
			value = false,
			disabled = false,
			onChange = () => {},
			label = '',
			variant = 'primary',
			error,
			textColor,
			size,
			checkboxClassName = '',
			checkboxLabelClass = '',
		},
		ref,
	) => {
		const classes = useStyles({ value, variant, error, textColor, size })

		return (
			<FormControlLabel
				ref={ref}
				value="end"
				className={classNames(classes.checkBoxLabel, checkboxLabelClass)}
				control={
					<Checkbox
						color="primary"
						classes={{
							root: classNames(classes.checkBox, checkboxClassName),
						}}
						checked={value}
						onChange={onChange}
						disabled={disabled}
						inputProps={{ 'aria-label': 'primary checkbox' }}
						checkedIcon={
							variant === 'primary' ? (
								<CheckBoxOutlined className={classes.checkBoxIcon} />
							) : (
								<CheckSquareIcon checked className={classes.checkBoxIcon} />
							)
						}
						icon={
							variant === 'primary' ? (
								<CheckBoxOutlineBlank className={classes.checkBoxIcon} />
							) : (
								<CheckSquareIcon className={classes.checkBoxIcon} />
							)
						}
					/>
				}
				label={label}
				labelPlacement="end"
			/>
		)
	},
)

export default CheckBox
