import AppRoute from '@/interfaces/route'

import { getAllAuthorizedRoles } from '@/utils/authHandlers'
import { createPageTitle } from '@/utils/page'
import { SETTINGS_PATHS } from './constants'
import { BlockedAccounts } from './pages/BlockedAccounts'
import Notifications from './pages/Notifications'
import { ProfilePrivacy } from './pages/ProfilePrivacy'

const allAuthorizedRolesAllowed = getAllAuthorizedRoles()

const BLOCKED_ACCOUNTS = createPageTitle('Blocked Accounts')
const NOTIFICATIONS_SETTINGS = createPageTitle('Notification Settings')
const PROFILE_SETTINGS = createPageTitle('Profile Settings')

const SETTINGS_ROUTES: AppRoute[] = [
	{
		component: BlockedAccounts,
		title: BLOCKED_ACCOUNTS,
		name: BLOCKED_ACCOUNTS,
		path: SETTINGS_PATHS.BLOCKED_ACCOUNTS,
		exact: true,
		isPrivate: true,
		allowedRoles: allAuthorizedRolesAllowed,
	},
	{
		component: Notifications,
		title: NOTIFICATIONS_SETTINGS,
		name: NOTIFICATIONS_SETTINGS,
		path: SETTINGS_PATHS.NOTIFICATIONS,
		exact: true,
		isPrivate: true,
		allowedRoles: allAuthorizedRolesAllowed,
	},
	{
		component: ProfilePrivacy,
		title: PROFILE_SETTINGS,
		name: PROFILE_SETTINGS,
		path: SETTINGS_PATHS.PROFILE_SETTINGS,
		exact: true,
		isPrivate: true,
		allowedRoles: allAuthorizedRolesAllowed,
	},
]

export default SETTINGS_ROUTES
