import { ButtonColors, IconButtonWithTooltip, styles } from '@/components/shared'
import useOutsideClickCallback from '@/hooks/useOutsideClickCallback'
import ExpandLessIcon from '@mui/icons-material/ExpandLess'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import LockIcon from '@mui/icons-material/Lock'
import { IconButton } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import { useRef, useState } from 'react'

const useStyles = makeStyles((theme) => ({
	root: ({ selected }: any) => ({
		width: styles.FILL_AVAILABLE_WIDTH,
		marginTop: 15,
		border: selected ? `solid 1px ${theme.colors.grey[200]}` : 'none',
		borderRadius: 8,
		backgroundColor: theme.colors.white[500],
	}),
	buttonsContainer: {
		width: styles.FILL_AVAILABLE_WIDTH,
		backgroundColor: theme.colors.grey[200],
		justifyContent: 'space-between',
		display: 'flex',
		alignItems: 'center',
	},
	arrowButton: {
		margin: '5px 0 5px 5px',
		width: 20,
		height: 20,
		backgroundColor: theme.colors.white[500],
		...styles.BOX_SHADOW,
	},
	lockIconContainer: {
		'&:hover': {
			backgroundColor: 'transparent',
		},
	},
}))

export type FnSelectQuestion = (data: { id: string; mandatory?: string }) => void

interface ItemOrdinalScaleContainerProps {
	uniqId: string
	currentIndex: number
	itemsArray: any[]
	onChange: (newArray: any[]) => void
	children: React.ReactNode
	isEdit?: boolean
	onSelectItem?: FnSelectQuestion
	onClickOutside?: () => void
	highlightItem?: boolean
	disabled?: boolean
	maxArrayLength?: number
	value?: boolean
	mandatory?: string
}

const ItemOrdinalScaleContainer = ({
	uniqId,
	currentIndex,
	onChange,
	itemsArray,
	children,
	isEdit = false,
	onSelectItem,
	onClickOutside,
	highlightItem = false,
	disabled = false,
	maxArrayLength,
	value,
	mandatory,
}: ItemOrdinalScaleContainerProps) => {
	const itemRef = useRef<HTMLDivElement | null>(null)

	const preSelected = value ?? isEdit
	const [selected, setSelected] = useState(disabled ? false : preSelected)

	const classes = useStyles({
		selected: disabled ? false : (preSelected && highlightItem) || selected,
	})

	const canMoveUp = !!currentIndex
	const canMoveDown = currentIndex < (maxArrayLength ?? itemsArray.length) - 1
	const isLocked = !!mandatory

	const handleSelect = () => {
		if (disabled) return
		if (onSelectItem) onSelectItem({ id: uniqId, mandatory })
		if (value === undefined) setSelected(true)
	}
	const handleClickOutside = () => {
		if (disabled) return
		if (onClickOutside) onClickOutside()
		if (value === undefined) setSelected(false)
	}

	const changeItemOrdinalScale = (arr: any[], newIndex: number) =>
		arr.reduce((acc, item, index, arr) => {
			if (index === currentIndex) {
				return [...acc, arr[newIndex]]
			} else if (index === newIndex) {
				return [...acc, arr[currentIndex]]
			}

			return [...acc, item]
		}, [] as any[])

	const handleClickDown = () => {
		if (!canMoveDown) return

		onChange(changeItemOrdinalScale(itemsArray, currentIndex + 1))
	}

	const handleClickUp = () => {
		if (!canMoveUp) return

		onChange(changeItemOrdinalScale(itemsArray, currentIndex - 1))
	}

	useOutsideClickCallback(itemRef, handleClickOutside)

	return (
		<div ref={itemRef} id={uniqId} className={classes.root} onClick={handleSelect}>
			{(value ?? selected) && (
				<div className={classes.buttonsContainer}>
					<div>
						<IconButton className={classes.arrowButton} onClick={handleClickUp} disabled={!canMoveUp} size="large">
							<ExpandLessIcon />
						</IconButton>
						<IconButton className={classes.arrowButton} onClick={handleClickDown} disabled={!canMoveDown} size="large">
							<ExpandMoreIcon />
						</IconButton>
					</div>
					{isLocked && (
						<IconButtonWithTooltip
							classnames={{
								button: classes.lockIconContainer,
							}}
							arrow={true}
							color={ButtonColors.WHITE}
							placement="right"
							tooltipTitle="This field is required for this form type."
						>
							<LockIcon />
						</IconButtonWithTooltip>
					)}
				</div>
			)}
			<div>{children}</div>
		</div>
	)
}

export default ItemOrdinalScaleContainer
