import { EntityAutocomplete } from '@/components/EntityAutocomplete'
import { URL_TYPES } from '@/constants/validations'
import { AutocompleteStorageKeys } from '@/utils/autoComplete'
import { faFacebookF, faInstagram, faLinkedinIn, faTwitter, faYoutube } from '@fortawesome/free-brands-svg-icons'
import { faGlobe } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { ReactElement } from 'react'
import { Controller } from 'react-hook-form'

import { ISocialMediaQuestion } from '../types'
import { CategoriesInput } from './questionInput/CategoriesInput'
import { OrgTypeInput } from './questionInput/OrgTypeInput'
import { UserInput } from './questionInput/UserInput'

interface Question {
	label: string
	description?: string
	isRequired?: boolean
	Input: (props: any) => ReactElement
}

export const basicInfoQuestions: Question[] = [
	{
		label: 'Name of Organization',
		isRequired: true,
		Input: (props) => {
			return <UserInput {...{ ...props, inputName: 'name', placeholder: 'What’s the name of this organization?' }} />
		},
	},
	{
		label: 'Description',
		description: 'This will appear on your organization’s page on the Engagement App.',
		isRequired: true,
		Input: (props) => {
			return <UserInput {...{ ...props, inputName: 'description', placeholder: 'Describe this organization' }} />
		},
	},
	{
		label: 'Categories',
		description: 'You can select multiple categories.',
		isRequired: true,
		Input: CategoriesInput,
	},
	{
		label: 'Organization Type',
		description: 'You must select one type.',
		isRequired: true,
		Input: OrgTypeInput,
	},
	{
		label: 'Who is the Super Admin of this organization?',
		description: 'The Super Admin is the primary administrator of an organization. More admins can be added later.',
		isRequired: true,
		Input: (props) => (
			<Controller
				name="superAdminUserId"
				defaultValue=""
				control={props.stateControl.control}
				render={({ field: { value, onChange, name } }) => {
					return <EntityAutocomplete onChange={(item) => onChange(item.id)} entity={AutocompleteStorageKeys.Users} />
				}}
			/>
		),
	},
]

const roleInputRender = (inputName) => (props) =>
	(
		<Controller
			name={inputName}
			defaultValue=""
			control={props.stateControl.control}
			render={({ field: { value, onChange, name } }) => {
				return <EntityAutocomplete onChange={(item) => onChange(item.id)} entity={AutocompleteStorageKeys.Users} />
			}}
		/>
	)

export const roleQuestions: Question[] = [
	{
		label: 'Who is the Primary Leader?',
		description:
			'This person is the current primary leader of their organization. They typically hold the title of President or Executive Director.',
		isRequired: true,
		Input: roleInputRender('rolePrimaryLeaderUserId'),
	},
	{
		label: 'Who is the Primary Contact for Student Affairs?',
		description: 'This person is responsible for receiving and acting upon all information sent from Student Affairs.',
		isRequired: true,
		Input: roleInputRender('rolePrimaryContactAffairsUserId'),
	},
	{
		label: 'Who is the Primary Contact for Finance?',
		description: 'This person is responsible for receiving and acting upon all information regarding their organization’s finances.',
		isRequired: true,
		Input: roleInputRender('rolePrimaryContactFinanceUserId'),
	},
	{
		label: 'Who is the Primary Contact for the Public?',
		description:
			'This person is listed as the Primary Contact of the organization on the Engagement App, and is therefore responsible for receiving and acting upon all information sent from the public.',
		isRequired: true,

		Input: roleInputRender('rolePrimaryContactPublicUserId'),
	},
]

export const socialMediaInputs: ISocialMediaQuestion[] = [
	{
		Icon: ({ className }) => <FontAwesomeIcon className={className} icon={faGlobe} size="2x" />,
		type: URL_TYPES.WEBSITE,
		inputPlaceholder: 'Add website URL (Example: https://www.psu.edu)',
	},
	{
		isSocialMedia: true,
		type: URL_TYPES.LINKEDIN,
		Icon: ({ className }) => <FontAwesomeIcon className={className} icon={faLinkedinIn} color="#ffffff" size="2x" />,
		inputPlaceholder: 'Add LinkedIn URL (Example: https://linkedin.com/in/username)',
	},
	{
		isSocialMedia: true,
		type: URL_TYPES.INSTAGRAM,
		Icon: ({ className }) => <FontAwesomeIcon className={className} icon={faInstagram} color="#ffffff" />,
		inputPlaceholder: 'Add Instagram URL (Example: https://instagram.com/username)',
	},
	{
		isSocialMedia: true,
		type: URL_TYPES.TWITTER,
		Icon: ({ className }) => <FontAwesomeIcon className={className} icon={faTwitter} color="#ffffff" />,
		inputPlaceholder: 'Add Twitter URL (Example: https://twitter.com/username)',
	},
	{
		isSocialMedia: true,
		type: URL_TYPES.YOUTUBE,
		Icon: ({ className }) => <FontAwesomeIcon className={className} icon={faYoutube} color="#ffffff" />,
		inputPlaceholder: 'Add YouTube URL (Example: https://www.youtube.com/watch?v={video_code})',
	},
	{
		isSocialMedia: true,
		type: URL_TYPES.FACEBOOK,
		Icon: ({ className }) => <FontAwesomeIcon className={className} icon={faFacebookF} color="#ffffff" />,
		inputPlaceholder: 'Add Facebook URL (Example: https://facebook.com/username)',
	},
]
