import classNames from 'classnames'
import { Link } from 'react-router-dom'

import MiddlePaneLoader from '@/components/MiddlePaneLoader'
import { PostLinks } from '@/components/post/PostLinks'
import { PostDto } from '@/types'
import { Paper, Typography } from '@mui/material'
import { makeStyles } from '@mui/styles'

const useStyles = makeStyles((theme) => ({
	root: {
		padding: `${theme.spacing(3)} ${theme.spacing(2.5)} ${theme.spacing(5)} ${theme.spacing(5)}`,
		borderRadius: 7,
		backgroundColor: theme.colors.white[500],
	},
	header: {
		display: 'flex',
		justifyContent: 'space-between',
		marginBottom: theme.spacing(3),
		alignItems: 'center',
		'& a': {
			color: theme.colors.Primary[700],
			fontWeight: theme.typography.fontWeightMedium,
			textDecoration: 'none',
		},
	},
	listTitle: {
		fontSize: 25,
		lineHeight: '34px',
		fontWeight: theme.typography.fontWeightBold,
	},
	postList: {
		maxHeight: '100%',
		overflowY: 'auto',
		boxShadow: 'none',
	},
	post: {},
	postTitle: {
		fontWeight: theme.typography.fontWeightMedium,
		marginBottom: theme.spacing(2.25),
	},
	linksList: {
		marginTop: theme.spacing(2),
		height: 30,
	},
	postDescription: {},
	noResults: {
		padding: `${theme.spacing(5)}`,
		textAlign: 'center',
		color: theme.colors.Neutral[500],
	},
}))

export type IPostsProps = {
	title: string
	seeAllLink: string
	loading: boolean
	data: Array<PostDto>
	postClass?: string
}

const Posts: React.FC<IPostsProps> = ({ title, seeAllLink, loading, data, postClass }) => {
	const classes = useStyles()
	return (
		<>
			<div className={classes.header}>
				<Typography className={classes.listTitle}>{title}</Typography>
				{seeAllLink && <Link to={seeAllLink}>See all</Link>}
			</div>
			<Paper className={classes.postList}>
				{loading && <MiddlePaneLoader minHeight={200} />}
				{data.length > 0 && !loading
					? data.map((res) => (
							<div key={res.id} className={classNames(classes.post, postClass)}>
								<Typography className={classes.postTitle}>{res.title}</Typography>
								<Typography className={classes.postDescription}>{res.description}</Typography>
								<div className={classes.linksList}>
									<PostLinks linksData={res} />
								</div>
							</div>
					  ))
					: !loading && <div className={classes.noResults}>No {title}</div>}
			</Paper>
		</>
	)
}

export default Posts
