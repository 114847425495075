import http from '@/api/http/requestHttp'
import { PSU_SCHOOL_ID } from '@/constants/configuration'
import { EntityListParams } from '@/types'

import { FormsListDto, SubmissionsDto, SubmissionStatuses } from './types'

const API_BASE_URL = `${PSU_SCHOOL_ID}/oms/forms`

export const submitForm = async (formId: string, answers: any) => {
	const url = `${API_BASE_URL}/${formId}/submit`
	return await http.post(url, {
		answers,
	})
}

export const getSubmissions = async (params: EntityListParams) => {
	const url = `${API_BASE_URL}/submissions`
	return await http.get<SubmissionsDto>(url, { params })
}

export const updateSubmissionStatus = async (id: string, status: `${SubmissionStatuses}`) => {
	const url = `${API_BASE_URL}/submissions/${id}`

	return await http.patch(url, {
		status,
	})
}

export const getFormsList = async (params: EntityListParams) => {
	return await http.get<FormsListDto>(API_BASE_URL)
}

export const getSubmissionById = async (id: string) => {
	const url = `${API_BASE_URL}/submissions/${id}`
	return await http.get<any>(url)
}
// @TODO: correct response type
export const getFormSubmissionsById = async (id: string, params: EntityListParams) => {
	const url = `${API_BASE_URL}/${id}/submissions`
	return await http.get(url, { params })
}

// @TODO: correct response type
export const getFormQuestions = async (id: string) => {
	const url = `${API_BASE_URL}/${id}/questions`
	return await http.get(url)
}
