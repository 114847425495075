import { useEffect } from 'react'
import { Controller } from 'react-hook-form'
import { v4 as uuidv4 } from 'uuid'

import { ImageUploadField } from '@/components/shared'
import { FILE_URL_PREFIX } from '@/constants/configuration'
import { PhotoUploadDirectories, UploadErrorMessage, UploadErrors } from '@/constants/uploads'
import uploadPhoto from '@/utils/photoUpload'
import { CameraAltOutlined } from '@mui/icons-material'
import { Typography } from '@mui/material'
import { makeStyles } from '@mui/styles'

import { baseStyles } from '../baseStyles'

const useStyles = makeStyles(() => ({
	...baseStyles,
	dropZone: {
		...baseStyles.flexCenteredItems,
		flexDirection: 'column',
		background: '#F2F2F2',
		padding: 80,
		width: 'fit-content',
		minWidth: '45%',
		color: '#7B8794',
		marginTop: 34,
		font: 'normal normal 600 18px/24px Open Sans',
		textAlign: 'center',
	},
	dropZoneInner: {
		...baseStyles.flexCenteredItems,
		flexDirection: 'column',
		background: '#F2F2F2',
		color: '#7B8794',
		font: 'normal normal 600 18px/24px Open Sans',
		textAlign: 'center',
	},
	dropZoneConstraints: {
		font: 'normal normal normal 18px/24px Open Sans',
	},
	dropZoneIcon: {
		width: 77,
		height: 70,
	},
}))

export const OrganizationPhotoSection = ({ stateControl }) => {
	const classes = useStyles()

	const { control } = stateControl

	return (
		<section className={classes.section}>
			<div className={classes.sectionHeader}>
				<Typography className={classes.sectionHeaderTitle} variant="h2">
					Organization Photo
				</Typography>
			</div>
			<div className={classes.genericQuestion}>
				<h3 className={classes.questionLabel}>
					Add a Photo
					<span className={classes.questionRequiredIndicator}>*</span>
				</h3>
				This photo will act as this organization’s profile picture on the Engagement App.
				<Controller name="photoUrl" control={control} render={(props) => <ControlledPhotoUploader {...{ ...props, classes }} />} />
			</div>
		</section>
	)
}

const ControlledPhotoUploader = ({ field: { value, onChange }, classes }) => {
	const handleClearUploadImageField = () => {}

	const uploadPhotoByBlobUrl = async (blobUrl) => {
		const { data } = await uploadPhoto({
			fileUrl: blobUrl,
			imageName: uuidv4(),
			directory: PhotoUploadDirectories.ORGANIZATION,
			options: {
				compressImage: true,
				onError: {
					uploadErrors: UploadErrors.ORGANIZATION,
					uploadErrorMessage: UploadErrorMessage.POST,
				},
			},
		})
		onChange(`${FILE_URL_PREFIX}${data.fileName}`)
	}

	useEffect(() => {
		if (!value.startsWith('blob:')) {
			return
		}
		uploadPhotoByBlobUrl(value)
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [value])

	return (
		<div className={classes.dropZone}>
			<ImageUploadField
				className={classes.dropZoneInner}
				value={value}
				onClear={handleClearUploadImageField}
				onChange={onChange}
				cameraIcon={<CameraAltOutlined className={classes.dropZoneIcon} />}
				placeholder={
					<>
						<p>Upload Photo</p>
						<p className={classes.dropZoneConstraints}>Preferred pixels: 1280:960px (Aspect ratio: 4:3)</p>
						<p className={classes.dropZoneConstraints}>Preferred file size: 5mb</p>
					</>
				}
			/>
		</div>
	)
}
