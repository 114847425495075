import { push } from 'connected-react-router'
import { v4 as uuidv4 } from 'uuid'

import { LIST_INITIAL_STATE } from '@/constants/app'
import * as config from '@/constants/configuration'
import { PhotoUploadDirectories, UploadErrorMessage, UploadErrors } from '@/constants/uploads'
import { RootState } from '@/store'
import { closeSnackbar, enqueueSnackbar } from '@/store/app'
import { SnackbarType } from '@/store/app/types'
import { EntityListParams, ListRequestParams } from '@/types'
import { isAdmin } from '@/utils/authHandlers'
import { createPhotoUrl, prepareListParams } from '@/utils/common'
import { getRoundedTime, mergeDateTime } from '@/utils/dateTime'
import { createFieldUpdateToast, creationSuccessToast } from '@/utils/notificationHelpers'
import uploadPhoto from '@/utils/photoUpload'
import { createAsyncThunk, createSelector, createSlice, isAnyOf } from '@reduxjs/toolkit'

import {
	deleteEvent,
	getEventAttendees,
	getEventById,
	getEvents,
	getEventVolunteers,
	getEventVolunteerShifts,
	getListCategories,
	patchEvent,
	postNewEvent,
	updateEventStatus,
} from './api'
import { EventLocationTypes, EventRepeatingOptions, EVENT_PATHS, FORM } from './constants'
import { transformVolunteersData, transformAttendees } from './helpers'
import { EventCategoryDto, EventDto, EventInfo, EventsDto, EventsState, EventStatuses } from './types'

const FEATURE_NAME = 'EVENTS'

const initialState: EventsState = {
	categories: [],
	isLoading: false,
	constantsInitialized: false,
	eventsList: { ...LIST_INITIAL_STATE },
	selectedEvent: null,
	selectedEventDetails: {
		isLoading: true,
		refreshing: false,
		attendees: {
			isLoading: true,
			lists: {
				going: [],
				interested: [],
				attendees: [],
			},
		},
		volunteers: {
			volunteerShifts: [],
			volunteers: {},
		},
	},
}

export const loadEventConstantsRequest = createAsyncThunk<EventCategoryDto>(
	`${FEATURE_NAME}/EVENT_CONSTANTS_REQUESTS`,
	async (_, { rejectWithValue }) => {
		try {
			const response = await getListCategories()
			return response.data
		} catch (e) {
			return rejectWithValue(e)
		}
	},
)
const prepareEventInfo = (userId: number, data: any, eventId?: string, oldImageUrl?: string) => {
	const imageName = uuidv4()
	const photoUpdated = oldImageUrl !== data[FORM.photoUrl]
	const imageLocation = createPhotoUrl(imageName, PhotoUploadDirectories.EVENT)

	const photoUrl = photoUpdated ? imageLocation : oldImageUrl

	const shifts = (data[FORM.shifts] || []).map(({ start, end }: any) => {
		const startDate = mergeDateTime(data[FORM.startDate], start)
		const endTime = mergeDateTime(data[FORM.startDate], end)

		return {
			max_users: data[FORM.volunteersPerShift],
			time: {
				start: startDate,
				end: endTime,
			},
		}
	})
	// @TODO: #refactor #uglyCode
	// can be looped through data
	const eventInfo: EventInfo = {
		schoolId: config.PSU_SCHOOL_ID,
		id: eventId,
		name: data[FORM.eventName],
		categories: data[FORM.categories],
		startDate: mergeDateTime(data[FORM.startDate], data[FORM.startTime]),
		endDate: mergeDateTime(data[FORM.endDate], data[FORM.endTime]),
		description: data[FORM.description],
		locationName: data[FORM.locationName],
		streetAddress: data[FORM.streetAddress] ?? '',
		contactName: data[FORM.contactName],
		contactEmail: data[FORM.contactEmail],
		campus: null,
		campuses: data[FORM.campus],
		photoUrl: photoUrl!,
		submitterID: userId,
		shifts,
		externalUrl: data[FORM.externalUrl],
		exclude: data[FORM.exclude],
	}
	// @TODO: need to do this because backend doesn't accept null or empty string, fix when backend is corrected
	if (!!data[FORM.virtualLink]) {
		eventInfo['virtualLink'] = data[FORM.virtualLink]
	}

	if (!!data[FORM.streetAddress]) {
		eventInfo['streetAddress'] = data[FORM.streetAddress]
	}
	if (!!data[FORM.hostOrganization]) {
		eventInfo['hostOrganization'] = data[FORM.hostOrganization]
	}
	if (data[FORM.repeating] !== EventRepeatingOptions.DISABLED) {
		eventInfo['repeating'] = data[FORM.repeating]
		eventInfo['repeatCount'] = data[FORM.repeatCount]
	}

	return {
		eventInfo,
		newImageName: photoUpdated ? imageName : null,
	}
}
export const createEventRequest = createAsyncThunk<
	any,
	any,
	{
		state: RootState
	}
>(`${FEATURE_NAME}/CREATE_EVENT_REQUEST`, async (data: any, { dispatch, getState, rejectWithValue }) => {
	const {
		AUTH: { role },
	} = getState()
	const eventId = uuidv4()

	try {
		const userId = getState().AUTH.userInfo!.id

		dispatch(
			enqueueSnackbar({
				key: eventId,
				notification: {
					message: {
						type: SnackbarType.uploading,
						message: 'Uploading Event',
					},
				},
			}),
		)

		const { eventInfo, newImageName } = prepareEventInfo(userId, data)

		const response = await postNewEvent(eventInfo)
		// @TODO: response type needs to be corrected above, eventId was required before by backend,
		// new routes doesn't required that, remove and refactor accordingly
		const backendEventId = response.data.id
		if (response.status === 200 && newImageName) {
			await uploadPhoto({
				fileUrl: data[FORM.photoUrl],
				imageName: newImageName,
				directory: PhotoUploadDirectories.EVENT,
				options: {
					compressImage: true,
				},
			})
		}
		dispatch(push(isAdmin(role) ? EVENT_PATHS.APPROVED_LIST : EVENT_PATHS.LIST))
		const successId = uuidv4()
		dispatch(
			enqueueSnackbar(
				creationSuccessToast('Event', 'Your event has successfully been submitted!', (messageId) => {
					dispatch(closeSnackbar({ key: successId }))
					dispatch(push(EVENT_PATHS.VIEW_EVENT(backendEventId)))
				}),
			),
		)
	} catch (e: any) {
		return rejectWithValue(e)
	} finally {
		dispatch(closeSnackbar({ key: eventId }))
	}
})

export const editEventRequest = createAsyncThunk<
	any,
	any,
	{
		state: RootState
	}
>(`${FEATURE_NAME}/EDIT_EVENT_REQUEST`, async (data: any, { dispatch, getState, rejectWithValue }) => {
	const { id: userId } = getState().AUTH.userInfo
	const { id: eventId, photoUrl } = getState().EVENTS.selectedEvent!

	try {
		dispatch(
			enqueueSnackbar({
				key: eventId,
				notification: {
					message: {
						type: SnackbarType.uploading,
						message: 'Updating Event',
					},
				},
			}),
		)

		const { eventInfo, newImageName } = prepareEventInfo(userId, data, eventId, photoUrl)

		const response = await patchEvent(userId, eventInfo)

		if (response.status === 200 && newImageName) {
			await uploadPhoto({
				fileUrl: data[FORM.photoUrl],
				imageName: newImageName,
				directory: PhotoUploadDirectories.EVENT,
				options: {
					compressImage: true,
					onError: {
						uploadErrors: UploadErrors.EVENT,
						uploadErrorMessage: UploadErrorMessage.EVENT,
					},
				},
			})
		}
	} catch (e: any) {
		return rejectWithValue(e)
	} finally {
		dispatch(closeSnackbar({ key: eventId }))
		dispatch(push(EVENT_PATHS.VIEW_EVENT(eventId)))
	}
})

export const getEventsListRequest = createAsyncThunk<
	EventsDto,
	ListRequestParams,
	{
		state: RootState
	}
>(`${FEATURE_NAME}/GET_EVENTS_REQUEST`, async (requestParams, { dispatch, getState, rejectWithValue }) => {
	try {
		const {
			EVENTS: {
				eventsList: { params },
			},
		} = getState()

		const queryParams = prepareListParams(
			{
				...params,
			},
			requestParams,
			dispatch,
			setListParams,
		) as EntityListParams

		const response = await getEvents(queryParams)

		return response.data
	} catch (e: any) {
		return rejectWithValue(e)
	}
})

export const getEventByIdRequest = createAsyncThunk<
	EventDto,
	string,
	{
		state: RootState
	}
>(`${FEATURE_NAME}/GET_SINGLE_EVENT_REQUEST`, async (id, { rejectWithValue }) => {
	try {
		const response = await getEventById(id)
		return response.data
	} catch (e: any) {
		return rejectWithValue(e)
	}
})

export const updateEventStatusRequest = createAsyncThunk<
	any,
	{
		status: EventStatuses
		id: string
		refresh?: boolean
	},
	{
		state: RootState
	}
>(`${FEATURE_NAME}/UPDATE_EVENT_STATUS_REQUEST`, async ({ id, status, refresh }, { dispatch, rejectWithValue }) => {
	try {
		const response = await updateEventStatus(id, status)
		dispatch(setEventStatus({ id, status }))
		dispatch(enqueueSnackbar(createFieldUpdateToast('Status')))
		if (refresh) {
			dispatch(getEventByIdRequest(id))
		}
		return response.data
	} catch (e: any) {
		return rejectWithValue(e)
	}
})
export const getEventAttendeesRequest = createAsyncThunk<
	any,
	string,
	{
		state: RootState
	}
>(`${FEATURE_NAME}/GET_EVENT_ATTENDEES_REQUEST`, async (id, { dispatch, rejectWithValue }) => {
	try {
		const response = await getEventAttendees(id)
		const attendeeResponse = response.data.response

		return transformAttendees(attendeeResponse)
	} catch (e: any) {
		return rejectWithValue(e)
	}
})

export const getEventVolunteersDataRequest = createAsyncThunk<
	any,
	string,
	{
		state: RootState
	}
>(`${FEATURE_NAME}/GET_EVENT_VOLUNTEERS_REQUEST`, async (id, { dispatch, rejectWithValue }) => {
	try {
		const shifts = await getEventVolunteerShifts(id)
		const res = await getEventVolunteers(id)

		const volunteers = transformVolunteersData(res.data.response)

		return {
			volunteerShifts: shifts.data?.event || [],
			volunteers: volunteers || [],
		}
	} catch (e: any) {
		return rejectWithValue(e)
	}
})

export const refreshEventDetailsRequest = createAsyncThunk<
	any,
	string,
	{
		state: RootState
	}
>(`${FEATURE_NAME}/REFRESH_EVENT_DETAILS_REQUEST`, async (id, { dispatch, rejectWithValue }) => {
	try {
		const shifts = await getEventVolunteerShifts(id)
		const res = await getEventVolunteers(id)

		const response = await getEventAttendees(id)
		const attendeesRep = response.data.response

		const volunteers = transformVolunteersData(res.data.response)

		return {
			volunteers: {
				volunteerShifts: shifts.data?.event || [],
				volunteers: volunteers || [],
			},
			attendees: transformAttendees(attendeesRep),
		}
	} catch (e: any) {
		return rejectWithValue(e)
	}
})

// @TODO: need to be refactored when we switch the api, there is no need to send userId
// Can be used createDeletionThunk
export const deleteEventRequest = createAsyncThunk<
	any,
	{
		id: string
		cb?: () => void
	},
	{
		state: RootState
	}
>(`${FEATURE_NAME}/DELETE_EVENT_REQUEST`, async ({ id, cb }, { dispatch, getState, rejectWithValue }) => {
	try {
		const { id: userId } = getState().AUTH.userInfo

		await deleteEvent(userId, id)
		dispatch(
			enqueueSnackbar({
				key: id,
				notification: {
					message: {
						type: SnackbarType.info,
						message: `Event with id:${id} has been deleted successfully`,
					},
				},
			}),
		)
		if (cb) cb()
	} catch (e: any) {
		return rejectWithValue(e)
	}
})

export const eventsSlice = createSlice({
	name: FEATURE_NAME,
	initialState,
	reducers: {
		setPage: (state, { payload }) => {
			state.eventsList.params.page = payload
		},
		setPageSize: (state, { payload }) => {
			state.eventsList.params.limit = payload
		},
		setListParams: (state, { payload }) => {
			state.eventsList.params = payload
		},
		setEventStatus: (state, { payload }) => {
			const { id, status } = payload
			if (state.selectedEvent.id === id) {
				state.selectedEvent.status = status
			}
		},
		resetList: (state) => {
			state.eventsList = { ...LIST_INITIAL_STATE }
		},
	},
	extraReducers: (builder) => {
		builder
			.addCase(loadEventConstantsRequest.pending, (state) => {
				state.isLoading = true
			})
			.addCase(loadEventConstantsRequest.rejected, (state) => {
				state.isLoading = false
			})
			.addCase(loadEventConstantsRequest.fulfilled, (state, { payload }) => {
				state.categories = payload.categories.map((category) => ({
					label: category.name || category.category,
					value: category.id,
				}))
				state.isLoading = false
				state.constantsInitialized = true
			})
			.addCase(getEventsListRequest.fulfilled, (state, { payload }) => {
				state.eventsList.list = payload
			})
			.addCase(getEventByIdRequest.fulfilled, (state, { payload }) => {
				state.selectedEvent = payload
				if (state.selectedEvent.submittedBy) {
					state.selectedEvent.submittedBy.date = payload.createdAt
				}

				if (payload.approvedAt && payload.approvedBy) {
					state.selectedEvent.approvedBy.date = payload.approvedAt
				}
			})
			.addCase(getEventAttendeesRequest.pending, (state) => {
				state.selectedEventDetails.isLoading = true
				state.selectedEventDetails.attendees.isLoading = true
			})
			.addCase(getEventAttendeesRequest.rejected, (state) => {
				state.selectedEventDetails.isLoading = false
				state.selectedEventDetails.attendees.isLoading = false
			})
			.addCase(getEventVolunteersDataRequest.fulfilled, (state, { payload }) => {
				state.selectedEventDetails.volunteers = payload
			})
			.addCase(getEventAttendeesRequest.fulfilled, (state, { payload }) => {
				const { attendee, interested, going } = payload
				state.selectedEventDetails.isLoading = false
				state.selectedEventDetails.attendees.isLoading = false
				state.selectedEventDetails.attendees.lists = { attendees: attendee, interested, going }
			})
			.addCase(refreshEventDetailsRequest.pending, (state) => {
				state.selectedEventDetails.refreshing = true
			})
			.addCase(refreshEventDetailsRequest.rejected, (state) => {
				state.selectedEventDetails.refreshing = false
			})
			.addCase(refreshEventDetailsRequest.fulfilled, (state, { payload }) => {
				const { attendee, interested, going } = payload.attendees
				state.selectedEventDetails.attendees.lists = { attendees: attendee, interested, going }
				state.selectedEventDetails.volunteers = payload.volunteers
				state.selectedEventDetails.refreshing = false
			})
			.addMatcher(isAnyOf(getEventByIdRequest.pending), (state) => {
				state.isLoading = true
			})
	},
})

export const { setPage, setPageSize, setListParams, setEventStatus, resetList } = eventsSlice.actions

const selectedState = (state: { [FEATURE_NAME]: EventsState }) => state[FEATURE_NAME]

export const getCategories = createSelector(selectedState, (state) => state.categories)
export const selectEventsList = createSelector(selectedState, (state) => state.eventsList)
export const selectConstantsInitialized = createSelector(selectedState, (state) => state.constantsInitialized)
export const selectCurrentEvent = createSelector(selectedState, (state) => state.selectedEvent)
export const selectAttendeesListIsLoading = createSelector(selectedState, (state) => state.selectedEventDetails.attendees.isLoading)
export const selectEventDetailsIsLoading = createSelector(selectedState, (state) => state.selectedEventDetails.isLoading)
export const selectAttendeeLists = createSelector(selectedState, (state) => state.selectedEventDetails.attendees.lists)

export const selectVolunteersData = createSelector(selectedState, (state) => state.selectedEventDetails.volunteers)
export const selectEventDetailsIsRefreshing = createSelector(selectedState, (state) => state.selectedEventDetails.refreshing)

export const hasVolunteerShift = createSelector(selectedState, (state) => state.selectedEvent.volunteerShifts.length > 0)

export const selectCurrentEventForm = createSelector(selectedState, (state) => {
	const { selectedEvent } = state
	const hasShifts = !!selectedEvent?.volunteerShifts?.length

	return {
		id: selectedEvent?.id,
		eventName: selectedEvent?.name,
		startDate: selectedEvent?.startDate,
		startTime: getRoundedTime(selectedEvent?.startDate || ''),
		endDate: selectedEvent?.endDate,
		exclude: selectedEvent?.exclude,
		endTime: getRoundedTime(selectedEvent?.endDate || ''),
		description: selectedEvent?.description,
		campus: selectedEvent?.campuses.map(({ id }) => id),
		locationType: selectedEvent?.virtualLink ? EventLocationTypes.VIRTUAL : EventLocationTypes.IN_PERSON,
		virtualLink: selectedEvent?.virtualLink,
		repeating: selectedEvent?.repeating ?? EventRepeatingOptions.DISABLED,
		repeatCount: selectedEvent?.repeatCount,
		locationName: selectedEvent?.locationName,
		streetAddress: selectedEvent?.streetAddress,
		hostOrganization: selectedEvent?.organization?.length ? selectedEvent?.organization.map((org) => org.id) : null,
		categories: selectedEvent?.categories?.map(({ id }) => id),
		contactName: selectedEvent?.contactName,
		contactEmail: selectedEvent?.contactEmail,
		photoUrl: selectedEvent?.photoUrl,
		addingShifts: hasShifts,
		shiftsNumber: hasShifts ? selectedEvent?.volunteerShifts?.length : 0,
		volunteersPerShift: hasShifts ? selectedEvent?.volunteerShifts[0].maxUsers : 0,
		shifts: hasShifts
			? selectedEvent?.volunteerShifts.map((s) => ({
					start: getRoundedTime(s.startDate),
					end: getRoundedTime(s.endDate),
			  }))
			: [],
		externalUrl: selectedEvent?.externalUrl,
	}
})
