import React, { useState } from 'react'

import { ImagePlaceholder, ImagePlaceholderSizes } from '@/components/ImagePlaceholder'
import ImageViewModal from '@/components/ImageViewModal'
import { Theme } from '@mui/material'
import { makeStyles } from '@mui/styles'

const useStyles = makeStyles<Theme, { imageUrl: string }>((theme) => ({
	thumbnailContainer: ({ imageUrl }) => ({
		overflow: 'hidden',
		height: 470,
		marginBottom: theme.spacing(2),
		background: imageUrl ? `url(${imageUrl}), linear-gradient(0deg, rgba(154,154,154,1) 0%, rgba(0,0,0,1) 94%)` : theme.colors.Neutral[300],
		backgroundPosition: 'center center',
		backgroundRepeat: 'no-repeat',
		backgroundSize: 'contain',
		textAlign: 'center',
		cursor: imageUrl ? 'pointer' : 'initial',
		borderRadius: 5,
		[theme.breakpoints.down('sm')]: {
			height: 'auto',
		},
	}),
	thumbnail: {
		maxWidth: '100%',
		maxHeight: '100%',
	},
}))

export type IThumbnailProps = {
	photoUrl: string
	altText?: string
}

const Thumbnail: React.FC<IThumbnailProps> = ({ photoUrl, altText }) => {
	const [fullImageView, setFullImageView] = useState('')

	const onModalClose = () => {
		setFullImageView('')
	}
	const openModal = () => {
		if (photoUrl) {
			setFullImageView(photoUrl)
		}
	}
	const classes = useStyles({ imageUrl: photoUrl })

	return (
		<div onClick={openModal} className={classes.thumbnailContainer}>
			{photoUrl ? (
				<ImageViewModal onClose={onModalClose} imageSrc={fullImageView} />
			) : (
				<ImagePlaceholder placeholderSize={ImagePlaceholderSizes.LARGE} />
			)}
		</div>
	)
}

export { Thumbnail }
