import { v4 as uuidv4 } from 'uuid'

import { LIST_INITIAL_STATE } from '@/constants/app'
import { RootState } from '@/store'
import { closeSnackbar, enqueueSnackbar } from '@/store/app'
import { SnackbarType } from '@/store/app/types'
import { createDeletionThunk, prepareListParams } from '@/utils/common'
import { createAsyncThunk, createSelector, createSlice } from '@reduxjs/toolkit'

import { createFile, deleteFileById, getFiles } from './api'
import { DocumentListRequestParams, DocumentParams, DocumentsState, FileDto, FileListDto, FileListParams } from './types'

const FEATURE_NAME = 'DOCUMENTS'

const initialState: DocumentsState = {
	documentList: { ...LIST_INITIAL_STATE },
}

export const getDocumentsRequest = createAsyncThunk<FileListDto, DocumentListRequestParams, { state: RootState }>(
	`${FEATURE_NAME}/GET_DOCUMENTS_REQUESTS`,
	async (requestParams, { rejectWithValue, getState, dispatch }) => {
		try {
			const {
				DOCUMENTS: {
					documentList: { params },
				},
			} = getState()

			const queryParams = prepareListParams(
				{
					...params,
				},
				requestParams,
				dispatch,
				setListParams,
			) as FileListParams
			queryParams.organizationId = requestParams.organizationId
			const response = await getFiles(queryParams)

			return response.data
		} catch (e) {
			return rejectWithValue(e)
		}
	},
)

export const createDocumentRequest = createAsyncThunk<
	FileDto,
	{
		data: DocumentParams
		reloadList: boolean
		reloadSameOrg?: boolean
	},
	{
		state: RootState
	}
>(
	`${FEATURE_NAME}/CREATE_DOCUMENT_REQUESTS`,
	async ({ data: { files, organizationId }, reloadList, reloadSameOrg = false }, { rejectWithValue, dispatch }) => {
		const snackbarId = uuidv4()
		try {
			const promises = files.map(({ id, file }) => {
				return createFile({
					file,
					organizationId,
				})
			})
			dispatch(
				enqueueSnackbar({
					key: snackbarId,
					notification: {
						message: {
							type: SnackbarType.uploading,
							message: 'Uploading files',
						},
					},
				}),
			)
			await Promise.all(promises)
			const successId = uuidv4()
			dispatch(
				enqueueSnackbar({
					key: successId,
					notification: {
						message: {
							message: 'Done!',
							type: SnackbarType.success,
						},
					},
				}),
			)
			if (reloadList) {
				const reloadParams = reloadSameOrg ? { page: 1, organizationId } : { page: 1 }
				dispatch(getDocumentsRequest(reloadParams))
			}
		} catch (e) {
			return rejectWithValue(e)
		} finally {
			dispatch(closeSnackbar({ key: snackbarId }))
		}
	},
)

export const deleteDocumentRequest = createDeletionThunk(FEATURE_NAME, deleteFileById)

export const documentsSlice = createSlice({
	name: FEATURE_NAME,
	initialState,
	reducers: {
		setListParams: (state, { payload }) => {
			state.documentList.params = { ...state.documentList.params, ...payload }
		},
	},
	extraReducers: (builder) => {
		builder
			.addCase(getDocumentsRequest.pending, (state) => {
				state.documentList.isLoading = true
			})
			.addCase(getDocumentsRequest.fulfilled, (state, { payload }) => {
				state.documentList.isLoading = false
				state.documentList.list.results = payload.result
				state.documentList.list.count = payload.total
				state.documentList.list.page = state.documentList.list.page + 1
				state.documentList.list.pages = payload.total / state.documentList.params.limit
			})
			.addCase(getDocumentsRequest.rejected, (state) => {
				state.documentList.isLoading = false
			})
	},
})

export const { setListParams } = documentsSlice.actions

const selectedState = (state: { [FEATURE_NAME]: DocumentsState }) => state[FEATURE_NAME]

export const selectDocumentsList = createSelector(selectedState, (state) => state.documentList)
