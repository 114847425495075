import { Typography } from '@mui/material'
import { makeStyles } from '@mui/styles'

const useStyles = makeStyles((theme) => ({
	root: {
		marginTop: theme.spacing(6),
		marginBottom: theme.spacing(2.75),
	},
	labelContainer: {
		display: 'flex',
		marginBottom: theme.spacing(1.25),
	},
	label: {
		marginRight: theme.spacing(1.75),
		fontWeight: theme.typography.fontWeightMedium,
	},
	description: {
		color: theme.colors.Neutral[500],
		marginBottom: theme.spacing(3.5),
	},
	fieldsContainer: {
		maxWidth: 710,
		marginBottom: theme.spacing(2.5),
	},

	actions: {
		display: 'flex',
		alignItems: 'center',
		'& > button': {
			cursor: 'pointer',
			textDecoration: 'none',
			textTransform: 'none',
			marginRight: theme.spacing(2),
			fontWeight: theme.typography.fontWeightMedium,
			...theme.typography.body1,
			'&:disabled': {
				opacity: 0.5,
			},
		},
	},
}))

export type ICardContainerProps = {
	label?: string
	description?: string
	actions?: React.ReactNode
}

const CardContainer: React.FC<ICardContainerProps> = ({ label, description, actions, children }) => {
	const classes = useStyles()
	if (!label && !description) {
		return <></>
	}

	return (
		<div className={classes.root}>
			{!!label && (
				<div className={classes.labelContainer}>
					<Typography className={classes.label}>{label}</Typography>
					{actions}
				</div>
			)}
			{!!description && <Typography className={classes.description}>{description}</Typography>}
			{children}
		</div>
	)
}

export { CardContainer }
