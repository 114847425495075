import { useCallback, useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { v4 as uuidv4 } from 'uuid'

import { validateImageSize } from '@/components/shared/utils/cropImage'
import { ERRORS } from '@/constants'
import { maxAllowedImageSize } from '@/constants/uploads'
import { closeSnackbar, enqueueSnackbar } from '@/store/app'
import { SnackbarType } from '@/store/app/types'

const useImageUploadError = () => {
	const dispatch = useDispatch()
	const [error, setError] = useState('')
	const [errorId, setErrorId] = useState(uuidv4())

	const validateImage = useCallback(async (url: string) => {
		const isValid = await validateImageSize(url)

		if (!isValid) {
			setError(ERRORS.imageUpload(maxAllowedImageSize))
			return false
		}
		setError('')
		return true
	}, [])

	useEffect(() => {
		if (error) {
			const newId = uuidv4()

			dispatch(
				enqueueSnackbar({
					key: newId,
					notification: {
						message: {
							type: SnackbarType.error,
							message: error,
						},
					},
				}),
			)
			setErrorId(newId)
		} else {
			dispatch(closeSnackbar({ key: errorId }))
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [error])

	return validateImage
}

export default useImageUploadError
