import http from '@/api/http/requestHttp'
import * as config from '@/constants/configuration'
import { FormCategoryDto } from '@/store/forms/types'

const getListCategories = async () => {
	const url = `${config.PSU_SCHOOL_ID}/oms/forms/categories`

	return await http.get<FormCategoryDto[]>(url)
}

export default getListCategories
