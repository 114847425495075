import classNames from 'classnames'
import { forwardRef } from 'react'

import { STYLES } from '@/constants'
import { ArrowBackIos, Close } from '@mui/icons-material'
import { Typography } from '@mui/material'
import { makeStyles } from '@mui/styles'

const useStyles = makeStyles((theme) => ({
	card: {
		minWidth: 500,
		backgroundColor: theme.colors.white[500],
		borderRadius: 8,
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
		justifyContent: 'center',
	},
	popupModalHeader: {
		width: STYLES.FILL_AVAILABLE_WIDTH,
		display: 'flex',
		flexDirection: 'column',
	},
	popupModalHeaderContainer: {
		width: '100%',
		display: 'flex',
		borderBottom: `solid 1px ${theme.colors.grey[200]}`,
	},
	modalTitle: ({ subTitle }: any) => ({
		margin: subTitle ? '30px 0 15px 0' : '30px 0',
		width: STYLES.FILL_AVAILABLE_WIDTH,
		textAlign: 'center',
		fontWeight: 700,
	}),
	closeIcon: {
		color: theme.colors.grey[500],
		cursor: 'pointer',
		margin: '0 20px 0 0',
		width: 24,
		height: 24,
	},
	backIcon: {
		cursor: 'pointer',
		margin: '30px 0 15px 20px',
		width: 18,
		height: 18,
	},
	subTitle: {
		color: theme.colors.grey[500],
		width: STYLES.FILL_AVAILABLE_WIDTH,
		textAlign: 'center',
		marginBottom: 10,
	},
	cardFooter: {
		width: STYLES.FILL_AVAILABLE_WIDTH,
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
		justifyContent: 'center',
		borderTop: `solid 1px ${theme.colors.grey[200]}`,
		padding: 30,
		marginTop: 10,
		[theme.breakpoints.down('xs')]: {
			width: '82%',
		},
	},
	navButtonContainer: {
		width: 44,
		display: 'flex',
		alignItems: 'center',
	},
}))

interface CommonPopupModalCardProps {
	title?: string
	titleContent?: React.ReactNode
	subTitle?: string
	onClose?: (e: any) => void
	handleBack?: (e: any) => void
	children: React.ReactNode
	classnames?: {
		header?: string
		footer?: string
		card?: string
	}
	showFooter?: boolean
	footerContent?: React.ReactNode
}

const CommonModalCard = forwardRef<any, CommonPopupModalCardProps>(
	(
		{ title = '', subTitle = '', titleContent, children, onClose, handleBack, classnames = {}, showFooter = false, footerContent = null },
		ref,
	) => {
		const classes = useStyles({ subTitle })

		return (
			<div ref={ref} className={classNames(classes.card, classnames.card)}>
				{(title || onClose) && (
					<div className={classNames(classes.popupModalHeaderContainer, classnames.header)}>
						<div className={classes.navButtonContainer}>
							{' '}
							{handleBack && <ArrowBackIos className={classes.backIcon} onClick={handleBack} />}
						</div>
						<div className={classes.popupModalHeader}>
							<Typography className={classes.modalTitle}>{title}</Typography>
							{subTitle && <Typography className={classes.subTitle}>{subTitle}</Typography>}
							{titleContent}
						</div>
						<div className={classes.navButtonContainer}> {onClose && <Close className={classes.closeIcon} onClick={onClose} />}</div>
					</div>
				)}
				{children}
				{showFooter && <div className={classNames(classnames.footer, classes.cardFooter)}>{footerContent}</div>}
			</div>
		)
	},
)

export default CommonModalCard
