import http from '@/api/http/requestHttp'
import * as config from '@/constants/configuration'
import { CampusDto } from '@/types'

const getListCampuses = async () => {
	const url = `${config.PSU_SCHOOL_ID}/campus`

	return await http.get<{ campuses: CampusDto[] }>(url)
}

export default getListCampuses
