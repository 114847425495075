import React, { useCallback, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { JsonParam, NumberParam, StringParam, useQueryParams, withDefault } from 'use-query-params'
import { v4 as uuidv4 } from 'uuid'

import { AppDispatch } from '@/store'
import { getUserRole } from '@/store/auth'
import { EntityList } from '@/types'
import { isAdmin } from '@/utils/authHandlers'
import { MenuItem, Stack, styled } from '@mui/material'
import {
	DataGrid,
	GridCellEditCommitParams,
	GridCellParams,
	GridColumns,
	GridColumnVisibilityModel,
	GridCsvExportMenuItem,
	GridDensity,
	GridExportMenuItemProps,
	GridFilterModel,
	GridRowParams,
	GridSortModel,
	GridToolbarColumnsButton,
	GridToolbarContainer,
	GridToolbarDensitySelector,
	GridToolbarExportContainer,
	GridToolbarFilterButton,
	useGridApiContext,
} from '@mui/x-data-grid'
import { AsyncThunk } from '@reduxjs/toolkit'

import { DATA_TABLE_MAP, TABLE_NAMES } from './config'
import { DataTableFooter } from './DataTableFooter'
import { generateExcel } from './utils'
import { removeFromExportList, updateExportProgress } from '@/features/exportList/exportListSlice'
import { AvailableGeneratorFileTypes } from './fileExporter/constants'

const StyledDataGrid = styled(DataGrid)(({ theme }) => ({
	'.MuiDataGrid-columnSeparator': {
		display: 'none',
	},
	'&.MuiDataGrid-root': {
		border: 'none',
	},
	'& .MuiDataGrid-cell a': {
		color: theme.colors.Primary[700],
		textDecoration: 'none',
		fontWeight: theme.typography.fontWeightMedium,
	},
}))

export type IDataTableProps = {
	columns: GridColumns
	request: AsyncThunk<
		any,
		{
			page?: number
			limit?: number
			sortBy?: GridSortModel
			filters?: GridFilterModel
			q?: string
		},
		any
	>
	tableName?: TABLE_NAMES
	onCellEditCommit?: (param: GridCellEditCommitParams) => void
	data: EntityList<any>
	defaultParams?: any
	defaultFilters?: Record<string, any>
	onRowClick?: (params: GridRowParams) => void
	onCellClick?: (params: GridCellParams) => void
	disableSelectionOnClick?: boolean
	customReport?: boolean
	apiCallArgs?: any[]
	valueGetters?: Record<string, any>
}

const ExcelExportMenuItem = (props: GridExportMenuItemProps<{ tableName: string; apiCallArgs: any[]; valueGetters: any }>) => {
	const apiRef = useGridApiContext()
	const dispatch = useDispatch()
	const allColumns = apiRef.current.getAllColumns()
	// @TODO: Ask Dan if he wants only visible columns to be exported
	//const visibleColumns = apiRef.current.getVisibleColumns()
	const colSelector = (columnName) => (c) => c[columnName]
	const {
		hideMenu,
		options: { tableName, apiCallArgs, valueGetters },
	} = props

	const filterFields = (c) => c.field !== '__check__'

	const filedSelector = colSelector('field')

	const columnInfoMapper = allColumns.filter(filterFields).reduce((acc, column) => {
		const fieldKey = filedSelector(column)
		const fieldHeader = colSelector('headerName')(column)
		acc[fieldKey] = {
			header: fieldHeader,
			getter: valueGetters && valueGetters[fieldKey] ? valueGetters[fieldKey] : null,
		}

		return acc
	}, {})

	const fetchData = async () => {
		const apiCall = DATA_TABLE_MAP[tableName]['api']
		const exportId = uuidv4()
		dispatch(updateExportProgress({ exportId, fileName: tableName }))
		await generateExcel({
			type: AvailableGeneratorFileTypes.EXCEL,
			exportId,
			cols: columnInfoMapper,
			fileName: tableName,
			apiCall,
			apiCallArgs,
			progressTracker: (exportId, progress) => {
				dispatch(updateExportProgress({ exportId, fileName: tableName, progress }))
			},
		})
		dispatch(removeFromExportList(exportId))
	}
	const clickHandler = () => {
		fetchData()
	}
	return (
		<MenuItem
			onClick={() => {
				clickHandler()
				hideMenu?.()
			}}
		>
			Export Excel
		</MenuItem>
	)
}

const DataTable: React.FC<IDataTableProps> = ({
	columns,
	request,
	data,
	onCellEditCommit,
	defaultFilters,
	onRowClick,
	onCellClick,
	disableSelectionOnClick,
	customReport,
	tableName,
	defaultParams = {},
	apiCallArgs,
	valueGetters,
}) => {
	const dispatch = useDispatch<AppDispatch>()
	const currentUserRole = useSelector(getUserRole)
	const [searchQuery, setSearchQuery] = useQueryParams({
		filters: withDefault(JsonParam, defaultFilters ?? {}),
		sortBy: withDefault(JsonParam, []),
		page: withDefault(NumberParam, 1),
		limit: withDefault(NumberParam, 10),
		fields: withDefault(JsonParam, {}),
		density: withDefault(StringParam, 'comfortable'),
		q: withDefault(StringParam, ''),
	})
	const showReportButton = customReport ?? isAdmin(currentUserRole)
	useEffect(() => {
		const { fields, density, ...rest } = searchQuery

		const promise = dispatch(
			request({
				...rest,
				...defaultParams,
			}),
		)
		return () => promise.abort()
		// need this only once
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [dispatch])

	const handleSortModelChange = useCallback(
		(sortModel: GridSortModel) => {
			setSearchQuery({ sortBy: sortModel, filters: defaultFilters, page: 1 }, 'pushIn')
			dispatch(request({ sortBy: sortModel, filters: defaultFilters, page: 1, ...defaultParams }))
		},
		[dispatch, setSearchQuery, request, defaultParams, defaultFilters],
	)

	const onFilterChange = useCallback(
		(filterModel: GridFilterModel) => {
			setSearchQuery({ filters: filterModel, page: 1 }, 'pushIn')
			dispatch(request({ filters: { items: [...filterModel.items, ...defaultFilters.items] }, page: 1, ...defaultParams }))
		},
		[dispatch, setSearchQuery, request, defaultParams, defaultFilters],
	)

	const handleRowEditCommit = useCallback(
		(params: GridCellEditCommitParams) => {
			if (onCellEditCommit) {
				onCellEditCommit(params)
			}
		},
		[onCellEditCommit],
	)

	const onPageChanged = useCallback(
		(page: number) => {
			setSearchQuery({ page: page + 1 }, 'pushIn')
			dispatch(request({ page: page + 1, ...defaultParams }))
		},
		[dispatch, setSearchQuery, request, defaultParams],
	)

	const onPageSizeChanged = useCallback(
		(pageSize: number) => {
			setSearchQuery({ limit: pageSize, page: 1 }, 'pushIn')
			dispatch(request({ limit: pageSize, page: 1, ...defaultParams }))
		},
		[dispatch, setSearchQuery, request, defaultParams],
	)

	const onColumnVisibilityChange = useCallback(
		(columnModel: GridColumnVisibilityModel) => {
			setSearchQuery({ fields: columnModel }, 'pushIn')
		},
		[setSearchQuery],
	)

	const defaultPerPageOption = [10, 20, 50, 100]

	if (searchQuery.limit && !defaultPerPageOption.includes(searchQuery.limit)) {
		defaultPerPageOption.push(searchQuery.limit)
	}
	const onStateChange = useCallback(
		(state) => {
			if (state.density.value !== searchQuery.density) {
				setSearchQuery(
					{
						density: state.density.value,
					},
					'pushIn',
				)
			}
		},
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[searchQuery.density],
	)
	function CustomToolbar() {
		return (
			<GridToolbarContainer>
				<GridToolbarColumnsButton />
				<GridToolbarFilterButton />
				<GridToolbarDensitySelector />
				<GridToolbarExportContainer>
					<GridCsvExportMenuItem />
					{DATA_TABLE_MAP[tableName] ? <ExcelExportMenuItem options={{ tableName, apiCallArgs, valueGetters }} /> : null}
				</GridToolbarExportContainer>
			</GridToolbarContainer>
		)
	}

	return (
		<div style={{ height: 600, width: '100%', marginTop: '16px' }}>
			{data.list && (
				<StyledDataGrid
					onStateChange={onStateChange}
					density={searchQuery.density as GridDensity}
					initialState={{
						columns: {
							columnVisibilityModel: searchQuery.fields,
						},
						sorting: searchQuery.sortBy,
					}}
					onRowClick={onRowClick}
					onCellClick={onCellClick}
					componentsProps={{
						footer: { customReport: showReportButton },
					}}
					components={{
						Footer: DataTableFooter,
						Toolbar: CustomToolbar,
						NoRowsOverlay: () => (
							<Stack height="100%" alignItems="center" justifyContent="center">
								No results found.
							</Stack>
						),
						NoResultsOverlay: () => (
							<Stack height="100%" alignItems="center" justifyContent="center">
								No results with specified filters, try expanding your search.
							</Stack>
						),
					}}
					page={searchQuery.page - 1}
					pageSize={searchQuery.limit}
					onPageChange={onPageChanged}
					onPageSizeChange={onPageSizeChanged}
					paginationMode="server"
					rowCount={data.list.count}
					checkboxSelection
					sortingMode="server"
					loading={data.isLoading}
					onCellEditCommit={handleRowEditCommit}
					onSortModelChange={handleSortModelChange}
					rowsPerPageOptions={[...defaultPerPageOption.sort((a, b) => a - b)]}
					rows={data.list.results}
					columns={columns}
					filterMode="server"
					onFilterModelChange={onFilterChange}
					onColumnVisibilityModelChange={onColumnVisibilityChange}
					disableSelectionOnClick={disableSelectionOnClick}
				/>
			)}
		</div>
	)
}

export { DataTable }
