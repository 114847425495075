import { useState, useEffect } from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import classNames from 'classnames'

import ExpandLessIcon from '@mui/icons-material/ExpandLess'
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight'
import { specialColors } from '@navengage/sen-shared-assets'
import { IconButton, Typography } from '@mui/material'
import { makeStyles } from '@mui/styles'

const useStyles = makeStyles((theme) => ({
	root: {
		width: 'calc(100% - 20px)',
		margin: '0 10px',
	},
	button: ({ checked }: { checked: boolean }) => ({
		width: '100%',
		height: 60,
		backgroundColor: checked ? theme.colors.Primary[100] : specialColors.transparent,
		borderRadius: 5,
		border: 'none',
		color: checked ? theme.colors.Primary[700] : theme.colors.black[500],
		display: 'flex',
		justifyContent: 'space-between',
		'&:hover': {
			color: theme.colors.Primary[700],
			backgroundColor: checked ? theme.colors.Primary[100] : specialColors.transparent,
		},
		...theme.typography.body1,
	}),
	buttonText: {
		fontWeight: 700,
	},
	subNavContainer: {
		width: 'calc(100% - 24px)',
		display: 'flex',
		flexDirection: 'column',
		padding: '0 12px',
	},
	subNavButton: {
		width: '100%',
		height: 60,
		backgroundColor: specialColors.transparent,
		borderRadius: 5,
		color: theme.colors.black[500],
		display: 'flex',
		justifyContent: 'space-between',
		'&:hover': {
			color: theme.colors.Primary[700],
			backgroundColor: specialColors.transparent,
		},
		borderTop: `solid 1px ${theme.colors.grey[200]}`,
		'&:last-child': {
			borderBottom: `solid 1px ${theme.colors.grey[200]}`,
		},
		...theme.typography.body1,
	},
	activeSubNav: {
		backgroundColor: theme.colors.Primary[100],
		color: theme.colors.Primary[700],
		'&:hover': {
			color: theme.colors.Primary[700],
			backgroundColor: theme.colors.Primary[100],
		},
	},
	icon: ({ checked }: { checked: boolean }) => ({
		width: 25,
		height: 25,
		color: checked ? theme.colors.Primary[700] : theme.colors.grey[500],
	}),
}))

export interface SettingsNavigation {
	label: string
	subs?: {
		label: string
		path: string
	}[]
	onClick?: () => void
	path?: string
}

export interface SettingsNavProps {
	navigation: SettingsNavigation
	index: number
	activeNavIndex: number
	handleChangeNav: (index: number) => void
}

const SettingsNav = ({ navigation, index, activeNavIndex, handleChangeNav }: SettingsNavProps) => {
	const { pathname } = useLocation()
	const { push } = useHistory()

	const checked = index === activeNavIndex

	const [isOpen, setIsOpen] = useState(checked)
	const [activeSubIndex, setActiveSubIndex] = useState(0)

	const classes = useStyles({ checked: checked && !isOpen })

	const handleClick = () => {
		if (navigation.path) {
			setIsOpen(false)
			push(navigation.path)
		} else if (navigation.onClick) {
			navigation.onClick()
		} else if (navigation.subs?.length && !checked) {
			push(navigation.subs[0].path)
		}

		if (!navigation.onClick) {
			handleChangeNav(index)
		}

		setIsOpen((prev) => !prev && !!navigation.subs?.length)
	}

	const handleClickSubNav = (newSubIndex: number, path: string) => () => {
		setActiveSubIndex(newSubIndex)
		push(path)
	}

	useEffect(() => {
		if (!checked) {
			setIsOpen(false)
			setActiveSubIndex(0)
		}
	}, [checked])

	useEffect(() => {
		if (navigation.path && pathname.includes(navigation.path)) {
			handleChangeNav(index)
		} else if (navigation.subs?.length) {
			const activeSubIndex = navigation.subs.findIndex((nav) => pathname.includes(nav.path))

			if (activeSubIndex !== -1) {
				setIsOpen(true)
				setActiveSubIndex(activeSubIndex)
				handleChangeNav(index)
			}
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [pathname])

	return (
		<div className={classes.root}>
			<IconButton className={classes.button} onClick={handleClick}>
				<Typography className={classes.buttonText}>{navigation.label}</Typography>
				{!isOpen ? <KeyboardArrowRightIcon className={classes.icon} /> : <ExpandLessIcon className={classes.icon} />}
			</IconButton>
			{navigation.subs?.length && isOpen && (
				<div className={classes.subNavContainer}>
					{navigation.subs.map((subNav, idx) => (
						<IconButton
							key={subNav.label}
							className={classNames(classes.subNavButton, idx === activeSubIndex ? classes.activeSubNav : '')}
							onClick={handleClickSubNav(idx, subNav.path)}
						>
							<Typography>{subNav.label}</Typography>
						</IconButton>
					))}
				</div>
			)}
		</div>
	)
}

export default SettingsNav
