import SaveIcon from '@mui/icons-material/Save'
import { push } from 'connected-react-router'
import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import FormBuilderForm from '@/components/Form'
import { FieldDataType, FormBuilderData } from '@/components/Form/FormBuilder/types'
import { ColorButton, OutlinedButton, styles } from '@/components/shared'
import { PATHS } from '@/constants'
import { APP_URL } from '@/constants/configuration'
import { useHandleCopy } from '@/hooks/useHandleCopy'
import { showExitConfirmationModal } from '@/store/app'
import { getUserRole } from '@/store/auth'
import {
	deleteFormRequest,
	getIndividualForm,
	getIsLoadingIndividualForm,
	getIsLoadingUploadFormData,
	readFormRequest,
	saveFormRequest,
} from '@/store/forms'
import { isAdmin } from '@/utils/authHandlers'
import { CircularProgress } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import { Delete } from '@mui/icons-material'

import { FORM_PATHS } from '@/features/formSubmissions/constants'
import { useDeleteConfirmation } from '@/features/events/hooks'
import { ActionModal } from '@/components/ActionModal'
import { PageContentContainer } from '@/components/PageContentContainer'

const useStyles = makeStyles((theme) => ({
	placeholder: {
		width: styles.FILL_AVAILABLE_WIDTH,
		height: '100%',
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
		justifyContent: 'center',
	},
	headerButton: {
		minWidth: 130,
		marginLeft: 5,
	},
	outlinedButton: {
		minWidth: 130,
		color: theme.colors.black[500],
		borderColor: theme.colors.grey[300],
		marginLeft: 5,
	},
}))

const EditForm = ({
	match: {
		params: { id },
	},
}: any) => {
	const classes = useStyles()
	const dispatch = useDispatch()
	const isLoading = useSelector(getIsLoadingIndividualForm)
	const isSaving = useSelector(getIsLoadingUploadFormData)
	const data = useSelector(getIndividualForm)
	const currentUserRole = useSelector(getUserRole)

	const copyHandler = useHandleCopy(true)

	const copyFormSubmissionLink = (id: string) => () => {
		if (!isDraft) {
			copyHandler(`${APP_URL.replace(/\/$/, '')}${PATHS.APP.SUBMIT_FORM(id)}`)
		} else {
			copyHandler(`${APP_URL.replace(/\/$/, '')}${PATHS.APP.FORMS_EDIT(id)}`)
		}
	}
	const handleSaveForm = (data: FormBuilderData<FieldDataType>, isDraft: boolean, redirect?: string, disabled?: boolean) => () => {
		if (disabled) return

		dispatch(
			saveFormRequest({
				data,
				isDraft,
				redirect,
				isNew: false,
			}),
		)
	}

	const { handleClose, confirmationRequired, setConfirmationRequired, renderButtons } = useDeleteConfirmation(
		deleteFormRequest,
		'Form',
		id,
		() => dispatch(push(FORM_PATHS.TEMPLATES)),
	)

	const handleClickCancel = (changes: boolean) => () => {
		const redirectPath = isAdmin(currentUserRole) ? PATHS.APP.FORMS_TEMPLATE_LISTS : PATHS.APP.FORMS_LIST
		if (changes) {
			dispatch(
				showExitConfirmationModal({
					isOpen: true,
					redirectPath,
				}),
			)
		} else {
			dispatch(push(redirectPath))
		}
	}

	const handleDeleteForm = () => {
		setConfirmationRequired(true)
	}

	useEffect(() => {
		dispatch(readFormRequest(id))
	}, [dispatch, id])

	if (isLoading)
		return (
			<div className={classes.placeholder}>
				<CircularProgress />
			</div>
		)

	const isDraft = !!data?.data.formData.isDraft

	return (
		<PageContentContainer>
			<ActionModal
				handleClose={handleClose}
				open={confirmationRequired}
				renderButtons={renderButtons}
				title={`Delete Form`}
				description="Are you sure you want to delete this form?"
			/>
			<FormBuilderForm
				title="Edit Form"
				disabled={isLoading || isSaving}
				data={data?.builder}
				canEditQuestions={isDraft}
				canSaveWithErrors={isDraft}
			>
				{({ getFormData, allActionsDisabled, draftSavingDisabled, publishingDisabled, isDirty }) => (
					<>
						<OutlinedButton onClick={copyFormSubmissionLink(id)} startIcon={<SaveIcon />} className={classes.outlinedButton}>
							{!isDraft ? 'Copy link to form' : 'Share my draft'}
						</OutlinedButton>
						<OutlinedButton onClick={handleDeleteForm} startIcon={<Delete />} className={classes.outlinedButton}>
							Delete
						</OutlinedButton>

						<OutlinedButton className={classes.outlinedButton} onClick={handleClickCancel(isDirty)} disabled={allActionsDisabled}>
							Cancel
						</OutlinedButton>

						{isDraft ? (
							<>
								<OutlinedButton
									className={classes.outlinedButton}
									disabled={draftSavingDisabled}
									onClick={handleSaveForm(getFormData(), true, PATHS.APP.FORMS, draftSavingDisabled)}
								>
									Save
								</OutlinedButton>
								<ColorButton
									className={classes.headerButton}
									disabled={publishingDisabled}
									onClick={handleSaveForm(getFormData(), false, PATHS.APP.FORMS, publishingDisabled)}
								>
									Publish
								</ColorButton>
							</>
						) : (
							<>
								<ColorButton
									className={classes.headerButton}
									disabled={draftSavingDisabled}
									onClick={handleSaveForm(getFormData(), isDraft, PATHS.APP.FORMS, draftSavingDisabled)}
								>
									Save
								</ColorButton>
							</>
						)}
					</>
				)}
			</FormBuilderForm>
		</PageContentContainer>
	)
}

export default EditForm
