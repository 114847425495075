import React from 'react'

import { Box, styled } from '@mui/material'

const StyledButton = styled(Box, {
	shouldForwardProp: (prop) => prop !== 'filled' && prop !== 'disabled',
})<{ filled?: boolean; disabled?: boolean }>(({ theme, filled, disabled }) => ({
	pointerEvents: disabled ? 'none' : 'all',
	cursor: !disabled ? 'pointer' : 'inherit',
	backgroundColor: filled ? theme.colors.white[500] : theme.colors.grey[200],
	boxShadow: filled ? `0 0 0 1px inset ${theme.colors.grey[200]}` : `0 0 0 1px inset transparent`,
	color: theme.colors.black[500],
	display: 'flex',
	textTransform: 'none',
	justifyContent: 'space-between',
	padding: '17px 7px 17px 20px',
	fontSize: theme.typography.pxToRem(18),
	lineHeight: theme.typography.pxToRem(24),
	fontWeight: theme.typography.fontWeightMedium,
	...theme.shape,
	':focus': {
		backgroundColor: theme.colors.white[500],
		border: `1px solid ${theme.colors.grey[200]}`,
	},
}))

export type ISelectButtonProps = {
	children?: React.ReactNode
	filled?: boolean
	disabled?: boolean
	icon?: React.ReactNode
	onClick?: () => void
}

const SelectButton: React.FC<ISelectButtonProps> = ({ children, filled, disabled, icon, onClick }) => {
	return (
		<StyledButton filled={filled} disabled={disabled} onClick={onClick}>
			{children}
			{icon}
		</StyledButton>
	)
}

export { SelectButton }
