import { RootState } from '@/store'
import { enqueueSnackbar } from '@/store/app'
import { creationSuccessToast } from '@/utils/notificationHelpers'
import { createAsyncThunk, createSelector, createSlice, isAnyOf } from '@reduxjs/toolkit'

import { createResource, deleteResource, getResourceById, getResources, updateResourceById } from './api'
import { ResourceDto, ResourceListParams, ResourceParams, ResourceResultsDto, ResourcesState } from './types'

const FEATURE_NAME = 'RESOURCES'

const initialState: ResourcesState = {
	list: {
		page: 1,
		isLoading: false,
		// Default value 20 come from backend
		limit: 20,
		total: 0,
		result: [],
		loadMore: true,
	},
	reloading: false,
	selected: {} as ResourceDto,
	updating: false,
}

export const createResourceRequest = createAsyncThunk<
	ResourceDto,
	{
		data: ResourceParams
		reloadList?: boolean
	},
	{
		state: RootState
	}
>(`${FEATURE_NAME}/CREATE_RESOURCE_REQUESTS`, async ({ data, reloadList = false }, { rejectWithValue, dispatch }) => {
	try {
		const response = await createResource(data)
		dispatch(enqueueSnackbar(creationSuccessToast('Resource')))

		if (reloadList) {
			dispatch(getResourcesRequest({ page: 1 }))
		}
		return response.data
	} catch (e) {
		return rejectWithValue(e)
	}
})
export const updateResourceRequest = createAsyncThunk<
	ResourceDto,
	{ id: string; data: ResourceParams; reloadList?: boolean },
	{
		state: RootState
	}
>(`${FEATURE_NAME}/UPDATE_RESOURCE_REQUESTS`, async ({ id, data, reloadList = true }, { rejectWithValue, dispatch }) => {
	try {
		const response = await updateResourceById(id, data)
		if (reloadList) {
			dispatch(getResourcesRequest({ page: 1 }))
		}
		return response.data
	} catch (e) {
		return rejectWithValue(e)
	}
})
export const getResourcesRequest = createAsyncThunk<ResourceResultsDto, ResourceListParams, { state: RootState }>(
	`${FEATURE_NAME}/GET_RESOURCES_REQUESTS`,
	async (params, { rejectWithValue, getState, dispatch }) => {
		try {
			const response = await getResources(params)

			return response.data
		} catch (e) {
			return rejectWithValue(e)
		}
	},
)
export const loadMoreResourcesRequest = createAsyncThunk<any, undefined, { state: RootState }>(
	`${FEATURE_NAME}/LOAD_MORE_RESOURCES_REQUESTS`,
	async (__, { rejectWithValue, getState, dispatch }) => {
		try {
			const {
				RESOURCES: {
					list: { page, limit, total, result },
				},
			} = getState()
			if (result.length < total) {
				const currentPage = page + 1
				dispatch(setListLoading(true))
				const response = await getResources({
					page: currentPage,
					limit,
				})
				dispatch(addListResults(response.data))
				dispatch(setListLoading(false))
				dispatch(setPage(currentPage))
			}
		} catch (e) {
			return rejectWithValue(e)
		}
	},
)

export const deleteResourceRequest = createAsyncThunk<any, { id: string; cb?: () => void }, { state: RootState }>(
	`${FEATURE_NAME}/DELETE_RESOURCE_REQUESTS`,
	async ({ id, cb }, { rejectWithValue }) => {
		try {
			const response = await deleteResource(id)
			if (cb) cb()
			return response.data
		} catch (e) {
			return rejectWithValue(e)
		}
	},
)

export const getResourceByIdRequest = createAsyncThunk<ResourceDto, string, { state: RootState }>(
	`${FEATURE_NAME}/GET_RESOURCE_BY_ID_REQUESTS`,
	async (id, { rejectWithValue, getState, dispatch }) => {
		try {
			const response = await getResourceById(id)

			return response.data
		} catch (e) {
			return rejectWithValue(e)
		}
	},
)

export const resourceSlice = createSlice({
	name: FEATURE_NAME,
	initialState,
	reducers: {
		setPage: (state, { payload }) => {
			state.list.page = payload
		},
		setListLoading: (state, { payload }) => {
			state.list.isLoading = payload
		},
		addListResults: (state, { payload }) => {
			state.list.result = [...state.list.result, ...payload.result]
			if (state.list.result.length >= payload.total) {
				state.list.loadMore = false
			}
		},
	},
	extraReducers: (builder) => {
		builder
			.addCase(createResourceRequest.pending, (state) => {
				state.updating = true
			})
			.addCase(getResourcesRequest.pending, (state) => {
				state.reloading = true
			})
			.addCase(getResourcesRequest.fulfilled, (state, { payload }) => {
				state.list.result = payload.result
				state.reloading = false
				state.list.total = payload.total
				state.list.loadMore = true
			})
			.addCase(getResourcesRequest.rejected, (state) => {
				state.reloading = false
			})
			.addMatcher(isAnyOf(createResourceRequest.fulfilled, createResourceRequest.rejected), (state) => {
				state.updating = false
			})
	},
})

export const { setPage, setListLoading, addListResults } = resourceSlice.actions

const selectedState = (state: { [FEATURE_NAME]: ResourcesState }) => state[FEATURE_NAME]

export const getResourceList = createSelector(selectedState, (state) => state.list)
export const getReloading = createSelector(selectedState, (state) => state.reloading)
