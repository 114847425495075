import React from 'react'

import { ModalHeader } from '@/components/Modal/ModalHeader'
import { Dialog, useMediaQuery, useTheme } from '@mui/material'
import { makeStyles } from '@mui/styles'

import { DocumentForm } from './DocumentForm'

const useStyles = makeStyles((theme) => ({
	content: {
		padding: `${theme.spacing(4.5)} ${theme.spacing(6.75)} ${theme.spacing(7)}`,
	},
}))

export type IDocumentModalProps = {
	id?: string
	open: boolean
	organizationId?: string
	handleClose: () => void
}

const DocumentModal: React.FC<IDocumentModalProps> = ({ id, handleClose, open, organizationId }) => {
	const classes = useStyles()
	const theme = useTheme()
	const fullScreen = useMediaQuery(theme.breakpoints.down('sm'))
	const title = id ? 'Edit Document' : 'Upload Document'
	return (
		<Dialog fullScreen={fullScreen} fullWidth maxWidth="md" onClose={handleClose} open={open}>
			<ModalHeader handleClose={handleClose} title={title} />
			<div className={classes.content}>
				<DocumentForm organizationId={organizationId} />
			</div>
		</Dialog>
	)
}

export { DocumentModal }
