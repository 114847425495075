import React, { useCallback } from 'react'
import { useSelector } from 'react-redux'
import { Route, Switch, useHistory } from 'react-router-dom'

import ContentContainer from '@/components/ContentContainer'
import { Header } from '@/components/EntityManager/Header'
import RouterTabs from '@/components/RouterTabs'
import { ROLES } from '@/constants'
import useSubHeader from '@/hooks/useSubHeader'
import { getUserRole } from '@/store/auth'
import { SubHeaderList } from '@/types'
import { isAdmin } from '@/utils/authHandlers'

import { ORGANIZATION_PATHS } from '../../constants'
import { getOrganizationsListRequest } from '../../organizationSlice'
import Active from './Active'
import MyOrganizations from './MyOrganizations'
import Pending from './Pending'

const TABS = [
	{
		label: 'Organizations',
		value: ORGANIZATION_PATHS.ACTIVE_LIST,
	},
	{
		label: 'Registrations',
		value: ORGANIZATION_PATHS.PENDING_LIST,
	},
]

const SUB_HEADERS: SubHeaderList = [
	{
		text: 'See the organizations that you can currently manage as a Super Admin/Admin',
		roles: [ROLES.STUDENT_ADMIN],
	},
	{
		text: 'See all of the organizations at your campus',
		roles: [ROLES.CAMPUS_ADMIN],
		path: ORGANIZATION_PATHS.ACTIVE_LIST,
	},
	{
		text: 'See organization registrations submitted by students',
		roles: [ROLES.CAMPUS_ADMIN],
		path: ORGANIZATION_PATHS.PENDING_LIST,
	},
]

const List = React.memo(() => {
	const history = useHistory()
	const currentUserRole = useSelector(getUserRole)
	const primaryBtnText = isAdmin(currentUserRole) ? 'Add Organization' : 'Register Organization'
	const subHeader = useSubHeader(SUB_HEADERS)
	const handleCreateOrganization = useCallback(() => {
		history.push(ORGANIZATION_PATHS.CREATE)

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])

	return (
		<ContentContainer>
			<Header
				title={'Organizations'}
				handlePrimaryBtn={handleCreateOrganization}
				primaryBtnText={primaryBtnText}
				searchPlaceholder={'Search organizations'}
				searchRequest={getOrganizationsListRequest}
				subHeader={subHeader.text}
			/>
			{isAdmin(currentUserRole) ? (
				<>
					<RouterTabs tabs={TABS} />
					<Switch>
						<Route exact path={ORGANIZATION_PATHS.ACTIVE_LIST} component={Active} />
						<Route exact path={ORGANIZATION_PATHS.PENDING_LIST} component={Pending} />
					</Switch>
				</>
			) : (
				<MyOrganizations />
			)}
		</ContentContainer>
	)
})

export default List
