import React from 'react'

import NetworkUserRow from '@/components/NetworkUserRow'
import { UserData } from '@/interfaces/common'
import { formatTime } from '@/utils/dateTime'
import { styled, Typography } from '@mui/material'
import { makeStyles } from '@mui/styles'
import { Box } from '@mui/system'

import { getShiftsInfo } from '../helpers'

const ShiftUserInfoText = styled(Box, {
	shouldForwardProp: (prop) => prop !== 'active',
})<{ active: boolean }>(({ active, theme }) => ({
	...theme.typography.body1,
	display: 'flex',
	alignItems: 'center',
	justifyContent: 'center',
	color: active ? theme.colors.black[500] : theme.colors.Neutral[500],
	backgroundColor: theme.colors.Neutral[200],
	width: theme.spacing(10.25),
	height: theme.spacing(4.75),
	fontWeight: theme.typography.fontWeightMedium,
}))

const RowBox = styled(Box)(() => ({
	display: 'flex',
	flexDirection: 'row',
	alignItems: 'center',
	justifyContent: 'space-between',
}))

const useStyles = makeStyles((theme) => ({
	shiftListContainer: {
		marginTop: theme.spacing(5.5),
	},
	shiftInfoRoot: {
		borderBottom: `solid 1px ${theme.colors.Neutral[300]}`,
		marginBottom: theme.spacing(6),
	},
	shiftInfoContainer: {
		marginBottom: theme.spacing(4),
	},
	shiftInfoTime: {
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
		height: 38,
		width: 200,
		backgroundColor: theme.colors.grey[200],
		padding: '0 10px',
	},
	shiftInfoTitle: {
		fontWeight: theme.typography.fontWeightBold,
		marginRight: theme.spacing(2),
	},
	shiftInfoPlaceholder: {
		color: theme.colors.grey[500],
		marginBottom: theme.spacing(2),
	},
	emptyList: {
		textAlign: 'center',
		padding: `${theme.spacing(24.5)} ${theme.spacing(2)}`,
	},
}))

export type IShiftListProps = {
	shifts?: Array<any>
	volunteers: Record<number, UserData[]>
	emptyPlaceholder?: React.ReactNode
}

const ShiftList: React.FC<IShiftListProps> = ({ shifts, volunteers, emptyPlaceholder }) => {
	const classes = useStyles()

	return shifts.length === 0 ? (
		emptyPlaceholder ? (
			<div className={classes.emptyList}>{emptyPlaceholder}</div>
		) : null
	) : (
		<div className={classes.shiftListContainer}>
			{shifts?.map((shift) => {
				const label = `SHIFT ${shift.id}`
				const time = `${formatTime(shift.startDate)}-${formatTime(shift.endDate)}`
				const { bookedSpots, totalSpots } = getShiftsInfo([shift])

				return (
					<div key={label} className={classes.shiftInfoRoot}>
						<RowBox className={classes.shiftInfoContainer}>
							<RowBox>
								<Typography className={classes.shiftInfoTitle}>{label}</Typography>
								<Typography className={classes.shiftInfoTime}>{time}</Typography>
							</RowBox>
							<ShiftUserInfoText active={bookedSpots > 0 ? true : false}>{`${bookedSpots}/${totalSpots}`}</ShiftUserInfoText>
						</RowBox>
						{volunteers && volunteers[shift.id]?.length ? (
							volunteers[shift.id]?.map((user: UserData, i: number) => <NetworkUserRow key={i} user={user} />)
						) : (
							<Typography className={classes.shiftInfoPlaceholder}>No one has singed up for a slot yet.</Typography>
						)}
					</div>
				)
			})}
		</div>
	)
}

export { ShiftList }
