import http from '@/api/http/requestHttp'
import * as config from '@/constants/configuration'
import { AutocompleteDto, IAutocompleteItemDto } from '@/types'

import { AutocompleteRequestParams } from '../users/types'
import {
	OrganizationCategoryDto,
	OrganizationDto,
	OrganizationEventsDto,
	OrganizationRegistrationDto,
	OrganizationsDto,
	OrganizationsListParams,
	UserOrganizations,
} from './types'

export const getFilters = () => {
	const url = `${config.PSU_SCHOOL_ID}/organizations/org/filters`

	return http.get(url)
}

export const getListCategories = () => {
	const url = `${config.PSU_SCHOOL_ID}/organizations/listCategories`

	return http.get<{ categories: OrganizationCategoryDto[] }>(url)
}

export const getOrganizations = (params: OrganizationsListParams) => {
	const url = `${config.PSU_SCHOOL_ID}/oms/organizations/`

	return http.get<OrganizationsDto>(url, { params })
}

export const getOrganizationById = (id: string) => {
	const url = `${config.PSU_SCHOOL_ID}/oms/organizations/${id}`
	return http.get<OrganizationDto>(url)
}

export const getOrganizationEvents = (id: string, params: OrganizationsListParams) => {
	const url = `${config.PSU_SCHOOL_ID}/oms/organizations/${id}/events`
	return http.get<OrganizationEventsDto>(url, { params })
}

export const getOrganizationRegistrations = (params: OrganizationsListParams) => {
	const url = `${config.PSU_SCHOOL_ID}/oms/organizations/registrations`
	return http.get<OrganizationRegistrationDto>(url, { params })
}

export const updateOrganizationSettings = (id: string, params: any) => {
	const url = `${config.PSU_SCHOOL_ID}/oms/organizations/${id}`
	return http.patch(url, { ...params })
}

export const organizationsAutocomplete = (params: AutocompleteRequestParams) => {
	const url = `${config.PSU_SCHOOL_ID}/organizations/autocomplete`
	return http.get<AutocompleteDto<IAutocompleteItemDto>>(url, { params })
}

export const getOrganizationTypes = () => {
	const url = `${config.PSU_SCHOOL_ID}/oms/organizations/types`
	return http.get<{ id: number; name: string }[]>(url)
}
export const getAllUserOrganizations = async (userId: number) => {
	const url = `${config.PSU_SCHOOL_ID}/organizations/${userId}/allorganizations`

	return http.get<UserOrganizations>(url)
}
