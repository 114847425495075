import { PATHS } from '@/constants'
import { ANNOUNCEMENTS_PATH } from '@/features/announcements/constants'
import AnnouncementList from '@/features/announcements/pages/AnnouncementList'
import { DOCUMENTS_PATH } from '@/features/documents/constants'
import DocumentList from '@/features/documents/pages/DocumentList'
import { EVENT_PATHS } from '@/features/events/constants'
import EventsPage from '@/features/events/pages'
import { FORM_PATHS } from '@/features/formSubmissions/constants'
import OrganizationsPage from '@/features/organizations/pages'
import { RESOURCES_PATH } from '@/features/resources/constants'
import ResourceList from '@/features/resources/pages/ResourceList'
import { SETTINGS_PATHS } from '@/features/settings/constants'
import SettingsPage from '@/features/settings/pages'
import { USERS_PATHS } from '@/features/users/constants'
import UsersPage from '@/features/users/pages'
import AppRoute from '@/interfaces/route'
import FormsPage from '@/pages/FormsPage'
import HomePage from '@/pages/home'

import { getAllAuthorizedRoles } from '@/utils/authHandlers'
import { createPageTitle } from '@/utils/page'

const allAuthorizedRolesAllowed = getAllAuthorizedRoles()

const HOME_TITLE = createPageTitle('Home')
const ORGANIZATIONS_TITLE = createPageTitle('Organizations')

const FORMS_TITLE = createPageTitle('Forms')
const EVENTS_TITLE = createPageTitle('Events')
const USERS_TITLE = createPageTitle('Users')
const RESOURCES_TITLE = createPageTitle('Resources')
const DOCUMENTS_TITLE = createPageTitle('Documents')
const ANNOUNCEMENTS_TITLE = createPageTitle('Announcements')
const SETTINGS_TITLE = createPageTitle('Settings')

const routes: AppRoute[] = [
	{
		component: HomePage,
		title: HOME_TITLE,
		name: HOME_TITLE,
		path: PATHS.APP.HOME,
		exact: false,
		isPrivate: true,
		allowedRoles: allAuthorizedRolesAllowed,
	},
	{
		component: OrganizationsPage,
		title: ORGANIZATIONS_TITLE,
		name: ORGANIZATIONS_TITLE,
		path: PATHS.APP.ORGANIZATIONS,
		exact: false,
		isPrivate: true,
		allowedRoles: allAuthorizedRolesAllowed,
	},
	{
		component: FormsPage,
		title: FORMS_TITLE,
		name: FORMS_TITLE,
		path: FORM_PATHS.ROOT,
		exact: false,
		isPrivate: true,
		allowedRoles: allAuthorizedRolesAllowed,
	},
	{
		component: EventsPage,
		title: EVENTS_TITLE,
		name: EVENTS_TITLE,
		path: EVENT_PATHS.ROOT,
		exact: false,
		isPrivate: true,
		allowedRoles: allAuthorizedRolesAllowed,
	},
	{
		component: UsersPage,
		title: USERS_TITLE,
		name: USERS_TITLE,
		path: USERS_PATHS.ROOT,
		exact: false,
		isPrivate: true,
		allowedRoles: allAuthorizedRolesAllowed,
	},
	{
		component: DocumentList,
		title: DOCUMENTS_TITLE,
		name: DOCUMENTS_TITLE,
		path: DOCUMENTS_PATH.ROOT,
		exact: false,
		isPrivate: true,
		allowedRoles: allAuthorizedRolesAllowed,
	},
	{
		component: ResourceList,
		title: RESOURCES_TITLE,
		name: RESOURCES_TITLE,
		path: RESOURCES_PATH.ROOT,
		exact: false,
		isPrivate: true,
		allowedRoles: allAuthorizedRolesAllowed,
	},
	{
		component: AnnouncementList,
		title: ANNOUNCEMENTS_TITLE,
		name: ANNOUNCEMENTS_TITLE,
		path: ANNOUNCEMENTS_PATH.ROOT,
		exact: false,
		isPrivate: true,
		allowedRoles: allAuthorizedRolesAllowed,
	},
	{
		component: SettingsPage,
		title: SETTINGS_TITLE,
		name: SETTINGS_TITLE,
		path: SETTINGS_PATHS.ROOT,
		exact: false,
		isPrivate: true,
		allowedRoles: allAuthorizedRolesAllowed,
	},
]

export default routes
