import { v4 as uuidv4 } from 'uuid'
import { AvailableGeneratorFileTypes, GeneratorConfigMap } from './constants'
import FileGenerator from './fileGenerators/FileGenerator'
import { TFieldMapper } from './TFieldMapper'

export interface IFileExporter {
	start: () => void
	pause: () => void
	resume: () => void
	getFileId: () => string
}

/**
 * Helper class to export data from api as a file, should implement IFileExporter interface
 *
 * @export
 * @class FileExporter
 * @implements {IFileExporter}
 */
export class FileExporter implements IFileExporter {
	/**
	 *
	 *
	 * @protected
	 * @type {number}
	 * @memberof FileExporter
	 */
	protected _pageIndex: number = 1
	/**
	 *
	 *
	 * @protected
	 * @type {FileGenerator}
	 * @memberof FileExporter
	 */
	protected _fileGenerator: InstanceType<typeof FileGenerator>
	/**
	 *
	 *
	 * @protected
	 * @type {boolean}
	 * @memberof FileExporter
	 */
	protected _paused: boolean = false

	public constructor(
		readonly type: AvailableGeneratorFileTypes,
		readonly fileName: string,
		readonly _fileId: string | null,
		readonly cols: TFieldMapper,
		readonly apiCall: any,
		readonly apiCallArgs?: any[],
		readonly onProgress?: (fileId: string, progress: number) => void,
		readonly limit: number = 200,
		readonly startPage: number = 1,
	) {
		this._pageIndex = startPage
		this._fileId = _fileId ? _fileId : uuidv4()
		const generatorClass = GeneratorConfigMap[type]
		this._fileGenerator = new generatorClass(fileName, { defaultHeaders: cols })
	}
	/**
	 * Start data exports
	 *
	 * @memberof FileExporter
	 */
	public async start() {
		await this.iterateApiCall()
	}
	/**
	 * Pause exporting data from api
	 *
	 * @memberof FileExporter
	 */
	public pause() {
		this._paused = true
	}

	/**
	 * Resumes exporting data from api
	 *
	 * @memberof FileExporter
	 */
	public resume() {
		this._paused = false
		this.iterateApiCall()
	}

	/**
	 * Returns uuid of the file
	 *
	 * @return {*}  {string}
	 * @memberof FileExporter
	 */
	public getFileId(): string {
		return this._fileId
	}
	/**
	 * Recursive function which fetches and add data to file until it reaches the last page
	 *
	 * @private
	 * @memberof FileExporter
	 */
	private async iterateApiCall() {
		if (this._paused) {
			return
		}
		// Api call to server to get the results and total count of rows
		const {
			data: { count, results },
		} =
			this.apiCallArgs && this.apiCallArgs.length > 0
				? await this.apiCall.apply(null, [...this.apiCallArgs, { page: this._pageIndex, limit: this.limit }])
				: await this.apiCall({ page: this._pageIndex, limit: this.limit })
		// Adds data to generator
		this._fileGenerator.addData(results)

		// remaining pages count for given limit
		const totalPages = Math.ceil(count / this.limit)
		if (this.onProgress) {
			this.onProgress(this._fileId, this._pageIndex / totalPages)
		}

		this._pageIndex++
		if (this._pageIndex <= totalPages) {
			await this.iterateApiCall()
		} else {
			this._fileGenerator.downloadFile()
		}
	}
}
