import storage from '@/utils/storage'
import * as common from '@/utils/common'
import * as urlHandler from '@/utils/urlHandlers'

const utils = {
	storage,
	urlHandler,
	common,
}

export default utils
