import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Redirect, Switch, useLocation } from 'react-router-dom'

import ExitConfirmationModal from '@/components/ExitConfirmationModal'
import MainLayout from '@/components/MainLayout'
import PrivateRoute from '@/components/PrivateRoute'
import { PATHS } from '@/constants'
import useScrollToTop from '@/hooks/useScrollToTop'
import * as ROUTES from '@/routes'
import { getIsLoading, loadConstantsRequest, showUploadErrors } from '@/store/app'
import { setFormListTab } from '@/store/forms'
import { CircularProgress } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'

const useStyles = makeStyles((theme) => ({
	placeholder: {
		width: '100vw',
		height: '100vh',
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
	},
}))

const MainRouter = () => {
	const classes = useStyles()
	const dispatch = useDispatch()
	const isLoading = useSelector(getIsLoading)
	const { pathname } = useLocation()

	useScrollToTop()

	useEffect(() => {
		dispatch(loadConstantsRequest())
		dispatch(showUploadErrors())

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])

	useEffect(() => {
		if (!pathname.includes(PATHS.APP.FORMS)) {
			dispatch(setFormListTab({ pageTabIndex: 0, tableTabIndex: 0 }))
		}
	}, [dispatch, pathname])

	if (isLoading)
		return (
			<div className={classes.placeholder}>
				<CircularProgress />
			</div>
		)

	return (
		<MainLayout>
			<Switch>
				{ROUTES.mainRoutes.map((route) => (
					<PrivateRoute key={route.name} {...route} />
				))}
				<Redirect
					from={PATHS.APP.ROOT}
					to={{
						pathname: PATHS.APP.HOME,
					}}
				/>
			</Switch>
			<ExitConfirmationModal />
		</MainLayout>
	)
}

export default MainRouter
