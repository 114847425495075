import { ROLES } from '@/constants'
import APP from '@/constants/paths/main'
import { ANNOUNCEMENTS_PATH } from '@/features/announcements/constants'
import { DOCUMENTS_PATH } from '@/features/documents/constants'
import { EVENT_PATHS } from '@/features/events/constants'
import { ORGANIZATION_PATHS } from '@/features/organizations/constants'
import { RESOURCES_PATH } from '@/features/resources/constants'
import { DateRangeOutlined, DescriptionOutlined, PeopleOutlineOutlined } from '@mui/icons-material'
import { SvgIconTypeMap } from '@mui/material'
import { OverridableComponent } from '@mui/material/OverridableComponent'

type TQuickAction = {
	icon: OverridableComponent<SvgIconTypeMap<{}, 'svg'>> & { muiName: string }
	path: string
	label: string
	roles?: Array<ROLES>
}

export const CUSTOM_REPORT_URL = 'https://forms.gle/512GdfujKUnMRRcD6'

export const CREATE_ACTION_PATHS = {
	event: {
		icon: DateRangeOutlined,
		label: 'Event',
		path: EVENT_PATHS.EVENTS_CREATE,
	},
	organization: {
		icon: PeopleOutlineOutlined,
		label: 'New Organization',
		path: ORGANIZATION_PATHS.CREATE,
	},
	form: {
		icon: DescriptionOutlined,
		label: 'Form',
		path: APP.FORMS_CREATE,
	},
	document: {
		label: 'Document',
		path: DOCUMENTS_PATH.NEW,
	},
	resource: {
		label: 'Resource',
		path: RESOURCES_PATH.NEW,
	},
	announcement: {
		label: 'Announcement',
		path: ANNOUNCEMENTS_PATH.NEW,
	},
	customReportRequest: {
		label: 'Custom Report',
		url: CUSTOM_REPORT_URL,
	},
}

export const QUICK_ACTIONS: TQuickAction[] = [
	{
		...CREATE_ACTION_PATHS.event,
		label: 'Create Event',
	},
	{
		...CREATE_ACTION_PATHS.organization,
		label: 'Register Org',
		roles: [ROLES.CAMPUS_ADMIN, ROLES.STUDENT_ADMIN],
	},
]
