import { AVAILABLE_FILE_TYPES, FileTypes } from '@/features/documents/constants'
import { AutocompleteStorageKeys } from '@/utils/autoComplete'
import { TDropdownEntitiesKeys } from './config'

export enum QuestionType {
	TextAnswer = 'Text answer',
	Checkboxes = 'Checkboxes',
	Radio = 'Multiple choice',
	Dropdown = 'Dropdown',
	FileUpload = 'File Upload',
	Autocomplete = 'Autocomplete',
	DatePicker = 'DatePicker',
}

export enum AnswerOptionType {
	text = 'text_option',
	other = 'other_input',
}

export enum FileUploadFieldType {
	pdf = 'application/pdf',
}

export interface AnswerWithOtherOption {
	id: string
	type: AnswerOptionType
	answer: string | null
	value?: any
}

export interface AnswerDropdownOption {
	value: string
	label: string
}

export interface TextAnswerQuestion {}

export interface CheckboxesQuestion {
	answers: AnswerWithOtherOption[]
}

export interface RadioQuestion {
	answers: AnswerWithOtherOption[]
}

export interface DropdownQuestion {
	answers: AnswerDropdownOption[]
	generated?: TDropdownEntitiesKeys
	multiple?: boolean
}

export interface FileUploadQuestion {
	allowedFileTypes: FileTypes
	multiple: boolean
	fileCount: number
	maxFileSize: number
	placeholder?: string
}

export interface DatePickerQuestion {
	timePicker?: boolean
	rangePicker?: number
	datePickerPlaceholder?: string
	timePickerPlaceholder?: string
	timePickerType?: TimePickers
}

export enum TimePickers {
	clock,
	dropdown,
}

export interface AutocompleteQuestion {
	entity: AutocompleteStorageKeys
	multiple?: boolean
}

export type FieldDataWithOtherAnswer = CheckboxesQuestion | RadioQuestion
export type FieldDataType = TextAnswerQuestion | DropdownQuestion | FieldDataWithOtherAnswer | FileUploadQuestion | AutocompleteQuestion
export type FieldData<T extends FieldDataType> = T

export interface FormBuilderQuestion<T extends FieldDataType> {
	id: string
	questionType: QuestionType
	fieldData: FieldData<T>
	questionText: string
	required?: boolean
	description?: string
	image?: string
	mandatory?: string
}

export interface FormSettings {
	title: string
	description: string
	startDate?: Date
	endDate?: Date
	allowMultipleSubmissions: boolean
	isActive: boolean
	category: number | null
}
export interface FormBuilderData<T extends FieldDataType> {
	id: string
	questions: FormBuilderQuestion<T>[]
	settings?: FormSettings
}

export const fileTypeOptions = Object.keys(AVAILABLE_FILE_TYPES).map((type) => ({
	label: AVAILABLE_FILE_TYPES[type].label,
	value: type,
}))

export interface CardProps<T extends FieldDataType> {
	data: FieldData<T>
	onChange: (newData: FieldData<T>) => void
	isEdit: boolean
}
