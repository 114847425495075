import AutoSizer from 'react-virtualized-auto-sizer'

import { STYLES } from '@/constants'

import { makeStyles } from '@mui/styles'
import { Typography } from '@mui/material'
import { BackButton } from '@/components/BackButton'

const useStyles = makeStyles((theme) => ({
	root: {
		width: '100%',
		position: 'fixed',
		height: 90,
		display: 'flex',
		flexDirection: 'row',
		alignItems: 'center',
		justifyContent: 'space-between',
		backgroundColor: theme.colors.white[500],
		zIndex: 1,
		[theme.breakpoints.down('sm')]: {
			position: 'inherit',
			maxHeight: 'none',
			width: '100vw',
			minWidth: STYLES.CONNECTION_REQUESTS_BAR.SM,
		},
		borderBottom: `solid 1.5px ${theme.colors.grey[300]}`,
	},
	backButton: {
		position: 'absolute',
		left: '1.5vw',
	},
	blockHeader: {
		justifyContent: 'center',
		paddingLeft: '1.5vw',
		display: 'flex',
		alignItems: 'center',
		width: '100%',
	},
	titleText: {
		fontWeight: 700,
		marginRight: 5,
	},
	autoSizer: {
		height: 'fit-content !important',
	},
}))

interface SettingsPageHeaderProps {
	title: string
	pageInfo?: string
}

const SettingsPageHeader = ({ title = '', pageInfo = '' }: SettingsPageHeaderProps) => {
	const classes = useStyles()

	return (
		<AutoSizer className={classes.autoSizer}>
			{({ height, width }) => (
				<div
					className={classes.root}
					style={{
						width,
					}}
				>
					<div className={classes.blockHeader}>
						<BackButton className={classes.backButton} />

						<Typography variant="h3" className={classes.titleText}>
							{title}
						</Typography>
						{pageInfo && (
							<Typography variant="h3" className={classes.titleText}>
								{` | ${pageInfo}`}
							</Typography>
						)}
					</div>
				</div>
			)}
		</AutoSizer>
	)
}

export default SettingsPageHeader
