import { OutlinedInput, styled } from '@mui/material'

export const StyledInput = styled(OutlinedInput)(({ theme }) => ({
	'& :disabled': {
		textAlign: 'center',
		color: theme.colors.black[500],
		opacity: '1',
		textOverflow: 'ellipsis',
		overflow: 'hidden',
	},
}))
