import { useSelector } from 'react-redux'

import Router from '@/components/Router'
import { FORM_PATHS } from '@/features/formSubmissions/constants'
import { formRoutes } from '@/routes'
import { getUserRole } from '@/store/auth'
import { isAdmin } from '@/utils/authHandlers'

const FormsPage = () => {
	const currentUserRole = useSelector(getUserRole)
	return (
		<Router
			routes={formRoutes}
			redirectPaths={[
				{
					from: '/',
					to: isAdmin(currentUserRole) ? FORM_PATHS.SUBMISSIONS : FORM_PATHS.FORMS,
				},
			]}
		/>
	)
}

export default FormsPage
