import React, { useEffect } from 'react'
import { useDispatch } from 'react-redux'

import Router from '@/components/Router'

import { ORGANIZATION_PATHS } from '../constants'
import { loadOrganizationsConstantsRequest } from '../organizationSlice'
import ORGANIZATION_ROUTES from '../routes'

const OrganizationsPage = React.memo(() => {
	const dispatch = useDispatch()
	useEffect(() => {
		dispatch(loadOrganizationsConstantsRequest())
	}, [dispatch])
	return (
		<Router
			routes={ORGANIZATION_ROUTES}
			redirectPaths={[
				{
					from: '/',
					to: ORGANIZATION_PATHS.ACTIVE_LIST,
				},
			]}
		/>
	)
})

export default OrganizationsPage
