import http from '@/api/http/requestHttp'
import * as config from '@/constants/configuration'

const storeSignUp = async (userId: number, body: any) => {
	const url = `${config.PSU_SCHOOL_ID}/users/user/${userId}/signup/v2`

	return await http.post(url, {
		userId,
		...body,
	})
}

export default storeSignUp
