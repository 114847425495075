import { push } from 'connected-react-router'
import React, { useCallback, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { ActionModal } from '@/components/ActionModal'
import { DataTable } from '@/components/EntityManager/DataTable'
import { getFullDateFormatted } from '@/utils/dateTime'

import { EVENT_PATHS } from '../../constants'
import { getEventsListRequest, resetList, selectEventsList } from '../../eventsSlice'
import { useEventColumns } from '../../hooks'
import { EventStatuses } from '../../types'

const Denied = () => {
	const eventsList = useSelector(selectEventsList)
	const dispatch = useDispatch()

	useEffect(() => {
		dispatch(resetList())
	}, [dispatch])

	const deletionCallback = useCallback(() => {
		dispatch(push(EVENT_PATHS.DENIED_LIST))
	}, [dispatch])

	const { columns, confirmationRequired, renderButtons, handleClose } = useEventColumns(
		[
			{
				field: 'deniedAt',
				headerName: 'Denied At',
				editable: true,
				flex: 1,
				valueGetter: (params: any) => getFullDateFormatted(params.row.deniedAt),
				filterable: false,
			},
		],
		deletionCallback,
	)
	return (
		<>
			<DataTable
				defaultFilters={{
					items: [
						{
							columnField: 'status',
							operatorValue: 'is',
							value: EventStatuses.Denied,
						},
					],
				}}
				data={eventsList}
				request={getEventsListRequest}
				columns={columns}
			/>
			<ActionModal
				handleClose={handleClose}
				open={confirmationRequired}
				renderButtons={renderButtons}
				title="Delete Event"
				description="Anyone who has RSVP’d will be notified that this event has been deleted."
			/>
		</>
	)
}

export default Denied
