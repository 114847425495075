import React from 'react'

import { Button, Modal, styled, Typography } from '@mui/material'
import { makeStyles } from '@mui/styles'
import { Box } from '@mui/system'
import { getHoverColor } from '@navengage/sen-shared-assets'

import CommonModalCard from './Modal/CommonModalCard'

const ActionButton = styled(Button, { shouldForwardProp: (prop) => prop !== 'textColor' })<{
	textColor?: string
}>(({ theme, textColor }) => ({
	width: '100%',
	textAlign: 'center',
	borderTop: `solid 1px ${theme.colors.grey[200]}`,
	borderRadius: 0,
	color: textColor ? textColor : theme.colors.black[500],
	padding: '20px 0',
	fontWeight: theme.typography.fontWeightBold,
	...theme.typography.body1,
	textTransform: 'none',
	'&:hover': {
		backgroundColor: getHoverColor(theme.colors.white),
	},
}))

const useStyles = makeStyles((theme) => ({
	modal: {
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
	},
	description: {
		textAlign: 'center',
		padding: `${theme.spacing(5.75)} ${theme.spacing(2)}`,
		borderTop: `solid 1px ${theme.colors.grey[200]}`,
	},
	text: {
		width: '62%',
		minWidth: 320,
		margin: '0 auto',
	},
	cardHeader: {
		borderBottom: 'none',
	},
	acceptButton: {
		color: theme.colors.Primary[700],
	},
}))

export type IActionModalProps = {
	renderButtons: Array<{
		label: string
		color?: any
		onClick?: (id: string) => void
	}>
	title: string
	description?: string
	subTitle?: string
	handleClose: () => void
	open: boolean
	id?: string
}

const ActionModal: React.FC<IActionModalProps> = ({ renderButtons, handleClose, title, description, subTitle, open, id }) => {
	const classes = useStyles()

	return (
		<Modal open={open} onClose={handleClose} className={classes.modal}>
			<CommonModalCard
				title={title}
				subTitle={subTitle}
				onClose={handleClose}
				classnames={{
					header: classes.cardHeader,
				}}
			>
				{description && (
					<Box className={classes.description}>
						<Typography className={classes.text}>{description}</Typography>
					</Box>
				)}
				{renderButtons.map(({ label, color, onClick }) => {
					return (
						<ActionButton textColor={color} key={label} onClick={() => onClick(id)}>
							{label}
						</ActionButton>
					)
				})}
			</CommonModalCard>
		</Modal>
	)
}

export { ActionModal }
