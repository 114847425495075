import * as actions from '@/store/organizations/actions'
import { OrganizationsState } from '@/store/organizations/types'
import { createReducer } from '@reduxjs/toolkit'

const initialState: OrganizationsState = {
	categories: [],
	types: [],
	statuses: [],
	list: {
		organizations: [],
		isLoading: false,
		total: 0,
	},
	userOrganizations: {
		followed: [],
		member: [],
		admin: [],
		superAdmin: [],
	},
}

const organizationsReducer = createReducer(initialState, (builder) => {
	builder
		.addCase(actions.setIsLoading, (state, action) => ({
			...state,
			isLoading: action.payload,
		}))
		.addCase(actions.setFilters, (state, { payload: { categories, types, statuses } }) => ({
			...state,
			categories,
			types,
			statuses,
		}))
		.addCase(actions.loadOrganizationsListRequest.pending, (state) => ({
			...state,
			list: {
				...state.list,
				isLoading: true,
			},
		}))
		.addCase(actions.loadOrganizationsListRequest.fulfilled, (state) => ({
			...state,
			list: {
				...state.list,
				isLoading: false,
			},
		}))
		.addCase(actions.loadOrganizationsListRequest.rejected, (state) => ({
			...state,
			list: {
				...state.list,
				isLoading: false,
			},
		}))
		.addCase(actions.setOrganizationsList, (state, { payload: { list, total } }) => ({
			...state,
			list: {
				...state.list,
				organizations: list,
				total,
			},
		}))
})

export { organizationsReducer }
