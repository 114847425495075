import { cloneDeep } from 'lodash'
import { Dispatch, SetStateAction, useState } from 'react'
import { v4 as uuidv4 } from 'uuid'

import { ButtonColors, IconButtonWithTooltip, styles } from '@/components/shared'
import { scrollIntoElementView } from '@/utils/common'
import AddOutlinedIcon from '@mui/icons-material/AddOutlined'
import DeleteForeverOutlinedIcon from '@mui/icons-material/DeleteForeverOutlined'
import FileCopyOutlinedIcon from '@mui/icons-material/FileCopyOutlined'
import makeStyles from '@mui/styles/makeStyles'

import ItemOrdinalScaleContainer, { FnSelectQuestion } from './ItemOrdinalScaleContainer'
import QuestionCard from './QuestionCard'
import { FieldDataType, FormBuilderData, FormBuilderQuestion, QuestionType } from './types'

const useStyles = makeStyles((theme) => ({
	root: {
		width: styles.FILL_AVAILABLE_WIDTH,
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'flex-start',
		justifyContent: 'center',
	},
	buttonsContainer: {
		position: 'sticky',
		alignSelf: 'flex-end',
		bottom: '40vh',
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'flex-end',
		justifyContent: 'center',
	},
	form: {
		width: '90%',
	},
	sideButton: {
		margin: 5,
		padding: 10,
		width: 40,
		height: 40,
		backgroundColor: theme.colors.white[500],
		...styles.BOX_SHADOW_DOWN,
	},
}))

const insertItem = (
	arr: FormBuilderQuestion<FieldDataType>[],
	index: number,
	newItem: FormBuilderQuestion<FieldDataType>,
): FormBuilderQuestion<FieldDataType>[] => [...arr.slice(0, index), newItem, ...arr.slice(index)]

const removeItem = (arr: FormBuilderQuestion<FieldDataType>[], id: string) => arr.filter((el) => el.id !== id)

interface FormBuilderProps {
	data: FormBuilderData<FieldDataType>
	setData: Dispatch<SetStateAction<FormBuilderData<FieldDataType>>>
	disabled?: boolean
	setIsTouched?: () => void
}

const FormBuilder = ({ data, setData, disabled, setIsTouched }: FormBuilderProps) => {
	const classes = useStyles()

	const [selectedQuestion, setSelectedQuestion] = useState<{ id: string; mandatory?: string } | null>(
		data.questions.length && !disabled ? { id: data.questions[0]?.id, mandatory: data.questions[0]?.mandatory } : null,
	)

	const handleSetTouched = () => {
		if (setIsTouched) setIsTouched()
	}

	const handleSelectQuestion: FnSelectQuestion = ({ id, mandatory }) => {
		setSelectedQuestion({ id, mandatory })
		if (id !== selectedQuestion?.id) scrollIntoElementView(`question-${id}`)
	}

	const handleAddQuestion = () => {
		handleSetTouched()

		const newQuestionId = uuidv4()
		const newItemIndex = selectedQuestion ? data.questions.findIndex((e) => e.id === selectedQuestion?.id) + 1 : data.questions.length

		setData((prev) => ({
			...prev,
			questions: insertItem(prev.questions, newItemIndex, {
				id: newQuestionId,
				questionType: QuestionType.TextAnswer,
				questionText: '',
				fieldData: {},
			}),
		}))
		handleSelectQuestion({ id: newQuestionId })
	}

	const handleDeleteSelectedQuestion = () => {
		handleSetTouched()

		setData((prev) => ({
			...prev,
			questions: removeItem(prev.questions, selectedQuestion?.id!),
		}))
		setSelectedQuestion(null)
	}

	const handleDuplicateSelectedQuestion = () => {
		handleSetTouched()

		const newQuestionId = uuidv4()
		const prevItemIndex = data.questions.findIndex((e) => e.id === selectedQuestion?.id)

		setData((prev) => ({
			...prev,
			questions: insertItem(prev.questions, prevItemIndex + 1, {
				...cloneDeep(prev.questions[prevItemIndex!]),
				id: newQuestionId,
			}),
		}))
		handleSelectQuestion({ id: newQuestionId })
	}

	const handleChangeQuestion = (questionData: FormBuilderQuestion<FieldDataType>) => {
		handleSetTouched()

		setData((prev) => ({
			...prev,
			questions: prev.questions.map((q) => (q.id === questionData?.id ? questionData : q)),
		}))
		setSelectedQuestion({ id: questionData.id, mandatory: questionData.mandatory })
	}

	const handleChangeQuestionsArray = (questions: FormBuilderQuestion<FieldDataType>[]) => {
		handleSetTouched()

		setData((prev) => ({
			...prev,
			questions,
		}))
	}

	return (
		<div className={classes.root}>
			<div className={classes.form}>
				{data.questions.map((q, index) => (
					<ItemOrdinalScaleContainer
						key={q.id}
						uniqId={q.id}
						currentIndex={index}
						value={q.id === selectedQuestion?.id}
						itemsArray={data.questions}
						isEdit={q.id === selectedQuestion?.id}
						onChange={handleChangeQuestionsArray}
						onSelectItem={handleSelectQuestion}
						highlightItem
						disabled={disabled}
						mandatory={q.mandatory}
					>
						<QuestionCard index={index} data={q} selected={q.id === selectedQuestion?.id} onChange={handleChangeQuestion} />
					</ItemOrdinalScaleContainer>
				))}
			</div>
			{!disabled && (
				<div className={classes.buttonsContainer}>
					{selectedQuestion !== null && !selectedQuestion?.mandatory && !!data.questions.length && (
						<>
							<IconButtonWithTooltip
								classnames={{
									button: classes.sideButton,
								}}
								color={ButtonColors.WHITE}
								tooltipTitle="Remove"
								placement="right"
								onClick={handleDeleteSelectedQuestion}
							>
								<DeleteForeverOutlinedIcon />
							</IconButtonWithTooltip>
							<IconButtonWithTooltip
								classnames={{
									button: classes.sideButton,
								}}
								color={ButtonColors.WHITE}
								tooltipTitle="Duplicate"
								placement="right"
								onClick={handleDuplicateSelectedQuestion}
							>
								<FileCopyOutlinedIcon />
							</IconButtonWithTooltip>
						</>
					)}
					<IconButtonWithTooltip
						classnames={{
							button: classes.sideButton,
						}}
						color={ButtonColors.WHITE}
						tooltipTitle="Add question"
						placement="right"
						onClick={handleAddQuestion}
					>
						<AddOutlinedIcon />
					</IconButtonWithTooltip>
				</div>
			)}
		</div>
	)
}

export default FormBuilder
