import { UserData } from '@/interfaces/common'
import { EntityList, EntityListing } from '@/types'

import { EventRepeatingOptions } from './constants'

export type EventsState = {
	categories: {
		label: string
		value: number
	}[]
	isLoading: boolean
	constantsInitialized: boolean
	eventsList: EntityList<IEvent>
	selectedEvent: EventDto | null
	selectedEventDetails: {
		isLoading: boolean
		refreshing: boolean
		attendees: {
			isLoading: boolean
			lists: {
				attendees: UserData[]
				interested: UserData[]
				going: UserData[]
			}
		}
		//@TODO: correct types whenever api docs are complete
		volunteers: {
			volunteers: Record<number, UserData[]>
			volunteerShifts: Array<any>
		}
	}
}

export type IEvent = {
	id: string
	name: string
	organizationName: string
	submittedBy: string
	createdAt: string
	startDate: string
	approvedAt: string | null
	deniedAt: string | null
	status: EventStatuses
}

export type EventCategoryDto = {
	categories: {
		category: string
		name: string
		createdAt: string
		externalUpdatedAt: null
		id: number
		updatedAt: string
	}[]
}
export interface EventInfo {
	schoolId: number
	id: string
	name: string
	categories: number[]
	startDate: Date
	endDate: Date
	description: string
	locationName: string
	streetAddress?: string
	contactName: string
	contactEmail: string
	campus: number
	campuses: number[]
	photoUrl: string
	hostOrganization?: string
	submitterID: number
	shifts: {
		max_users: number
		time: {
			start: Date
			end: Date
		}
	}[]
	repeatCount?: number
	externalUrl?: string
	virtualLink?: string
	repeating?: EventRepeatingOptions
	exclude?: boolean
}

export enum EventStatuses {
	Pending = 'Pending',
	Approved = 'Approved',
	Denied = 'Denied',
}

export type EventsDto = EntityListing<IEvent>

export type EventUserData = {
	id: number
	firstName: string
	lastName: string
	date: string
	userName?: string
}

export type EventOrganizations = Array<{ id: string; name: string }> | null
export type EventDto = {
	approvedAt: string | null
	campuses: Array<{ id: number; campusName: string }>
	campusId: number | null
	categories: Array<{ id: number; category: string }>
	contactEmail: string | null
	contactName: string
	createdAt: string | null
	dataLake: boolean
	dataLakeOverride: boolean
	deleted: boolean
	deniedAt: string | null
	description: string
	endDate: string
	exclude: boolean
	externalId: string
	externalUrl: string
	id: string
	lastUpdatedOnixCurated: string | null
	locationName: string
	name: string
	// @TODO correct type of organization
	organization: EventOrganizations
	photoUrl: string
	repeating: EventRepeatingOptions
	repeatCount?: number
	schoolId: number
	sourceSystem: null | string
	startDate: string | null
	status: EventStatuses
	streetAddress: string | null
	submittedByUserId: number
	updatedAt: string
	virtualLink: string | null
	// @TODO correct type of volunteerShifts
	volunteerShifts: Array<any> | null
	approvedBy: null | EventUserData
	submittedBy: null | EventUserData
}
