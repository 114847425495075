import makeStyles from '@mui/styles/makeStyles'

import { MetricGroupStats, MetricsGroup } from '../../types'
import { baseStyles } from './baseStyles'
import { MetricCard } from './MetricCard'
import { getValidMetrics } from './utils'

const useStyles = makeStyles(() => ({
	...baseStyles,
	wrapper: {
		...baseStyles.section,
	},
}))

export const EventsMetricsGroup = ({
	metricsGroupConfig: metricsGroup,
	stats,
}: {
	metricsGroupConfig: MetricsGroup
	stats: MetricGroupStats
}) => {
	const classes = useStyles()

	const validMetrics = getValidMetrics(metricsGroup, stats)

	return (
		<div>
			<div className={classes.gridAreaHeader}>
				<h3 className={classes.gridAreaTitle}>{metricsGroup.title}</h3>
			</div>
			<div className={classes.metricsGrid}>
				{validMetrics.map((metric) => {
					return <MetricCard key={metric.title} {...{ metric }} />
				})}
			</div>
		</div>
	)
}
