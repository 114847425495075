import { EntityAutocomplete } from '@/components/EntityAutocomplete'
import { OutlinedSelect } from '@/components/shared'

import { AutocompleteStorageKeys } from '@/utils/autoComplete'
import { mapFlatToOption } from '@/utils/common'
import { Switch } from '@mui/material'
import { makeStyles } from '@mui/styles'

import { AutocompleteQuestion, CardProps } from '../types'
import { useSettingsChangeHandler } from './hooks/useSettingsChangeHandler'
import { FieldSettingsContainer } from './styledComponents/FieldSettingsContainer'
import { StyledFormControlLabelInline } from './styledComponents/StyledFormControlLabelInline'

const useStyles = makeStyles((theme) => ({
	select: {
		width: '100%',
	},
}))

type TSettingKey = keyof AutocompleteQuestion

const AutocompleteCard = ({ data, onChange, isEdit }: CardProps<AutocompleteQuestion>) => {
	const classes = useStyles()
	const { entity } = data
	const optionChangeHandler = (value: AutocompleteStorageKeys) => {
		onChange({
			...data,
			entity: value,
		})
	}
	const onSettingChange = (key: TSettingKey, value: any) => {
		onChange({
			...data,
			[key]: value,
		})
	}
	const onSettingsChangedCreator = useSettingsChangeHandler<TSettingKey>(onSettingChange)

	return (
		<FieldSettingsContainer>
			{isEdit ? (
				<>
					<StyledFormControlLabelInline
						labelPlacement="start"
						control={<Switch checked={data.multiple} onChange={onSettingsChangedCreator('multiple', 'checked')} />}
						label="Allow multiple selection?"
					/>
					<OutlinedSelect
						classnames={{ input: classes.select }}
						onChange={optionChangeHandler}
						value={entity}
						options={Object.values(AutocompleteStorageKeys).map(mapFlatToOption)}
					/>
				</>
			) : (
				<EntityAutocomplete disabled={true} entity={data.entity} />
			)}
		</FieldSettingsContainer>
	)
}

export { AutocompleteCard }
