import http from '@/api/http/requestHttp'
import * as config from '@/constants/configuration'
import { orgTypes } from '@/utils/organizationRoles'

interface UserOrganizationPermissionsResponse {
	data: {
		list: {
			organizationId: string
			organizationMembershipTypeId: orgTypes
		}[]
	}
}

const getUserOrganizationPermissions = async (userId: number): Promise<UserOrganizationPermissionsResponse> => {
	const url = `${config.PSU_SCHOOL_ID}/organizations/user/${userId}/permissions`

	return await http.get(url)
}

export default getUserOrganizationPermissions
