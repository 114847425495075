import { CircularProgress, CircularProgressProps, Typography } from '@mui/material'
import { makeStyles } from '@mui/styles'
import classNames from 'classnames'
import React from 'react'

const useStyles = makeStyles((theme) => ({
	root: {
		display: 'flex',
		alignItems: 'center',
		padding: '0px 16px',
		border: `1px solid ${theme.colors.grey[500]}`,
		minHeight: 50,
		...theme.shape,
	},
	progressContainer: {
		position: 'relative',
		display: 'inline-flex',
	},
	progressNumber: {
		top: 0,
		left: 0,
		bottom: 0,
		right: 0,
		position: 'absolute',
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
	},
	itemName: {
		marginLeft: theme.spacing(2),
	},
}))

export type IExportItemProps = CircularProgressProps & { value?: number; title: string; className?: string }

const ExportItem: React.FC<IExportItemProps> = ({ title, value, className, ...rest }) => {
	const classes = useStyles()
	const progressInPercentages = value * 100
	return (
		<div className={classNames(classes.root, className)}>
			<div className={classes.progressContainer}>
				{value ? <CircularProgress value={progressInPercentages} {...rest} /> : <CircularProgress color="inherit" />}
				{value && (
					<div className={classes.progressNumber}>
						<Typography variant="caption" component="div" color="text.secondary">{`${Math.round(progressInPercentages)}%`}</Typography>
					</div>
				)}
			</div>
			<Typography className={classes.itemName}>{title}</Typography>
		</div>
	)
}

export { ExportItem }
