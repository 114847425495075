import postForm from './postForm'
import getListCategories from './getListCategories'
import getTemplates from './getTemplates'
import getForm from './getForm'
import patchForm from './patchForm'

const forms = {
	postForm,
	getListCategories,
	getTemplates,
	getForm,
	patchForm,
}

export default forms
