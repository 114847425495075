import { ChangeEvent } from 'react'
import makeStyles from '@mui/styles/makeStyles'
import { styles, TextField } from '@/components/shared'
import { IconButton, Typography } from '@mui/material'
import { AnswerOptionType, AnswerWithOtherOption } from '../../types'
import CloseIcon from '@mui/icons-material/Close'

const useStyles = makeStyles((theme) => ({
	root: {
		width: styles.FILL_AVAILABLE_WIDTH,
		margin: 10,
		display: 'flex',
		alignItems: 'center',
	},
	text: {
		width: styles.FILL_AVAILABLE_WIDTH,
		margin: '0 5px',
	},
	deleteButton: {
		padding: 5,
		width: 30,
		height: 30,
	},
}))

interface AnswerVariantWithOtherOptionProps {
	optionTypeElement?: React.ReactNode
	data: AnswerWithOtherOption
	isEdit?: boolean
	canDelete?: boolean
	onChange: (v: string) => void
	onDelete: () => void
}

const AnswerWithOtherOptionCard = ({
	data,
	onChange,
	onDelete,
	isEdit,
	optionTypeElement = null,
	canDelete = true,
}: AnswerVariantWithOtherOptionProps) => {
	const classes = useStyles()

	const handelChangeQuestion = ({ target: { value } }: ChangeEvent<HTMLInputElement>) => onChange(value)

	return (
		<div className={classes.root}>
			{optionTypeElement}
			<div>
				{isEdit ? (
					<TextField
						className={classes.text}
						value={data.answer}
						placeholder="Option"
						onChange={handelChangeQuestion}
						disabled={data.type === AnswerOptionType.other}
					/>
				) : (
					<Typography>{data.answer || 'Option'}</Typography>
				)}
			</div>
			{isEdit && canDelete && (
				<IconButton className={classes.deleteButton} onClick={onDelete} size="large">
					<CloseIcon fontSize="small" />
				</IconButton>
			)}
		</div>
	)
}

export default AnswerWithOtherOptionCard
