import { Dispatch, SetStateAction } from 'react'
import { FieldDataType, FormBuilderData } from '@/components/Form/FormBuilder/types'
import FormBuilder from '@/components/Form/FormBuilder'

interface FieldsTabProps {
	data: FormBuilderData<FieldDataType>
	setData: Dispatch<SetStateAction<FormBuilderData<FieldDataType>>>
	disabled?: boolean
	setIsTouched?: () => void
}

const FieldsTab = ({ data, setData, disabled, setIsTouched }: FieldsTabProps) => {
	return <FormBuilder data={data} setData={setData} disabled={disabled} setIsTouched={setIsTouched} />
}

export default FieldsTab
