import { connectRouter, routerMiddleware } from 'connected-react-router'
import { createBrowserHistory } from 'history'
import * as Sentry from '@sentry/react'

import { announcementSlice } from '@/features/announcements/announcementsSlice'
import { documentsSlice } from '@/features/documents/documentsSlice'
import { eventsSlice } from '@/features/events/eventsSlice'
import { submitFormSlice } from '@/features/formSubmissions/formSubmissionsSlice'
import { organizationSlice } from '@/features/organizations/organizationSlice'
import { resourceSlice } from '@/features/resources/resourcesSlice'
import { usersSlice } from '@/features/users/usersSlice'
import { appReducer, MODULE_NAME as APP_MODULE_NAME } from '@/store/app'
import { authReducer, MODULE_NAME as AUTH_MODULE_NAME } from '@/store/auth'
import errorMiddleware from '@/store/errorMiddleware'
import { formsReducer, MODULE_NAME as FORMS_MODULE_NAME } from '@/store/forms'
import { configureStore } from '@reduxjs/toolkit'
import { exportListSlice } from '@/features/exportList/exportListSlice'
import { notificationsSlice } from '@/features/notifications/notificationsSlice'
import { settingsSlice } from '@/features/settings/settingsSlice'

export const history = createBrowserHistory()
const sentryReduxEnhancer = Sentry.createReduxEnhancer({
	// Optionally pass options listed below
})

export const store = configureStore({
	reducer: {
		//@ts-ignore
		router: connectRouter(history),
		[APP_MODULE_NAME]: appReducer,
		[AUTH_MODULE_NAME]: authReducer,
		[organizationSlice.name]: organizationSlice.reducer,
		[FORMS_MODULE_NAME]: formsReducer,
		[submitFormSlice.name]: submitFormSlice.reducer,
		[eventsSlice.name]: eventsSlice.reducer,
		[usersSlice.name]: usersSlice.reducer,
		[resourceSlice.name]: resourceSlice.reducer,
		[announcementSlice.name]: announcementSlice.reducer,
		[documentsSlice.name]: documentsSlice.reducer,
		[exportListSlice.name]: exportListSlice.reducer,
		[notificationsSlice.name]: notificationsSlice.reducer,
		[settingsSlice.name]: settingsSlice.reducer,
	},
	middleware: (getDefaultMiddleware) =>
		getDefaultMiddleware({
			serializableCheck: false,
		}).concat([routerMiddleware(history), errorMiddleware]),
	enhancers: process.env.REACT_APP_ENV !== 'local' ? [sentryReduxEnhancer] : [],
})

export type RootState = ReturnType<typeof store.getState>
export type AppDispatch = typeof store.dispatch
