import React from 'react'

import { Close } from '@mui/icons-material'
import { Typography } from '@mui/material'
import { makeStyles } from '@mui/styles'

import DialogCloseButton from '../buttons/DialogCloseButton'

const useStyles = makeStyles((theme) => ({
	header: {
		textAlign: 'center',
		padding: `${theme.spacing(4.125)} ${theme.spacing(3)} ${theme.spacing(3)}`,
		borderBottom: `1px solid ${theme.colors.grey[300]}`,
	},

	title: {
		fontWeight: theme.typography.fontWeightBold,
	},
	subTitle: {
		marginTop: theme.spacing(2),
	},
}))

export type IModalHeaderProps = {
	title: string
	subTitle?: string
	handleClose?: () => void
}

const ModalHeader: React.FC<IModalHeaderProps> = ({ title, subTitle, handleClose }) => {
	const classes = useStyles()
	return (
		<>
			{handleClose && (
				<DialogCloseButton onClick={handleClose}>
					<Close />
				</DialogCloseButton>
			)}
			<div className={classes.header}>
				<Typography className={classes.title}>{title}</Typography>
				{subTitle && <Typography className={classes.subTitle}>{subTitle}</Typography>}
			</div>
		</>
	)
}

export { ModalHeader }
