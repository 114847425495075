import React, { Fragment, useCallback, useRef } from 'react'
import { useDispatch } from 'react-redux'
import { Waypoint } from 'react-waypoint'

import {
	deleteAnnouncementRequest,
	getAnnouncementsRequest,
	loadMoreAnnouncementsRequest,
} from '@/features/announcements/announcementsSlice'
import { useDeleteConfirmation } from '@/features/events/hooks'
import { deleteResourceRequest, getResourcesRequest, loadMoreResourcesRequest } from '@/features/resources/resourcesSlice'
import { TPostList, TPostType } from '@/types'
import { CircularProgress, List, Paper } from '@mui/material'
import { makeStyles } from '@mui/styles'

import { ActionModal } from '../ActionModal'
import PostListItem from './PostListItem'

type IPostListProps = TPostList & {
	editable?: boolean
	postType: TPostType
}

const useStyles = makeStyles((theme) => ({
	postListContainer: {
		maxHeight: '68vh',
		overflow: 'auto',
		boxShadow: 'none',
		marginRight: -46,
		paddingRight: 46,
	},
	loadingIndicator: {
		margin: `${theme.spacing(3)} 0`,
		textAlign: 'center',
	},
}))

const PostList: React.FC<IPostListProps> = ({ result, isLoading, editable, loadMore, postType }) => {
	const classes = useStyles()
	const scrollableAncestor = useRef()
	const dispatch = useDispatch()
	const reloadList = useCallback(() => {
		if (postType === 'Resource') {
			dispatch(getResourcesRequest({ page: 1 }))
		} else {
			dispatch(getAnnouncementsRequest({ page: 1 }))
		}
	}, [dispatch, postType])
	const deletionRequest = postType === 'Resource' ? deleteResourceRequest : deleteAnnouncementRequest
	const { handleClose, confirmationRequired, setConfirmationRequired, renderButtons, setCurrentEntityId } = useDeleteConfirmation(
		deletionRequest,
		postType,
		undefined,
		reloadList,
	)

	const loadMoreData = useCallback(() => {
		if (loadMore) {
			if (postType === 'Resource') {
				dispatch(loadMoreResourcesRequest())
			} else {
				dispatch(loadMoreAnnouncementsRequest())
			}
		}
	}, [dispatch, loadMore, postType])
	const handleDeleteClick = useCallback(
		(id) => {
			setCurrentEntityId(id)
			setConfirmationRequired(true)
		},
		[setConfirmationRequired, setCurrentEntityId],
	)

	return (
		<>
			<Paper ref={scrollableAncestor} className={classes.postListContainer}>
				<List>
					{result.map((post, index) => (
						<Fragment key={post.id}>
							<PostListItem postType={postType} onDelete={handleDeleteClick} key={post.id} canEdit={editable} {...post} />
							{index === result.length - 5 && <Waypoint onEnter={loadMoreData} />}
						</Fragment>
					))}
					{isLoading ? (
						<div className={classes.loadingIndicator}>
							<CircularProgress />
						</div>
					) : null}
				</List>
			</Paper>

			<ActionModal
				handleClose={handleClose}
				open={confirmationRequired}
				renderButtons={renderButtons}
				title={`Delete ${postType}`}
				description={`Are you sure you want to delete this ${postType.toLocaleLowerCase()}? Student Admins will no longer have access to it.`}
			/>
		</>
	)
}

export { PostList }
