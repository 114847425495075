import { useCallback, useRef, useState } from 'react'

import useOutsideClickCallback from './useOutsideClickCallback'

const usePopper = () => {
	const [open, setOpen] = useState(false)
	const anchorRef = useRef<HTMLButtonElement>(null)
	const popperRef = useRef<any>(null)

	const handleToggle = useCallback(() => {
		setOpen((prevOpen) => !prevOpen)
	}, [])

	const handleClose = useCallback((event?: React.MouseEvent<EventTarget>) => {
		if (anchorRef.current && anchorRef.current.contains(event.target as HTMLElement)) {
			return
		}

		setOpen(false)
	}, [])

	useOutsideClickCallback(popperRef, handleClose)

	return { open, handleToggle, anchorRef, popperRef, handleClose }
}

export default usePopper
