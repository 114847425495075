import ArrowBackIcon from '@mui/icons-material/ArrowBack'
import IconButton from '@/components/IconButton'
import classNames from 'classnames'
import { makeStyles } from '@mui/styles'

const useStyles = makeStyles((theme) => ({
	root: ({ variant }: any) => ({
		width: '100%',
		minHeight: '100vh',
		display: 'flex',
		flexDirection: 'row',
		background: `round ${
			variant !== 'primary' ? 'linear-gradient(0deg, rgba(1,31,69,0.43) 67%, rgba(1,31,69,0.43) 67%),' : ''
		} url(../img/WebBackground2.jpg)`,
		backgroundPosition: 'center center',
		backgroundRepeat: 'no-repeat',
		backgroundSize: 'cover',
		backgroundColor: theme.colors.Primary[1000],
		alignItems: 'center',
		justifyContent: 'center',
	}),
	backButton: {
		position: 'absolute',
		top: '4vh',
		left: '3vw',
	},
}))

interface CardLayoutProps {
	classnames?: {
		backButton?: string
		root?: string
	}
	onClickBackHandler?: () => void
	children: any
	variant?: 'primary' | 'secondary'
}

const CardLayout = ({
	onClickBackHandler,
	children,
	variant = 'primary',
	classnames = {
		root: '',
		backButton: '',
	},
}: CardLayoutProps) => {
	const classes = useStyles({ variant })

	return (
		<div className={classNames(classnames.root, classes.root)}>
			{onClickBackHandler && (
				<IconButton
					onClick={onClickBackHandler}
					className={classNames(classnames.backButton, classes.backButton)}
					startIcon={<ArrowBackIcon fontSize="large" />}
				>
					Back to Homepage
				</IconButton>
			)}
			{children}
		</div>
	)
}

export default CardLayout
