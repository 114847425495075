import http from '@/api/http/requestHttp'
import * as config from '@/constants/configuration'

import settings from '@/constants/http'
import { DtoUserNotifications } from './types'
// @TODO: Check maybe we should have dedicated oms route for this
export const patchUserNotifications = async (userId: number, viewedIds: number[]) => {
	const url = `${config.PSU_SCHOOL_ID}/notifications/user/${userId}/`

	return await http.patch(url, { viewedIds })
}

export const getUserNotifications = async (viewed: boolean, offset: number = 0) => {
	const url = `${config.PSU_SCHOOL_ID}/oms/notifications`

	const params = {
		viewed,
		offset,
		limit: settings.NOTIFICATIONS_PER_PAGE,
	}

	return http.get<DtoUserNotifications>(url, { params })
}

export default getUserNotifications
