import * as actions from '@/store/app/actions'
import { AppState } from '@/store/app/types'
import { createReducer } from '@reduxjs/toolkit'

const initialState: AppState = {
	isLoading: true,
	notifications: [],
	exitConfirmationModal: {
		isOpen: false,
	},
}

const appReducer = createReducer(initialState, (builder) => {
	builder
		.addCase(actions.setIsLoading, (state, action) => ({
			...state,
			isLoading: action.payload,
			error: action.payload ? null : state.error,
		}))
		.addCase(actions.setError, (state, action) => ({
			...state,
			isLoading: false,
			error: action.payload,
		}))
		.addCase(actions.enqueueSnackbar, (state, action) => ({
			...state,
			notifications: [
				...state.notifications,
				{
					//@ts-ignore
					key: action.payload.key,
					...action.payload.notification,
				},
			],
		}))
		.addCase(actions.closeSnackbar, (state, action) => ({
			...state,
			notifications: state.notifications.map((notification) =>
				notification.key === action.payload.key ? { ...notification, dismissed: true } : { ...notification },
			),
		}))
		.addCase(actions.dismissAllSnackbar, (state) => ({
			...state,
			notifications: state.notifications.map((notification) => ({
				...notification,
				dismissed: true,
			})),
		}))
		.addCase(actions.removeSnackbar, (state, action) => ({
			...state,
			notifications: state.notifications.filter((notification) => notification.key !== action.payload.key),
		}))
		.addCase(actions.showExitConfirmationModal, (state, action) => ({
			...state,
			exitConfirmationModal: action.payload,
		}))
		.addCase(actions.closeExitConfirmationModal, (state, action) => ({
			...state,
			exitConfirmationModal: {
				isOpen: false,
			},
		}))
})

export { appReducer }
