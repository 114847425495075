import classNames from 'classnames'
import React, { useCallback } from 'react'
import { useDispatch } from 'react-redux'
import { NumberParam, StringParam, useQueryParams, withDefault } from 'use-query-params'

import { ButtonColors, ColorButton } from '@/components/shared'
import { Add } from '@mui/icons-material'
import { Box, Grid, Theme, Typography } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import { GridFilterModel, GridSortModel } from '@mui/x-data-grid'
import { AsyncThunk } from '@reduxjs/toolkit'

import { SearchBox } from './SearchBox'

// Styles
const useStyles = makeStyles((theme: Theme) => ({
	title: {
		fontWeight: theme.typography.fontWeightBold,
		paddingTop: theme.spacing(0.75),
		paddingBottom: theme.spacing(0.75),
	},
	primaryButton: {
		padding: '13px 30px',
		width: '100%',
	},
	primaryBtnContainer: {
		maxWidth: 320,
	},
	searchContainer: {
		maxWidth: 420,
	},
	rightCol: {
		marginRight: theme.spacing(1),
		marginLeft: theme.spacing(1),
		flex: 1,
	},
	headerRight: {
		marginLeft: `-${theme.spacing(1)}px`,
		marginRight: `-${theme.spacing(1)}px`,
		display: 'flex',
		justifyContent: 'flex-end',
	},
	subHeaderContainer: {
		...theme.typography.body1,
		marginTop: theme.spacing(1.5),
		marginBottom: theme.spacing(2),
	},
}))

// Props
export type IHeaderProps = {
	title: string
	primaryBtnText?: string
	handlePrimaryBtn?: () => void
	searchRequest?: AsyncThunk<
		any,
		{
			page?: number
			limit?: number
			sortBy?: GridSortModel
			filters?: GridFilterModel
			q?: string
		},
		any
	>
	searchPlaceholder?: string
	subHeader?: React.ReactNode | string
}

const Header: React.FC<IHeaderProps> = ({ title, primaryBtnText, handlePrimaryBtn, searchRequest, searchPlaceholder, subHeader }) => {
	const classes = useStyles()
	const dispatch = useDispatch()
	const [searchQuery, setSearchQuery] = useQueryParams({
		q: withDefault(StringParam, ''),
		page: withDefault(NumberParam, 1),
	})

	const handleSearch = useCallback(
		(q: string) => {
			setSearchQuery({ q, page: 1 }, 'pushIn')
			dispatch(
				searchRequest({
					page: 1,
					q,
				}),
			)
		},
		[searchRequest, dispatch, setSearchQuery],
	)
	return (
		<>
			<Box>
				<Grid container spacing={1}>
					<Grid item sm={4}>
						<Typography className={classes.title} variant="h1">
							{title}
						</Typography>
					</Grid>
					<Grid item sm={8}>
						<Box display="flex" className={classes.headerRight}>
							{searchRequest && (
								<Box className={classNames(classes.rightCol, classes.searchContainer)}>
									<SearchBox onSearch={handleSearch} placeholder={searchPlaceholder} value={searchQuery.q} />
								</Box>
							)}
							{handlePrimaryBtn && (
								<Box className={classNames(classes.rightCol, classes.primaryBtnContainer)}>
									<ColorButton className={classes.primaryButton} startIcon={<Add />} onClick={handlePrimaryBtn}>
										{primaryBtnText}
									</ColorButton>
								</Box>
							)}
						</Box>
					</Grid>
				</Grid>
			</Box>
			{subHeader && <Box className={classes.subHeaderContainer}>{subHeader}</Box>}
		</>
	)
}

export { Header }
