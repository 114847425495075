import { ColumnInfo } from '@/components/CollapsibleTable'
import { formatDate } from '@/utils/dateTime'
import { FormActions } from '../../FormActions'

export enum TemplatesTabs {
	ACTIVE = 'Active',
	DRAFTS = 'Drafts',
	INACTIVE = 'Inactive',
}

export const templatesTabIndex = {
	[TemplatesTabs.ACTIVE]: 0,
	[TemplatesTabs.DRAFTS]: 1,
	[TemplatesTabs.INACTIVE]: 1,
}

export const templatesTabs = Object.values(TemplatesTabs).map((label) => ({
	label,
}))

export const templatesColumns: {
	[k in TemplatesTabs]: ColumnInfo[]
} = {
	[TemplatesTabs.ACTIVE]: [
		{
			name: 'Name',
			dataKey: 'title',
			align: 'left',
			size: 40,
			dataTransform: (title, row) => <FormActions tab={TemplatesTabs.ACTIVE} id={row.id} formTitle={title} />,
		},
		{
			name: 'Pending',
			dataKey: 'pending',
			showMetrics: true,
			align: 'center',
		},
		{
			name: 'In Review',
			dataKey: 'inreview',
			showMetrics: true,
			align: 'center',
		},
		{
			name: 'Approved',
			dataKey: 'approved',
			showMetrics: true,
			align: 'center',
		},
		{
			name: 'Denied',
			dataKey: 'denied',
			showMetrics: true,
			align: 'center',
		},
	],
	[TemplatesTabs.DRAFTS]: [
		{
			name: 'Name',
			dataKey: 'title',
			align: 'left',
			size: 40,
			dataTransform: (title, row) => <FormActions tab={TemplatesTabs.DRAFTS} id={row.id} formTitle={title} />,
		},
	],
	[TemplatesTabs.INACTIVE]: [
		{
			name: 'Name',
			dataKey: 'title',
			align: 'left',
			size: 30,
			dataTransform: (title, row) => <FormActions tab={TemplatesTabs.INACTIVE} id={row.id} formTitle={title} />,
		},
		{
			name: 'Date Created',
			dataKey: 'createdAt',
			align: 'center',
			size: 15,
			dataTransform: (date: string) => formatDate(date),
		},
		{
			name: 'Last Opened',
			dataKey: 'last_opened',
			align: 'center',
			size: 15,
		},
		{
			name: 'Approved',
			dataKey: 'approved',
			showMetrics: true,
			align: 'center',
		},
		{
			name: 'Denied',
			dataKey: 'denied',
			showMetrics: true,
			align: 'center',
		},
	],
}
