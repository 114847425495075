import { createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit'

export const FEATURE_NAME = 'EXPORT_LIST'

type TExportListState = Record<string, { fileName: string; progress?: number }>

const initialState: TExportListState = {}

export const exportListSlice = createSlice({
	name: FEATURE_NAME,
	initialState,
	reducers: {
		updateExportProgress: (
			state: TExportListState,
			{ payload: { exportId, fileName, progress } }: PayloadAction<{ fileName: string; progress?: number; exportId: string }>,
		) => {
			state[exportId] = { fileName, progress }
		},
		removeFromExportList: (state, { payload }: PayloadAction<string>) => {
			delete state[payload]
		},
	},
})

export const { removeFromExportList, updateExportProgress } = exportListSlice.actions

// Selectors
const selectState = (state: { [FEATURE_NAME]: TExportListState }) => state[FEATURE_NAME]

export const selectExportListState = createSelector(selectState, (state) => state)
