import React, { useCallback } from 'react'
import {
	Table as MaterialTable,
	TableBody,
	TableCell,
	TableContainer,
	TableFooter,
	TableRow,
	Typography,
	TableHead,
	LinearProgress,
} from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import { specialColors } from '@navengage/sen-shared-assets'
import { TextButton } from '../../components'
import { styles } from '../../constants'
import classNames from 'classnames'

const useStyles = makeStyles((theme) => ({
	cell: {
		...theme.typography.body1,
		paddingLeft: 0,
		borderBottomColor: theme.colors.grey[200],
	},
	semiBoldText: {
		fontWeight: 500,
	},
	divider: {
		borderLeft: `solid 1px ${theme.colors.grey[500]}`,
		margin: '0 5px',
		height: 16,
	},
	footer: {
		width: styles.FILL_AVAILABLE_WIDTH,
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'space-between',
	},
	buttonContainer: {
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
	},
	footerCell: {
		borderBottom: 'none',
		padding: '32px 16px',
		...theme.typography.body1,
	},
	tableInfoText: {
		fontStyle: 'italic',
	},
	loaderCell: {
		borderBottom: 'none',
		padding: 0,
	},
	progressBar: {
		backgroundColor: specialColors.whitetransparent10,
		'&>div.MuiLinearProgress-barColorPrimary': {
			backgroundColor: theme.colors.Primary[700],
		},
	},
}))

interface ColumnInfo {
	name: string
	dataKey: string
	align?: 'left' | 'right' | 'inherit' | 'center' | 'justify'
}

const rowRenderer = (
	rows: any[],
	columns: ColumnInfo[],
	rowsPerPage: number,
	page: number,
	getCellClassName: (isSemiBold?: boolean) => string,
) => {
	const start = (page - 1) * rowsPerPage
	const end = start + rowsPerPage

	return (rowsPerPage > 0 ? rows.slice(start, end) : rows).map((row, index) => (
		<TableRow key={`table-content-row-${index}`}>
			{columns.map(({ name, dataKey, ...rest }, idx) => {
				return (
					<TableCell key={`table-content-cell-${index}-${idx}`} {...rest} className={getCellClassName(idx === 0)}>
						{row[dataKey]}
					</TableCell>
				)
			})}
		</TableRow>
	))
}

interface TableProps {
	classnames?: {
		cell?: string
		progressBar?: string
	}
	columns: ColumnInfo[]
	isLoading?: boolean
	showEmptyRows?: boolean
	page: number
	rowsPerPage?: number
	onChangePage?: (event: any, newPage: number) => void
	totalRows: number
	rows: any[]
	emptyPlaceholder?: React.ReactNode
	footerButtonsColor?: string
	showHeader?: boolean
	showFooter?: boolean
}

const Table = ({
	classnames = {
		cell: '',
		progressBar: '',
	},
	columns,
	isLoading = false,
	showEmptyRows = false,
	page,
	rowsPerPage = 10,
	totalRows,
	rows,
	onChangePage = () => {},
	emptyPlaceholder = null,
	footerButtonsColor,
	showHeader = true,
	showFooter = true,
}: TableProps) => {
	const classes = useStyles()

	const getCellClassName = useCallback(
		(isSemiBold?: boolean) => classNames(classnames.cell, classes.cell, isSemiBold ? classes.semiBoldText : ''),
		[classes.cell, classes.semiBoldText, classnames.cell],
	)

	const renderRows = rowRenderer(rows, columns, rowsPerPage, page, getCellClassName)
	const emptyRows = Math.min(rowsPerPage, rowsPerPage - renderRows.length)

	const handleChangePage = useCallback(
		(newPage: number) => (event: any) => {
			onChangePage(event, newPage)
		},
		[onChangePage],
	)

	const start = (page - 1) * rowsPerPage + 1
	const end = start + rowsPerPage > totalRows ? totalRows : start + rowsPerPage
	const lastPage = Math.ceil(totalRows / rowsPerPage)
	const showPlaceholder = !!emptyPlaceholder && !totalRows && !isLoading

	return (
		<TableContainer>
			<MaterialTable>
				{showHeader && (
					<TableHead>
						<TableRow>
							{columns.map(({ name, dataKey, ...rest }, index) => (
								<TableCell key={`table-head-${index}`} className={getCellClassName(true)} {...rest}>
									{name}
								</TableCell>
							))}
						</TableRow>
					</TableHead>
				)}
				<TableBody>
					{isLoading && (
						<TableRow>
							<TableCell className={classes.loaderCell} colSpan={columns.length}>
								<LinearProgress className={classNames(classnames.progressBar, classes.progressBar)} />
							</TableCell>
						</TableRow>
					)}
					{renderRows}
					{showEmptyRows && emptyRows > 0 && !showPlaceholder && (
						<TableRow style={{ height: 30 * emptyRows }}>
							<TableCell className={getCellClassName()} colSpan={columns.length} />
						</TableRow>
					)}
					{showPlaceholder && (
						<TableRow style={{ height: 30 * emptyRows }}>
							<TableCell className={getCellClassName()} colSpan={columns.length}>
								{emptyPlaceholder}
							</TableCell>
						</TableRow>
					)}
				</TableBody>
				{showFooter && (
					<TableFooter>
						<TableRow>
							<TableCell className={classes.footerCell} colSpan={columns.length}>
								<div className={classes.footer}>
									<Typography className={classes.tableInfoText}>
										Showing {start} - {end} of {totalRows}
									</Typography>
									{rowsPerPage < totalRows && (
										<div className={classes.buttonContainer}>
											<TextButton color={footerButtonsColor} onClick={handleChangePage(1)} disabled={page <= 1}>
												First
											</TextButton>
											<span className={classes.divider} />
											<TextButton color={footerButtonsColor} onClick={handleChangePage(page - 1)} disabled={page <= 1}>
												Prev
											</TextButton>
											<span className={classes.divider} />
											<TextButton color={footerButtonsColor} onClick={handleChangePage(page + 1)} disabled={page >= lastPage}>
												Next
											</TextButton>
											<span className={classes.divider} />
											<TextButton color={footerButtonsColor} onClick={handleChangePage(lastPage)} disabled={page >= lastPage}>
												Last
											</TextButton>
										</div>
									)}
								</div>
							</TableCell>
						</TableRow>
					</TableFooter>
				)}
			</MaterialTable>
		</TableContainer>
	)
}

export default Table
