import { DateTime } from 'luxon'

import makeStyles from '@mui/styles/makeStyles'

import { metricsConfig } from '../../metricsConfig'
import { baseStyles } from './baseStyles'
import { EventsMetricsGroup } from './EventsMetricsGroup'
import { GridArea, GridAreaKey } from './GridArea'
import { OrganizationsMetricsGroup } from './OrganizationsMetricsGroup'
import { UsersMetricsGroup } from './UsersMetricsGroup'

const useStyles = makeStyles(() => ({
	...baseStyles,
	wrapper: {
		...baseStyles.section,
	},
	metricsGrid: {
		display: 'grid',
		gridTemplateAreas: `"a a a b" "c c d d"`,
		border: '0.5px solid #D7D9DE',
		borderRadius: 10,
	},
}))

const GRID_AREA_KEYS = Object.keys(GridAreaKey)

export const MetricsSection = ({ stats }) => {
	const classes = useStyles()
	const todayDate = DateTime.now()

	const { metrics: metricGroups } = metricsConfig

	const validMetricGroups = []

	if (stats.organizations) {
		validMetricGroups.push(
			<OrganizationsMetricsGroup {...{ metricsGroupConfig: metricGroups.organizations, stats: stats.organizations }} />,
		)
	}

	if (stats.users) {
		validMetricGroups.push(<UsersMetricsGroup {...{ metricsGroupConfig: metricGroups.users, stats: stats.users }} />)
	}

	if (stats.events) {
		validMetricGroups.push(<EventsMetricsGroup {...{ metricsGroupConfig: metricGroups.events, stats: stats.events }} />)
	}

	return (
		<section className={classes.wrapper}>
			<div className={classes.sectionHeader}>
				<h2 className={classes.sectionHeaderTitle}>Metrics</h2>
				<p className={classes.sectionHeaderSubtitle}>
					As of {todayDate.weekdayLong}, {todayDate.toFormat('MMMM dd, yyyy')}
				</p>
			</div>
			<div className={classes.metricsGrid}>
				{validMetricGroups.map((component, i) => {
					return (
						<GridArea key={i} gridArea={GridAreaKey[GRID_AREA_KEYS[i]]}>
							<div className="u-padding40">{component}</div>
						</GridArea>
					)
				})}
			</div>
		</section>
	)
}
