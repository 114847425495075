import classNames from 'classnames'
import React from 'react'

import makeStyles from '@mui/styles/makeStyles'

import { styles } from '../../constants'
import NavigationButton, { NavigationButtonClassNames, SideBarNavigation } from './NavigationButton'
import StickyContainer from './StickyContainer'

const useStyles = makeStyles((theme) => ({
	container: {
		width: styles.FILL_AVAILABLE_WIDTH,
		padding: '15px 15px 15px 0',
		borderRadius: 10,
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
		//backgroundColor: theme.colors.grey[100],
	},
	headerContainer: {
		width: styles.FILL_AVAILABLE_WIDTH,
		display: 'flex',
		alignItems: 'center',
		padding: '0 0 15px 0',
		margin: '15px 15px',
		borderBottom: `solid 1px ${theme.colors.grey[300]}`,
	},
	navButtonIcon: {
		width: 40,
		minWidth: 40,
		marginRight: 7,
	},
}))

export interface StaticSideBarProps {
	classnames?: {
		root?: string
	}
	navButtonClassNames?: NavigationButtonClassNames
	navigation: SideBarNavigation[]
	onClickNavigation: (path: string) => void
	header?: React.ReactNode
}

const StaticSideBar = ({ navigation, onClickNavigation, header, navButtonClassNames, classnames }: StaticSideBarProps) => {
	const classes = useStyles()

	return (
		<StickyContainer>
			<div className={classNames(classnames?.root, classes.container)}>
				{header && <div className={classes.headerContainer}>{header}</div>}
				{navigation.map((nav) => (
					<NavigationButton
						classnames={{
							icon: classes.navButtonIcon,
							...navButtonClassNames,
						}}
						key={nav.path}
						onClick={onClickNavigation}
						navigation={nav}
					/>
				))}
			</div>
		</StickyContainer>
	)
}

export default StaticSideBar
