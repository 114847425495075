import axios, { AxiosRequestConfig } from 'axios'
import * as config from '@/constants/configuration'

import util from '@/utils'
import { STORAGE } from '@/constants'

const instance = axios.create({
	baseURL: config.COGNITO_BASE,
	headers: {
		Authorization: config.COGNITO_AUTHORIZATION_BEARER,
		'Content-Type': 'application/x-www-form-urlencoded',
	},
})

instance.interceptors.request.use((req: AxiosRequestConfig) => {
	const token = req.headers?.token
	const userId = util.storage.get(STORAGE.CURRENT_USER_ID_PATH)
	const userAgent = window.navigator.userAgent

	if (token) {
		req.headers = {
			...req.headers,
			Authorization: `Bearer ${token}`,
			userId: `${userId}`,
			userAgent,
		}
	}
	return req
})

export default instance
