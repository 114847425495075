import { useMemo } from 'react'
import { useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'

import { getUserRole } from '@/store/auth'
import { SubHeaderList } from '@/types'
import { getFormattedCurrentPath } from '@/utils/urlHandlers'

const useSubHeader = (params: SubHeaderList) => {
	const history = useHistory()
	const currentPath = getFormattedCurrentPath(history.location.pathname)
	const currentUserRole = useSelector(getUserRole)

	const subHeader = useMemo(() => {
		return params.find((subHeader) => {
			const roleMatch = (subHeader.roles && subHeader.roles.includes(currentUserRole)) || !subHeader.roles
			const pathMatch = currentPath.startsWith(subHeader.path) || !subHeader.path
			return roleMatch && pathMatch
		})
	}, [currentUserRole, params, currentPath])
	return subHeader
}

export default useSubHeader
