import React from 'react'
import { SvgIcon } from '@mui/material'

const QuestionIcon = (props: any) => {
	return (
		<SvgIcon data-name="INFO BUTTON" xmlns="http://www.w3.org/2000/svg" width={24} height={24} viewBox="0 0 24 24" {...props}>
			<g data-name="\u042D\u043B\u043B\u0438\u043F\u0441 332" fill="none" stroke="#7b8794">
				<circle cx={12} cy={12} r={12} stroke="none" />
				<circle cx={12} cy={12} r={11.5} />
			</g>
			<text data-name="?" transform="translate(9 18)" fill="#7b8794" fontSize={15} fontFamily="OpenSans, Open Sans">
				<tspan x={0} y={0}>
					{'?'}
				</tspan>
			</text>
		</SvgIcon>
	)
}

export default QuestionIcon
