import React from 'react'
import makeStyles from '@mui/styles/makeStyles'
import classNames from 'classnames'
import { Typography } from '@mui/material'
import LockIcon from '../../icons/LockIcon'
import QuestionIcon from '../../icons/QuestionIcon'

const useStyles = makeStyles((theme) => ({
	label: {
		display: 'flex',
		alignItems: 'center',
	},
	title: {
		fontWeight: 700,
	},
	semiBold: {
		fontWeight: 600,
	},
	requiredMark: {
		color: theme.colors.red[500],
		marginLeft: 4,
	},
	optionalText: {
		color: theme.colors.grey[500],
		marginLeft: 4,
	},
	descriptionText: {
		color: theme.colors.grey[500],
		marginTop: '1vh',
		marginBottom: '1vh',
	},
	lockIcon: {
		width: 17,
		marginLeft: '0.5vw',
	},
	questionIcon: {
		width: 20,
		marginLeft: '0.5vw',
		cursor: 'pointer',
	},
}))

interface LabelProps {
	classnames?: {
		root?: string
		label?: string
		title?: string
		description?: string
	}
	title: string
	boldTitle?: boolean
	semiBold?: boolean
	required?: boolean
	optional?: boolean
	disabled?: boolean
	onClickQuestion?: () => void
	description?: string
	children: React.ReactNode
}

const Label = ({
	classnames = {
		root: '',
		label: '',
		title: '',
		description: '',
	},
	title = '',
	description = '',
	semiBold = false,
	required = false,
	optional = false,
	boldTitle = true,
	disabled = false,
	onClickQuestion,
	children,
}: LabelProps) => {
	const classes = useStyles()

	const text = (
		<div className={classNames(classnames.label, classes.label)}>
			<Typography
				className={classNames(classnames.title, boldTitle ? classes.title : '', semiBold ? classes.semiBold : '')}
				component="span"
			>
				{title}
			</Typography>
			{required && (
				<Typography className={classes.requiredMark} component="span">
					*
				</Typography>
			)}
			{optional && (
				<Typography className={classes.optionalText} component="span">
					(Optional)
				</Typography>
			)}
			{disabled && <LockIcon className={classes.lockIcon} />}
			{onClickQuestion && <QuestionIcon className={classes.questionIcon} onClick={onClickQuestion} />}
		</div>
	)

	return (
		<div className={classnames.root}>
			{text}
			{description && <Typography className={classNames(classnames.description, classes.descriptionText)}>{description}</Typography>}
			{children}
		</div>
	)
}

export default Label
