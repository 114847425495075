import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useSnackbar } from 'notistack'
import { getNotifications, removeSnackbar } from '@/store/app'

const Notifier = () => {
	const dispatch = useDispatch()
	const [displayed, setDisplayed] = useState<any>([])
	const notifications = useSelector(getNotifications)
	const { enqueueSnackbar, closeSnackbar } = useSnackbar()

	const storeDisplayed = (id: any) => {
		setDisplayed((prevDisplayed: any) => [...prevDisplayed, id])
	}

	const removeDisplayed = (id: any) => {
		setDisplayed((prevDisplayed: any) => [...prevDisplayed.filter((key: any) => id !== key)])
	}

	useEffect(() => {
		notifications.forEach(({ key, message, options = {}, dismissed = false }) => {
			if (dismissed) {
				// dismiss snackbar using notistack
				setTimeout(() => closeSnackbar(key), 1000)
				return
			}

			// do nothing if snackbar is already displayed
			if (displayed.includes(key)) return

			// display snackbar using notistack
			enqueueSnackbar(message, {
				key,
				...options,
				onClose: (event, reason, myKey) => {
					if (options.onClose) {
						options.onClose(event, reason, myKey)
					}
				},
				onExited: (event, myKey) => {
					// remove this snackbar from redux store
					dispatch(removeSnackbar({ key }))
					removeDisplayed(key)
				},
			})

			// keep track of snack-bars that we've displayed
			storeDisplayed(key)
		})
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [notifications, closeSnackbar, enqueueSnackbar, dispatch])

	return null
}

export default Notifier
