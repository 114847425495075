import { LIST_INITIAL_STATE } from '@/constants/app'
import { RootState } from '@/store'
import { enqueueSnackbar } from '@/store/app'
import { AutocompleteDto, IAutocompleteItemDto, ListRequestParams } from '@/types'
import { prepareListParams } from '@/utils/common'
import { createFieldUpdateToast } from '@/utils/notificationHelpers'
import { createAsyncThunk, createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit'
import { MODULE_NAME as AUTH_MODULE_NAME } from '@/store/auth'

import { AutocompleteRequestParams } from '../users/types'
import {
	getAllUserOrganizations,
	getFilters,
	getListCategories,
	getOrganizationById,
	getOrganizationEvents,
	getOrganizationRegistrations,
	getOrganizations,
	organizationsAutocomplete,
	updateOrganizationSettings,
} from './api'
import { ORGANIZATION_PRIMARY_ROLES } from './constants'
import {
	OrganizationDto,
	OrganizationEventsDto,
	OrganizationRegistrationDto,
	OrganizationsDto,
	OrganizationsListParams,
	OrganizationsState,
	TOrganizationPrimaryRoleKeys,
	TOrganizationPrimaryUser,
	UserOrganizations,
} from './types'

export const FEATURE_NAME = 'ORGANIZATIONS'

export const loadOrganizationsConstantsRequest = createAsyncThunk(
	`${FEATURE_NAME}/LOAD_ORGANIZATIONS_CONSTANTS_REQUEST`,
	async (_undefined, { dispatch, rejectWithValue }) => {
		try {
			const categories = await getListCategories()
			const {
				data: { types, statuses },
			} = await getFilters()

			const createOption = (arr: any[]) =>
				arr.map((item) => ({
					label: item.name || item.category,
					value: item.id,
				}))

			dispatch(
				setFilters({
					categories: createOption(categories.data.categories),
					types,
					statuses,
				}),
			)
		} catch (e: any) {
			return rejectWithValue(e)
		}
	},
)

export const getOrganizationsListRequest = createAsyncThunk<
	OrganizationsDto,
	ListRequestParams,
	{
		state: RootState
	}
>(`${FEATURE_NAME}/GET_ORGANIZATIONS_REQUEST`, async (requestParams, { dispatch, getState, rejectWithValue }) => {
	try {
		const {
			ORGANIZATIONS: {
				organizationList: { params },
			},
		} = getState()

		const queryParams = prepareListParams(
			{
				...params,
			},
			requestParams,
			dispatch,
			setListParams,
		) as OrganizationsListParams

		const response = await getOrganizations(queryParams)

		return response.data
	} catch (e: any) {
		return rejectWithValue(e)
	}
})

export const getOrganizationsRegistrationRequest = createAsyncThunk<
	OrganizationRegistrationDto,
	ListRequestParams,
	{
		state: RootState
	}
>(`${FEATURE_NAME}/GET_ORGANIZATION_REGISTRATION_REQUEST`, async (requestParams, { dispatch, getState, rejectWithValue }) => {
	try {
		const {
			ORGANIZATIONS: {
				organizationRegistrationList: { params },
			},
		} = getState()

		const queryParams = prepareListParams(
			{
				...params,
			},
			requestParams,
			dispatch,
			setListParams,
		) as OrganizationsListParams

		const response = await getOrganizationRegistrations(queryParams)

		return response.data
	} catch (e: any) {
		return rejectWithValue(e)
	}
})

export const getOrganizationsEventsListRequest = createAsyncThunk<
	OrganizationEventsDto,
	ListRequestParams & { id: string },
	{
		state: RootState
	}
>(`${FEATURE_NAME}/GET_ORGANIZATION_EVENTS_REQUEST`, async (requestParams, { dispatch, getState, rejectWithValue }) => {
	try {
		const {
			ORGANIZATIONS: {
				organizationEventList: { params },
				selectedOrganization: { id },
			},
		} = getState()

		const queryParams = prepareListParams(
			{
				...params,
			},
			requestParams,
			dispatch,
			setListParams,
		) as OrganizationsListParams

		const response = await getOrganizationEvents(id, queryParams)

		return response.data
	} catch (e: any) {
		return rejectWithValue(e)
	}
})
export const getOrganizationsByIdRequest = createAsyncThunk<
	OrganizationDto,
	string,
	{
		state: RootState
	}
>(`${FEATURE_NAME}/GET_ORGANIZATION_BY_ID_REQUEST`, async (id, { rejectWithValue }) => {
	try {
		const response = await getOrganizationById(id)
		return response.data
	} catch (e: any) {
		return rejectWithValue(e)
	}
})

export const updateOrganizationSettingsRequest = createAsyncThunk<
	{ success: 'Success' },
	{ data: Partial<OrganizationDto>; message: string },
	{
		state: RootState
	}
>(`${FEATURE_NAME}/UPDATE_ORGANIZATION_SETTINGS_REQUEST`, async ({ data, message }, { rejectWithValue, getState, dispatch }) => {
	const {
		ORGANIZATIONS: {
			selectedOrganization: { id },
		},
	} = getState()
	try {
		const response = await updateOrganizationSettings(id, data)

		dispatch(enqueueSnackbar(createFieldUpdateToast(message)))
		dispatch(updateSelectedOrganization(data))

		return response.data
	} catch (e: any) {
		return rejectWithValue(e)
	}
})

export const organizationsAutocompleteRequest = createAsyncThunk<
	AutocompleteDto<IAutocompleteItemDto>,
	AutocompleteRequestParams,
	{
		state: RootState
	}
>(`${FEATURE_NAME}/ORGANIZATIONS_AUTOCOMPLETE_REQUEST`, async (params, { dispatch, getState, rejectWithValue }) => {
	try {
		const response = await organizationsAutocomplete(params)

		return response.data
	} catch (e: any) {
		return rejectWithValue(e)
	}
})

export const initCurrentUserOrganizations = createAsyncThunk<
	UserOrganizations,
	undefined,
	{
		state: RootState
	}
>(`${FEATURE_NAME}/INIT_USER_ORGANIZATIONS`, async (__, { dispatch, getState, rejectWithValue }) => {
	const {
		[AUTH_MODULE_NAME]: {
			userInfo: { id: userId },
		},
	} = getState()
	try {
		const { data } = await getAllUserOrganizations(userId)

		return data
	} catch (e: any) {
		return rejectWithValue(e)
	}
})

const initialState: OrganizationsState = {
	categories: [],
	types: [],
	statuses: [],
	isLoading: true,
	constantsInitialized: false,
	organizationList: { ...LIST_INITIAL_STATE },
	organizationRegistrationList: { ...LIST_INITIAL_STATE },
	userOrganizations: {
		loading: false,
	},
	initialized: false,
	selectedOrganization: null,
	organizationEventList: { ...LIST_INITIAL_STATE },
}

export const organizationSlice = createSlice({
	name: FEATURE_NAME,
	initialState,
	reducers: {
		setFilters: (state, { payload: { categories, types, statuses } }) => {
			state.categories = categories
			state.types = types
			state.statuses = statuses
		},
		setListParams: (state, { payload }) => {
			state.organizationList.params = { ...state.organizationList.params, ...payload }
		},
		setCurrentOrganizationId: (state, { payload }) => {
			if (!state.selectedOrganization) {
				state.selectedOrganization = { id: payload } as OrganizationDto
			} else {
				state.selectedOrganization.id = payload
			}
		},
		updateSelectedOrganization: (state, { payload }: PayloadAction<Partial<OrganizationDto>>) => {
			if (state.selectedOrganization && state.selectedOrganization.id === payload.id) {
				state.selectedOrganization = { ...state.selectedOrganization, ...payload }
			}
		},
		setOrganizationStatus: (state, { payload: { id, status } }) => {
			if (state.selectedOrganization && state.selectedOrganization.id === id) {
				state.selectedOrganization.status = status
			}
		},
	},
	extraReducers: (builder) => {
		builder
			.addCase(getOrganizationsListRequest.fulfilled, (state, { payload }) => {
				state.organizationList.isLoading = false
				state.organizationList.list = payload
			})
			.addCase(getOrganizationsListRequest.rejected, (state) => {
				state.organizationList.isLoading = false
			})
			.addCase(getOrganizationsListRequest.pending, (state) => {
				state.organizationList.isLoading = true
			})
			.addCase(getOrganizationsByIdRequest.pending, (state) => {
				state.isLoading = true
			})
			.addCase(getOrganizationsByIdRequest.rejected, (state) => {
				state.isLoading = false
			})
			.addCase(getOrganizationsByIdRequest.fulfilled, (state, { payload }) => {
				state.selectedOrganization = { ...payload }
				state.isLoading = false
			})
			.addCase(getOrganizationsEventsListRequest.pending, (state) => {
				state.organizationEventList.isLoading = true
			})
			.addCase(getOrganizationsEventsListRequest.rejected, (state) => {
				state.organizationEventList.isLoading = false
			})
			.addCase(getOrganizationsEventsListRequest.fulfilled, (state, { payload }) => {
				state.organizationEventList.list = payload.list
				state.selectedOrganization = {
					...state.selectedOrganization,
					...payload.organization,
				}
				state.organizationEventList.isLoading = false
			})
			.addCase(getOrganizationsRegistrationRequest.pending, (state) => {
				state.organizationRegistrationList.isLoading = true
			})
			.addCase(getOrganizationsRegistrationRequest.rejected, (state) => {
				state.organizationRegistrationList.isLoading = false
			})
			.addCase(getOrganizationsRegistrationRequest.fulfilled, (state, { payload }) => {
				state.organizationRegistrationList.isLoading = false
				state.organizationRegistrationList.list = payload
			})
			.addCase(initCurrentUserOrganizations.pending, (state) => {
				state.userOrganizations.loading = true
			})
			.addCase(initCurrentUserOrganizations.fulfilled, (state, { payload }) => {
				state.userOrganizations.loading = false
				state.userOrganizations.list = payload
			})
			.addCase(initCurrentUserOrganizations.rejected, (state) => {
				state.userOrganizations.loading = false
			})
	},
})

export const { setFilters, setListParams, setCurrentOrganizationId, setOrganizationStatus, updateSelectedOrganization } =
	organizationSlice.actions

const selectedState = (state: { [FEATURE_NAME]: OrganizationsState }) => state[FEATURE_NAME]

export const selectOrganizationsList = createSelector(selectedState, (state) => state.organizationList)
export const selectCurrentOrganization = createSelector(selectedState, (state) => state.selectedOrganization)
export const selectCurrentOrganizationPrimaryUsers = createSelector(
	selectedState,
	(state): { role: TOrganizationPrimaryRoleKeys; user?: TOrganizationPrimaryUser }[] => {
		return (Object.keys(ORGANIZATION_PRIMARY_ROLES) as TOrganizationPrimaryRoleKeys[])
			.filter((role) => {
				return ['UserId', 'UserEmail', 'UserName'].every((key) => state.selectedOrganization.hasOwnProperty(`${role}${key}`))
			})
			.map((role) => {
				const user = {
					id: parseInt(state.selectedOrganization[`${role}UserId`], 10),
					email: state.selectedOrganization[`${role}UserEmail`],
					fullName: state.selectedOrganization[`${role}UserName`],
				}

				return {
					role,
					user,
				}
			})
	},
)
export const selectOrganizationsIsLoading = createSelector(selectedState, (state) => state.isLoading)

export const selectOrganizationEventsList = createSelector(selectedState, (state) => state.organizationEventList)

export const selectOrganizationRegistrations = createSelector(selectedState, (state) => state.organizationRegistrationList)

export const selectInitialized = createSelector(selectedState, (state) => state.initialized)
export const getCategories = createSelector(selectedState, (state) => state.categories)
export const getOrgTypes = createSelector(selectedState, (state) => state.types)
export const getOrgStatuses = createSelector(selectedState, (state) => state.statuses)

//export const getUserOrganizations = createSelector(selectedState, (state) => state.userOrganizations.list)
export const selectUserOrganizations = createSelector(selectedState, (state) => state.userOrganizations)
export const selectUserAdminOrganizations = createSelector(selectedState, (state) => {
	if (state.userOrganizations.list) {
		const { admin, superAdmin } = state.userOrganizations.list
		return [...admin, ...superAdmin].map((org) => ({ label: org.name, value: org.id }))
	} else {
		return []
	}
})
