import { useHistory } from 'react-router-dom'

import { PATHS } from '@/constants'
import { Button, Card, Typography } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'

const useStyles = makeStyles((theme) => ({
	root: {
		width: '100vw',
		minHeight: '100vh',
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
		justifyContent: 'center',
		backgroundColor: theme.colors.grey[100],
	},
	titleText: {
		fontWeight: theme.typography.fontWeightBold,
		padding: theme.spacing(1.25),
		color: theme.colors.Primary[700],
		alignItems: 'center',
		justifyContent: 'center',
	},
	contentText: {
		color: theme.colors.grey[500],
		padding: theme.spacing(1.25),
		alignItems: 'center',
		justifyContent: 'center',
	},
}))

const NotAuthorized = () => {
	const classes = useStyles()
	const { push } = useHistory()

	return (
		<div className={classes.root}>
			<Card>
				<Typography className={classes.titleText} variant="h1">
					Error
				</Typography>
				<Typography className={classes.contentText}>
					Your campus is not using the Organization Management System, or you do not have privileges to see this page.
				</Typography>
				<Typography className={classes.contentText}>
					If you think this is an error, please contact engagementapp@psu.edu. Thank you.
				</Typography>
				<Button onClick={() => push(PATHS.APP.ROOT)}>Back to Homepage</Button>
			</Card>
		</div>
	)
}

export default NotAuthorized
