import { useDispatch } from 'react-redux'

import { ButtonColors, ColorButton, OutlinedButton } from '@/components/shared'
import { ROLES } from '@/constants'
import { LandingPageLayout } from '@/pages/landing/layout'
import { devSignInRequest } from '@/store/auth'
import { devSignupHandler, handleSignIn } from '@/utils/authHandlers'
import Typography from '@mui/material/Typography'
import makeStyles from '@mui/styles/makeStyles'
import { alpha } from '@mui/system'

const useStyles = makeStyles((theme) => ({
	root: {
		position: 'relative',
		width: '100%',
		padding: '0 5%',
		[theme.breakpoints.down('sm')]: {
			padding: '0 5%',
		},
		background: 'round linear-gradient(0deg, rgba(2,26,67,1) 0%, rgba(1,31,69,0.23) 67%), url(./img/landing-page-background.webp)',
		backgroundPosition: 'top center',
		backgroundRepeat: 'no-repeat',
		backgroundSize: 'cover',
	},
	heroTitle: {
		width: 800,
		fontSize: 75,
		lineHeight: 1.1,
		fontWeight: theme.typography.fontWeightBold,
		[theme.breakpoints.down('lg')]: {
			maxWidth: 730,
			fontSize: 55,
			lineHeight: 1.2,
		},
		[theme.breakpoints.down('md')]: {
			maxWidth: 520,
			fontSize: 40,
			lineHeight: 1.2,
		},
		[theme.breakpoints.down('sm')]: {
			maxWidth: 320,
			fontSize: 30,
			lineHeight: 1.3,
		},
		color: theme.colors.white[500],
		margin: `${theme.spacing(26.25)} 0 ${theme.spacing(9.5)} 1%`,
	},
	button: {
		height: '5.5vh',
		width: '13.5vw',
		minWidth: 180,
		fontWeight: 600,
		minHeight: 50,
		marginRight: '2%',
		color: '#ffffff',
		[theme.breakpoints.down('sm')]: {
			minWidth: 120,
			minHeight: 30,
		},
	},
	buttonContainer: {
		marginTop: '4%',
		marginLeft: '1%',
		width: '100%',
	},
	logo: {
		height: 230,
		[theme.breakpoints.down('xl')]: {
			height: 195,
		},
		[theme.breakpoints.down('md')]: {
			height: 135,
		},
		[theme.breakpoints.down('sm')]: {
			height: 75,
		},
	},
	createAccountSection: {
		position: 'absolute',
		top: 0,
		left: 0,
		width: '100%',
		backgroundColor: alpha(theme.colors.Primary[1000], 0.81),
		padding: `${theme.spacing(3)} 0`,
		color: theme.colors.white[500],
	},
	overlayInner: {
		padding: '0 6%',
		justifyContent: 'space-between',
		display: 'flex',
		alignItems: 'center',
		fontWeight: theme.typography.fontWeightMedium,
	},
}))

const notAllowedDevSignIn = ['staging', 'prod']
const isDevEnv = !notAllowedDevSignIn.includes(`${process.env.REACT_APP_ENV}`)

const LandingPage = () => {
	const classes = useStyles()
	const dispatch = useDispatch()

	const handleDevSignin = (role: ROLES) => () => {
		dispatch(devSignInRequest(role))
	}

	return (
		<LandingPageLayout>
			<div className={classes.root}>
				<div className={classes.createAccountSection}>
					<div className={classes.overlayInner}>
						<Typography>
							If you have not created an account on the Engagement App, please do so in order to sign in to the Organization Management
							System. Thank you!
						</Typography>
						<OutlinedButton className={classes.button} color={ButtonColors.WHITE} onClick={handleSignIn}>
							Create Account
						</OutlinedButton>
					</div>
				</div>
				<Typography className={classes.heroTitle}>Organization Management System</Typography>
				<div className={classes.buttonContainer}>
					<ColorButton className={classes.button} onClick={handleSignIn}>
						Sign In
					</ColorButton>
					{isDevEnv && (
						<>
							<ColorButton className={classes.button} onClick={() => devSignupHandler(ROLES.STUDENT)}>
								Dev Student Signup
							</ColorButton>
							<ColorButton className={classes.button} onClick={() => devSignupHandler(ROLES.CAMPUS_ADMIN)}>
								Dev Admin Signup
							</ColorButton>
							<ColorButton className={classes.button} onClick={() => devSignupHandler(null)}>
								Dev Null Signup
							</ColorButton>
						</>
					)}
				</div>
				{isDevEnv && (
					<div className={classes.buttonContainer}>
						<ColorButton className={classes.button} onClick={handleDevSignin(ROLES.STUDENT)}>
							Dev Student
						</ColorButton>
						<ColorButton className={classes.button} onClick={handleDevSignin(ROLES.STUDENT_ADMIN)}>
							Dev Student Admin
						</ColorButton>
						<ColorButton className={classes.button} onClick={handleDevSignin(ROLES.CAMPUS_ADMIN)}>
							Dev System Admin
						</ColorButton>
					</div>
				)}
			</div>
		</LandingPageLayout>
	)
}

export default LandingPage
