import React from 'react'
import { Control, Controller, UseFormRegister } from 'react-hook-form'

import FieldErrorMessage from '../FieldErrorMessage'
import { Label, TextField } from '../shared'

export type IControlledInputProps = {
	control: Control<any, any>
	name: string
	required?: boolean
	disabled?: boolean
	multiline?: boolean
	inputClass?: string
	labelClass?: string
	errors?: Record<string, any>
	label: string
	placeholder?: string
	register: UseFormRegister<any>
}

const ControlledInput: React.FC<IControlledInputProps> = ({
	control,
	name,
	required = false,
	disabled = false,
	multiline = false,
	inputClass,
	labelClass,
	errors,
	label,
	placeholder,
	register,
}) => {
	return (
		<Controller
			name={name}
			control={control}
			render={({ field }) => {
				const { name } = field

				return (
					<Label classnames={{ root: labelClass }} title={label} required={required}>
						<TextField
							className={inputClass}
							error={!!errors[name]}
							disabled={disabled}
							variant="standard"
							multiline={multiline}
							placeholder={placeholder}
							{...register(name)}
						/>
						<FieldErrorMessage hasError={!!errors[name]} message={errors[name] ? errors[name].message : ''} />
					</Label>
				)
			}}
		/>
	)
}

export { ControlledInput }
