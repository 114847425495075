import React from 'react'
import { styled } from '@mui/material/styles'
import { Button } from '@mui/material'

const StyledButton = styled(Button)(({ theme }) => ({
	borderColor: theme.colors.Neutral[300],
	borderWidth: 1,
	fontWeight: theme.typography.fontWeightMedium,
	textTransform: 'none',
}))

interface Props {
	children: React.ReactNode
	onClick: React.MouseEventHandler
}

export function DataTableCellButton({ children, onClick }: Props) {
	return (
		<StyledButton variant="outlined" size="small" color="inherit" onClick={onClick}>
			{children}
		</StyledButton>
	)
}
