import { Switch } from '@mui/material'
import React from 'react'
import { DropdownQuestion } from '../../types'
import { useSettingsChangeHandler } from '../hooks/useSettingsChangeHandler'
import { FieldSettingsContainer } from '../styledComponents/FieldSettingsContainer'
import { StyledFormControlLabelInline } from '../styledComponents/StyledFormControlLabelInline'

type TSettingKey = keyof DropdownQuestion

export interface ISettingsProps extends DropdownQuestion {
	onChange: (key: TSettingKey, value: any) => void
}

const Settings: React.FC<ISettingsProps> = ({ onChange, multiple }) => {
	const onChangeHandler = useSettingsChangeHandler<TSettingKey>(onChange)
	return (
		<FieldSettingsContainer>
			<StyledFormControlLabelInline
				labelPlacement="start"
				control={<Switch checked={multiple} onChange={onChangeHandler('multiple', 'checked')} />}
				label="Allow multiple selection?"
			/>
		</FieldSettingsContainer>
	)
}

export { Settings }
