import { isNumber } from 'lodash'

import { MaterialUiColor } from '@/constants/materialUi'
import { Event, GroupTwoTone } from '@mui/icons-material'
import makeStyles from '@mui/styles/makeStyles'

import { baseStyles } from '../../../../components/layouts/dashboard/baseStyles'
import { ActivityCard } from './ActivityCard'

const useStyles = makeStyles(() => ({
	...baseStyles,
	wrapper: {
		...baseStyles.section,
	},
	activitiesGrid: {
		display: 'grid',
		gap: '21px',
		gridTemplateColumns: 'repeat(auto-fill, minmax(200px, 1fr)) ',
	},
}))

const statsConfig = [
	{
		title: 'Event Submissions',
		Icon: () => <Event color={MaterialUiColor.PRIMARY} fontSize="large" />,
		getCount: (stats) => stats.eventSubmissions,
	},
	{
		title: 'New Org Registrations',
		Icon: () => <GroupTwoTone color={MaterialUiColor.PRIMARY} fontSize="large" />,
		getCount: (stats) => stats.organizationRegistrations,
	},
	{ title: 'Org Re-Registrations' },
	{ title: 'Financial Requests' },
	{ title: 'Service Hour Submissions' },
	{ title: 'Current Elections' },
]

export const ActivitySection = ({ stats }) => {
	const classes = useStyles()

	const activities: { title: string; count: number; Icon: () => JSX.Element }[] = statsConfig.reduce(
		(validActivities, { title, getCount, Icon }) => {
			const count = getCount?.(stats)

			if (!isNumber(count)) {
				return validActivities
			}

			return [...validActivities, { title, count, Icon }]
		},
		[],
	)

	return (
		<section className={classes.wrapper}>
			<div className={classes.sectionHeader}>
				<h2 className={classes.sectionHeaderTitle}>Activity</h2>
			</div>
			<div className={classes.activitiesGrid}>
				{activities.map((activity, index) => {
					return <ActivityCard key={index} {...{ activity }} />
				})}
			</div>
		</section>
	)
}
