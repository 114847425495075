import { useRef, useState } from 'react'
import { useForm } from 'react-hook-form'

import { Card, ColorButton, styles } from '@/components/shared'
import { yupResolver } from '@hookform/resolvers/yup'
import { Popover, Typography } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import { specialColors } from '@navengage/sen-shared-assets'

import { FieldDataType, FormBuilderData } from '../FormBuilder/types'
import AnswerCard from './AnswerCard'
import { FormReaderData } from './types'
import getFormReaderValidationSchema from './validation'

const useStyles = makeStyles((theme) => ({
	root: {
		width: styles.FILL_AVAILABLE_WIDTH,
		padding: '30px 0',
	},

	form: {
		margin: '0 auto',
		width: '70%',
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
		justifyContent: 'center',
	},
	submitButton: {
		margin: '30px 0',
		padding: '10px 20px',
		width: '15vw',
		minWidth: 200,
	},
	bold: {
		fontWeight: theme.typography.fontWeightBold,
	},
	tooltip: {
		color: theme.colors.white[500],
		backgroundColor: theme.colors.grey[500],
		borderRadius: 3,
		padding: '5px 10px',
	},
	popover: {
		pointerEvents: 'none',
	},
	paper: {
		backgroundColor: specialColors.transparent,
		paddingTop: '1vh',
		boxShadow: 'none',
	},
}))

interface FormReaderProps {
	form: FormBuilderData<FieldDataType>
	data?: FormReaderData
	disabled?: boolean
	onSubmit?: (data: FormReaderData) => void
	submission?: boolean
}

const FormReader = ({ form, data, disabled, onSubmit, submission }: FormReaderProps) => {
	const classes = useStyles()

	const anchorRef = useRef<any>(null)

	const [openTooltip, setOpenTooltip] = useState(false)

	const {
		control,
		handleSubmit,
		formState: { errors, isValid, isDirty },
	} = useForm<FormReaderData>({
		resolver: yupResolver(getFormReaderValidationSchema(form.questions)),
		mode: 'onChange',
		reValidateMode: 'onChange',
		criteriaMode: 'firstError',
		shouldFocusError: true,
		defaultValues: data,
	})

	const disableSubmitButton = !isValid || !isDirty

	const shouldShowTooltip = disableSubmitButton && openTooltip

	const handleOpenTooltip = () => {
		setOpenTooltip(true)
	}
	const handleCloseTooltip = () => {
		setOpenTooltip(false)
	}

	const submitAndSetUpdating = (data: FormReaderData) => {
		if (onSubmit && !disableSubmitButton) {
			onSubmit(data)
		}
	}

	return (
		<div className={classes.root}>
			<form className={classes.form} onSubmit={handleSubmit(submitAndSetUpdating)}>
				<Card>
					<Typography className={classes.bold} variant="h1">
						{form.settings?.title}
					</Typography>
				</Card>
				{form.questions.map((q, index) => (
					<AnswerCard
						hideDefaultText
						submission={submission}
						key={q.id}
						index={index}
						data={q}
						control={control}
						errors={errors}
						disabled={disabled}
					/>
				))}
				{anchorRef?.current && (
					<Popover
						className={classes.popover}
						classes={{
							paper: classes.paper,
						}}
						open={shouldShowTooltip}
						anchorEl={anchorRef?.current}
						anchorOrigin={{
							vertical: 'bottom',
							horizontal: 'center',
						}}
						transformOrigin={{
							vertical: 'top',
							horizontal: 'center',
						}}
						disableRestoreFocus
					>
						<Typography className={classes.tooltip}>Complete required fields</Typography>
					</Popover>
				)}
				{!submission && (
					<ColorButton
						className={classes.submitButton}
						type={disableSubmitButton ? 'button' : 'submit'}
						disabled={disableSubmitButton}
						ref={anchorRef}
						onMouseEnter={handleOpenTooltip}
						onMouseLeave={handleCloseTooltip}
						component={disableSubmitButton ? 'div' : undefined}
					>
						Submit
					</ColorButton>
				)}
			</form>
		</div>
	)
}

export default FormReader
