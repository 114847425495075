import React, { useCallback, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Link, Route } from 'react-router-dom'

import { ActionModal } from '@/components/ActionModal'
import { DataTable } from '@/components/EntityManager/DataTable'
import { RouterModal } from '@/components/Modal/RouterModal'
import { useDeleteConfirmation } from '@/hooks/useDeleteConfirmation'
import { GridColumns } from '@mui/x-data-grid'
import { DataTableCellButton } from '@/components/buttons/DataTableCellButton'

import { USERS_PATHS } from '../../constants'
import { deleteCampusAdminRequest, getCampusAdminsListRequest, selectAdminsList } from '../../usersSlice'
import AddAdminModal from '../../components/CampusAdminModal'

const roleToLabelMap = {
	owner: 'Owner',
	admin: 'Admin',
}

const dataColumns: GridColumns = [
	{
		field: 'userName',
		headerName: 'Name',
		flex: 1,
		renderCell: (params) => {
			if (params.row.userId) {
				return <Link to={USERS_PATHS.VIEW_USER(params.row.userId)}>{params.value}</Link>
			}
			return params.value
		},
	},
	{
		field: 'email',
		headerName: 'Email',
		flex: 1,
	},
	{
		field: 'role',
		headerName: 'Role',
		flex: 1,
		sortable: false,
		renderCell: (params) => {
			const text = <span>{roleToLabelMap[params.value] || params.value}</span>
			const icon = params.row.role === 'owner' ? <img src="/img/icons/crown.png" alt="crown" style={{ marginLeft: '1rem' }} /> : null

			return (
				<>
					{text} {icon}
				</>
			)
		},
	},
]

const CampusAdmins = () => {
	const dispatch = useDispatch()
	const adminsList = useSelector(selectAdminsList)

	const modalChildren = useCallback((handleClose) => {
		return <AddAdminModal handleClose={handleClose} open={true} />
	}, [])

	const reloadList = useCallback(() => {
		dispatch(getCampusAdminsListRequest({ page: 1 }))
	}, [dispatch])

	const { handleClose, confirmationRequired, setConfirmationRequired, renderButtons, setCurrentEntityId } = useDeleteConfirmation({
		request: deleteCampusAdminRequest,
		entity: '',
		deleteBtnLabelPrefix: 'Remove',
		id: '',
		cb: reloadList,
	})
	const handleDeleteClick = useCallback(
		(data) => {
			setCurrentEntityId(data.row.id)
			setConfirmationRequired(true)
		},
		[setConfirmationRequired, setCurrentEntityId],
	)

	const columns = useMemo(() => {
		return [
			...dataColumns,
			{
				field: 'manage',
				headerName: 'Manage',
				flex: 1,
				sortable: false,
				renderCell: (params) => {
					if (params.row.role === 'owner') {
						return 'This user cannot be removed.'
					}
					return (
						<DataTableCellButton
							onClick={(e: any) => {
								handleDeleteClick(params)
								e.stopPropagation()
							}}
						>
							Remove
						</DataTableCellButton>
					)
				},
			},
		]
	}, [handleDeleteClick])

	return (
		<>
			<DataTable data={adminsList} request={getCampusAdminsListRequest} columns={columns} />
			<Route
				path={USERS_PATHS.NEW_ADMIN()}
				render={(props) => {
					return <RouterModal children={modalChildren} />
				}}
			/>
			<ActionModal
				handleClose={handleClose}
				open={confirmationRequired}
				renderButtons={renderButtons}
				title={`Remove Campus Admin`}
				description="Are you sure you want to remove this user as a Campus Admin?"
			/>
		</>
	)
}

export default CampusAdmins
