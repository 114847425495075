import { useSelector } from 'react-redux'
import { Link } from 'react-router-dom'

import { TABLE_NAMES } from '@/components/EntityManager/config'
import { DataTable } from '@/components/EntityManager/DataTable'
import { GridColumns } from '@mui/x-data-grid'

import { ORGANIZATION_PATHS } from '../../constants'
import { getOrganizationsListRequest, selectOrganizationsList } from '../../organizationSlice'
import { OrganizationStatuses } from '../../types'

const columns: GridColumns = [
	{
		field: 'name',
		headerName: 'Name',
		filterable: false,
		flex: 1,
		renderCell: (params) => {
			return <Link to={ORGANIZATION_PATHS.SINGLE(params.row.id)}>{params.row.name}</Link>
		},
	},
	{
		field: 'status',
		headerName: 'Status',
		filterable: true,
		type: 'singleSelect',
		valueOptions: Object.values(OrganizationStatuses),
		flex: 1,
	},
	{
		field: 'type',
		headerName: 'Type',
		filterable: false,
		flex: 1,
	},
	{
		field: 'category',
		headerName: 'Category',
		filterable: false,
		flex: 1,
	},
]

const Active = () => {
	const organizationsList = useSelector(selectOrganizationsList)
	return (
		<DataTable tableName={TABLE_NAMES.ORGANIZATIONS} data={organizationsList} request={getOrganizationsListRequest} columns={columns} />
	)
}

export default Active
