import { createAction, createAsyncThunk } from '@reduxjs/toolkit'
import { MODULE_NAME } from '@/store/organizations/constants'
import * as http from '@/api/http'
import { RootState } from '@/store'
import { Organization } from './types'
import { getOrganizationType } from '@/utils/organizationRoles'
import { ORGANIZATION_ROLE } from '@/features/organizations/constants'

export const setIsLoading = createAction<boolean>(`${MODULE_NAME}/SET_IS_LOADING`)

export const setOrganizationsList = createAction<{
	list: Organization[]
	total: number
}>(`${MODULE_NAME}/SET_ORGANIZATION_LIST`)
export const setFilters = createAction<{
	categories: {
		label: string
		value: number
	}[]
	types: {
		label: string
		value: number
	}[]
	statuses: {
		label: string
		value: string
	}[]
}>(`${MODULE_NAME}/SET_FILTERS`)

export const loadOrganizationsListRequest = createAsyncThunk<
	any,
	{
		q: string
		campusId: number | null
		categoryId: number | null
		status: string | null
		type: number | null
	},
	{
		state: RootState
	}
>(
	`${MODULE_NAME}/LOAD_ORGANIZATIONS_LIST_REQUEST`,
	async ({ q, campusId, categoryId, status, type }, { dispatch, getState, rejectWithValue }) => {
		try {
			const { id } = getState().AUTH.userInfo

			const { data } = await http.organizations.searchOrganizations({
				q,
				userId: id,
				campusId,
				categoryId,
				status,
				orgTypeId: type,
				membershipTypeId: [getOrganizationType(ORGANIZATION_ROLE.SUPER_ADMIN)],
			})

			dispatch(setOrganizationsList({ list: data, total: data.length }))
		} catch (e: any) {
			return rejectWithValue(e)
		}
	},
)

export const loadOrganizationsConstantsRequest = createAsyncThunk(
	`${MODULE_NAME}/LOAD_ORGANIZATIONS_CONSTANTS_REQUEST`,
	async (_undefined, { dispatch, rejectWithValue }) => {
		try {
			const categories = await http.organizations.getListCategories()
			const {
				data: { types, statuses },
			} = await http.organizations.getFilters()

			const createOption = (arr: any[]) =>
				arr.map((item) => ({
					label: item.name || item.category,
					value: item.id,
				}))

			dispatch(
				setFilters({
					categories: createOption(categories.data.categories),
					types,
					statuses,
				}),
			)
		} catch (e: any) {
			return rejectWithValue(e)
		}
	},
)
