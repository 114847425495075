import { useToggleValue } from '@/hooks/useToggleValue'
import { ExpandLess, ExpandMore } from '@mui/icons-material'
import { TimePicker } from '@mui/x-date-pickers'
import { DateTime } from 'luxon'
import { useCallback, useMemo } from 'react'

import { HiddenInput, PickerContainer } from './DatePickerSelect'
import { SelectButton } from './SelectButton'

export type ITimePickerSelectProps = {
	onChange: (date: Date) => void
	value: string | Date
	disabled?: boolean
	label?: string
}

const TimePickerSelect: React.FC<ITimePickerSelectProps> = ({ onChange, value, label = 'Select Time', disabled }) => {
	const [isOpened, toggleTimePicker] = useToggleValue()
	const displayLabel = useMemo(() => {
		if (value) {
			return DateTime.fromJSDate(new Date(value)).toLocaleString(DateTime.TIME_24_SIMPLE)
		} else {
			return label
		}
	}, [label, value])
	return (
		<TimePicker
			disableOpenPicker={disabled}
			label="Basic example"
			value={value}
			onChange={onChange}
			open={isOpened}
			onOpen={toggleTimePicker}
			onClose={toggleTimePicker}
			renderInput={({ inputRef, inputProps }) => (
				<PickerContainer>
					<HiddenInput ref={inputRef} {...inputProps} />
					<SelectButton
						disabled={disabled}
						filled={value ? true : false}
						onClick={toggleTimePicker}
						icon={isOpened ? <ExpandLess /> : <ExpandMore />}
					>
						{displayLabel}
					</SelectButton>
				</PickerContainer>
			)}
		/>
	)
}

export { TimePickerSelect }
