import React from 'react'
import { useHistory } from 'react-router-dom'

import { ANNOUNCEMENTS_PATH } from '@/features/announcements/constants'
import { RESOURCES_PATH } from '@/features/resources/constants'
import { PostDto, TPostType } from '@/types'
import DeleteIcon from '@mui/icons-material/Delete'
import { Button, IconButton, Typography } from '@mui/material'
import { makeStyles } from '@mui/styles'

import { PostLinks } from './PostLinks'

const useStyles = makeStyles((theme) => ({
	root: {
		marginTop: theme.spacing(3),
		paddingBottom: theme.spacing(4.5),
		borderBottom: `1px solid ${theme.colors.Neutral[200]}`,
	},
	itemBody: {
		display: 'flex',
		justifyContent: 'space-between',
		alignItems: 'center',
		marginBottom: theme.spacing(2.25),
	},
	description: {
		maxWidth: 940,
	},
	actionsList: {
		marginLeft: theme.spacing(3),
	},
	title: {
		fontWeight: theme.typography.fontWeightMedium,
		marginBottom: theme.spacing(2.125),
	},

	editButton: {
		textTransform: 'none',
		color: theme.colors.black[500],
		border: `1px solid ${theme.colors.grey[300]}`,
		padding: `${theme.spacing(1.5)} ${theme.spacing(3.25)}`,
		marginRight: theme.spacing(2.75),
		borderRadius: 7,
		'&:hover': {
			color: theme.colors.Primary[700],
		},
	},
}))

export type IPostListItemProps = PostDto & {
	canEdit?: boolean
	onDelete?: (id: string) => void
	postType: TPostType
}

const PostListItem: React.FC<IPostListItemProps> = ({ title, description, id, canEdit, onDelete, postType, ...rest }) => {
	const classes = useStyles()
	const { push } = useHistory()

	const handleEditClick = () => {
		if (postType === 'Resource') {
			push(RESOURCES_PATH.EDIT(id))
		} else {
			push(ANNOUNCEMENTS_PATH.EDIT(id))
		}
	}
	const handleDeleteClick = () => {
		if (onDelete) onDelete(id)
	}

	return (
		<div className={classes.root}>
			<Typography className={classes.title}>{title}</Typography>
			<div className={classes.itemBody}>
				<Typography className={classes.description}>{description}</Typography>
				{canEdit && (
					<div className={classes.actionsList}>
						<Button className={classes.editButton} variant="outlined" onClick={handleEditClick}>
							Edit
						</Button>

						<IconButton onClick={handleDeleteClick}>
							<DeleteIcon />
						</IconButton>
					</div>
				)}
			</div>
			<PostLinks linksData={rest} />
		</div>
	)
}

export default PostListItem
