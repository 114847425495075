import React from 'react'
import makeStyles from '@mui/styles/makeStyles'
import { styles } from '../../constants'
import classNames from 'classnames'

const useStyles = makeStyles((theme) => ({
	root: {
		width: styles.FILL_AVAILABLE_WIDTH,
		height: '100%',
		backgroundColor: 'none',
		boxShadow: 'none',
		zIndex: 1,
		display: 'flex',
		flexDirection: 'row',
		alignItems: 'flex-start',
		justifyContent: 'center',
	},
	contentWrapper: {
		height: '100%',
	},
	container: {
		position: 'sticky',
		top: `${styles.HEADER_HEIGHT}vh`,
		width: styles.FILL_AVAILABLE_WIDTH,
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
	},
}))

interface StickyContainerProps {
	children: React.ReactNode
	className?: string
}

const StickyContainer = ({ children, className = '' }: StickyContainerProps) => {
	const classes = useStyles()

	return (
		<div className={classes.root}>
			<div className={classNames(className, classes.container)}>{children}</div>
			<div className={classes.contentWrapper} />
		</div>
	)
}

export default StickyContainer
