import { styles } from '@/components/shared'
import appTheme from '@/theme'
import makeStyles from '@mui/styles/makeStyles'

const useStyles = makeStyles((theme) => ({
	root: ({ backgroundColor }: any) => ({
		width: styles.FILL_AVAILABLE_WIDTH,
		padding: '24px 30px 0 15px',
		backgroundColor,
	}),
}))

interface ContentContainerProps {
	children: React.ReactNode
	backgroundColor?: string
}

const ContentContainer = ({ children, backgroundColor = appTheme.colors.white[500] }: ContentContainerProps) => {
	const classes = useStyles({ backgroundColor })

	return <div className={classes.root}>{children}</div>
}

export default ContentContainer
