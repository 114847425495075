import React from 'react'
import { Theme } from '@mui/material/styles'
import makeStyles from '@mui/styles/makeStyles'
import { Tooltip, IconButton } from '@mui/material'
import { ButtonColors, pickButtonColor, createButtonColors } from '../../../theme'
import classNames from 'classnames'

interface StylesProps {
	color?: ButtonColors
}

const useStyles = makeStyles<Theme, StylesProps>((theme) => ({
	root: ({ color }) => {
		const buttonColors = createButtonColors(theme)
		const buttonColor = pickButtonColor(color ?? ButtonColors.PRIMARY_LIGHT, buttonColors)

		return {
			'&:hover': {
				color: buttonColor.textColor,
				backgroundColor: buttonColor.main,
			},
		}
	},
	label: {
		position: 'absolute',
		fontSize: 15,
		width: '1%',
		right: '-1%',
		textAlign: 'left',
	},
	tooltip: ({ color }) => ({
		fontSize: 16,
		padding: '5px 15px',
		borderRadius: 2,
		backgroundColor: theme.colors.grey[500],
		'& .MuiTooltip-arrow': {
			color: theme.colors.grey[500],
		},
	}),
	popper: {
		opacity: 0.95,
	},
}))

interface IconButtonWithTooltipProps extends StylesProps {
	classnames?: {
		tooltip?: string
		tooltipPopper?: string
		button?: string
		label?: string
	}
	tooltipTitle: string
	onClick?: (e: any) => void
	label?: string
	children: React.ReactNode
	disabled?: boolean
	placement?:
		| 'bottom'
		| 'left'
		| 'right'
		| 'top'
		| 'bottom-end'
		| 'bottom-start'
		| 'left-end'
		| 'left-start'
		| 'right-end'
		| 'right-start'
		| 'top-end'
		| 'top-start'
	arrow?: boolean
}

const IconButtonWithTooltip = ({
	classnames = {
		tooltip: '',
		tooltipPopper: '',
		button: '',
		label: '',
	},
	tooltipTitle,
	onClick = () => {},
	label,
	children,
	color = ButtonColors.PRIMARY_LIGHT,
	disabled,
	placement = 'top',
	arrow,
}: IconButtonWithTooltipProps) => {
	const classes = useStyles({ color })

	return (
		<Tooltip
			arrow={arrow}
			title={tooltipTitle}
			placement={placement}
			classes={{
				tooltip: classNames(classnames.tooltip, classes.tooltip),
				popper: classNames(classnames.tooltipPopper, classes.popper),
			}}
		>
			<IconButton onClick={onClick} className={classNames(classnames.button, classes.root)} disabled={disabled} size="large">
				{children}
				{label && <span className={classNames(classnames.label, classes.label)}>{label}</span>}
			</IconButton>
		</Tooltip>
	)
}

export default IconButtonWithTooltip
