import {
	FEEDBACK_URL,
	PSU_PHOTO_REGISTRY,
	REQUEST_ADD_ORG_URL,
	TWENTY_FIVE_LIVE_URL,
	PSU_LIONPATH_LINK,
	REQUEST_ADD_RESEARCH_URL,
	REQUEST_ADD_EDUCATION_ABROAD_URL,
	ABOUT_APP_URL,
	ACTIVATE_ORGANIZATION_URL,
	ORG_CENTRAL_URL,
} from '@/constants/configuration'

// Format Date for Google Calendar
export function googleDateFormat(a: string) {
	const b = new Date(a).toISOString().replace(/-|:|\.\d\d\d/g, '')
	return b
}

// Create Google Calendar link
export function createGoogleLink(name: string, startDate: string, endDate: string, description: string) {
	const googleUrl = `https://www.google.com/calendar/render?action=TEMPLATE&text=${encodeURIComponent(name)}&dates=${googleDateFormat(
		startDate,
	)}/${googleDateFormat(endDate)}&details=${encodeURIComponent(description)}`
	return googleUrl
}

// Create Outlook Link:
export function createOutlookLink(name: string, startDate: string, endDate: string, description: string) {
	const outlookUrl = `https://outlook.office.com/calendar/0/deeplink/compose?body=${encodeURIComponent(description)}&enddt=${new Date(
		endDate,
	).toISOString()}&startdt=${new Date(startDate).toISOString()}&subject=${encodeURIComponent(name)}`
	return outlookUrl
}

export const submitFeedback = () => window.open(FEEDBACK_URL, '_blank')

export const goTo25Live = () => window.open(TWENTY_FIVE_LIVE_URL, '_blank')

export const switchToStudentView = () => {
	const appUrl = process.env.REACT_APP_ENGAGEMENT_APP_URL

	window.open(appUrl, '_current')
}

export const requestToAddOrganization = () => window.open(REQUEST_ADD_ORG_URL, '_blank')

export const requestToAddStudyAbroad = () => window.open(REQUEST_ADD_EDUCATION_ABROAD_URL, '_blank')

export const requestToAddResearch = () => window.open(REQUEST_ADD_RESEARCH_URL, '_blank')

export const activateOrganizationForm = () => window.open(ACTIVATE_ORGANIZATION_URL, '_blank')

export const openOrgCentralPage = () => window.open(ORG_CENTRAL_URL, '_blank')

export const addPrefixToLink = (link: string) => {
	// eslint-disable-next-line no-useless-escape
	if (link.search(/^http[s]?\:\/\//) === -1) {
		link = 'http://' + link
	}
	return link
}

export const goToRegistry = () => window.open(PSU_PHOTO_REGISTRY, '_blank')

export const goToLionPath = () => window.open(PSU_LIONPATH_LINK, '_blank')

export const openAboutAppPage = () => window.open(ABOUT_APP_URL, '_blank')

export const createGmailMessageLink = (subject: string, message: string) =>
	`https://mail.google.com/mail/u/0/?view=cm&fs=1&tf=1&subject=${encodeURIComponent(subject)}&body=${encodeURIComponent(message)}`
export const createOutlookMessageLink = (subject: string, message: string) =>
	`https://outlook.office.com/mail/u/0/?view=cm&fs=1&tf=1&subject=${encodeURIComponent(subject)}&body=${encodeURIComponent(message)}`
