import { SIDEBAR_SIZES } from '@/styles/sidebar'
import { styled } from '@mui/styles'

export const PageContentContainer = styled('div')(({ theme }) => ({
	display: 'flex',
	flexDirection: 'column',
	alignItems: 'center',
	width: `${100 - SIDEBAR_SIZES.regular}%`,
	[theme.breakpoints.down('md')]: {
		width: `${100 - SIDEBAR_SIZES.md}%`,
	},
}))
