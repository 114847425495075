import * as yup from 'yup'
import { QuestionType, FormBuilderQuestion, AnswerOptionType, FieldDataType, DropdownQuestion } from '../FormBuilder/types'

const getFormReaderValidationSchema = (questions: FormBuilderQuestion<FieldDataType>[]) => {
	const getOptionWithCustomAnswerSchema = () =>
		yup.object().shape({
			id: yup.string(),
			type: yup.string(),
			otherAnswer: yup.string().when('type', (type, schema) => (type === AnswerOptionType.other ? schema.required() : schema)),
		})

	return yup.object().shape(
		questions.reduce((acc, { questionType, required, id, fieldData }) => {
			let questionSchema: any = {}

			switch (questionType) {
				case QuestionType.Checkboxes:
					questionSchema = {
						[id]: yup.array().of(getOptionWithCustomAnswerSchema()),
					}
					break
				case QuestionType.Radio:
					questionSchema = {
						[id]: getOptionWithCustomAnswerSchema(),
					}
					break
				case QuestionType.FileUpload:
				case QuestionType.Autocomplete:
					questionSchema = { [id]: yup.mixed() }
					break
				case QuestionType.Dropdown:
					const dropdownData = fieldData as DropdownQuestion
					questionSchema = dropdownData.multiple ? { [id]: yup.array() } : { [id]: yup.string() }
					if (dropdownData.multiple && required) {
						questionSchema[id] = questionSchema[id].min(1)
					}
					break
				case QuestionType.TextAnswer:
				default:
					questionSchema = { [id]: yup.string() }
					break
			}

			if (required) {
				questionSchema[id] = questionSchema[id].required()
			}

			return { ...acc, ...questionSchema }
		}, {}),
	)
}

export default getFormReaderValidationSchema
