import { Chip } from '@mui/material'
import { styled } from '@mui/styles'

const CategoryItem = styled(Chip)(({ theme }) => ({
	...theme.shape,
	...theme.typography.body1,
	height: 'auto',
	marginRight: theme.spacing(1.25),
	marginBottom: theme.spacing(1.25),
	fontWeight: theme.typography.fontWeightMedium,
	border: `1px solid ${theme.colors.Neutral[300]}`,
	padding: `${theme.spacing(1)} ${theme.spacing(2.5)}`,
}))

export default CategoryItem
