import Footer from '@/components/Footer'
import makeStyles from '@mui/styles/makeStyles'

import Header from './header'

const useStyles = makeStyles((theme) => ({
	root: {
		width: '100%',
		minHeight: '100vh',
		height: 'max-content',
		display: 'flex',
		flexDirection: 'column',
		justifyContent: 'space-between',
	},
	container: {
		flex: '1 1 auto',
		width: '100vw',
		display: 'flex',
	},
	button: {
		margin: '4vh 4vw 4vh 0',
		height: '5.5vh',
		width: '13.5vw',
		minWidth: 180,
		fontweight: 600,
		minHeight: 50,
		[theme.breakpoints.down('sm')]: {
			minWidth: 120,
			minHeight: 30,
		},
	},
}))

export const LandingPageLayout = ({ children }: any) => {
	const classes = useStyles()

	return (
		<div className={classes.root}>
			<Header />
			<main className={classes.container}>{children}</main>
			<Footer />
		</div>
	)
}
