import classNames from 'classnames'
import { useState } from 'react'

import { APP_MESSAGES } from '@/constants'

import IconButton from '@/components/IconButton'
import { TextButton } from '@/components/shared'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'
import { Typography } from '@mui/material'
import { makeStyles } from '@mui/styles'
import { UserNotification } from '../types'
import Notification from './Notification'

interface StyledProps {
	showDivider?: boolean
}

const useStyles = makeStyles((theme) => ({
	contentContainer: {
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
		overflowY: 'auto',
	},
	noMoreMessage: {
		margin: '20px auto 20px auto',
	},
	rootContainer: ({ showDivider }: { showDivider: boolean }) => ({
		width: '100%',
		margin: '15px 0',
		borderBottom: showDivider ? `solid 1px ${theme.colors.grey[200]}` : 'none',
	}),
	title: {
		marginBottom: 15,
		fontWeight: 600,
	},
	placeholder: {
		padding: '5px 0 25px 0',
		width: '100%',
	},
	seeMoreButton: {
		color: theme.colors.Primary[700],
		borderColor: theme.colors.Primary[700],
		border: 'solid 1px',
		padding: '10px 20px',
	},
	controlPanel: {
		width: '100%',
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
		margin: '3vh 0 6vh 0',
	},
	icon: {
		fontSize: '2.1em',
	},
	seeMoreLink: {
		fontWeight: 600,
	},
}))

type NotificationsBlockProps = {
	classnames?: {
		root?: string
		contentContainer?: string
	}
	notifications?: UserNotification[]
	isNew?: boolean
	compactView?: boolean
	handleLoadMoreItems?: (page: number) => void
	isLoading?: boolean
	canLoadMore?: boolean
	handleClosePopup?: () => void
	seeMoreLink?: boolean
	onNotificationView?: (notification: UserNotification) => void
	hideTitle?: boolean
} & StyledProps

const NotificationsBlock = ({
	classnames = {
		root: '',
		contentContainer: '',
	},
	notifications = [],
	seeMoreLink,
	isNew = false,
	compactView = false,
	showDivider = true,
	handleLoadMoreItems = () => {},
	isLoading = false,
	canLoadMore = false,
	handleClosePopup = () => {},
	onNotificationView,
	hideTitle,
}: NotificationsBlockProps) => {
	const classes = useStyles({ showDivider })
	const [currentPage, setCurrentPage] = useState(1)

	const handleLoadMore = () => {
		const page = currentPage + 1
		handleLoadMoreItems(page)
		setCurrentPage(page)
	}

	return (
		<div className={classNames(classnames.root, classes.rootContainer)}>
			{!hideTitle && <Typography className={classes.title}>{isNew ? 'New' : 'Past'}</Typography>}
			{isNew && !notifications.length ? (
				<div className={classes.placeholder}>
					<Typography>{APP_MESSAGES.NOTIFICATIONS.ALL_CAUGHT_UP}</Typography>
				</div>
			) : (
				<div className={classNames(classnames.contentContainer, classes.contentContainer)}>
					{notifications.map((notification) => (
						<Notification
							onViewed={onNotificationView}
							notification={notification}
							key={notification.id}
							compactView={compactView}
							handleClosePopup={handleClosePopup}
						/>
					))}

					{canLoadMore ? (
						!isLoading && (
							<div className={classes.controlPanel}>
								{seeMoreLink ? (
									<TextButton className={classes.seeMoreLink} onClick={handleLoadMore}>
										{APP_MESSAGES.GENERALS.SEE_MORE}
									</TextButton>
								) : (
									<IconButton
										className={classes.seeMoreButton}
										endIcon={<KeyboardArrowDownIcon className={classes.icon} fontSize="medium" />}
										onClick={handleLoadMore}
									>
										{APP_MESSAGES.GENERALS.SEE_MORE}
									</IconButton>
								)}
							</div>
						)
					) : (
						<Typography className={classes.noMoreMessage}>{APP_MESSAGES.NOTIFICATIONS.NO_MORE} 🎉</Typography>
					)}
				</div>
			)}
		</div>
	)
}

export default NotificationsBlock
