import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import Router from '@/components/Router'

import { EVENT_PATHS } from '../constants'
import { getCategories, loadEventConstantsRequest, selectConstantsInitialized } from '../eventsSlice'
import EVENT_ROUTES from '../routes'

const EventsPage = React.memo(() => {
	const dispatch = useDispatch()
	const constantsInitialized = useSelector(selectConstantsInitialized)
	const eventCategories = useSelector(getCategories)

	useEffect(() => {
		if (!constantsInitialized || eventCategories.length === 0) {
			dispatch(loadEventConstantsRequest())
		}
	}, [dispatch, eventCategories, constantsInitialized])
	return (
		<Router
			routes={EVENT_ROUTES}
			redirectPaths={[
				{
					from: '/',
					to: EVENT_PATHS.NEW_LIST,
				},
			]}
		/>
	)
})

export default EventsPage
