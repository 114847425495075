import Axios from '@/api/Cognito/config'

interface Dto {
	[k: string]: any
}

interface AxiosDto {
	data: any
	status: number
}

const parseResponseData = (axiosData: AxiosDto) => ({
	data: axiosData.data,
	status: axiosData.status,
})

export const post = async (url: string, data: Dto) => {
	const response = await Axios.post(url, data)
	return parseResponseData(response)
}

export const put = async (url: string, data: Dto) => {
	const response = await Axios.put(url, data)
	return parseResponseData(response)
}

export const del = async (url: string, params: Dto) => {
	const response = await Axios.delete(url, { params })
	return parseResponseData(response)
}

export const get = async (url: string, params: Dto, config: Dto) => {
	const response = await Axios.get(url, { params, ...config })
	return parseResponseData(response)
}

type Method = typeof post | typeof put | typeof del | typeof get

export const errorHandler = async (method: Method, url: string, payload = {}, config = {}) => {
	try {
		const response = await method(url, payload, config)
		return response
	} catch (err: any) {
		console.error('Cognito error on: ', url, ' with payload: ', payload, '. Error message: ', err.message)
		return {
			data: {},
			status: null,
		}
	}
}
