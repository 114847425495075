import React from 'react'
import { Link as RouterLink } from 'react-router-dom'

import { ActionMenu } from '@/components/ActionMenu'
import { ActionModal } from '@/components/ActionModal'
import { PATHS } from '@/constants'
import { APP_URL } from '@/constants/configuration'
import { useDeleteConfirmation } from '@/features/events/hooks'
import { useHandleCopy } from '@/hooks/useHandleCopy'
import { deleteFormFromTemplates, deleteFormRequest } from '@/store/forms'
import ContentCopyIcon from '@mui/icons-material/ContentCopy'
import DeleteIcon from '@mui/icons-material/Delete'
import EditIcon from '@mui/icons-material/Edit'
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined'
import { Link } from '@mui/material'
import { makeStyles } from '@mui/styles'
import classNames from 'classnames'
import { useDispatch } from 'react-redux'
import { TemplatesTabs } from './Tabs/Templates/tabsData'

const useStyles = makeStyles((theme) => ({
	link: {
		textDecoration: 'none',
		color: theme.colors.black[500],
		...theme.typography.body1,
	},
	menuItemLink: {
		padding: '8px 16px',
		width: '100%',
		display: 'flex',
		alignItems: 'center',
	},
}))
export type IFormActionsProps = {
	id: string
	formTitle: string
	isDraft?: boolean
	tab: TemplatesTabs
}

const FormActions: React.FC<IFormActionsProps> = ({ id, formTitle, tab }) => {
	const classes = useStyles()
	const copyHandler = useHandleCopy(true)
	const dispatch = useDispatch()
	const isDraft = tab === TemplatesTabs.DRAFTS
	const handleCopy = () => {
		if (!isDraft) {
			copyHandler(`${APP_URL.replace(/\/$/, '')}${PATHS.APP.SUBMIT_FORM(id)}`)
		} else {
			copyHandler(`${APP_URL.replace(/\/$/, '')}${PATHS.APP.FORMS_EDIT(id)}`)
		}
	}
	const { handleClose, confirmationRequired, setConfirmationRequired, renderButtons } = useDeleteConfirmation(
		deleteFormRequest,
		'Form',
		id,
		() => dispatch(deleteFormFromTemplates({ tab, formId: id })),
	)
	const handleDeleteClick = () => {
		setConfirmationRequired(true)
	}
	return (
		<>
			<Link className={classes.link} component={RouterLink} to={PATHS.APP.FORMS_EDIT(id)}>
				{formTitle}
			</Link>

			<ActionMenu
				actions={[
					{
						title: (
							<Link className={classNames(classes.link, classes.menuItemLink)} component={RouterLink} to={PATHS.APP.FORMS_EDIT(id)}>
								<EditIcon />
								Edit Form
							</Link>
						),
					},
					{
						title: (
							<Link
								className={classNames(classes.link, classes.menuItemLink)}
								component={RouterLink}
								to={PATHS.APP.VIEW_FORM_SUBMISSIONS(id)}
							>
								<VisibilityOutlinedIcon />
								View Submissions
							</Link>
						),
					},
					{
						title: (
							<Link onClick={handleCopy} className={classNames(classes.link, classes.menuItemLink)}>
								<ContentCopyIcon />
								{isDraft ? 'Copy Edit Link' : 'Copy Submission Link'}
							</Link>
						),
					},
					{
						title: (
							<Link onClick={handleDeleteClick} className={classNames(classes.link, classes.menuItemLink)}>
								<DeleteIcon />
								Delete Form
							</Link>
						),
					},
				]}
			/>
			<ActionModal
				handleClose={handleClose}
				open={confirmationRequired}
				renderButtons={renderButtons}
				title={`Delete Form`}
				description="Are you sure you want to delete this form?"
			/>
		</>
	)
}

export { FormActions }
