import { Box, styled } from '@mui/material'

const Card = styled(Box)(({ theme }) => ({
	padding: `${theme.spacing(3.75)} ${theme.spacing(5.25)} `,
	border: `1px solid ${theme.colors.Neutral[300]}`,
	marginTop: theme.spacing(2),
	borderRadius: 7,
}))

export default Card
