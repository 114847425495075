import makeStyles from '@mui/styles/makeStyles'
import { styles } from '@/components/shared'
import ItemOrdinalScaleContainer from '../../ItemOrdinalScaleContainer'
import { AnswerOptionType, AnswerWithOtherOption, FieldDataWithOtherAnswer } from '../../types'
import AddOption from './AddOption'
import Option from './Option'

const useStyles = makeStyles((theme) => ({
	root: {
		width: styles.FILL_AVAILABLE_WIDTH,
		margin: '10px 0',
	},
}))

interface OptionsCardProps {
	data: FieldDataWithOtherAnswer
	isEdit?: boolean
	onChange: (v: AnswerWithOtherOption[]) => void
	optionTypeElement?: React.ReactNode
}

const OptionsCard = ({ data, onChange, isEdit, optionTypeElement = null }: OptionsCardProps) => {
	const classes = useStyles()

	const canAddOtherOption = !data.answers.some((a) => a.type === AnswerOptionType.other)

	const handelChangeItem = (id: number) => (value: string) =>
		onChange(data.answers.map((a, idx) => (idx === id ? { ...a, answer: value } : a)))
	const handelDeleteItem = (id: number) => () => onChange(data.answers.filter((a, idx) => idx !== id))
	const handelAddItem = (answer: AnswerWithOtherOption) => {
		if (canAddOtherOption) {
			onChange(data.answers.concat(answer))
		} else {
			const otherOptionId = data.answers.length - 1

			onChange([...data.answers.slice(0, otherOptionId), answer, ...data.answers.slice(otherOptionId)])
		}
	}

	return (
		<div className={classes.root}>
			{data.answers.map((a, idx) => (
				<ItemOrdinalScaleContainer
					key={a.id}
					uniqId={a.id}
					currentIndex={idx}
					itemsArray={data.answers}
					isEdit={isEdit}
					onChange={onChange}
					disabled={a.type === AnswerOptionType.other}
					maxArrayLength={canAddOtherOption ? data.answers.length : data.answers.length - 1}
				>
					<Option
						key={a.id}
						optionTypeElement={optionTypeElement}
						data={a}
						isEdit={isEdit}
						onChange={handelChangeItem(idx)}
						onDelete={handelDeleteItem(idx)}
						canDelete={data.answers.length > 1}
					/>
				</ItemOrdinalScaleContainer>
			))}
			{isEdit && <AddOption optionTypeElement={optionTypeElement} canAddOtherOption={canAddOtherOption} onAddNewAnswer={handelAddItem} />}
		</div>
	)
}

export default OptionsCard
