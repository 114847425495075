import { useCallback, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Link, Route, useHistory } from 'react-router-dom'

import { ActionModal } from '@/components/ActionModal'
import ContentContainer from '@/components/ContentContainer'
import { DataTable } from '@/components/EntityManager/DataTable'
import { Header } from '@/components/EntityManager/Header'
import { RouterModal } from '@/components/Modal/RouterModal'
import { getGridAction } from '@/features/events/helpers'
import { useDeleteConfirmation } from '@/features/events/hooks'
import { ORGANIZATION_PATHS } from '@/features/organizations/constants'
import { USERS_PATHS } from '@/features/users/constants'
import { getFullDateFormatted } from '@/utils/dateTime'
import { GridColumns } from '@mui/x-data-grid'

import { DocumentModal } from '../components/DocumentModal'
import { DOCUMENTS_PATH } from '../constants'
import { deleteDocumentRequest, getDocumentsRequest, selectDocumentsList } from '../documentsSlice'

interface DocumentListProps {
	organizationId?: string
	subHeader?: string
}

const columns: GridColumns = [
	{
		field: 'originalname',
		headerName: 'Name',
		filterable: false,
		flex: 1,
		renderCell: (params) => {
			return (
				<a download href={params.row.location}>
					{params.row.originalname}
				</a>
			)
		},
	},
	{
		field: 'organization',
		headerName: 'Organization',
		filterable: false,
		flex: 1,
		renderCell: (params) => {
			const { organization } = params.row
			if (!organization) {
				return 'N/A'
			}
			const { name, id } = organization
			return <Link to={ORGANIZATION_PATHS.SINGLE(id)}>{name}</Link>
		},
	},
	{
		field: 'createdBy',
		headerName: 'Uploaded By',
		filterable: false,
		flex: 1,
		renderCell: (params) => {
			const {
				createdBy: { id, firstName, lastName },
			} = params.row
			return (
				<Link to={USERS_PATHS.VIEW_USER(id)}>
					{firstName} {lastName}
				</Link>
			)
		},
	},
	{
		field: 'createdAt',
		headerName: 'Date Uploaded',
		filterable: false,
		flex: 1,
		valueGetter: (params) => getFullDateFormatted(params.row.createdAt),
	},
]

const DocumentList = ({
	organizationId,
	subHeader = 'See documents uploaded on behalf of organizations at your campus',
}: DocumentListProps) => {
	const { push } = useHistory()
	const documentsList = useSelector(selectDocumentsList)
	const dispatch = useDispatch()
	const [documentName, setDocumentName] = useState<string>('')
	const handleCreateDocument = useCallback(() => {
		push(!organizationId ? DOCUMENTS_PATH.NEW : ORGANIZATION_PATHS.NEW_DOCUMENT(organizationId))
	}, [push, organizationId])
	const reloadList = useCallback(() => {
		dispatch(getDocumentsRequest({ page: 1, organizationId }))
	}, [dispatch, organizationId])
	const { handleClose, confirmationRequired, setConfirmationRequired, renderButtons, setCurrentEntityId } = useDeleteConfirmation(
		deleteDocumentRequest,
		'Document',
		undefined,
		reloadList,
	)
	const handleDownload = useCallback(
		(id, data) => () => {
			const link = document.createElement('a')
			link.download = data.row.originalname
			link.href = data.row.location
			link.click()
		},
		[],
	)
	const handleDeleteClick = useCallback(
		(id, data) => () => {
			setCurrentEntityId(id)
			setConfirmationRequired(true)
			if (data && data.row.originalname) setDocumentName(data.row.originalname)
		},
		[setConfirmationRequired, setCurrentEntityId],
	)
	const extendedColumns = useMemo(() => {
		return [
			...columns.filter((col) => !organizationId || (organizationId && col.field !== 'organization')),
			{
				field: 'actions',
				type: 'actions',
				headerName: 'Actions',
				width: 100,
				cellClassName: 'actions',
				getActions: getGridAction({ handleDeleteClick, handleDownload }),
			},
		]
	}, [handleDeleteClick, handleDownload, organizationId])

	const modalChildren = useCallback(
		(handleClose) => {
			return <DocumentModal organizationId={organizationId} handleClose={handleClose} open={true} />
		},
		[organizationId],
	)

	const defaultParams = useMemo(() => ({ organizationId }), [organizationId])
	return (
		<ContentContainer>
			<Header
				title={'Documents'}
				handlePrimaryBtn={handleCreateDocument}
				primaryBtnText={'Upload Document'}
				searchPlaceholder={'Search documents'}
				searchRequest={getDocumentsRequest}
				subHeader={subHeader}
			/>
			<DataTable defaultParams={defaultParams} data={documentsList} request={getDocumentsRequest} columns={extendedColumns} />
			<Route
				path={!organizationId ? DOCUMENTS_PATH.NEW : ORGANIZATION_PATHS.NEW_DOCUMENT(organizationId)}
				render={(props) => {
					return <RouterModal children={modalChildren} />
				}}
			/>
			<Route
				path={DOCUMENTS_PATH.EDIT()}
				render={({
					match: {
						params: { id },
					},
				}) => {
					return <RouterModal children={modalChildren} />
				}}
			/>
			<ActionModal
				handleClose={handleClose}
				open={confirmationRequired}
				renderButtons={renderButtons}
				title={`Delete ${documentName}`}
				description="Are you sure you want to delete this document?"
			/>
		</ContentContainer>
	)
}

export default DocumentList
