import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { v4 as uuidv4 } from 'uuid'

import { enqueueSnackbar, getError } from '@/store/app'
import { SnackbarType } from '@/store/app/types'

export const useErrorAsSnackbar = () => {
	const error = useSelector(getError)
	const dispatch = useDispatch()
	useEffect(() => {
		if (error?.message) {
			dispatch(
				enqueueSnackbar({
					key: uuidv4(),
					notification: {
						message: {
							message: error.message,
							type: SnackbarType.error,
						},
					},
				}),
			)
		}
	}, [error, dispatch])
	return error
}
