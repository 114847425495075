import http from '@/api/http/requestHttp'
import { PSU_SCHOOL_ID } from '@/constants/configuration'

import { AutocompleteRequestParams, UserInfoDto, UserListQueryParams, UsersListDto, CampusAdminsListDto } from './types'

const BASE_URL = `${PSU_SCHOOL_ID}/oms/users`

export const getUsersList = async (params: UserListQueryParams) => {
	return http.get<UsersListDto>(BASE_URL, { params })
}

export const getCampusAdminsList = async (params: UserListQueryParams) => {
	return http.get<CampusAdminsListDto>(`${BASE_URL}/admins`, { params })
}

export const addCampusAdmin = (userId: number) => {
	return http.post(`${BASE_URL}/admins`, { userId })
}

export const deleteCampusAdmin = async (adminId: string) => {
	return http.delete(`${BASE_URL}/admins/${adminId}`)
}

export const getUserById = async (userId: number) => {
	const url = `${BASE_URL}/${userId}`
	return http.get<UserInfoDto>(url)
}

export const searchUsers = async (params: AutocompleteRequestParams, campusId: number) => {
	const { sort, page, ...filteredParams } = params
	const url = `${PSU_SCHOOL_ID}/users/autocomplete`
	return http.get(url, { params: { ...filteredParams, campusId: campusId } })
}

export const searchCampusAdminCandidates = async (params: AutocompleteRequestParams, campusId: number) => {
	const { sort, page, ...filteredParams } = params
	const url = `${PSU_SCHOOL_ID}/oms/users/admins/candidates`
	return http.get(url, { params: { ...filteredParams, campusId: campusId } })
}
