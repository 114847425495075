import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import MiddlePaneLoader from '@/components/MiddlePaneLoader'
import { CommonSelect } from '@/components/shared'
import { CardContainer } from '@/features/organizations/components/CardContainer'
import { FnUpdateRole, RoleEditor } from '@/features/organizations/components/RoleEditor'
import { StyledInput } from '@/features/organizations/components/StyledInput'
import { UserRow } from '@/features/organizations/components/UserRow'
import { OrganizationDescriptionMapper, organizationStatusOptions, ORGANIZATION_PRIMARY_ROLES } from '@/features/organizations/constants'
import {
	getOrganizationsByIdRequest,
	selectCurrentOrganization,
	selectCurrentOrganizationPrimaryUsers,
	selectOrganizationsIsLoading,
	updateOrganizationSettingsRequest,
} from '@/features/organizations/organizationSlice'
import { OrganizationDto, OrganizationStatuses } from '@/features/organizations/types'
import { AppDispatch } from '@/store'
import { getUserRole } from '@/store/auth'
import { isAdmin } from '@/utils/authHandlers'
import { engagementAppLink, LINK_SCOPES } from '@/utils/linksHelper'
import { Link, styled, Typography } from '@mui/material'
import { makeStyles } from '@mui/styles'
import { Box } from '@mui/system'
import isArray from 'lodash/isArray'

const SettingsSection = styled(Box)(({ theme }) => ({
	marginBottom: theme.spacing(7.25),
	maxWidth: 1142,
	'& h2': {
		fontSize: 25,
		fontWeight: theme.typography.fontWeightBold,
		marginBottom: theme.spacing(2),
	},
}))

const useStyles = makeStyles((theme) => ({
	settingsContainer: {
		marginTop: theme.spacing(4.25),
	},
	statusSelect: {
		marginTop: theme.spacing(4),
		minWidth: theme.spacing(29),
	},
	statusDescription: {
		...theme.typography.body1,
	},
}))

const SettingsContent: React.FC<OrganizationDto> = ({
	status,
	memberCount,
	id,

	superAdmins,
	admins,
}) => {
	const dispatch = useDispatch<AppDispatch>()
	const classes = useStyles()
	const currentUserRole = useSelector(getUserRole)
	const [statusValue, setStatusValue] = useState<OrganizationStatuses>(status)
	const appLink = engagementAppLink(LINK_SCOPES.ORGANIZATION, id)
	const updateStatus = useCallback(
		(selectedStatus) => {
			dispatch(updateOrganizationSettingsRequest({ data: { status: selectedStatus, id }, message: 'Status' }))
			setStatusValue(selectedStatus)
		},
		[dispatch, id],
	)

	const organizationPrimaryUsers = useSelector(selectCurrentOrganizationPrimaryUsers)

	const description = useMemo(
		() =>
			status && OrganizationDescriptionMapper[status]
				? OrganizationDescriptionMapper[status].replaceAll(new RegExp(`(${status})`, 'ig'), '<strong>$1</strong>')
				: '',
		[status],
	)

	const updateRole = useCallback<FnUpdateRole>(
		(role, { id: userId, email, fullName }) => {
			dispatch(
				updateOrganizationSettingsRequest({
					data: {
						[`${role}UserId`]: userId,
						[`${role}UserEmail`]: email,
						[`${role}UserName`]: fullName,
						id,
					},
					message: ORGANIZATION_PRIMARY_ROLES[role].label,
				}),
			)
		},
		[dispatch, id],
	)
	return (
		<div className={classes.settingsContainer}>
			<SettingsSection>
				<Typography variant="h2">Status</Typography>

				<div className={classes.statusDescription} dangerouslySetInnerHTML={{ __html: description }}></div>

				{isAdmin(currentUserRole) && (
					<CommonSelect
						classnames={{
							input: classes.statusSelect,
						}}
						onChange={updateStatus}
						value={statusValue}
						options={organizationStatusOptions}
					/>
				)}
			</SettingsSection>
			<SettingsSection>
				<Typography variant="h2">Members</Typography>
				<Typography>
					This organization has {memberCount} current members, including super admins and admins. View and manage the membership list on the
					Engagement App.{' '}
					<Link target="_blank" underline="none" href={appLink}>
						Go to Engagement App
					</Link>
				</Typography>
			</SettingsSection>
			<SettingsSection>
				<Typography variant="h2">Roles</Typography>
				<Typography>
					Roles let Campus Admins know which individuals in an organization should receive specific information, regardless of the title
					they hold. Roles are not public on the Engagement App, except for the Primary Contact for the Public. Roles can be edited by
					Student Admins and Campus Admins. An individual can hold multiple roles if applicable.
				</Typography>
				{organizationPrimaryUsers.map(({ role, user }) => (
					<RoleEditor updateRole={updateRole} role={role} key={role} user={user} />
				))}
			</SettingsSection>
			<SettingsSection>
				<Typography variant="h2">Membership Types</Typography>
				<Typography>
					Membership Types determine the level of administrative permissions for members. They can be managed by Student Admins and Campus
					Admins on the Engagement App.{' '}
					<Link target="_blank" underline="none" href={appLink}>
						Go to Engagement App
					</Link>
				</Typography>
				{superAdmins && isArray(superAdmins) && (
					<CardContainer
						label="Super Admins"
						description={`Super Admins have the highest level of permissions for their organization. 
					They edit their organization’s page, make posts, create events, and manage all membership roles and requests. 
					There can be more than one Super Admin. If a person is a Super Admin, they are automatically a 
					Student Admin on the Organization Management System.`}
					>
						{superAdmins.map(({ email, userName }, index) => (
							<UserRow
								key={index}
								userName={<StyledInput disabled={true} value={userName} fullWidth />}
								email={<StyledInput disabled={true} value={email} fullWidth />}
							/>
						))}
					</CardContainer>
				)}

				{admins && isArray(admins) && (
					<CardContainer
						label="Admins"
						description={`Admins can do everything that a Super Admin can do except for managing Super Admins. 
					If a person is a Super Admin, they are automatically a Student Admin on the Organization Management System.`}
					>
						{admins.map(({ email, userName }, index) => (
							<UserRow
								key={index}
								userName={<StyledInput disabled={true} value={userName} fullWidth />}
								email={<StyledInput disabled={true} value={email} fullWidth />}
							/>
						))}
					</CardContainer>
				)}
			</SettingsSection>
		</div>
	)
}
type ISettings = {
	orgId: string
}

const Settings: React.FC<ISettings> = ({ orgId }) => {
	const dispatch = useDispatch()

	const isLoading = useSelector(selectOrganizationsIsLoading)
	const currentOrganizationData = useSelector(selectCurrentOrganization)

	useEffect(() => {
		dispatch(getOrganizationsByIdRequest(orgId))
	}, [dispatch, orgId])

	return <>{isLoading ? <MiddlePaneLoader /> : <SettingsContent {...currentOrganizationData} />}</>
}

export { Settings }
