import { ORGANIZATION_ROLE } from '@/features/organizations/constants'
import { OrganizationRoles } from '@/store/auth/types'

const ORGANIZATION_ROLE_BY_ID = {
	1: ORGANIZATION_ROLE.SUPER_ADMIN,
	2: ORGANIZATION_ROLE.ADMIN,
	3: ORGANIZATION_ROLE.MEMBER,
}

export type orgTypes = keyof typeof ORGANIZATION_ROLE_BY_ID

export const getOrganizationRole = (type: orgTypes): ORGANIZATION_ROLE | undefined => ORGANIZATION_ROLE_BY_ID[type]

export const getOrganizationType = (role: ORGANIZATION_ROLE): orgTypes => {
	const typeByRole = Object.keys(ORGANIZATION_ROLE_BY_ID).reduce((acc, type) => {
		return {
			...acc,
			// @ts-ignore
			[ORGANIZATION_ROLE_BY_ID[type]]: type,
		}
	}, {}) as { [key: string]: orgTypes }

	return +typeByRole[role] as orgTypes
}

export const checkOrgPermission = (role: ORGANIZATION_ROLE, types?: orgTypes[]) =>
	types ? types.some((type) => getOrganizationRole(type) === role) : false
export const isSuperAdmin = (types?: orgTypes[]) => checkOrgPermission(ORGANIZATION_ROLE.SUPER_ADMIN, types)
export const isAdmin = (types?: orgTypes[]) => checkOrgPermission(ORGANIZATION_ROLE.ADMIN, types)
export const isMember = (types?: orgTypes[]) => checkOrgPermission(ORGANIZATION_ROLE.MEMBER, types)
export const hasOrganizationRole = (types?: orgTypes[]) => isMember(types) || isAdmin(types) || isSuperAdmin(types)

export const getAllOrganizationRoles = () => Object.values(ORGANIZATION_ROLE)
export const getSuperAdminOrganizationIds = (organizationRoles: OrganizationRoles) => {
	const orgIds = Object.keys(organizationRoles)

	return orgIds.filter((id) => isSuperAdmin(organizationRoles[id]))
}

export const organizationRoleOptions = getAllOrganizationRoles().map((role) => ({
	label: role,
	value: getOrganizationType(role),
}))

export const ORGANIZATION_ROLE_IDS = getAllOrganizationRoles().map((role) => getOrganizationType(role))
export const getMaxMembershipPermissionLevel = (types?: orgTypes[]) => {
	if (!types || !types.length) return null

	let maxType: orgTypes = 3

	types?.forEach((type) => (maxType > type ? (maxType = type) : null))

	return {
		type: maxType,
		role: getOrganizationRole(maxType),
	}
}
