interface UserPhotoInfo {
	externalUid: string
	photoUrl?: string
	data?: string
}

const cachedImagesStoreKey = 'images'

export const cacheUserPhoto = ({ externalUid, photoUrl, data }: UserPhotoInfo): string | null => {
	try {
		const store = JSON.parse(sessionStorage.getItem(cachedImagesStoreKey) ?? '{}')
		const cachedImage = store[externalUid] ?? {}

		store[externalUid] = {
			externalUid,
			photoUrl: photoUrl ? photoUrl : cachedImage.photoUrl,
			data,
		}

		sessionStorage.setItem(cachedImagesStoreKey, JSON.stringify(store))

		return store[externalUid].data
	} catch (error) {
		console.error(error)
		return null
	}
}

export const getCachedUserPhoto = ({ externalUid, photoUrl }: UserPhotoInfo): string | null => {
	try {
		const store = JSON.parse(sessionStorage.getItem(cachedImagesStoreKey) ?? '{}')
		const cachedImage = store[externalUid] ?? {}

		if (cachedImage.photoUrl !== photoUrl) return null

		return cachedImage?.data
	} catch (error) {
		console.error(error)
		return null
	}
}
